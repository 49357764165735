import { Box, BoxProps, Text, useColorModeValue } from "@chakra-ui/react";
import { MouseEvent, useRef } from "react";
import { makeEvent, useEventListener } from "services/events";

interface MouseCardDataProps {
  id: string;
  title?: string;
  content?: string;
  cardContainerProps?: BoxProps;
}

interface SetMouseCardDataAction {
  title?: string;
  content?: string;
  parent?: HTMLElement;
  event: MouseEvent<HTMLElement>;
}

export const MouseInfoBox = ({
  id,
  title,
  content,
  cardContainerProps,
}: MouseCardDataProps) => {
  const cardRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLParagraphElement>(null);
  const contentRef = useRef<HTMLParagraphElement>(null);

  useEventListener(
    `MouseCardData-${id}`,
    ({ title, content, event, parent }: SetMouseCardDataAction) => {
      if (title) titleRef.current!.innerText = title;
      if (content) contentRef.current!.innerText = content;
      const card = cardRef.current!;
      followMouse({ event, parent, card });
    }
  );
  useEventListener(`onMouseCardDataLeave-${id}`, () => {
    const card = cardRef.current!;
    card.style.opacity = "0";
    card.style.visibility = "hidden";
  });

  return (
    <Box
      ref={cardRef}
      id={id}
      top="0"
      left="0"
      pos="absolute"
      transition="0.1s"
      overflow="hidden"
      borderRadius="8px"
      visibility="hidden"
      bg="#fff"
      w="200px"
      zIndex={1}
      opacity={0}
      boxShadow="0 0 1em rgba(0, 0, 33, 0.08)"
      {...cardContainerProps}
    >
      <Box w="100%" bg={"gray.100"} p="5px 10px">
        <Text ref={titleRef} id={`title-${id}`}>
          {title}
        </Text>
      </Box>
      <Box w="100%" p="5px 10px">
        <Text ref={contentRef} id={`content-${id}`}>
          {content}
        </Text>
      </Box>
    </Box>
  );
};

export const setMouseCardData = (id: string, data: SetMouseCardDataAction) =>
  makeEvent(`MouseCardData-${id}`, data, { throwsError: false });
export const onMouseCardDataLeave = (id: string) =>
  makeEvent(`onMouseCardDataLeave-${id}`, null, { throwsError: false });

export function followMouse({
  event,
  card,
  parent,
}: {
  card: HTMLElement;
  event: MouseEvent<HTMLElement>;
  parent?: HTMLElement;
}) {
  const container = parent ?? event.currentTarget.parentElement!;
  const cardRect = card.getBoundingClientRect();
  const { top, left } = container.getBoundingClientRect();
  const x = event.clientX - left;
  const y = event.clientY - top - cardRect.height - 8;
  card.style.opacity = "1";
  card.style.visibility = "visible";
  card.style.transform = `translate(${x}px,${y}px)`;
}

MouseInfoBox.displayName = "MouseInfoBox";
