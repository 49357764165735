import { Icon, IconProps } from "@chakra-ui/react";

export function SearchIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="21px"
      height="21px"
      viewBox="0 0 21 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="var(--chakra-colors-custom-gray)"
      {...rest}
    >
      <path
        d="M20 20L15.514 15.506L20 20ZM18 9.5C18 11.7543 17.1045 13.9163 15.5104 15.5104C13.9163 17.1045 11.7543 18 9.5 18C7.24566 18 5.08365 17.1045 3.48959 15.5104C1.89553 13.9163 1 11.7543 1 9.5C1 7.24566 1.89553 5.08365 3.48959 3.48959C5.08365 1.89553 7.24566 1 9.5 1C11.7543 1 13.9163 1.89553 15.5104 3.48959C17.1045 5.08365 18 7.24566 18 9.5V9.5Z"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </Icon>
  );
}

export const SearchIcon2 = ({ ...rest }: IconProps) => {
  return (
    <Icon
      width="38px"
      height="27px"
      viewBox="0 0 38 27"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle cx="13.5" cy="13.5" r="13.5" fill="#B21F17" fillOpacity="0.1" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.10872C10 5.56872 10.4383 5.13046 10.9783 5.13046H36.0217C36.2812 5.13046 36.53 5.23353 36.7135 5.41699C36.8969 5.60045 37 5.84927 37 6.10872C37 6.36817 36.8969 6.617 36.7135 6.80046C36.53 6.98392 36.2812 7.08698 36.0217 7.08698H10.9783C10.7188 7.08698 10.47 6.98392 10.2865 6.80046C10.1031 6.617 10 6.36817 10 6.10872Z"
        fill="black"
        stroke="white"
        strokeWidth="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 6.1087C9.99979 5.56908 10.1059 5.03471 10.3123 4.53612C10.5187 4.03754 10.8214 3.58452 11.203 3.20295C11.5845 2.82138 12.0375 2.51875 12.5361 2.31234C13.0347 2.10593 13.5691 1.99979 14.1087 2H32.8913C33.4309 1.99979 33.9653 2.10593 34.4639 2.31234C34.9625 2.51875 35.4155 2.82138 35.7971 3.20295C36.1786 3.58452 36.4813 4.03754 36.6877 4.53612C36.8941 5.03471 37.0002 5.56908 37 6.1087V20.1957C37 20.4551 36.8969 20.7039 36.7135 20.8874C36.53 21.0708 36.2812 21.1739 36.0217 21.1739C35.7623 21.1739 35.5135 21.0708 35.33 20.8874C35.1465 20.7039 35.0435 20.4551 35.0435 20.1957V6.1087C35.0435 4.91913 34.0809 3.95652 32.8913 3.95652H14.1087C12.9191 3.95652 11.9565 4.91913 11.9565 6.1087V21.7609C11.9565 22.9504 12.9191 23.913 14.1087 23.913H32.1087C32.3681 23.913 32.617 24.0161 32.8004 24.1996C32.9839 24.383 33.087 24.6319 33.087 24.8913C33.087 25.1508 32.9839 25.3996 32.8004 25.583C32.617 25.7665 32.3681 25.8696 32.1087 25.8696H14.1087C13.5691 25.8698 13.0347 25.7636 12.5361 25.5572C12.0375 25.3508 11.5845 25.0482 11.203 24.6666C10.8214 24.285 10.5187 23.832 10.3123 23.3334C10.1059 22.8349 9.99979 22.3005 10 21.7609V6.1087Z"
        fill="black"
        stroke="white"
        strokeWidth="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.6307 10.2174C25.937 10.2174 25.2501 10.3541 24.6092 10.6196C23.9682 10.885 23.3859 11.2742 22.8954 11.7647C22.4048 12.2552 22.0157 12.8376 21.7502 13.4785C21.4848 14.1194 21.3481 14.8063 21.3481 15.5001C21.3481 16.1938 21.4848 16.8807 21.7502 17.5216C22.0157 18.1625 22.4048 18.7449 22.8954 19.2354C23.3859 19.726 23.9682 20.1151 24.6092 20.3805C25.2501 20.646 25.937 20.7827 26.6307 20.7827C28.0318 20.7827 29.3754 20.2261 30.3661 19.2354C31.3568 18.2447 31.9133 16.9011 31.9133 15.5001C31.9133 14.099 31.3568 12.7554 30.3661 11.7647C29.3754 10.774 28.0318 10.2174 26.6307 10.2174ZM19.3916 15.5001C19.3916 13.5801 20.1543 11.7388 21.5119 10.3812C22.8695 9.02362 24.7108 8.26093 26.6307 8.26093C28.5507 8.26093 30.392 9.02362 31.7496 10.3812C33.1072 11.7388 33.8699 13.5801 33.8699 15.5001C33.8699 17.42 33.1072 19.2613 31.7496 20.6189C30.392 21.9765 28.5507 22.7392 26.6307 22.7392C24.7108 22.7392 22.8695 21.9765 21.5119 20.6189C20.1543 19.2613 19.3916 17.42 19.3916 15.5001Z"
        fill="black"
        stroke="white"
        strokeWidth="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.6343 19.5038C30.7251 19.413 30.833 19.3409 30.9517 19.2917C31.0704 19.2425 31.1976 19.2172 31.3261 19.2172C31.4546 19.2172 31.5818 19.2425 31.7005 19.2917C31.8192 19.3409 31.9271 19.413 32.0179 19.5038L35.931 23.4169C36.0218 23.5077 36.0939 23.6156 36.1431 23.7343C36.1922 23.853 36.2175 23.9802 36.2175 24.1087C36.2175 24.2372 36.1922 24.3644 36.1431 24.4831C36.0939 24.6018 36.0218 24.7097 35.931 24.8005C35.8401 24.8914 35.7323 24.9635 35.6136 25.0126C35.4949 25.0618 35.3676 25.0871 35.2391 25.0871C35.1107 25.0871 34.9834 25.0618 34.8647 25.0126C34.746 24.9635 34.6382 24.8914 34.5473 24.8005L30.6343 20.8875C30.5434 20.7966 30.4713 20.6888 30.4221 20.5701C30.373 20.4514 30.3477 20.3242 30.3477 20.1957C30.3477 20.0672 30.373 19.9399 30.4221 19.8212C30.4713 19.7025 30.5434 19.5947 30.6343 19.5038Z"
        fill="black"
        stroke="white"
        strokeWidth="0.4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.1309 11.587C13.1309 11.047 13.5691 10.6087 14.1091 10.6087H18.8048C19.0642 10.6087 19.313 10.7118 19.4965 10.8953C19.68 11.0787 19.783 11.3275 19.783 11.587C19.783 11.8464 19.68 12.0953 19.4965 12.2787C19.313 12.4622 19.0642 12.5653 18.8048 12.5653H14.1091C13.8497 12.5653 13.6008 12.4622 13.4174 12.2787C13.2339 12.0953 13.1309 11.8464 13.1309 11.587ZM13.1309 19.4131C13.1309 18.8731 13.5691 18.4348 14.1091 18.4348H18.8048C19.0642 18.4348 19.313 18.5379 19.4965 18.7213C19.68 18.9048 19.783 19.1536 19.783 19.4131C19.783 19.6725 19.68 19.9214 19.4965 20.1048C19.313 20.2883 19.0642 20.3913 18.8048 20.3913H14.1091C13.8497 20.3913 13.6008 20.2883 13.4174 20.1048C13.2339 19.9214 13.1309 19.6725 13.1309 19.4131ZM13.1309 15.5C13.1309 14.96 13.5691 14.5218 14.1091 14.5218H17.2396C17.499 14.5218 17.7478 14.6248 17.9313 14.8083C18.1147 14.9918 18.2178 15.2406 18.2178 15.5C18.2178 15.7595 18.1147 16.0083 17.9313 16.1918C17.7478 16.3752 17.499 16.4783 17.2396 16.4783H14.1091C13.8497 16.4783 13.6008 16.3752 13.4174 16.1918C13.2339 16.0083 13.1309 15.7595 13.1309 15.5Z"
        fill="black"
        stroke="white"
        strokeWidth="0.4"
      />
    </Icon>
  );
};

export function SearchIcon3({ ...rest }: IconProps) {
  return (
    <Icon
      width="36px"
      height="29px"
      viewBox="0 0 36 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <circle
        cx="13.5"
        cy="14.1875"
        r="13.5"
        fill="#B21F17"
        fillOpacity="0.1"
      />
      <g clipPath="url(#clip0_1206_4740)">
        <path
          d="M27.9736 20.0097L28.0481 20.0842L28.1186 20.0059L28.6392 19.4274C28.9149 19.1211 29.3922 19.1082 29.684 19.4L35.2079 24.9239C36.1308 25.8468 36.1306 27.3397 35.2079 28.2624C34.2848 29.1855 32.7921 29.1853 31.8692 28.2624L26.4675 22.8607L26.4675 22.8607C26.1965 22.5898 26.185 22.1543 26.4414 21.8695L26.4414 21.8695L27.0197 21.2269L27.0832 21.1564L27.0161 21.0893L25.669 19.7422L25.6035 19.6767L25.5332 19.7371C23.6848 21.323 21.2845 22.2824 18.6639 22.2824C12.8391 22.2824 8.1 17.5433 8.1 11.7185C8.1 5.8938 12.8391 1.15469 18.6639 1.15469C24.4886 1.15469 29.2277 5.8938 29.2277 11.7185C29.2277 14.339 28.2683 16.7394 26.6824 18.5879L26.622 18.6582L26.6875 18.7237L27.9736 20.0097ZM28.0327 22.2548L27.9693 22.3254L28.0364 22.3925L32.888 27.2438C33.2479 27.6037 33.8294 27.6038 34.1891 27.2438C34.548 26.885 34.548 26.3015 34.1891 25.9426L29.2767 21.03L29.2021 20.9554L29.1316 21.0338L28.0327 22.2548ZM18.6639 2.59531C13.6331 2.59531 9.54063 6.68782 9.54063 11.7185C9.54063 16.7493 13.6331 20.8418 18.6639 20.8418C23.6946 20.8418 27.7871 16.7493 27.7871 11.7185C27.7871 6.68782 23.6946 2.59531 18.6639 2.59531Z"
          fill="black"
          stroke="white"
          strokeWidth="0.2"
        />
        <path
          d="M11.3813 11.7185C11.3813 7.70297 14.6483 4.43594 18.6639 4.43594C22.6794 4.43594 25.9465 7.70297 25.9465 11.7185C25.9465 15.7341 22.6794 19.0012 18.6639 19.0012C14.6483 19.0012 11.3813 15.7341 11.3813 11.7185ZM12.8219 11.7185C12.8219 14.9399 15.4425 17.5605 18.6639 17.5605C21.8852 17.5605 24.5061 14.9399 24.5061 11.7185C24.5061 8.49721 21.8852 5.87635 18.6639 5.87635C15.4425 5.87635 12.8219 8.49721 12.8219 11.7185Z"
          fill="black"
          stroke="white"
          strokeWidth="0.2"
        />
        <path
          d="M17.773 13.0902L17.8438 13.1609L17.9145 13.0902L21.4358 9.56885C21.7172 9.28747 22.1732 9.28747 22.4546 9.56885C22.7358 9.85002 22.7358 10.3062 22.4546 10.5874L18.353 14.689L18.353 14.689C18.2125 14.8297 18.0283 14.9 17.8438 14.9C17.6592 14.9 17.4752 14.8297 17.3345 14.689L14.8735 12.228L14.8735 12.228C14.5922 11.9468 14.5922 11.4907 14.8735 11.2095L14.8735 11.2095C15.1549 10.9281 15.6109 10.9281 15.8921 11.2095L15.8921 11.2095L17.773 13.0902Z"
          fill="black"
          stroke="white"
          strokeWidth="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_1206_4740">
          <rect
            width="28"
            height="28"
            fill="white"
            transform="translate(8 1)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
}
