import { Center, Flex, Grid, Spinner } from "@chakra-ui/react";
import { useAttendanceContext } from "contexts/attendance-context";
import { useEffect, useState } from "react";
import { HeaderDataForm } from "./header-data-form";
import { PaginedForm } from "../pagined-form";
import api from "api/api";
import ChatWindow from "components/chat-window";
import { useRouter } from "routes/router-control/use-router";
import {
  consultaFGTS,
  mapConsultaFGTS,
} from "components/atendimentos-components/atendimento-form/functions/consulta";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { isColomboUser } from "utils/is-colombo-user";
import { useApplicationContext } from "contexts/ApplicationContext";

export const AttendanceDataForm = ({
  attendanceId,
  init,
  setIsDisabledBackButton,
}: {
  attendanceId?: string | null;
  init: boolean;
  setIsDisabledBackButton: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const { dispatch, setConsultandoFGTS, setReConsultandoFGTS } =
    useAttendanceContext();
  const { user } = useApplicationContext();

  const router = useRouter();

  useEffect(() => {
    return () => {
      dispatch({ type: "setInvalidField", payload: { action: "clear" } });
    };
  }, []);

  const getCompletedData = async () => {
    const destination = init
      ? `/atendimentos/${attendanceId}/kanban/start`
      : `/atendimentos/${attendanceId}/kanban/open`;
    setIsLoading(true);
    try {
      const { data } = await api.get(destination);
      dispatch({ type: "initAttendance", payload: data });
      if (isColomboUser(user)) {
        if (data.atendimentoFactaFGTS?.statusSaldo === "PENDING" && data.cpf) {
          consultaFGTS({
            loading: setConsultandoFGTS,
            verificar: () =>
              api.get(`/facta/fgts/consultar-solicitacao-saldo/${data.id}`),
            isEndLoop: (response) => response.data?.statusSaldo !== "PENDING",
            onFinish: (response) => {
              const formValues = data as Attendance;
              mapConsultaFGTS(response?.data, dispatch, formValues);
            },
          });
        }
        if (
          data.atendimentoFactaFGTS?.statusRecalculo === "PENDING" &&
          data.cpf
        ) {
          consultaFGTS({
            loading: setReConsultandoFGTS,
            verificar: () =>
              api.get(`/facta/fgts/consultar-solicitacao-recalculo/${data.id}`),
            errorMessage: "Erro ao recalcular",
            isEndLoop: (response) =>
              response.data?.statusRecalculo !== "PENDING",
            onFinish: (response) => {
              const formValues = data as Attendance;
              mapConsultaFGTS(response?.data, dispatch, formValues);
            },
          });
        }
      }
    } catch (e) {
      router.push("/atendimentos");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getCompletedData();
  }, []);

  return (
    <Flex
      flexDir="column"
      w="100%"
      bg="#fff"
      borderRadius="5px"
      minH="calc(100vh - 30px - 80px - 36px - 30px - 30px)"
      pos="relative"
    >
      {isLoading ? (
        <Center inset="0" pos="absolute">
          <Spinner />
        </Center>
      ) : (
        <Grid
          templateColumns={{
            base: "calc(100% - 400px) 400px",
            "2xl": "calc(100% - 500px) 500px",
          }}
          h="100%"
          w="100%"
          minH="calc(100vh - 30px - 80px - 36px - 30px - 30px)"
        >
          <Grid
            pos="relative"
            maxW={`calc(100vw - 30px - 30px - 100px - 400px)`}
            overflow="auto"
            gridTemplateRows={`auto minmax(0px, 100%)`}
          >
            <HeaderDataForm isLoading={isLoading} />
            <PaginedForm setIsDisabledBackButton={setIsDisabledBackButton} />
          </Grid>
          <Flex
            flexDir="column"
            justifyContent="flex-start"
            borderLeft="1px solid #E8EAED"
            p="25px"
          >
            <ChatWindow />
          </Flex>
        </Grid>
      )}
    </Flex>
  );
};
