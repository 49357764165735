import { Icon, IconProps } from "@chakra-ui/react";

export function ChatIcon({
  color = "#1C1C1E",
  ...rest
}: { color: string } & Omit<IconProps, "color">) {
  return (
    <Icon
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M4.4849 15.4765C4.77494 15.4765 4.98901 15.3384 5.3343 15.0277L7.8825 12.7281L12.3988 12.735C14.4774 12.735 15.6445 11.5334 15.6445 9.48241V4.11669C15.6445 2.0657 14.4774 0.864105 12.3988 0.864105H3.59406C1.52236 0.864105 0.348389 2.0657 0.348389 4.11669V9.48241C0.348389 11.5403 1.55688 12.7281 3.54572 12.7281H3.82195V14.7307C3.82195 15.1865 4.06365 15.4765 4.4849 15.4765ZM4.34678 4.91775C4.10509 4.91775 3.90482 4.71748 3.90482 4.46197C3.90482 4.22027 4.10509 4.02001 4.34678 4.02001H11.577C11.8256 4.02001 12.0259 4.22027 12.0259 4.46197C12.0259 4.71748 11.8256 4.91775 11.577 4.91775H4.34678ZM4.34678 7.20353C4.10509 7.20353 3.90482 6.99636 3.90482 6.74085C3.90482 6.50606 4.10509 6.29888 4.34678 6.29888H11.577C11.8256 6.29888 12.0259 6.49915 12.0259 6.74085C12.0259 6.99636 11.8256 7.20353 11.577 7.20353H4.34678ZM4.34678 9.48241C4.10509 9.48241 3.90482 9.27524 3.90482 9.03354C3.90482 8.77803 4.10509 8.57776 4.34678 8.57776H9.06337C9.31198 8.57776 9.51224 8.77803 9.51224 9.03354C9.51224 9.27524 9.31198 9.48241 9.06337 9.48241H4.34678Z"
        fill={color}
      />
    </Icon>
  );
}
