import { Toast } from "components/toast";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { RefObject } from "react";

export const downloadMailing = async (
  id: number,
  name: string,
  downloadRef: RefObject<HTMLAnchorElement>
) => {
  try {
    const { data } = await api.get(`/mailings/${id}/download`);
    const file = new File([data], "mailing.csv", {
      type: "text/plain",
    });
    const download = downloadRef.current!;
    const fileUrl = URL.createObjectURL(file);
    download.href = fileUrl;
    download.download = `${name}.csv`;
    download.click();
  } catch (e) {
    Toast({
      title: "Ocorreu um erro ao baixar mailing",
    });
  }
};
