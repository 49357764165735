import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Flex } from "@chakra-ui/react";
import api from "api/api";
import DynamicTable from "components/dynamic-table";
import {
  StatusFormat,
  bgError,
  bgSucess,
  bgWarning,
} from "components/dynamic-table/row-status";
import { Column } from "components/dynamic-table/types";
import { GetLayout } from "components/get-layout";
import { openModalConfirm } from "components/modal-confirm-new";
import {
  ModalComDropdownRecorrencia,
  openModalComDropdownRecorrencia,
} from "components/servicos-components/modal-datafast/modal-renovacao-fila";
import { PriceButton } from "components/servicos-components/service-card";
import { SkeletonLoading } from "components/skeleton-loading";
import { TitleWithBackButton } from "components/title-with-back-button";
import { useApplicationContext } from "contexts/ApplicationContext";
import { SelectedCustomer } from "pages/carteira";
import { useEffect, useState } from "react";
import { useRouter } from "routes/router-control/use-router";
import { formatData } from "utils/string-formats";
import { Toast } from "components/toast";

interface SessionList {
  id: number;
  ativo: boolean;
  possuiRecorrencia: boolean;
  dataExpiracao: string;
  expirado: boolean;
  validLicense: boolean;
}

export function WhatsAppServicePage() {
  const [selectedCustomer, setSelectedCustomer] = useState<SelectedCustomer>();
  const [sessionList, setSessionList] = useState<SessionList[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const params = new URLSearchParams(window.location.search);
  const partnerId = params.get("partnerId");

  const { user } = useApplicationContext();

  const createNewSession = async () => {
    openModalComDropdownRecorrencia({
      modalTitle: "Contratar nova sessão",
      onConfirm: async ({ possuiRecorrencia }) => {
        try {
          const { data } = await api.get(
            `/services/whatsapp/create-mensal-license?customerId=${partnerId}&possuiRecorrencia=${possuiRecorrencia}`
          );
          setSessionList((list) => [...list, data]);
        } catch (e: any) {
          const codError = e?.response?.data?.message;
          const errorMessage = (
            {
              sem_saldo: "Saldo insuficiente",
            } as any
          )[codError ?? ""];
          Toast({
            title: errorMessage ?? "Erro ao contratar nova sessão whatsapp",
            status: "error",
          });
        } finally {
        }
      },
      children: <PriceButton mt="15px">R$ 15,00 (mensal)</PriceButton>,
    });
  };

  const renewSession = async (row: SessionList) => {
    openModalComDropdownRecorrencia({
      modalTitle: "Mudar Recorrência",
      onConfirm: async ({ possuiRecorrencia }) => {
        try {
          const { data } = await api.get(
            `/services/whatsapp/${row.id}/renew-mensal-license?possuiRecorrencia=${possuiRecorrencia}`
          );
          setSessionList((list) => {
            return list.map((currRow) => {
              if (row.id === currRow.id) return data;
              return currRow;
            });
          });
        } catch (e: any) {
          const codError = e?.response?.data?.message;
          const errorMessage = (
            {
              sem_saldo: "Saldo insuficiente",
            } as any
          )[codError ?? ""];
          Toast({
            title: errorMessage ?? "Erro ao renovar sessão whatsapp",
            status: "error",
          });
        } finally {
        }
      },
      children: <PriceButton mt="15px">R$ 15,00 (mensal)</PriceButton>,
    });
  };

  const deleteSession = async (row: SessionList) => {
    openModalConfirm({
      message: `Deletar sessão?`,
      onConfirm: async () => {
        try {
          const { data } = await api.delete(`/services/whatsapp/${row.id}`);
          setSessionList((list) => {
            return list.filter((currRow) => row.id !== currRow.id);
          });
        } catch (e: any) {
          Toast({ title: "Erro ao excluir sessão whatsapp", status: "error" });
        } finally {
        }
      },
    });
  };

  const changeRecorrencySession = async (row: SessionList) => {
    openModalConfirm({
      message: `Deseja ${
        row.possuiRecorrencia ? "DESATIVAR" : "ATIVAR"
      } recorrência?`,
      onConfirm: async () => {
        try {
          const { data } = await api.get(
            `/services/whatsapp/${
              row.id
            }/change-recorrencia?possuiRecorrencia=${!row.possuiRecorrencia}`
          );
          setSessionList((list) => {
            return list.map((currRow) => {
              if (row.id === currRow.id) return data;
              return currRow;
            });
          });
        } catch (e: any) {
          Toast({
            title: "Erro ao mudar recorrência de sessão whatsapp",
            status: "error",
          });
        } finally {
        }
      },
      confirmButtonStyle: { variant: undefined },
    });
  };

  const columns: Column[] = [
    {
      name: "Data Expiração",
      render: (row: SessionList) => {
        return (
          <>
            <StatusFormat w="82px" bg={row.expirado ? bgError : bgSucess}>
              {row.dataExpiracao ? formatData(row.dataExpiracao) : null}
            </StatusFormat>
          </>
        );
      },
    },
    {
      name: "Status",
      render: (row: SessionList) => {
        return (
          <>
            <StatusFormat
              w="82px"
              bg={row.expirado ? bgError : row.ativo ? bgSucess : bgError}
            >
              {row.expirado ? "Expirado" : row.ativo ? "Ativo" : "Inativo"}
            </StatusFormat>
          </>
        );
      },
    },
    {
      name: "Recorrência",
      render: (row: SessionList) => {
        return (
          <>
            <StatusFormat bg={row.possuiRecorrencia ? bgSucess : bgWarning}>
              {row.possuiRecorrencia ? "SIM" : "NÃO"}
            </StatusFormat>
          </>
        );
      },
    },
    {
      name: "Ações",
      render: (row: SessionList) => {
        return (
          <>
            {row.expirado ? (
              <Button
                mr="10px"
                size="xs"
                onClick={() => renewSession(row)}
                variant="outline"
                w="145px"
              >
                Renovar
              </Button>
            ) : null}
            {row.ativo ? (
              <Button
                mr="10px"
                size="xs"
                onClick={() => changeRecorrencySession(row)}
                variant="outline"
                w="145px"
              >
                {row.possuiRecorrencia ? "Desativar" : "Ativar"} Recorrência
              </Button>
            ) : null}
            {row.expirado ? (
              <Button
                mr="10px"
                size="xs"
                onClick={() => deleteSession(row)}
                variant="outline"
                w="145px"
              >
                Excluir
              </Button>
            ) : null}
          </>
        );
      },
    },
  ];

  const getSessions = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`/customers/short`);
      const selectedCustomer = data?.find?.(
        (curr: SelectedCustomer) => `${curr.id}` === partnerId
      );
      setSelectedCustomer(selectedCustomer);
      if (!selectedCustomer) {
        Toast({ title: "Usuário não permitido" });
        router.push("/servicos");
      }
    } catch {
    } finally {
    }
    if (`${user.userData.customerId}` !== partnerId) {
      if (["SUPER", "FINANCEIRO", "COMERCIAL"].includes(user.userData.type!)) {
      } else {
        Toast({ title: "Usuário não permitido" });
        router.push("/");
      }
    }
    try {
      const { data } = await api.get(`/services?customerId=${partnerId}`);
      setSessionList(data.servicesWhatsappDTO?.sessions || []);
    } catch (e) {
      setSessionList([]);
      Toast({ title: "Erro ao obter serviços", status: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSessions();
  }, []);

  return (
    <GetLayout>
      <TitleWithBackButton
        title="Sessões WhatsApp"
        onClick={() => {
          router.push(`/servicos?partnerId=${partnerId}`);
        }}
      />
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        fontSize="20px"
        fontWeight="semibold"
      >
        Parceiro:&nbsp;
        <SkeletonLoading isLoading={!selectedCustomer} borderRadius="5px">
          {selectedCustomer ? (
            `${selectedCustomer?.codigoNova} - ${selectedCustomer?.name}`
          ) : (
            <Box w="150px" h="30px" ml="10px"></Box>
          )}
        </SkeletonLoading>
      </Flex>
      <Box
        my="10px"
        w="100%"
        borderBottom="1px solid var(--chakra-colors-gray-200)"
      />
      <Box w="100%" bg="#fff" p="20px 30px" borderRadius="5px">
        <Flex justifyContent="flex-end">
          <Button
            variant="outline"
            ml="auto"
            leftIcon={<AddIcon />}
            onClick={() => createNewSession()}
          >
            Adicionar nova sessão
          </Button>
        </Flex>
        <DynamicTable
          columns={columns}
          rows={sessionList}
          isLoading={isLoading}
          pageSize={15}
          headerStyle={(header) => ({
            textAlign: header.key === "valor" ? "end" : "center",
            bg: "#fff",
            color: "#656F7D",
            textTransform: "uppercase",
            fontSize: "12px",
            fontWeight: "medium",
            _hover: { bg: "gray.50" },
            px: { base: "12px", "2xl": "20px" },
          })}
          containerStyle={{ boxShadow: "none" }}
        />
      </Box>
      <ModalComDropdownRecorrencia />
    </GetLayout>
  );
}
