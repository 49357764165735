import { Box, Center, Grid, IconButton, Text } from "@chakra-ui/react";
import { CleanIcon } from "components/vectors/clean-icon";
import { SetStateAction, useEffect, useState } from "react";
import api from "api/api";
import { DropdownField } from "components/dropdown-field";
import { Toast } from "components/toast";
import { MailingData, MailingOptionFilter } from "../..";

type FilterProps = {
  addedFilters: MailingOptionFilter;
  onChangeHigienizacao: (
    newValue: SetStateAction<MailingOptionFilter>,
    key: string
  ) => void;
  filterKey: string;
  isDisabled?: boolean;
  allModalData: MailingOptionFilter;
  mailingId?: number;
  errorMessage: string | undefined;
  mailingData: Partial<MailingData>;
};

export interface MargemConvenioMailingProps {
  idBanco?: number;
  convenio?: number;
  idSubConvenio?: number;
}

interface OptionsProps {
  nomeConvenio: string;
  botId: number;
  dadosConvenioDTOList: {
    id: number;
    name: string;
    idSubconvenio: number;
    bancos: { id: number; name: string; idBanco: number }[];
  }[];
}

export function MargemCovenio({
  addedFilters,
  onChangeHigienizacao,
  filterKey,
  isDisabled,
  allModalData,
  mailingId,
  errorMessage,
  mailingData,
}: FilterProps) {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<OptionsProps[] | null>(null);
  let filterValue = addedFilters.MARGEM_CONVENIO
    .value as MargemConvenioMailingProps;
  const selectedConvenio = options?.find(
    (convenio) => convenio.botId === filterValue.convenio
  );
  const subconvenios = selectedConvenio?.dadosConvenioDTOList;
  const selectedSubConvenio = subconvenios?.find(
    (subC) => subC.idSubconvenio === filterValue.idSubConvenio
  );

  const set = ({ value }: { value: any }) => {
    onChangeHigienizacao(
      { ...addedFilters, [filterKey]: { ...addedFilters[filterKey], value } },
      filterKey
    );
  };

  const cleanFilter = () => {
    set({ value: {} });
  };

  const getOptions = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`/pipeline/cronowise-convenios`);
      setOptions(data);
      console.log(data);
    } catch (e: any) {
      if (e.response?.data?.message === "nenhum usuario ativo para higienizar convenios") {
        Toast({ title: "Nenhum usuário ativo para higienizar convênios", status: "error" });
      } else {
        Toast({ title: "Erro ao obter dados", status: "error" });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onChange = (key: keyof MargemConvenioMailingProps, value: any) => {
    const newValue = { ...filterValue, [key]: value };
    filterValue = newValue;
    set({ value: newValue });
    console.log(filterValue);
  };

  useEffect(() => {
    getOptions();
  }, []);

  const getErrorMessage = (
    selectedKey: "convênio" | "banco" | "subconvênio"
  ) => {
    const keyWords = ["convênio", "subconvênio", "banco"];

    if (
      !keyWords.find((word) => errorMessage?.split(" ").find((w) => w === word))
    )
      return errorMessage || "";

    const fieldCondition = !!errorMessage
      ?.split(" ")
      .find((w) => w === selectedKey);

    if (fieldCondition) return errorMessage;

    let messageToAll = false;
    keyWords
      .filter((word) => word !== selectedKey)
      .forEach((curr) => {
        if (errorMessage?.split(" ").find((w) => w === selectedKey))
          messageToAll = true;
      });

    if (messageToAll) return errorMessage || "";
  };

  return (
    <>
      <Grid templateColumns="auto 32px" gap="8px" mb="10px">
        <Box>
          <DropdownField
            title="Convênios"
            dropdownProps={{
              w: "100%",
              onChange: (value) => {
                onChange("convenio", value);
                onChange("idSubConvenio", undefined);
                onChange("idBanco", undefined);
              },
              disabled: isDisabled,
              loadingText: "Selecione",
              spinnerPlacement: "end",
              isLoading: isLoading,
            }}
            options={options?.map((c) => ({
              name: c.nomeConvenio,
              value: c.botId,
            }))}
            value={filterValue.convenio}
            errorMessage={getErrorMessage("convênio")}
          />
        </Box>
        <Box>
          <Text mb="8px" fontWeight="medium">
            &nbsp;
          </Text>
          <Center h="36px">
            <IconButton
              aria-label=""
              variant="outline"
              size="sm"
              icon={<CleanIcon />}
              onClick={() => cleanFilter()}
              disabled={isDisabled}
            />
          </Center>
        </Box>
      </Grid>
      {subconvenios ? (
        <Box mb="10px">
          <DropdownField
            title="Subconvênio"
            dropdownProps={{
              w: "100%",
              onChange: (value) => {
                onChange("idSubConvenio", value);
                onChange("idBanco", undefined);
              },
              disabled: isDisabled,
              loadingText: "Selecione",
              spinnerPlacement: "end",
              isLoading: isLoading,
            }}
            options={subconvenios?.map((subC) => ({
              name: subC.name,
              value: subC.idSubconvenio,
            }))}
            value={filterValue.idSubConvenio}
            errorMessage={getErrorMessage("subconvênio")}
          />
        </Box>
      ) : null}
      {selectedSubConvenio &&
      filterValue.convenio !== 4 &&
      filterValue.convenio !== 5 ? (
        <Box>
          <DropdownField
            title="Banco"
            dropdownProps={{
              w: "100%",
              onChange: (value) => {
                onChange("idBanco", value);
              },
              disabled: isDisabled,
              loadingText: "Selecione",
              spinnerPlacement: "end",
              isLoading: isLoading,
            }}
            options={selectedSubConvenio.bancos?.map((banco) => ({
              name: banco.name,
              value: banco.idBanco,
            }))}
            value={filterValue.idBanco}
            errorMessage={getErrorMessage("banco")}
          />
        </Box>
      ) : null}
    </>
  );
}
