import { useId, useState } from "react";
import { useEventListener } from "services/events";
import { emitRouterChangeEvent } from "./router-select";

export interface RouterChangeEventParam {
  href: string;
  payload?: any;
}

export type RouterPushParams =
  | string
  | { pathname: string; payload?: { [k: string]: any } };

export const useRouter = () => {
  const [routerData, setRouterData] = useState({
    search: window.location.search,
    href: window.location.href,
    pathname: window.location.pathname,
  });

  const id = useId();

  useEventListener(
    "routerChange",
    ({ href }: RouterChangeEventParam) => {
      const completedUrl = getUrlData(href);
      const url = new URL(completedUrl.fullUrl);
      setRouterData({
        href: url.href,
        pathname: url.pathname,
        search: url.search,
      });
    },
    id,
    { removeDidUnMount: true }
  );

  return { ...routerData, push: pushRouter };
};

export function getUrlData(href: string) {
  const origin = window.location.origin;
  const fullUrl = href.indexOf("/") === 0 ? `${origin}${href}` : href;
  return { fullUrl };
}

function pushRouter(hrefData: RouterPushParams) {
  const payload = typeof hrefData === "object" ? hrefData.payload : undefined;
  const href = typeof hrefData === "object" ? hrefData.pathname : hrefData;
  emitRouterChangeEvent({ href, payload });
}

export function updateRouterVar(url: URL) {
  Router.href = url.href;
  Router.search = url.search;
  Router.pathname = url.pathname;
}

export const Router = {
  search: window.location.search,
  href: window.location.href,
  pathname: window.location.pathname,
  push: pushRouter,
};
