import { CheckIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Flex, FlexProps, useColorModeValue } from "@chakra-ui/react";
import { Checkbox } from "components/checkbox";
import { MouseEvent, useRef } from "react";

interface DropdownSubitem {
  name: string;
  value: any;
  data?: any;
  isDisabled?: () => boolean;
}
interface DropdownSubitemProps {
  option:
    | {
        subitems?: DropdownSubitem[];
        value?: any;
        name: string;
        data?: any;
      }
    | any;
  selectedValue: string;
  onClose: () => void;
  onChange: ((a: any, b: any) => void) | undefined;
  multiSelect?: boolean;
  optionValueKey?: string;
  optionLabelKey?: string;
  containerOptionProps?: FlexProps;
}

export function DropdownItem({
  option,
  selectedValue,
  onChange,
  onClose,
  multiSelect,
  optionValueKey = "value",
  optionLabelKey = "name",
  containerOptionProps,
}: DropdownSubitemProps) {
  const menuRef = useRef<HTMLDivElement>(null);
  const hoverColor = useColorModeValue("bg-gray", "rgba(255,255,255, .05)");
  const activeColor = useColorModeValue("gray.200", "rgba(255,255,255, .1)");
  const { subitems, name = option[optionLabelKey] } = option;
  const optionValue = option[optionValueKey];
  const isSubitemActive = !!subitems?.find(
    (subI: any) => subI.value === selectedValue
  );
  const isActive = selectedValue === optionValue;

  const onClick = () => {
    if (!isActive) onChange?.(optionValue, option);
    if (!multiSelect) onClose();
  };

  const onClickIfSubitems = (e: MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    const { style, clientHeight } = menuRef.current!;
    if (clientHeight > 0) style.height = "0";
    else style.height = `${subitems.length * 36}px`;
    externalOnClick?.(e);
  };

  const { onClick: externalOnClick, ...optionProps } =
    containerOptionProps || {};

  return (
    <>
      {subitems ? (
        <>
          <Flex
            alignItems="center"
            h="36px"
            px="10px"
            cursor="pointer"
            bg={isSubitemActive ? activeColor : "none"}
            _hover={{ bg: hoverColor }}
            onClick={onClickIfSubitems}
            justifyContent="space-between"
            gap="10px"
            onMouseDown={(e) => e.preventDefault()}
            {...optionProps}
          >
            {name}
            <ChevronDownIcon />
          </Flex>
          <Box ref={menuRef} w="100%" h="0" overflow="hidden" transition="0.2s">
            {subitems.map((subItem: any) => {
              const { name, value } = subItem;
              return (
                <Flex
                  w="100%"
                  key={value}
                  alignItems="center"
                  h="36px"
                  pl="15px"
                  pr="10px"
                  cursor="pointer"
                  bg={selectedValue === value ? activeColor : "none"}
                  _hover={{ bg: hoverColor }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!(selectedValue === value)) onChange?.(value, subItem);
                    onClose();
                    externalOnClick?.(e);
                  }}
                  justifyContent="space-between"
                  gap="10px"
                  onMouseDown={(e) => e.preventDefault()}
                  {...optionProps}
                >
                  {name}
                  {selectedValue === value ? (
                    <CheckIcon w="12px" />
                  ) : (
                    <Box w="12px" />
                  )}
                </Flex>
              );
            })}
          </Box>
        </>
      ) : (
        <Flex
          alignItems="center"
          h="36px"
          px="10px"
          cursor="pointer"
          bg={isActive ? activeColor : "none"}
          _hover={{ bg: hoverColor }}
          onClick={(e) => {
            e.stopPropagation();
            if (!isActive) onChange?.(optionValue, option);
            if (!multiSelect) onClose();
            externalOnClick?.(e);
          }}
          onMouseDown={(e) => e.preventDefault()}
          pointerEvents={option.isDisabled?.() ? "none" : undefined}
          opacity={option.isDisabled?.() ? 0.6 : 1}
          {...optionProps}
        >
          {multiSelect ? (
            <Checkbox
              mr="8px"
              isChecked={selectedValue.includes(optionValue)}
              onChange={() => {
                if (!isActive) onChange?.(optionValue, option);
                if (!multiSelect) onClose();
              }}
              isDisabled={option.isDisabled?.()}
            />
          ) : null}
          {name}
        </Flex>
      )}
    </>
  );
}
