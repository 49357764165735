import { Icon, IconProps } from "@chakra-ui/react";

export function EX3CompanyLogo({ ...rest }: IconProps) {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 814 502"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M650.6 497.8C649.8 493.9 649.9 453.1 650.7 450.1C651.4 447.2 653 448.5 653 452.1C653 454.8 655.3 456.3 656.2 454.1C657 451.9 664.5 448 667.9 448C674.9 448.1 682.6 453.2 684.9 459.3C686.3 462.9 686.3 472.1 684.9 475.7C683.6 479.1 679.4 483.7 675.8 485.6C670.1 488.6 661 486.9 656.6 482.1C655.6 481 654.3 480.3 653.9 480.6C653.4 480.9 653 485.6 653 491.1C653 500.9 651.9 504.1 650.6 497.8ZM675.8 481.8C682.4 477.8 684.7 468.3 681 460.3C678.6 454.9 674 452 668 452C661.8 452 657.4 454.9 654.8 460.7C648.4 475.2 662.9 489.8 675.8 481.8Z"
        fill="currentColor"
      />
      <path
        d="M777 499.5C775.1 497.2 776.4 496.6 780.3 497.9C785.2 499.6 788.3 498 790.9 492.8C792 490.3 793 487.8 793 487.2C793 485.8 785.6 467.6 781.1 457.8C777.9 450.8 777.3 448 779 448C780.3 448 783.9 455.3 788.9 468.1C791.2 473.9 793.7 479.5 794.5 480.6C795.8 482.4 796 482.3 797.5 478.4C805 459.8 809.9 449.5 811.3 449.2C813.4 448.8 813.8 447.5 802.2 474.9C792.1 498.7 790.4 501 783.2 501C780 501 777.8 500.5 777 499.5Z"
        fill="currentColor"
      />
      <path
        d="M494.2 484.9C483.1 479.4 477.3 464.9 481.5 452.9C485.3 442.2 492.9 436.1 503.5 435.2C509.1 434.8 510.7 435.1 515.4 437.4C520.1 439.7 522.9 442.5 521.7 443.7C521.4 443.9 519.1 442.9 516.5 441.6C513.9 440.2 510.3 438.8 508.4 438.4C500.6 436.9 489.3 442.9 485.9 450.3C483.4 455.9 483.4 466.1 485.9 471.5C491.2 482.9 504.2 487.2 515.7 481.3C521.2 478.6 523.3 478.7 520.9 481.6C516.4 487 502.1 488.8 494.2 484.9Z"
        fill="currentColor"
      />
      <path
        d="M538.4 485.6C526 480.2 525 457.8 536.9 451.2C545.6 446.3 554.9 448 560.6 455.6C563.2 459 563.5 460.1 563.5 467C563.5 473.7 563.2 475.2 560.7 478.9C559.2 481.2 556.5 484 554.7 485C550.9 487.3 543.1 487.6 538.4 485.6ZM553.7 482C562.7 476.6 563.4 461.7 555.1 454.7C548.7 449.4 537.3 451.8 533.4 459.2C530.8 464 530.9 473.1 533.6 476.7C538.8 483.8 547.2 486 553.7 482Z"
        fill="currentColor"
      />
      <path
        d="M575.9 473C575.8 465.3 575.8 458.3 575.8 457.5C575.8 451.6 576.1 449 577 449C577.6 449 578 450.1 578 451.5C578 454.5 579.3 454.6 583.1 452C590.2 446.8 596.8 447 602.9 452.5L606.7 456L608.6 453.8C611 451.2 617.7 448 621 448C622.4 448 625.3 448.9 627.6 449.9C634.1 452.8 635.1 456.2 634.8 472.6C634.6 482.5 634.2 486.5 633.3 486.8C632.3 487.1 632 484.2 632 474.6C632 456 629.8 452 619.5 452C614.1 452 609.5 455.5 608 460.8C607.5 462.8 607 469.6 607 475.8C607 485.1 606.7 487 605.5 487C604.3 487 604 485 604 475.3C604 461.6 602.9 456.9 599.3 454C596.1 451.5 589 451.3 584.9 453.7C580.2 456.5 579 460.4 579 472.8C579 478.9 578.7 484.5 578.4 485.4C576.8 489.6 576 485.8 575.9 473Z"
        fill="currentColor"
      />
      <path
        d="M700.4 486C694.9 483.8 692.5 476.7 695.5 471.1C697.4 467.3 698.9 466.8 710 466.1L719.5 465.5L719.8 461.7C720.1 458.7 719.6 457.4 717.1 455C714.7 452.6 713.3 452 709.7 452C707.3 452 703.3 452.7 701 453.5C695.9 455.3 694.6 454.6 697.8 451.9C702.6 448 711.8 447.2 717.3 450.2C718.3 450.7 720 452.6 721 454.3C722.7 457.1 723 459.4 723 472.3C723 481.4 722.6 487 722 487C721.5 487 721 485.9 721 484.5C721 483.1 720.8 482 720.6 482C720.4 482 718.2 483.1 715.9 484.5C711.3 487.1 704.6 487.7 700.4 486ZM714.7 482.4C717.8 480.4 720.2 475.5 719.8 471.7L719.5 468.5H710.7C702.1 468.5 701.7 468.6 699.3 471.4C696.7 474.4 696.6 478.7 699.1 481.7C701.9 484.9 710.1 485.3 714.7 482.4Z"
        fill="currentColor"
      />
      <path
        d="M738 468.1C738 449.1 738.9 444.4 741.1 450.9C741.7 452.6 742.4 454 742.7 454C743 454 744.5 452.9 746.1 451.6C752.8 446 764.9 448.1 768.4 455.4C770.5 459.8 770.8 487.4 768.8 486.8C767.9 486.5 767.3 482.3 766.9 472.4C766.6 464.8 766 457.7 765.6 456.8C763.5 452.2 753.9 450.4 748 453.5C742.9 456.2 741.6 460 741.2 474.3C740.9 484.3 740.5 487 739.4 487C738.2 487 738 483.6 738 468.1Z"
        fill="currentColor"
      />
      <path
        d="M1.99984 409C-0.200157 407.6 -0.800157 5.5 1.39984 2.5C2.79984 0.5 4.99984 0.5 111.8 0.2C211.5 -0.0999998 220.9 1.89781e-07 222.4 1.5C223.8 3 224 7.1 224 37.9C224 65.6 223.7 73 222.7 73.9C221.8 74.6 200.1 75 150.7 75.2C94.3998 75.4 79.7998 75.8 78.7998 76.8C77.7998 77.8 77.4998 87.3 77.4998 122C77.4998 146.1 77.7998 166.1 78.1998 166.5C78.5998 166.9 127.4 167.4 186.6 167.7C277.9 168.1 294.2 167.9 293.7 166.7C293.4 166 287.4 156.8 280.2 146.4C252.3 105.6 252.9 106.6 254.5 105.7C255.3 105.3 273.4 105 294.8 105.2C338.9 105.5 336.2 105 342.4 114.2C353.4 130.6 367.6 150.4 368.6 150.7C370.2 151.2 377.6 140.7 422 74C444.1 40.7 463.8 11.9 465.7 9.9C467.6 7.8 471.5 4.9 474.4 3.4L479.5 0.5L517.8 0.2C554.8 -0.0999998 556 1.89781e-07 556 1.8C556 2.9 548.7 14.2 539.8 27.1C514.8 63.3 477 118 473.5 123C471.8 125.5 466.6 132.9 462 139.5C457.4 146.1 447.5 160.3 440 171C424.4 193.1 421.9 197.6 421.8 203C421.7 208.6 424.2 212.9 442.2 238.5C465.3 271.4 477 288.1 497 317C506.7 331 517.5 346.6 520.9 351.5C535.2 372.2 556.7 403.3 558.8 406.5L561 410H519.2C477.5 410 477.4 410 474.2 407.8C472.4 406.5 466.4 399 461 391C449.5 374.3 442.7 364.3 408 313.5C372.4 261.4 369.9 257.9 369 258.2C368.5 258.4 362.2 267.1 355 277.5C347.7 288 340.9 297.5 339.8 298.7C338.7 299.9 335.5 301.8 332.8 303C327.8 305.1 326.6 305.2 289.2 305.1C268 305.1 250.5 304.8 250.3 304.6C250 304.3 251.6 301.8 253.7 298.8C260.3 289.7 290 246.2 290 245.6C290 245.3 242.4 245 184.2 245H78.2998L77.6998 262.3C77.2998 271.7 76.9998 291 76.9998 305.1C76.9998 325.6 77.2998 331 78.3998 332.6C79.7998 334.4 81.8998 334.5 151.1 334.8C206 335 222.6 335.3 223.2 336.3C224.5 338.4 224.2 407.4 222.8 408.8C221.3 410.3 4.39984 410.5 1.99984 409Z"
        fill="currentColor"
      />
      <path
        d="M591.2 408.8C589.5 407.1 589.5 337.9 591.2 336.2C592.1 335.3 609.3 335 662.6 335C701.2 335 733.5 334.7 734.4 334.4C735.8 333.9 736 328.9 736 288.9C736 264.2 735.9 243.9 735.8 243.8C735.6 243.7 721 243.3 703.3 243C674.1 242.6 670.9 242.3 669.3 240.7C667.6 239.1 667.5 236.2 667.5 204.4V169.8L669.9 167.9C672.1 166.1 674.2 166 702.9 166C719.7 166 734 165.6 734.7 165.2C736.4 164.1 737 79.6 735.2 77.8C734.2 76.8 723.7 76.4 686.9 76C646.6 75.6 639.8 75.3 639 74C638.4 73.1 638 58.1 638 38.3C638 5.19998 638.1 3.89997 640 1.99997C642 -2.55602e-05 642.9 -2.56019e-05 727.1 0.199974C801.3 0.499974 812.2 0.699974 813 1.99997C814.2 3.89997 813.9 407.5 812.7 408.7C812.2 409.1 762.5 409.6 702.1 409.8C617 410 592.2 409.8 591.2 408.8Z"
        fill="currentColor"
      />
    </Icon>
  );
}
