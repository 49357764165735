import { Box, Button, Text } from "@chakra-ui/react";
import { dropdownSimNao } from "components/atendimentos-components/atendimento-form/fields-data";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import { useState } from "react";
import { useEventListener, makeEvent } from "services/events";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { PriceButton } from ".";

interface ModalDataProps {
  onConfirm?: ({
    possuiRecorrencia,
    cobranca,
  }: {
    possuiRecorrencia: boolean;
    cobranca: "MENSAL" | "AVULSO";
  }) => void;
  modalTitle: string;
}

export function ModalAtivoStatus() {
  const [isOpen, setIsOpen] = useState(false);
  const [cobranca, setCobranca] = useState<"MENSAL" | "AVULSO">();
  const [possuiRecorrencia, setRecorrente] = useState(false);
  const [modalData, setModalData] = useState<Partial<ModalDataProps>>({});
  const [isLoading, setIsLoading] = useState(false);

  const onOpen = (modalData: ModalDataProps) => {
    setIsOpen(true);
    setModalData(modalData);
  };
  const onClose = () => {
    setIsOpen(false);
    setCobranca(undefined);
    setRecorrente(false);
    setModalData({});
    setIsLoading(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      await modalData.onConfirm?.({ possuiRecorrencia, cobranca: cobranca! });
    } finally {
      onClose();
    }
  };

  useEventListener("openModalAtivoStatus", onOpen);

  const modalFooter = (
    <>
      <Button
        variant="outline"
        leftIcon={<CloseIcon w="12px" h="12px" />}
        onClick={onClose}
      >
        Cancelar
      </Button>
      <Button
        onClick={handleSubmit}
        isLoading={isLoading}
        loadingText="Confirmando"
        leftIcon={<CheckIcon />}
        isDisabled={cobranca == null}
      >
        Confirmar
      </Button>
    </>
  );

  return (
    <CustomModal
      modalTitle={modalData.modalTitle}
      modalFooter={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box w="100%">
        <Box mb="10px" w="100%">
          <Text mb="8px" fontWeight="semibold">
            Cobrança:
          </Text>
          <Dropdown
            options={[
              { name: "MENSAL", value: "MENSAL" },
              { name: "AVULSO", value: "AVULSO" },
            ]}
            value={cobranca}
            onChange={(value) => {
              setCobranca(value);
            }}
            variant="outline-custom"
            w="100%"
          />
        </Box>
        {cobranca === "MENSAL" ? (
          <Box w="100%">
            <Text mb="8px" fontWeight="semibold">
              Recorrente:
            </Text>
            <Dropdown
              options={dropdownSimNao}
              value={possuiRecorrencia}
              onChange={(value) => {
                setRecorrente(value);
              }}
              variant="outline-custom"
              w="100%"
            />
          </Box>
        ) : null}

        {cobranca == null ? null : cobranca === "MENSAL" ? (
          <PriceButton mt="40px">R$ 19,90 mensal</PriceButton>
        ) : (
          <PriceButton mt="40px">R$ 0,05 por consulta</PriceButton>
        )}
      </Box>
    </CustomModal>
  );
}

export const openModalAtivoStatus = (modalData: ModalDataProps) =>
  makeEvent("openModalAtivoStatus", modalData);
