import { Flex, Text } from "@chakra-ui/react";

export function NotFound() {
  return (
    <Flex w="100%" h="100vh" justifyContent="center" alignItems="center">
      <Text fontSize="16">
        <Text as="span" fontWeight="bold">
          404
        </Text>{" "}
        Not Found
      </Text>
    </Flex>
  );
}
