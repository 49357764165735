export function phoneFormat(
  phoneNumber: string | null | undefined,
  options?: { withCountryNumber: boolean }
) {
  const withOutNine = phoneNumber?.length === 12;
  if (withOutNine)
    if (options?.withCountryNumber)
      return phoneNumber.replace(
        /(\d{2})(\d{2})(\d{4})(\d{4})/,
        "+$1 ($2) $3-$4"
      );
    else
      return phoneNumber.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/, "($2) $3-$4");
  if (phoneNumber)
    if (options?.withCountryNumber)
      return phoneNumber.replace(
        /(\d{2})(\d{2})(\d{5})(\d{4})/,
        "+$1 ($2) $3-$4"
      );
    else
      return phoneNumber.replace(/(\d{2})(\d{2})(\d{5})(\d{4})/, "($2) $3-$4");
}

export const maskCEPFormat = (value: string | undefined) => {
  value = value?.replace(/\D/g, "") ?? "";
  const format = customMask(value, {
    match: /(\d{0,5})(\d{0,3})/,
    separators: ["-"],
  });
  return { raw: format.replace(/\D/g, ""), value: format };
};

export const maskDate = (value: string) => {
  const raw = value.split("-").reverse().join("/");
  const mask = value.split("/").reverse().join("-");
  return { raw, mask };
};

export function maskPhone(value: string | undefined) {
  if (!value) return "";
  let format = value
    .replace(/\D/g, "")
    .match(/(\d{0,2})(\d{0,5})(\d{0,4})/) as RegExpMatchArray;
  return !format[2]
    ? format[1]
    : "(" + format[1] + ") " + format[2] + (format[3] ? "-" + format[3] : "");
}

export function phoneFormatMask(v: string | undefined) {
  return {
    value: maskPhone(v),
    raw: v?.replace(/\D/g, "") || "",
  };
}

export function maskPhoneFormat(value: string | undefined) {
  if (!value) value = "";
  let format = value
    .replace(/\D/g, "")
    .match(/(\d{0,2})(\d{0,5})(\d{0,4})/) as RegExpMatchArray;
  return {
    parts: format,
    formatedValue: !format[2]
      ? format[1]
      : "(" + format[1] + ") " + format[2] + (format[3] ? "-" + format[3] : ""),
    onlyNumbers: value.replace(/\D/g, ""),
  };
}
export const onKeyDownOmitNegativeNumbers = (
  evt: React.KeyboardEvent<HTMLInputElement>
) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault();

export const onlyNumberValidMask = (
  value: string | number | null | undefined
) => {
  if (value == null) value = "";
  let parse = "";
  if (typeof value === "string") parse = value?.replace?.(",", ".");
  if (typeof value === "number") parse = `${value}`;
  return { value: parse, raw: parse };
};

export function maskCPF(value: string | undefined | null) {
  // 123.123.1232-12
  if (!value) return "";
  const onlyNumbers = value.replace(/\D/g, "");
  let format = onlyNumbers.match(
    /(\d{0,3})(\d{0,3})(\d{0,3})(\d{0,2})/
  ) as RegExpMatchArray;
  const separators = [".", ".", ".", "-"];

  return format
    .map((part, i) => {
      if (i === 0) return "";
      const sep = format[i + 1] ? separators[i] ?? "" : "";
      return part + sep;
    })
    .join("");
}

export function maskCPFFormat(value: string | undefined | null) {
  const result = maskCPF(value);
  return { raw: result.replace(/\D/g, ""), value: result };
}

export function cnpjMask(value: string | undefined) {
  value = value?.replace(/\D/g, "") ?? "";
  const formated = customMask(value, {
    match: /(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/,
    separators: [".", ".", "/", "-"],
  });
  return { raw: formated.replace(/\D/g, ""), value: formated };
}
// 99.999.999/9999-99

export const customMask = (
  value: string,
  { match, separators }: { match: RegExp; separators: string[] }
) => {
  const f = value.match(match);
  const result = f
    ?.map((part, i) => {
      if (i === 0) return "";
      const sep = f[i + 1] ? separators[i - 1] ?? "" : "";
      return part + sep;
    })
    .join("");
  return result ?? "";
};

export function cpfFormat(cpfNumber: string) {
  if (cpfNumber)
    return cpfNumber.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
}

export function statusFormat(status: string) {
  if (status) return status.toLowerCase().replace("_", " ");
}

export function currencyMask(value: string) {
  if (value === "") value = "0,00";
  value = value.replace(".", "").replace(",", "").replace(/\D/g, "");
  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat("pt-BR", options).format(
    parseFloat(value) / 100
  );
  return result;
}

export const calcBirthday = (data: string) => {
  if (data.indexOf("/") != -1) data = data.split("/").reverse().join("-");
  const today = new Date();
  const birthDate = new Date(data);
  let age = today.getFullYear() - birthDate.getUTCFullYear();
  const month = today.getMonth() - birthDate.getUTCMonth();
  const day = today.getDate() - birthDate.getUTCDate();

  if (month < 0) age -= 1;
  else if (month === 0 && day < 0) age -= 1;
  return `${age} anos`;
};

export function currencyMaskFormat(value: string | number | undefined) {
  if (value == null) value = "";
  if (typeof value === "number") value = value.toFixed(2);
  if (!value) value = "0,00";
  value = value.replace(".", "").replace(",", "").replace(/\D/g, "");
  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat("pt-BR", options).format(
    parseFloat(value) / 100
  );
  return {
    value: result,
    raw: Number(result.replace(/\./g, "").replace(",", ".")),
  };
}

export function currencyFloatMaskFormat(
  value: string | number | undefined,
  max: number = 1,
  fractionDigits: number = 5
) {
  if (value == null) value = "";
  if (typeof value === "number") value = value.toFixed(fractionDigits);
  if (!value) value = "0,00000";
  if (typeof value === "string") {
    value = value.replace(".", "").replace(",", "").replace(/\D/g, "");
    let divider: string | number = "1";
    for (let i = 0; i < fractionDigits; i++) divider = divider + "0";
    divider = Number(divider);
    const options = { minimumFractionDigits: fractionDigits };
    let result = new Intl.NumberFormat("pt-BR", options).format(
      parseFloat(value) / divider
    );
    let parseNumber = Number(result.replace(/\./g, "").replace(",", "."));
    if (max && parseNumber > max) {
      parseNumber = max;
      result = max.toFixed(fractionDigits).replace(".", ",");
    }
    return { value: result, raw: parseNumber };
  }
  return { value: "", raw: "" };
}

export function integerInputFormat(value: string | number | undefined) {
  if (typeof value === "number") return { value: `${value}`, raw: value };
  else {
    value = value?.replace(/\D/g, "").replace(".", "").replace(",", "");
    return {
      value: value ?? "",
      raw: value ? Number(value) : undefined,
    };
  }
}

export function formatFileSize(size: number) {
  const k = 1024;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(size) / Math.log(k));
  const formatSize =
    parseFloat((size / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  const sizeFloat = parseFloat(formatSize.split(" ")[0]);
  const sizeType = formatSize.split(" ")[1];
  return `${sizeFloat} ${sizeType}`;
}

export function numberMask(value: string) {
  // if(isNaN(parseFloat(value)))

  return value.replace(",", ".").trim();
}

export const removeLastChar = (v: string) => v.slice(0, v.length - 1);

function percentualMaskOnKeyDown(
  e: React.KeyboardEvent<HTMLInputElement>,
  onChangeState: (value: string) => void
) {
  const value = e.currentTarget.value;
  const onlyNumbers = value.replace(/\D/g, "");
  if (e.key === "Backspace") {
    const removedLast = removeLastChar(onlyNumbers);
    e.currentTarget.value = removedLast ? `${removedLast}%` : "";
    if (!removedLast) {
      e.preventDefault();
      onChangeState("");
    }
  }
}

function percentualMask(currentValue: string | null | undefined | number) {
  const raw = `${currentValue}`?.replace(/\D/g, "");
  const value = `${raw}%`;
  if (!raw) return { raw: "", value: "" };
  return { raw, value };
}

export function percentualMaskKit() {
  return { onKeyDown: percentualMaskOnKeyDown, mask: percentualMask };
}

export function percentageMask(value: string) {
  value = value.trim().replace(",", ".");
  return value.length ? (value.indexOf("%") !== -1 ? value : value + "%") : "";
}

export const formatData = (data: string) => {
  if (data?.indexOf("/") === -1) {
    let toArr = data?.split("-");
    let parseData = null;
    if (toArr?.[0].length === 4) parseData = toArr.reverse().join("/");
    return parseData;
  }
  return data;
};

export const limitDigitsMask =
  (limit: number, parse?: (v: string) => any) =>
  (value: string | number | null | undefined) => {
    if (typeof value === "number") value = value.toFixed();
    if (!value) value = "";
    const format = value.slice(0, limit);
    return { raw: parse?.(format) ?? format, value: format };
  };
