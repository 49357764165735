import { CloseIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import api from "api/api";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { CustomModal } from "components/custom-modal";
import { SkeletonLoading } from "components/skeleton-loading";
import { Toast } from "components/toast";
import { useState } from "react";
import { makeEvent, useEventListener } from "services/events";

interface MsgProps {
  mensagem: string;
}

export function ModalVerMsgUra() {
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState<MsgProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const onOpen = (attendance: Attendance) => {
    setIsOpen(true);
    findMsgUra(attendance);
  };
  const onClose = () => {
    setIsOpen(false);
    setData([]);
    setIsLoading(false);
  };

  useEventListener("openModalVerMsgUra", onOpen);

  const findMsgUra = async (attendance: Attendance) => {
    setIsLoading(true);
    try {
      let { data }: { data: MsgProps[] } = await api.get(
        `/atendimentos/${attendance.id}/mensagem-ura`
      );

      setData(data);
    } catch (e: any) {
      Toast({ title: "Erro ao obter mensagens URA", status: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const modalFooter = (
    <>
      <Button
        onClick={onClose}
        variant={"outline"}
        size="sm"
        leftIcon={<CloseIcon w="12px" h="12px" />}
      >
        Fechar
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalFooter={modalFooter}
      modalTitle="Mensangens URA"
    >
      <Box maxH="200px" overflow="auto">
        {isLoading ? (
          <SkeletonLoading
            w="100%"
            h="100px"
            isLoading={isLoading}
            borderRadius="5px"
          />
        ) : (
          <OrderedList>
            {data.map(({ mensagem }: MsgProps, i) => {
              return (
                <ListItem mb="5px" fontWeight="medium">
                  <Text key={i}>{mensagem}</Text>
                </ListItem>
              );
            })}
          </OrderedList>
        )}
      </Box>
    </CustomModal>
  );
}

export const openModalVerMsgUra = (attendance: Attendance) =>
  makeEvent("openModalVerMsgUra", attendance);
