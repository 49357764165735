import { normalizeText } from "utils/filter-array-by";

export interface MailingField {
  name: string;
  field: string;
  isVisible: (p: string) => boolean;
  csvField?: string | string[];
  type?: "multi-select";
}
export interface MailingFields {
  title: string;
  fields: MailingField[];
}

export const uploadMailingFields: MailingFields[] = [
  {
    title: "Selecione as Colunas de Dados Cadastrais:",
    fields: [
      {
        name: "Benefício",
        field: "colunaBeneficio",
        csvField: "BENEFICIO",
        isVisible: (convenio) => ["INSS", "GOV", "PREF"].includes(convenio),
      },
      {
        name: "Matrícula",
        field: "colunaMatricula",
        csvField: "BENEFICIO",
        isVisible: (convenio) =>
          convenio === "SIAPE" || convenio === "EXERCITO",
      },
      {
        name: "CPF",
        field: "colunaCPF",
        csvField: "CPF",
        isVisible: (convenio) => true,
      },
      {
        name: "Telefones",
        field: "colunasTelefones",
        csvField: ["TELEFONE_HIGIENIZADO_1"],
        type: "multi-select",
        isVisible: (convenio) => true,
      },
      {
        name: "Nome",
        field: "colunaNome",
        csvField: "NOME",
        isVisible: (convenio) => true,
      },
      {
        name: "Data Nascimento",
        field: "colunaDataNascimento",
        csvField: "DATA_NASCIMENTO",
        isVisible: (convenio) => true,
      },
      {
        name: "Sexo",
        field: "colunaSexo",
        csvField: "SEXO",
        isVisible: (convenio) => true,
      },
      {
        name: "Naturalidade",
        field: "colunaNaturalidade",
        csvField: "NATURALIDADE",
        isVisible: (convenio) => true,
      },
      {
        name: "UF Naturalidade",
        field: "colunaUFNaturalidade",
        csvField: "UF_NATURALIDADE",
        isVisible: (convenio) => true,
      },
      {
        name: "Documento",
        field: "colunaDocumento",
        csvField: "DOCUMENTO",
        isVisible: (convenio) => true,
      },
      {
        name: "Órgão Emissor Doc.",
        field: "colunaOrgaoEmissorDoc",
        csvField: "ORGAO_EMISSOR_DOC",
        isVisible: (convenio) => true,
      },
      {
        name: "UF Emissão Doc.",
        field: "colunaUFEmissaoDoc",
        csvField: "UF_EMISSAO_DOC",
        isVisible: (convenio) => true,
      },
      {
        name: "Data Emissão Doc.",
        field: "colunaDataEmissaoDoc",
        csvField: "DATA_EMISSAO_DOC",
        isVisible: (convenio) => true,
      },
      {
        name: "Nome da mãe",
        field: "colunaNomeMae",
        csvField: "NOME_MAE",
        isVisible: (convenio) => true,
      },
      {
        name: "Nome do pai",
        field: "colunaNomePai",
        csvField: "NOME_PAI",
        isVisible: (convenio) => true,
      },
      {
        name: "CEP",
        field: "colunaCEP",
        csvField: "CEP",
        isVisible: (convenio) => true,
      },
      {
        name: "Endereço",
        field: "colunaEndereco",
        csvField: "ENDERECO",
        isVisible: (convenio) => true,
      },
      {
        name: "Número",
        field: "colunaNumero",
        csvField: "NUMERO",
        isVisible: (convenio) => true,
      },
      {
        name: "Complemento",
        field: "colunaComplemento",
        csvField: "COMPLEMENTO",
        isVisible: (convenio) => true,
      },
      {
        name: "Bairro",
        field: "colunaBairro",
        csvField: "BAIRRO",
        isVisible: (convenio) => true,
      },
      {
        name: "Município",
        field: "colunaMunicipio",
        csvField: "MUNICIPIO",
        isVisible: (convenio) => true,
      },
      {
        name: "UF",
        field: "colunaUF",
        csvField: "UF",
        isVisible: (convenio) => true,
      },
      {
        name: "E-mail",
        field: "colunaEmail",
        csvField: "EMAIL",
        isVisible: (convenio) => true,
      },
      {
        name: "Valor Benefício (MR)",
        field: "colunaValorBeneficio",
        csvField: "VALOR_BENEFICIO",
        isVisible: (convenio) => true,
      },
      {
        name: "Cod. Espécie Benefício",
        field: "colunaCodigoEspecie",
        csvField: "CODIGO_ESPECIE",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "DDB",
        field: "colunaDDB",
        csvField: "DDB",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "UF Benefício",
        field: "colunaUFBeneficio",
        csvField: "UF_BENEFICIO",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Situação Benefício",
        field: "colunaSituacaoBeneficio",
        csvField: "SITUACAO_BENEFICIO",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Meio de Pagamento",
        field: "colunaMeioPagamento",
        csvField: "MEIO_PAGAMENTO",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Cód. Banco Pagamento",
        field: "colunaCodigoBancoPagamento",
        csvField: "CODIGO_BANCO_PAGAMENTO",
        isVisible: (convenio) => true,
      },
      {
        name: "Agência Pagamento",
        field: "colunaAgenciaPagamento",
        csvField: "AGENCIA_PAGAMENTO",
        isVisible: (convenio) => true,
      },
      {
        name: "DV Agência Pagamento",
        field: "colunaAgenciaPagamentoDV",
        csvField: "AGENCIA_PAGAMENTO_DV",
        isVisible: (convenio) => true,
      },
      {
        name: "Conta Pagamento",
        field: "colunaContaPagamento",
        csvField: "CONTA_PAGAMENTO",
        isVisible: (convenio) => true,
      },
      {
        name: "DV Conta Pagamento",
        field: "colunaContaPagamentoDV",
        csvField: "CONTA_PAGAMENTO_DV",
        isVisible: (convenio) => true,
      },
      {
        name: "Valor Parcelas",
        field: "colunaValorParcelas",
        csvField: "VALOR_PARCELAS",
        isVisible: (convenio) => convenio === "INSS" || convenio === "SIAPE",
      },
      {
        name: "Valor Empréstimos",
        field: "colunaValorEmprestimos",
        csvField: "VALOR_EMPRESTIMOS",
        isVisible: (convenio) => convenio === "INSS" || convenio === "SIAPE",
      },
      {
        name: "Margem 35",
        field: "colunaMargem35",
        csvField: "MARGEM_35",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Margem Aumento",
        field: "colunaMargemAumento",
        csvField: "MARGEM_AUMENTO_2025",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Total Contratos",
        field: "colunaTotalContratos",
        csvField: "TOTAL_CONTRATOS",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Possui Cartão RMC",
        field: "colunaPossuiCartaoRMC",
        csvField: "POSSUI_CARTAO_RMC",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Possui Cartão RCC",
        field: "colunaPossuiCartaoRCC",
        csvField: "POSSUI_CARTAO_RCC",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Margem Cartão",
        field: "colunaMargemCartao",
        csvField: "MARGEM_CARTAO_RMC",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Possui Representante Legal",
        field: "colunaPossuiRepresentanteLegal",
        csvField: "POSSUI_REPRESENTANTE_LEGAL",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "CPF Representante Legal",
        field: "colunaCPFRepresentanteLegal",
        csvField: "CPF_REPRESENTANTE_LEGAL",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Nome Representante Legal",
        field: "colunaNomeRepresentanteLegal",
        csvField: "NOME_REPRESENTANTE_LEGAL",
        isVisible: (convenio) => convenio === "INSS",
      },
      {
        name: "Não Perturbe",
        field: "colunaNaoPerturbe",
        csvField: "NAO_PERTURBE",
        isVisible: (convenio) => true,
      },
    ],
  },
  {
    title: "Selecione as Colunas de Contratos:",
    fields: [
      {
        name: "Código Contrato",
        field: "colunaCodigoContrato",
        isVisible: (convenio) => true,
      },
      {
        name: "Código Banco Contrato",
        field: "colunaCodigoBancoContrato",
        isVisible: (convenio) => true,
      },
      {
        name: "Valor Emprestimo",
        field: "colunaValorEmprestimo",
        isVisible: (convenio) => true,
      },
      {
        name: "Início Desconto",
        field: "colunaInicioDesconto",
        isVisible: (convenio) => true,
      },
      {
        name: "Final Desconto",
        field: "colunaFinalDesconto",
        isVisible: (convenio) => true,
      },
      {
        name: "Prazo",
        field: "colunaPrazo",
        isVisible: (convenio) => true,
      },
      {
        name: "Valor Parcela",
        field: "colunaValorParcela",
        isVisible: (convenio) => true,
      },
      {
        name: "Tipo Empréstimo",
        field: "colunaTipoEmprestimo",
        isVisible: (convenio) => true,
      },
      {
        name: "Data Averbação",
        field: "colunaDataAverbacao",
        isVisible: (convenio) => true,
      },
      {
        name: "Taxa",
        field: "colunaTaxa",
        isVisible: (convenio) => true,
      },
      {
        name: "Parcelas Restantes",
        field: "colunaParcelasRestantes",
        isVisible: (convenio) => true,
      },
      {
        name: "Parcelas Pagas",
        field: "colunaParcelasPagas",
        isVisible: (convenio) => true,
      },
      {
        name: "Saldo Devedor",
        field: "colunaSaldoDevedor",
        isVisible: (convenio) => true,
      },
    ],
  },
];

export type TipoOperacaoMailing =
  | "NOVO"
  | "CARTAO_RMC_NOVO"
  | "CARTAO_RCC_NOVO"
  | "SAQUE_RMC"
  | "SAQUE_RCC"
  | "REFIN_CARTEIRA"
  | "PORT"
  | "PORT_COM_REFIN_DA_PORT";

export const dropdownTipoOperacaoMailing: {
  name: string;
  value: TipoOperacaoMailing;
}[] = [
  { name: "Novo", value: "NOVO" },
  { name: "Cartão RMC novo", value: "CARTAO_RMC_NOVO" },
  { name: "Cartão RCC novo", value: "CARTAO_RCC_NOVO" },
  { name: "Saque RMC", value: "SAQUE_RMC" },
  { name: "Saque RCC", value: "SAQUE_RCC" },
  { name: "Refin. carteira", value: "REFIN_CARTEIRA" },
  { name: "Portabilidade", value: "PORT" },
  {
    name: "Port. com refin. da port.",
    value: "PORT_COM_REFIN_DA_PORT",
  },
];

export function compareHeaders(
  headers: string[],
  onChangeValues: (field: string, value: any) => void
) {
  uploadMailingFields
    .reduce((acc, currentFieldsGroup) => {
      acc = [
        ...acc,
        ...currentFieldsGroup.fields
          .filter((c) => c.csvField)
          .map(({ csvField, name, field }) => ({
            csvField: csvField!,
            field,
            name,
          })),
      ];
      return acc;
    }, [] as { name: string; csvField: string | string[]; field: string }[])
    .forEach((fieldData) => {
      const selectedHeader = headers.find(
        (currHeader) =>
          Array.isArray(fieldData.csvField)
            ? fieldData.csvField.includes(currHeader.toUpperCase())
            : currHeader.toUpperCase() === fieldData.csvField.toUpperCase()
        // fieldData.csvField.includes(normalizeText(currHeader))
        // : normalizeText(currHeader) === normalizeText(fieldData.csvField)
      );
      const isPresent = !!selectedHeader;

      if (isPresent) {
        onChangeValues(fieldData.field, fieldData.csvField);
      }
    });
}
