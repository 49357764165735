import { Center, Flex, Grid, Text } from "@chakra-ui/react";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useAttendanceContext } from "contexts/attendance-context";
import { AttendanceTabKey } from "..";
import { CardEmprestimoBancario } from "./contratos/contrato-emprestimo-bancario";
import { valorLiberadoCliente } from "./utils";
import {
  bancosContratos,
  parseEnumbancoToCodBanco,
} from "components/atendimentos-components/atendimento-form/contracts-table/consts";
import { colorsMap } from "../../attendance-field/attendance-input";

export function OportunidadesAtendimentoFormPart({
  isLoading,
  setIsLoading,
  activeTab,
}: {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  activeTab: AttendanceTabKey;
}) {
  const { formControl, dispatch } = useAttendanceContext();
  const formValues = formControl.values;
  const filteredContractsEmprestimos =
    formValues.contratosEmprestimosInss?.filter(
      ({ oportunidade }) => oportunidade
    ) || [];

  const filteredContractsRCC =
    formValues.contratosRCCInss?.filter(({ oportunidade }) => oportunidade) ||
    [];
  const filteredContractsRMC =
    formValues.contratosRMCInss?.filter(({ oportunidade }) => oportunidade) ||
    [];
  const parseTagText = new Map([
    ["API_BANCO_CARTAO", "CARTÃO ATUALIZADO"],
    ["DATAFAST", "REFIN BANCO"],
    ["CONSULTA OFFLINE", ""],
    ["EXTRATO_ONLINE", "EXTR. ONLINE"],
    ["IN100", "IN100"],
  ]);

  const onChangeInput = useCallback(
    (fieldName: keyof Attendance) => (value: string) => {
      dispatch({
        type: "changeField",
        payload: { fieldName, data: value },
      });
      dispatch({
        type: "setInvalidField",
        payload: { fieldName, action: "clear" },
      });
    },
    []
  );

  const onChangeDropdown = useCallback(
    (fieldName: keyof Attendance) => (newValue: any) => {
      dispatch({
        type: "changeField",
        payload: { fieldName, data: newValue },
      });
      dispatch({
        type: "setInvalidField",
        payload: { fieldName, action: "clear" },
      });
    },
    []
  );

  return (
    <>
      <Grid templateColumns="1fr" gap="10px">
        {formValues.margens?.oportunidadeCreditoPessoal ? (
          <Flex
            flexDir="column"
            border="1px solid #E8EAED"
            bg="#F5F7F9"
            p="10px 15px"
            borderRadius="5px"
            _hover={{ bg: "gray.100" }}
            transition="0.2s"
            cursor="pointer"
          >
            <Text mb="15px" fontSize="18" fontWeight="bold">
              EMPRÉSTIMO PESSOAL
            </Text>
            <Grid templateColumns="1fr 1fr 1fr 1fr" gap="10px">
              <Text>
                <b>Banco:</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {
                    bancosContratos[
                      parseEnumbancoToCodBanco[
                        formValues.margens?.bancoCreditoPessoal
                      ] ?? ""
                    ]
                  }
                </Text>
              </Text>
              <Text>
                <b>Valor crédito:</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.valorCreditoPessoal}
                </Text>
              </Text>
              <Text>
                <b>Prazo:</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.prazoCreditoPessoal}
                </Text>
              </Text>
              <Text>
                <b>Valor liberado ao cliente:</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.valorLiberadoClienteCreditoPessoal}
                </Text>
              </Text>
            </Grid>
          </Flex>
        ) : null}

        {filteredContractsRMC.map((emprestimo) => {
          return (
            <Flex
              key={emprestimo.contrato}
              flexDir="column"
              border="1px solid #E8EAED"
              bg="#F5F7F9"
              p="10px 15px"
              borderRadius="5px"
            >
              <Flex alignItems="flex-start">
                <Text mb="15px" fontSize="18" fontWeight="bold">
                  CONTRATO RMC
                </Text>
                {emprestimo.origem ? (
                  <Center
                    p="2px 5px"
                    fontSize="11"
                    borderRadius="5px"
                    bg={colorsMap.get(emprestimo.origem)}
                    color="#fff"
                    ml="auto"
                    mb="8px"
                  >
                    {parseTagText.get(emprestimo.origem)}
                  </Center>
                ) : null}
              </Flex>
              <Grid templateColumns="1fr 1fr 1fr 1fr" gap="10px">
                <Text>
                  <b>Banco:</b> {bancosContratos[emprestimo.codBanco]}
                </Text>
                <Text>
                  <b>Contrato:</b> {emprestimo.contrato}
                </Text>
                <Text>
                  <b>Limite cartão:</b> R${" "}
                  {emprestimo.limiteCartao ? emprestimo.limiteCartao : "--"}
                </Text>
                <Text>
                  <b>Início Contrato:</b> {emprestimo.dataInicioContrato}
                </Text>
                <Text>
                  <b>Valor Reservado:</b>{" "}
                  <Text as="span"> R$ {emprestimo.valorReservado ?? "--"}</Text>
                </Text>
                <Text>
                  <b>Valor Saque Máximo:</b>{" "}
                  <Text as="span">
                    {" "}
                    R$ {emprestimo.valorSaqueMaximo ?? "--"}
                  </Text>
                </Text>
                <Text>
                  <b>Valor Saque Minimo:</b>{" "}
                  <Text as="span">
                    {" "}
                    R$ {emprestimo.valorSaqueMinimo ?? "--"}
                  </Text>
                </Text>
                <Text>
                  <b>Situação:</b>{" "}
                  <Text
                    as="span"
                    p="4px"
                    borderRadius="5px"
                    color={emprestimo.situacao === "Ativo" ? "#00cc00" : ""}
                  >
                    {emprestimo.situacao}
                  </Text>
                </Text>
                <Text>
                  <b>Observação:</b>{" "}
                  <Text as="span" whiteSpace="pre-wrap">
                    {emprestimo.observacao}
                  </Text>
                </Text>
              </Grid>
            </Flex>
          );
        })}
        {filteredContractsRCC.map((emprestimo) => {
          return (
            <Flex
              key={emprestimo.contrato}
              flexDir="column"
              border="1px solid #E8EAED"
              bg="#F5F7F9"
              p="10px 15px"
              borderRadius="5px"
            >
              <Flex alignItems="flex-start">
                <Text mb="15px" fontSize="18" fontWeight="bold">
                  CONTRATO RCC
                </Text>
                {emprestimo.origem ? (
                  <Center
                    p="2px 5px"
                    fontSize="11"
                    borderRadius="5px"
                    bg={colorsMap.get(emprestimo.origem)}
                    color="#fff"
                    ml="auto"
                    mb="8px"
                  >
                    {parseTagText.get(emprestimo.origem)}
                  </Center>
                ) : null}
              </Flex>
              <Grid templateColumns="1fr 1fr 1fr 1fr" gap="10px">
                <Text>
                  <b>Banco:</b> {bancosContratos[emprestimo.codBanco]}
                </Text>
                <Text>
                  <b>Contrato:</b> {emprestimo.contrato}
                </Text>
                <Text>
                  <b>Limite cartão:</b> R${" "}
                  {emprestimo.limiteCartao ? emprestimo.limiteCartao : "--"}
                </Text>
                <Text>
                  <b>Início Contrato:</b> {emprestimo.dataInicioContrato}
                </Text>
                <Text>
                  <b>Valor Reservado:</b>{" "}
                  <Text as="span"> R$ {emprestimo.valorReservado ?? "--"}</Text>
                </Text>
                <Text>
                  <b>Valor Saque Máximo:</b>{" "}
                  <Text as="span">
                    {" "}
                    R$ {emprestimo.valorSaqueMaximo ?? "--"}
                  </Text>
                </Text>
                <Text>
                  <b>Valor Saque Minimo:</b>{" "}
                  <Text as="span">
                    {" "}
                    R$ {emprestimo.valorSaqueMinimo ?? "--"}
                  </Text>
                </Text>
                <Text>
                  <b>Situação:</b>{" "}
                  <Text
                    as="span"
                    p="4px"
                    borderRadius="5px"
                    color={emprestimo.situacao === "Ativo" ? "#00cc00" : ""}
                  >
                    {emprestimo.situacao}
                  </Text>
                </Text>
                <Text>
                  <b>Observação:</b>{" "}
                  <Text as="span" whiteSpace="pre-wrap">
                    {emprestimo.observacao}
                  </Text>
                </Text>
              </Grid>
            </Flex>
          );
        })}

        {formValues.margens?.oportunidadeRMC ? (
          <Flex
            flexDir="column"
            border="1px solid #E8EAED"
            bg="#F5F7F9"
            p="10px 15px"
            borderRadius="5px"
            _hover={{ bg: "gray.100" }}
            transition="0.2s"
            cursor="pointer"
          >
            <Text mb="15px" fontSize="18" fontWeight="bold">
              CARTÃO NOVO RMC
            </Text>
            <Grid templateColumns="1fr 1fr 1fr 1fr" gap="10px">
              <Text>
                <b>Valor margem cartão (RMC):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.valorMargemCartaoRMC}
                </Text>
              </Text>
              <Text>
                <b>Possui cartão (RMC):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.possuiCartaoRmc ? "SIM" : "NÃO"}
                </Text>
              </Text>
              <Text>
                <b>Multiplicador (RMC):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.multiplicadorRMC}
                </Text>
              </Text>
              <Text>
                <b>Banco (RMC):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.bancoRMC}
                </Text>
              </Text>
              <Text>
                <b>Valor limite cartão (RMC):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.valorLimiteCartaoRMC}
                </Text>
              </Text>
              <Text>
                <b>Porcentagem saque (RMC):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.porcentagemSaqueRMC}
                </Text>
              </Text>
              <Text gridArea="auto / span 2">
                <b>Observação (RMC):</b>{" "}
                <Text as="span" whiteSpace="pre-wrap">
                  {formValues.margens?.observacaoRMC}
                </Text>
              </Text>
            </Grid>
          </Flex>
        ) : null}

        {formValues.margens?.oportunidadeRCC ? (
          <Flex
            flexDir="column"
            border="1px solid #E8EAED"
            bg="#F5F7F9"
            p="10px 15px"
            borderRadius="5px"
            _hover={{ bg: "gray.100" }}
            transition="0.2s"
            cursor="pointer"
          >
            <Text mb="15px" fontSize="18" fontWeight="bold">
              CARTÃO NOVO RCC
            </Text>

            <Grid templateColumns="1fr 1fr 1fr 1fr" gap="10px">
              <Text>
                <b>Possui Cartão RCC:</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.possuiCartaoBeneficio ? "SIM" : "NÃO"}
                </Text>
              </Text>
              <Text>
                <b>Valor margem cartão (RCC):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.valorMargemCartaoRCC}
                </Text>
              </Text>
              <Text>
                <b>Multiplicador (RCC):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.multiplicadorRCC}
                </Text>
              </Text>
              <Text>
                <b>Banco (RCC):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.bancoRCC}
                </Text>
              </Text>
              <Text>
                <b>Valor limite cartão (RCC):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.valorLimiteCartaoRCC}
                </Text>
              </Text>
              <Text>
                <b>Porcentagem saque (RCC):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.porcentagemSaqueRCC}
                </Text>
              </Text>
              <Text>
                <b>Valor saque cartão (RCC):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.valorSaqueCartaoRCC}
                </Text>
              </Text>
              <Text gridArea="auto / span 2">
                <b>Observação (RCC):</b>{" "}
                <Text as="span" whiteSpace="pre-wrap">
                  {formValues.margens?.observacaoRCC}
                </Text>
              </Text>
            </Grid>
          </Flex>
        ) : null}

        {formValues.margens?.oportunidadeMargemLivreNovo ? (
          <Flex
            flexDir="column"
            border="1px solid #E8EAED"
            bg="#F5F7F9"
            p="10px 15px"
            borderRadius="5px"
            _hover={{ bg: "gray.100" }}
            transition="0.2s"
            cursor="pointer"
          >
            <Text mb="15px" fontSize="18" fontWeight="bold">
              EMPRÉSTIMO NOVO
            </Text>
            <Grid templateColumns="1fr 1fr 1fr 1fr" gap="10px">
              <Text>
                <b>Margem livre (novo):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.valorMargemLivreNovo}
                </Text>
              </Text>
              <Text>
                <b>Banco:</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.bancoMargemLivreNovo}
                </Text>
              </Text>
              <Text>
                <b>Coeficiente (Novo):</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.coeficienteNovo}
                </Text>
              </Text>
              <Text>
                <b>Código Tabela:</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.codigoTabela}
                </Text>
              </Text>
              <Text>
                <b>Prazo:</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {formValues.margens?.prazoNovo}
                </Text>
              </Text>
              <Text>
                <b>Valor liberado ao cliente:</b>{" "}
                <Text as="span" whiteSpace="nowrap">
                  {valorLiberadoCliente(formValues.margens)}
                </Text>
              </Text>
            </Grid>
          </Flex>
        ) : null}

        {filteredContractsEmprestimos.map((emprestimo, index) => {
          return (
            <CardEmprestimoBancario
              dispatch={dispatch}
              emprestimo={emprestimo}
              emprestimoList={formValues.contratosEmprestimosInss || []}
              index={index}
              key={index}
              activeTab={activeTab}
            />
          );
        })}
      </Grid>
      {/* </Accordion> */}
      {/* <CustomTableColor
        columns={contractColumns({ dispatch, formValues, isNovoPrazo })}
        rows={filteredContracts}
        headerStyle={{ whiteSpace: "normal", fontSize: "12px" }}
      /> */}
    </>
  );
}
