import { fieldValidation } from "utils/field-validation";
import { ObjectSchema, objectSchema } from "utils/object-methods";
import {
  DigitacaoINSSProps,
  SimulacaoDataBMGProps,
  SimulacaoDataC6Props,
  SimulacaoDataFACTAProps,
  SimulacaoDataProps,
  TabelaProps,
  UsuarioBanco,
} from "./types";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { AttendanceActionsTypes } from "contexts/attendance-context/actions-reducer";
import { Dispatch } from "react";
import { openModalError } from "components/modal-error";
import { Box, Text } from "@chakra-ui/react";
import { SimulacaoResultProps } from "..";
import { maskPhoneFormat } from "utils/string-formats";

const setAttendanceError = (
  key: keyof Attendance,
  dispatch: Dispatch<AttendanceActionsTypes>
) => {
  dispatch({
    type: "setInvalidField",
    payload: { fieldName: key, action: "append" },
  });
};

export function validateSimulacaoFields(
  body: SimulacaoDataFACTAProps | SimulacaoDataC6Props | SimulacaoDataBMGProps,
  dispatch: Dispatch<AttendanceActionsTypes>
  // onClose: () => void
) {
  const banco = body.banco;
  const attendanceFieldErrors = new Map<string, { name: string }>([]);

  const schemaFACTA: ObjectSchema<SimulacaoDataFACTAProps> = {
    banco: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    tipoOperacao: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    cpf: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        setAttendanceError("cpf", dispatch);
        attendanceFieldErrors.set("cpf", { name: "CPF" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    birthDay: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        setAttendanceError("dataNascimento", dispatch);
        attendanceFieldErrors.set("dataNascimento", { name: "Nascimento" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    loginCertificado: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    vendedor: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    loginVendedor: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    term: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    codigoTabela: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    tabela: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    valueInstallment: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    valorRenda: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        setAttendanceError("valorSalario", dispatch);
        attendanceFieldErrors.set("valorSalario", { name: "Valor Salário" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    cpfRepresentanteLegal: (value) => {
      return { valid: true };
    },
    nomeRepresentanteLegal: (value) => {
      return { valid: true };
    },
  };

  const schemaBMG: ObjectSchema<SimulacaoDataBMGProps> = {
    banco: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    cpf: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        setAttendanceError("cpf", dispatch);
        attendanceFieldErrors.set("cpf", { name: "CPF" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    benefitNumber: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        setAttendanceError("nb", dispatch);
        attendanceFieldErrors.set("nb", { name: "Número Benefício" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    valueInstallment: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    birthDay: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        setAttendanceError("dataNascimento", dispatch);
        attendanceFieldErrors.set("dataNascimento", { name: "Nascimento" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    codeTable: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    term: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    margin: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    numberInstallments: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
  };
  const schemaC6: ObjectSchema<SimulacaoDataC6Props> = {
    banco: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    benefitNumber: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        setAttendanceError("nb", dispatch);
        attendanceFieldErrors.set("nb", { name: "Número Benefício" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    cpf: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        setAttendanceError("cpf", dispatch);
        attendanceFieldErrors.set("cpf", { name: "CPF" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    birthDay: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        setAttendanceError("dataNascimento", dispatch);
        attendanceFieldErrors.set("dataNascimento", { name: "Nascimento" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    incomeAmount: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      if (!isValid) {
        setAttendanceError("valorSalario", dispatch);
        attendanceFieldErrors.set("valorSalario", { name: "Valor Salário" });
      }
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    simulationType: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    formalizationSubtype: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    productTypeCode: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    valueInstallment: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    term: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    username: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    password: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    tableCode: (value) => {
      const { isValid } = fieldValidation({ value }).required();
      return { valid: isValid, message: "Preencha o campo corretamente" };
    },
    cpfCertifiedUser: (value, fullObject) => {
      const { isValid } = fieldValidation({ value }).required();
      if (fullObject.username && !isValid) {
        return { valid: isValid, message: "CPF do usuário não cadastrado" };
      } else {
        return { valid: isValid, message: "Preencha o campo corretamente" };
      }
    },
  };

  const schema = new Map([
    ["BMG", schemaBMG],
    ["FACTA", schemaFACTA],
    ["C6", schemaC6],
  ]).get(banco!);

  if (!schema) return { isValid: false, errors: [] };

  const result = objectSchema(schema, body);

  if (attendanceFieldErrors.size) {
    const fields = Array.from(attendanceFieldErrors.entries());
    // onClose();
    openModalError({
      title: "Revisar campos do atendimento",
      message: (
        <Box>
          <Text fontWeight="bold" mb="8px">
            Revise os campos:{" "}
          </Text>

          {fields.map(([key, { name }]) => {
            return <Text color="red.500">{name}</Text>;
          })}
        </Box>
      ),
    });
  }

  return result;
}

export const dropdownPrazo = [
  { name: "12", value: "12" },
  { name: "24", value: "24" },
  { name: "36", value: "36" },
  { name: "48", value: "48" },
  { name: "60", value: "60" },
  { name: "72", value: "72" },
  { name: "84", value: "84" },
];

export function getSimulacaoBodyType(
  attendance: Attendance,
  simulacao: SimulacaoDataProps,
  selectedUsuariobanco: UsuarioBanco,
  selectedTabela: Partial<TabelaProps>
) {
  const formatC6Data = (data: string) => {
    if (typeof data === "string") {
      data = data.split("/").reverse().join("-");
    }
    return data;
  };

  if (simulacao.banco === "FACTA") {
    const body: SimulacaoDataFACTAProps = {
      banco: "FACTA",
      tipoOperacao: "27",
      cpf: attendance.cpf!,
      birthDay: attendance.dataNascimento!,
      loginCertificado: selectedUsuariobanco.username!,
      vendedor: selectedUsuariobanco.username,
      loginVendedor: selectedUsuariobanco.username,
      term: simulacao.term,
      tabela: selectedTabela.codigo,
      codigoTabela: selectedTabela.codigo,
      valueInstallment: simulacao.valueInstallment,
      valorRenda: attendance.valorSalario!,
      cpfRepresentanteLegal: attendance.cpfRepresentanteLegal,
      nomeRepresentanteLegal: attendance.nomeRepresentanteLegal,
    };
    return body;
  } else if (simulacao.banco === "BMG") {
    const body: SimulacaoDataBMGProps = {
      banco: "BMG",
      cpf: attendance.cpf!,
      benefitNumber: attendance.nb,
      // REVISAR ESSE CAMPO AQUI, se vai continuar existindo no atendimento ou não
      valueInstallment: simulacao.valueInstallment,
      birthDay: attendance.dataNascimento!,
      codeTable: selectedTabela.codigo,
      term: Number(simulacao.term),
      margin: simulacao.valueInstallment,
      numberInstallments: Number(simulacao.term),
    };
    return body;
  } else if (simulacao.banco === "C6") {
    const body: SimulacaoDataC6Props = {
      banco: "C6",
      benefitNumber: attendance.nb,
      cpf: attendance.cpf!,
      birthDay: formatC6Data(attendance.dataNascimento!),
      // Perguntar pra o dvd qual campos são esses
      incomeAmount: attendance.valorSalario!,
      simulationType: "POR_VALOR_PARCELA",
      formalizationSubtype: "DIGITAL_WEB",
      productTypeCode: "0007",
      valueInstallment: simulacao.valueInstallment,
      term: simulacao.term,
      username: selectedUsuariobanco.username,
      password: selectedUsuariobanco.password,
      cpfCertifiedUser: selectedUsuariobanco.cpfUsuarioCertificado,
      tableCode: selectedTabela.codigo,
    };
    return body;
  }
}
export const formatTipoContaPagamento = (value: string) =>
  value === "CONTA_CORRENTE" ? "CORRENTE" : "POUPANCA";

export const bankTypeOptions = [
  { name: "Conta Corrente", value: "CORRENTE" },
  { name: "Conta Poupança", value: "POUPANCA" },
];

function gerarNumeroCelularAleatorio(comDDD = false) {
  const ddd = Math.floor(Math.random() * (99 - 11 + 1)) + 11; // Gera um DDD entre 11 e 99
  const primeiroDigito = Math.floor(Math.random() * 9) + 6; // Primeiro dígito do celular (6-9 no Brasil)
  const restante = Math.floor(Math.random() * 100000000)
    .toString()
    .padStart(8, "0"); // Número com 8 dígitos

  return {
    phoneNumber: comDDD
      ? `(${ddd}) ${primeiroDigito}${restante}`
      : `${primeiroDigito}${restante}`,
    parts: { ddd: `${ddd}`, phone: `${primeiroDigito}${restante}` },
  };
}
function gerarNumeroAleatorio(min = 1, max = 9999) {
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function gerarContaAleatoria() {
  return `${gerarNumeroAleatorio(10000000, 99999999)}-${gerarNumeroAleatorio(
    0,
    9
  )}`;
}

export function copyAttendanceDataToDigitacaoINSS(
  simulacao: SimulacaoResultProps,
  attendance: Attendance,
  digitacaoFields: DigitacaoINSSProps
) {
  const formatSexo = (value: string) => (value === "MASCULINO" ? "M" : "F");

  const result: DigitacaoINSSProps = {
    banco: simulacao.banco,
    taxIdentifier: attendance.cpf!,
    name: attendance.nome,
    gender: formatSexo(attendance.sexo!),
    birthDay: attendance.dataNascimento!,
    documentNumber: attendance.documento!,
    documentEmissionState: attendance.ufEmissaoDoc! || attendance.uf,
    documentIssuer: attendance.orgaoEmissorDoc! || "SSP",
    documentDate: attendance.dataEmissaoDoc! || "01/10/2022",
    birthState: attendance.ufNaturalidade! || attendance.uf,
    birthCity: attendance.naturalidade! || attendance.cidade,
    phoneNumber1:
      phoneSeparate({
        completedNumber: attendance.telefoneResidencial!,
      }).number || gerarNumeroCelularAleatorio().parts.phone,
    phoneDDD1:
      phoneSeparate({
        completedNumber: attendance.telefoneResidencial!,
      }).ddd || gerarNumeroCelularAleatorio().parts.ddd,
    incomeValue: attendance.valorSalario!,
    addressZipCode1: attendance.cep!,
    addressStreet1: attendance.endereco!,
    addressNumber1: attendance.numero! || gerarNumeroAleatorio(),
    addressNeigh1: attendance.bairro!,
    addressStateCode1: attendance.uf!,
    addressCity1: attendance.cidade!,
    mothersName: attendance.nomeMae! || "Maria da Silva",
    bankAgency:
      attendance.agenciaPagamento! || gerarNumeroAleatorio(1000, 9999),
    bankAccountNumber: attendance.contaPagamento! || gerarContaAleatoria(),
    benefitNumber: Number(attendance.nb!),
    specie: `${attendance.especieNB!}`,
    benefitState: attendance.ufNB! || attendance.uf,
    bankType:
      formatTipoContaPagamento(attendance.tipoContaPagamento!) || "CORRENTE",
    // civilStatus: 5,
    bankNumber: attendance.codBancoPagamento! || "237",
    cpfLegalRepresentative: attendance.cpfRepresentanteLegal!,
    nameLegalRepresentative: attendance.nomeRepresentanteLegal!,
    // certifiedLogin: ,
    // phoneDDD1: ,
    // passwordConsig: ,
    // seller: ,
    // sellerLogin: ,
    // tableCode: ,
    // userConsig: ,
    ...digitacaoFields,
  };
  return result;
}

export function phoneSeparate({
  dddPhone,
  phone,
  completedNumber,
}: {
  dddPhone?: string | null;
  phone?: string | null;
  completedNumber?: string;
}) {
  const completedString =
    (dddPhone ?? "") + (phone ?? "") || (completedNumber ?? "");
  const result = maskPhoneFormat(completedString);
  const ddd = result.parts[1];
  const numberPart1 = result.parts[2];
  const numberPart2 = result.parts[3];
  const number = numberPart1 + numberPart2;
  return {
    ddd,
    number,
    phoneAndDDDNumbers: result.onlyNumbers,
    phoneAndDDDFormatedValue: result.formatedValue,
  };
}

export function validateDigitacaoINSS(
  body: DigitacaoINSSProps,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  let schema: ObjectSchema<DigitacaoINSSProps> = {
    banco: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },
    taxIdentifier: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    name: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    gender: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    // civilStatus: (value) => {
    //   const { isValid, errorMessage } = fieldValidation({ value }).required({
    //     message: "Preencha o campo corretamente",
    //   });
    //   return { valid: isValid, message: errorMessage };
    // },
    birthDay: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    documentNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    documentEmissionState: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    documentIssuer: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    documentDate: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    birthState: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    birthCity: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    phoneNumber1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    incomeValue: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressZipCode1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressStreet1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressNumber1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressNeigh1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressStateCode1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    addressCity1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    mothersName: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankAgency: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankAccountNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    benefitNumber: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    specie: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    benefitState: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    bankType: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });

      return { valid: isValid, message: errorMessage };
    },
    cpfLegalRepresentative: (value) => {
      return { valid: true };
    },
    nameLegalRepresentative: (value) => {
      return { valid: true };
    },
    phoneDDD1: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      return { valid: isValid, message: errorMessage };
    },
    //
    // USUARIO BANCO
    //
    userConsig: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      if (body.banco === "BMG") {
        return { valid: isValid, message: errorMessage };
      } else {
        return { valid: true };
      }
    },
    passwordConsig: (value) => {
      const { isValid, errorMessage } = fieldValidation({ value }).required({
        message: "Preencha o campo corretamente",
      });
      if (body.banco === "BMG") {
        return { valid: isValid, message: errorMessage };
      } else {
        return { valid: true };
      }
    },
    // seller: (value) => {
    //   const { isValid, errorMessage } = fieldValidation({ value }).required({
    //     message: "Preencha o campo corretamente",
    //   });
    //   return { valid: isValid, message: errorMessage };
    // },
    // sellerLogin: (value) => {
    //   const { isValid, errorMessage } = fieldValidation({ value }).required({
    //     message: "Preencha o campo corretamente",
    //   });
    //   return { valid: isValid, message: errorMessage };
    // },
    // cpfCertifiedUser: (value) => {
    //   const { isValid, errorMessage } = fieldValidation({ value }).required({
    //     message: "Preencha o campo corretamente",
    //   });
    //   return { valid: isValid, message: errorMessage };
    // },
  };

  const result = objectSchema(schema, body);

  return result;
}

// {
//   "bancoSelecionado": "FACTA",
//   "cpf": 25805298880,
//   "nome": "APARECIDA DONIZETE MARTIS",
//   "sexo": "F",
//   "estadoCivil": 5,
//   "dataNascimento": "21/09/1960",
//   "codigoFamiliar": null,
//   "rg": "00286488036",
//   "estadoRg": "SP",
//   "orgaoEmissor": "SSP",
//   "dataExpedicao": "01/10/2022",
//   "estadoNatural": "SP",
//   "cidadeNatural": "Pirapora do Bom Jesus",
//   "nacionalidade": 1,
//   "celular": "11994995909",
//   "renda": 1412,
//   "cep": "06550000",
//   "endereco": "RUA JOAO JOSE PINTO 000980",
//   "numero": "1",
//   "bairro": "IGAVETA",
//   "estado": "SP",
//   "cidade": "Pirapora do Bom Jesus",
//   "nomeMae": "LUZIA MARIA ALVES",
//   "nomePai": "Nao informado",
//   "banco": 1,
//   "agencia": 9596,
//   "conta": "0010244797",
//   "matricula": 1972322173,
//   "tipoCreditoBeneficio": 1,
//   "tipoBeneficio": "21",
//   "estadoBeneficio": "SP",
//   "clienteIletradoImpossibilitado": "N",
//   "valorPatrimonio": 10000,
//   "paisOrigem": 0,
//   "messageErro": null,
//   "tipoConta": "CORRENTE"
// }
