import { SelectedCustomer } from "pages/carteira";

import { PriceButton } from ".";
import { SkeletonLoading } from "components/skeleton-loading";
import { Box, Button, Flex, Grid, Text } from "@chakra-ui/react";
import { FGTSIcon } from "./icons";
import { RepeatIcon } from "@chakra-ui/icons";
import { openModalConfirm } from "components/modal-confirm-new";
import apiCarteira from "api/api-carteira";
import { useApplicationContext } from "contexts/ApplicationContext";
import { Dispatch, SetStateAction } from "react";
import {
  bgError,
  bgSucess,
  StatusFormat,
} from "components/dynamic-table/row-status";
import { useRouter } from "routes/router-control/use-router";
import { Toast } from "components/toast";

export function SimulacaoFGTSServiceCard({
  isLoading,
  priceDesc,
  title,
  serviceData,
  selectedCustomer,
  setCardData,
}: {
  isLoading?: boolean;
  title: string;
  priceDesc: string;
  serviceData: any;
  selectedCustomer: SelectedCustomer;
  setCardData: Dispatch<
    SetStateAction<Partial<{ simulacaoFGTS: { ativo: boolean } }>>
  >;
}) {
  const { user } = useApplicationContext();
  const router = useRouter();

  const px = "25px";
  const cardData = serviceData?.["simulacaoFGTS"];
  const isActive: boolean = !!cardData?.ativo;

  const changeAtivoStatus = () => {
    const message = `Deseja ${
      !isActive ? "ATIVAR" : "DESATIVAR"
    } serviço "${title}"?`;

    openModalConfirm({
      message,
      onConfirm: async () => {
        try {
          const { data } = await apiCarteira.put(
            `/services/avulsos/${selectedCustomer.id}/SIMULACAO_FGTS/ativo`,
            { ativo: !isActive }
          );
          setCardData((prev) => ({
            ...prev,
            simulacaoFGTS: { ativo: data.ativo },
          }));
        } catch (e) {
          Toast({ title: "Erro ao obter dados do serviço", status: "error" });
        } finally {
        }
      },
    });
  };
  const titleContainerHeight = "80px";
  return (
    <SkeletonLoading
      isLoading={!!isLoading}
      borderRadius="5px"
      bg="#fff"
      overflow="hidden"
    >
      <Box
        bg="#fff"
        borderRadius="5px"
        overflow="hidden"
        h="100%"
        border="1px solid var(--chakra-colors-custom-gray)"
      >
        <Flex
          px={px}
          w="100%"
          h={titleContainerHeight}
          // bg="linear-gradient(to right, #141B3B, #B21F17)"
          alignItems="center"
          pos="relative"
          overflow="hidden"
          justifyContent="flex-start"
        >
          <Text
            fontSize={{ base: "18px", "2xl": "20px" }}
            // color="#fff"
            fontWeight="semibold"
          >
            {title}
          </Text>
          <Box pos="absolute" bottom="-20px" right="35px">
            <FGTSIcon />
          </Box>
        </Flex>
        <Flex flexDir="column" px={px} py="15px" minH="215px">
          <Grid
            templateColumns="1fr 2fr"
            gap="10px"
            alignItems="center"
            w="100%"
            mb="10px"
          >
            <Text fontWeight="semibold">Status:</Text>
            <Flex alignItems="center" justifyContent="flex-end">
              <StatusFormat
                mr="10px"
                w="82px"
                bg={isActive ? bgSucess : bgError}
              >
                {isActive ? "Ativo" : "Inativo"}
              </StatusFormat>
              <Button
                w="calc(100% - 92px)"
                leftIcon={<RepeatIcon w="13px" h="13px" />}
                variant={"outline"}
                size="xs"
                onClick={changeAtivoStatus}
              >
                {!isActive ? "Ativar" : "Desativar"}
              </Button>
            </Flex>
          </Grid>

          <PriceButton mt="auto">{priceDesc}</PriceButton>
        </Flex>
      </Box>
    </SkeletonLoading>
  );
}
