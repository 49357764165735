import { DropdownOptionProps } from "components/dropdown";

export const mailingBanks: DropdownOptionProps[] = [
  { name: "ITAU", value: "ITAU" },
  { name: "OLE", value: "OLE" },
  { name: "PAN", value: "PAN" },
  { name: "DAYCOVAL", value: "DAYCOVAL" },
  { name: "C6", value: "C6" },
  { name: "SAFRA", value: "SAFRA" },
  { name: "BRADESCO", value: "BRADESCO" },
  { name: "BMG", value: "BMG" },
  { name: "CETELEM", value: "CETELEM" },
  { name: "BANRISUL", value: "BANRISUL" },
];
