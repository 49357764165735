import { Flex, IconButton, IconButtonProps, Text } from "@chakra-ui/react";
import { Popover, PopoverProps } from "components/popover";
import { DotsIcon } from "components/vectors/dots-icon";
import { MouseEvent, ReactNode } from "react";

export type MenuThreeDotsOptions = {
  label: string | JSX.Element;
  onClick?: (e: MouseEvent<HTMLDivElement>) => void;
  isDisabled?: boolean;
}[];

export interface MenuThreeOptionsProps {
  options: MenuThreeDotsOptions;
  popoverProps?: Partial<PopoverProps>;
  customButton?: JSX.Element | ReactNode;
  buttonProps?: Partial<IconButtonProps>;
}

export const MenuThreeDots = ({
  options,
  popoverProps,
  customButton,
  buttonProps,
}: MenuThreeOptionsProps) => {
  return (
    <Popover
      button={
        customButton || (
          <IconButton
            aria-label=""
            variant="ghost"
            size="sm"
            borderRadius="full"
            icon={<DotsIcon />}
            {...buttonProps}
          />
        )
      }
      contentProps={{ padding: "0", overflow: "auto" }}
      position="left-bottom"
      buttonTriggerContainerProps={{
        onClick(e) {
          e.preventDefault();
          e.stopPropagation();
        },
      }}
      {...popoverProps}
    >
      {({ onClose }) => (
        <>
          {options.map((option, index) => {
            return (
              <Flex
                onClick={(e) => {
                  option.onClick?.(e);
                  onClose();
                }}
                key={index}
                w="100%"
                _hover={{ bg: "gray.100" }}
                px="6px"
                py="5px"
                cursor="pointer"
                opacity={option.isDisabled ? 0.5 : 1}
                pointerEvents={option.isDisabled ? "none" : "auto"}
              >
                {option.label}
              </Flex>
            );
          })}
        </>
      )}
    </Popover>
  );
};
