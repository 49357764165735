import {
  Box,
  BoxProps,
  Center,
  Flex,
  InputProps,
  useStyleConfig,
} from "@chakra-ui/react";
import { defaultScroll } from "chakra/theme";
import { filterArrayBy } from "utils/filter-array-by";
import { InputField, InputFieldProps } from "components/input-field";
import { FocusEvent, KeyboardEvent, useEffect, useRef, useState } from "react";
import { getRelativeRect } from "utils/relative-rect";
import { Checkbox } from "components/checkbox";
import { ChevronDownIcon } from "@chakra-ui/icons";

export interface InputBoxSearchProps {
  options: any[];
  checkIsInclude: (option: any) => boolean;
  onChange: (value: any) => void;
  inputBoxProps?: BoxProps;
  isLoading?: boolean;
  optionValue?: string;
  optionLabel?: string;
  optionLabelRender?: (option: any) => any;
  searchKeys?: string[];
  errorMessage?: string;
  clearInput?: boolean;
  openOnFocus?: boolean;
  value?: string;
  isInvalid?: boolean;
  multiSelect?: boolean;
  resultLimit?: number;
  closeOnSelect?: boolean;
}

export function InputBoxSearch({
  options,
  onChange: onChangeValue,
  inputBoxProps,
  isLoading,
  searchKeys = ["name"],
  optionLabel = "name",
  optionValue = "value",
  errorMessage,
  resultLimit = 50,
  checkIsInclude,
  optionLabelRender,
  clearInput = true,
  openOnFocus,
  value = "",
  isInvalid,
  multiSelect,
  closeOnSelect = true,
}: InputBoxSearchProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState<any[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const partnerListRef = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLDivElement>(null);
  const isOpened = isOpen && (openOnFocus || result.length);
  const invalidBorder = "3px solid var(--chakra-colors-secondary-600)";
  const border = "1px solid var(--chakra-colors-custom-gray)";

  const clearInputSearch = () => {
    input.current!.textContent = "";
  };

  const setInputValue = (value: string) => {
    if (input.current) input.current.textContent = value;
  };

  const handleScroll = (element: HTMLElement, parent: HTMLElement) => {
    const relRef = getRelativeRect(element, parent);
    const elHeight = element.clientHeight;
    const top = relRef.top + parent.scrollTop!;
    const scrollPos = parent.scrollTop! + parent.clientHeight;
    if (top + elHeight > scrollPos)
      parent.scrollTop = top + elHeight - parent.clientHeight;
    if (top < parent.scrollTop) parent.scrollTop = top;
  };

  let currentOptions = result.slice(0, resultLimit);

  const handleKeyPress = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    const list = partnerListRef.current;

    if (key === "Escape") setIsOpen(false);
    else if (result.length) setIsOpen(true);

    if (key === "Enter" && isOpened) {
      const currResult = result[selectedIndex];
      if (currResult) {
        onChangeValue(currResult[optionValue]);
        if (!multiSelect) input.current?.blur();
        setIsOpen(false);
      }
    } else if (key === "ArrowUp" && isOpened) {
      const newCurrIndex = selectedIndex - 1;
      const currResult = result[newCurrIndex];
      if (!currResult) {
        const selectedHtml = list!.children[
          result.length - 1
        ] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(result.length - 1);
        if (clearInput) clearInputSearch();
      } else {
        const selectedHtml = list!.children[newCurrIndex] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(newCurrIndex);
        if (clearInput) clearInputSearch();
      }
    } else if (key === "ArrowDown" && isOpened) {
      const newCurrIndex = selectedIndex + 1;
      const currResult = result[newCurrIndex];
      if (!currResult) {
        const selectedHtml = list!.children[0] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(0);
        if (clearInput) clearInputSearch();
      } else {
        const selectedHtml = list!.children[newCurrIndex] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(newCurrIndex);
        if (clearInput) clearInputSearch();
      }
    } else setSelectedIndex(-1);
  };

  useEffect(() => {
    if (openOnFocus) setResult(options.slice(0, resultLimit));
    if (input.current) setInputValue(value);
  }, [options]);

  useEffect(() => {
    if (!clearInput && input.current) setInputValue(value);
  }, [value]);

  const { onChange: onChangeInputValue, ...restInputProps } =
    inputBoxProps || {};

  if (options.filter((item) => checkIsInclude(item))?.length && multiSelect) {
    const selecteds = options.filter((item) => checkIsInclude(item));
    console.log(selecteds);
    const unSelecteds = currentOptions.filter((item) => !checkIsInclude(item));
    currentOptions = [...selecteds, ...unSelecteds];
  }

  const onFocus = (e: FocusEvent<HTMLDivElement>) => {
    const iconBox = e.currentTarget.parentElement
      ?.children[1] as HTMLDivElement;

    e.currentTarget.style.borderTop = border;
    e.currentTarget.style.borderLeft = border;
    e.currentTarget.style.borderBottom = border;

    iconBox.style.borderTop = border;
    iconBox.style.borderRight = border;
    iconBox.style.borderBottom = border;

    setIsOpen(true);
    inputBoxProps?.onFocus?.(e);
  };

  const onBlur = (e: FocusEvent<HTMLDivElement>) => {
    const iconBox = e.currentTarget.parentElement
      ?.children[1] as HTMLDivElement;

    e.currentTarget.style.borderTop = "";
    e.currentTarget.style.borderLeft = "";
    e.currentTarget.style.borderBottom = "";

    iconBox.style.borderTop = "";
    iconBox.style.borderRight = "";
    iconBox.style.borderBottom = "";

    setIsOpen(false);
    inputBoxProps?.onBlur?.(e);
  };

  return (
    <Box w="100%" gap="16px">
      <Box w="100%" pos="relative">
        <Flex pos="relative">
          <Box
            textOverflow="ellipsis"
            whiteSpace="nowrap"
            overflow="hidden"
            ref={input}
            h="32px"
            w="100%"
            borderRadius="0.375rem 0 0 0.375rem"
            borderTop={isInvalid ? invalidBorder : border}
            borderLeft={isInvalid ? invalidBorder : border}
            borderBottom={isInvalid ? invalidBorder : border}
            px="16px"
            py="4px"
            cursor="pointer"
            alignItems="center"
            _focus={{ outline: "none" }}
            contentEditable
            onKeyDown={handleKeyPress}
            onKeyUp={(e) => {
              const value = e.currentTarget.textContent;
              if (value) {
                setResult(
                  filterArrayBy(options, value, searchKeys).slice(
                    0,
                    resultLimit
                  )
                );
              } else
                setResult(openOnFocus ? options.slice(0, resultLimit) : []);
              onChangeInputValue?.(e);
            }}
            isDisabled={isLoading}
            errorMessage={errorMessage}
            isLoading={isLoading}
            {...restInputProps}
            onFocus={onFocus}
            onBlur={onBlur}
          ></Box>
          <Center
            w="32px"
            h="32px"
            borderRadius="0 0.375rem 0.375rem 0"
            borderTop={isInvalid ? invalidBorder : border}
            borderRight={isInvalid ? invalidBorder : border}
            borderBottom={isInvalid ? invalidBorder : border}
            cursor="pointer"
            onClick={(e) => {
              if (input.current) input.current.focus();
            }}
          >
            <ChevronDownIcon w="20px" h="20px" display="block" right="5px" />
          </Center>
        </Flex>

        {isOpened ? (
          <Box
            ref={partnerListRef}
            pos="absolute"
            bottom="-2px"
            left="0"
            right="0"
            zIndex="1"
            transform="translateY(100%)"
            bg="#fff"
            borderRadius="6px"
            overflow="auto"
            border="1px solid var(--chakra-colors-gray-200)"
            maxH={"240px"}
            sx={defaultScroll}
            onMouseDown={(e) => e.preventDefault()}
          >
            {currentOptions.map((item, index) => {
              const isInclude = checkIsInclude(item);
              const isSelected = index === selectedIndex;
              return (
                <Box
                  p="5px 10px"
                  key={`input-search-${index}`}
                  w="100%"
                  _hover={{ bg: "gray.100" }}
                  bg={
                    isInclude ? "gray.200" : isSelected ? "gray.100" : undefined
                  }
                  cursor="pointer"
                  onClick={(e: any) => {
                    onChangeValue(item[optionValue]);
                    setSelectedIndex(index);
                    if (clearInput) clearInputSearch();
                    if (closeOnSelect) {
                      setIsOpen(false);
                      e.currentTarget.focus();
                    }
                  }}
                  textAlign="start"
                  as="button"
                >
                  <Flex alignItems="center">
                    {multiSelect ? (
                      <Checkbox
                        mr="5px"
                        isChecked={isInclude}
                        onClick={(e) => {}}
                      />
                    ) : undefined}{" "}
                    {optionLabelRender?.(item) ?? `${item[optionLabel]}`}
                  </Flex>
                </Box>
              );
            })}
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
