import { Icon, IconProps } from "@chakra-ui/icons";

export function PegTechLogo({ ...rest }: IconProps) {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 3497 676"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1359 674.6C1359 673.7 1361.1 673.1 1366.3 672.7C1375.1 672 1376.4 671.6 1377.9 668.4C1379.5 664.9 1379.3 663.7 1376.9 660.6C1375.1 658.3 1374.1 658 1368.9 658H1363V651.7V645.3L1356.3 644.1C1338.9 640.9 1326.6 629.2 1322.5 611.9C1315.8 583.2 1333.3 556.7 1360.3 555.2C1372.8 554.5 1383.8 557.8 1392 564.7C1395.2 567.3 1403 578.4 1403 580.2C1403 580.6 1401.8 581 1400.2 581C1398 581 1397.1 580.2 1395.1 576.4C1389.3 565.6 1378.3 559.8 1363.4 559.7C1355.4 559.6 1353.7 560 1347.9 562.7C1344.4 564.4 1339.8 567.5 1337.6 569.6C1329.1 578.1 1324.7 593.8 1326.9 607.3C1329.2 621.2 1335.2 630.1 1346.2 636.1C1352.3 639.4 1352.9 639.5 1363.5 639.5C1373.3 639.5 1375.1 639.2 1380.1 636.9C1386.8 633.7 1392.9 627.9 1395.6 622.2C1397.1 618.8 1398 618 1400.3 618C1401.8 618 1403 618.2 1403 618.5C1403 618.8 1401.9 621.2 1400.6 623.8C1398.2 628.5 1390.8 636.2 1386 639.2C1383.5 640.7 1375.5 643.3 1369.8 644.5C1367.3 645 1367 645.4 1367 649.4V653.7L1372.1 654.4C1378.9 655.3 1383 659.2 1383 664.7C1383 672.5 1377.9 676 1366.5 676C1360.9 676 1359 675.7 1359 674.6Z"
        fill="#042460"
      />
      <path
        d="M774.7 644C765.9 641.8 759.1 636.5 755.9 629.5C753.3 624 753.5 623 757 623C759.2 623 760 623.5 760 624.8C760 627.7 765.5 635.3 769.1 637.4C773.8 640.2 786.6 640.8 792.5 638.6C801.7 635 806.4 626.1 804 616.9C802.2 610.3 797.8 606.8 787.3 603.6C766.6 597.2 765.9 596.8 761.5 592.6C756.6 587.9 754.5 581.9 755.4 575.2C756.9 563.9 766.1 556.2 779.2 555.2C791.5 554.3 800.7 558.3 806.1 566.7C809.6 572.4 809.7 574 806.6 574C804.9 574 803.5 572.7 801.3 569.2C797.1 562.5 791 559.5 781.5 559.5C776 559.6 773.6 560.1 770 562C755.9 569.8 757.6 588.8 773 594.3C775.5 595.2 782 597.2 787.5 598.9C799.4 602.5 803.1 604.6 806.5 609.9C814.1 621.3 808.5 636.7 794.6 642.5C789.9 644.5 779.7 645.2 774.7 644Z"
        fill="#042460"
      />
      <path
        d="M922.5 643.6C901.6 638.5 888.7 619.4 890.3 596C891.5 578.3 901.5 564 917 557.8C921.4 556 924.6 555.6 933.1 555.6C943.3 555.5 943.8 555.6 951.1 559.2C968.7 567.9 978.2 587 975 607.3C971 633.5 947.6 649.7 922.5 643.6ZM949.1 636.7C963.8 629.6 970.7 616.2 969.8 596.8C969.4 588 968.9 586.2 965.6 579.6C960 568.2 951.4 561.9 938.7 559.9C912.2 555.6 891.2 578.9 895.9 607.3C898.5 622.9 905.3 632 918.9 637.7C923.7 639.7 925.8 640 934 639.8C942 639.5 944.5 639 949.1 636.7Z"
        fill="#042460"
      />
      <path
        d="M1198 643.5C1187.9 640.6 1181.6 634.6 1178.3 624.3C1177 620.5 1176.6 612.9 1176.2 587.8L1175.8 556H1178.8H1181.9L1182.2 589.8C1182.5 622.4 1182.6 623.6 1184.7 627.7C1189.5 636.6 1197.9 640.6 1209.9 639.8C1223 638.8 1230 632.1 1232 618.5C1232.5 614.6 1233 599 1233 583.8V556H1235.6H1238.2L1237.8 588.8C1237.3 625.6 1237 627.3 1230 635.3C1222.9 643.3 1209.6 646.7 1198 643.5Z"
        fill="#042460"
      />
      <path
        d="M1516.1 643.4C1499.3 638.9 1487.8 625.5 1485 607.3C1481.8 587 1491.3 567.9 1508.9 559.2C1516 555.7 1516.9 555.5 1526.4 555.5C1538.5 555.5 1544.4 557.3 1552.8 563.4C1569.6 575.8 1574.9 601.4 1564.9 621.8C1556.3 639.4 1535.5 648.6 1516.1 643.4ZM1544.2 636.2C1555.6 630.6 1562 621 1564 606.8C1566.8 586.6 1557.5 568.2 1541.2 561.9C1533.7 559 1521.7 558.8 1514.3 561.5C1503.2 565.5 1494.9 574.7 1491.4 586.9C1488.9 595.4 1489.8 610.8 1493.3 618.3C1500.2 633.4 1511.6 640.3 1528.5 639.8C1536.4 639.5 1538.4 639.1 1544.2 636.2Z"
        fill="#042460"
      />
      <path
        d="M1799.3 644C1791.3 642.3 1784.2 637.2 1781 630.9C1778.3 625.6 1778.4 623 1781.3 623C1783 623 1783.9 623.9 1784.9 626.5C1788.1 635.5 1795.3 639.9 1806.9 640C1820.5 640 1828.1 634 1828.8 622.6C1829.1 617.5 1828.8 616 1826.8 613C1823.2 607.8 1819.2 605.6 1806.3 601.9C1786 596 1780 590.7 1780 578.2C1780 563.3 1791.7 554.1 1809.4 555.2C1820.3 555.9 1828.8 561.4 1832.5 570.3L1834 574H1831.1C1829 574 1827.9 573.4 1827.5 572C1826.4 568.6 1822 564 1817.6 561.8C1808.5 557 1793.1 559.8 1787.9 567.2C1785.5 570.6 1784.4 577.1 1785.4 582.4C1786.7 589.2 1791.5 592.4 1808.2 597.7C1824.3 602.8 1829.9 606.2 1832.6 612.7C1834.5 617.2 1834.3 625.7 1832.1 630.5C1830.1 635.1 1824.2 640.6 1819.2 642.7C1814.9 644.5 1804.7 645.2 1799.3 644Z"
        fill="#042460"
      />
      <path
        d="M2714 643.9C2692.2 639.6 2677.3 616.7 2680.9 593C2684.6 569.4 2700.5 555.1 2723 555C2738.9 555 2750.6 561.3 2759.4 574.5C2762.6 579.4 2762.7 581 2759.5 581C2757.8 581 2756.6 580.1 2755.4 577.9C2754.6 576.2 2752 572.8 2749.7 570.3C2736 555.5 2709.3 555.8 2695.6 571.1C2688.4 579.1 2686.5 585.1 2686.5 600C2686.5 612 2686.6 612.8 2689.7 619.2C2693.5 627.3 2699.4 633.4 2706.9 636.9C2711.8 639.2 2713.7 639.5 2723 639.5C2732.2 639.5 2734.2 639.2 2739 636.9C2746.4 633.4 2750.6 629.7 2754.2 623.3C2756.7 618.8 2757.6 618 2760.1 618H2763L2760.6 622.8C2752.2 639.2 2733.5 647.7 2714 643.9Z"
        fill="#042460"
      />
      <path
        d="M2877 644C2861.4 640.7 2848.4 627.3 2844.9 610.9C2843.3 603.4 2844.3 588.7 2846.9 582C2852.9 566.3 2866.6 556.5 2884.1 555.3C2902.3 554 2919.5 564.8 2926.1 581.7C2929.4 590.3 2929.9 605.6 2927.2 614.7C2923.3 627.3 2913.8 637.6 2902.1 642.1C2895.9 644.4 2883.5 645.3 2877 644ZM2902.2 637.3C2918.3 630 2926.9 610.5 2923 589.9C2919.1 569.4 2900.6 556.4 2880.2 560C2871.3 561.5 2865.3 564.9 2859.2 571.4C2851.8 579.6 2849.6 585.8 2849.5 599.5C2849.5 612.2 2851.1 618 2856.9 625.6C2865.2 636.5 2873.5 640.2 2888 639.7C2895 639.5 2898.7 638.8 2902.2 637.3Z"
        fill="#042460"
      />
      <path
        d="M1059 600V556H1062H1065V598V640H1081.5C1097.3 640 1098 640.1 1098 642C1098 643.9 1097.3 644 1078.5 644H1059V600Z"
        fill="#042460"
      />
      <path
        d="M1654 600V556H1676C1697.3 556 1698 556.1 1698 558C1698 559.9 1697.3 560 1678.5 560H1659V579V598H1676.5C1693.3 598 1694 598.1 1694 600C1694 601.9 1693.3 602 1676.5 602H1659V621V640H1678.5C1697.3 640 1698 640.1 1698 642C1698 643.9 1697.3 644 1676 644H1654V600Z"
        fill="#042460"
      />
      <path
        d="M2020 600V556H2035.9C2054.6 556 2060 557.3 2066 563.1C2070.1 567 2073 574.4 2073 580.5C2073 586 2070.2 593.4 2066.8 596.9C2060.8 603.1 2056.3 604.4 2039.8 604.8L2025 605.2V624.6V644H2022.5H2020V600ZM2060.2 596.5C2070.3 589.4 2070.2 571.4 2060 563.9C2056.5 561.3 2048.5 560 2035.9 560H2025V580.1V600.2L2040.4 599.8C2055.7 599.5 2055.9 599.5 2060.2 596.5Z"
        fill="#042460"
      />
      <path
        d="M2149.4 641.8C2149.7 640.5 2156.9 621.3 2165.3 599L2180.6 558.5L2183.6 558.2L2186.7 557.9L2200.5 594.7C2208.1 614.9 2215.4 634.3 2216.6 637.8L2219 644H2216.2C2213.9 644 2213.2 643.4 2212.1 640.3C2211.3 638.2 2209.4 633 2207.8 628.8L2204.8 621H2183.6H2162.4L2161.1 624.8C2154.9 642.1 2153.9 644 2151.3 644C2149.2 644 2148.9 643.6 2149.4 641.8ZM2203 616.4C2203 615.2 2184.8 566.5 2184.1 565.7C2183.7 565.3 2179.3 575.9 2174.3 589.2C2169.3 602.6 2164.9 614.3 2164.5 615.3C2163.8 616.9 2165.1 617 2183.4 617C2194.2 617 2203 616.7 2203 616.4Z"
        fill="#042460"
      />
      <path
        d="M2300 600V556H2315.8C2335.2 556 2340.3 557.2 2346.4 563.3C2353.6 570.5 2355.3 583 2350.4 592.8C2347.5 598.7 2343.8 601.7 2336.5 604.2C2333.8 605.1 2331.6 606.4 2331.7 606.9C2331.9 607.5 2336.9 616.1 2343 626L2354.1 644.1L2350.8 643.8C2347.8 643.5 2346.9 642.4 2336.5 625L2325.4 606.5L2315.2 606.2L2305 605.9V625V644H2302.5H2300V600ZM2337.7 599.2C2343.6 596.1 2347 590.6 2347.7 583.2C2348.6 573.4 2345.3 566.8 2337.7 562.8C2334.1 561 2331.3 560.6 2319.3 560.2L2305 559.7V581V602.2L2319.3 601.8C2331.5 601.5 2334.1 601.1 2337.7 599.2Z"
        fill="#042460"
      />
      <path
        d="M2431.5 641.8C2432 640.5 2439.1 621.3 2447.5 599L2462.8 558.5H2466H2469.2L2485.1 600.9C2493.8 624.2 2501 643.5 2501 643.7C2501 643.9 2499.8 644 2498.3 643.8C2495.8 643.5 2495.2 642.5 2491.4 632.5L2487.3 621.5L2465.9 621.2L2444.5 621L2440.4 632.2C2436.7 642.6 2436.2 643.5 2433.6 643.8C2431.1 644.1 2430.9 643.9 2431.5 641.8ZM2485 616.3C2485.1 615 2466.5 565 2466 565C2465.4 565 2447 614.2 2447 615.8C2447 617.2 2485 617.6 2485 616.3Z"
        fill="#042460"
      />
      <path
        d="M3013 600V556H3028.8C3046.7 556 3052.3 557.1 3058.2 562C3068.8 570.7 3069.2 590.8 3059 599.4C3056.6 601.4 3050.9 603.9 3045.2 605.6C3044.2 605.9 3056.3 627.1 3065.8 641.8C3067.2 643.9 3067.1 644 3064.2 644C3061.3 644 3060.5 643 3049.8 625.3L3038.5 606.5L3028.3 606.2L3018 605.9V625V644H3015.5H3013V600ZM3050.5 599.3C3052.7 598.2 3055.3 596.4 3056.2 595.3C3062.8 588 3062.6 572.9 3055.8 566.3C3050.9 561.6 3047.8 560.8 3032.3 560.2L3018 559.7V581V602.2L3032.3 601.8C3044.3 601.5 3047.1 601.1 3050.5 599.3Z"
        fill="#042460"
      />
      <path
        d="M3150 600V555.8L3169.3 556.2C3187.6 556.5 3188.7 556.6 3193 559C3199.6 562.7 3203.1 568.3 3203.7 576.1C3204.3 584.7 3201.6 590.1 3194.2 595L3188.9 598.5L3192.7 600.1C3200.2 603.3 3206 612.2 3206 620.5C3206 629 3200.3 637.6 3192 641.4C3188.1 643.2 3185.1 643.5 3168.8 643.8L3150 644.2V600ZM3191.3 636.6C3193.5 635.5 3196.4 632.9 3197.8 630.7C3200.1 627.3 3200.5 625.5 3200.5 620C3200.5 611.4 3197.8 607.1 3190.1 603.3C3185.1 600.8 3183.4 600.6 3169.8 600.2L3155 599.8V619.4V639.1L3171.3 638.8C3184.8 638.6 3188.1 638.2 3191.3 636.6ZM3186.5 594.9C3201 590.8 3203 569.6 3189.5 562.6C3186.1 560.8 3183.3 560.5 3170.3 560.1L3155 559.8V577.9V596H3168.8C3176.3 596 3184.3 595.5 3186.5 594.9Z"
        fill="#042460"
      />
      <path
        d="M3285.5 637.3C3286.9 633.5 3294.2 614.2 3301.7 594.3L3315.3 558H3318.4H3321.5L3337.3 600.3C3345.9 623.5 3353 642.8 3353 643.3C3353 643.7 3351.9 644 3350.6 644C3348.1 644 3347.1 641.9 3340.9 624.7L3339.6 621L3318.2 621.2L3296.8 621.5L3292.7 632.8C3288.8 643.4 3288.5 644 3285.8 644H3283L3285.5 637.3ZM3335.1 609.3C3324.5 580.2 3318.6 565.1 3318 565.7C3317.3 566.6 3299 615.2 3299 616.3C3299 616.7 3307.8 617 3318.5 617H3338L3335.1 609.3Z"
        fill="#042460"
      />
      <path
        d="M3435 600V556H3437.4C3439.4 556 3443.6 561.8 3465.6 595.2L3491.5 634.4L3491.8 595.2L3492 556H3494.5H3497V600V644H3494.7C3492.7 644 3488.3 637.8 3466.4 604.5L3440.5 564.9L3440.2 604.5L3440 644H3437.5H3435V600Z"
        fill="#042460"
      />
      <path
        d="M291 610.3C277.4 608.9 247.9 602.1 226.2 595.4L214.8 591.9L215.5 584.2C215.8 580 216.5 574.3 217 571.5C218.6 562.4 220.9 546.7 223 530.5C226.4 503.5 228.1 494.7 230.4 491.5C236.3 482.9 256.3 460 257.8 460C258.2 460 261.7 469.3 265.5 480.8C281.8 530 281 527.3 279.6 529C275.5 533.7 274 539.8 276.1 544.2C277.4 547.1 282.9 551 285.8 551C287 551 288.4 551.8 288.9 552.8C289.9 554.7 294.4 567.4 302.4 591.5C305.4 600.3 308.1 608.3 308.5 609.3C309.1 610.8 308.5 611 301.9 610.8C297.8 610.8 292.9 610.5 291 610.3Z"
        fill="#042460"
      />
      <path
        d="M313.5 610.3C312 609.2 309.9 603.6 304.4 586.5C301.8 578.3 299.3 571.1 299 570.5C298.6 570 297.1 565.6 295.6 560.7L292.8 552L296 548.4C301.3 542.3 302.3 537.1 299.3 532C297.3 528.6 294 526.9 288.1 526.1C284 525.5 283.6 525.2 282.4 521.5C279.3 511.4 270.6 485.2 266 472.1C263.3 464.1 261 456.9 261 456.1C261 455.2 262.5 452.9 264.3 450.9C269.1 445.5 281.7 430.6 311.4 395.2L338.3 363.1L341.1 365.1C342.7 366.2 344 367.6 344 368.1C344 368.7 342.1 372.2 339.7 375.8C327.5 394.8 322.9 413.5 326.9 428C332 446.7 346.4 464.1 364 473C376.2 479.2 382.9 480.5 401 480.4C419.6 480.3 429.9 478.6 456 471C476.4 465.1 478 464.7 478.7 465.3C479 465.7 478.8 467.9 478.2 470.2C477.6 472.6 476.1 478.6 474.9 483.5C471.2 498.8 470.2 500.1 441.4 531.5C410.8 564.8 398.6 578.1 391.5 585.9C378.5 600.2 373.7 604.9 371.2 605.8C365.4 608.1 348.7 610.1 332 610.6C322.4 610.9 314 610.8 313.5 610.3Z"
        fill="#042460"
      />
      <path
        d="M388.3 594.7C393.4 589.1 405 576.4 414 566.5C423 556.6 431.1 547.8 431.9 546.9C432.8 546.1 441.1 537.2 450.4 527.2C459.7 517.2 467.5 509.2 467.8 509.4C468.2 509.9 466.5 518.3 464.8 523.5C463.8 526.7 459.5 543.1 456 557C454.8 561.7 453.4 567.4 452.8 569.7C452.2 572 451 574.6 450 575.5C449.1 576.4 441.7 580.4 433.8 584.4C419.2 591.7 406.8 596.6 388.7 601.9L379 604.8L388.3 594.7Z"
        fill="#042460"
      />
      <path
        d="M192 581.2C182.4 576.4 172.6 571.2 170.3 569.6L166 566.8L171.4 560.6C174.3 557.3 177.6 553.4 178.6 552.1C179.6 550.8 182.1 547.8 184.1 545.6C189.4 539.4 203.1 523.3 206.7 518.8C208.5 516.6 212.3 512.1 215.2 508.8C218.1 505.4 221.1 502 221.8 501.1C222.9 499.7 223 500.3 222.5 505C220.9 518.6 219.3 531 216.5 551C214.4 566.1 212.9 577.2 212.1 583.9C211.8 586.9 211.1 589.5 210.5 589.6C210 589.8 201.6 586 192 581.2Z"
        fill="#042460"
      />
      <path
        d="M456 570.9C456.1 570.7 457 566.9 458.1 562.5C459.2 558.1 460.6 552.7 461.1 550.5C462.5 544.9 463.3 541.9 468.4 521.6C470.9 511.8 473.5 503 474.2 502.1C477.2 498.2 481.7 493.1 487.5 486.9C490.9 483.2 494.8 478.9 496 477.2L498.3 474.1L501.9 476.1C505.6 478.1 527.5 493.1 529.3 494.9C530.5 496.1 519.7 512.1 510.7 522.5C502.7 531.7 487.1 547.3 479 554.1C469.6 562 455.9 572 456 570.9Z"
        fill="#042460"
      />
      <path
        d="M150.5 555.8C144.5 551.3 136.5 544.6 132.7 541L125.7 534.5L143.6 533.9C173.8 532.9 186.7 528.7 199.5 515.7C205.5 509.6 206.9 507.5 208.8 501.7C213.3 488.1 211.5 477.3 202.9 465.5C195.3 455.1 178.8 440.8 162.3 430.3C141.7 417 110.9 401.4 95.2 396.1C86.7 393.2 71.6 391 60.2 391H50.8L51.2 383C51.5 375.5 51.6 375 53.8 375C55.9 375 56 374.6 56 367.5C56 363.2 56.4 360 57 360C57.6 360 58 360.4 58 360.9C58 362.9 79.1 372.3 95.3 377.5C113.2 383.3 137.4 389.1 152.5 391.2C157.5 391.8 167.8 392.4 175.5 392.4C193.9 392.5 201.8 390.3 209 383.1C213.2 378.8 213.9 377.6 214.5 372.7C216.5 356.5 207.1 340.9 183.5 321.4C173.8 313.4 173.5 313.2 174.3 315.3C175.6 318.7 170.8 317.1 164.1 311.7C155.9 305.1 143.8 296.7 134.3 291.1C129.3 288.2 127 286.2 127 284.9C127 283.5 126.3 283 124.1 283C122.3 283 113.6 279.2 102.8 273.6C92.7 268.5 80.6 262.5 75.8 260.4C69.3 257.5 67 256 67 254.6C67 253.5 67.5 253 68.3 253.3C69.2 253.6 71.1 250.7 74 244.4C76.5 239.2 78.9 235 79.5 235C80 235 83 230.1 86 224.2C90.2 215.9 92 213.4 93.7 213.2C95.4 212.9 95.8 213.2 95.4 214.4C95 215.5 95.4 216 96.7 216C99.5 216 104.9 220.2 116.5 231.4C132.8 247.2 145 258.1 153.8 265C158.5 268.6 161.6 271.6 161 272C160.4 272.4 152 274.4 142.4 276.6C132.8 278.7 125 280.8 125 281.2C125 283.5 131.5 282.9 147.4 279.1C157 276.8 165.6 275.2 166.7 275.6C167.7 275.9 171.7 277.7 175.5 279.5C179.4 281.4 186.1 283.9 190.5 285C194.9 286.1 199.2 287.8 200.1 288.8C201 289.7 203 294.6 204.5 299.5C206 304.5 214 328.6 222.1 353.1C233.4 386.8 237 398.8 237 402.6C237 405.3 235.6 416.7 233.9 428C232.2 439.3 230.4 451.2 230 454.5C228.4 465.6 226.9 477.5 226.1 483.5C225.2 491.1 223.9 493.5 214.3 504.6C210 509.6 196.8 525 185 538.8C173.2 552.7 163.1 564 162.5 564C161.9 564 156.5 560.3 150.5 555.8Z"
        fill="#042460"
      />
      <path
        d="M1515.2 546C1517.4 539.5 1523.9 538.2 1530.9 543C1533.3 544.7 1536.1 546 1537.2 546C1539.1 546 1543 542.7 1543 541C1543 540.5 1543.7 540 1544.5 540C1546.8 540 1546.3 542.5 1543.4 545.9C1539.9 550.1 1535.2 550.1 1529.1 546C1523.1 541.9 1520.6 542.1 1518.1 546.8C1517.5 548 1516.3 549 1515.5 549C1514.4 549 1514.3 548.4 1515.2 546Z"
        fill="#042460"
      />
      <path
        d="M477.1 488.9C477.5 486.5 478.8 480.9 479.9 476.6C481.1 472.2 482 467.9 482 466.9C482 463.9 483.9 463.7 488.3 466.3C497 471.4 497 471.2 486 482.9L476.2 493.3L477.1 488.9Z"
        fill="#042460"
      />
      <path
        d="M527.5 489.2C511.1 478.4 502.1 471.6 502.8 470.5C503.2 469.8 506.2 466.3 509.5 462.6C517.6 453.5 521.2 451.7 532.7 451.2C541.5 450.8 542 450.9 544.4 453.3C546.2 455.2 547 457 547 459.5C547 463.2 543.4 473.5 539.7 480C536.5 485.9 532.5 492 532 491.9C531.7 491.9 529.7 490.7 527.5 489.2Z"
        fill="#042460"
      />
      <path
        d="M3136 301.5V117H3168H3200V301.5V486H3168H3136V301.5Z"
        fill="#042460"
      />
      <path
        d="M3432 406V326H3339.5H3247V298V270H3339.5H3432V193.5V117H3464H3496V301.5V486H3464H3432V406Z"
        fill="#042460"
      />
      <path
        d="M230 482.3C230 480.7 230.4 476.9 230.9 473.9C231.4 470.9 232.6 463.1 233.5 456.5C234.4 449.9 235.5 441.6 236 438C236.5 434.4 237.3 428.2 237.9 424.2C238.5 420.2 239 415.5 239 413.7C239 412 239.4 410 239.8 409.3C240.8 407.7 256.4 453.6 255.4 455.2C254.1 457.4 234.2 481.5 232 483.5C230 485.3 230 485.3 230 482.3Z"
        fill="#042460"
      />
      <path
        d="M754 301V116.9L893.8 117.3C1031.5 117.6 1033.7 117.7 1044.5 119.8C1100.2 130.5 1134.9 165.1 1144.6 219.5C1147.1 233.5 1147.1 263.6 1144.6 277.5C1139.4 306.3 1127.6 329.5 1109 347.4C1087.4 368.2 1060.8 379.3 1025.7 382C1019 382.5 980.1 383 939.3 383H865V352V321H942.4C1026.6 321 1030.1 320.8 1043.9 315.3C1067.3 306 1081 287.2 1084.2 259.9C1086.2 242.8 1082.6 224.3 1074.8 211.3C1068.1 200.2 1057.1 191.2 1044.2 186.3C1030.4 181.1 1030.4 181.1 919.8 181H817V333V485H785.5H754V301Z"
        fill="#042460"
      />
      <path
        d="M1183 301V117H1347H1511V149V181H1378.5H1246V301V421H1379H1512V453V485H1347.5H1183V301Z"
        fill="#042460"
      />
      <path
        d="M1706.8 483.9C1685.1 481.8 1666 476.2 1645.5 466C1627.4 456.9 1615.2 448.2 1601 434C1566.8 399.8 1548.8 356 1547.3 303.5C1546.2 266.5 1552.4 237.7 1567.6 207.9C1589.2 165.8 1627.8 135.5 1674.9 123.5C1698.7 117.5 1693.8 117.7 1814.3 117.3L1925 116.9V149V181H1819.8C1704.3 181 1704.8 181 1686.8 187C1646.3 200.6 1619.2 234 1612 279.5C1610.4 289.7 1610.6 313.9 1612.5 324.5C1619.2 362.6 1641.3 393.3 1673.5 409.1C1684.6 414.5 1696.8 418.3 1708.4 419.9C1713.4 420.6 1745.7 421 1799.6 421H1883V373V325H1800H1717V298V271H1832H1947V378V485L1831.3 484.9C1767.6 484.8 1711.6 484.4 1706.8 483.9Z"
        fill="#042460"
      />
      <path
        d="M2115 333V181H2045H1975V149V117H2147H2319V149V181H2249H2179V333V485H2147H2115V333Z"
        fill="#042460"
      />
      <path
        d="M2352 301V117H2516H2680V149V181H2547.5H2415V301V421H2548H2681V453V485H2516.5H2352V301Z"
        fill="#042460"
      />
      <path
        d="M2875.1 483.9C2814.8 477.6 2764.2 442.7 2737.1 388.8C2726.8 368.4 2720.5 347.8 2717.5 325.1C2715.8 312.6 2715.6 284 2717 272C2727.1 190.8 2779.7 134.7 2860 119.9L2872.5 117.6L2982.8 117.2L3093 116.9V149V181H2988.6C2919.7 181 2881.5 181.4 2876.4 182.1C2851.4 185.6 2829.1 196.9 2811.8 214.8C2795.6 231.7 2786.3 250.3 2781.4 275.6C2779 287.6 2779 312.1 2781.4 325C2789.1 368.1 2817.3 402.1 2855.8 414.9C2872.8 420.6 2866.4 420.3 2984.3 420.7L3093 421.1V453V485L2988.3 484.9C2930.6 484.8 2879.7 484.3 2875.1 483.9Z"
        fill="#042460"
      />
      <path
        d="M493.8 466C491.3 464.4 488.9 462.7 488.6 462.2C488.3 461.7 492.2 460.1 497.3 458.7C510.7 455 511.1 455 510.2 456.5C509.5 457.7 499.1 469 498.7 469C498.6 469 496.4 467.6 493.8 466Z"
        fill="#042460"
      />
      <path
        d="M258 449.3C258 447.4 257.3 445.4 251.5 429.5C244.7 411.2 242 401.7 242 396.5C242 388.8 246.9 351.5 250.1 335.7C250.6 333 251 329.8 251 328.5C251 327.2 251.5 323.9 252.1 321.2L253.1 316.2L259 314.6C266 312.6 265.3 312.4 280 322.6C285.2 326.3 293.8 332.2 299 335.8C335.1 360.6 335.4 360.8 333.8 362.9C333.1 363.8 329.8 367.6 326.5 371.5C323.2 375.4 318.3 381.2 315.5 384.5C312.8 387.7 307.8 393.6 304.5 397.5C301.1 401.3 292.4 411.7 285 420.5C277.6 429.3 270.5 437.6 269.2 439C268 440.4 265.3 443.6 263.2 446.2C259.2 451.3 258 452 258 449.3Z"
        fill="#042460"
      />
      <path
        d="M236.8 385.8C235.9 383.4 233.4 375.9 231.2 369C228.9 362.1 221.9 341.2 215.6 322.5C209.2 303.8 204 288 204 287.4C204 286.8 205.8 285.5 208 284.5C210.3 283.5 213 281.9 214.1 280.9C216.1 279.1 216.5 279.3 226.9 286.3L237.6 293.5L237.2 298.5C236.8 304.5 239.1 308.7 244.9 312.7C249.4 315.8 249.6 316.7 248 326.4C246.3 337.4 244.2 352.3 243 361.5C242.4 366.5 241.5 372.9 240.9 375.9C240.4 378.9 240 382.7 240 384.4C240 389.9 238.5 390.5 236.8 385.8Z"
        fill="#042460"
      />
      <path
        d="M343.5 362C342 360.3 341.9 359.8 343.1 358.3C346.2 354.4 373.9 321.2 380.6 313.3C384.8 308.5 385.3 308.2 388.8 308.7C394.1 309.4 400.5 307.4 402.9 304.3C405.4 301.2 405.7 295.2 403.6 291.1L402.1 288.3L426.7 259.4C440.2 243.5 455.6 225.3 460.9 219C466.2 212.7 471.2 207 472 206.3C473.9 204.9 526.6 192.1 531.2 192C531.6 192 533.2 193.9 534.9 196.3C536.5 198.6 539.1 201.9 540.5 203.5C542.9 206.2 543.1 207 542.5 211.5C542.2 214.3 541.5 218.1 541 220C540.5 221.9 538.2 230.9 536 240C533.8 249.1 530.8 260.8 529.4 266C528 271.2 525.8 279.5 524.6 284.4L522.5 293.2L506 293.5C471.9 294.1 429.6 304.6 401.2 319.6C383.1 329.1 373.8 336.2 357 353.4C351.3 359.2 346.3 364 345.9 364C345.6 364 344.5 363.1 343.5 362Z"
        fill="#042460"
      />
      <path
        d="M319.5 345.4C309.6 338.5 300.4 332.1 299 331.2C278.8 317.8 267.9 309.6 268.5 308.1C268.9 307.2 269.8 304.4 270.6 301.7C272 297.4 271.9 296.6 270.2 292.7C268.9 289.7 266.8 287.4 263.1 285L257.9 281.5L258.4 276.5C259.5 266.2 261.1 253.6 261.4 253.3C261.5 253.2 273.5 250.4 288.1 247.1C302.6 243.8 324.9 238.8 337.5 236C350.2 233.1 367 229.3 374.9 227.5L389.3 224.3L391.4 226.7C394.4 230.3 398.3 232 403.1 232C406.4 232 407.8 231.4 410.6 228.6C412.5 226.7 414 224.2 414 222.9C414 221.6 414.4 220 414.8 219.4C415.2 218.8 425.9 216 438.5 213.1C451.2 210.2 462.6 207.6 463.9 207.3C465.8 206.8 464.2 209.2 456.4 218.6C451 225.1 446.1 231 445.5 231.6C445 232.2 440 238 434.5 244.5C429 251.1 420.5 261.1 415.5 266.8C410.6 272.5 405 279.2 403.1 281.5C400.1 285.3 399.4 285.7 397.3 284.9C391.3 282.7 384.1 284.9 381.6 289.8C378.9 295.2 378.7 298.8 380.9 302.6L383.1 306.2L380.8 308.4C377.1 311.9 370.8 319 366 325.1C353.2 341 338.3 358.3 337.6 357.9C337.5 357.9 329.4 352.3 319.5 345.4Z"
        fill="#042460"
      />
      <path
        d="M567.5 298.4C561.5 297.1 549.8 295.5 541.5 294.9C533.3 294.2 526.4 293.6 526.3 293.5C526 293.3 531.9 270 536.4 253C537.8 247.8 540.3 237.9 542 231C543.7 224.1 545.4 217.2 545.8 215.5L546.6 212.5L551.1 219.5C557.7 229.9 563.4 241.4 571.6 261.2C577.5 275.7 584 296 584 300.1C584 301.4 579.7 301 567.5 298.4Z"
        fill="#042460"
      />
      <path
        d="M228.4 282.9L218.3 275.9L220.6 269.3C221.9 265.7 223 262.7 223.2 262.5C223.6 262.2 257.5 254.1 257.6 254.3C257.8 254.6 256.2 266.8 254.9 275.1L253.9 281.8L250.2 282.4C247.9 282.7 245.1 284.3 242.7 286.5C240.7 288.4 238.9 290 238.7 289.9C238.6 289.9 234 286.7 228.4 282.9Z"
        fill="#042460"
      />
      <path
        d="M223.4 239.3C223.5 226.9 223 218.6 222.2 215C220.6 208.6 216.3 194.4 215.1 191.5C214.6 190.4 212.9 186.4 211.4 182.5C208.3 174.7 197.8 154 194.9 149.8C193.8 148.4 193 146.9 193 146.5C193 146.2 190.3 142.1 187 137.5L181.1 129.1L185.8 125.7C188.4 123.8 191.5 121.7 192.8 121.1C194.1 120.4 195.5 118.8 196 117.4C196.6 116 197.8 115 198.9 115C200.3 115 200.8 115.5 200.4 116.5C199.7 118.2 201.1 118.5 202.6 117C204.5 115.1 220.1 111.9 226.2 112.2C231.4 112.5 232.6 112.9 234.2 115.1C237.7 119.7 240 131.3 240 143.9C240 155.5 242 174.7 244 182.7C246.6 193.5 253.1 203.4 260.5 208C263.6 209.9 263.8 210.4 263.4 214.3C262.8 219.3 261.7 226.9 259.4 240.6L257.7 250.8L241.1 254.4C232 256.4 224.3 258 223.9 258C223.6 258 223.4 249.6 223.4 239.3Z"
        fill="#042460"
      />
      <path
        d="M262.4 246.8C262.7 245.5 263.4 240.5 264 235.5C265.1 226.5 267.2 212.7 267.5 212.2C267.6 212.1 269.7 212.7 272.1 213.6C278.6 215.9 287.4 215.7 294.5 213C302 210.1 313 199.5 318.3 190C329.5 169.6 336.9 134.8 337 101.7C337 89 337 89 339.3 89.5C340.5 89.7 349.2 91.1 358.5 92.5C387.3 96.8 419.9 107.3 446 120.8C459.9 128 494.2 152.2 501.3 159.8L504.1 162.8L496.4 171.6C492.2 176.5 485 185.1 480.3 190.6C475.7 196.2 470.7 201.3 469.3 201.9C466 203.4 446.9 208.1 428.6 212L414.7 214.9L411.1 210.9C406.6 205.8 400.5 204.6 395.3 207.9C392.2 209.9 389 215.4 389 219C389 220.4 387.1 221.1 379.8 222.7C374.7 223.8 349.4 229.6 323.5 235.5C265.2 248.8 264.8 248.9 263.2 249C262.4 249 262.1 248.2 262.4 246.8Z"
        fill="#042460"
      />
      <path
        d="M483.2 192.7C486.7 188.7 492.7 181.6 496.5 176.8C500.4 172.1 504.6 167.6 505.9 166.9C508.1 165.6 508.4 165.8 511.3 169.5C513 171.7 517.8 177 521.8 181.2C528.8 188.4 529.1 188.9 526.9 189.5C516.9 191.8 478.8 200 478 200C477.4 200 479.8 196.7 483.2 192.7Z"
        fill="#042460"
      />
      <path
        d="M301 633.4C299.1 633.2 292.6 632.5 286.5 632C246.5 628.1 196.5 609.8 161.8 586.4C146.2 575.9 146.9 576.4 132.5 565.1C120.4 555.6 91.3999 525.6 79.3999 510C74.3999 503.6 59.9999 482.4 59.9999 481.4C59.9999 481.2 58.9999 479.3 57.6999 477.2C54.6999 472.5 49.3999 462.8 44.1999 452.5C32.3999 429.2 30.7999 420.4 36.6999 411.9L39.8999 407.3L57.6999 406.6C77.9999 405.8 85.5999 406.6 97.9999 410.9C124.6 420 162 441.9 180.3 458.9C191.9 469.8 193.6 475.8 187 482.1C182.5 486.4 175.5 488.9 162.5 491.1C154 492.6 95.4999 492.9 86.5999 491.6C83.4999 491.1 81.9999 491.2 81.9999 492C81.9999 493.7 91.3999 507.2 99.5999 517.2C131.2 555.7 180.9 588.1 231 602.8C290 620.1 354.8 620 406.5 602.4C422.8 596.9 451.5 582 468.5 570.2C506 544.2 547 494.2 558 461.1C561.1 451.8 561.5 446.5 559.5 439C553.8 416.6 537.5 409.2 506.9 414.9C493.6 417.5 483.3 420.1 458.5 427.5C422.2 438.3 409.7 441 396.1 441C380.5 441 369.6 436.7 360.6 426.9C353.4 419.1 351 413.7 351 405.1C351 390.6 361.9 372.7 381 356C399.1 340.2 411 333.2 432.6 325.7C461.1 315.8 500.8 311.7 532 315.3C577.9 320.7 609.6 338.4 624.9 367.2C633.4 383.1 635.5 395.5 633.4 415.5C632.7 422.1 631.8 428.6 631.5 430C631.1 431.4 630.4 434.5 629.8 437C624 461.8 611.4 489.6 596.6 510C583.6 528.1 557.8 554.9 541 567.9C497.1 601.9 443.8 623.1 380.6 631.6C370.1 633.1 309.8 634.4 301 633.4Z"
        fill="#FF0006"
      />
      <path
        d="M50.2999 374.3C35.6999 351.8 31.7999 345.7 29.9999 342.6C22.2999 329.1 20.2999 325.5 16.4999 318C14.0999 313.3 11.3999 307.7 10.4999 305.5C9.59995 303.3 8.39995 300.6 7.89995 299.5C5.19995 293.3 3.89995 289.1 1.49995 278C-3.70005 255.2 5.39995 240.5 25.4999 239.2C33.0999 238.8 34.7999 239.3 65.9999 252C80.2999 257.8 125 279.8 125 281C125 281.5 126 282.2 127.2 282.6C132.1 284.1 155.4 299.3 166 307.8C174.3 314.5 186.7 328.7 190 335.4C196.5 348.3 192 358.6 178.2 362.6C170.3 364.9 151.9 364.7 136.8 362.1C114.3 358.2 85.8999 350 73.7999 343.9C66.7999 340.4 63.4999 340.2 61.7999 343.3C58.6999 348.8 58.0999 353.2 58.6999 369.6C58.9999 378.6 58.9999 386 58.5999 386C58.2999 386 54.4999 380.7 50.2999 374.3Z"
        fill="#FF0006"
      />
      <path d="M1294 298V271H1396H1498V298V325H1396H1294V298Z" fill="#FF0006" />
      <path
        d="M2463 298V271H2564.5H2666V298V325H2564.5H2463V298Z"
        fill="#FF0006"
      />
      <path
        d="M182.2 261C168.2 256 151 242.9 126.5 218.7C118.9 211.1 111.5 204.7 110.3 204.4C106.8 203.5 102.4 205.7 98.7 210.4C95 215.1 84.2 235.9 81.4 243.7C80.4 246.6 79.4 248.8 79.2 248.5C78.9 248.3 78.4 243.2 77.9 237.3C77.4 231.4 76.6 223.1 76 219C74.8 210.3 73.3 197.4 72 184.5C71.5 179.6 70.4 169.7 69.5 162.5C64.6 122.3 67.6 104.6 81.8 90.6C91.2 81.3 98.8 77 105.7 77C111.6 77 125.9 84.7 136 93.3C159.3 113.1 183.8 144.4 195.4 169.1C202.9 185.1 206.5 196.8 209.5 215.9C215 250.7 204 268.9 182.2 261Z"
        fill="#FF0006"
      />
      <path
        d="M272.9 186.4C267.5 184.8 259.6 178.2 257.6 173.7C253.5 164.8 252.9 160.5 251.9 136.8C250.6 103.8 249.7 102.2 234.2 103.3C220.3 104.2 204.6 111 198.7 118.5C197.1 120.5 195.6 121.9 195.4 121.7C194.3 120.6 199.1 105.6 207.1 85C225 39.2 248.7 9.5 273.3 1.9C300.9 -6.7 320 14.4 326.5 60.6C329.2 80.2 328.1 113.7 324 131.7C320.8 145.5 319.5 150.4 317 156.5C310.1 173.4 302.1 183.4 292.7 186.9C288.8 188.4 278.4 188.1 272.9 186.4Z"
        fill="#FF0006"
      />
    </Icon>
  );
}
