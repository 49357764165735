import { Box, Button, Text } from "@chakra-ui/react";
import { Popover } from "components/popover";
import { Filter2Icon } from "components/vectors/filter-icon";
import {
  initialKanbanColumns,
  parseOrigem,
} from "../kanban-and-table-atendimento/kanban/parts/utils";
import { useEffect, useState } from "react";
import { NewDropdown } from "components/dropdown/new-dropdown";
import {
  dropdownConvenioAtendimento,
  dropdownTabulacao,
} from "../atendimento-form/fields-data";
import { CalendarFilter } from "./calendar-filter";
import api from "api/api";
import { DropdownOptionProps } from "components/dropdown";
import { useAttendanceKanbanContext } from "../kanban-and-table-atendimento/kanban/kanban-context";
import { use3cPlusContext } from "contexts/Context3cPlus";
import { GanhosPerdidosSwitch } from "../pagina-atendimento/ganhos-perdidos-switch";
import { Toast } from "components/toast";
import { TabulacaoType } from "../kanban-and-table-atendimento/kanban/parts/types";

export interface AttendanceFilterProps {
  etapa?: string[];
  usersIds?: number[];
  convenios?: string[];
  dataCriacaoInicial?: string;
  dataCriacaoFinal?: string;
  cpfNbFone?: string;
  resultado?: "GANHO" | "PERDIDO";
  tabulacao?: TabulacaoType;
  //
  origem?: string;
  margem?: { min?: number; max?: number };
}

export function AttendanceFilter() {
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const [usersList, setUsersList] = useState<DropdownOptionProps[]>([]);
  const [isLoadingUsers, setIsLoadingUsers] = useState(false);
  const {
    attendanceFilter,
    setAttendanceFilter,
    getAllColumnAttendances,
    isLoadingAttendances,
  } = useAttendanceKanbanContext();

  const { controls3cPlus } = use3cPlusContext();

  const getUsers = async () => {
    setIsLoadingUsers(true);
    try {
      const { data } = await api.get("/usuarios/possible-transfer-attendance");
      setUsersList(
        data.map((user: any) => ({ name: user.name, value: user.id }))
      );
    } catch (e) {
      Toast({ title: "Erro ao carregar usuários!", status: "error" });
    } finally {
      setIsLoadingUsers(false);
    }
  };

  const onChange = (value: any, key: keyof AttendanceFilterProps) => {
    setErrors((err) => {
      delete err[key];
      return err;
    });
    if (Array.isArray(value))
      setAttendanceFilter((filter) => {
        if (!value.length) {
          delete filter[key];
          return { ...filter };
        }
        return { ...filter, [key]: value };
      });
    else {
      if (value == null || value === "")
        setAttendanceFilter((attendanceFilter) => {
          delete attendanceFilter[key];
          return { ...attendanceFilter };
        });
      else setAttendanceFilter({ ...attendanceFilter, [key]: value });
    }
  };

  const onChangeMultiselect = (
    value: any,
    field: "usersIds" | "etapa" | "convenios" | "origem" | "tabulacao"
  ) => {
    const currentEl = attendanceFilter[field] as any[] | null;
    if (currentEl?.length && currentEl != null) {
      const isInclude = currentEl.includes(value);
      if (isInclude)
        onChange(
          currentEl.filter((curr: any) => curr !== value),
          field
        );
      else onChange([...attendanceFilter[field]!, value], field);
    } else onChange([value], field);
  };

  useEffect(() => {
    getUsers();
  }, []);

  const sliderMax = 1000;
  const sliderMin = 0;

  const changeSlider = (slider: HTMLInputElement, value: string) => {
    slider.value = value;
    const progress =
      ((parseInt(value) - sliderMin) / (sliderMax - sliderMin)) * 100;
    slider.style.backgroundSize = `${progress}% 100%`;
  };

  const columnsOptions = initialKanbanColumns.map(({ colName, colId }) => ({
    name: colName,
    value: colId,
  }));

  return (
    <Popover
      button={
        <Button
          variant="ghost"
          alignItems="center"
          cursor="pointer"
          _hover={{ bg: "gray.200" }}
          px="10px"
          borderRadius="6px"
          leftIcon={<Filter2Icon />}
          fontSize="14px"
          fontWeight="medium"
          isDisabled={controls3cPlus.inCall || isLoadingAttendances}
        >
          Filtrar por
        </Button>
      }
      title="Filtrar por"
      position="left-bottom"
      withOutCloseButton
      popupStyles={{ minWidth: "320px" }}
      contentProps={{ padding: "0px" }}
    >
      {({ onClose }) => (
        <>
          <Box overflow="auto" maxHeight="300px" padding="8px">
            <Box mb="20px">
              <Text fontSize="14px" fontWeight="medium" mb="8px">
                Usuário
              </Text>
              <NewDropdown
                multiSelect
                isLoading={isLoadingUsers}
                options={usersList}
                onChange={(option) =>
                  onChangeMultiselect(option.value, "usersIds")
                }
                value={attendanceFilter.usersIds}
                isDisabled={isLoadingAttendances}
              />
            </Box>
            <Box mb="20px">
              <Text fontSize="14px" fontWeight="medium" mb="8px">
                Origem
              </Text>
              <NewDropdown
                isLoading={isLoadingUsers}
                options={[{ name: "Todas", value: undefined }, ...origens]}
                onChange={(option) => onChange(option.value, "origem")}
                value={attendanceFilter.origem}
                isDisabled={isLoadingAttendances}
                placeholderRender={() =>
                  origens.find(({ value }) => value === attendanceFilter.origem)
                    ?.name ?? "Todas"
                }
              />
            </Box>
            <Box mb="20px">
              <Text fontSize="14px" fontWeight="medium" mb="8px">
                Convênio
              </Text>
              <NewDropdown
                multiSelect
                options={dropdownConvenioAtendimento}
                value={attendanceFilter.convenios}
                onChange={(option) => {
                  onChangeMultiselect(option.value, "convenios");
                }}
                isDisabled={isLoadingAttendances}
              />
            </Box>
            <Box mb="20px">
              <Text fontSize="14px" fontWeight="medium" mb="8px">
                Tabulações
              </Text>
              <NewDropdown
                multiSelect
                options={dropdownTabulacao}
                value={attendanceFilter.tabulacao}
                onChange={(option) => {
                  onChangeMultiselect(option.value, "tabulacao");
                }}
                isDisabled={isLoadingAttendances}
              />
            </Box>
            <Box mb="20px">
              <Text mb="8px" fontWeight="medium">
                Data de criação
              </Text>
              <CalendarFilter onChange={onChange} filter={attendanceFilter} />
            </Box>
            <Box mb="20px">
              <Text mb="8px" fontWeight="medium">
                Ganhos / Perdidos
              </Text>
              <GanhosPerdidosSwitch
                onChange={onChange}
                attendanceFilter={attendanceFilter}
              />
            </Box>
          </Box>
          <Box padding="8px">
            <Button
              w="100%"
              size="lg"
              fontSize="14"
              borderRadius="4"
              onClick={() => {
                getAllColumnAttendances();
                onClose();
              }}
            >
              Aplicar
            </Button>
          </Box>
        </>
      )}
    </Popover>
  );
}

const origens = Array.from(parseOrigem.entries()).map(([value, name]) => {
  if (name === "LP") name = "Landing Page";
  return { name, value };
});
