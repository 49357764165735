import { Attendance } from "../types";

export function setAndResetFields(
  fieldName: keyof Attendance,
  value: string,
  resetFieldsList: (keyof Attendance)[],
  onChangeField: (
    fieldName: keyof Attendance,
    value: string | boolean | null
  ) => void
) {
  onChangeField(fieldName, value);

  resetFieldsList.forEach((fieldName) => {
    if (fieldName === "needTokenTabela") {
      onChangeField(fieldName, false);
    } else onChangeField(fieldName, "");
  });
}
