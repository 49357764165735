import { extendTheme, UseToastOptions } from "@chakra-ui/react";

export const defaultScroll = {
  "&::-webkit-scrollbar": { width: "7px", height: "7px", display: "initial" },
  "&::-webkit-scrollbar-track": { width: "7px" },
  "&::-webkit-scrollbar-thumb": {
    background: "#bbb",
    borderRadius: "10px",
    display: "initial",
  },
  scrollbarColor: "#bbb transparent",
  scrollbarWidth: "thin",
};

export const invalidTextStyle = {
  color: "secondary.600",
  fontWeight: "600",
  marginTop: "10px",
  fontSize: "14",
};

export const slimScroll = {
  "::-webkit-scrollbar": {
    width: `3px`,
    height: `3px`,
    display: "block",
  },
  "::-webkit-scrollbar-thumb": {
    borderRadius: `10px`,
    backgroundColor: `#bbb`,
    display: "block",
  },
  "::-webkit-scrollbar-corner": { display: `none` },
};

export const removeScroll = {
  "::-webkit-scrollbar": {
    display: "none",
  },
  "::-webkit-scrollbar-thumb": {
    display: "none",
  },
  "::-webkit-scrollbar-corner": { display: `none` },
};

export const toastDefaultStyle: UseToastOptions = {
  containerStyle: {
    background: "var(--chakra-colors-primary-400)",
    boxShadow: "0 0 1em rgba(0,0,0, 0.2)",
    borderRadius: "7px",
    color: "white",
  },
  variant: "none",
  duration: 4000,
  position: "top",
  isClosable: true,
};

export const defaultTransition = "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)";

const theme = extendTheme({
  styles: {
    global: {
      body: {
        fontFamily: "Poppins, sans-serif",
        fontSize: "14px",
        maxWidth: "100vw",
        color: "#343a40",
        ...defaultScroll,
      },
      h3: {
        fontSize: "24px",
        marginBottom: "10px",
      },
    },
  },
  fonts: {
    heading: "Poppins, sans-serif",
    mono: "Poppins, sans-serif",
  },
  colors: {
    primary: {
      50: "#E1E4F5",
      100: "#BEC6E9",
      200: "#3E54B7",
      300: "#2d3d85",
      400: "#202c5f",
      500: "#141B3B",
      600: "#0F152E",
      700: "#0C1022",
      800: "#080B17",
      900: "#04050B",
      950: "#030408",
    },
    secondary: {
      400: "#e5484d",
      500: "#E30613",
      600: "#BD000B",
      700: "#822025",
    },
    third: {
      50: "#F9FAFB",
      100: "#F3F5F7",
      200: "#E6EAEF",
      300: "#D6DDE5",
      400: "#CAD3DD",
      500: "#BDC8D5",
      600: "#8C9FB6",
      700: "#5D7592",
      800: "#3F5064",
      900: "#202832",
      950: "#101419",
    },
    text: "#00023E",
    "custom-gray": "#BDC8D5",
    "bg-gray": "rgba(10,40,130, 0.04)",
    progressColor: { 500: "#BDC8D5" },
  },
  borders: {
    "default-border": "1px solid var(--chakra-colors-third-200)",
    "default-border-dark": "1px solid var(--chakra-colors-third-200)",
  },
  components: {
    Button: {
      variants: {
        whatsapp: {
          bg: "#076058",
          _hover: { bg: "#00a884" },
          _loading: {
            _hover: { bg: "#076058" },
            opacity: 0.5,
            cursor: "not-allowed",
          },
          color: "#ffffff",
          _disabled: {
            _hover: { bg: "#076058" },
            opacity: 0.5,
            cursor: "not-allowed",
          },
        },
        default: {
          bg: "primary.500",
          color: "white",
          _hover: { bg: "primary.400" },
          _disabled: {
            _hover: { bg: "var(--chakra-colors-primary-400)!important" },
          },
        },
        danger: {
          color: "#fff",
          bg: "var(--chakra-colors-secondary-700)",
          border: "1px solid var(--chakra-colors-secondary-400)",
          _hover: {
            bg: "var(--chakra-colors-secondary-400)!important",
            color: "#ffffff",
          },
          fontWeight: "bold",
        },
        "outline-custom": {
          borderBottom: "1px solid #9CA2AB",
          color: "#4E5762",
          _hover: { bg: "" },
          height: "32px",
          borderRadius: "0px",
          fontSize: "14px",
          padding: "4px 2px",
        },
        outline: {
          border: "1px solid #4E5762 !important",
          fontWeight: "semibold",
          _hover: { bg: "var(--chakra-colors-gray-100)" },
          _active: { bg: "var(--chakra-colors-gray-200)" },
          _loading: {
            bg: "var(--chakra-colors-gray-200)",
            _hover: { bg: "var(--chakra-colors-gray-200)" },
          },
        },
        smallDropdown: {
          // Fazer styles dropdown pequeno de cores
          borderRadius: "6px",
          height: "30px",
          padding: "0px 9px",
          bg: "#CBFFCD",
          border: "1px solid #E8EAED",
          fontSize: "12px",
        },
      },
      baseStyle: {
        borderRadius: "5px",
        fontFamily: "Poppins, sans-serif",
        _loading: {
          opacity: "50%",
          cursor: "not-allowed",
          _hover: { bg: "primary.400" },
          disabled: true,
        },
        _disabled: {
          opacity: "50%",
          cursor: "not-allowed",
        },
      },
      defaultProps: {
        variant: "default",
        size: "sm",
      },
    },
    Input: {
      variants: {
        "outline-custom": {
          field: {
            height: "32px",
            borderRadius: "0px",
            borderBottom: "1px solid #9CA2AB",
            _placeholder: { fontSize: "14px", color: "#343B44" },
            color: "#4E5762",
            borderTop: "none",
            borderRight: "none",
            borderLeft: "none",
            _focus: {
              borderTop: "none",
              borderRight: "none",
              borderLeft: "none",
              borderBottom: "1px solid #9CA2AB",
            },
            fontSize: "14px",
            padding: "4px 2px",
          },
        },
        "outline-gray": {
          field: {
            height: "32px",
            _placeholder: { fontSize: "14px", color: "#343B44" },
            fontSize: "14px",
            border: "1px solid #d9d9d9",
          },
        },
        defaultStyle: {
          field: {
            height: "32px",
            _placeholder: { fontSize: "14px", color: "#343B44" },
            fontSize: "14px",
          },
        },
      },

      baseStyle: {
        field: {
          border: "1px solid var(--chakra-colors-custom-gray)",
          color: "primary.500",
          bg: "none",
          _hover: { bg: "bg-gray" },
          _disabled: { opacity: "50%", cursor: "not-allowed" },
          _invalid: { border: "3px solid var(--chakra-colors-secondary-600)" },
          _focus: {
            outline: "none",
            boxShadow: "none",
            borderStyle: "solid",
            borderWidth: "1px",
            borderColor: "var(--chakra-colors-custom-gray)",
          },
        },
      },
      defaultProps: { variant: "defaultStyle" },
    },
    Textarea: {
      baseStyle: {
        _invalid: { border: "3px solid var(--chakra-colors-secondary-600)" },
      },
    },
    Th: {
      variants: {
        default: {
          p: "8px 14px",
        },
      },
      baseStyle: {},
      defaultProps: {
        variant: "default",
      },
    },
  },
});

export default theme;
