import {
  Box,
  ButtonProps,
  Center,
  Flex,
  Modal,
  ModalBody,
  ModalBodyProps,
  ModalCloseButton,
  ModalContent,
  ModalContentProps,
  ModalFooter,
  ModalFooterProps,
  ModalHeader,
  ModalHeaderProps,
  ModalOverlay,
  ModalProps,
  Spinner,
} from "@chakra-ui/react";
import { defaultScroll } from "chakra/theme";
import { ReactNode, RefObject, useEffect, useRef } from "react";

interface CustomModalProps
  extends Omit<ModalContentProps, "closeOnOverlayClick"> {
  children?: ReactNode;
  modalFooter?: string | ReactNode;
  modalTitle?: string | ReactNode;
  isOpen: boolean;
  onClose?: () => void;
  size?:
    | "xs"
    | "sm"
    | "md"
    | "lg"
    | "xl"
    | "2xl"
    | "3xl"
    | "4xl"
    | "5xl"
    | "6xl"
    | "full";
  withOutBlur?: boolean;
  scroll?: "outside" | "inside";
  isLoading?: boolean;
  finalFocusRef?: RefObject<any>;
  closeOnOverlayClick?: ModalProps["closeOnOverlayClick"];
  id?: string;
  modalCloseButtonProps?: ButtonProps;
  modalFooterProps?: ModalFooterProps;
  modalHeaderProps?: ModalHeaderProps;
  modalBodyProps?: ModalBodyProps;
}

export function CustomModal({
  children,
  modalFooter,
  modalTitle,
  isOpen,
  onClose,
  size,
  withOutBlur,
  isLoading,
  scroll,
  finalFocusRef,
  closeOnOverlayClick,
  modalCloseButtonProps,
  modalFooterProps,
  modalHeaderProps,
  modalBodyProps,
  id,
  ...rest
}: CustomModalProps) {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose || (() => {})}
      isCentered
      size={size}
      scrollBehavior={scroll}
      finalFocusRef={finalFocusRef}
      closeOnOverlayClick={closeOnOverlayClick}
    >
      <ModalOverlay backdropFilter={withOutBlur ? undefined : "blur(8px)"} />
      <ModalContent borderRadius="5px" pointerEvents="all" {...rest}>
        {modalTitle ? (
          <ModalHeader {...modalHeaderProps}>{modalTitle}</ModalHeader>
        ) : null}
        {onClose ? (
          <ModalCloseButton
            color="#707070"
            zIndex="1"
            {...modalCloseButtonProps}
          />
        ) : null}
        <ModalBody
          sx={defaultScroll}
          pos="relative"
          id={id}
          style={{ padding: "8px 24px" }}
          overflow={
            scroll === "inside" ? (isLoading ? "hidden" : "auto") : undefined
          }
          {...modalBodyProps}
        >
          {isLoading ? <LoadingContainer modalSize={size} /> : null}
          {children}
        </ModalBody>
        {modalFooter ? (
          <ModalFooter gap="15px" {...modalFooterProps}>
            {modalFooter}
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
}

function LoadingContainer({ modalSize }: { modalSize?: string }) {
  const isFull = modalSize === "full";

  return (
    <Flex
      pos={isFull ? "fixed" : "absolute"}
      top={isFull ? "62px" : "0"}
      right="0"
      bottom={isFull ? "72px" : "0"}
      left="0"
      backdropFilter="blur(8px)"
      bg="rgba(255,255,255, 0.1)"
      zIndex="100"
      alignItems="center"
      justifyContent="center"
    >
      <Spinner zIndex="101" />
    </Flex>
  );
}
