import { Box, Image } from "@chakra-ui/react";
import { Logo } from "components/vectors/logo";
import { ActosLogo } from "components/vectors/logo/actos-logo";
import { CapitalSolucoesLogo } from "components/vectors/logo/capital-solucoes-logo";
import { CorbexLogo } from "components/vectors/logo/corbex";
import { EntrouNaConta } from "components/vectors/logo/entrounaconta";
import { EX3CompanyLogo } from "components/vectors/logo/ex3-company";
import { ForcePromotoraLogo } from "components/vectors/logo/force-promotora";
import { LogoImediata } from "components/vectors/logo/imediata";
import { MaisAgilLogo } from "components/vectors/logo/mais-agil-logo";
import { MelhorCredito } from "components/vectors/logo/melhor-credito";
import { MendesPromotora } from "components/vectors/logo/mendes-promotora";
import { LogoNexttPromotora } from "components/vectors/logo/next-promotora";
import { PegTechLogo } from "components/vectors/logo/pegtech-logo";
import { PopCashLogo } from "components/vectors/logo/popcash-logo";
import { QfazLogo } from "components/vectors/logo/qfaz-logo";
import { RagazziLogo } from "components/vectors/logo/ragazzi";
import { SharkLogo } from "components/vectors/logo/shark";
import { SolidaPromotora } from "components/vectors/logo/solida-promotora";
import { VisaoVendas } from "components/vectors/logo/visao-vendas";
import { W2Promotora } from "components/vectors/logo/w2-promotora";
import { WepayLogo } from "components/vectors/logo/wepay";
import { getDomainKeySelect } from "utils/verify-domain";

export const getLogo = () => {
  return (
    {
      maisagil: <MaisAgilLogo />,
      comprex: (
        <Image width="100%" src="/assets/layout/images/comprex-logo.png" />
      ),
      pl30: <Image width="100%" src="/assets/layout/images/pl30-logo.png" />,
      imediata: <LogoImediata />,
      suapromotora: (
        <Image width="100%" src="/assets/layout/images/sua-promotora.png" />
      ),
      solassessoria: (
        <Image width="90%" src="/assets/layout/images/logo-sol-promotora.png" />
      ),
      w2promotora: <W2Promotora />,
      corbex: <CorbexLogo />,
      larpromotora: (
        <Image width="100%" src="assets/layout/images/logo-lar-promotora.png" />
      ),
      ragazzi: <RagazziLogo />,
      sharkpromotora: <SharkLogo />,
      melhorcredito: <MelhorCredito />,
      xforcepromotora: <ForcePromotoraLogo />,
      solidapromotora: <SolidaPromotora />,
      entrounaconta: <EntrouNaConta />,
      mendespromotora: (
        <Box w="80%" mx="auto">
          <MendesPromotora />
        </Box>
      ),
      visaopromotora: <VisaoVendas />,
      wepaypromotora: <WepayLogo />,
      ex3company: (
        <Box w="80%" mx="auto">
          <EX3CompanyLogo />
        </Box>
      ),
      nexttpromotora: <LogoNexttPromotora />,
      actos: <ActosLogo />,
      qfaz: <QfazLogo />,
      capitalsolucoes: <CapitalSolucoesLogo />,
      pegtech: <PegTechLogo />,
      popcash: <PopCashLogo />,
    }[getDomainKeySelect()] ?? <Logo />
  );
};

export const getNavbarLogo = () => {
  return (
    {
      maisagil: <MaisAgilLogo width="80px" height="auto" />,
      comprex: (
        <Image width="180px" src="/assets/layout/images/comprex-logo.png" />
      ),
      pl30: <Image width="80px" src="/assets/layout/images/pl30-logo.png" />,
      imediata: <LogoImediata width="140px" />,
      suapromotora: (
        <Image width="180px" src="/assets/layout/images/sua-promotora.png" />
      ),
      solassessoria: (
        <Image
          width="140px"
          src="/assets/layout/images/logo-sol-promotora.png"
        />
      ),
      w2promotora: <W2Promotora width="180px" />,
      corbex: <CorbexLogo width="180px" />,
      larpromotora: (
        <Image
          width="180px"
          src="assets/layout/images/logo-lar-promotora.png"
        />
      ),
      ragazzi: <RagazziLogo width="180px" />,
      sharkpromotora: <SharkLogo width="180px" />,
      melhorcredito: <MelhorCredito width="150px" />,
      xforcepromotora: <ForcePromotoraLogo width="170px" />,
      solidapromotora: <SolidaPromotora width="150px" />,
      entrounaconta: <EntrouNaConta width="150px" />,
      mendespromotora: <MendesPromotora width="90px" />,
      visaopromotora: <VisaoVendas width="100px" />,
      wepaypromotora: <WepayLogo width="160px" />,
      ex3company: <EX3CompanyLogo width="100px" />,
      nexttpromotora: <LogoNexttPromotora width="120px" />,
      actos: <ActosLogo width="100px" />,
      qfaz: <QfazLogo width="55px" />,
      capitalsolucoes: <CapitalSolucoesLogo width="220px" />,
      pegtech: <PegTechLogo width="220px" />,
      popcash: <PopCashLogo width="80px" />,
    }[getDomainKeySelect()] ?? <Logo width="177px" height="34px" />
  );
};

export const getTitle = () => {
  return (
    {
      maisagil: "+Ágil Promotora",
      comprex: "Comprex Promotora",
      pl30: "PL30 Promotora",
      imediata: "Imediata Brasil Promotora",
      suapromotora: "Sua Promotora",
      solassessoria: "Sol Assessoria",
      w2promotora: "W2 Promotora",
      corbex: "Corbex Promotora",
      larpromotora: "Lar Promotora",
      ragazzi: "Ragazzi Promotora",
      sharkpromotora: "Shark Promotora",
      melhorcredito: "Melhor Crédito Promotora",
      xforcepromotora: "Xforce Promotora",
      solidapromotora: "Sólida Promotora",
      entrounaconta: "Entrou Na Conta Promotora",
      mendespromotora: "Mendes Promotora",
      visaopromotora: "Visão Promotora",
      wepaypromotora: "Wepay Promotora",
      ex3company: "EX3 Promotora",
      nexttpromotora: "Nextt Promotora",
      actos: "Actos Promotora",
      qfaz: "Qfaz Promotora",
      capitalsolucoes: "Capital Soluções Promotora",
      pegtech: "PegTech Promotora",
      popcash: "PopCash Promotora",
    }[getDomainKeySelect()] ?? "Nova Promotora"
  );
};

export const getFavIcon = () => {
  var link: HTMLLinkElement = document.querySelector(
    "link[rel~='icon']"
  ) as HTMLLinkElement;

  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.head.appendChild(link);
  }
  if (getDomainKeySelect() === "solidapromotora") {
    link.href = "/favicon-solida.png";
  }
};
