import { Box, Center, Flex, Text } from "@chakra-ui/react";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { DropdownField, DropdownFieldProps } from "components/dropdown-field";
import { useAttendanceContext } from "contexts/attendance-context";
import { memo } from "react";
import { colorsMap, tagTextMap } from "./attendance-input";
import { AttendanceKey } from "contexts/attendance-context/actions-reducer";

interface AttendanceDropdownProps extends Partial<DropdownFieldProps> {
  isVisible?: () => boolean;
  fieldName?: AttendanceKey;
  customTag?: string;
}

function AttendanceDropdown({
  title,
  isVisible,
  fieldName,
  customTag,
  ...rest
}: AttendanceDropdownProps) {
  const { dropdownProps, onChange, ...restDropdownFieldProps } = rest || {};
  const { formControl, dispatch } = useAttendanceContext();
  const isInvalid = formControl.invalidFields.includes(fieldName!);
  const jsonSerialized = JSON.parse(
    formControl.values.sourceFieldsJson || "{}"
  );
  let updatedBy = jsonSerialized[fieldName || ""]?.updatedBy;

  const color = colorsMap.get(customTag || updatedBy);
  const text = tagTextMap.get(customTag || updatedBy);

  const removeKeyOfJSON = () => {
    if (fieldName && jsonSerialized[fieldName]) {
      delete jsonSerialized[fieldName];
      const stringfy = JSON.stringify(jsonSerialized);
      dispatch({
        type: "changeField",
        payload: {
          data: stringfy,
          fieldName: "sourceFieldsJson",
        },
      });
    }
  };

  if (isVisible == null || isVisible?.()) {
    return (
      <Box>
        {title ? (
          <Flex mb="8px" alignItems="center" justifyContent="space-between">
            <Text fontWeight="medium" {...restDropdownFieldProps?.titleProps}>
              {title}
            </Text>
            {text ? (
              <Center
                p="2px 5px"
                fontSize="11"
                borderRadius="5px"
                bg={color}
                color="#fff"
              >
                {text}
              </Center>
            ) : null}
          </Flex>
        ) : null}
        <DropdownField
          isInvalid={isInvalid}
          dropdownProps={{
            w: "100%",
            variant: "outline-custom",
            ...dropdownProps,
          }}
          onChange={(a, b) => {
            onChange?.(a, b);
            removeKeyOfJSON();
          }}
          {...restDropdownFieldProps}
        />
      </Box>
    );
  }
  return null;
}

export default memo(AttendanceDropdown);
