import { AddIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import api from "api/api";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { FaSave } from "react-icons/fa";
import { WhatsAppSessionListProps } from ".";
import { SendJsonMessage } from "react-use-websocket/dist/lib/types";
import { openModalError } from "components/modal-error";
import { Toast } from "components/toast";

export function AddWhatsAppServer({
  setLoading,
  loading,
  setServers,
  servers,
  sendJsonMessage,
}: {
  servers: WhatsAppSessionListProps[];
  setLoading: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
  setServers: Dispatch<SetStateAction<WhatsAppSessionListProps[]>>;
  sendJsonMessage: SendJsonMessage;
}) {
  const { isOpen, onToggle, onClose } = useDisclosure();
  const [serverName, setServerName] = useState("");
  const [internalLoading, setInternalLoading] = useState(false);

  const onClosePopover = () => {
    setServerName("");
    onClose();
  };

  const handleSubmit = async () => {
    setInternalLoading(true);
    if (serverName.trim())
      try {
        const { data } = await api.post("/whatsapp-sessions", {
          nome: serverName,
        });
        sendJsonMessage({ action: "update-pm2" });
        setServers([...servers, data]);
        Toast({
          title: "Servidor Criado",
        });
      } catch (e: any) {
        const data = e?.response?.data;
        const errorMessage: string | undefined =
          typeof data?.message === "string" ? data.message : undefined;
        openModalError({
          title:
            errorMessage === "exceeded_maximum_session" ? "Atenção" : "Erro",
          message:
            errorMessage === "exceeded_maximum_session"
              ? "Não é possivel criar novas sessões! Verifique a quantidade contratada."
              : "Não foi possível criar a sessão!",
        });
      } finally {
        setInternalLoading(false);
        onClosePopover();
      }
    else Toast({ title: "Preencha o nome" });
  };

  useEffect(() => {
    setInternalLoading(false);
  }, [servers]);

  return (
    <Popover placement="bottom-start" isOpen={isOpen} onClose={onClosePopover}>
      <PopoverTrigger>
        <Button disabled={loading} onClick={onToggle} leftIcon={<AddIcon />}>
          Criar Sessão
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>Criar Sessão WhatsApp</PopoverHeader>
        <PopoverBody>
          <Box w="100%">
            <Text mb="8px">Nome da sessão:</Text>
            <Input
              value={serverName}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) handleSubmit();
              }}
              onChange={(e) => setServerName(e.target.value)}
            />
            <Flex w="100%" justifyContent="flex-end">
              <Button
                size="sm"
                isLoading={internalLoading}
                loadingText="Criando Sessão"
                mt="10px"
                onClick={handleSubmit}
                leftIcon={<FaSave />}
                disabled={serverName.trim() === ""}
              >
                Criar Sessão
              </Button>
            </Flex>
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
}
