import { Box, Button, Center, Flex, Input, Text } from "@chakra-ui/react";
import {
  CustomTableColor,
  CustomTableColorProps,
} from "components/dynamic-table/color-table";
import { PeopleHand } from "components/vectors/people-hand";
import { Dispatch, SetStateAction } from "react";
import {
  convenioColors,
  getNameInitials,
  parseConvenio,
} from "../../kanban-and-table-atendimento/kanban/parts/utils";
import api from "api/api";
import { Toast } from "components/toast";
import { maskCPF } from "utils/string-formats";
import { updateAttendances } from "../../kanban-and-table-atendimento/kanban/kanban-context";
import { openModalConfirm } from "components/modal-confirm-new";
import { CheckIcon, CloseIcon, SearchIcon } from "@chakra-ui/icons";
import { useRouter } from "routes/router-control/use-router";

export const CreateWithRegisteredCustomer = ({
  inputSearch,
  setInputSearch,
  searchResult,
  setSearchResult,
  isLoading,
  setIsLoading,
  setModalLoading,
  onClose,
}: {
  inputSearch: string;
  setInputSearch: Dispatch<SetStateAction<string>>;
  setSearchResult: Dispatch<SetStateAction<any[]>>;
  searchResult: any[];
  isLoading?: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setModalLoading: Dispatch<SetStateAction<boolean>>;
  onClose: () => void;
}) => {
  const router = useRouter();
  const onSearch = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`/atendimentos/templates`, {
        params: { nbOrCpfOrTelefone: inputSearch },
      });
      setSearchResult(data);
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  };

  // useInputDelay(onSearch, [inputSearch]);

  const createAttendance = async (row: any) => {
    setModalLoading(true);
    const getKeys = [
      "nb",
      "convenio",
      "cpf",
      "dataNascimento",
      "nome",
      "mailingLeadId",
    ];
    const body: any = {};
    getKeys.forEach((key) => {
      body[key] = row[key];
    });
    try {
      const { data } = await api.post("/atendimentos", body);
      updateAttendances();
      openModalConfirm({
        message: "Atendimento criado. Deseja iniciar o atendimento?",
        onConfirm: () => {
          const init = data.etapa === "PENDENTE";
          router.push(`/atendimento-cliente-page?id=${data.id}&init=${init}`);
        },
        confirmButtonStyle: { variant: undefined, leftIcon: <CheckIcon /> },
        rejectButtonStyle: { leftIcon: <CloseIcon w="12px" h="12px" /> },
      });
      onClose();
    } catch (e) {
      Toast({ title: "Erro ao criar atendimento", status: "error" });
    } finally {
      setModalLoading(false);
    }
  };

  const columns: CustomTableColorProps["columns"] = [
    {
      name: "CONVÊNIO",
      render: (row) => {
        return row.convenio ? (
          <Flex>
            <Flex
              alignItems="center"
              onChange={(newConvenio) => {}}
              borderRadius="6px"
              fontWeight="medium"
              fontSize="11px"
              h="27px"
              p="3px 8px"
              bg={convenioColors.get(row.convenio!) ?? "#F3EBA4"}
            >
              {parseConvenio(row.convenio)}
            </Flex>
          </Flex>
        ) : null;
      },
    },
    {
      name: "CPF",
      render: (row) => {
        return maskCPF(row.cpf);
      },
    },
    {
      name: "CLIENTE",
      render: (row) => {
        return row.nome ? (
          <Flex alignItems="center">
            <Center
              w="30px"
              h="30px"
              minW="30px"
              minH="30px"
              pos="relative"
              borderRadius="full"
              bg="#BCBCBC"
              mr="10px"
            >
              <Text fontSize="13px">{getNameInitials(row.nome)}</Text>
            </Center>
            <Text>{row.nome}</Text>
          </Flex>
        ) : (
          "(Sem nome)"
        );
      },
    },
    {
      name: "AÇÕES",
      render: (row) => {
        return (
          <Flex>
            {/* <IconButton
              aria-label=""
              bg="linear-gradient(to right top, #27D144, #59F472)"
              variant="none"
              _hover={{ opacity: 0.8 }}
              size="xs"
              h="27px"
              w="27px"
              icon={<WhatsAppIcon width="16px" height="16px" color="white" />}
              mr="8px"
            /> */}
            <Button
              size="xs"
              onClick={() => createAttendance(row)}
              variant="outline"
            >
              Iniciar Atendimento
            </Button>
          </Flex>
        );
      },
    },
  ];

  return (
    <Flex flexDir="column" w="100%" alignItems="center">
      <Center mb="20px">
        <PeopleHand />
        <Text ml="13px" fontSize="24px" fontWeight="semibold" mb="50px">
          Iniciar atendimento com clientes cadastrados
        </Text>
      </Center>
      <Flex w="100%" mb="20px">
        <Box w="100%">
          <Text fontSize="16" fontWeight="medium" mb="8px">
            Informe o benefício ou CPF ou telefone
          </Text>
          <Input
            w="100%"
            h="40px"
            color="#4E5762"
            onChange={(e) => setInputSearch(e.target.value.replace(/\D/g, ""))}
            mr="10px"
            value={inputSearch}
            onKeyDown={(e) => {
              if (e.key === "Enter" && e.currentTarget.value) {
                onSearch();
              }
            }}
          />
        </Box>

        <Box ml="15px">
          <Text fontSize="16" fontWeight="medium" mb="8px">
            &nbsp;
          </Text>
          <Button h="36px" leftIcon={<SearchIcon />} onClick={onSearch}>
            Pesquisar
          </Button>
        </Box>
      </Flex>
      <CustomTableColor
        columns={columns}
        rows={searchResult}
        isLoading={isLoading}
        loadingRowsCount={4}
        containerProps={{ overflowY: "visible", overflowX: "visible" }}
      />
    </Flex>
  );
};
