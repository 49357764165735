import { INITIAL_ATTENDANCE_VALUES } from "contexts/attendance-context";
import {
  Attendance,
  Product,
} from "../../components/atendimentos-components/atendimento-form/types";

export type AttendanceActionsTypes =
  | {
      type: "changeField" | "changeFieldWithInitial";
      payload: { fieldName: keyof Attendance; data: any };
    }
  | {
      type: "closeAttendance" | "discardChanges";
      payload?: undefined;
    }
  | {
      type: "setExtratoCopiado";
      payload: boolean;
    }
  | {
      type: "replaceAttendance" | "initAttendance";
      payload: Attendance;
    }
  | {
      type: "addProduct";
      payload: Product;
    }
  | {
      type: "deleteProduct";
      payload: number;
    }
  | {
      type: "updateProduct";
      payload: { updatedItem: Product; index: number };
    }
  | {
      type: "setInvalidField";
      payload:
        | {
            fieldName: AttendanceKey;
            action: "append" | "clear";
          }
        | { fieldName?: undefined; action: "clear" };
    };

export type AttendanceKey = keyof Attendance | `${keyof Attendance}.${string}`;
export interface FormControl {
  values: Attendance | Partial<Attendance>;
  initialValues: Attendance | Partial<Attendance>;
  pendingChanges: boolean;
  invalidFields: AttendanceKey[];
  isExtratoCopiado: boolean;
}

export type AttendanceOperations =
  | "initAttendance"
  | "closeAttendance"
  | "changeField"
  | "changeFieldWithInitial"
  | "discardChanges"
  | "replaceAttendance"
  | "addProduct"
  | "deleteProduct"
  | "updateProduct"
  | "setInvalidField";

export let controllerAttendanceRequests = new AbortController();
export let currentAttendanceForm: FormControl = INITIAL_ATTENDANCE_VALUES;

export function dispatchAttendance(
  state: FormControl,
  action: AttendanceActionsTypes
): FormControl {
  const { type, payload } = action;
  const { values, initialValues, invalidFields } = state;
  const produtosFgts = values.produtosFgts as any[];

  switch (type) {
    case "initAttendance":
      currentAttendanceForm = {
        ...state,
        values: payload,
        initialValues: { ...payload },
        pendingChanges: false,
        isExtratoCopiado: false,
      };
      return currentAttendanceForm;

    case "closeAttendance":
      controllerAttendanceRequests.abort();
      controllerAttendanceRequests = new AbortController();
      currentAttendanceForm = { ...INITIAL_ATTENDANCE_VALUES };
      return currentAttendanceForm;

    case "changeField":
      currentAttendanceForm = {
        ...state,
        values: { ...values, [payload.fieldName]: payload.data },
        pendingChanges: true,
      };
      return currentAttendanceForm;

    case "changeFieldWithInitial":
      currentAttendanceForm = {
        ...state,
        initialValues: { ...initialValues, [payload.fieldName]: payload.data },
        values: { ...values, [payload.fieldName]: payload.data },
      };
      return currentAttendanceForm;

    case "discardChanges":
      currentAttendanceForm = {
        ...state,
        values: { ...initialValues },
        pendingChanges: false,
        isExtratoCopiado: false,
        invalidFields: [],
      };
      return currentAttendanceForm;

    case "replaceAttendance":
      currentAttendanceForm = {
        ...state,
        values: payload,
        pendingChanges: true,
      };
      return currentAttendanceForm;

    case "setInvalidField":
      let fields = invalidFields;
      if (payload.fieldName) {
        if (payload.action === "append") fields.push(payload.fieldName);
        else if (payload.action === "clear")
          fields = fields.filter((f) => f !== payload.fieldName);
      } else {
        if (payload.action === "clear") fields = [];
      }
      currentAttendanceForm = { ...state, invalidFields: fields };
      return currentAttendanceForm;

    case "addProduct":
      currentAttendanceForm = {
        ...state,
        values: { ...values, produtosFgts: [...produtosFgts, payload] },
        pendingChanges: true,
      };
      return currentAttendanceForm;

    case "deleteProduct":
      const deletedProduct = produtosFgts.filter(
        (_, index) => index !== payload
      );
      currentAttendanceForm = {
        ...state,
        values: { ...values, produtosFgts: deletedProduct },
        pendingChanges: true,
      };
      return currentAttendanceForm;

    case "updateProduct":
      const updatedProduct = produtosFgts.map((product, i) =>
        i === payload.index ? payload.updatedItem : product
      );
      currentAttendanceForm = {
        ...state,
        values: { ...values, produtosFgts: updatedProduct },
        pendingChanges: true,
      };
      return currentAttendanceForm;

    case "setExtratoCopiado":
      currentAttendanceForm = {
        ...state,
        isExtratoCopiado: payload,
      };
      return currentAttendanceForm;

    default:
      currentAttendanceForm = state;
      return currentAttendanceForm;
  }
}
