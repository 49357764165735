import { Icon, IconProps } from "@chakra-ui/react";

export function TableIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="16px"
      height="16px"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x="0.771973"
        y="1.11485"
        width="14.3544"
        height="3.56304"
        rx="1.27679"
        stroke="currentColor"
      />
      <rect
        x="0.771973"
        y="6.38861"
        width="14.3544"
        height="3.56304"
        rx="1.27679"
        stroke="currentColor"
      />
      <rect
        x="0.771973"
        y="11.6624"
        width="9.96139"
        height="3.56304"
        rx="1.27679"
        stroke="currentColor"
      />
    </Icon>
  );
}
