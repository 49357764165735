import { Icon, IconProps } from "@chakra-ui/react";

export function ForcePromotoraLogo({ ...rest }: IconProps) {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 4181 1304"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1810 1301.9C1801 1300.3 1788.5 1295.5 1780.8 1290.7C1754.2 1274.1 1740.5 1243.5 1742.3 1204.5C1744 1167.4 1759.7 1140.4 1788 1126C1808.6 1115.6 1839.3 1114.3 1862.1 1123.1C1881.2 1130.4 1896.6 1145.1 1905.4 1164.2C1917.7 1190.8 1917.8 1230.2 1905.5 1256.8C1898 1272.8 1885.9 1285.7 1871.1 1293.4C1857.4 1300.5 1850.2 1302.1 1831.5 1302.5C1822.7 1302.7 1813 1302.5 1810 1301.9ZM1842.5 1276.5C1861.2 1271.6 1875.7 1255.9 1881.7 1234C1884.3 1224.7 1884.2 1196.3 1881.7 1187C1874 1159.5 1854 1142.8 1828.5 1142.8C1814.1 1142.8 1801.2 1148 1791.1 1158C1777.3 1171.6 1770.1 1196.9 1773 1221.5C1777.1 1256.4 1797.8 1277.8 1827.5 1277.9C1832.6 1278 1839.2 1277.3 1842.5 1276.5Z"
        fill="#131A5B"
      />
      <path
        d="M2288 1301.5C2261.6 1296.1 2242.7 1281.4 2231.4 1257.5C2225 1243.9 2222.9 1234.3 2222.3 1215.8C2221.5 1192.8 2224.4 1177.1 2232.5 1161C2242.7 1140.7 2261.4 1126 2284.5 1119.9C2296.1 1116.8 2319 1116.8 2331.3 1119.8C2355.1 1125.7 2373.3 1140.4 2384 1162.5C2391.9 1178.9 2393.5 1186.8 2393.5 1210.5C2393.5 1227.9 2393.2 1231.6 2391.2 1239.4C2388.4 1250.5 2381.8 1264.5 2375.5 1272.9C2365.1 1286.6 2347.6 1297.4 2329.8 1301C2318.8 1303.3 2298.1 1303.5 2288 1301.5ZM2321.9 1276.5C2341 1271.5 2354.8 1256.6 2361.2 1234.3C2364.2 1223.8 2364.4 1200.5 2361.7 1188.7C2356.3 1165.8 2340.4 1148.6 2320.5 1144C2317.7 1143.3 2312.1 1142.8 2308 1142.8C2286.8 1142.8 2269.5 1153.8 2260.2 1173C2254.8 1184.2 2253 1191.9 2252.3 1207C2251.9 1217.3 2252.2 1221.3 2253.9 1229.5C2258.9 1253.7 2273.5 1270.9 2293.7 1276.5C2300.2 1278.3 2315.3 1278.3 2321.9 1276.5Z"
        fill="#131A5B"
      />
      <path
        d="M2583.8 1302C2557.5 1297.5 2535.7 1280.9 2524.5 1256.8C2512.2 1230.1 2512.2 1191 2524.4 1164.4C2535.1 1141.3 2554 1125.7 2578.3 1120C2612.8 1111.9 2646.1 1121.5 2666.5 1145.2C2675.5 1155.6 2681.1 1167.2 2685.2 1183.7C2687.1 1191.1 2687.4 1195.3 2687.4 1210.5C2687.4 1225.7 2687.1 1229.9 2685.2 1237.3C2680.7 1255.5 2674.6 1267.2 2663.7 1278.6C2652.1 1290.9 2636.1 1299.2 2619 1301.9C2611.6 1303.1 2590.8 1303.2 2583.8 1302ZM2620.3 1275.1C2638.7 1267.9 2651.9 1251 2656.1 1229C2657.9 1219.2 2657.7 1200 2655.6 1190.5C2649.1 1161.2 2628.3 1142.8 2601.5 1142.8C2576.1 1142.8 2555.6 1159.8 2548.3 1187C2546.9 1192 2546.5 1197.4 2546.5 1211C2546.5 1226.5 2546.8 1229.3 2548.8 1236C2555.5 1257.2 2568.9 1271.4 2587.1 1276.5C2595.3 1278.8 2612.8 1278 2620.3 1275.1Z"
        fill="#131A5B"
      />
      <path
        d="M2854.5 1301.4C2844.1 1299.2 2832.1 1293 2826.6 1286.9C2816.2 1275.3 2812.5 1258.3 2816.5 1239.6C2820.2 1221.9 2835.1 1208.7 2857.5 1203.1C2868.7 1200.4 2885 1197.3 2895 1196C2927 1192 2935.5 1186.8 2935.4 1171.3C2935.3 1162.3 2931.7 1154.3 2925.8 1150C2911.8 1139.8 2879.4 1140.2 2862.8 1150.7C2856.5 1154.6 2851 1165.2 2851 1173.2V1176H2836.4H2821.7L2822.4 1169.8C2823.9 1156.3 2830.5 1143.2 2840.3 1134.5C2854.6 1121.6 2882 1115.2 2911 1117.9C2935 1120.2 2947.4 1125.8 2956.9 1139C2963.4 1148 2963.4 1147.3 2964 1213C2964.3 1245.7 2964.9 1273.2 2965.3 1274.2C2966.6 1276.8 2971.6 1278.3 2977 1277.7L2982 1277.1V1288.1V1299.1L2978.3 1300.7C2976 1301.7 2970.6 1302.5 2964.9 1302.8C2956.7 1303.2 2954.7 1302.9 2950.2 1301C2947.3 1299.7 2943.9 1297.4 2942.5 1295.8C2939.7 1292.5 2937 1284.3 2937 1279.1V1275.7L2931.2 1281.4C2920.2 1292.2 2909 1298.1 2892.8 1301.4C2883.2 1303.4 2864.1 1303.4 2854.5 1301.4ZM2896.5 1276.5C2912.3 1273.1 2925.4 1264 2931.2 1252.2L2934.5 1245.6L2934.8 1226.3L2935.1 1207.1L2930.8 1209.4C2925 1212.5 2920.5 1213.5 2896.2 1217C2868.7 1220.9 2860 1223.9 2852.3 1232.2C2847.3 1237.7 2845.6 1242.5 2845.6 1251.5C2845.6 1264.8 2853.9 1274.1 2868.4 1277C2875.1 1278.3 2889 1278.1 2896.5 1276.5Z"
        fill="#131A5B"
      />
      <path
        d="M2068.3 1299.2L2054.1 1298.9L2053.8 1234.2C2053.5 1175.1 2053.3 1169 2051.6 1164.1C2046.6 1149 2037.7 1142.9 2020.5 1142.8C1999.5 1142.6 1982.3 1154.7 1974.6 1174.9C1972.6 1180.4 1972.5 1182 1972.2 1239.8L1971.9 1299H1957.5H1943V1210V1121H1956.5H1970V1134.5C1970 1141.9 1970.3 1148 1970.8 1148C1971.2 1147.9 1973.1 1145.6 1975 1142.9C1979.5 1136.4 1989.4 1128 1997.3 1124.2C2007.4 1119.2 2015 1117.7 2029 1117.7C2040 1117.7 2042.5 1118 2049.5 1120.4C2063.1 1125 2071.3 1132 2075.7 1142.6C2076.7 1145 2077.8 1147 2078.1 1147C2078.5 1147 2080 1145.2 2081.5 1142.9C2088.5 1132.9 2101.3 1124.1 2115 1119.9C2121.3 1117.9 2124.6 1117.6 2135.5 1117.6C2143.9 1117.7 2151 1118.3 2155.4 1119.4C2175.1 1124.1 2188 1136.7 2191.9 1154.8C2192.6 1158.4 2193 1181.7 2193 1229.5V1299H2179H2165V1241.3C2165 1209.5 2164.5 1179.2 2164 1174C2161.7 1152.1 2151.2 1142.7 2129.3 1142.8C2108.8 1142.8 2094.5 1151.3 2086.7 1167.8L2083.5 1174.5L2083 1237L2082.5 1299.5L2068.3 1299.2Z"
        fill="#131A5B"
      />
      <path
        d="M2477.5 1299C2466 1298.6 2454.9 1297.8 2452.9 1297.1C2444.6 1294.5 2439.4 1289 2436.3 1279.6C2434.8 1275.1 2434.5 1267.5 2434.2 1210.8L2433.9 1147H2418.9H2404V1134V1121H2419H2434V1094.5V1068H2448.5H2463V1094.5V1121H2481H2499V1134V1147H2481H2463V1205.8C2463 1259.7 2463.1 1264.8 2464.8 1268C2467.2 1272.8 2471 1273.9 2486.3 1273.9L2499 1274V1287C2499 1294.2 2498.9 1299.9 2498.8 1299.8C2498.6 1299.7 2489.1 1299.3 2477.5 1299Z"
        fill="#131A5B"
      />
      <path
        d="M2715 1210.3V1121H2728.5H2742V1140C2742 1150.5 2742.3 1159 2742.8 1159C2743.2 1159 2745.3 1155.7 2747.5 1151.8C2752.9 1142.1 2766.4 1128.3 2774.6 1124.2C2782.3 1120.3 2790.2 1118.3 2800.8 1117.4L2809 1116.7V1132.2V1147.7L2800.3 1148.3C2783.3 1149.4 2771.9 1154 2762 1164C2752.1 1173.9 2747.4 1184.8 2745.1 1204C2744.5 1208.7 2744 1231.5 2744 1255.7V1298.9L2729.5 1299.2L2715 1299.5V1210.3Z"
        fill="#131A5B"
      />
      <path
        d="M1437 1176V1053H1492.4C1523.6 1053 1552.2 1053.5 1557.9 1054C1584.6 1056.8 1602.8 1068.3 1612.5 1088.5C1617.5 1099.1 1618.9 1104.1 1619.9 1116.7C1622.8 1151.3 1610.5 1178.3 1586.6 1190C1570.6 1197.8 1560.8 1198.9 1506.3 1199H1469V1249V1299H1453H1437V1176ZM1555.8 1169.6C1577.6 1164 1588 1149.9 1588 1126.2C1588 1106.2 1580.8 1092.9 1566.6 1086.4C1554.6 1080.9 1552.5 1080.7 1509.3 1080.2L1469 1079.8V1125.2C1469 1150.2 1469.3 1171 1469.8 1171.4C1470.2 1171.8 1488.3 1172 1510 1171.7C1542 1171.3 1550.7 1170.9 1555.8 1169.6Z"
        fill="#131A5B"
      />
      <path
        d="M1648.8 1210L1648.5 1121H1662.3H1676V1140.3C1676.1 1161.5 1675.8 1161 1682 1149.8C1683.9 1146.3 1689.1 1139.9 1693.5 1135.5C1705.4 1123.4 1715.5 1118.9 1733.8 1117.4L1742 1116.7V1132.4V1148H1736.6C1720.8 1148 1702.9 1154.9 1694.3 1164.3C1685.2 1174.3 1680.1 1187.2 1678 1205.5C1677.5 1210.5 1677 1233.5 1677 1256.8V1299H1663H1649L1648.8 1210Z"
        fill="#131A5B"
      />
      <path
        d="M1437.3 481.3L1437.6 128.5L1439.8 118.5C1446.9 87.6 1459.3 64.7 1480.5 43.5C1500.9 23 1523.3 10.8 1554 3.4C1561.1 1.7 1572.3 1.6 1748.3 1.3L1935 1V79V157H1768H1601V239V321H1763H1925V399V477H1763H1601V655.5V834H1519H1437L1437.3 481.3Z"
        fill="#131A5B"
      />
      <path
        d="M2257 832.9C2122.5 821.1 2013.8 724.4 1987.6 593C1982.1 565.7 1980.2 527.9 1983 500.5C1994.2 386.8 2065 292 2170 250C2239.8 222.1 2321 221.3 2392.7 247.7C2408.4 253.5 2435 266.6 2448.9 275.5C2485.9 299 2520 333.4 2542.9 370.2C2566.7 408.3 2581.6 452.4 2586.6 499C2588.8 520 2588.1 556.5 2585 576.5C2569.9 676.1 2510.3 758.2 2421.5 802C2385.6 819.7 2351.4 829.2 2309.5 833C2296.8 834.2 2270.8 834.1 2257 832.9ZM2314 687.5C2344.8 682 2374.3 666.9 2395.5 645.5C2412.6 628.4 2424.3 609.4 2431.9 586.5C2438.2 567.5 2440.4 553.9 2440.4 532C2440.4 511.9 2439.4 503.6 2434.9 486.1C2427.7 458.2 2414.4 435.3 2393.4 415C2367 389.5 2333.6 375.4 2293 372.5C2281.4 371.7 2262.3 373.3 2249.3 376.1C2205.4 385.6 2168.4 413.4 2148 452.2C2135.9 475.1 2130.3 497.3 2129.3 525.5C2127.3 584.8 2153.2 636.5 2199.6 666.2C2216.4 677 2239.8 685.3 2262 688.6C2274.2 690.3 2301.2 689.8 2314 687.5Z"
        fill="#131A5B"
      />
      <path
        d="M2635.3 578.3C2635.5 336.3 2635.7 322.1 2637.4 315C2644.5 285.1 2658.9 262.2 2681.4 244.8C2692.5 236.2 2710.8 227.1 2724.5 223.3L2734.5 220.5L2855.8 220.2L2977 219.9V300V380H2886H2795V607V834H2715H2635L2635.3 578.3Z"
        fill="#131A5B"
      />
      <path
        d="M3276 832.9C3168 823.4 3078.1 763 3032.3 669C2994.3 591 2991 494.4 3023.6 413.2C3047.2 354.4 3088.1 305.7 3141.6 272.8C3211 230.1 3301.4 217.2 3382.8 238.5C3421.3 248.6 3461.3 268.6 3491.5 292.9C3502.9 302 3520.9 319.8 3531.2 332C3538.7 340.9 3552.4 360.7 3558.4 371.2L3561 375.9L3546.8 384.3C3521.9 399 3438.6 447.6 3436.3 448.9C3434.2 449.9 3433.8 449.5 3430 443C3413.8 415.2 3387.9 393.8 3357.5 383C3338.3 376.3 3323.8 373.9 3302 373.9C3259.3 373.9 3222.1 388.8 3194 417C3171.9 439.2 3159.2 463.3 3151.9 497C3149.9 506.3 3149.6 510.3 3149.6 531C3149.6 551.7 3149.9 555.8 3151.8 565C3158.9 597.9 3171.5 621.7 3193.4 643.6C3214.7 664.8 3236.5 676.8 3266 683.6C3311 693.9 3359.9 684.7 3395.1 659.4C3409.3 649.2 3425.6 630.5 3432.9 616.2L3435.1 611.9L3438.3 613.8C3440.1 614.9 3468.9 631.4 3502.3 650.5L3563.1 685.2L3559.9 690.8C3548.8 710.6 3530.9 733.2 3511.2 752.1C3462.6 798.9 3399.6 827 3329.5 833C3315.7 834.1 3289.8 834.1 3276 832.9Z"
        fill="#131A5B"
      />
      <path
        d="M3876 833.4C3849.2 831.5 3817.6 825.8 3795.1 819C3712.2 793.8 3648.5 738.7 3614.3 662.6C3585.7 599.2 3579.4 520.5 3597.2 449.5C3617 370.1 3666.5 304.9 3736.4 266.1C3798.2 231.9 3879.5 220 3951.9 234.6C3993.4 242.9 4035.6 262.7 4070 290C4082.4 299.8 4102.8 320 4112.4 332C4148.6 377.4 4170.6 431 4178.2 493C4180.1 508.7 4179.9 556.4 4177.7 571C4176.8 577.3 4175.8 584.6 4175.4 587.3L4174.7 592H3958.4C3764 592 3742 592.2 3742 593.5C3742 596.4 3749.2 614.5 3754.2 624.1C3776.4 666.9 3819.5 693 3877.9 699.1C3895.9 701 3926.8 699.8 3941.6 696.6C3955.4 693.7 3970.9 688.5 3982.5 683C3998.8 675.2 4017.8 660.9 4027.5 649.2C4031.8 643.9 4032.7 643.3 4034.5 644.2C4038.7 646.5 4150.4 711 4150.8 711.5C4152 712.7 4132.1 736.9 4119.5 749.5C4096.4 772.8 4076.3 787.3 4047.9 801.4C4015.4 817.6 3984.6 826.6 3945 831.6C3933.9 833 3887.4 834.2 3876 833.4ZM4032.2 477.4C4032.6 477.1 4031.7 472.9 4030.4 468C4020.4 431.5 4000.9 403.3 3972.5 384.5C3961.5 377.2 3953.8 373.5 3940.1 369C3924.6 363.8 3913.6 361.9 3894.9 361.3C3840.3 359.4 3794.9 379 3766.4 417C3755.7 431.1 3743.9 456.9 3739.6 475.3L3739 478H3885.3C3965.8 478 4031.9 477.7 4032.2 477.4Z"
        fill="#131A5B"
      />
      <path
        d="M2.1998 807.8C2.9998 806.5 38.8998 745.8 81.9998 672.8L160.3 540L274 540L274 675L274 810L0.799805 810L2.1998 807.8Z"
        fill="url(#paint0_linear_790_9)"
      />
      <path
        d="M274 810L274 675L274 540C269.7 539.9 314.7 540.3 383.4 540.2L508.2 540L506.8 542.3C506 543.5 470.1 604.2 427 677.3L348.8 810L308.6 809.9C286.6 809.8 272.4 810.2 274 810Z"
        fill="url(#paint1_linear_790_9)"
      />
      <path
        d="M516.3 508.6C515.3 507.3 477.4 453.1 432 388.2L349.5 270.1H175.8C80.2 270 2 269.7 2 269.3C2 268.8 37.5 208.4 80.8 135L159.6 1.5L508.5 1L624.8 167.1C688.8 258.4 741.3 333.7 741.6 334.4C742.1 335.6 521.1 511 519.1 511C518.6 511 517.3 509.9 516.3 508.6Z"
        fill="url(#paint2_linear_790_9)"
      />
      <path
        d="M516.3 508.7L626.4 421.7C686.9 373.9 739.1 332.5 740 331.5C740 331.5 744 337.3 812.6 435.3L885.6 539.5L1059.9 539.8C1170.1 539.9 1234 540.4 1233.8 541C1233.6 541.5 1198 602.2 1154.5 675.9L1075.5 809.9L727.2 810L516.3 508.7Z"
        fill="url(#paint3_linear_790_9)"
      />
      <path
        d="M886.499 1H1234.1L1232.8 3.4C1232.1 4.7 1196.4 65.2 1153.5 137.9L1075.6 270H727.899L886.499 1Z"
        fill="url(#paint4_linear_790_9)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_790_9"
          x1="0.90014"
          y1="675.522"
          x2="508.1"
          y2="675.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#041DAC" />
          <stop offset="1" stopColor="#7691EE" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_790_9"
          x1="0.90014"
          y1="675.522"
          x2="508.1"
          y2="675.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#041DAC" />
          <stop offset="1" stopColor="#7691EE" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_790_9"
          x1="2.24358"
          y1="407.004"
          x2="1233.56"
          y2="407.004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#041DAC" />
          <stop offset="1" stopColor="#7691EE" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_790_9"
          x1="2.24358"
          y1="407.004"
          x2="1233.56"
          y2="407.004"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#041DAC" />
          <stop offset="1" stopColor="#7691EE" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_790_9"
          x1="1234"
          y1="136"
          x2="728"
          y2="136"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#7691EE" />
          <stop offset="1" stopColor="#041DAC" />
        </linearGradient>
      </defs>
    </Icon>
  );
}
