import { CloseIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/react";
import api from "api/api";
import { CustomModal } from "components/custom-modal";
import DynamicTable from "components/dynamic-table";
import { Column } from "components/dynamic-table/types";
import { ChangeIcon } from "components/vectors/change-icon";
import { useState } from "react";
import { useEventListener, makeEvent } from "services/events";
import { Attendance } from "../atendimento-form/types";
import { Toast } from "components/toast";

export interface UsersProps {
  id: number;
  username: string;
  name: string;
}

interface AttendanceId {
  id: Attendance["id"];
  ids?: number[];
}

export function ModalChangeUser({
  onUpdate,
}: {
  onUpdate: ({
    newUserName,
    attendanceId,
  }: {
    newUserName: string;
    attendanceId: number;
  }) => void;
}) {
  const [users, setUsers] = useState<UsersProps[]>([]);
  const [changeUserModalData, setChangeUserModalData] =
    useState<AttendanceId | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  async function handleChangeUser({
    toUserId,
    attendanceId,
    newUserName,
  }: {
    toUserId: number;
    attendanceId: number;
    newUserName: string;
  }) {
    setIsLoading(true);
    try {
      const { data } = await api.get(
        `atendimentos/${attendanceId}/transfer/${toUserId}`
      );
      console.log(data);
      Toast({ title: "Atendimento transferido com sucesso!" });
      onClose();
      onUpdate({ newUserName, attendanceId });
    } catch (e) {
      Toast({ title: "Erro ao transferir atendimento!", status: "error" });
    } finally {
      setIsLoading(false);
    }
  }

  const getUsers = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get("/usuarios/possible-transfer-attendance");
      setUsers(data);
    } catch (e) {
      Toast({ title: "Erro ao carregar usuários!", status: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  const onClose = () => {
    setIsOpen(false);
    setChangeUserModalData(null);
    setUsers([]);
    setIsLoading(false);
  };

  const onOpen = (data: AttendanceId) => {
    setIsOpen(true);
    getUsers();
    setChangeUserModalData(data);
  };

  useEventListener("openModalUserTransferAttendance", onOpen);

  const columns: Column[] = [
    {
      name: "Nome do Usuário",
      key: "name",
    },
    {
      name: "Ações",
      render: (row) => (
        <Button
          onClick={() =>
            handleChangeUser({
              attendanceId: changeUserModalData?.id!,
              toUserId: row.id,
              newUserName: row.name,
            })
          }
          leftIcon={<ChangeIcon />}
          size="sm"
          variant="outline"
        >
          Transferir
        </Button>
      ),
    },
  ];

  const footer = (
    <>
      <Button
        onClick={onClose}
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
      >
        Cancelar
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Transferir Atendimento"
      modalFooter={footer}
      size="2xl"
    >
      <DynamicTable
        columns={columns}
        rows={users}
        isLoading={isLoading}
        size="md"
        containerStyle={{ boxShadow: "0 0 1em rgba(0,0,0, 0.05)" }}
      />
    </CustomModal>
  );
}

export const openModalUserTransferAttendance = (data: AttendanceId) =>
  makeEvent("openModalUserTransferAttendance", data);
