import { Box, Button, Flex, Grid, Text } from "@chakra-ui/react";
import apiDigitacao from "api/api-digitacao";
import { CustomModal } from "components/custom-modal";
import { useAttendanceContext } from "contexts/attendance-context";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { makeEvent, useEventListener } from "services/events";
import { ModalSimulacaoApi } from "./requests";
import { DropdownField } from "components/dropdown-field";
import { bancosDigitacaoMailingPipeline } from "components/mailing-components/mailing-options-modal/parts/digitacao-filter";
import {
  dropdownPrazo,
  getSimulacaoBodyType,
  validateSimulacaoFields,
} from "./utils";
import { SimulacaoDataProps, TabelaProps, UsuarioBanco } from "./types";
import {
  Attendance,
  AttendanceMargens,
} from "components/atendimentos-components/atendimento-form/types";
import { InputField } from "components/input-field";
import { Toast } from "components/toast";
import {
  currencyMaskFormat,
  onKeyDownOmitNegativeNumbers,
  onlyNumberValidMask,
} from "utils/string-formats";
import { SimulacaoResultProps } from "..";
import AttendanceInput from "components/atendimentos-components/pagina-atendimento/attendance-field/attendance-input";
import { openModalDigitacaoINSS } from "../modal-digitacao-inss";

export function SimulacaoFields({
  setSimulacao: setSimulacaoAtendimento,
  simulacaoResult,
  getSimulacaoResult,
  onChangeInput,
}: {
  simulacaoResult: SimulacaoResultProps;
  setSimulacao: Dispatch<SetStateAction<SimulacaoResultProps>>;
  getSimulacaoResult: (param?: { pending?: boolean }) => Promise<void>;
  onChangeInput: (
    fieldName: keyof Attendance,
    subKey?: keyof AttendanceMargens
  ) => (value: string) => void;
}) {
  const [simulacao, setSimulacao] = useState<SimulacaoDataProps>({});
  const [loadingSimulacao, setLoadingSimulacao] = useState(false);
  const [usuariosBancos, setUsuariosBancos] = useState<
    { name: string; value: string; completedUser: UsuarioBanco }[]
  >([]);
  // const [selectedUsuariobanco, setSelectedUsuarioBanco] = useState<
  //   Partial<UsuarioBanco>
  // >({});
  const [tabelasList, setTabelasList] = useState<
    { name: string; value: string; completedTable: TabelaProps }[]
  >([]);
  const [loadingDigitacao, setLoadingDigitacao] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const { formControl, dispatch } = useAttendanceContext();
  const attendance = formControl.values as Attendance;
  const isVisibleUsuarioSelect = ["FACTA", "C6"].includes(simulacao.banco!);
  const selectedTabela = tabelasList.find((curr) => {
    return [
      simulacao.codeTable,
      simulacao.tableCode,
      simulacao.tabela,
    ].includes(curr.completedTable.codigo);
  })?.completedTable;
  const selectedUsuariobanco = usuariosBancos.find((curr) => {
    return [
      simulacao.loginVendedor,
      simulacao.vendedor,
      simulacao.username,
    ].includes(curr.completedUser.username);
  })?.completedUser;
  const modalApi = ModalSimulacaoApi();
  const allDisabled = simulacaoResult?.status === "PENDENT";

  const onChangeSimulacao = (key: keyof SimulacaoDataProps, value: any) => {
    setSimulacao((prev) => ({ ...prev, [key]: value }));
    setErrors((errors) => {
      delete errors[key];
      return { ...errors };
    });
  };

  const handleSimular = async () => {
    if (simulacao.banco === "BMG") {
      if (!selectedTabela || !simulacao.banco) {
        return Toast({ title: "Preencha os campos" });
      }
    } else {
      if (!selectedUsuariobanco || !selectedTabela || !simulacao.banco) {
        return Toast({ title: "Preencha os campos" });
      }
    }
    const body = getSimulacaoBodyType(
      attendance,
      simulacao,
      selectedUsuariobanco!,
      selectedTabela
    );
    const { isValid, errors } = validateSimulacaoFields(body!, dispatch);
    if (!isValid) {
      setErrors(errors);
      console.log(errors);
      return;
    }
    setLoadingSimulacao(true);
    try {
      const { data } = await apiDigitacao.post(
        `api/simulacoes/inss/atendimento/${attendance.id}/simular`,
        body
      );
      if (["C6", "FACTA"].includes(simulacao.banco)) {
        setSimulacaoAtendimento(data);
      } else {
        setSimulacaoAtendimento({ status: "PENDENT" });
      }

      if (data.status === "FINISHED") {
        Toast({ title: "Simulação concluída", status: "success" });
      } else if (data.status === "PENDENT") {
        Toast({ title: "Simulação em andamento", status: "info" });
      } else if (data.status === "SYSTEM_ERROR") {
        Toast({
          title: "Erro ao simular",
          description: data.observacao,
          status: "error",
        });
      }
    } catch (e: any) {
      const errorMessage = e?.response?.data?.msgInterface;
      Toast({
        title: "Erro ao simular",
        description: errorMessage,
        duration: 6000,
        status: "error",
      });
    } finally {
      setLoadingSimulacao(false);
    }
  };

  useEffect(() => {
    if (simulacao.banco) {
      if (isVisibleUsuarioSelect) {
        modalApi.getUsuariosBancos(
          simulacao.banco,
          setUsuariosBancos,
          setLoading
        );
      }
      modalApi.getTabelasDigitacao(
        simulacao.banco,
        attendance.convenio!,
        setTabelasList
      );
    }
  }, [simulacao.banco]);

  useEffect(() => {
    if (simulacaoResult) {
      onChangeSimulacao("banco", simulacaoResult.banco);
      if (simulacaoResult.simulacao?.term) {
        onChangeSimulacao("term", `${simulacaoResult.simulacao?.term}`);
      }
      if (simulacaoResult.simulacao?.valueInstallment) {
        onChangeSimulacao(
          "valueInstallment",
          simulacaoResult.simulacao?.valueInstallment
        );
      }
      if (simulacaoResult.simulacao?.username)
        onChangeSimulacao("username", simulacaoResult.simulacao?.username);
      if (simulacaoResult.simulacao?.vendedor)
        onChangeSimulacao("vendedor", simulacaoResult.simulacao?.vendedor);
      if (simulacaoResult.simulacao?.loginVendedor) {
        onChangeSimulacao("loginVendedor", simulacaoResult.simulacao?.vendedor);
      }
      if (simulacaoResult.simulacao?.tabela)
        onChangeSimulacao("tabela", simulacaoResult.simulacao?.tabela);
      if (simulacaoResult.simulacao?.tableCode)
        onChangeSimulacao("tableCode", simulacaoResult.simulacao?.tableCode);
      if (simulacaoResult.simulacao?.codeTable)
        onChangeSimulacao("codeTable", simulacaoResult.simulacao?.codeTable);
      if ((simulacaoResult.simulacao as any)?.codigoTabela) {
        onChangeSimulacao(
          "codeTable",
          (simulacaoResult.simulacao as any)?.codigoTabela
        );
      }
    }
  }, [simulacaoResult]);

  const clearTabela = () => {
    onChangeSimulacao("tabela", undefined);
    onChangeSimulacao("tableCode", undefined);
    onChangeSimulacao("codeTable", undefined);
  };

  const clearUsuarioBanco = () => {
    onChangeSimulacao("loginVendedor", undefined);
    onChangeSimulacao("vendedor", undefined);
    onChangeSimulacao("username", undefined);
  };

  return (
    <Flex flexDir="column" gridArea="auto / span 2">
      <Grid templateColumns={"1fr 1fr"} columnGap="20px" rowGap="40px">
        <AttendanceInput
          title="Margem livre (novo)"
          onChange={onChangeInput("margens", "valorMargemLivreNovo")}
          fieldName="margens.valorMargemLivreNovo"
          value={attendance.margens?.valorMargemLivreNovo}
          inputProps={{
            type: "number",
            onKeyDown: onKeyDownOmitNegativeNumbers,
          }}
          source="margens.fonteEmprestimoNovo"
          mask={onlyNumberValidMask}
        />

        <Box>
          <InputField
            title="Valor Parcela"
            onChange={(e) => {
              onChangeSimulacao(
                "valueInstallment",
                currencyMaskFormat(e.target.value).raw
              );
            }}
            value={currencyMaskFormat(simulacao.valueInstallment).value}
            inputProps={{ variant: "outline-custom" }}
            errorMessage={errors["valueInstallment"]}
            isDisabled={allDisabled}
          />
        </Box>
        <Box>
          <DropdownField
            title="Banco"
            onChange={(value) => {
              onChangeSimulacao("banco", value);
              clearTabela();
              clearUsuarioBanco();
            }}
            value={simulacao.banco}
            options={bancosDigitacaoMailingPipeline}
            dropdownProps={{ w: "100%", variant: "outline-custom" }}
            errorMessage={errors["banco"]}
            isDisabled={allDisabled}
          />
        </Box>
        <Box>
          <DropdownField
            title="Prazo"
            onChange={(value) => {
              onChangeSimulacao("term", value);
            }}
            value={simulacao.term}
            options={dropdownPrazo}
            dropdownProps={{ w: "100%", variant: "outline-custom" }}
            errorMessage={errors["term"]}
            // isLoading={loading}
            isDisabled={allDisabled}
          />
        </Box>
        <Box gridArea="auto / span 2">
          <DropdownField
            title="Tabelas"
            onChange={(value, option) => {
              onChangeSimulacao("tabela", option.completedTable.codigo);
              onChangeSimulacao("tableCode", option.completedTable.codigo);
              onChangeSimulacao("codeTable", option.completedTable.codigo);
              setErrors((errors) => {
                delete errors["tabela"];
                delete errors["tableCode"];
                delete errors["codeTable"];
                return { ...errors };
              });
            }}
            value={selectedTabela?.codigo}
            options={tabelasList}
            dropdownProps={{
              w: "100%",
              variant: "outline-custom",
              children:
                selectedTabela?.descricao?.length! > 85
                  ? selectedTabela?.descricao?.slice(0, 85) + "..."
                  : selectedTabela?.descricao,
            }}
            isLoading={loading}
            errorMessage={
              errors["tabela"] || errors["tableCode"] || errors["codeTable"]
            }
            isDisabled={allDisabled}
          />
        </Box>
        {isVisibleUsuarioSelect ? (
          <Box gridArea="auto / span 2">
            <DropdownField
              title="Usuário digitador banco"
              onChange={(value, option) => {
                const usuario = option.completedUser as UsuarioBanco;
                onChangeSimulacao("loginVendedor", usuario.username);
                onChangeSimulacao("vendedor", usuario.username);
                onChangeSimulacao("username", usuario.username);
                setErrors((errors) => {
                  delete errors["usuarioBanco"];
                  delete errors["cpfCertifiedUser"];
                  return { ...errors };
                });
              }}
              value={selectedUsuariobanco?.username}
              options={usuariosBancos}
              dropdownProps={{ w: "100%", variant: "outline-custom" }}
              isLoading={loading}
              errorMessage={
                errors["usuarioBanco"] || errors["cpfCertifiedUser"]
              }
              isDisabled={allDisabled}
            />
          </Box>
        ) : null}
        <Box gridArea="auto / span 2">
          <AttendanceInput
            title="Valor liberado ao cliente"
            value={simulacaoResult.valorLiberado?.toFixed(2)}
            customTag={simulacaoResult.valorLiberado ? "SIMULADO" : undefined}
          />
        </Box>
        <Box gridArea="auto / span 2">
          <AttendanceInput
            title="Observação"
            customTag={simulacaoResult.observacao ? "SIMULADO" : undefined}
            value={simulacaoResult.observacao}
            inputProps={{ color: "red.500" }}
          />
        </Box>
      </Grid>

      <Flex justifyContent="flex-end" mt="10px">
        <Button
          // size="xs"
          mr="10px"
          // variant="outline"
          onClick={(e) => {
            e.stopPropagation();
            // handleDigitar();
            openModalDigitacaoINSS({ simulacao: simulacaoResult });
          }}
          isLoading={loadingDigitacao}
          isDisabled={simulacaoResult.status !== "FINISHED"}
        >
          Digitar
        </Button>

        <Button
          // mt="15px"
          // ml="auto"
          onClick={async () => {
            if (simulacaoResult?.status === "PENDENT") {
              setLoadingSimulacao(true);
              await getSimulacaoResult({ pending: true });
              setLoadingSimulacao(false);
            } else handleSimular();
          }}
          isLoading={loadingSimulacao}
        >
          {simulacaoResult?.status === "PENDENT" ? "Atualizar" : "Simular"}
        </Button>
      </Flex>
    </Flex>
  );
}
