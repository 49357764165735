import { ObjectSchema, objectSchema } from "utils/object-methods";
import { DownloadRelatoryProps } from ".";

export function validateFilterCampanhaSMSBody(fields: DownloadRelatoryProps) {
  const schema: ObjectSchema = {
    dataInicial: (value: string) => {
      let format = fields.campanhaIds.length ? null : value.replace("T", " ");
      const dataInicialNumber = value ? new Date(value).getTime() : null;
      const dataFinalNumber = fields.dataFinal
        ? new Date(fields.dataFinal).getTime()
        : null;
      let valid =
        (!!dataInicialNumber &&
          !!dataFinalNumber &&
          dataInicialNumber <= dataFinalNumber) ||
        (!dataInicialNumber && !dataInicialNumber);
      return { valid, message: "Insira um intervalo válido", format };
    },
    dataFinal: (value: string) => {
      let format = fields.campanhaIds.length ? null : value.replace("T", " ");
      const dataFinalNumber = value ? new Date(value).getTime() : null;
      const dataInicialNumber = fields.dataInicial
        ? new Date(fields.dataInicial).getTime()
        : null;
      let valid =
        (!!dataInicialNumber &&
          !!dataFinalNumber &&
          dataInicialNumber <= dataFinalNumber) ||
        (!dataInicialNumber && !dataInicialNumber);
      return { valid, message: "Insira um intervalo válido", format };
    },
    campanhaIds: (value: string[]) => {
      const isDateEmpty = !fields.dataFinal && !fields.dataInicial;
      let format = isDateEmpty ? value : null;
      let valid =
        !!value.length ||
        (!value.length && !!fields.dataInicial && !!fields.dataFinal) ||
        (!value.length && !fields.dataInicial && !fields.dataFinal);
      return { valid, format };
    },
  };
  return objectSchema(schema, fields);
}
