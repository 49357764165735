import { ChevronLeftIcon } from "@chakra-ui/icons";
import { Flex, FlexProps, IconButton, Text } from "@chakra-ui/react";

export function TitleWithBackButton({
  onClick,
  title,
  ...rest
}: {
  title: string;
  onClick?: () => void;
} & FlexProps) {
  return (
    <Flex alignItems="center" mb="30px" {...rest}>
      {onClick ? (
        <IconButton
          w="27px"
          h="27px"
          minW="27px"
          aria-label=""
          mr="12px"
          icon={<ChevronLeftIcon w="19px" h="19px" />}
          onClick={onClick}
        />
      ) : null}
      <Text color="#343B44" fontSize="24px" fontWeight="semibold">
        {title}
      </Text>
    </Flex>
  );
}
