export function printDiv(id: string) {
  var printContents = document.getElementById(id)?.innerHTML!;
  var WinPrint = window.open();
  if (WinPrint) {
    WinPrint.document.write(printContents);
    WinPrint.document.close();
    WinPrint.focus();
    WinPrint.print();
    WinPrint.close();
  }
}
