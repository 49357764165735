import { Box, Center, Flex, Grid, Input, Text } from "@chakra-ui/react";
import { Dropdown } from "components/dropdown";
import { SearchIcon3 } from "components/vectors/search-icon";
import { Dispatch, SetStateAction } from "react";
import { maskCPF } from "utils/string-formats";

export const GenerateOfflineConsultation = ({
  setConsultaMatriculaCPF,
  setTipoConsulta,
  tipoConsulta,
  consultaMatriculaCPF,
  isLoading,
  makeConsultation,
}: {
  setTipoConsulta: Dispatch<SetStateAction<string>>;
  tipoConsulta: string;
  setConsultaMatriculaCPF: Dispatch<SetStateAction<string>>;
  consultaMatriculaCPF: string;
  isLoading: boolean;
  makeConsultation: () => Promise<void>;
}) => {
  const tipoConsultaOptions = [
    { name: "INSS (Offline) por número do benefício", value: "INSS_NB" },
    { name: "INSS (Offline) por CPF", value: "INSS_CPF" },
    { name: "SIAPE (Offline)", value: "SIAPE" },
  ];

  const isEmpty = tipoConsulta === "";
  const isCPF = tipoConsulta === "INSS_CPF";

  const matriculaPlaceholder = isCPF
    ? "Digite o CPF"
    : isEmpty
    ? "Digite a matrícula ou CPF"
    : "Digite a matrícula";

  return (
    <Flex flexDir="column" w="100%" alignItems="center">
      <Center mb="20px">
        <SearchIcon3 />
        <Text ml="13px" fontSize="24px" fontWeight="semibold" mb="50px">
          Pesquisa rápida
        </Text>
      </Center>
      <Grid templateColumns="1fr 1fr" w="100%" gap="24px">
        <Box>
          <Text fontSize="16" fontWeight="medium" mb="8px">
            Tipo de consulta
          </Text>
          <Dropdown
            w="100%"
            variant="outline-custom"
            options={tipoConsultaOptions}
            value={tipoConsulta}
            onChange={(value) => {
              if (value === "INSS_CPF") {
                setConsultaMatriculaCPF(
                  maskCPF(consultaMatriculaCPF).replace(/\D/g, "")
                );
              }
              setTipoConsulta(value);
            }}
            isDisabled={isLoading}
          />
        </Box>
        <Box>
          <Text fontSize="16" fontWeight="medium" mb="8px">
            {isCPF
              ? "CPF"
              : isEmpty
              ? "Matrícula ou CPF"
              : tipoConsulta === "INSS_NB"
              ? "Número do benefício"
              : "Matrícula"}
          </Text>
          <Input
            variant="outline-custom"
            placeholder={matriculaPlaceholder}
            value={isCPF ? maskCPF(consultaMatriculaCPF) : consultaMatriculaCPF}
            onChange={(e) => {
              if (isCPF) {
                setConsultaMatriculaCPF(
                  maskCPF(e.target.value).replace(/\D/g, "")
                );
              } else {
                setConsultaMatriculaCPF(e.target.value.replace(/\D/g, ""));
              }
            }}
            onKeyDown={(e) => {
              if (
                e.key === "Enter" &&
                (!!tipoConsulta || !!consultaMatriculaCPF)
              )
                makeConsultation();
            }}
            isDisabled={isLoading}
          />
        </Box>
      </Grid>
    </Flex>
  );
};
