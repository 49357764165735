import { Box, Text } from "@chakra-ui/react";
import { ConsultaProps } from "components/consulta-components/consulta-fields/types";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import { useState } from "react";
import { makeEvent, useEventListener } from "services/events";

interface ModalData {
  benefitList: ConsultaProps[];
  selectBenefit: (benefit: ConsultaProps) => void;
}

export function ModalConsultaOfflineSelectBenefit() {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<Partial<ModalData>>({});

  const onOpen = (props: ModalData) => {
    setIsOpen(true);
    setModalData(props);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  useEventListener("openModalConsultaOfflineSelectBenefit", onOpen);

  return (
    <CustomModal
      modalTitle="Selecione o benefício"
      isOpen={isOpen}
      //   onClose={onClose}
    >
      <Text mb="8px">Selecione o benefício.</Text>
      <Dropdown
        w="100%"
        options={
          modalData.benefitList?.map((consulta) => ({
            name: `${consulta.beneficio.beneficio} - Esp: ${consulta.beneficio.especie.codigo}`,
            value: consulta.beneficio.especie.codigo,
          })) || []
        }
        onChange={(value) => {
          const selected = modalData.benefitList?.find(
            (crr) => crr.beneficio.especie.codigo === value
          );

          modalData.selectBenefit?.(selected!);
          onClose();
        }}
      />
      <Box mb="16px" />
    </CustomModal>
  );
}

export const openModalConsultaOfflineSelectBenefit = (props: ModalData) =>
  makeEvent("openModalConsultaOfflineSelectBenefit", props);
