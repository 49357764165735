import { AxiosError } from "axios";
import { UserTypes } from "contexts/types/application-context-types";

export const formatMilliseconds = (value: number) => {
  if (!value) return "";
  let horas = Math.trunc(value / 3600000);
  let min = Math.trunc((value - horas * 3600000) / 60000);
  let seg = Math.trunc((value - (horas * 3600000 + min * 60000)) / 1000);
  return (horas > 0 ? horas + "h " : "") + min + "m " + seg + "s";
};

export const formatToBrazilianDate = (rawDate: string) => {
  if (rawDate === undefined || rawDate === null) {
    return rawDate;
  }

  rawDate = rawDate.trim();

  if (rawDate.length === 10) {
    if (
      Number.isInteger(Number(rawDate[0])) &&
      Number.isInteger(Number(rawDate[1])) &&
      Number.isInteger(Number(rawDate[2])) &&
      Number.isInteger(Number(rawDate[3])) &&
      Number.isInteger(Number(rawDate[4])) &&
      Number.isInteger(Number(rawDate[5])) &&
      Number.isInteger(Number(rawDate[6])) &&
      Number.isInteger(Number(rawDate[7])) &&
      Number.isInteger(Number(rawDate[8])) &&
      Number.isInteger(Number(rawDate[9])) &&
      rawDate[4] === "-" &&
      rawDate[7] === "-"
    ) {
      let ano = Number(rawDate.substring(0, 4));
      if (ano < 1900 || ano > 2050) {
        return rawDate;
      }
      let mes = Number(rawDate.substring(5, 7));
      if (mes <= 0 || mes >= 13) {
        return rawDate;
      }
      let dia = Number(rawDate.substring(8, 10));
      if (dia <= 0 || dia >= 32) {
        return rawDate;
      }
      return (
        rawDate.substring(8, 10) +
        "/" +
        rawDate.substring(5, 7) +
        "/" +
        rawDate.substring(0, 4)
      );
    }
  }

  return rawDate;
};

export const getErrorMessage = ({
  error,
  defaultMessage,
}: {
  error: AxiosError;
  defaultMessage: string;
}) => {
  const messageInterface = error?.response?.data?.msgInterface;
  return messageInterface ?? defaultMessage;
};

export const parseUserType = new Map<UserTypes, string>([
  ["COMERCIAL", "Comercial"],
  ["FINANCEIRO", "Financeiro"],
  ["PARCEIRO_MASTER", "Master"],
  ["PARCEIRO_OPERACIONAL", "Operacional"],
  ["PARCEIRO_VENDEDOR", "Vendedor"],
  ["REGIONAL", "Regional"],
  ["SUPER", "Super"],
]);
