import { Icon, IconProps } from "@chakra-ui/react";

export function ActosLogo({ ...rest }: IconProps) {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 216 52"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M64.5508 45.7481V41.4789L66.5566 41.5598C68.218 41.6003 68.684 41.7014 69.15 42.0454C70.3251 42.9357 70.5074 44.7769 69.5146 45.91C69.1094 46.4158 68.8663 46.4967 67.5494 46.5777L66.0703 46.6788V48.3582V50.0173H65.3207H64.5508V45.7481ZM68.4408 44.6555C68.6029 44.2711 68.6029 44.0283 68.4408 43.6438C68.2382 43.1987 68.0761 43.138 67.0834 43.138H65.969V44.1497V45.1613H67.0834C68.0761 45.1613 68.2382 45.1006 68.4408 44.6555Z"
        fill="black"
      />
      <path
        d="M81.3672 45.7684V41.5194H83.1501C85.2572 41.5194 86.1487 41.8836 86.6957 42.9357C87.2428 44.0081 87.0402 45.1007 86.1284 45.9303L85.3991 46.598L86.3311 48.3178L87.263 50.0174H86.3918H85.5206L84.6292 48.2773C83.8998 46.8408 83.6769 46.5575 83.3122 46.6182C82.9273 46.6789 82.8867 46.8205 82.826 48.3583L82.7652 50.0174H82.0763H81.3672V45.7684ZM85.0951 44.8377C85.9258 44.0283 85.237 43.1381 83.7782 43.1381H82.7854V44.1497V45.1614H83.7782C84.4265 45.1614 84.8925 45.04 85.0951 44.8377Z"
        fill="black"
      />
      <path
        d="M100.412 49.7139C97.9607 48.6011 97.1097 45.6066 98.5888 43.2797C99.6221 41.6408 102.195 40.9934 104.16 41.9039C105.173 42.3692 105.538 42.7739 106.105 44.0486C106.916 45.8898 106.187 48.419 104.545 49.4307C103.593 50.0174 101.425 50.1591 100.412 49.7139ZM103.674 47.9334C104.951 47.0836 105.153 45.1614 104.12 43.9474C103.168 42.8144 101.243 42.7537 100.21 43.8463C99.6423 44.4533 99.4195 46.0719 99.8044 47.0027C100.372 48.3583 102.337 48.8237 103.674 47.9334Z"
        fill="black"
      />
      <path
        d="M117.836 45.7684C117.836 42.5108 117.897 41.5194 118.1 41.5194C118.221 41.5194 119.072 42.349 119.944 43.3404C120.835 44.3521 121.605 45.1614 121.666 45.1412C121.726 45.1412 122.496 44.3116 123.388 43.3202C124.259 42.3287 125.11 41.5194 125.252 41.5194C125.475 41.5194 125.535 42.3085 125.535 45.7684V50.0174H124.725H123.915V47.5489V45.0805L122.74 46.2338L121.544 47.4073L120.511 46.2742L119.457 45.1209V47.5692V50.0174H118.647H117.836V45.7684Z"
        fill="black"
      />
      <path
        d="M139.313 49.6936C138.381 49.289 137.753 48.682 137.246 47.7108C136.922 47.0835 136.861 46.6182 136.922 45.4446C136.983 44.1699 137.064 43.8867 137.611 43.219C138.685 41.9038 139.171 41.6408 140.812 41.5598C142.494 41.4587 143.547 41.8633 144.439 42.9155C145.614 44.3116 145.695 46.7598 144.641 48.2571C143.527 49.8353 141.136 50.4827 139.313 49.6936ZM142.858 47.731C144.236 46.3551 143.932 44.1497 142.25 43.3404C140.224 42.3489 138.3 43.8057 138.563 46.1123C138.644 46.8812 138.806 47.2049 139.394 47.731C140.022 48.2975 140.265 48.3987 141.156 48.3987C142.048 48.3987 142.271 48.3178 142.858 47.731Z"
        fill="black"
      />
      <path
        d="M158.155 46.5777V43.1381H157.041H155.926V42.3287V41.5194H158.864H161.802V42.3287V43.1381H160.789H159.776V46.5777V50.0174H158.965H158.155V46.5777Z"
        fill="black"
      />
      <path
        d="M174.809 49.5926C171.831 48.1155 171.79 43.4619 174.769 41.9848C175.762 41.479 177.565 41.3576 178.558 41.742C179.368 42.0455 180.604 43.3 180.867 44.1093C181.414 45.7887 180.969 47.8727 179.793 48.9451C178.598 50.0579 176.349 50.3614 174.809 49.5926ZM178.173 48.1155C179.733 47.2657 179.854 44.4735 178.375 43.5023C176.207 42.1062 173.533 44.1903 174.364 46.6587C174.728 47.7513 175.62 48.3988 176.754 48.3988C177.281 48.3786 177.909 48.2774 178.173 48.1155Z"
        fill="black"
      />
      <path
        d="M192.396 45.7481V41.4789L194.503 41.5598C196.265 41.6003 196.711 41.7014 197.197 42.0454C198.494 43.0166 198.453 45.1816 197.116 46.0921C196.468 46.517 196.468 46.517 196.833 47.1037C197.015 47.4275 197.461 48.2166 197.825 48.864L198.474 50.0173H197.623H196.752L195.84 48.338C195.171 47.124 194.807 46.6586 194.462 46.6181C194.037 46.5574 194.016 46.5979 194.016 48.2773V50.0173H193.206H192.396V45.7481ZM196.205 44.9185C196.346 44.7769 196.448 44.4329 196.448 44.1497C196.448 43.3808 196.083 43.138 194.989 43.138H194.016V44.1497V45.1613H194.989C195.516 45.1613 196.063 45.0602 196.205 44.9185Z"
        fill="black"
      />
      <path
        d="M209.009 48.9855C211.42 42.8952 212.129 41.317 212.312 41.6205C212.514 41.9847 215.695 49.7948 215.695 49.9364C215.695 49.9769 215.331 50.0174 214.885 50.0174C214.216 50.0174 214.034 49.9364 213.831 49.5115C213.629 49.0462 213.507 49.0057 212.129 49.0057C210.711 49.0057 210.63 49.0259 210.509 49.5115C210.407 49.9567 210.265 50.0174 209.495 50.0174H208.604L209.009 48.9855ZM212.96 47.2454C212.879 47.0431 212.676 46.4968 212.514 46.0112C212.352 45.5256 212.19 45.1614 212.15 45.2018C212.129 45.2423 211.906 45.7886 211.663 46.4361L211.238 47.5894H212.17C212.98 47.5894 213.082 47.5489 212.96 47.2454Z"
        fill="black"
      />
      <path
        d="M170.413 34.0128C168.67 33.6891 166.928 33.0214 165.651 32.1918C158.682 27.7 157.811 16.9359 163.97 11.1492C166.239 9.02468 168.772 8.01301 172.337 7.79044C178.963 7.40601 184.332 11.1896 185.912 17.3608C186.459 19.4651 186.459 22.5001 185.912 24.6043C184.98 28.2666 182.549 31.3218 179.408 32.7988C176.693 34.0938 173.33 34.5389 170.413 34.0128ZM176.612 28.2868C179.591 26.8907 181.475 23.0464 180.867 19.6269C180.057 15.0542 176.572 12.4643 171.952 13.0106C166.948 13.5974 164.031 18.8581 165.834 23.9973C166.604 26.1825 168.184 27.781 170.372 28.5903C171.973 29.1771 175.052 29.0354 176.612 28.2868Z"
        fill="black"
      />
      <path
        d="M204.856 33.9117C203.6 33.6486 201.898 33.1226 200.946 32.6977C200.034 32.293 199.892 32.1716 199.973 31.7669C200.034 31.5039 200.176 30.3304 200.318 29.1568L200.54 27.0323L201.797 27.6798C203.418 28.5296 205.18 28.9747 206.983 28.9747C209.475 28.9747 210.752 27.9833 210.57 26.1825C210.448 25.0292 209.719 24.2806 208.118 23.6938C205.95 22.9047 203.336 21.4682 202.344 20.497C200.844 19.0199 200.399 17.9273 200.399 15.6207C200.399 13.8807 200.459 13.5772 201.047 12.3429C202.891 8.4379 206.842 7.1025 212.312 8.5593C213.163 8.8021 213.872 9.00443 213.872 9.02466C213.872 9.2877 213.244 13.6783 213.203 13.7188C213.163 13.7593 212.555 13.5974 211.846 13.3748C207.774 12.1204 205.2 13.0309 205.423 15.6814C205.545 17.0168 206.213 17.6845 208.28 18.4534C211.704 19.7686 213.892 21.3468 215.007 23.3499C215.554 24.3413 215.594 24.5841 215.594 26.4456C215.594 28.2059 215.533 28.6105 215.067 29.602C213.629 32.637 211.056 34.0533 207.085 34.0128C206.092 33.9926 205.079 33.9521 204.856 33.9117Z"
        fill="black"
      />
      <path
        d="M64.3481 33.6891C64.3481 33.588 66.7389 27.6394 69.6564 20.4161C73.4249 11.1492 75.066 7.32509 75.2686 7.36555C75.451 7.40602 77.6999 12.6869 80.9011 20.5577C83.8186 27.781 86.2297 33.7296 86.2297 33.7701C86.2297 33.8105 84.9735 33.8308 83.4134 33.8308H80.6175L80.1109 32.4751L79.6044 31.0993H75.2686H70.9329L70.4263 32.4549L69.9198 33.8308H67.1441C65.6043 33.8308 64.3481 33.7701 64.3481 33.6891ZM76.6058 23.1678C75.998 21.4075 75.4307 19.8293 75.3699 19.6674C75.2686 19.4246 72.8576 25.697 72.8576 26.2028C72.8576 26.2837 73.9517 26.3444 75.2889 26.3444H77.7202L76.6058 23.1678Z"
        fill="black"
      />
      <path
        d="M107.726 33.4261C101.02 31.6253 97.2314 24.4829 99.1156 17.1989C99.5816 15.3779 100.899 12.9702 102.114 11.7157C103.431 10.3398 104.93 9.36864 106.896 8.62001C108.314 8.09394 108.638 8.05348 111.454 8.05348C113.865 8.03324 114.716 8.11418 115.608 8.39744L116.722 8.76164L116.439 11.3717L116.155 13.9616L115.223 13.5569C114.007 13.0309 112.082 12.8285 110.441 13.0106C103.046 13.9009 101.486 24.7864 108.273 28.1047C109.347 28.6308 109.59 28.6712 111.86 28.651C113.805 28.651 114.514 28.5498 115.446 28.2261L116.601 27.8012L116.702 28.3273C116.925 29.4806 117.289 32.8191 117.208 32.9202C116.823 33.2844 114.352 33.6891 111.961 33.7498C109.692 33.8105 108.962 33.77 107.726 33.4261Z"
        fill="black"
      />
      <path
        d="M135.463 23.5117V13.1927H132.323H129.182V10.6636V8.1344H138.097H147.012V10.6636V13.1927H143.77H140.528V23.5117V33.8307H137.996H135.463V23.5117Z"
        fill="black"
      />
      <path
        d="M0.790777 50.4828C0.851559 50.3816 7.19315 39.2533 14.9125 25.7374C22.6318 12.2215 29.0747 0.931332 29.2367 0.668299C29.5204 0.223165 29.7027 0.465965 34.4843 8.8628C38.273 15.5398 39.3671 17.6036 39.1645 17.7655C37.8475 18.9592 0.912341 50.6244 0.831298 50.6244C0.750255 50.6244 0.750255 50.5637 0.790777 50.4828Z"
        fill="#EEBA2B"
      />
      <path
        d="M44.4322 43.3808L30.3105 35.5505L34.3019 35.4088C36.5103 35.3279 40.8461 35.2672 43.9662 35.3077L49.619 35.3481L53.5495 42.2275C55.7174 46.0111 57.7637 49.5924 58.0879 50.1792C58.4323 50.7457 58.6755 51.2313 58.635 51.2111C58.5944 51.2111 52.2123 47.6702 44.4322 43.3808Z"
        fill="#1791C8"
      />
    </Icon>
  );
}
