import { DownloadIcon } from "@chakra-ui/icons";
import { Box, Flex, Grid, Image, Link, Text } from "@chakra-ui/react";
import { sortArrayBy } from "utils/filter-array-by";
import { BINIcon } from "components/vectors/bin-icon";
import { CircleProgress } from "components/vectors/circle-progress";
import { changeProgress } from "components/vectors/circle-progress/change-progress-interface";
import { CSVIcon } from "components/vectors/csv-icon";
import { DownloadCircleIcon } from "components/vectors/download-icon";
import { ExelIcon } from "components/vectors/file-extensions-icons";
import { ImageIcon } from "components/vectors/image-icon";
import { PDFIcon } from "components/vectors/pdf-icon";
import { memo, ReactNode, useRef, useState } from "react";
import { FaPlay } from "react-icons/fa";
import { makeEvent } from "services/events";
import { getExportedFunction } from "services/get-external-function";
import { createWebSocketEventListener } from "utils/web-socket-events";
import { Message } from "..";
import { AudioMessagePlayer } from "./audio/audio-message-player";
import { addLinks } from "./functions";
import { MessageArrow } from "./arrow-message";
import { MessageContainer } from "./message-container";

interface MediaPart {
  requestedMedia: {
    chatContactId: string;
    dataPart: string;
    filesize: number;
    messageId: string;
    mimetype: string;
    part: number;
    amountParts: number;
    type: string;
  };
}

function MessageTemplate({
  type,
  body,
  direction,
  from,
  id,
  filename,
  mimetype,
  duration,
  userFullName,
  url,
  mediaKey,
}: Message) {
  const isMyMessage = direction === "o";
  const [isLoading, setLoading] = useState(false);
  const isRevoked = type === "revoked";
  const [imageSrc, setImageSrc] = useState("");
  const mediaParts = useRef<Map<number, string>>(new Map());
  const mediaMimetype = useRef<string | undefined>(undefined);
  const mediaUrl = useRef<string | undefined>(undefined);
  const [isDownloadedMedia, setIsDownloadedMedia] = useState(false);
  const linkRef = useRef<HTMLAnchorElement>(null);

  const setCircleProgress = (value: number, isVisible?: boolean) => {
    changeProgress(`circle-progress-${id}`, { value, isVisible });
    if (isVisible === false) {
    }
  };

  createWebSocketEventListener(
    "requested-media",
    async (data) => {
      const { requestedMedia }: MediaPart = data;
      const target = mediaParts.current;
      setLoading(true);
      target.set(requestedMedia.part, requestedMedia.dataPart);
      const progress = (target.size / requestedMedia.amountParts) * 100;
      setCircleProgress(progress);
      if (progress === 100) {
        setCircleProgress(progress, false);
        const mediaPartsArray = Array.from(target.entries()).map(
          ([key, value]) => ({ key, value })
        );
        const base64Content = sortArrayBy(mediaPartsArray, "key", "ASC")
          .map(({ value }) => value)
          .join("");
        const withHeader = `data:${requestedMedia.mimetype};base64,${base64Content}`;
        setLoading(false);
        const resp = await fetch(withHeader);
        const blob = await resp.blob();
        const file = new File(
          [blob],
          `whatsapp-file-${new Date().getTime()}.${
            requestedMedia.mimetype.split("/")[1].split(";")[0]
          }`,
          { type: requestedMedia.mimetype }
        );
        mediaMimetype.current = requestedMedia.mimetype;
        const url = URL.createObjectURL(file);
        if (type === "image") {
          setImageSrc(url);
          setIsDownloadedMedia(true);
        } else if (type === "video") {
          mediaUrl.current = url;
          setIsDownloadedMedia(true);
        } else if (type === "document") {
          mediaUrl.current = url;
          linkRef.current!.href = url;
          setIsDownloadedMedia(true);
          if (!(mimetype === "application/pdf"))
            linkRef.current!.download = filename || "";
          mediaParts.current = new Map();
          linkRef.current?.click();
        } else if (type === "ptt" || type === "audio") {
          mediaUrl.current = url;
          setIsDownloadedMedia(true);
        }
      }
    },
    id
  );

  if (type === "chat" || isRevoked) {
    return (
      <MessageContainer isMyMessage={isMyMessage}>
        {userFullName ? (
          <Text fontSize="14" fontWeight="bold">
            {userFullName}
          </Text>
        ) : null}
        <Text
          whiteSpace="pre-wrap"
          wordBreak="break-word"
          lineHeight="19px"
          fontSize="14"
          fontStyle={isRevoked ? "italic" : "normal"}
          fontWeight="500"
          opacity={isRevoked ? "0.6" : undefined}
        >
          {isRevoked ? "Mensagem Apagada" : addLinks(body)}
        </Text>
      </MessageContainer>
    );
  } else if (type === "image") {
    return (
      <MessageContainer isMyMessage={isMyMessage}>
        <Flex
          w="100%"
          borderRadius="10px"
          overflow="hidden"
          alignItems="start"
          minH={body || imageSrc ? undefined : "180px"}
          maxH="240px"
          onClick={() => {
            if (isDownloadedMedia) {
              makeEvent("open-modal-whatsapp-image", {
                src: imageSrc,
                type: "image",
                mimetype: mediaMimetype.current,
              });
            } else {
              const sendJsonMessage = getExportedFunction("sendJsonMessage");
              sendJsonMessage({
                action: "request-media",
                contentType: "action",
                requestMessageId: id,
                url: url,
                mediaKey: mediaKey,
                mimetype: mimetype,
                type: type,
              });
            }
          }}
          pos="relative"
          cursor="pointer"
        >
          {body || imageSrc ? (
            <Image
              src={imageSrc ? imageSrc : `data:image/jpeg;base64,${body}`}
              w="100%"
            />
          ) : (
            <ImageIcon width="40%" display="block" m="auto" />
          )}
          <Flex
            alignItems="center"
            justifyContent="center"
            pos="absolute"
            inset={0}
          >
            {isLoading ? null : isDownloadedMedia ? null : (
              <Flex
                justifyContent="center"
                alignItems="center"
                w="50px"
                h="50px"
                bg="rgba(0,0,0,0.4)"
                borderRadius="50%"
                color="white"
              >
                <DownloadIcon />
              </Flex>
            )}

            <Box
              bg="rgba(0,0,0,0.4)"
              borderRadius="50%"
              width="50px"
              height="50px"
              display={isLoading ? "block" : "none"}
            >
              <CircleProgress id={id} isVisible={isLoading} />
            </Box>
          </Flex>
        </Flex>
      </MessageContainer>
    );
  } else if (type === "ptt" || type === "audio") {
    return (
      <MessageContainer isMyMessage={isMyMessage}>
        <AudioMessagePlayer
          src={mediaUrl.current!}
          id={id}
          isDownloaded={isDownloadedMedia}
          duration={duration!}
          myMessage={isMyMessage}
          isLoading={isLoading}
          userFullName={userFullName}
          type={type}
          url={url}
          mediaKey={mediaKey}
          mimetype={mimetype}
        />
      </MessageContainer>
    );
  } else if (type === "video") {
    return (
      <MessageContainer isMyMessage={isMyMessage}>
        <Flex
          w="100%"
          borderRadius="10px"
          overflow="hidden"
          minH="180px"
          onClick={() => {
            if (isDownloadedMedia) {
              makeEvent("open-modal-whatsapp-image", {
                src: mediaUrl.current,
                type: "video",
                mimetype: mediaMimetype.current,
              });
            } else {
              const sendJsonMessage = getExportedFunction("sendJsonMessage");
              sendJsonMessage({
                contentType: "action",
                action: "request-media",
                requestMessageId: id,
                url: url,
                mediaKey: mediaKey,
                mimetype: mimetype,
                type: type,
              });
            }
          }}
          pos="relative"
          cursor="pointer"
        >
          <Image
            src={imageSrc ? imageSrc : `data:image/jpeg;base64,${body}`}
            w="100%"
          />
          <Flex
            alignItems="center"
            justifyContent="center"
            pos="absolute"
            inset={0}
          >
            {isLoading ? null : isDownloadedMedia ? (
              <Flex
                justifyContent="center"
                alignItems="center"
                w="50px"
                h="50px"
                bg="rgba(0,0,0,0.4)"
                borderRadius="50%"
                color="white"
              >
                <FaPlay />
              </Flex>
            ) : (
              <Flex
                justifyContent="center"
                alignItems="center"
                w="50px"
                h="50px"
                bg="rgba(0,0,0,0.4)"
                borderRadius="50%"
                color="white"
              >
                <DownloadIcon />
              </Flex>
            )}
            <Box
              bg="rgba(0,0,0,0.4)"
              borderRadius="50%"
              width="50px"
              height="50px"
              display={isLoading ? "block" : "none"}
            >
              <CircleProgress id={id} isVisible={isLoading} />
            </Box>
          </Flex>
        </Flex>
      </MessageContainer>
    );
  } else if (type === "document") {
    return (
      <MessageContainer isMyMessage={isMyMessage}>
        {body ? (
          <Box
            w="100%"
            bgImg={`data:image/jpeg;base64,${body}`}
            sx={{ aspectRatio: `${21 / 9}` }}
            bgSize="contain"
            bgRepeat="no-repeat"
          />
        ) : null}
        <Grid
          alignItems="center"
          templateColumns="30px auto 30px"
          px="8px"
          borderRadius="6px"
          py="10px"
          bg="#f5f7f6"
          gap="8px"
        >
          <Flex alignItems="center" justifyContent="center">
            {mimetype === "application/pdf" ? (
              <PDFIcon />
            ) : mimetype === "text/csv" ? (
              <CSVIcon />
            ) : mimetype ===
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ? (
              <ExelIcon />
            ) : (
              <BINIcon />
            )}
          </Flex>
          <Text fontSize="13" whiteSpace="pre-wrap" wordBreak="break-word">
            {filename}
          </Text>
          <Flex alignItems="center" justifyContent="center">
            <Box
              onClick={() => {
                if (isDownloadedMedia) {
                  linkRef.current!.href = mediaUrl.current!;
                  if (!(mimetype === "application/pdf"))
                    linkRef.current!.download = filename || "";
                  linkRef.current?.click();
                } else {
                  const sendJsonMessage =
                    getExportedFunction("sendJsonMessage");
                  sendJsonMessage({
                    contentType: "action",
                    action: "request-media",
                    requestMessageId: id,
                    url: url,
                    mediaKey: mediaKey,
                    mimetype: mimetype,
                    type: type,
                  });
                }
              }}
              borderRadius="50%"
              _hover={{ bg: "rgba(0,0,0, 0.05)" }}
              transition="0.3s"
              cursor={!isLoading ? "pointer" : undefined}
            >
              <CircleProgress
                id={id}
                isVisible={isLoading}
                width="24px"
                height="24px"
              />
              {isLoading ? null : <DownloadCircleIcon color="#aaa" />}
            </Box>
            <Link display="none" ref={linkRef} target="_blank" />
          </Flex>
        </Grid>
      </MessageContainer>
    );
  }
  return null;
}
export default memo(MessageTemplate);
