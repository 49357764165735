import { DragDropContext } from "react-beautiful-dnd";
import { useAttendanceKanbanContext } from "../kanban/kanban-context";
import { Box } from "@chakra-ui/react";
import { DraggableAttendanceTable } from "../../draggable-table";
import { pageContentPadding } from "components/get-layout";

export function TableMode() {
  const { handleDrop, kanbanColumns } = useAttendanceKanbanContext();

  return (
    <DragDropContext onDragEnd={handleDrop}>
      <Box px={pageContentPadding} pb={pageContentPadding}>
        {kanbanColumns.map(
          ({ colId, attendancesList, colName, title, color }, index) => {
            const isLast = kanbanColumns.length === index + 1;
            return (
              <DraggableAttendanceTable
                key={colId}
                isLast={isLast}
                attendancesList={attendancesList}
                colId={colId}
                colName={colName}
                title={title}
                color={color}
              />
            );
          }
        )}
      </Box>
    </DragDropContext>
  );
}
