import { Box, Button, Flex, IconButton, Text } from "@chakra-ui/react";
import { LogoutIcon2 } from "components/vectors/logout-icon";
import { TurnOffPhoneIcon } from "components/vectors/turn-off-phone-icon";
import { useApplicationContext } from "contexts/ApplicationContext";
import { use3cPlusContext } from "contexts/Context3cPlus";
import { useEffect, useState } from "react";
import { IoMdMicOff, IoMdReturnRight } from "react-icons/io";
import { desligarChamada3cPlus, exitManualCallState } from "services/api3cplus";
import { Toast } from "components/toast";

export function Navbar3cplusTemplate() {
  const [returningToCampaign, setReturningToCampaign] = useState(false);
  const [campanhaLoading, setCampanhaLoading] = useState(false);
  const { deslogarCampanha, dispatch3cPlus, controls3cPlus } =
    use3cPlusContext();
  const {
    user: { userData },
  } = useApplicationContext();

  useEffect(() => {
    if (!controls3cPlus.inCall) {
      if (controls3cPlus.isManualMode) {
      }
    }
  }, [controls3cPlus.inCall]);

  if (!userData.enabled3cplus) return null;

  return (
    <Flex
      alignItems="center"
      mr={{ xl: "15px", "2xl": "35px" }}
      fontWeight="400"
      pos="relative"
      pr="10px"
    >
      <Flex ml={{ xl: "15px", "2xl": "35px" }} alignItems="center">
        <Text mr="8px" fontWeight="800" color="custom-gray">
          Em Ligação
        </Text>
        <Box
          w="18px"
          h="18px"
          borderRadius="full"
          bg={controls3cPlus.inCall ? "green.500" : "red"}
          animation={controls3cPlus.inCall ? "pulseGreen 2s infinite" : ""}
          border="2px solid var(--chakra-colors-gray-200)"
        />
      </Flex>

      {!controls3cPlus.inCall && controls3cPlus.isManualMode ? (
        <Button
          ml={{ xl: "15px", "2xl": "35px" }}
          size="sm"
          onClick={async () => {
            setReturningToCampaign(true);
            try {
              await exitManualCallState({ dispatch3cPlus });
            } catch (e: any) {
              Toast({ title: "Erro ao voltar para campanha", status: "error" });
            } finally {
              setReturningToCampaign(false);
            }
          }}
          loadingText="Voltando"
          isLoading={returningToCampaign}
          rightIcon={<IoMdReturnRight />}
        >
          Voltar para campanha
        </Button>
      ) : null}

      {controls3cPlus.inCall ? (
        <Flex
          ml={{ xl: "15px", "2xl": "35px" }}
          mr={{ xl: "15px", "2xl": "35px" }}
        >
          <Button
            mr="10px"
            variant="danger"
            size="sm"
            bg="red"
            rightIcon={<TurnOffPhoneIcon width="16px" height="16px" />}
            onClick={() => {
              const { idCall } = controls3cPlus;
              dispatch3cPlus({ type: "hangingUpCall" });
              desligarChamada3cPlus({ idCall: idCall!, dispatch3cPlus });
            }}
            isLoading={controls3cPlus.hangingUpCall}
            loadingText="Desligando Ligação"
          >
            Desligar Ligação
          </Button>
          <IconButton
            aria-label=""
            size="sm"
            onClick={() => {
              if (controls3cPlus.isMuted) dispatch3cPlus({ type: "unMute" });
              else dispatch3cPlus({ type: "mute" });
            }}
            icon={
              <IoMdMicOff
                size="17px"
                color={controls3cPlus.isMuted ? "red" : "white"}
              />
            }
          />
        </Flex>
      ) : null}
      {controls3cPlus.campaign.isLogged ? (
        <Text
          color="custom-gray"
          fontWeight="medium"
          ml={{ xl: "15px", "2xl": "35px" }}
        >
          Logado na campanha: <b>{controls3cPlus.campaign.campaignName}</b>
        </Text>
      ) : null}

      <Button
        size="sm"
        ml={{ xl: "15px", "2xl": "35px" }}
        rightIcon={<LogoutIcon2 height="18px" width="18px" />}
        onClick={async () => {
          setCampanhaLoading(true);
          try {
            await deslogarCampanha();
          } catch (e) {
            Toast({ title: "Erro ao deslogar campanha", status: "error" });
          } finally {
            setCampanhaLoading(false);
          }
        }}
        isLoading={campanhaLoading}
        loadingText={
          controls3cPlus.campaign.isLogged
            ? "Deslogando Campanha"
            : "Logando Campanha"
        }
      >
        {controls3cPlus.campaign.isLogged
          ? "Deslogar campanha"
          : "Logar Campanha"}
      </Button>
    </Flex>
  );
}
