export const getDomainKeySelect = () => {
  const hostname = window.location.hostname;
  const subdomain = hostname.split(".")[0];
  const domain = hostname.split(".")[1];

  // return "popcash";

  if (domain === "larpromotora") return "larpromotora";

  if (subdomain === "login" && domain === "solidapromotora")
    return "solidapromotora";

  return window.location.hostname.split(".")[0];
};
// window.location.hostname.split(".")[0];
