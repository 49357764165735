import { Icon, IconProps } from "@chakra-ui/react";

export const SolidaPromotora = ({ ...rest }: IconProps) => {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 952 188"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M0 172.2C0 153.1 1.2 147.8 6.7 143C13 137.4 15.1 137 36.2 137C59.4 137 64.7 136 72.4 130.2C79.7 124.7 83.1 117.5 83.8 106.8L84.3 99.1L47.7 98.8L11.2 98.5L7.3 95.4C0.9 90.3 -0.2 87.1 0.4 75.7C1.5 58.2 7.8 44.1 20.5 31.5C30.6 21.3 40.1 15.9 53.5 12.5L63.3 10H115.7H168.2L167.8 28.3C167.4 48 167 49.9 161.3 55.1C155.7 60.3 154.9 60.5 130 61C103.5 61.6 101.7 62 93.2 69.9C87.6 75.2 84.9 81.6 84.2 91.3L83.7 99H117.6C138.2 99 153 99.4 155.3 100.1C160.7 101.6 165.8 106.4 167.1 111.3C168.6 116.9 167.4 130.8 164.8 138.5C158.7 156.7 145.7 172 129.3 180.4C126.4 181.8 120.2 184.1 115.4 185.5L106.8 188H53.4H0V172.2Z"
        fill="#053A70"
      />
      <path
        d="M279 177C261.2 174.8 251 171.4 241.6 164.6C233 158.5 225.9 147.5 224.6 138.3L224 134H240.1H256.3L259.4 137.7C265.3 144.7 268.1 146.9 273.8 149.1C278.8 151 281.5 151.3 295.5 151.4C313.9 151.4 318.8 150.4 323.7 145.5C328 141.2 328.2 136.5 324.2 132.7C319.7 128.4 314.7 127.1 288.5 123.5C264.3 120.2 245.3 113.7 237.4 106.1C224.6 93.5999 225.1 70.3999 238.6 57.9999C253.8 43.9999 291.4 38.9999 324.4 46.4999C338.2 49.6999 349.3 58.2999 355.5 70.4999C357.8 74.9999 359 78.3999 358.4 78.8999C356.7 80.3999 328.3 83.1999 326.2 82.0999C325.2 81.5999 323.1 79.3999 321.5 77.3999C319.9 75.2999 316.4 72.6999 313.1 71.0999C307.8 68.6999 306.6 68.4999 292 68.4999C279.4 68.4999 275.6 68.7999 271.8 70.2999C263.9 73.3999 260.2 79.6999 263 85.0999C265.4 89.3999 279.5 93.6999 297.5 95.4999C306 96.2999 320.5 99.1999 332.1 102.3C346.7 106.2 356.8 113.9 360.1 123.5C362.1 129.4 361.8 144.4 359.7 150.7C357.3 157.6 349.5 165.6 341 169.7C328.2 175.9 298.8 179.4 279 177Z"
        fill="#053A70"
      />
      <path
        d="M430.3 176.9C400 173 380.8 158.1 372.2 132.2C370 125.6 369.7 122.7 369.7 111.5C369.7 96.9 370.8 91.5 376.7 79.1C381.3 69.4 392.4 58.2 402.7 52.7C425.6 40.6 464.8 39.8 487.7 51C504.1 59.1 515.1 71.9 519.7 88.2C522.2 97.2 522.1 121.9 519.6 131C515.6 145.5 503.4 160.2 489.1 167.8C473.6 176.1 450.8 179.6 430.3 176.9ZM458 148.9C464.7 147.4 473 142 477.2 136.5C482.7 129.2 484.5 122.5 484.4 109.5C484.4 96 482.1 89.1 475.2 81.7C459.5 65 425.9 67 413.5 85.5C408.4 93.3 406.7 100.3 407.2 112.9C407.5 122.5 407.8 124.2 410.7 130C419 146.8 436.2 153.7 458 148.9Z"
        fill="#053A70"
      />
      <path
        d="M696.3 176.1C683.9 173.4 675.8 169 666.9 160.1C654.2 147.4 649 133.4 649 112.6C649.1 83.4 658.9 64.3 680.8 51.2C695.9 42 722.7 40.5 741 47.8C747.4 50.3 755.7 55.8 759.4 59.9C760.5 61 761.7 62 762.2 62C762.6 62 763 49.2 763 33.6C763 6.5 763.1 5.2 765.1 2.6L767.1 0H783.1H799V84.8V169.7L796.9 171.6C795.1 173.3 792.9 173.5 779.4 173.8L764 174.1V164.6C764 159.3 763.8 155 763.6 155C763.4 155 760.6 157.6 757.3 160.8C754.1 163.9 748.8 167.9 745.5 169.7C732 176.8 711.6 179.5 696.3 176.1ZM741.6 146.1C756.5 139.1 763 128.5 763 110.8C763 92.4 756.8 81 743.2 74.3C738.3 71.9 736 71.5 726.7 71.1C714.7 70.7 709.4 72 701.1 77C695.5 80.5 689.9 88.3 687.9 95.5C687 98.5 686.5 105.1 686.6 112C686.6 125 688.2 129.8 694.6 137.7C701.8 146.4 711.2 150.1 725.1 149.7C733.4 149.4 735.7 148.9 741.6 146.1Z"
        fill="#053A70"
      />
      <path
        d="M846.6 175.4C831.6 172.5 820.2 164.3 816 153.4C812.8 145.2 812.8 131.9 815.9 123.9C818.4 117.5 825.9 109.7 833.1 106C844.7 100 867 96.0001 889 96.0001C897 96.0001 906.5 95.7001 910.3 95.3001L917 94.7001V89.9001C917 83.7001 915.9 81.0001 911.5 76.1001C905.4 69.4001 892.3 66.7001 876.7 68.9001C867.4 70.2001 862.9 72.3001 857 78.4001C852.9 82.7001 852.3 83.0001 847.5 82.9001C844.8 82.9001 838 82.3001 832.5 81.6001L822.5 80.5001L822.7 77.7001C823 72.8001 827.7 64.2001 832.9 59.0001C844.3 47.6001 865 42.2001 893 43.3001C921.3 44.3001 938 52.3001 946.9 69.0001C951.4 77.4001 951.9 84.0001 952 129.1C952 166.7 951.9 168.9 950.1 171.1C948.3 173.4 947.5 173.5 933.6 173.8L919 174.1L918.9 167.3C918.8 163.6 918.8 159.9 918.9 159.3C919.2 157 917.2 157.9 910.7 162.9C901.7 169.6 894.8 172.8 883.9 175C872.9 177.3 856.9 177.5 846.6 175.4ZM887.3 151C905.1 148.1 917 136.8 917 122.8V117H898.8C872.6 117 860.9 119.2 853.6 125.1C846 131.5 846.9 142.7 855.5 148C861.5 151.7 875.3 153 887.3 151Z"
        fill="#053A70"
      />
      <path
        d="M538.7 173.3C538.3 173 538 134.9 538 88.8V4.9L540.5 2.5C542.9 0.1 543.2 0 559 0H575.2L574.8 84.3C574.5 130.6 574 169.3 573.6 170.2C573.2 171.1 571.5 172.3 569.8 172.9C566.5 174.1 539.8 174.4 538.7 173.3Z"
        fill="#053A70"
      />
      <path
        d="M596.9 172.8C596.7 172.1 596.6 143.5 596.8 109.2L597 47L614.8 47.2L632.5 47.5L632.8 106C633.1 168.7 632.9 171.3 628.4 173C624.5 174.5 597.3 174.3 596.9 172.8Z"
        fill="#053A70"
      />
      <path
        d="M445 25.3C446.8 23.2 452.9 16.7 458.6 10.8L468.9 0H489.7C501.1 0 510 0.4 509.4 0.8C508.9 1.3 501.5 7.8 492.9 15.3L477.2 29H459.5H441.8L445 25.3Z"
        fill="#053A70"
      />
      <path
        d="M596 17C596 5.5 596.1 4.8 598.5 2.5C600.9 0.1 601.3 0 617 0H633V12.5C633 29.7 633.9 29 612.5 29H596V17Z"
        fill="#053A70"
      />
    </Icon>
  );
};
