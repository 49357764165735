import { Button } from "@chakra-ui/react";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { copyExtratoDataToAtendimento } from "components/atendimentos-components/atendimento-form/functions/mapeamentos";
import { CustomModal } from "components/custom-modal";
import { openModalConfirm } from "components/modal-confirm-new";
import { useAttendanceContext } from "contexts/attendance-context";
import { useState } from "react";
import { useEventListener, makeEvent } from "services/events";
import { Toast } from "components/toast";

export const LastStatementModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { dispatch, formControl } = useAttendanceContext();

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  useEventListener("openLastStatementModal", onOpen);

  return (
    <CustomModal
      isOpen={isOpen}
      modalTitle="Último Extrato"
      onClose={onClose}
      size="2xl"
      modalFooter={
        <>
          <Button
            onClick={() => {
              openModalConfirm({
                message: `Deseja copiar dados do último extrato para o atendimento?`,
                onConfirm: () => {
                  copyExtratoDataToAtendimento(
                    formControl.values as Attendance,
                    dispatch
                  );
                  Toast({
                    title: "Dados copiados para o atendimento",
                    status: "info",
                  });
                },
                confirmButtonStyle: { variant: undefined },
              });
              onClose();
            }}
            whiteSpace="normal"
          >
            Copiar dados do extrato para o atendimento
          </Button>
          {formControl.values.extratoOnline ? (
            <Button
              onClick={() => {
                window.open(
                  `${process.env.REACT_APP_PUBLIC_API_URL}/extratos/exibir/${
                    formControl.values.extratoOnline!.hash
                  }`,
                  "_blank"
                );
              }}
              whiteSpace="normal"
            >
              Exibir extrato
            </Button>
          ) : null}
        </>
      }
    >
      <></>
    </CustomModal>
  );
};

export const openLastStatementModal = () => makeEvent("openLastStatementModal");
