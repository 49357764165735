import { Icon } from "@chakra-ui/react";

export function RagazziLogo({ ...rest }) {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 1065 252"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M789.8 235C782.9 232.9 778.3 227.9 776.6 220.7C775.6 216.3 777.8 209.9 781.9 205.9C792.3 195.5 808.9 200.6 812.4 215.3C814.1 222.5 807.4 232.7 799.5 234.9C795 236.1 793.6 236.2 789.8 235ZM802.1 226.1C804.5 223.6 805 222.3 805 218.2C805 214.1 804.5 212.7 802.1 210.1C799.7 207.5 798.5 207 794.5 207C790.7 207 789.3 207.5 786.9 209.9C784.4 212.4 784 213.6 784 218C784 222.4 784.4 223.6 786.9 226.1C789.3 228.5 790.7 229 794.5 229C798.3 229 799.7 228.5 802.1 226.1Z"
        fill="#131313"
      />
      <path
        d="M899.8 235C892.9 232.9 888.3 227.9 886.6 220.7C885.6 216.3 887.8 209.9 891.9 205.9C902.3 195.5 918.9 200.6 922.4 215.3C924.1 222.5 917.4 232.7 909.5 234.9C905 236.1 903.6 236.2 899.8 235ZM912.1 226.1C914.5 223.6 915 222.3 915 218.2C915 214.1 914.5 212.7 912.1 210.1C909.7 207.5 908.5 207 904.5 207C900.7 207 899.3 207.5 896.9 209.9C894.4 212.4 894 213.6 894 218C894 222.4 894.4 223.6 896.9 226.1C899.3 228.5 900.7 229 904.5 229C908.3 229 909.7 228.5 912.1 226.1Z"
        fill="#131313"
      />
      <path
        d="M940 234.3C935.8 232.1 935.2 230.3 935.1 217.8C935 208.7 934.9 208 933 208C931.4 208 931 207.3 931 204.5C931 201.7 931.4 201 933 201C934.7 201 935 200.3 935 196V191H939H943V196V201H947.5C952 201 952 201 952 204.5C952 208 952 208 947.5 208H942.9L943.2 218.3L943.5 228.5H947.8C951.7 228.5 952 228.7 952 231.2C952 234.3 951.7 234.6 947 235.4C944.6 235.9 942.4 235.5 940 234.3Z"
        fill="#131313"
      />
      <path
        d="M973.8 235C966.9 232.9 962.3 227.9 960.6 220.7C959.6 216.3 961.8 209.9 965.9 205.9C976.3 195.5 992.9 200.6 996.4 215.3C998.1 222.5 991.4 232.7 983.5 234.9C979 236.1 977.6 236.2 973.8 235ZM986.1 226.1C988.5 223.6 989 222.3 989 218.2C989 214.1 988.5 212.7 986.1 210.1C983.7 207.5 982.5 207 978.5 207C974.7 207 973.3 207.5 970.9 209.9C968.4 212.4 968 213.6 968 218C968 222.4 968.4 223.6 970.9 226.1C973.3 228.5 974.7 229 978.5 229C982.3 229 983.7 228.5 986.1 226.1Z"
        fill="#131313"
      />
      <path
        d="M1041.5 235.3C1037.8 234.3 1035.1 231.8 1034 228.5C1031 219.4 1040.2 212.2 1051.8 214.8C1056 215.8 1056 215.8 1056 212.9C1056 208.5 1052.5 206.7 1044.4 207.1C1038.2 207.5 1037.7 207.3 1036.9 205.2C1036.2 203.3 1036.5 202.8 1038.8 201.9C1043.3 200.1 1054.3 200.3 1057.3 202.3C1062.6 205.8 1063.5 208.4 1063.9 222.2L1064.2 235H1060.1C1056.6 235 1056 234.7 1056 233C1056 230.9 1056 230.9 1053.3 232.9C1050.3 235 1044.7 236.2 1041.5 235.3ZM1053.9 227.9C1055.5 226.4 1056 224.9 1055.8 222.7C1055.5 219.6 1055.3 219.5 1050.3 219.2C1043.4 218.8 1041 220.1 1041 224.3C1041 226.1 1041.5 228.1 1042.2 228.8C1044.2 230.8 1051.3 230.3 1053.9 227.9Z"
        fill="#131313"
      />
      <path
        d="M705 211.9V188.8L716.8 189.2C726.2 189.5 729.3 190 732 191.5C737.2 194.6 739.4 198.3 739.4 203.8C739.4 213.8 733.5 218.7 720.5 219.7L713 220.3V227.6V235H709H705V211.9ZM729.4 210.9C731.5 209.3 732 208 732 204.5C732 198.1 728.9 196 719.9 196H713V204.5V213H719.9C725.3 213 727.3 212.6 729.4 210.9Z"
        fill="#131313"
      />
      <path
        d="M750 218V201H754C757.9 201 758 201.1 758 204.5V208L761.3 204.6C763 202.7 765.7 200.9 767.3 200.6C769.9 200 770 200.1 770 204C770 207.6 769.7 208 767.5 208C766.1 208 763.6 209.3 761.8 210.9C758.5 213.8 758.5 213.9 758.2 224.4L757.8 235H753.9H750V218Z"
        fill="#131313"
      />
      <path
        d="M824 218V201H828C831.7 201 832 201.2 832 203.6V206.3L834.5 203.8C838.9 199.4 846 199.3 850.1 203.6L852.3 205.9L855.7 203.3C860.4 199.7 867.8 199.5 871.5 203C875 206.3 876 210.8 876 223.8V235H872H868V222.7C868 212 867.8 210.1 866.2 208.7C863.7 206.4 858.4 206.6 856 209C854.2 210.8 854 212.3 854 223V235H850H846V222.7C846 212 845.8 210.1 844.2 208.7C841.7 206.4 836.4 206.6 834 209C832.2 210.8 832 212.3 832 223V235H828H824V218Z"
        fill="#131313"
      />
      <path
        d="M1007 218V201H1011C1014.9 201 1015 201.1 1015 204.5V208L1018.3 204.6C1020 202.7 1022.7 200.9 1024.3 200.6C1026.9 200 1027 200.1 1027 204C1027 207.6 1026.7 208 1024.5 208C1023.1 208 1020.6 209.3 1018.8 210.9C1015.5 213.8 1015.5 213.9 1015.2 224.4L1014.8 235H1010.9H1007V218Z"
        fill="#131313"
      />
      <path
        d="M627.8 200C623.1 199.5 615.5 198.2 610.9 197C602.4 194.8 590 190 590 188.9C590 187.9 599.8 167 600.3 167C600.5 167 603.7 168.4 607.4 170C617.8 174.7 625.9 176.2 638.5 175.8C646.9 175.5 650.6 174.9 654 173.4C663.7 168.9 668.1 161.4 668.8 148L669.3 139.5L663.2 145.3C654.9 153.1 646.4 157 636.2 157.7C616.1 159 598 148.7 589.3 130.9C585.5 123 584 116.2 584 106.5C584 91.1 588.4 80 598.4 69.6C609.1 58.5 623.9 53.4 639.2 55.7C648.2 57 653.7 59.5 661.7 66C665.1 68.7 668.1 71 668.4 71C668.7 71 669 67.9 669 64V57H684H699V105C699 158.2 698.6 162.9 692.8 174.7C683.6 193.6 659.9 202.8 627.8 200ZM654 130.4C666.2 125.3 672.6 108.5 666.9 96C662.3 85.8 653.5 80.6 641 80.7C625.1 80.8 614.6 91 614.7 106.4C614.7 120.1 621.4 128.7 634.7 132C639.4 133.1 649.3 132.3 654 130.4Z"
        fill="#131313"
      />
      <path
        d="M499 167.6C492.3 165.6 489.9 164.5 485.3 161.1C474.3 153.1 469.9 135 475.7 121.8C478.1 116.4 483.2 110.5 488 107.6C500.1 100.1 520.8 98.2 537.9 103.1C541.5 104.1 544.6 104.8 544.8 104.5C545 104.3 544.9 101.9 544.5 99.1C543.6 92.6 540.6 88.2 535 85C530.8 82.7 529.6 82.5 518 82.6C507.6 82.7 504.2 83.1 497.9 85.2L490.3 87.7L486.6 76.3C484.5 70 483 64.7 483.2 64.5C484.2 63.5 500.3 58.7 506.8 57.4C523.3 54.3 544.3 56.5 554.5 62.4C563.6 67.8 571 79.6 572.9 92C573.5 95.6 574 113.9 574 132.8V167H559.5H545V161C545 157.7 544.7 155 544.4 155C544 155 541.9 156.6 539.7 158.6C537.5 160.6 532.8 163.6 529.2 165.3C523.7 167.9 521.3 168.4 513.1 168.7C507 168.9 501.9 168.5 499 167.6ZM535.3 144.7C542.1 140.7 544.4 136.9 544.8 128.8L545.2 122.1L539.9 120.6C531.4 118.3 524.6 117.8 517.4 119.1C512 120 510.1 120.9 506.9 124C503.9 126.8 503 128.5 502.9 131.1C502.6 139.9 503.9 142.8 509.8 146C512.9 147.7 514.9 148 522.1 147.7C529.2 147.5 531.4 147 535.3 144.7Z"
        fill="#131313"
      />
      <path
        d="M734 167.6C722.9 164.5 713.6 156.3 710.4 146.8C708.4 140.6 708.2 130 710 124.5C711.8 118.8 716.5 112.4 721.2 109C734.1 99.6 760.3 97.7 778.5 104.6C779.7 105.1 780 104.5 780 101.9C780 92.3 774.4 85.2 765 83C757.8 81.3 743.5 82.2 733.7 85.1C729.5 86.3 725.8 87.1 725.6 86.9C725.4 86.7 723.7 81.6 721.7 75.7L718.1 64.9L721.7 63.3C733.6 58.4 745.9 56 759.4 56C786.6 56.1 801.3 65.5 807.2 86.9C808.8 92.7 809 97.7 809 130.2V167H794.5H780.1L779.8 160.9L779.5 154.9L775.5 158.2C769.4 163.4 765 165.8 758.5 167.5C751.5 169.3 740.3 169.3 734 167.6ZM765.1 147C770.1 145.6 775 142.1 777.7 138.1C779.5 135.5 780 133.3 780 128.4V122.1L774.3 120.3C770.8 119.3 765.1 118.6 760 118.6C745.5 118.6 738 123.8 738 133.8C738 139.9 740.4 143.8 745.3 146.1C750 148.2 759.3 148.6 765.1 147Z"
        fill="#131313"
      />
      <path
        d="M347 94.9V22.8L386.3 23.3C428.2 23.8 430.9 24 441.6 29.1C459.7 37.6 468.4 55.2 465.9 77.9C465.3 83 464 88.1 462.3 91.7C458.9 99 449.4 108.6 441.7 112.5C438 114.5 436.2 116 436.5 116.8C436.8 117.6 444.1 128.6 452.8 141.3C461.5 154.1 469 165.1 469.4 165.8C470 166.7 466.3 167 452.3 167H434.5L419 144L403.6 121H390.8H378V144V167H362.5H347V94.9ZM424.9 90.5C426.8 89.4 429.7 86.8 431.4 84.7C434 81.5 434.5 79.9 434.8 74.1C435.5 62 430.2 54.6 419 52.1C416.5 51.5 406.3 51 396.3 51H378V72.1V93.1L399.8 92.8C419.4 92.5 421.8 92.3 424.9 90.5Z"
        fill="#131313"
      />
      <path
        d="M820 156.6V146.2L848.8 114.3L877.6 82.5L849.8 82.2L822 82V69.5V57H869H916V67.8L915.9 78.5L887.5 109.7C871.8 126.9 859 141.2 859 141.5C859 141.8 871.8 142 887.5 142H916V154.5V167H868H820V156.6Z"
        fill="#131313"
      />
      <path
        d="M923 156.6V146.3L951.9 114.4L980.8 82.5L952.9 82.2L925 82V69.5V57H972H1019V67.8V78.5L990.3 110L961.6 141.5L990.3 141.8L1019 142V154.5V167H971H923V156.6Z"
        fill="#131313"
      />
      <path d="M1032 112V57H1047H1062V112V167H1047H1032V112Z" fill="#131313" />
      <path
        d="M1031 30.5V17H1047H1063V30.5V44H1047H1031V30.5Z"
        fill="#131313"
      />
      <path
        d="M69 251.3C59.8 250.6 43.3 247.7 35.2 245.4C24.2 242.2 15.8 237.9 9.8 232.2C2.2 225.2 0.5 221.2 0.5 211C0.5 203.1 0.8 201.9 4.6 194C6.8 189.3 11.4 181.9 14.8 177.4C22.6 167 42.3 148.1 57.5 136.2C72.3 124.7 102.7 104.2 122.4 92.5C136.2 84.3 137.3 83.9 140 85C143.2 86.3 145.7 91 144.3 93.3C143.9 94.1 138.1 100.7 131.5 107.9C78.4 166.3 76.6 198.6 125.7 209.5C147.3 214.3 173.7 215.5 225.5 214.1C265.6 213.1 266.6 213.2 268.4 218.3C269.9 222.3 266.7 226.1 260.4 227.7C219.9 238.2 160.8 248.2 122.8 250.9C109.3 251.9 79.8 252.1 69 251.3Z"
        fill="#B71515"
      />
      <path
        d="M143.2 167C141.2 166.2 139 162.5 139 159.9C139 159.4 144.4 153.1 150.9 145.8C171.2 123.3 180.8 110.3 187.5 96.4999C201.5 67.5999 191.9 49.9999 158.1 42.4999C136.8 37.7999 110.2 36.4999 57.9999 37.8999C25.6999 38.7999 20.1999 38.6999 18.2999 37.3999C14.9999 35.2999 14.2999 31.3999 16.6999 28.1999C18.8999 25.1999 19.5999 24.9999 48.5999 18.4999C147.2 -3.60007 227.5 -5.90007 263.4 12.1999C270.5 15.7999 278.5 22.9999 281.2 28.3999C287.2 40.1999 283 56.2999 269.1 74.6999C261.4 84.9999 241.6 104 226.5 115.8C206.3 131.5 149.9 168.2 146.4 167.9C145.9 167.9 144.5 167.5 143.2 167Z"
        fill="#B71515"
      />
    </Icon>
  );
}
