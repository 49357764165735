import { Box, Button, Center, Flex, Grid, Text } from "@chakra-ui/react";
import {
  Attendance,
  AttendanceFactaFGTS,
} from "components/atendimentos-components/atendimento-form/types";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useAttendanceContext } from "contexts/attendance-context";
import { useCallback, useEffect, useState } from "react";
import AttendanceInput from "../../../attendance-field/attendance-input";
import { CustomTableColor } from "components/dynamic-table/color-table";
import {
  consultarFGTSColombo,
  dropdownBancosSimulacaoFGTSColombo,
  getTabelaOptionsColombo,
  reCalcularFGTSColombo,
  simulacoesColumnsColombo,
} from "./utils";
import { RepeatIcon } from "@chakra-ui/icons";
import { WithInvalidBox } from "components/invalid-box";
import AttendanceDropdown from "../../../attendance-field/attendance-dropdown";
import { isColomboUser } from "utils/is-colombo-user";
import { AttendanceKey } from "contexts/attendance-context/actions-reducer";
import { ModalDigitarPropostaFGTS } from "../digitacao/modal-digitar-proposta";

export function SimulacoesColomboAtendimentoFormPart() {
  const [dropdownTabela, setDropdownTabela] = useState<
    { name: string; value: string }[]
  >([]);
  const [loadingTabela, setLoadingTabela] = useState(false);
  const {
    formControl,
    dispatch,
    setReConsultandoFGTS,
    reConsultandoFGTS,
    consultandoFGTS,
    setConsultandoFGTS,
  } = useAttendanceContext();
  const { user } = useApplicationContext();
  const formValues = formControl.values;

  const codTable = formValues.atendimentoFactaFGTS?.codTable;
  const observacaoSaldo =
    formValues.atendimentoFactaFGTS?.observacaoSaldo ?? "";
  const simulacaoAntecipacaoFactaFGTS =
    formValues.simulacaoAntecipacaoFactaFGTS || [];
  let currentAttendance = formValues as any;
  const isShowRecalular =
    formValues.atendimentoFactaFGTS?.bancoDigitacaoFGTSAPI === "PAN" ||
    formValues.atendimentoFactaFGTS?.bancoDigitacaoFGTSAPI === "FACTA";
  const valorLiberadoAntecipacao =
    formValues.atendimentoFactaFGTS?.valorLiberadoAntecipacao;

  const clearFieldsValorLiberadoAndSimulacao = () => {
    const newAtendimentoFactaFGTS: AttendanceFactaFGTS = {
      ...formValues.atendimentoFactaFGTS!,
      valorLiberadoAntecipacao: "",
    };
    dispatch({
      type: "changeFieldWithInitial",
      payload: { fieldName: "simulacaoAntecipacaoFactaFGTS", data: [] },
    });
    dispatch({
      type: "changeFieldWithInitial",
      payload: {
        fieldName: "atendimentoFactaFGTS",
        data: newAtendimentoFactaFGTS,
      },
    });
  };

  const onChangeInput = useCallback(
    (fieldName: keyof Attendance) => (value: string) => {
      dispatch({
        type: "changeField",
        payload: { fieldName, data: value },
      });
      dispatch({
        type: "setInvalidField",
        payload: { fieldName, action: "clear" },
      });
    },
    []
  );

  const onChangeDropdown = useCallback(
    (
        fieldName: keyof Attendance,
        subKey?: keyof AttendanceFactaFGTS,
        mode?: "changeFieldWithInitial"
      ) =>
      (newValue: any) => {
        let objectPart = (currentAttendance[fieldName] as any) || {};
        if (subKey) {
          objectPart[subKey] = newValue;
          currentAttendance[fieldName] = objectPart;
        }

        dispatch({
          type: mode || "changeField",
          payload: { fieldName, data: subKey ? objectPart : newValue },
        });
        dispatch({
          type: "setInvalidField",
          payload: {
            fieldName: (subKey
              ? `${fieldName}.${subKey}`
              : fieldName) as AttendanceKey,
            action: "clear",
          },
        });
        if (subKey === "bancoDigitacaoFGTSAPI") {
          onChangeDropdown(
            "atendimentoFactaFGTS",
            "codTable",
            "changeFieldWithInitial"
          )(null);
          clearFieldsValorLiberadoAndSimulacao();
          getTabelaOptionsColombo({
            setDropdownTabela,
            setLoadingTabela,
            bancoDigitacaoFGTSAPI: newValue,
            dispatch,
          });
        }
        if (subKey === "codTable") clearFieldsValorLiberadoAndSimulacao();
      },
    []
  );

  useEffect(() => {
    if (formValues.atendimentoFactaFGTS?.bancoDigitacaoFGTSAPI)
      getTabelaOptionsColombo({
        setDropdownTabela,
        setLoadingTabela,
        bancoDigitacaoFGTSAPI:
          formValues.atendimentoFactaFGTS.bancoDigitacaoFGTSAPI,
        dispatch,
      });
  }, []);

  return (
    <>
      <Grid templateColumns="1fr 1fr" columnGap="20px" rowGap="40px" mb="15px">
        <Box>
          <Box mb="15px">
            <AttendanceDropdown
              title="Banco"
              onChange={onChangeDropdown(
                "atendimentoFactaFGTS",
                "bancoDigitacaoFGTSAPI",
                "changeFieldWithInitial"
              )}
              fieldName="atendimentoFactaFGTS.bancoDigitacaoFGTSAPI"
              dropdownProps={{
                placeholder: "Selecione o banco",
                isDisabled:
                  loadingTabela || consultandoFGTS || reConsultandoFGTS,
              }}
              options={
                isColomboUser(user)
                  ? [{ name: "FACTA", value: "FACTA" }]
                  : dropdownBancosSimulacaoFGTSColombo
              }
              isInvalid={
                !!formControl.invalidFields.includes(
                  "atendimentoFactaFGTS.bancoDigitacaoFGTSAPI"
                )
              }
              value={formValues.atendimentoFactaFGTS?.bancoDigitacaoFGTSAPI}
            />
          </Box>
          <Text mb="8px" fontSize="15px" color="#4C545F" fontWeight="medium">
            Tabelas
          </Text>
          <WithInvalidBox
            display="flex"
            flexDir="column"
            maxH="160px"
            w="100%"
            overflow="auto"
            isInvalid={formControl.invalidFields.includes(
              "atendimentoFactaFGTS.codTable"
            )}
            borderRadius="5px"
          >
            {dropdownTabela.length === 0 ? "Não há tabelas" : null}
            {dropdownTabela.map(({ name, value }) => {
              const isActive =
                formValues.atendimentoFactaFGTS?.codTable === value;
              return (
                <Flex
                  w="100%"
                  key={value}
                  border={"1px solid #E8EAED"}
                  alignItems="center"
                  px="16px"
                  h="42px"
                  minH="42px"
                  borderRadius="5px"
                  mb="10px"
                  cursor="pointer"
                  _hover={{ bg: "gray.50" }}
                  opacity={
                    loadingTabela || consultandoFGTS || reConsultandoFGTS
                      ? 0.6
                      : 1
                  }
                  pointerEvents={
                    loadingTabela || consultandoFGTS || reConsultandoFGTS
                      ? "none"
                      : undefined
                  }
                  onClick={() => {
                    onChangeDropdown(
                      "atendimentoFactaFGTS",
                      "codTable",
                      "changeFieldWithInitial"
                    )(value);
                  }}
                >
                  <Center
                    borderRadius="full"
                    border="1px solid #141B3B"
                    w="12px"
                    h="12px"
                    mr="9px"
                  >
                    <Box
                      bg={isActive ? "#141B3B" : ""}
                      borderRadius="full"
                      h="8px"
                      w="8px"
                    />
                  </Center>
                  <Text fontSize="15px" color="#4C545F">
                    {name}
                  </Text>
                </Flex>
              );
            })}
          </WithInvalidBox>
        </Box>
        <Flex
          flexDir="column"
          justifyContent="center"
          bg="#F5F7F9"
          borderRadius="5px"
          p="20px"
        >
          <Flex mb="20px">
            <Box mr="8px" />
            <Text fontWeight="bold">Valor liberado</Text>
          </Flex>
          <Flex
            justifyContent="space-between"
            px="16px"
            py="14px"
            borderRadius="5px"
            bg="#fff"
            mb="25px"
          >
            <Text fontSize="15px" mr="auto"></Text>
            <Text fontWeight="bold" fontSize="15px">
              R$ {valorLiberadoAntecipacao ? valorLiberadoAntecipacao : "--"}
            </Text>
          </Flex>
          <Grid templateColumns="1fr 1fr" gap="10px">
            <Button
              w="100%"
              gridArea={isShowRecalular ? undefined : `auto / span 2`}
              isLoading={consultandoFGTS}
              loadingText="Consultando"
              isDisabled={reConsultandoFGTS}
              onClick={() => {
                consultarFGTSColombo({
                  dispatch,
                  formControl,
                  loading: setConsultandoFGTS,
                  codTable: codTable!,
                });
              }}
            >
              Consultar
            </Button>
            {isShowRecalular ? (
              <Button
                w="100%"
                leftIcon={<RepeatIcon />}
                isLoading={reConsultandoFGTS}
                loadingText="Recalculando"
                isDisabled={dropdownTabela.length === 0 || consultandoFGTS}
                onClick={() => {
                  reCalcularFGTSColombo({
                    dispatch,
                    formControl,
                    loading: setReConsultandoFGTS,
                    codTable: codTable!,
                  });
                }}
              >
                Recalcular
              </Button>
            ) : null}
          </Grid>
        </Flex>
        <Box gridArea="auto / span 2">
          <AttendanceInput
            title="Observação"
            value={observacaoSaldo}
            inputProps={{
              color: "red",
              as: "textarea",
              height: "60px",
              minH: "60px",
            }}
          />
        </Box>
      </Grid>
      <CustomTableColor
        columns={simulacoesColumnsColombo({ dispatch, formValues })}
        rows={simulacaoAntecipacaoFactaFGTS}
        headerStyle={{ whiteSpace: "normal", fontSize: "12px" }}
      />
      <ModalDigitarPropostaFGTS />

      {/* <Accordion title="Desconto anual FGTS" containerProps={{ mb: "20px" }}>
          <CustomTableColor
            columns={simulacoesColumns({ dispatch, formValues })}
            rows={simulacaoAntecipacaoFactaFGTS}
            headerStyle={{ whiteSpace: "normal", fontSize: "12px" }}
          />
        </Accordion> */}
    </>
  );
}
