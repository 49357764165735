import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { CustomModal } from "components/custom-modal";
import { useState } from "react";
import { makeEvent, useEventListener } from "services/events";
import { formatData } from "utils/string-formats";

export function ModalVerMsgSMS() {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<Attendance | null>(null);
  const allMessages = modalData?.mensagensSms || [];
  const messagesGroupedByDays = allMessages
    .map((msg) => new Date(msg.dataMensagem).toLocaleDateString())
    .filter(function (item, pos, a) {
      return a.indexOf(item) === pos;
    })
    .reduce((acc, date, i) => {
      acc.push({
        date,
        messages: allMessages.filter(
          (msg) => new Date(msg.dataMensagem).toLocaleDateString() === date
        ),
      });
      return acc;
    }, [] as { date: string; messages: Attendance["mensagensSms"] }[]);

  const onOpen = (attendance: Attendance) => {
    setIsOpen(true);
    setModalData(attendance);
  };
  const onClose = () => {
    setIsOpen(false);
    setModalData(null);
  };

  useEventListener("openModalVerMsgSMS", onOpen);

  const modalFooter = (
    <>
      <Button
        onClick={onClose}
        variant={"outline"}
        size="sm"
        leftIcon={<CloseIcon w="12px" h="12px" />}
      >
        Fechar
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalFooter={modalFooter}
      modalTitle="Mensangens SMS Respondidas"
    >
      {messagesGroupedByDays.map((item, i) => {
        const isLast = i + 1 === messagesGroupedByDays.length;
        return (
          <UnorderedList mb={isLast ? "" : "10px"} key={i}>
            <ListItem mb={isLast ? "" : "10px"}>
              <Text fontWeight="semibold">{item.date}</Text>
              <UnorderedList>
                {item.messages.map((msg, i) => {
                  const isLast = i + 1 === item.messages.length;
                  return (
                    <ListItem key={i} mb={isLast ? "" : "8px"}>
                      <Text>{msg.mensagem}</Text>
                      <Text color="gray.500">
                        {new Date(msg.dataMensagem).toLocaleString()}
                      </Text>
                    </ListItem>
                  );
                })}
              </UnorderedList>
            </ListItem>
            {isLast ? null : (
              <Box w="calc(100% + 1em)" ml="-1em" h="1px" bg="gray.200" />
            )}
          </UnorderedList>
        );
      })}
    </CustomModal>
  );
}

export const openModalVerMsgSMS = (attendance: Attendance) =>
  makeEvent("openModalVerMsgSMS", attendance);
