type UnitTest = (
  a: any,
  fullObject: any,
  currentKey: string
) => { valid: boolean; format?: any; message?: string };

type Type =
  | "string"
  | "number"
  | "bigint"
  | "boolean"
  | "symbol"
  | "undefined"
  | "function"
  | "array"
  | UnitTest;

export type ObjectSchema<T = any> = Record<
  keyof T,
  Type | { [k: string]: Type }
>;

export function objectSchema<T = any>(schema: ObjectSchema<T>, object: any) {
  const fullObject = { ...object };
  const objectCopy = { ...object };
  let fieldsErrors: (keyof T)[] = [];
  const errors: any = {};
  const keys = Object.keys(schema) as (keyof T)[];
  const objectKeys = Object.keys(objectCopy) as (keyof T)[];
  for (let key of objectKeys)
    if (keys.indexOf(key) === -1) delete objectCopy[key];

  for (let key of keys) {
    if (typeof schema[key] !== "object") {
      switch (schema[key]) {
        case "array":
          if (!Array.isArray(objectCopy[key])) fieldsErrors.push(key);
          break;
        default:
          if (typeof schema[key] === "function") {
            const validate = schema[key] as UnitTest;
            const { valid, format, message } = validate(
              objectCopy[key],
              fullObject,
              key as any
            );
            if (!valid) {
              fieldsErrors.push(key);
              if (message) errors[key] = message;
            }
            if (format !== undefined) objectCopy[key] = format;
          } else if (!(typeof objectCopy[key] === schema[key]))
            fieldsErrors.push(key);
      }
    } else {
      const objectTest = objectSchema(
        schema[key] as ObjectSchema,
        objectCopy[key]
      );
      fieldsErrors = fieldsErrors.concat(objectTest.fieldsErrors as any);
      Object.assign(errors, objectTest.errors);
    }
  }

  return {
    isValid: !fieldsErrors.length,
    fieldsErrors,
    body: objectCopy,
    errors,
  };
}

export function isObjectEqual(
  object1: any = {},
  object2: any = {},
  exception?: string[]
) {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (let key of keys1) {
    if (!exception?.includes(key))
      if (Array.isArray(object1[key])) {
        if (object1[key].length !== object2[key].length) return false;
        if (!Array.isArray(object2[key])) return false;
        for (let i = 0; i < object1[key].length; i++) {
          if (object1[key][i] !== object2[key][i]) return false;
        }
      } else if (object1[key] !== object2[key]) return false;
  }
  return true;
}

// TODO: uma funcao que vai pegar esses 2 objetos e saber quais props que estao diferentes
export function isObjectDiff(object1: any, object2: any, exception?: string[]) {
  if (!isObjectEqual(object1, object2)) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    const differentKeysList = [];
    // if (keys1.length !== keys2.length) {
    //   return false;
    // }
    for (let key of keys1) {
      if (!exception?.includes(key))
        if (Array.isArray(object1[key])) {
          if (object1[key].length !== object2[key].length) {
            differentKeysList.push(key);
          }
          if (!Array.isArray(object2[key])) {
            differentKeysList.push(key);
          }
          for (let i = 0; i < object1[key].length; i++) {
            if (object1[key][i] !== object2[key][i]) {
              differentKeysList.push(key);
            }
          }
        } else if (object1[key] !== object2[key]) {
          differentKeysList.push(key);
        }
    }
    return { differentKeysList };
  } else return { differentKeysList: [] };
}

export const isObject = (x: any) =>
  typeof x === "object" && !Array.isArray(x) && x !== null;

// console.log(
//     objectSchema(
//       {
//         test: "undefined",
//         test1: {
//           test1: "number",
//           testando: "string",
//           testandoa1: "array",
//           adjas: { tejkasjd: "boolean" },
//         },
//         alou: "string",
//         testando: {},
//         testando1: "boolean",
//       },
//       {
//         test: undefined,
//         test1: {
//           test1: 1,
//           testando: "alo",
//           testandoa1: [],
//           adjas: { tejkasjd: true },
//         },
//         alou: "test",
//         testando: {},
//         testando1: true,
//       }
//     )
//   );
