import { DocumentIcon } from "components/vectors/document-icon";

import { ReactNode } from "react";
import { HomeIcon } from "components/vectors/menu-icons/home-icon";
import { MegaphoneIcon } from "components/vectors/menu-icons/mega-phone-icon";
import { PersonIcon } from "components/vectors/menu-icons/person-icon";
import { WalletIcon } from "components/vectors/menu-icons/wallet-icon";
import { PhoneIcon } from "components/vectors/menu-icons/phone-icon";

export interface SubItemProps {
  name: string;
  href: string;
  subItems?: undefined;
}

export interface MenuItemProps {
  name: string;
  subItems?: SubItemProps[];
  href?: string;
  icon: JSX.Element | ReactNode;
}

export const menuArray: MenuItemProps[] = [
  {
    name: "Home",
    href: "/",
    icon: <HomeIcon />,
  },
  {
    name: "Acessos",
    icon: <PersonIcon />,
    subItems: [
      { name: "Empresas", href: "/empresas" },
      { name: "Usuários Corban", href: "/usuarios" },
      { name: "Usuários Bancos", href: "/usuarios-bancos" },
      { name: "Sessões WhatsApp", href: "/sessoes-whatsapp" },
    ],
  },
  {
    name: "Carteira",
    icon: <WalletIcon />,
    subItems: [
      { name: "Transações", href: "/carteira" },
      { name: "Serviços", href: "/servicos" },
    ],
  },
  {
    name: "Mailing",
    icon: <MegaphoneIcon />,
    subItems: [
      { name: "Mailing Lista", href: "/mailing" },
      { name: "Campanha WhatsApp", href: "/campanha-whatsapp" },
      { name: "Campanha SMS", href: "/campanha-sms" },
    ],
  },
  {
    name: "Consultas",
    icon: <DocumentIcon />,
    subItems: [
      { name: "Offline INSS", href: "/consulta" },
      { name: "IN100", href: "/consulta-in100" },
      { name: "Extrato", href: "/extrato-online-avulso" },
      { name: "Telefonia", href: "/consulta-telefonia" },
    ],
  },
  {
    name: "Atendimento",
    icon: <PhoneIcon />,
    subItems: [
      { name: "Kanban", href: "/atendimentos" },
      // { name: "Tabela Digitação", href: "/tabelas-digitacao" },
      { name: "Tabelas Digitação", href: "/tabelas" },
    ],
  },
];
