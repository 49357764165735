import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import apiCarteira from "api/api-carteira";
import { CustomModal } from "components/custom-modal";
import { DolarIcon2 } from "components/vectors/dolar-icon";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useState } from "react";
import { useEventListener, makeEvent } from "services/events";
import { currencyMask } from "utils/string-formats";
import { SelectedCustomer } from "pages/carteira";
import { Toast } from "components/toast";

export function ModalInsertCoin({
  selectedCustomer,
  onChangePartner,
}: {
  selectedCustomer: SelectedCustomer;
  onChangePartner: (selectedPartner: SelectedCustomer) => void;
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [insertSaldoValue, setInsertSaldoValue] = useState("");
  const { user } = useApplicationContext();

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setInsertSaldoValue("");
  };

  useEventListener("openModalInsertCoin", onOpen);

  const handleSubmit = async (valor: number) => {
    setIsLoading(true);
    try {
      const { data } = await apiCarteira.post(
        `/carteiras/creditos/add/${selectedCustomer.id}`,
        { valor }
      );
      Toast({
        title: "Saldo inserido com sucesso",
        description: `Inserido no cliente: ${selectedCustomer.name}`,
        status: "success",
      });
      onClose();

      onChangePartner({ ...selectedCustomer });
    } catch (e) {
      Toast({
        title: "Erro ao inserir saldo",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const modalFooter = (
    <>
      <Button
        leftIcon={<DolarIcon2 variant="white" />}
        isLoading={isLoading}
        loadingText="Inserindo"
        onClick={() => {
          handleSubmit(
            Number(insertSaldoValue.replace(/\./g, "").replace(",", "."))
          );
        }}
        isDisabled={insertSaldoValue === "0,00" || !insertSaldoValue}
      >
        Inserir
      </Button>
      <Button
        onClick={onClose}
        variant="outline"
        leftIcon={<CloseIcon w="12px" h="12px" />}
      >
        Cancelar
      </Button>
    </>
  );
  return (
    <CustomModal
      modalTitle="Adicionar Saldo"
      modalFooter={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="md"
    >
      <Box>
        <Flex alignItems="center" mb="8px">
          <DolarIcon2 display="block" mr="8px" /> <Text>Valor</Text>
        </Flex>
        <Input
          w="100%"
          mb="12px"
          variant="outline-custom"
          onChange={(e) => setInsertSaldoValue(currencyMask(e.target.value))}
          value={currencyMask(insertSaldoValue)}
          //   type="number"
        />
      </Box>
    </CustomModal>
  );
}

export const openModalInsertCoin = () => makeEvent("openModalInsertCoin");
