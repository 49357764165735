import { MailingFilterProps } from "../../types";
import { Template } from "../mailing-template";
import { RadioFilter } from "./radio-filter";
import { Flex } from "@chakra-ui/react";
import { DropdownField } from "components/dropdown-field";

export const DropdownMailingFilter = ({
  title,
  description,
  mapKey,
  onChangeFilter,
  operators,
  filtersValues,
  options,
  invalidFields,
}: {
  options: any[];
  invalidFields: { [k: string]: string };
} & MailingFilterProps) => {
  const currentFilter = filtersValues.get(mapKey);
  const filterValue = currentFilter?.value ?? [];
  const errorMessage = invalidFields[mapKey];

  console.log(filterValue);

  const onChange = (value: any) => {
    // console.log(value, currentFilter);
    const isInclude = filterValue.includes(value);
    if (isInclude) {
      onChangeFilter(mapKey, (prev) => {
        prev.set(mapKey, {
          ...currentFilter!,
          value: filterValue.filter((curr) => curr !== value),
        });
        return new Map(prev.entries());
      });
    } else {
      onChangeFilter(mapKey, (prev) => {
        prev.set(mapKey, {
          ...currentFilter!,
          value: [...filterValue, value],
        });
        return new Map(prev.entries());
      });
    }
  };

  return (
    <Template title={title} description={description} mapKey={mapKey}>
      <RadioFilter
        mapKey={mapKey}
        operators={operators}
        onChangeFilter={onChangeFilter}
        value={currentFilter?.op}
      />
      {currentFilter?.op === "in" || currentFilter?.op === "not_in" ? (
        <Flex
          flexDir="column"
          borderRadius="6"
          //   border="1px solid var(--chakra-colors-custom-gray)"
          w="500px"
        >
          <DropdownField
            onChange={onChange}
            value={filterValue}
            options={options}
            dropdownProps={{
              w: "100%",
              multiSelect: true,
              children: filterValue
                .map(
                  (value) =>
                    options.find((option) => option.value === value).name
                )
                .join(", "),
            }}
            errorMessage={errorMessage}
          />
        </Flex>
      ) : null}
    </Template>
  );
};
