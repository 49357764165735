import { Icon, IconProps } from "@chakra-ui/react";

export function VisaoVendas({ ...rest }: IconProps) {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 330 149"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M78.3998 147.9C68.2998 146.6 61.9998 140.9 59.2998 130.7C57.6998 124.2 57.4998 79 59.0998 72.6C60.4998 67.1 64.9998 61.6 70.2998 58.8C73.3998 57.2 76.8998 56.5 83.6998 56.2C91.7998 55.8 93.3998 56 98.1998 58.3C108.3 62.9 111 69.6 111 90.2V104H94.8998H78.7998L79.1998 117C79.4998 130.8 79.9998 132.3 84.5998 132.3C88.7998 132.3 89.9998 129.6 89.9998 120.8V113H100.6H111.3L110.7 121.3C109.8 134.4 106.2 141.7 98.4998 145.7C94.6998 147.7 85.0998 148.7 78.3998 147.9ZM89.9998 84.7C89.9998 74.4 88.5998 71 84.4998 71C80.3998 71 78.9998 74.4 78.9998 84.7V94H84.4998H89.9998V84.7Z"
        fill="#620909"
      />
      <path
        d="M236.1 147.8C230.4 146.8 226.4 142.9 224.5 136.6C222.7 130.7 222.5 114.3 224.1 108.5C225.9 102.2 232.1 96.8 241.5 93.5C245.9 91.9 250.5 90.3 251.8 89.9C253.8 89.1 254 88.4 254 81.7C254 75.8 253.6 74 252.2 72.7C249.8 70.5 248.4 70.6 246 73C244.7 74.3 244 76.3 244 79V83H233.9H223.7L224.2 76.5C225.4 62.1 232.9 56 249.6 56C261.2 56 268.7 59.6 273.1 67.3C275.4 71.3 275.5 72.5 275.8 93.3L276.2 115H286.6H297V120.8C297 128.1 298.6 131.4 302.5 131.8C306.3 132.3 308 129.9 308 124.1C308 117.5 305.6 114.3 293.3 104.6C278.6 93 277.5 91.1 277.5 78.8C277.5 69.6 277.6 69 280.6 65.3C285.1 59.6 291.9 56.6 301.3 56.1C311.8 55.7 317.4 57.3 322.5 62.4C326.9 66.8 329 72.2 329 78.9V83H318.6H308.3L307.7 79C307 73.6 305.4 71.8 301.6 72.2C298.7 72.5 298.5 72.8 298.2 76.8C297.8 82.1 298.2 82.6 309.5 91.1C324.4 102.2 328.5 107.8 329.6 118.7C331.5 136.6 321.4 148 303.7 148.1C294.2 148.2 288.9 146.7 284.4 142.8C280.4 139.3 278.4 135.8 277.1 130.3C276.4 127.3 276.2 128.6 276.1 137.3L276 148H265.5H255.1L254.8 142.3L254.5 136.5L251.8 141.1C249.3 145.3 247.3 146.8 242 148.2C241.2 148.5 238.5 148.3 236.1 147.8ZM252.8 130.8C254 129.6 254.6 99 253.5 99C251.5 99 246.8 102.5 245.5 105C243.7 108.4 243.4 127 245 130.1C246.2 132.3 250.9 132.7 252.8 130.8Z"
        fill="#620909"
      />
      <path
        d="M19 147C19 146.4 15.9 126.3 12 102.3C8.2 78.3 5 58.3 5 57.8C5 57.4 9.7 57 15.5 57C25 57 26 57.2 26 58.8C26 59.9 26.9 69.9 27.9 81.1C29 92.3 30.2 105.1 30.6 109.5L31.4 117.5L32.7 104C33.4 96.6 34.8 83.1 35.8 74L37.6 57.5L47.8 57.2C57 57 58 57.1 58 58.7C58 59.7 54.8 79.9 51 103.5C47.2 127.2 44 146.8 44 147.3C44 147.7 38.4 148 31.5 148C23.5 148 19 147.6 19 147Z"
        fill="#620909"
      />
      <path
        d="M113 102.5V57H123.5H133.9L134.2 62.7L134.5 68.5L136.9 63.7C139.7 58 143.1 56 149.8 56C155.6 56 160.4 58.8 163.4 64C165.4 67.3 165.5 69.2 165.8 107.8L166.1 148H155.5H145V111.2C145 83.9 144.7 74.1 143.8 73.2C142.1 71.5 137.5 71.7 135.9 73.7C134.8 75 134.4 82.6 134.2 111.7L133.9 148H123.5H113V102.5Z"
        fill="#620909"
      />
      <path
        d="M176.3 145.9C174.6 144.8 172.1 141.8 170.8 139.2C168.5 134.6 168.5 133.9 168.5 102C168.5 70.3 168.6 69.4 170.7 65.3C174.3 58.7 178.2 56.5 186.5 56.5C192.2 56.5 194.1 56.9 196.8 58.8L200 61.1V50.6V40H210.5H221V94V148H210.5H200V142.8L199.9 137.5L198 141.1C195.3 146.3 192.1 148 185.3 148C181.2 148 178.6 147.4 176.3 145.9ZM199 129.4C200.4 125.9 200.3 76.4 199 73.9C198.2 72.6 196.9 72 194.4 72C191.6 72 190.8 72.5 189.9 74.7C189.3 76.3 189 87.2 189.2 103C189.5 131 189.7 132 195.1 132C197.3 132 198.3 131.4 199 129.4Z"
        fill="#620909"
      />
      <path
        d="M202 31.5C202 27.2 202.1 27 204.9 27C206.5 27 208.3 27.7 209 28.5C210.7 30.6 209.4 34.6 206.8 35.4C202.4 36.5 202 36.2 202 31.5ZM207.6 32.5C208.2 30.9 206.4 28 204.9 28C204.4 28 204 29.4 204 31C204 34.2 206.6 35.3 207.6 32.5Z"
        fill="#620909"
      />
      <path
        d="M212 31.5V27L216.3 27.1C219.2 27.3 219.7 27.4 217.8 27.7C214.4 28.2 214.1 30.7 217.3 31.3L219.5 31.7L217.3 31.9C214.2 32.1 214.4 34 217.5 34C218.9 34 220 34.5 220 35C220 35.6 218.2 36 216 36H212V31.5Z"
        fill="#620909"
      />
      <path
        d="M7.7 33.3C3.5 21.3 0 11.2 0 10.8C0 10.3 3.1 10 6.9 10H13.9L16.5 18.7C18 23.6 20 30.2 21 33.5C22 36.8 23.1 39.4 23.6 39.3C24.1 39.2 26.5 32.6 28.9 24.6L33.3 10H40.2C43.9 10 47 10.1 47 10.3C47 10.4 43.4 20.4 39.1 32.5L31.3 54.5L23.4 54.8L15.4 55.1L7.7 33.3Z"
        fill="#E10000"
      />
      <path d="M48 32.5V10H55H62V32.5V55H55H48V32.5Z" fill="#E10000" />
      <path
        d="M73.6996 53.6C68.7996 51.8 66.9996 50.2 65.2996 46.3C62.8996 40.5 63.2996 40 70.3996 40C76.2996 40 76.7996 40.2 77.3996 42.5C78.0996 45.3 82.7996 46.6 87.8996 45.5C90.2996 45 90.9996 44.3 90.9996 42.4C90.9996 40 90.1996 39.6 76.0996 36C69.5996 34.3 64.9996 29.4 64.9996 24.1C64.9996 14.6 71.2996 10 84.2996 10C88.5996 10 93.4996 10.6 95.2996 11.4C99.1996 13 104 18.4 104 21.1C104 22.8 103.2 23 97.4996 23C92.5996 23 90.9996 22.7 90.9996 21.6C90.9996 19.4 85.5996 17.6 82.2996 18.7C80.2996 19.4 79.4996 20.3 79.4996 22.1C79.4996 24.3 80.3996 24.7 89.4996 27.1C97.6996 29.3 99.9996 30.4 102.3 33C104.9 36 105.2 36.8 104.8 41.6C104.3 47.8 102.4 50.4 96.4996 53.1C91.6996 55.3 79.3996 55.6 73.6996 53.6Z"
        fill="#E10000"
      />
      <path
        d="M103 54.6C103 54.3 116.9 15.8 118.5 11.7C119.1 10.2 120.3 10 127.2 10.2L135.2 10.5L143.1 32C147.4 43.8 151 53.8 151 54.3C151 54.7 147.9 55 144 55H137.1L136.2 51.8L135.4 48.5L126.8 48.2L118.2 47.9L117.6 51.5L116.9 55H110C106.1 55 103 54.8 103 54.6ZM131.7 37.3C131.5 36.3 130.4 32.5 129.4 28.8C128.4 25 127.3 22 126.9 22C126.3 22 122 35.6 122 37.8C122 38.6 123.7 39 127.1 39C131.5 39 132.2 38.8 131.7 37.3Z"
        fill="#E10000"
      />
      <path
        d="M162.7 53.6C154.4 50.5 149.9 43.4 149.7 33.1C149.6 24.8 151.1 20.2 155.3 16.2C160.1 11.6 164.8 10 173.7 10C190.3 10.1 197.8 18.2 196.8 34.7C195.9 48.1 189.1 54.3 174.5 54.8C169.5 54.9 165.1 54.5 162.7 53.6ZM180.5 42.5C182.7 40.4 183 39.2 183 33C183 24.6 182 22.3 177.4 20.4C170.8 17.6 164.5 21.3 163.4 28.6C161.9 38.9 165.7 45 173.5 45C177 45 178.7 44.4 180.5 42.5Z"
        fill="#E10000"
      />
      <path
        d="M127 4.89999C125.6 4.29999 123 4.09999 121.3 4.39999C117.4 4.99999 116.7 2.89999 120.3 1.29999C121.9 0.59999 123.6 0.69999 126.5 1.69999C129.3 2.69999 131.2 2.79999 133.2 2.09999C135.4 1.29999 136 1.39999 136 2.49999C136 3.99999 133 5.99999 130.8 5.89999C130.1 5.89999 128.4 5.39999 127 4.89999Z"
        fill="#E10000"
      />
    </Icon>
  );
}
