import { Icon, IconProps } from "@chakra-ui/react";

export function HeadPhoneIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="14px"
      height="12px"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.2106 2.24103C10.6095 1.63383 9.89459 1.15116 9.10674 0.820675C8.31889 0.490188 7.47359 0.318369 6.61925 0.315056H6.56963C4.8372 0.315056 3.17572 1.00335 1.9507 2.22852C0.725683 3.4537 0.0374756 5.11539 0.0374756 6.84804V10.3658C0.0374756 10.7657 0.196293 11.1491 0.478989 11.4319C0.761685 11.7146 1.1451 11.8734 1.5449 11.8734H2.54984C2.94964 11.8734 3.33306 11.7146 3.61575 11.4319C3.89845 11.1491 4.05727 10.7657 4.05727 10.3658V7.85312C4.05727 7.45328 3.89845 7.06981 3.61575 6.78708C3.33306 6.50434 2.94964 6.34551 2.54984 6.34551H1.06503C1.1904 4.97207 1.82456 3.69511 2.84302 2.76532C3.86147 1.83552 5.19067 1.32006 6.56963 1.32013H6.61172C7.98494 1.32593 9.30649 1.84447 10.3173 2.77409C11.3282 3.70372 11.9554 4.97741 12.0761 6.34551H10.5894C10.1896 6.34551 9.80621 6.50434 9.52352 6.78708C9.24082 7.06981 9.082 7.45328 9.082 7.85312V10.3658C9.082 10.7657 9.24082 11.1491 9.52352 11.4319C9.80621 11.7146 10.1896 11.8734 10.5894 11.8734H11.5944C11.9942 11.8734 12.3776 11.7146 12.6603 11.4319C12.943 11.1491 13.1018 10.7657 13.1018 10.3658V6.84804C13.1051 5.99346 12.9397 5.14663 12.6152 4.35608C12.2907 3.56552 11.8133 2.84678 11.2106 2.24103ZM2.54984 7.35058C2.68311 7.35058 2.81091 7.40353 2.90515 7.49777C2.99938 7.59202 3.05232 7.71984 3.05232 7.85312V10.3658C3.05232 10.4991 2.99938 10.6269 2.90515 10.7212C2.81091 10.8154 2.68311 10.8683 2.54984 10.8683H1.5449C1.41163 10.8683 1.28383 10.8154 1.18959 10.7212C1.09536 10.6269 1.04242 10.4991 1.04242 10.3658V7.35058H2.54984ZM12.0968 10.3658C12.0968 10.4991 12.0439 10.6269 11.9497 10.7212C11.8554 10.8154 11.7276 10.8683 11.5944 10.8683H10.5894C10.4562 10.8683 10.3284 10.8154 10.2341 10.7212C10.1399 10.6269 10.087 10.4991 10.087 10.3658V7.85312C10.087 7.71984 10.1399 7.59202 10.2341 7.49777C10.3284 7.40353 10.4562 7.35058 10.5894 7.35058H12.0968V10.3658Z"
        fill="currentColor"
      />
    </Icon>
  );
}
