import { CloseIcon, DownloadIcon } from "@chakra-ui/icons";
import { Box, Button, Image, Link, useDisclosure } from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";
import { ImageIcon } from "components/vectors/image-icon";
import { useEffect, useRef, useState } from "react";
import { useEventListener } from "services/events";

export function ModalWhatsAppImage() {
  const { isOpen, onClose, onOpen } = useDisclosure();
  const linkRef = useRef<HTMLAnchorElement>(null);
  const [src, setSrc] = useState();
  const [mediaType, setMediaType] = useState("image");
  const [mimetype, setMimetype] = useState("");

  const onCloseModal = () => {
    onClose();
  };

  useEventListener("open-modal-whatsapp-image", ({ src, type, mimetype }) => {
    setSrc(src);
    setMediaType(type);
    setMimetype(mimetype);
    onOpen();
  });

  const modalFooter = (
    <>
      <Button
        leftIcon={<DownloadIcon />}
        onClick={() => {
          linkRef.current!.click();
        }}
      >
        {mediaType === "image" ? "Baixar Imagem" : "Baixar Vídeo"}
      </Button>
      <Button
        leftIcon={<CloseIcon w="11px" h="11px" />}
        onClick={onCloseModal}
        variant="outline"
      >
        Fechar
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onCloseModal}
      size="4xl"
      modalTitle={mediaType === "image" ? "Imagem WhatsApp" : "Video WhatsApp"}
      modalFooter={modalFooter}
    >
      {mediaType === "image" ? (
        <Image w="100%" src={src} />
      ) : (
        <Box width="100%" borderRadius="6px" overflow="hidden">
          <video width="100%" controls>
            <source src={src} type={mimetype} />
            Your browser does not support the video tag.
          </video>
        </Box>
      )}
      <Link ref={linkRef} download display="none" href={src} />
    </CustomModal>
  );
}
