import { CustomModal } from "components/custom-modal";
import { useState } from "react";
import { makeEvent, useEventListener } from "services/events";
import { CustomTableColor } from "components/dynamic-table/color-table";
import { getCronowiseSelectResultColumns, GovDataRow } from "../../utils";
import { Button } from "@chakra-ui/react";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

interface ModalMargensCronowiseProps {
  rows: GovDataRow[];
  onUpdateMargens: (selectedRow: GovDataRow) => void;
}

export function ModalMargensCronowise() {
  const [modalData, setModalData] = useState<
    Partial<ModalMargensCronowiseProps>
  >({});
  const [checkedRow, setCheckedRow] = useState<GovDataRow | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = (modalData: ModalMargensCronowiseProps) => {
    setIsOpen(true);
    modalData.rows = modalData.rows.map((row, id) => ({ ...row, id }));
    setModalData(modalData);
    console.log(modalData.rows);
  };

  const onClose = () => {
    setIsOpen(false);
    setModalData({});
    setCheckedRow(null);
  };

  useEventListener("openModalMargensCronowise", onOpen);

  const modalFooter = (
    <>
      <Button
        leftIcon={<CheckIcon />}
        onClick={() => {
          modalData.onUpdateMargens?.(checkedRow!);
          onClose();
        }}
        isDisabled={checkedRow === null}
      >
        Selecionar
      </Button>
      <Button
        leftIcon={<CloseIcon w="11px" h="11px" />}
        onClick={onClose}
        variant="outline"
      >
        Cancelar
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalFooter={modalFooter}
      size="6xl"
      modalTitle="Selecionar Vínculo"
    >
      <CustomTableColor
        rows={modalData.rows || []}
        columns={getCronowiseSelectResultColumns({ checkedRow, setCheckedRow })}
        onClickRow={(row: GovDataRow) => {
          if (row.id === checkedRow?.id) {
            setCheckedRow(null);
          } else {
            setCheckedRow(row);
          }
        }}
      />
    </CustomModal>
  );
}

export const openModalMargensCronowise = (
  modalData: ModalMargensCronowiseProps
) => {
  makeEvent("openModalMargensCronowise", modalData);
};
