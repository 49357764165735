import { RepeatIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Input,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import DynamicTable from "components/dynamic-table";
import { GetLayout } from "components/get-layout";
import { DocumentIcon } from "components/vectors/document-icon";
import { useEffect, useState } from "react";
import api from "../api/api";
import {
  ModalExtratoOnline,
  openModalExtratoOnline,
} from "components/extrato-online-components/modal-extrato-online";

export const ExtratoOnlineAvulso = () => {
  const [extratosOnline, setExtratosOnline] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchBenefit, setSearchBenefit] = useState("");

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadExtratosOnline();
      setLoading(false);
    };
    load();
  }, []);

  const loadExtratosOnline = async () => {
    let response = await api.get("/extratos");
    setExtratosOnline(response.data);
  };

  const atualizar = async () => {
    setLoading(true);
    await loadExtratosOnline();
    setLoading(false);
  };

  const columns = [
    {
      name: "Benefício",
      key: "nb",
    },
    {
      name: "Nome",
      render: ({ name }) => name,
    },
    {
      name: "Data Criação",
      render: ({ dataCriacao }) => new Date(dataCriacao).toLocaleString(),
    },
    {
      name: "Ações",
      render: (row) => (
        <Tooltip label="Exibir Extrato">
          <IconButton
            variant="outline"
            as="a"
            href={`${process.env.REACT_APP_PUBLIC_API_URL}/extratos/exibir/${row.hash}`}
            target="_blank"
            leftIcon={
              <Box transform="translateX(4px)">
                <DocumentIcon width="22px" height="22px" />
              </Box>
            }
          />
        </Tooltip>
      ),
    },
  ];

  const filteredExtratos = extratosOnline.filter((extrato) =>
    extrato.nb.includes(searchBenefit)
  );

  return (
    <GetLayout>
      <Grid w="100%" templateColumns="0.75fr 0.75fr 1.5fr" gap="20px" mb="20px">
        <Box>
          <Text mb="10px">&nbsp;</Text>
          <Button
            leftIcon={<DocumentIcon width="22px" height="22px" />}
            onClick={openModalExtratoOnline}
            w="100%"
          >
            Gerar Novo Extrato
          </Button>
        </Box>
        <Box>
          <Text mb="10px">&nbsp;</Text>
          <Button
            w="100%"
            leftIcon={<RepeatIcon />}
            onClick={atualizar}
            loadingText="Atualizando"
            isLoading={loading}
          >
            Atualizar Listagem
          </Button>
        </Box>
        <Box>
          <Text mb="10px">Pesquisar Benefício</Text>
          <Input
            mr="8px"
            onChange={(e) => setSearchBenefit(e.target.value)}
            value={searchBenefit}
            w="100%"
          />
        </Box>
      </Grid>

      <DynamicTable
        rows={filteredExtratos}
        columns={columns}
        isLoading={loading}
      />
      <ModalExtratoOnline loadExtratosOnline={loadExtratosOnline} />
    </GetLayout>
  );
};
