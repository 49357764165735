import { Context3cPlusProvider } from "contexts/Context3cPlus";
import { useRouter } from "./router-control/use-router";
import { AttendanceProvider } from "contexts/attendance-context";
import { getEventListeners, makeEvent } from "services/events";
import { ToastContainer } from "components/toast";
import "../components/toast/style.css";

export function PageTemplate({
  PageComponent,
}: {
  PageComponent: () => JSX.Element;
}) {
  const attendanceRoutes = ["/atendimentos", "/atendimento-cliente-page"];
  const router = useRouter();
  return (
    <main
      onClickCapture={(e) => {
        if (getEventListeners("globalOnClick")) makeEvent("globalOnClick", e);
        if (getEventListeners("blur-popover")) makeEvent("blur-popover", e);
        if (getEventListeners("onClickBody")) makeEvent("onClickBody", e);
      }}
    >
      <ToastContainer />
      {attendanceRoutes.includes(router.pathname) ? (
        <Context3cPlusProvider>
          {router.pathname === "/atendimento-cliente-page" ? (
            <AttendanceProvider>
              <PageComponent />
            </AttendanceProvider>
          ) : (
            <PageComponent />
          )}
        </Context3cPlusProvider>
      ) : (
        <PageComponent />
      )}
    </main>
  );
}
