import { Icon, IconProps } from "@chakra-ui/icons";
import { SVGProps } from "react";

export function CheckIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.8412 11.9865C23.8412 18.5337 18.5337 23.8412 11.9866 23.8412C5.43936 23.8412 0.131836 18.5337 0.131836 11.9865C0.131836 5.43936 5.43936 0.131836 11.9866 0.131836C18.5337 0.131836 23.8412 5.43936 23.8412 11.9865ZM10.2608 17.1236H10.2684C10.608 17.1236 10.9315 16.9779 11.157 16.7234L18.1436 8.82027C18.5772 8.32975 18.5311 7.58053 18.0406 7.14691C17.5498 6.71329 16.8006 6.7594 16.3673 7.24992L10.2803 14.1349L7.61636 11.041C7.18907 10.545 6.44064 10.4891 5.94433 10.9162C5.44801 11.3435 5.39216 12.0922 5.81946 12.5882L9.37007 16.7118C9.59346 16.971 9.91828 17.1212 10.2608 17.1236Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export function CheckIconV2({ ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" focusable="false" {...rest}>
      <g fill="currentColor">
        <polygon points="5.5 11.9993304 14 3.49933039 12.5 2 5.5 8.99933039 1.5 4.9968652 0 6.49933039"></polygon>
      </g>
    </svg>
  );
}

export function CheckIconV3({
  isChecked = true,
  ...rest
}: { isChecked?: boolean } & SVGProps<SVGSVGElement>) {
  return isChecked ? (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.5 9L8.16667 10.6667L11.5 7.33333M16.5 9C16.5 9.98491 16.306 10.9602 15.9291 11.8701C15.5522 12.7801 14.9997 13.6069 14.3033 14.3033C13.6069 14.9997 12.7801 15.5522 11.8701 15.9291C10.9602 16.306 9.98491 16.5 9 16.5C8.01509 16.5 7.03982 16.306 6.12987 15.9291C5.21993 15.5522 4.39314 14.9997 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801 2.0709 11.8701C1.69399 10.9602 1.5 9.98491 1.5 9C1.5 7.01088 2.29018 5.10322 3.6967 3.6967C5.10322 2.29018 7.01088 1.5 9 1.5C10.9891 1.5 12.8968 2.29018 14.3033 3.6967C15.7098 5.10322 16.5 7.01088 16.5 9Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16 8.5C16 9.48491 15.806 10.4602 15.4291 11.3701C15.0522 12.2801 14.4997 13.1069 13.8033 13.8033C13.1069 14.4997 12.2801 15.0522 11.3701 15.4291C10.4602 15.806 9.48491 16 8.5 16C7.51509 16 6.53982 15.806 5.62987 15.4291C4.71993 15.0522 3.89314 14.4997 3.1967 13.8033C2.50026 13.1069 1.94781 12.2801 1.5709 11.3701C1.19399 10.4602 1 9.48491 1 8.5C1 6.51088 1.79018 4.60322 3.1967 3.1967C4.60322 1.79018 6.51088 1 8.5 1C10.4891 1 12.3968 1.79018 13.8033 3.1967C15.2098 4.60322 16 6.51088 16 8.5Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
