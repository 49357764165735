import { GridProps } from "@chakra-ui/react";
import {
  Attendance,
  ConsultaPropostaFGTS,
  DigitationDataProps,
} from "components/atendimentos-components/atendimento-form/types";
import { AttendanceActionsTypes } from "contexts/attendance-context/actions-reducer";
import {
  mapObject,
  mapObjectToObject,
} from "components/atendimentos-components/atendimento-form/functions/mapeamentos";
import { KeyForward } from "components/atendimentos-components/atendimento-form/functions/mapeamentos/types";
import { DropdownFieldProps } from "components/dropdown-field";
import { InputFieldProps } from "components/input-field";
import { InputSearchProps } from "components/input-search";
import { Dispatch } from "react";
import {
  currencyMaskFormat,
  customMask,
  maskCPF,
  maskPhoneFormat,
} from "utils/string-formats";
import { factaCitiesArray } from "./cities-array";
import { ObjectSchema, objectSchema } from "utils/object-methods";
import { fieldValidation } from "utils/field-validation";
import { paisesList } from "utils/country-list";
import { Toast } from "components/toast";
import axios from "axios";

export const UFsBrasil: { value: string; name: string }[] = [
  { name: "AC", value: "AC" },
  { name: "AL", value: "AL" },
  { name: "AM", value: "AM" },
  { name: "AP", value: "AP" },
  { name: "BA", value: "BA" },
  { name: "CE", value: "CE" },
  { name: "DF", value: "DF" },
  { name: "ES", value: "ES" },
  { name: "GO", value: "GO" },
  { name: "MA", value: "MA" },
  { name: "MG", value: "MG" },
  { name: "MS", value: "MS" },
  { name: "MT", value: "MT" },
  { name: "PA", value: "PA" },
  { name: "PB", value: "PB" },
  { name: "PE", value: "PE" },
  { name: "PI", value: "PI" },
  { name: "PR", value: "PR" },
  { name: "RJ", value: "RJ" },
  { name: "RN", value: "RN" },
  { name: "RO", value: "RO" },
  { name: "RR", value: "RR" },
  { name: "RS", value: "RS" },
  { name: "SC", value: "SC" },
  { name: "SE", value: "SE" },
  { name: "SP", value: "SP" },
  { name: "TO", value: "TO" },
];

export const dropdownOrgaosEmissoresDocDigitacaoFGTSFacta = [
  { name: "ABNC - Academia Brasileira de Neurocirurgia", value: "ABNC" },
  {
    name: "CGPI - Coordenação Geral de Polícia de Imigração da Polícia Federal",
    value: "CGPI",
  },
  {
    name: "CGPMAF - Coordenadoria Geral de Polícia Marítima, Aeronáutica e de Fronteiras",
    value: "CGPMAF",
  },
  { name: "CNIG - Conselho Nacional de Imigração", value: "CNIG" },
  { name: "CNT - Confederação Nacional de Transporte", value: "CNT" },
  { name: "CORECON - Conselho Regional de Economia", value: "CORECON" },
  { name: "COREN - Conselho Regional de Enfermagem", value: "COREN" },
  { name: "CRA - Conselho Regional de Administração", value: "CRA" },
  { name: "CRAS - Centro de Referência de Assistência Social", value: "CRAS" },
  { name: "CRB - Conselho Regional de Biblioteconomia", value: "CRB" },
  { name: "CRC - Conselho Regional de Contabilidade", value: "CRC" },
  { name: "CRE - Conselho Regional de Estatística", value: "CRE" },
  { name: "CREA - Conselho Regional de Engenharia e Agronomia", value: "CREA" },
  {
    name: "CRECI - Conselho Regional de Corretores de Imóveis",
    value: "CRECI",
  },
  {
    name: "CREFIT - Conselho Regional de Fisioterapia e Terapia Ocupacional",
    value: "CREFIT",
  },
  { name: "CRESS - Conselho Regional de Serviço Social", value: "CRESS" },
  { name: "CRF - Conselho Regional de Farmácia", value: "CRF" },
  { name: "CRM - Conselho Regional de Medicina", value: "CRM" },
  { name: "CRMV - Conselho Regional de Medicina Veterinária", value: "CRMV" },
  { name: "CRN - Conselho Regional de Nutrição", value: "CRN" },
  { name: "CRO - Conselho Regional de Odontologia", value: "CRO" },
  { name: "CRP - Conselho Regional de Psicologia", value: "CRP" },
  {
    name: "CRPRE - Conselho Regional de Profissionais de Relações Públicas",
    value: "CRPRE",
  },
  { name: "CRQ - Conselho Regional de Química", value: "CRQ" },
  {
    name: "CRRC - Conselho Regional de Representantes Comerciais",
    value: "CRRC",
  },
  { name: "CSC - Carteira Sede Carpina de Pernambuco", value: "CSC" },
  { name: "CTPS - Carteira de Trabalho e Previdência Social", value: "CTPS" },
  {
    name: "DETRAN - Carteira Nacional de Habilitação, CRLV  Certificado de Registro e Licenciamento de Veículos, etc.",
    value: "DETRAN",
  },
  { name: "DIC - Diretoria de Identificação Civil", value: "DIC" },
  { name: "DIREX - Diretoria de Identificação Civil", value: "DIREX" },
  { name: "DPF - Polícia Federal", value: "DPF" },
  {
    name: "DPMAF - Divisão de Polícia Marítima, Área e de Fronteiras",
    value: "DPMAF",
  },
  { name: "DPT - Departamento de Polícia Técnica Geral", value: "DPT" },
  { name: "DST - Programa Municipal DST/Aids", value: "DST" },
  { name: "FGTS - Fundo de Garantia do Tempo de Serviço", value: "FGTS" },
  { name: "FIPE - Fundação Instituto de Pesquisas Econômicas", value: "FIPE" },
  { name: "FLS - Fundação Lyndolpho Silva", value: "FLS" },
  { name: "GOVGO - Governo do Estado de Goiás", value: "GOVGO" },
  { name: "IFP - Instituto Félix Pacheco", value: "IFP" },
  { name: "IGP - Instituto Geral de Perícias", value: "IGP" },
  {
    name: "IICCECF - Instituto de Identificação Civil e Criminal Engrácia da Costa Francisco de Rondônia",
    value: "IICCECF",
  },
  { name: "IIMG - Inter institutional Monitoring Group", value: "IIMG" },
  { name: "IML - Instituto Médico Legal", value: "IML" },
  { name: "IPC - Índice de Preços ao Consumidor", value: "IPC" },
  { name: "IPF - Instituto Pereira Faustino", value: "IPF" },
  { name: "MAE - Ministério da Aeronáutica", value: "MAE" },
  { name: "MEX - Ministério do Exército", value: "MEX" },
  { name: "MMA - Ministério da Marinha", value: "MMA" },
  { name: "OAB - Ordem dos Advogados do Brasil", value: "OAB" },
  { name: "OMB - Ordens dos Músicos do Brasil", value: "OMB" },
  { name: "PCMG - Policia Civil do Estado de Minas Gerais", value: "PCMG" },
  { name: "PMMG - Polícia Militar do Estado de Minas Gerais", value: "PMMG" },
  { name: "POF - Polícia Federal", value: "POF" },
  { name: "POM - Polícia Militar", value: "POM" },
  { name: "SDS - Secretaria de Defesa Social (Pernambuco)", value: "SDS" },
  { name: "SECC - Secretaria de Estado da Casa Civil", value: "SECC" },
  {
    name: "SEJUSP - Secretaria de Estado de Justiça e Segurança Pública  Mato Grosso",
    value: "SEJUSP",
  },
  { name: "SES - Carteira de Estrangeiro", value: "SES" },
  {
    name: "SESP - Secretaria de Estado da Segurança Pública do Paraná",
    value: "SESP",
  },
  { name: "SJS - Secretaria da Justiça e Segurança", value: "SJS" },
  {
    name: "SJTC - Secretaria da Justiça do Trabalho e Cidadania",
    value: "SJTC",
  },
  {
    name: "SJTS - Secretaria da Justiça do Trabalho e Segurança",
    value: "SJTS",
  },
  {
    name: "SNJ - Secretaria Nacional de Justiça / Departamento de Estrangeiros",
    value: "SNJ",
  },
  { name: "SPTC - Secretaria de Polícia Técnico Científica", value: "SPTC" },
  { name: "SSP - Secretaria de Segurança Pública do Estado", value: "SSP" },
];

const statusCivilOptions = [
  { name: "CASADO", value: "CASADO" },
  { name: "DESQUITADO", value: "DESQUITADO" },
  { name: "DIVORCIADO", value: "DIVORCIADO" },
  { name: "NÃO CADASTRADO", value: "NAO CADASTRADO" },
  { name: "NÃO DEFINIDO", value: "NAO DEFINIDO" },
  { name: "SEPARADO", value: "SEPARADO" },
  { name: "SOLTEIRO", value: "SOLTEIRO" },
  { name: "UNIÃO ESTÁVEL", value: "UNIÃO ESTÁVEL" },
  { name: "VIUVO", value: "VIUVO" },
  { name: "OUTROS", value: "OUTROS" },
];

const yesOrNoOptions = [
  { name: "Sim", value: "SIM" },
  { name: "Não", value: "NÃO" },
];

const dropdownTipoConta = [
  { name: "Conta Corrente", value: "CONTA_CORRENTE_INDIVIDUAL" },
  { name: "Conta Poupança", value: "CONTA_POUPANCA_INDIVIDUAL" },
];

type DigitationField =
  | {
      key: keyof DigitationDataProps;
      ufKey?: undefined;
      label: string;
      fieldType: "input";
      componentProps?: InputFieldProps;
      format?: (
        value: any,
        digitationData: DigitationDataProps
      ) => { raw: any; value: any };
      onChange?: (
        value: string,
        changeFunction: (field: keyof DigitationDataProps, data: any) => void,
        digitationData: DigitationDataProps
      ) => void;
      containerProps?: GridProps;
      options?: undefined;
      isVisible?: (attendance: Attendance) => boolean;
    }
  | {
      key: keyof DigitationDataProps;
      ufKey?: undefined;
      label: string;
      fieldType: "dropdown";
      componentProps?: DropdownFieldProps;
      format?: (
        value: any,
        digitationData: DigitationDataProps
      ) => { raw: any; value: any };
      containerProps?: GridProps;
      options?: undefined;
      isVisible?: (attendance: Attendance) => boolean;
      onChange?: (
        value: string,
        changeFunction: (field: keyof DigitationDataProps, data: any) => void,
        digitationData: DigitationDataProps
      ) => void;
    }
  | {
      key: keyof DigitationDataProps;
      ufKey?: keyof DigitationDataProps;
      label: string;
      fieldType: "inputSearch";
      componentProps?: InputSearchProps;
      format?: undefined;
      onChange?: undefined;
      containerProps?: GridProps;
      options: { name: string; value: any }[];
      isVisible?: (attendance: Attendance) => boolean;
    };

export function maskDateFormat(value: string | undefined | null) {
  value = value?.replace(/\D/g, "") ?? "";
  const result = customMask(value, {
    match: /(\d{0,2})(\d{0,2})(\d{0,4})/,
    separators: ["/", "/"],
  });
  return { raw: result, value: result };
}

export const fieldsDigitacaoFGTSFacta: DigitationField[] = [
  {
    label: "CPF",
    key: "cpf",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
    format: (value) => {
      const result = maskCPF(value);
      return { raw: result, value: result };
    },
  },
  {
    label: "Nome",
    key: "name",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Data Nascimento",
    key: "birthDay",
    fieldType: "input",
    componentProps: { inputProps: { placeholder: "DD/MM/AAAA" } },
    containerProps: { gridArea: "auto / span 12" },
    format: maskDateFormat,
  },
  {
    label: "Sexo",
    key: "gender",
    fieldType: "dropdown",
    componentProps: {
      options: [
        { name: "Feminino", value: "F" },
        { name: "Masculino", value: "M" },
      ],
    },
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Estado Civil",
    key: "civilStatus",
    fieldType: "dropdown",
    componentProps: { options: statusCivilOptions },
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "CPF do Cônjuge",
    key: "cpfPartner",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
    format: (value) => {
      const result = maskCPF(value);
      return { raw: result, value: result };
    },
    isVisible: (att) => att.formularioFactaFGTS?.civilStatus === "CASADO",
  },
  {
    label: "Nome do Cônjuge",
    key: "namePartner",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
    isVisible: (att) => att.formularioFactaFGTS?.civilStatus === "CASADO",
  },
  {
    label: "Data de nascimento Cônjuge",
    key: "birthDayPartner",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
    isVisible: (att) => att.formularioFactaFGTS?.civilStatus === "CASADO",
  },
  {
    label: "Nacionalidade",
    key: "nationality",
    fieldType: "dropdown",
    componentProps: {
      options: [
        { name: "Brasileiro", value: "BRASILEIRO" },
        { name: "Estrangeiro", value: "ESTRANGEIRO" },
      ],
    },
    containerProps: { gridArea: "auto / span 12" },
    onChange(value, changeFunction, digitationData) {
      if (value === "BRASILEIRO") {
        changeFunction("nationality", value);
        changeFunction("originCountry", undefined);
      } else if (value === "ESTRANGEIRO") {
        changeFunction("nationality", value);
      }
    },
  },
  {
    label: "País Origem",
    key: "originCountry",
    fieldType: "dropdown",
    componentProps: { options: paisesList },
    containerProps: { gridArea: "auto / span 12" },
    isVisible: (att) => att.formularioFactaFGTS?.nationality === "ESTRANGEIRO",
  },
  {
    label: "Número do Documento",
    key: "documentNumber",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "UF Emissão Documento",
    key: "documentEmissionState",
    fieldType: "dropdown",
    componentProps: { options: UFsBrasil },
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Orgão Emissor Documento",
    key: "documentIssuer",
    fieldType: "dropdown",
    componentProps: { options: dropdownOrgaosEmissoresDocDigitacaoFGTSFacta },
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Data Emissão Documento",
    key: "documentDate",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
    format: maskDateFormat,
  },
  {
    label: "Nome Mãe",
    key: "mothersName",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Nome Pai",
    key: "fathersName",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Cidade Nascimento",
    key: "birthCity",
    fieldType: "inputSearch",
    containerProps: { gridArea: "auto / span 12" },
    options: factaCitiesArray,
    ufKey: "birthState",
    // componentProps: {
    //   optionLabelRender: (option) => `${option.name} - ${option.value.uf}`,
    // },
  },
  {
    label: "Salário",
    key: "incomeValue",
    fieldType: "input",
    format: currencyMaskFormat,
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Patrimônio",
    key: "equityValue",
    fieldType: "input",
    format: currencyMaskFormat,
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Pessoa Analfabeta",
    key: "illiterate",
    fieldType: "dropdown",
    componentProps: { options: yesOrNoOptions },
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Celular",
    key: "phoneNumber1",
    fieldType: "input",
    componentProps: {
      inputProps: { placeholder: "Número Telefone ex.: (00) 00000-0000" },
    },
    containerProps: {
      gridArea: "auto / span 12",
      templateColumns: "220px auto",
    },
    format: (value, digitationData) => {
      const ddd = digitationData["phoneDDD1"] ?? "";
      const result = maskPhoneFormat(ddd + value);
      return { raw: "", value: result.formatedValue };
    },
    onChange: (value, changeFunc) => {
      const result = maskPhoneFormat(value);
      const ddd = result.parts[1];
      const numberPart1 = result.parts[2];
      const numberPart2 = result.parts[3];
      const number = numberPart1 + numberPart2;
      changeFunc?.("phoneNumber1", number);
      changeFunc?.("phoneDDD1", ddd);
    },
  },
  {
    label: "Rua",
    key: "addressStreet1",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Número",
    key: "addressNumber1",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
    componentProps: {
      inputProps: { type: "number" },
    },
  },
  {
    label: "Complemento",
    key: "addressComplement1",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Bairro",
    key: "addressNeigh1",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Cidade",
    key: "addressCity1",
    fieldType: "inputSearch",
    containerProps: { gridArea: "auto / span 12" },
    options: factaCitiesArray,
    ufKey: "addressStateCode1",
    // componentProps: {
    //   optionLabelRender: (option) => `${option.name} - ${option.value.uf}`,
    // },
  },
  {
    label: "CEP",
    key: "addressZipCode1",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Código Banco",
    key: "bankNumber",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Agência",
    key: "bankAgency",
    fieldType: "input",
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Conta + DV",
    key: "bankAccountNumber",
    fieldType: "input",
    componentProps: {
      inputProps: { placeholder: "Conta com dígito verficador" },
    },
    containerProps: { gridArea: "auto / span 12" },
  },
  {
    label: "Tipo Conta",
    key: "bankType",
    fieldType: "dropdown",
    componentProps: { options: dropdownTipoConta },
    containerProps: { gridArea: "auto / span 12" },
  },
];

// console.log(
//   fieldsDigitacaoFGTSFacta
//     .map(
//       ({ label, key, fieldType, ufKey, options }) => `<Box w="100%">
//    ${
//      fieldType === "input"
//        ? `<InputField
//             title="${label}"
//             onChange={(e) => onChangeForm({ key: "${key}", value: e.target.value })}
//             value={digitacao["${key}"]}
//             errorMessage={errors["${key}"]}
//           />`
//        : fieldType === "dropdown"
//        ? `<DropdownField
//             title="${label}"
//             onChange={(value) => onChangeForm({ key: "${key}", value: value })}
//             value={digitacao["${key}"]}
//             errorMessage={errors["${key}"]}
//             dropdownProps={{ w: "100%" }}
//           />`
//        : `<InputSearch
//                       onChange={(optionValue: {
//                         cityName: string;
//                         uf: string;
//                       }) => {
//                         onChangeForm({ key: "${key}", value: optionValue.cityName });
//                         if (digitacao["${ufKey}"])
//                           onChangeForm({ key: "${ufKey}", value: optionValue.uf });
//                       }}
//                       clearInput={false}
//                       searchKeys={["name"]}
//                       errorMessage={errors["${key}"]}
//                       checkIsInclude={(option) =>
//                         digitacao["${key}"] === option.value.cityName
//                       }
//                       optionLabelRender={(option) =>
//                         ${"`${option.name} - ${option.value.uf}`"}
//                       }
//                       value={
//                         digitacao["${key}"] && digitacao["${ufKey}"]
//                           ? ${"`"}${`digitacao["${key}"] - digitacao["${ufKey}"]`}${"`"}
//                           : undefined
//                       }
//                       options={options}
//                       inputProps={{
//                         id: "${`InputSearch-${key}`}",
//                         bg: "#fff",
//                         onBlur: (e) => {
//                           if (
//                             digitacao["${ufKey}"] &&
//                             digitacao["${key}"]
//                           )
//                             e.target.value = ${"`"}${`digitacao["${key}"] - digitacao["${ufKey}"]`}${"`"};
//                           else e.target.value = "";
//                         },
//                       }}
//                     />`
//    }
// </Box>`
//     )
//     .join(`\n`)
// );

export function copyAttendanceDataToFactaForm(
  data: Attendance,
  dispatch: Dispatch<AttendanceActionsTypes>
) {
  let formularioFactaFGTS = data.formularioFactaFGTS;
  const keyForward: KeyForward<Attendance> = {
    cpf: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, cpf: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    ufEmissaoDoc: (value) => {
      if (UFsBrasil.find((crr) => crr.value === value))
        formularioFactaFGTS = {
          ...formularioFactaFGTS,
          documentEmissionState: value,
        };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    nome: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, name: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    dataNascimento: (value) => {
      const format = maskDateFormat(value).raw;
      const { isValid } = fieldValidation({ value }).date();
      if (isValid)
        formularioFactaFGTS = { ...formularioFactaFGTS, birthDay: format };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },

    estadoCivil: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, civilStatus: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    documento: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, documentNumber: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    dataEmissaoDoc: (value) => {
      const format = maskDateFormat(value).raw;
      const { isValid } = fieldValidation({ value }).date();
      if (isValid)
        formularioFactaFGTS = { ...formularioFactaFGTS, documentDate: format };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    nomeMae: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, mothersName: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    nomePai: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, fathersName: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    valorSalario: (value) => {
      if (value)
        formularioFactaFGTS = { ...formularioFactaFGTS, incomeValue: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },

    endereco: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, addressStreet1: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    numero: (value) => {
      value = value?.replace(/\D/g, "");
      if (Number(value) !== 0)
        formularioFactaFGTS = { ...formularioFactaFGTS, addressNumber1: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    complemento: (value) => {
      formularioFactaFGTS = {
        ...formularioFactaFGTS,
        addressComplement1: value,
      };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    bairro: (value) => {
      if (value === "NULL") {
      } else
        formularioFactaFGTS = { ...formularioFactaFGTS, addressNeigh1: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    cep: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, addressZipCode1: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    codBancoPagamento: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, bankNumber: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    sexo: (value) => {
      if (value === "FEMININO")
        formularioFactaFGTS = { ...formularioFactaFGTS, gender: "F" };
      else if (value === "MASCULINO")
        formularioFactaFGTS = { ...formularioFactaFGTS, gender: "M" };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
  };

  Toast({ title: "Dados do atendimento copiados" });
  mapObject({ keyForward, data, dispatch });
}
export function copyAttendanceDataToDigitacao(data: Attendance) {
  let formularioFactaFGTS = data.formularioFactaFGTS;
  const possiveisCidades = factaCitiesArray.filter((crr) => {
    return (
      crr.value.uf === data.uf && crr.value.cityName.includes(data.cidade!)
    );
  });
  console.log(possiveisCidades);

  const keyForward: KeyForward<Attendance> = {
    cpf: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, cpf: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    ufEmissaoDoc: (value) => {
      if (UFsBrasil.find((crr) => crr.value === value))
        formularioFactaFGTS = {
          ...formularioFactaFGTS,
          documentEmissionState: value,
        };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    nome: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, name: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    dataNascimento: (value) => {
      const format = maskDateFormat(value).raw;
      const { isValid } = fieldValidation({ value }).date();
      if (isValid)
        formularioFactaFGTS = { ...formularioFactaFGTS, birthDay: format };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },

    estadoCivil: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, civilStatus: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    documento: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, documentNumber: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    dataEmissaoDoc: (value) => {
      const format = maskDateFormat(value).raw;
      const { isValid } = fieldValidation({ value }).date();
      if (isValid)
        formularioFactaFGTS = { ...formularioFactaFGTS, documentDate: format };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    nomeMae: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, mothersName: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    nomePai: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, fathersName: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    valorSalario: (value) => {
      if (value)
        formularioFactaFGTS = { ...formularioFactaFGTS, incomeValue: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },

    endereco: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, addressStreet1: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    numero: (value) => {
      value = value?.replace(/\D/g, "");
      if (Number(value) !== 0)
        formularioFactaFGTS = { ...formularioFactaFGTS, addressNumber1: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    complemento: (value) => {
      formularioFactaFGTS = {
        ...formularioFactaFGTS,
        addressComplement1: value,
      };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    cidade: (value) => {
      if (possiveisCidades.length === 1) {
        formularioFactaFGTS = {
          ...formularioFactaFGTS,
          addressCity1: possiveisCidades[0].value.cityName,
          addressStateCode1: possiveisCidades[0].value.uf,
        };
      }
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    bairro: (value) => {
      if (value === "NULL") {
      } else
        formularioFactaFGTS = { ...formularioFactaFGTS, addressNeigh1: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    cep: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, addressZipCode1: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    codBancoPagamento: (value) => {
      formularioFactaFGTS = { ...formularioFactaFGTS, bankNumber: value };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
    sexo: (value) => {
      if (value === "FEMININO")
        formularioFactaFGTS = { ...formularioFactaFGTS, gender: "F" };
      else if (value === "MASCULINO")
        formularioFactaFGTS = { ...formularioFactaFGTS, gender: "M" };
      return { data: formularioFactaFGTS, field: "formularioFactaFGTS" };
    },
  };

  Toast({ title: "Dados do atendimento copiados" });

  return mapObjectToObject({ keyForward, data })?.formularioFactaFGTS;
}

export interface CEPProps {
  cep: string;
  logradouro: string;
  complemento: string;
  unidade: string;
  bairro: string;
  localidade: string;
  uf: string;
  estado: string;
  regiao: string;
  ibge: string;
  gia: string;
  ddd: string;
  siafi: string;
}

type GetCEPDataReturn =
  | ({ error: false; errorMessage?: string } & CEPProps)
  | { error: true; errorMessage?: string };

export const getCEPData = async (cpf: string): Promise<GetCEPDataReturn> => {
  try {
    const { data } = await axios.get<CEPProps>(
      `https://viacep.com.br/ws/${cpf}/json`
    );
    return { ...data, error: false };
  } catch (e: any) {
    const errorMessage = ((
      {
        400: "CEP inválido.",
        404: "CEP não encontrado.",
        500: "Erros do backend e/ou dos servidores de dados.",
      } as any
    )[e?.response?.status || ""] || "") as string;
    return { errorMessage, error: true };
  }
};

export function mapeamentoConsultaPropostaFGTS({
  data,
  formValues,
  dispatch,
}: {
  data: ConsultaPropostaFGTS;
  formValues: Attendance;
  dispatch: Dispatch<AttendanceActionsTypes>;
}) {
  let atendimentoFactaFGTS = formValues.atendimentoFactaFGTS!;
  const keyForward: KeyForward<Attendance> = {
    formalizationLink: (formalizationLink) => {
      atendimentoFactaFGTS = { ...atendimentoFactaFGTS, formalizationLink };
      return {
        data: atendimentoFactaFGTS,
        field: "atendimentoFactaFGTS",
        dispatchOptions: { type: "changeFieldWithInitial" },
      };
    },
    ade: (ade) => {
      atendimentoFactaFGTS = { ...atendimentoFactaFGTS, ade };
      return {
        data: atendimentoFactaFGTS,
        field: "atendimentoFactaFGTS",
        dispatchOptions: { type: "changeFieldWithInitial" },
      };
    },
  };

  mapObject({ keyForward, data, dispatch });
}

export const digitacaoPropostaErrors = new Map([
  ["celular_invalido", "Insira um número de celular válido"],
  [
    "already_have_digitacao",
    "Já existe uma digitação para este CPF. Contate o administrador para liberar.",
  ],
  ["no_simulation_found", "É necessário fazer uma simulação antes de digitar"],
  [
    "servico_indisponivel_temporariamente",
    "Serviço indisponível temporáriamente",
  ],
]);

export function checkFieldsDigitacaoFacta(
  formularioFactaFGTS: Attendance["formularioFactaFGTS"]
) {
  let schema: ObjectSchema = {
    cpf: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value })
        .required({ message })
        .cpf({ message: "Insira um cpf válido" });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    name: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    birthDay: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value })
        .required({ message })
        .date({ message: "Data inválida" });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    gender: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    civilStatus: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    nationality: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    originCountry: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return {
        valid:
          formularioFactaFGTS.nationality === "ESTRANGEIRO"
            ? validation.isValid
            : true,
        message: validation.errorMessage,
      };
    },
    documentNumber: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    documentEmissionState: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    documentIssuer: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    documentDate: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value })
        .required({ message })
        .date({ message: "Data inválida" });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    mothersName: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    fathersName: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    birthCity: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    birthState: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    incomeValue: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    equityValue: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    illiterate: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    phoneDDD1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    phoneNumber1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressStreet1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressNumber1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      if (validation.isValid && value.length && Number(value) === 0) {
        validation.errorMessage = "O número do endereço não pode ser zero";
        validation.isValid = false;
      }
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressComplement1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressNeigh1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressCity1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressStateCode1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    addressZipCode1: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    bankAgency: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    bankNumber: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    bankAccountNumber: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
    bankType: (value) => {
      const message = "Preecha o campo";
      const validation = fieldValidation({ value }).required({ message });
      return { valid: validation.isValid, message: validation.errorMessage };
    },
  };

  if (formularioFactaFGTS.civilStatus === "CASADO") {
    schema = {
      ...schema,
      namePartner: (value) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
      birthDayPartner: (value) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value }).required({ message });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
      cpfPartner: (value) => {
        const message = "Preecha o campo";
        const validation = fieldValidation({ value })
          .required({ message })
          .cpf({ message: "Insira um cpf válido" });
        return { valid: validation.isValid, message: validation.errorMessage };
      },
    };
  }

  const { isValid, errors } = objectSchema(schema, formularioFactaFGTS);

  return { isValid, errors };
}
