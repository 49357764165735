export function RenewIcon() {
  return (
    <svg
      width="16px"
      height="16px"
      viewBox="0 0 118 118"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_750_9)">
        <path
          d="M54.6209 117.746C46.0475 117.124 38.0272 114.889 30.099 110.925C25.1209 108.436 19.9815 104.955 16.2479 101.568L14.8651 100.3L11.2928 103.803C8.55021 106.5 7.46701 107.421 6.63732 107.744C3.91779 108.758 0.875601 107.122 0.230289 104.264C-0.0923674 102.95 -0.0693205 83.6371 0.253336 82.4156C0.575992 81.125 1.68224 79.7883 2.85763 79.2582C3.73341 78.8664 4.58615 78.8203 14.7498 78.8203C27.0568 78.8203 26.8494 78.7973 28.3014 80.5488C29.3385 81.8395 29.6381 83.2914 29.1541 84.9508C28.8084 86.1492 28.4858 86.5641 25.2361 89.8598L21.6869 93.4551L22.8854 94.5152C32.3807 102.835 43.7889 107.421 56.9947 108.205L59.4608 108.366L59.4147 113.114L59.3455 117.885L58.3084 117.908C57.7322 117.931 56.0729 117.862 54.6209 117.746Z"
          fill="currentColor"
        />
        <path
          d="M2.85751 56.2574C1.65907 55.7043 0.575867 54.3906 0.230164 53.077C-0.253821 51.0949 1.4286 43.3512 3.54891 37.9582C6.49891 30.4219 10.486 24.1992 16.1325 18.3684C21.1106 13.2059 25.8813 9.70274 32.4036 6.43008C40.0091 2.6043 47.6837 0.622272 56.8794 0.11524L59.4606 -0.0230408V4.8168V9.6336L57.2251 9.79493C49.2509 10.302 43.8349 11.6848 37.3356 14.7961C22.6548 21.8254 12.7446 34.9621 9.9329 51.1641C9.37977 54.2293 8.8036 55.2895 7.16727 56.1652C5.92274 56.8106 4.19423 56.8566 2.85751 56.2574Z"
          fill="currentColor"
        />
        <path
          d="M51.51 97.7418C51.1412 97.5113 50.6342 97.1195 50.4037 96.866C49.4358 95.7828 49.4588 95.5984 51.51 79.9727C52.5932 71.791 53.5151 64.8309 53.5842 64.4852C53.6764 63.9551 53.6072 63.8398 53.2154 63.8398C52.2014 63.8398 52.985 62.5262 58.1936 55.5199C62.3651 49.9195 63.6096 48.4215 64.0244 48.3984C64.3701 48.3984 64.5315 48.5367 64.5315 48.8594C64.5315 49.3203 64.6928 49.3203 72.7822 49.3203C80.5951 49.3203 81.0561 49.3434 81.7705 49.7813C82.969 50.5188 83.476 51.5328 83.3608 53.0078C83.2916 54.2293 82.9229 54.8516 70.1779 74.7871C54.9901 98.6176 55.935 97.1656 55.0131 97.7418C54.0682 98.318 52.4319 98.318 51.51 97.7418Z"
          fill="currentColor"
        />
        <path
          d="M58.7695 113.137V108.413L62.4109 108.136C66.9281 107.767 70.8691 106.984 74.9023 105.647C87.2785 101.521 97.6035 92.6715 103.434 81.1711C105.739 76.6539 107.191 72.3211 108.09 67.1817C108.643 64.1856 108.873 63.5863 109.864 62.6414C112.515 60.1063 116.917 61.4199 117.77 65.0152C118 65.9602 117.954 66.5594 117.447 69.302C116.41 75.0176 114.704 80.1109 112.215 85.1121C102.513 104.402 82.5539 117.216 61.1203 117.816L58.7695 117.885V113.137Z"
          fill="currentColor"
        />
        <path
          d="M91.2656 38.6496C89.7215 37.843 88.8918 36.5063 88.7766 34.7086C88.7074 33.6484 88.7996 33.0492 89.0762 32.4731C89.2836 32.0352 91.0352 30.0992 92.948 28.1633L96.4512 24.6371L95.1836 23.4617C92.0031 20.4887 87.3938 17.4695 82.4387 15.0957C75.8703 11.9383 69.0945 10.2328 61.4891 9.77188L58.7695 9.61055V4.83985V0.0460968L60.7746 0.138284C71.0535 0.645316 79.2352 2.71953 87.9008 7.07539C92.948 9.61055 97.0734 12.3992 101.706 16.4555L103.273 17.8152L107.007 14.1277C110.763 10.3941 111.478 9.91016 113.045 9.91016C114.75 9.91016 117.009 11.5004 117.585 13.0906C117.816 13.7359 117.885 16.4785 117.885 24.7754V35.6074L117.239 36.7137C116.894 37.3129 116.156 38.0965 115.649 38.4422L114.681 39.0645L103.48 39.1336L92.2797 39.2027L91.2656 38.6496Z"
          fill="currentColor"
        />
        <path
          d="M36.8751 63.5863C35.0544 62.8488 34.0634 60.5902 34.8239 58.9078C34.9853 58.5852 41.2771 49.9195 48.8364 39.5945C57.5482 27.7254 62.8489 20.673 63.356 20.3504C65.4763 18.9215 68.6798 20.65 68.6798 23.2312C68.6798 24.3605 64.5314 48.9285 64.3239 49.0898C64.0704 49.2512 53.6763 63.1715 53.538 63.5402C53.4458 63.7707 51.7173 63.8398 45.4255 63.8168C40.7009 63.8168 37.2208 63.7246 36.8751 63.5863Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_750_9">
          <rect width="118" height="118" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
