import { Box, Grid } from "@chakra-ui/react";
import { AttendanceFilterProps } from ".";
import { InputField } from "components/input-field";

export function CalendarFilter({
  onChange,
  filter,
}: {
  onChange: (value: any, key: keyof AttendanceFilterProps) => void;
  filter: AttendanceFilterProps;
}) {
  return (
    <Grid templateColumns="1fr 1fr" w="100%" gap="7px">
      <Box>
        <InputField
          inputProps={{
            variant: "outline-gray",
            height: "48px",
            type: "date",
            onClick: (e) => e.stopPropagation(),
          }}
          value={filter.dataCriacaoInicial || ""}
          onChange={(e) => {
            onChange(e.target.value, "dataCriacaoInicial");
          }}
        />
      </Box>
      <Box>
        <InputField
          inputProps={{
            variant: "outline-gray",
            height: "48px",
            type: "date",
            onClick: (e) => e.stopPropagation(),
          }}
          value={filter.dataCriacaoFinal || ""}
          onChange={(e) => {
            onChange(e.target.value, "dataCriacaoFinal");
          }}
        />
      </Box>
    </Grid>
  );
}
