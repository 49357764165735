import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from "@chakra-ui/icons";
import {
  Flex,
  IconButton,
  Table,
  TableCellProps,
  TableContainer,
  TableContainerProps,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { SkeletonLoading } from "components/skeleton-loading";
import { useEffect, useState } from "react";

export interface CustomTableColorColumn {
  name: string | JSX.Element;
  render: (row: any) => any;
  isVisible?: boolean;
  headerStyles?: TableCellProps;
  rowStyle?: (row: any) => TableCellProps;
}
export interface CustomTableColorProps {
  color?: string;
  isLoading?: boolean;
  columns: CustomTableColorColumn[];
  rows: any[];
  rowsLength?: number;
  currentPage?: number;
  onPrevPage?: () => void;
  onNextPage?: () => void;
  onFirstPage?: () => void;
  onClickRow?: (row: any) => void;
  loadingRowsCount?: number;
  containerProps?: TableContainerProps;
  headerStyle?: TableCellProps;
  pageSize?: number;
  showControls?: boolean;
}

export function CustomTableColor({
  isLoading,
  color = "#000",
  columns,
  rows = [],
  rowsLength,
  currentPage = 0,
  loadingRowsCount = 10,
  onPrevPage,
  onNextPage,
  onFirstPage,
  onClickRow,
  containerProps,
  headerStyle,
  pageSize = 10,
  showControls = true,
}: CustomTableColorProps) {
  const [index, setIndex] = useState(currentPage);
  const filteredColumns = columns.filter(
    (col) => col.isVisible == null || col.isVisible
  );

  const withOutExternalControls = !onFirstPage || !onNextPage || !onPrevPage;

  const prevPage = () => {
    if (!withOutExternalControls) onPrevPage();
    else setIndex((i) => i - 1);
  };

  const nextPage = () => {
    if (!withOutExternalControls) onNextPage();
    else setIndex((i) => i + 1);
  };

  const firstPage = () => {
    if (!withOutExternalControls) onFirstPage();
    else setIndex((i) => 0);
  };

  const paginedRows = paginateRows(rows, pageSize);

  const selectedPage = withOutExternalControls
    ? paginedRows[index] ?? []
    : rows;

  const { pageIndexesArr } = getPageIndexes(
    paginedRows,
    withOutExternalControls,
    index
  );

  useEffect(() => {
    setIndex(currentPage);
  }, [currentPage]);

  const lastPage = () => {
    if (withOutExternalControls) setIndex((i) => paginedRows.length - 1);
  };

  useEffect(() => {
    if (withOutExternalControls) setIndex(0);
  }, [rows]);

  return (
    <TableContainer
      bg="#fff"
      w="100%"
      borderRadius="5px"
      borderLeft="1px solid #EAEAEA"
      borderRight="1px solid #EAEAEA"
      borderBottom="1px solid #EAEAEA"
      borderTop={`4px solid ${color}`}
      {...containerProps}
    >
      <Table overflow="visible">
        <Thead color="#656F7D" fontSize="14px" fontWeight="medium">
          <Tr>
            {filteredColumns.map((column, index) => {
              return (
                <Td
                  key={`column-${index}`}
                  border="none"
                  {...headerStyle}
                  {...column.headerStyles}
                >
                  {column.name}
                </Td>
              );
            })}
          </Tr>
        </Thead>
        <Tbody>
          {isLoading
            ? Array.from({ length: loadingRowsCount }).map((_, index) => {
                return (
                  <Tr key={index}>
                    {filteredColumns.map((_, index) => {
                      return (
                        <Td key={index} p="0px">
                          <SkeletonLoading
                            key={index}
                            isLoading
                            h="59px"
                            w="100%"
                          />
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })
            : selectedPage.map((row, index) => {
                const isLast = rows.length === index + 1;
                return (
                  <Tr
                    key={row.id || `row-${index}`}
                    bg="#fff"
                    onClick={() => onClickRow?.(row)}
                    _hover={{ bg: "rgba(45, 61, 133, 0.03)" }}
                  >
                    {filteredColumns.map((cell, index) => {
                      return (
                        <Td
                          key={`${row.id}-${index}`}
                          borderBottom={isLast ? "none" : "1px solid #EAEAEA"}
                          {...cell.rowStyle?.(row)}
                        >
                          {cell.render(row)}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
        </Tbody>
      </Table>
      {showControls ? (
        <Flex justifyContent="flex-end" alignItems="center" px="24px" py="16px">
          <IconButton
            variant="ghost"
            aria-label="proxima pagina"
            icon={<ArrowLeftIcon w="9px" h="9px" />}
            onClick={() => {
              firstPage();
            }}
            isDisabled={index === 0 || isLoading}
          />
          <IconButton
            variant="ghost"
            aria-label="proxima pagina"
            icon={<ChevronLeftIcon />}
            onClick={() => prevPage()}
            isDisabled={index === 0 || isLoading}
          />

          {pageIndexesArr.map((pageNumber) => {
            const active = index === pageNumber;
            return (
              <Text
                mx="8px"
                p="4px"
                borderRadius="5px"
                bg={active ? "gray.100" : ""}
                onClick={() => {
                  if (withOutExternalControls) setIndex(pageNumber);
                }}
                cursor={"pointer"}
              >
                {pageNumber + 1}
              </Text>
            );
          })}

          <IconButton
            variant="ghost"
            aria-label="proxima pagina"
            icon={<ChevronRightIcon />}
            onClick={() => nextPage()}
            isDisabled={
              (!onNextPage ? !paginedRows[index + 1] : false) || isLoading
            }
          />
          <IconButton
            variant="ghost"
            aria-label="última pagina"
            icon={<ArrowRightIcon w="9px" h="9px" />}
            onClick={() => {
              lastPage();
            }}
            isDisabled={!paginedRows[index + 1] || isLoading}
          />
        </Flex>
      ) : null}
    </TableContainer>
  );
}

const paginateRows = (rows: any[], pageSize: number) => {
  let pageAcumulator: any[] = [];
  const paginedRows: any[][] = rows.reduce((acc, curr, index) => {
    const lastRow = index + 1 === rows.length;
    // Append row in page acc
    pageAcumulator.push(curr);
    // If page acc length equals page size catch de page into paginedRows and reset the page acc
    if (pageAcumulator.length === pageSize) {
      acc.push(pageAcumulator);
      pageAcumulator = [];
    }
    // Catch the last page if smaller of pageSize
    if (lastRow && pageAcumulator.length) {
      acc.push(pageAcumulator);
    }
    return acc;
  }, [] as any[][]);
  return paginedRows;
};

function getPageIndexes(
  paginedRows: any[][],
  withOutExternalControls: boolean,
  currentIndex: number
) {
  const numberOfPages = paginedRows.length;
  let pageIndexesArr =
    numberOfPages > 1 ? paginedRows.map((row, i) => i) : [currentIndex];

  if (numberOfPages > 1) {
    if (currentIndex - 2 >= 0 && numberOfPages > 5) {
      const cut: number[] = pageIndexesArr.slice(
        currentIndex - 2,
        currentIndex + 3
      );
      if (cut.length < 5)
        pageIndexesArr = pageIndexesArr.slice(numberOfPages - 5, numberOfPages);
      else pageIndexesArr = cut;
    } else pageIndexesArr = pageIndexesArr.slice(0, 5);
  }
  console.log(pageIndexesArr);
  return { pageIndexesArr };
}
