import { RepeatIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import { useAttendanceKanbanContext } from "../kanban/kanban-context";
import { useState } from "react";

let lastSearchValue = "";

export function InputSearchNbCpf() {
  let [searchValue, setSearchValue] = useState(lastSearchValue);
  const {
    setAttendanceFilter,
    attendanceFilter,
    getAllColumnAttendances,
    isLoadingAttendances,
  } = useAttendanceKanbanContext();

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    e.currentTarget.value = value;
    setSearchValue(value);
    searchValue = value;
    lastSearchValue = value;
    if (!value.length && attendanceFilter.cpfNbFone) onSearch();
  };

  const onSearch = () => {
    if (searchValue) {
      const newValue = {
        ...attendanceFilter,
        cpfNbFone: searchValue.replace(/\D/g, ""),
      };
      setAttendanceFilter(newValue);
      getAllColumnAttendances({ filter: newValue });
    } else {
      const newValue = { ...attendanceFilter };
      delete newValue["cpfNbFone"];
      setAttendanceFilter(newValue);
      getAllColumnAttendances({ filter: newValue });
    }
  };

  return (
    <Flex alignItems="center">
      <InputGroup w="320px">
        <InputLeftElement w="36px">
          <Search2Icon color="#343B44" width="12px" height="12px" />
        </InputLeftElement>
        <Input
          p="0 10px 0 36px"
          placeholder="Pesquisar número ben. ou CPF"
          w="320px"
          onChange={onChange}
          value={searchValue}
          onKeyDown={(e) => {
            if (
              e.key === "Enter" &&
              e.currentTarget.value &&
              !isLoadingAttendances
            ) {
              onSearch();
            }
          }}
        />
      </InputGroup>
      <Button
        leftIcon={<Search2Icon />}
        ml="10px"
        size="sm"
        variant="outline"
        onClick={onSearch}
        isLoading={isLoadingAttendances}
        loadingText="Pesquisando"
        isDisabled={searchValue === ""}
      >
        Pesquisar
      </Button>
      <Button
        leftIcon={<RepeatIcon />}
        ml="10px"
        size="sm"
        variant="outline"
        onClick={() => getAllColumnAttendances()}
        isLoading={isLoadingAttendances}
        loadingText="Atualizando"
      >
        Atualizar
      </Button>
    </Flex>
  );
}
