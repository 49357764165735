import {
  Box,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Spinner,
  Text,
  TextProps,
  useStyleConfig,
} from "@chakra-ui/react";
import { Popover, PopoverProps } from "components/popover";
import {
  ChangeEvent,
  ReactNode,
  forwardRef,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

export interface InputFieldProps {
  title?: string;
  value?: any;
  onChange?: (a: ChangeEvent<HTMLInputElement>) => void;
  isInvalid?: boolean;
  errorMessage?: string;
  inputProps?: InputProps;
  titleProps?: TextProps;
  popoverProps?: Omit<PopoverProps, "children" | "button">;
  messageErrorProps?: TextProps;
  isLoading?: boolean;
  rightIcon?: JSX.Element | ReactNode;
  leftIcon?: JSX.Element | ReactNode;
  closeErrorOnBlur?: boolean;
  isDisabled?: boolean;
  inputGroupProps?: InputGroupProps;
  withDropdown?: boolean;
}

export const InputField = forwardRef<HTMLInputElement, InputFieldProps>(
  (
    {
      title,
      value,
      isInvalid,
      errorMessage,
      onChange,
      titleProps,
      inputProps,
      popoverProps,
      messageErrorProps,
      isLoading,
      leftIcon,
      rightIcon,
      closeErrorOnBlur = true,
      isDisabled,
      inputGroupProps,
      withDropdown,
    },
    ref
  ) => {
    const errorBox = useRef<HTMLDivElement>(null);
    const { onBlur, onFocus, height, ...inputPropsRest } = inputProps || {};
    const styles = useStyleConfig("Input", {
      variant: inputProps?.variant,
    }) as any;
    const variantHeight = styles?.field?.height as string;

    const defaultHeight = height || variantHeight || "32px";
    const iconWidth = defaultHeight;
    const paddingLeft = leftIcon ? defaultHeight : "";
    const paddingRight = rightIcon ? defaultHeight : "";

    const onFocusIn = () => {
      if (errorMessage && errorBox.current) {
        errorBox.current.style.transform = `scale(0.8)`;
        errorBox.current.style.visibility = `hidden`;
        errorBox.current.style.opacity = `0`;
      }
    };
    const onFocusOut = () => {
      if (errorBox.current) {
        errorBox.current.style.transform = ``;
        errorBox.current.style.visibility = ``;
        errorBox.current.style.opacity = ``;
      }
    };

    return (
      <>
        {title ? (
          <Text mb="8px" fontWeight="medium" {...titleProps}>
            {title}
          </Text>
        ) : null}

        <Box pos="relative" role="group">
          <InputGroup height={defaultHeight} {...inputGroupProps}>
            <Input
              ref={ref}
              value={value}
              onChange={onChange}
              isInvalid={isInvalid || !!errorMessage}
              onBlur={(e) => {
                onFocusOut();
                onBlur?.(e);
              }}
              isDisabled={isDisabled}
              onFocus={(e) => {
                onFocusIn();
                onFocus?.(e);
              }}
              height={defaultHeight}
              pl={paddingLeft}
              pr={paddingRight}
              {...inputPropsRest}
            />
            {rightIcon || (isLoading && !leftIcon) ? (
              <InputRightElement w={iconWidth} height={defaultHeight}>
                {isLoading ? <Spinner w="20px" h="20px" /> : rightIcon}
              </InputRightElement>
            ) : null}
            {leftIcon ? (
              <InputLeftElement w={iconWidth} height={defaultHeight}>
                {isLoading ? <Spinner w="20px" h="20px" /> : leftIcon}
              </InputLeftElement>
            ) : null}
          </InputGroup>

          {errorMessage ? (
            <Box
              ref={errorBox}
              bg="secondary.700"
              maxW="80%"
              minW="26px"
              pos="absolute"
              borderRadius="4px"
              p="3px"
              right={0}
              top="25px"
              zIndex="10"
              border="1px solid var(--chakra-colors-secondary-400)"
              transition="0.2s"
              transform="scale(0.8)"
              visibility="hidden"
              opacity={0}
              _groupHover={{
                transform: "scale(1)",
                visibility: "visible",
                opacity: "1",
              }}
            >
              <Box
                w="10px"
                h="10px"
                zIndex="-1"
                transform="rotate(45deg)"
                top="-6px"
                bg="secondary.700"
                right="10px"
                pos="absolute"
                borderTop="1px solid var(--chakra-colors-secondary-400)"
                borderLeft="1px solid var(--chakra-colors-secondary-400)"
                borderRadius="0 0 10px 0"
              />
              <Text
                color="#fff"
                fontWeight="bold"
                fontSize="9"
                whiteSpace="pre-wrap"
                {...messageErrorProps}
              >
                {errorMessage}&nbsp;
              </Text>
            </Box>
          ) : null}
        </Box>
      </>
    );
  }
);
