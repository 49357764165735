export function DolarBag() {
  return (
    <svg
      width="15"
      height="17"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.94831 10.4149H7.09072C6.77887 10.4149 6.52627 10.1623 6.52627 9.85048C6.52627 9.53863 6.77887 9.28603 7.09072 9.28603H8.60008C8.8059 9.28603 8.9743 9.11763 8.9743 8.91181C8.9743 8.70599 8.8059 8.53759 8.60008 8.53759H7.89218V8.0168C7.89218 7.81098 7.72378 7.64258 7.51796 7.64258C7.31214 7.64258 7.14374 7.81098 7.14374 8.0168V8.53759H7.09072C6.36723 8.53759 5.77783 9.12699 5.77783 9.85048C5.77783 10.574 6.36723 11.1634 7.09072 11.1634H7.94831C8.26016 11.1634 8.51276 11.416 8.51276 11.7278C8.51276 12.0397 8.26016 12.2923 7.94831 12.2923H6.41089C6.20507 12.2923 6.03667 12.4607 6.03667 12.6665C6.03667 12.8723 6.20507 13.0407 6.41089 13.0407H7.14685V13.574C7.14685 13.7798 7.31525 13.9482 7.52108 13.9482C7.7269 13.9482 7.8953 13.7798 7.8953 13.574V13.0407H7.95767C7.96078 13.0407 7.9639 13.0407 7.96702 13.0407C8.68428 13.0314 9.2612 12.4451 9.2612 11.7278C9.2612 11.0043 8.6718 10.4149 7.94831 10.4149Z"
        fill="#4E5762"
        stroke="#4E5762"
        strokeWidth="0.3"
      />
      <path
        d="M13.1435 9.56653C12.7505 8.66216 12.2079 7.78586 11.5218 6.9657C10.6923 5.96778 9.86591 5.30042 9.44491 4.99168L11.0353 2.04158C11.1133 1.89501 11.0884 1.71414 10.9667 1.59875C10.5551 1.19647 10.1185 1 9.63202 1C9.18607 1 8.77755 1.1684 8.42204 1.31809C8.14137 1.43347 7.8763 1.54574 7.66112 1.54574C7.59563 1.54574 7.5395 1.53638 7.48337 1.51455C6.74116 1.24324 6.17048 1.07484 5.6185 1.07484C4.91996 1.07484 4.32432 1.35551 3.74116 1.95426C3.61954 2.079 3.60083 2.27547 3.69751 2.42204L5.41268 5.01351C4.98857 5.32848 4.17775 5.9896 3.36383 6.9657C2.68087 7.78586 2.13514 8.66216 1.7422 9.56653C1.24948 10.7048 1 11.8929 1 13.0967C1 14.6965 2.30353 16 3.90333 16H10.9823C12.5821 16 13.8857 14.6965 13.8857 13.0967C13.8857 11.8929 13.6362 10.7048 13.1435 9.56653ZM4.49584 2.26611C4.85759 1.95426 5.20374 1.81705 5.6185 1.81705C6.06133 1.81705 6.56029 1.96674 7.22453 2.2131C7.36175 2.26299 7.50832 2.28794 7.658 2.28794C8.02287 2.28794 8.37214 2.14449 8.70582 2.00416C9.02703 1.87006 9.33264 1.7422 9.6289 1.7422C9.77235 1.7422 9.97505 1.76715 10.2308 1.94802L8.73389 4.72973H6.12682L4.49584 2.26611ZM10.9823 15.2516H3.90333C2.71518 15.2516 1.74844 14.2848 1.74844 13.0967C1.74844 11.0977 2.48129 9.20166 3.92204 7.46154C4.83264 6.36071 5.75884 5.67775 6.0395 5.47817H8.83992C9.1237 5.67464 10.0468 6.36071 10.9574 7.46154C12.4012 9.20166 13.131 11.0946 13.131 13.0967C13.1372 14.2848 12.1705 15.2516 10.9823 15.2516Z"
        fill="#4E5762"
        stroke="#4E5762"
        strokeWidth="0.3"
      />
    </svg>
  );
}
