import { Icon, IconProps } from "@chakra-ui/react";

export function DotsIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="3px"
      height="15px"
      viewBox="0 0 3 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M9.7694e-05 2.18916C9.77098e-05 1.82775 0.127689 1.51761 0.382874 1.25876C0.63806 0.999909 0.943801 0.870483 1.3001 0.870483C1.65639 0.870483 1.96213 0.999909 2.21732 1.25876C2.4725 1.51761 2.6001 1.82775 2.6001 2.18916C2.6001 2.55058 2.4725 2.86072 2.21732 3.11957C1.96213 3.37842 1.65639 3.50785 1.3001 3.50785C1.06417 3.50785 0.847505 3.4468 0.650098 3.3247C0.45269 3.20748 0.293801 3.04875 0.173431 2.84851C0.0578751 2.65314 9.76833e-05 2.43336 9.7694e-05 2.18916Z"
        fill="currentColor"
      />
      <path
        d="M9.74457e-05 7.87048C9.74615e-05 7.50907 0.127689 7.19893 0.382874 6.94008C0.638059 6.68123 0.943801 6.5518 1.3001 6.5518C1.65639 6.5518 1.96213 6.68123 2.21732 6.94008C2.4725 7.19893 2.6001 7.50907 2.6001 7.87048C2.6001 8.2319 2.4725 8.54203 2.21732 8.80089C1.96213 9.05974 1.65639 9.18916 1.3001 9.18916C1.06417 9.18916 0.847505 9.12811 0.650097 9.00601C0.45269 8.8888 0.293801 8.73007 0.17343 8.52982C0.0578748 8.33446 9.7435e-05 8.11468 9.74457e-05 7.87048Z"
        fill="currentColor"
      />
      <path
        d="M9.71974e-05 13.5518C9.72132e-05 13.1904 0.127689 12.8803 0.382874 12.6214C0.638059 12.3625 0.943801 12.2331 1.3001 12.2331C1.65639 12.2331 1.96213 12.3625 2.21732 12.6214C2.4725 12.8803 2.6001 13.1904 2.6001 13.5518C2.6001 13.9132 2.4725 14.2234 2.21732 14.4822C1.96213 14.7411 1.65639 14.8705 1.3001 14.8705C1.06417 14.8705 0.847504 14.8094 0.650097 14.6873C0.45269 14.5701 0.2938 14.4114 0.17343 14.2111C0.0578746 14.0158 9.71867e-05 13.796 9.71974e-05 13.5518Z"
        fill="currentColor"
      />
    </Icon>
  );
}
