import { Box, Button, Center, Flex, Spinner } from "@chakra-ui/react";
import { PersonIcon } from "components/vectors/menu-icons/person-icon";
import { DocumentIcon3 } from "components/vectors/document-icon";
import { ChartIcon } from "components/vectors/chart-icon";
import { Tabs, Tab } from "components/tabs";
import { useEffect, useState } from "react";
import { DadosCadastraisAtendimentoFormPart } from "./parts/dados-cadastrais";
import { ContratosAtendimentoFormPart } from "./parts/contratos";
import { OportunidadesAtendimentoFormPart } from "./parts/oportunidades";
import { MargensAtendimentoFormPart } from "./parts/margens";
import { useAttendanceContext } from "contexts/attendance-context";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { toastDefaultStyle } from "chakra/theme";
import api from "api/api";
import { SimulacoesAtendimentoFormPart } from "./parts/simulacoes/simulacoes";
import { use3cPlusContext } from "contexts/Context3cPlus";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useRouter } from "routes/router-control/use-router";
import { ModalTabulacao3cPlus } from "components/atendimentos-components/modal-tabulacao-3cplus";
import { isColomboUser } from "utils/is-colombo-user";
import { KeyboardIcon } from "components/vectors/keyboard-icon";
import { DigitacaoAtendimentoFormPart } from "./parts/digitacao";
import { ChatIcon } from "components/vectors/chat-icon";
import { ModalVerMsgSMS, openModalVerMsgSMS } from "./modal-ver-msg-sms";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { DigitadosAtendimentoPart } from "./parts/digitacao/digitados-part";
import { SimulacoesColomboAtendimentoFormPart } from "./parts/simulacoes/simulacoes-colombo";
import { Toast } from "components/toast";
import { ModalVerMsgUra, openModalVerMsgUra } from "./modal-ver-msg-ura";

export const attTabsHeight = "57px";
const marginTop = 16;

export type AttendanceTabKey =
  | "dados-cadastrais"
  | "dados-bancarios"
  | "margens"
  | "contratos"
  | "oportunidades"
  | "simulacao-fgts"
  | "simulacao-fgts-colombo"
  | "digitacao"
  | "digitados";

export const PaginedForm = ({
  setIsDisabledBackButton,
}: {
  setIsDisabledBackButton: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [activeTab, setActiveTab] =
    useState<AttendanceTabKey>("dados-cadastrais");
  const { formControl, dispatch, setTransparentLoading, transparentLoading } =
    useAttendanceContext();
  const { controls3cPlus, dispatch3cPlus } = use3cPlusContext();
  const formValues = formControl.values;
  const { user, setMenuErrorMessage } = useApplicationContext();

  const router = useRouter();
  const customerId = user.userData.customerId;
  const userName = user.userData.name;
  const isVisibleNovaSimulacaoAndDigitacao = [
    1, 8525, 6420, 5944, 1651, 4502, 8328, 5120, 8116, 8220, 8551, 8574, 1870,
    2399, 4717,
  ].includes(user.userData.customerId!);

  // const colomboVisible = ;

  useEffect(() => {
    if (controls3cPlus.inCall) {
      setMenuErrorMessage({
        message: "É necessário desligar a ligação e efetuar a tabulação.",
      });
    }
  }, [controls3cPlus.inCall]);

  const onChangeTab = (tabKey: AttendanceTabKey, tab: Tab) => {
    setActiveTab(tabKey);
  };

  const updateAtendimento = async () => {
    setTransparentLoading(true);
    const { id } = formControl.values;
    try {
      const response = await api.put(
        `/atendimentos/${id}/kanban`,
        formControl.values
      );
      dispatch({ type: "initAttendance", payload: response.data });
    } catch (error) {
      Toast({
        title: "Erro ao salvar alterações no atendimento!",
        status: "error",
      });
      setTransparentLoading(false);
      return;
    } finally {
      setTransparentLoading(false);
    }
  };

  const tabs: Tab<AttendanceTabKey>[] = [
    {
      name: "Dados cadastrais",
      icon: <PersonIcon width="18px" height="18px" />,
      key: "dados-cadastrais",
    },
    {
      name: "Margens",
      icon: <ChartIcon />,
      key: "margens",
      isVisible: () => formValues.convenio !== "ANTECIPACAO_FGTS",
    },
    {
      name: "Contratos",
      icon: <DocumentIcon3 />,
      key: "contratos",
      isVisible: () => formValues.convenio !== "ANTECIPACAO_FGTS",
    },
    {
      name: "Oportunidades",
      icon: <ChartIcon />,
      key: "oportunidades",
      isVisible: () => formValues.convenio !== "ANTECIPACAO_FGTS",
    },
    {
      name: "Simulação FGTS",
      icon: <ChartIcon />,
      key: "simulacao-fgts-colombo",
      isVisible: () =>
        ["ANTECIPACAO_FGTS"].includes(formValues.convenio!) &&
        isColomboUser(user),
    },
    {
      name: "Simulação FGTS",
      icon: <ChartIcon />,
      key: "simulacao-fgts",
      isVisible: () =>
        ["ANTECIPACAO_FGTS"].includes(formValues.convenio!) &&
        !isColomboUser(user),
    },
    {
      name: "Digitação",
      icon: <KeyboardIcon />,
      key: "digitacao",
      isVisible: () => isColomboUser(user),
    },
    {
      name: "Digitados",
      icon: <KeyboardIcon />,
      key: "digitados",
      isVisible: () =>
        formValues.convenio === "INSS"
          ? user.userData.customerId === 1
          : ["ANTECIPACAO_FGTS"].includes(formValues.convenio!) &&
            !isColomboUser(user),
    },
  ];

  return (
    <Box w="100%">
      {transparentLoading ? (
        <Center
          inset="0"
          pos="absolute"
          zIndex="1"
          borderRadius="5px 0px 0px 5px"
          backdropFilter="blur(8px)"
          children={<Spinner />}
        />
      ) : null}
      <Tabs
        tabs={tabs}
        activeTab={activeTab}
        onChangeTab={onChangeTab}
        styles={{
          mt: `${marginTop}px`,
          h: attTabsHeight,
          maxW: `calc(100vw - 30px - 30px - 100px - 400px)`,
          overflow: "auto",
        }}
      />
      <Flex
        flexDir="column"
        p="25px"
        w="100%"
        minH={`calc(100% - ${marginTop}px - ${attTabsHeight})`}
      >
        {activeTab === "dados-cadastrais" ? (
          <DadosCadastraisAtendimentoFormPart
            isLoading={transparentLoading}
            setIsLoading={setTransparentLoading}
          />
        ) : activeTab === "margens" ? (
          <MargensAtendimentoFormPart
            isLoading={transparentLoading}
            setIsLoading={setTransparentLoading}
          />
        ) : activeTab === "contratos" ? (
          <ContratosAtendimentoFormPart
            isLoading={transparentLoading}
            setIsLoading={setTransparentLoading}
            activeTab={activeTab}
          />
        ) : activeTab === "oportunidades" ? (
          <OportunidadesAtendimentoFormPart
            isLoading={transparentLoading}
            setIsLoading={setTransparentLoading}
            activeTab={activeTab}
          />
        ) : activeTab === "simulacao-fgts" ? (
          <SimulacoesAtendimentoFormPart />
        ) : activeTab === "simulacao-fgts-colombo" ? (
          <SimulacoesColomboAtendimentoFormPart />
        ) : activeTab === "digitacao" ? (
          <DigitacaoAtendimentoFormPart
            isLoading={transparentLoading}
            setIsLoading={setTransparentLoading}
          />
        ) : activeTab === "digitados" ? (
          <DigitadosAtendimentoPart
            isLoading={transparentLoading}
            setIsLoading={setTransparentLoading}
          />
        ) : null}

        <Flex w="100%" mt="auto" pt="20px" justifyContent="space-between">
          <Flex alignItems="center" gap="10px">
            <Button
              size="sm"
              onClick={() => {
                openModalVerMsgSMS(formValues as Attendance);
              }}
              leftIcon={<ChatIcon color="white" />}
            >
              Ver Respostas SMS
            </Button>
            {user.userData.customerId === 8273 ? (
              <Button
                size="sm"
                onClick={() => {
                  openModalVerMsgUra(formValues as Attendance);
                }}
                leftIcon={<ChatIcon color="white" />}
              >
                Ver Respostas URA
              </Button>
            ) : null}
          </Flex>
          <Flex alignItems="center">
            {formControl.pendingChanges ? (
              <Button
                leftIcon={<CheckIcon />}
                onClick={updateAtendimento}
                isLoading={transparentLoading}
                loadingText="Salvando"
                size="sm"
              >
                Salvar alterações
              </Button>
            ) : null}
            {formControl.pendingChanges ? (
              <Button
                ml="8px"
                leftIcon={<CloseIcon w="12px" h="12px" />}
                onClick={() => dispatch({ type: "discardChanges" })}
                disabled={transparentLoading}
                variant="outline"
                size="sm"
              >
                Descartar alterações
              </Button>
            ) : null}
          </Flex>
        </Flex>
      </Flex>
      <ModalVerMsgSMS />
      <ModalVerMsgUra />
      <ModalTabulacao3cPlus setIsDisabledBackButton={setIsDisabledBackButton} />
    </Box>
  );
};
