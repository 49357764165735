import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  Grid,
  Text,
} from "@chakra-ui/react";
import {
  dropdownConvenioAtendimento,
  dropdownTabulacao,
} from "components/atendimentos-components/atendimento-form/fields-data";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import {
  columnsColors,
  convenioColors,
  etapaDropdownArray,
  origemOptions,
} from "components/atendimentos-components/kanban-and-table-atendimento/kanban/parts/utils";
import AttendanceDropdown from "components/performance/attendance-dropdown";
import { Calendar2Icon } from "components/vectors/calendar-icon";
import { PersonIcon } from "components/vectors/menu-icons/person-icon";
import { useAttendanceContext } from "contexts/attendance-context";
import { useCallback, useState } from "react";
import { calcBirthday, maskCPF } from "utils/string-formats";
import {
  fazerConsultaIn100,
  fazerConsultaOffline,
  gerarExtrato,
} from "./variables";
import { isColomboUser } from "utils/is-colombo-user";
import { useApplicationContext } from "contexts/ApplicationContext";
import {
  LastStatementModal,
  openLastStatementModal,
} from "./last-statement-modal";
import { CheckIcon } from "@chakra-ui/icons";
import { DocumentIcon } from "components/vectors/document-icon";
import { colorsMap } from "../attendance-field/attendance-input";
import { CheckIcon2 } from "components/vectors/checked-box-icon";
import api from "api/api";
import { useRouter } from "routes/router-control/use-router";
import { openModalConfirm } from "components/modal-confirm-new";
import { ModalConsultaOfflineSelectBenefit } from "./modal-consulta-offline-select-benefit";
import { Dropdown } from "components/dropdown";
import { Toast } from "components/toast";
import { ModalConsultaCPF, openModalConsultaCPF } from "./modal-consulta-cpf";
import { fieldValidation } from "utils/field-validation";

export const HeaderDataForm = ({
  isLoading,
  containerProps,
}: {
  isLoading: boolean;
  containerProps?: BoxProps;
}) => {
  const [loadingResultado, setLoadingResultado] = useState(false);
  const [loadingConsultaCPF, setLoadingConsultaCPF] = useState(false);
  const [loadingStatus, setIsLoadingStatus] = useState("");
  const { user } = useApplicationContext();
  const { formControl, dispatch, setTransparentLoading, transparentLoading } =
    useAttendanceContext();
  const formValues = formControl.values;
  const isTipoOperacao =
    formValues.convenio === "SIAPE" || formValues.convenio === "INSS";
  const isLoadingConsultaOffline = loadingStatus === "consulta-offline";
  const isLoadingConsultaIn100 = loadingStatus === "consulta-in100";
  const isLoadingGerandoExtrato = loadingStatus === "gerando-extrato";

  const router = useRouter();
  const margens = formValues.margens;
  const resultado = formValues.resultado;
  const parseTagText = new Map([
    ["CONSULTA OFFLINE", ""],
    ["EXTRATO_ONLINE", "EXTR. ONLINE"],
    ["IN100", "IN100"],
  ]);

  const getOriginTag = (fieldName: string) => {
    const jsonSerialized = JSON.parse(
      formControl.values.sourceFieldsJson || "{}"
    );
    let tagText = jsonSerialized[fieldName]?.updatedBy;
    if (tagText === "CONSULTA OFFLINE") tagText = "";
    const color = colorsMap.get(tagText);
    return { tagText: parseTagText.get(tagText), color, isVisible: !!tagText };
  };

  const onChangeField = useCallback(
    (fieldName: keyof Attendance, value: string | boolean | null) => {
      dispatch({
        type: "changeField",
        payload: { fieldName, data: value },
      });
      dispatch({
        type: "setInvalidField",
        payload: { fieldName, action: "clear" },
      });
    },
    []
  );

  const patchResultado = async ({
    newResultado,
    withLoading,
  }: {
    newResultado: Attendance["resultado"];
    withLoading?: boolean;
  }) => {
    if (withLoading) setLoadingResultado(true);
    try {
      const { data } = await api.patch(
        `/atendimentos/${formValues.id}/resultado`,
        { resultado: newResultado }
      );
      dispatch({
        type: "changeFieldWithInitial",
        payload: { fieldName: "resultado", data: newResultado },
      });
      if (newResultado) router.push("/atendimentos");
    } catch (e) {
      Toast({ title: "Erro ao marcar resultado", status: "error" });
    } finally {
      if (withLoading) setLoadingResultado(false);
    }
  };

  const onChangeResultado = async (newResultado: Attendance["resultado"]) => {
    if (formControl.pendingChanges)
      return Toast({
        title: "Há alterações não salvas",
        description: "Salve as alterações ou descarte-as",
      });
    if (newResultado)
      openModalConfirm({
        message: `Confirma atendimento como ${newResultado}?`,
        onConfirm: async () => {
          await patchResultado({ newResultado });
        },
        confirmButtonStyle: { variant: undefined },
      });
    else await patchResultado({ newResultado, withLoading: true });
  };

  return (
    <Box borderBottom="1px solid #E8EAED" p="25px" {...containerProps}>
      <Flex justifyContent="space-between" mb="35px" flexWrap="wrap" gap="15px">
        <Flex alignItems="center">
          <Center w="52px" h="52px" bg="gray.100" borderRadius="full">
            <PersonIcon />
          </Center>
          <Box color="#656F7D" ml="14px">
            <Flex alignItems="center">
              <Text
                fontSize={{ xl: "18px", "2xl": "20px" }}
                fontWeight="semibold"
                color="#343B44"
              >
                {formControl.values.nome
                  ? formControl.values.nome
                  : "(Sem nome)"}
              </Text>
            </Flex>
            <Flex alignItems="center">
              {formControl.values.cpf ? (
                <Flex alignItems="center" color="#4E5762">
                  <PersonIcon width="13px" height="13px" />
                  <Text ml="5px" fontSize="14px" fontWeight="medium">
                    {maskCPF(formControl.values.cpf)}
                  </Text>
                </Flex>
              ) : null}

              {formControl.values.dataNascimento ? (
                <>
                  <Box w="1px" bg="#656F7D" h="13px" mx="8px" />
                  <Flex alignItems="center" color="#4E5762">
                    <Calendar2Icon width="14px" height="14px" />
                    <Text ml="5px" fontSize="14px" fontWeight="medium">
                      {formValues.dataNascimento}
                    </Text>
                  </Flex>
                </>
              ) : null}
            </Flex>
          </Box>
        </Flex>
        <Flex alignItems="center">
          <Flex mr="5px" borderRadius="5px" overflow="hidden">
            <Button
              borderRadius="5px 0px 0px 5px"
              px="5px"
              fontSize="11px"
              aria-label=""
              variant="outline"
              loadingText="Consultando"
              size="xs"
              bg={resultado === "GANHO" ? "green.700" : undefined}
              color={resultado === "GANHO" ? "white" : undefined}
              isDisabled={loadingResultado}
              _hover={resultado === "GANHO" ? { bg: "green.600" } : undefined}
              _active={resultado === "GANHO" ? { bg: "green.700" } : undefined}
              transform="translateX(1px)"
              onClick={() =>
                onChangeResultado(resultado === "GANHO" ? null : "GANHO")
              }
              leftIcon={
                <CheckIcon2
                  width="15px"
                  height="15px"
                  isChecked={resultado === "GANHO"}
                />
              }
            >
              Ganho
            </Button>
            <Button
              borderRadius="0px 5px 5px 0px"
              px="5px"
              fontSize="11px"
              aria-label=""
              variant="outline"
              isDisabled={loadingResultado}
              loadingText="Consultando"
              size="xs"
              bg={resultado === "PERDIDO" ? "red.700" : undefined}
              color={resultado === "PERDIDO" ? "white" : undefined}
              _hover={resultado === "PERDIDO" ? { bg: "red.600" } : undefined}
              _active={resultado === "PERDIDO" ? { bg: "red.700" } : undefined}
              onClick={() =>
                onChangeResultado(resultado === "PERDIDO" ? null : "PERDIDO")
              }
              leftIcon={
                <CheckIcon2
                  width="15px"
                  height="15px"
                  isChecked={resultado === "PERDIDO"}
                />
              }
            >
              Perdido
            </Button>
          </Flex>
          <Grid templateColumns="1fr 1fr" gap="5px">
            {["GOV", "PREF", "INSS", "SIAPE"].includes(formValues.convenio!) ? (
              <Button
                aria-label=""
                px="5px"
                fontSize="11px"
                variant="outline"
                leftIcon={<DocumentIcon width="15px" height="15px" />}
                loadingText="Consultando"
                isLoading={isLoadingConsultaOffline}
                isDisabled={isLoadingConsultaIn100 || isLoadingGerandoExtrato}
                onClick={() => {
                  openModalConfirm({
                    message: ({ onClose }) => (
                      <Box w="100%">
                        <Text>
                          Deseja usar o <b>Número do benefício</b> ou <b>CPF</b>
                          ?
                        </Text>
                        <Dropdown
                          w="100%"
                          // mt="10px"
                          onChange={(value) => {
                            if (value === "cpf") {
                              fazerConsultaOffline({
                                dispatch,
                                formControl,
                                setIsLoadingStatus,
                                mode: "cpf",
                              });
                              onClose();
                            } else if (value === "nb") {
                              fazerConsultaOffline({
                                dispatch,
                                formControl,
                                setIsLoadingStatus,
                                mode: "nb",
                              });
                              onClose();
                            }
                          }}
                          options={[
                            { name: "Número benefício", value: "nb" },
                            { name: "CPF", value: "cpf" },
                          ]}
                        />
                      </Box>
                    ),
                    confirmButtonStyle: {
                      children: "CPF",
                      variant: undefined,
                      display: "none",
                    },
                    rejectButtonStyle: {
                      children: "Número benefício",
                      variant: undefined,
                      display: "none",
                    },
                  });
                }}
                size="xs"
              >
                Consulta Offline
              </Button>
            ) : null}
            {(!isColomboUser(user) || user.userData?.verIn100) &&
            formValues.convenio === "INSS" ? (
              <Button
                aria-label=""
                px="5px"
                fontSize="11px"
                // ml="10px"
                variant="outline"
                leftIcon={<DocumentIcon width="15px" height="15px" />}
                isLoading={isLoadingConsultaIn100}
                isDisabled={isLoadingConsultaOffline || isLoadingGerandoExtrato}
                loadingText="Consultando"
                onClick={() => {
                  fazerConsultaIn100({
                    dispatch,
                    formControl,
                    setIsLoadingStatus,
                  });
                }}
                size="xs"
              >
                Consulta IN100
              </Button>
            ) : null}

            <Button
              leftIcon={<DocumentIcon width="15px" height="15px" />}
              variant="outline"
              px="5px"
              fontSize="11px"
              onClick={() => {
                if (fieldValidation({ value: formValues.cpf }).cpf().isValid) {
                  openModalConsultaCPF({ setLoadingConsultaCPF });
                } else {
                  Toast({ title: "Insira um CPF" });
                  dispatch({
                    type: "setInvalidField",
                    payload: { fieldName: "cpf", action: "append" },
                  });
                }
              }}
              disabled={isLoading}
              isLoading={loadingConsultaCPF}
              loadingText="Consultando"
              size="xs"
            >
              Consulta CPF
            </Button>

            {!isColomboUser(user) && formValues.convenio === "INSS" ? (
              <Button
                leftIcon={<DocumentIcon width="15px" height="15px" />}
                px="5px"
                fontSize="11px"
                onClick={() =>
                  gerarExtrato({
                    formValues: formValues as Attendance,
                    setIsLoadingStatus,
                    dispatch,
                  })
                }
                isLoading={isLoadingGerandoExtrato}
                loadingText="Gerando"
                disabled={isLoading}
                // ml="10px"
                size="xs"
              >
                Novo extrato
              </Button>
            ) : null}
            {!isColomboUser(user) && formValues.extratoOnline ? (
              <Button
                px="5px"
                fontSize="11px"
                leftIcon={<CheckIcon w="12px" h="12px" />}
                onClick={openLastStatementModal}
                disabled={isLoading}
                // ml="10px"
                size="xs"
              >
                Ver último extrato
              </Button>
            ) : null}
          </Grid>
        </Flex>
      </Flex>
      <ModalConsultaCPF />
      <Grid templateColumns="1fr 1fr 1fr 1fr 1fr 1fr" mb="10px" gap="15px">
        <Center flexDir="column" p="12px" bg="#F5F7F9">
          {getOriginTag("dataNascimento").isVisible ? (
            <Center
              p="2px 5px"
              fontSize="11"
              borderRadius="5px"
              bg={getOriginTag("dataNascimento").color}
              color="#fff"
              mt="-4px"
              mb="3px"
              mx="auto"
            >
              {getOriginTag("dataNascimento").tagText}
            </Center>
          ) : null}
          <Text
            fontWeight="medium"
            textAlign="center"
            fontSize={{ base: "13px", "2xl": "14px" }}
            color="#4E5762"
          >
            IDADE
          </Text>
          <Text fontSize={{ base: "15px", "2xl": "17px" }} fontWeight="medium">
            {formValues.dataNascimento
              ? calcBirthday(formValues.dataNascimento)
              : "--"}
          </Text>
        </Center>

        <Center flexDir="column" p="12px" bg="#F5F7F9" borderRadius="5px">
          {formValues.convenio !== "ANTECIPACAO_FGTS" ? (
            <>
              {getOriginTag("especieNB").isVisible ? (
                <Center
                  p="2px 5px"
                  fontSize="11"
                  borderRadius="5px"
                  bg={getOriginTag("especieNB").color}
                  color="#fff"
                  mt="-4px"
                  mb="3px"
                  mx="auto"
                >
                  {getOriginTag("especieNB").tagText}
                </Center>
              ) : null}
              <Text
                fontWeight="medium"
                textAlign="center"
                fontSize={{ base: "13px", "2xl": "14px" }}
                color="#4E5762"
              >
                ESPÉCIE
              </Text>
              <Text
                fontSize={{ base: "15px", "2xl": "17px" }}
                fontWeight="medium"
              >
                {formValues.especieNB != null ? formValues.especieNB : "--"}
              </Text>
            </>
          ) : (
            <>
              {getOriginTag("cidade").isVisible ? (
                <Center
                  p="2px 5px"
                  fontSize="11"
                  borderRadius="5px"
                  bg={getOriginTag("especieNB").color}
                  color="#fff"
                  mt="-4px"
                  mb="3px"
                  mx="auto"
                >
                  {getOriginTag("especieNB").tagText}
                </Center>
              ) : null}
              <Text
                fontWeight="medium"
                textAlign="center"
                fontSize={{ base: "13px", "2xl": "14px" }}
                color="#4E5762"
              >
                UF
              </Text>
              <Text
                fontSize={{ base: "15px", "2xl": "17px" }}
                fontWeight="medium"
              >
                {formValues.uf !== undefined && formValues.uf !== null
                  ? formValues.uf
                  : "--"}
              </Text>
            </>
          )}
        </Center>

        <Center flexDir="column" p="12px" bg="#F5F7F9" borderRadius="5px">
          {formValues.convenio !== "ANTECIPACAO_FGTS" ? (
            <>
              {getOriginTag("valorSalario").isVisible ? (
                <Center
                  p="2px 5px"
                  fontSize="11"
                  borderRadius="5px"
                  bg={getOriginTag("valorSalario").color}
                  color="#fff"
                  mt="-4px"
                  mb="3px"
                  mx="auto"
                >
                  {getOriginTag("valorSalario").tagText}
                </Center>
              ) : null}
              <Text
                fontWeight="medium"
                textAlign="center"
                fontSize={{ base: "13px", "2xl": "14px" }}
                color="#4E5762"
              >
                VALOR BENEFÍCIO
              </Text>
              <Text
                fontSize={{ base: "15px", "2xl": "17px" }}
                fontWeight="medium"
              >
                R${" "}
                {formValues.valorSalario != null
                  ? formValues.valorSalario
                  : "--"}
              </Text>
            </>
          ) : // <>
          //   {getOriginTag("cidade").isVisible ? (
          //     <Center
          //       p="2px 5px"
          //       fontSize="11"
          //       borderRadius="5px"
          //       bg={getOriginTag("especieNB").color}
          //       color="#fff"
          //       mt="-4px"
          //       mb="3px"
          //       mx="auto"
          //     >
          //       {getOriginTag("especieNB").tagText}
          //     </Center>
          //   ) : null}
          //   <Text
          //     fontWeight="medium"
          //     textAlign="center"
          //     fontSize={{ base: "13px", "2xl": "14px" }}
          //     color="#4E5762"
          //   >
          //     SALDO
          //   </Text>
          //   <Text
          //     fontSize={{ base: "15px", "2xl": "17px" }}
          //     fontWeight="medium"
          //   >
          //     {/* {formValues. != null ? formValues.cidade : "--"} */}
          //   </Text>
          // </>
          null}
        </Center>

        <Center flexDir="column" p="12px" bg="#F5F7F9" borderRadius="5px">
          {formValues.convenio !== "ANTECIPACAO_FGTS" ? (
            <>
              {getOriginTag("margens.valorMargemLivreNovo").isVisible ? (
                <Center
                  p="2px 5px"
                  fontSize="11"
                  borderRadius="5px"
                  bg={getOriginTag("margens.valorMargemLivreNovo").color}
                  color="#fff"
                  mt="-4px"
                  mb="3px"
                  mx="auto"
                >
                  {getOriginTag("margens.valorMargemLivreNovo").tagText}
                </Center>
              ) : null}
              <Text
                fontWeight="medium"
                textAlign="center"
                fontSize={{ base: "13px", "2xl": "14px" }}
                color="#4E5762"
              >
                MARGEM{" "}
                {Number(formValues.especieNB) === 88 ||
                Number(formValues.especieNB) === 87
                  ? 30
                  : 35}
              </Text>
              <Text
                fontSize={{ base: "15px", "2xl": "17px" }}
                fontWeight="medium"
              >
                R${" "}
                {margens?.valorMargemLivreNovo != null
                  ? margens?.valorMargemLivreNovo
                  : "--"}
              </Text>
            </>
          ) : null}
        </Center>

        <Center flexDir="column" p="12px" bg="#F5F7F9" borderRadius="5px">
          {formValues.convenio !== "ANTECIPACAO_FGTS" ? (
            <>
              {getOriginTag("margens.valorMargemCartaoRMC").isVisible ? (
                <Center
                  p="2px 5px"
                  fontSize="11"
                  borderRadius="5px"
                  bg={getOriginTag("margens.valorMargemCartaoRMC").color}
                  color="#fff"
                  mt="-4px"
                  mb="3px"
                  mx="auto"
                >
                  {getOriginTag("margens.valorMargemCartaoRMC").tagText}
                </Center>
              ) : null}
              <Text
                fontWeight="medium"
                textAlign="center"
                fontSize={{ base: "13px", "2xl": "14px" }}
                color="#4E5762"
              >
                MARGEM RMC
              </Text>
              <Text
                fontSize={{ base: "15px", "2xl": "17px" }}
                fontWeight="medium"
              >
                R${" "}
                {margens?.valorMargemCartaoRMC
                  ? margens?.valorMargemCartaoRMC
                  : "--"}
              </Text>
            </>
          ) : null}
        </Center>

        <Center flexDir="column" p="12px" bg="#F5F7F9" borderRadius="5px">
          {formValues.convenio !== "ANTECIPACAO_FGTS" ? (
            <>
              {getOriginTag("margens.valorMargemCartaoRCC").isVisible ? (
                <Center
                  p="2px 5px"
                  fontSize="11"
                  borderRadius="5px"
                  bg={getOriginTag("margens.valorMargemCartaoRCC").color}
                  color="#fff"
                  mt="-4px"
                  mb="3px"
                  mx="auto"
                >
                  {getOriginTag("margens.valorMargemCartaoRCC").tagText}
                </Center>
              ) : null}
              <Text
                fontWeight="medium"
                textAlign="center"
                fontSize={{ base: "13px", "2xl": "14px" }}
                color="#4E5762"
              >
                MARGEM RCC
              </Text>
              <Text
                fontSize={{ base: "15px", "2xl": "17px" }}
                fontWeight="medium"
              >
                R${" "}
                {margens?.valorMargemCartaoRCC != null
                  ? margens?.valorMargemCartaoRCC
                  : "--"}
              </Text>
            </>
          ) : null}
        </Center>
      </Grid>
      <Grid w="100%" templateColumns="1fr 1fr 1fr 1fr" gap="20px">
        <Box>
          <Text fontWeight="medium" mb="20px" color="#656F7D">
            CONVÊNIO
          </Text>
          <AttendanceDropdown
            fieldName="convenio"
            onChangeField={onChangeField}
            value={formValues.convenio || ""}
            w="100%"
            variant="smallDropdown"
            bg={convenioColors.get(formValues.convenio!) ?? undefined}
            options={dropdownConvenioAtendimento}
            _hover={{ opacity: 0.8 }}
            border="none"
          />
        </Box>
        <Box>
          <Text fontWeight="medium" mb="20px" color="#656F7D">
            ETAPA
          </Text>
          <AttendanceDropdown
            fieldName="etapa"
            onChangeField={onChangeField}
            w="100%"
            variant="smallDropdown"
            border="none"
            value={formValues.etapa}
            options={etapaDropdownArray}
            {...columnsColors.get(formValues.etapa!)}
          />
        </Box>
        <Box>
          <Text fontWeight="medium" mb="20px" color="#656F7D">
            TABULAÇÃO
          </Text>
          <AttendanceDropdown
            fieldName="tabulacao"
            onChangeField={onChangeField}
            options={dropdownTabulacao}
            value={formValues.tabulacao || ""}
            w="100%"
            variant="smallDropdown"
            border="1px solid #E8EAED"
          />
        </Box>
        <Box>
          <Text fontWeight="medium" mb="20px" color="#656F7D">
            ORIGEM
          </Text>
          <AttendanceDropdown
            fieldName="origem"
            options={origemOptions}
            onChangeField={onChangeField}
            value={formValues.origem || ""}
            w="100%"
            variant="smallDropdown"
            border="1px solid #E8EAED"
          />
        </Box>
      </Grid>
      <ModalConsultaOfflineSelectBenefit />
      <LastStatementModal />
    </Box>
  );
};
