import { Box, Center, Flex } from "@chakra-ui/react";
import { KanbanIcon } from "components/vectors/kanban-icon";
import { TableIcon } from "components/vectors/table-icon";
import { useAttendanceKanbanContext } from "../kanban-and-table-atendimento/kanban/kanban-context";
import { VisualizationMode } from "../kanban-and-table-atendimento/kanban/parts/types";
import { useApplicationContext } from "contexts/ApplicationContext";
import { use3cPlusContext } from "contexts/Context3cPlus";

export function VisualizationModeSwitch() {
  const { menuErrorMessage } = useApplicationContext();
  const { controls3cPlus } = use3cPlusContext();
  const {
    setKanbanColumns,
    getAllColumnAttendances: getAttendances,
    setVisualizationMode,
    visualizationMode,
  } = useAttendanceKanbanContext();

  const onChangeKanbanEnabled = (state: VisualizationMode) => {
    setVisualizationMode(state);
    if (state === "tables") {
      setKanbanColumns((columns) =>
        columns.map((column) => {
          column.attendancesList = column.attendancesList.slice(0, 5);
          return column;
        })
      );
    } else {
      getAttendances();
    }
  };

  return (
    <Flex
      borderRadius="5px"
      w="64px"
      h="32px"
      pos="relative"
      zIndex="1"
      border={"1px solid #E8EAED"}
      opacity={controls3cPlus.inCall ? 0.5 : undefined}
      pointerEvents={controls3cPlus.inCall ? "none" : undefined}
    >
      <Box
        w="32px"
        bg="primary.500"
        h="32px"
        pos="absolute"
        left="0px"
        zIndex="-1"
        transform={
          visualizationMode === "kanban" ? "translateX(0)" : "translateX(100%)"
        }
        transition="transform 0.2s"
        borderRadius="5px"
      />
      <Center
        w="32px"
        borderRadius="5px"
        h="32px"
        onClick={() => onChangeKanbanEnabled("kanban")}
        cursor="pointer"
        color={visualizationMode === "kanban" ? "white" : "#343B44"}
        transition="color, border 0.2s"
      >
        <KanbanIcon />
      </Center>
      <Center
        w="32px"
        borderRadius="5px"
        h="32px"
        onClick={() => onChangeKanbanEnabled("tables")}
        cursor="pointer"
        color={visualizationMode === "tables" ? "white" : "#343B44"}
        transition="color 0.2s"
      >
        <TableIcon />
      </Center>
    </Flex>
  );
}
