import {
  Box,
  Center,
  Flex,
  IconButton,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Toast } from "components/toast";
import { AttendanceKandanColumnProps } from "../kanban-and-table-atendimento/kanban/parts/types";
import {
  ArrowLeftIcon,
  CheckIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import {
  convenioColors,
  initialKanbanColumns,
  parseConvenio,
} from "../kanban-and-table-atendimento/kanban/parts/utils";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { maskCPF } from "utils/string-formats";
import { useAttendanceKanbanContext } from "../kanban-and-table-atendimento/kanban/kanban-context";
import { SkeletonLoading } from "components/skeleton-loading";
import { useState } from "react";
import { openModalConfirm } from "components/modal-confirm-new";
import { useRouter } from "routes/router-control/use-router";
import { rowColorStatus } from "components/dynamic-table/row-status";
import { CustomTableColorColumn } from "components/dynamic-table/color-table";
import { attandanceOrigemEnum } from "./utils";

interface DraggableAttendanceTableProps extends AttendanceKandanColumnProps {
  isLast: boolean;
}

export const attendanceTablePageSize = 5;

export function DraggableAttendanceTable({
  colId,
  attendancesList,
  colName,
  title,
  color,
  isLast,
}: DraggableAttendanceTableProps) {
  const {
    isLoadingAttendances,
    loadMoreAttendances,
    setKanbanColumns,
    loadingColumns,
    kanbanColumns,
    handleDrop,
  } = useAttendanceKanbanContext();
  const [pageControl, setPageControl] = useState({ pageNumber: 0 });

  const router = useRouter();

  const listPagePart = attendancesList.slice(
    pageControl.pageNumber * attendanceTablePageSize,
    (pageControl.pageNumber + 1) * attendanceTablePageSize
  );

  const columns: CustomTableColorColumn[] = [
    {
      name: "ATENDENTE",
      render: (row) => row.userName,
      rowStyle: (row) => ({ bg: row.unreadMessage ? "#ddffd8" : "#fff" }),
    },
    {
      name: "NOME",
      render: (row) => (
        <Text opacity={row.nome ? 1 : 0.5}>{row.nome ?? "(sem nome)"}</Text>
      ),
      rowStyle: (row) => ({ bg: row.unreadMessage ? "#ddffd8" : "#fff" }),
    },
    {
      name: "CPF",
      render: (row) => (
        <Text opacity={row.cpf ? 1 : 0.5}>
          {row.cpf ? maskCPF(row.cpf) : "(sem cpf)"}
        </Text>
      ),
      rowStyle: (row) => ({ bg: row.unreadMessage ? "#ddffd8" : "#fff" }),
    },
    {
      name: "DATA CRIAÇÃO",
      headerStyles: { textAlign: "center" },
      render: (row) => (
        <Center textAlign="center">
          {row.dataCriacaoMilliseconds
            ? new Date(row.dataCriacaoMilliseconds).toLocaleDateString()
            : null}
        </Center>
      ),
      rowStyle: (row) => ({ bg: row.unreadMessage ? "#ddffd8" : "#fff" }),
    },
    {
      name: "CONVÊNIO",
      headerStyles: { textAlign: "center" },
      render: (row) => {
        return row.convenio ? (
          <Center
            alignItems="center"
            onChange={(newConvenio) => {}}
            borderRadius="6px"
            fontWeight="medium"
            fontSize="11px"
            h="27px"
            p="3px 8px"
            bg={convenioColors.get(row.convenio!) ?? "#F3EBA4"}
          >
            {parseConvenio(row.convenio)}
          </Center>
        ) : null;
      },
      rowStyle: (row) => ({ bg: row.unreadMessage ? "#ddffd8" : "#fff" }),
    },
    {
      name: "ETAPA",
      headerStyles: { textAlign: "center" },
      render: (row) => {
        return (
          <Center
            alignItems="center"
            borderRadius="6px"
            fontWeight="medium"
            fontSize="11px"
            h="27px"
            p="3px 8px"
            bg={rowColorStatus.get(row.convenio!) ?? "#F3EBA4"}
          >
            {
              initialKanbanColumns.find((col) => col.colId === row.etapa)
                ?.colName
            }
          </Center>
        );
      },
      rowStyle: (row) => ({ bg: row.unreadMessage ? "#ddffd8" : "#fff" }),
    },
    {
      name: "ORIGEM",
      headerStyles: { textAlign: "center" },
      render: (row) => {
        return (
          <Center textAlign="center">
            {attandanceOrigemEnum.get(row.origem)}
          </Center>
        );
      },
      rowStyle: (row) => ({ bg: row.unreadMessage ? "#ddffd8" : "#fff" }),
    },
  ];

  return (
    <Box key={colId} mb={isLast ? undefined : "32px"}>
      <Flex justifyContent="space-between" alignItems="flex-end" mb="16px">
        <Box fontWeight="medium">
          <Text mb="5px" color="#6A6A6A" fontSize="12px">
            {title}
          </Text>
          <Flex justifyContent="space-between" alignItems="center">
            <Flex alignItems="center">
              <Box w="13px" h="13px" borderRadius="full" bg={color} mr="5px" />
              <Text fontSize="17px">{colName}</Text>
            </Flex>
          </Flex>
        </Box>
      </Flex>
      <Droppable
        // isDropDisabled
        mode="standard"
        droppableId={colId}
        children={(provided) => {
          const { droppableProps, placeholder } = provided;
          return (
            <TableContainer
              ref={provided.innerRef}
              {...droppableProps}
              bg="#fff"
              borderRadius="5px"
              borderLeft="1px solid #EAEAEA"
              borderRight="1px solid #EAEAEA"
              borderBottom="1px solid #EAEAEA"
              borderTop={`4px solid ${color}`}
            >
              <Box display="none">{placeholder}</Box>
              <Table overflow="visible">
                <Thead color="#656F7D" fontSize="14px" fontWeight="medium">
                  <Tr>
                    {columns.map((columns, index) => {
                      return (
                        <Td
                          key={`column-${index}`}
                          border="none"
                          {...columns.headerStyles}
                        >
                          {columns.name}
                        </Td>
                      );
                    })}
                  </Tr>
                </Thead>
                <Tbody>
                  {isLoadingAttendances || loadingColumns[colId]
                    ? Array.from({ length: 5 }).map((_, index) => {
                        return (
                          <Tr key={index}>
                            {columns.map((_, index) => {
                              return (
                                <Td key={index} p="0px">
                                  <SkeletonLoading
                                    key={index}
                                    isLoading
                                    h="59px"
                                    w="100%"
                                  />
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })
                    : listPagePart.map((attendance, index) => {
                        const isLast = attendancesList.length === index + 1;
                        const id = attendance.id;
                        return (
                          <Draggable
                            draggableId={`${id}`}
                            key={id}
                            index={index}
                            children={(provided) => {
                              const { dragHandleProps, draggableProps } =
                                provided;
                              return (
                                <Tr
                                  onClick={() => {
                                    if (attendance.etapa === "PENDENTE") {
                                      openModalConfirm({
                                        message:
                                          "Deseja iniciar o atendimento?",
                                        onConfirm: () => {
                                          // openModalAttendance({
                                          //   id,
                                          //   init: true,
                                          // });
                                          router.push(
                                            `/atendimento-cliente-page?id=${id}&init=true`
                                          );
                                        },
                                        confirmButtonStyle: {
                                          variant: undefined,
                                          leftIcon: <CheckIcon />,
                                        },
                                        rejectButtonStyle: {
                                          leftIcon: (
                                            <CloseIcon w="12px" h="12px" />
                                          ),
                                        },
                                      });
                                    } else {
                                      // openModalAttendance({ id });
                                      router.push(
                                        `/atendimento-cliente-page?id=${id}&init=false`
                                      );
                                    }
                                  }}
                                  key={id}
                                  ref={provided.innerRef}
                                  {...dragHandleProps}
                                  {...draggableProps}
                                  bg="#fff"
                                >
                                  {columns.map((cell, index) => {
                                    return (
                                      <Td
                                        key={`${id}-${index}`}
                                        borderBottom={
                                          isLast ? "none" : "1px solid #EAEAEA"
                                        }
                                        {...cell.rowStyle?.(attendance)}
                                      >
                                        {cell.render(attendance)}
                                      </Td>
                                    );
                                  })}
                                </Tr>
                              );
                            }}
                          />
                        );
                      })}
                </Tbody>
              </Table>
              <Flex
                justifyContent="flex-end"
                alignItems="center"
                px="24px"
                py="16px"
              >
                <IconButton
                  variant="ghost"
                  aria-label="proxima pagina"
                  icon={<ArrowLeftIcon w="9px" h="9px" />}
                  onClick={() => {
                    const pageNumber = 0;
                    setKanbanColumns((columns) => {
                      return columns.map((column) => {
                        if (column.colId === colId) {
                          column.attendancesList = column.attendancesList.slice(
                            0,
                            (pageNumber + 1) * attendanceTablePageSize
                          );
                        }
                        return column;
                      });
                    });
                    setPageControl({ ...pageControl, pageNumber });
                  }}
                  isDisabled={
                    pageControl.pageNumber === 0 ||
                    loadingColumns[colId] ||
                    isLoadingAttendances
                  }
                />
                <IconButton
                  variant="ghost"
                  aria-label="proxima pagina"
                  icon={<ChevronLeftIcon />}
                  onClick={() => {
                    const pageNumber = pageControl.pageNumber - 1;
                    setKanbanColumns((columns) => {
                      return columns.map((column) => {
                        if (column.colId === colId) {
                          column.attendancesList = column.attendancesList.slice(
                            0,
                            (pageNumber + 1) * attendanceTablePageSize
                          );
                        }
                        return column;
                      });
                    });
                    setPageControl({ ...pageControl, pageNumber });
                  }}
                  isDisabled={
                    pageControl.pageNumber === 0 ||
                    loadingColumns[colId] ||
                    isLoadingAttendances
                  }
                />
                <Text mx="8px" p="4px" borderRadius="5px">
                  {pageControl.pageNumber + 1}
                </Text>
                <IconButton
                  variant="ghost"
                  aria-label="proxima pagina"
                  icon={<ChevronRightIcon />}
                  onClick={async () => {
                    try {
                      const moreAttendances = await loadMoreAttendances({
                        colId,
                        limit: 5,
                      });
                      if (!moreAttendances.length) {
                        Toast({ title: "Não há mais páginas", status: "info" });
                      } else {
                        const pageNumber = pageControl.pageNumber + 1;
                        setPageControl({ ...pageControl, pageNumber });
                      }
                    } catch (e) {
                      Toast({
                        title: "Erro ao carregar página do atendimento",
                        status: "error",
                      });
                    } finally {
                    }
                  }}
                  isDisabled={
                    loadingColumns[colId] ||
                    isLoadingAttendances ||
                    listPagePart.length < attendanceTablePageSize
                  }
                />
              </Flex>
            </TableContainer>
          );
        }}
      />
    </Box>
  );
}
