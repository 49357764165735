import api from "api/api";
import { Pipeline, PipelineStep } from ".";
import { Toast } from "components/toast";
import { toastDefaultStyle } from "chakra/theme";
import { makeEvent } from "services/events";
import { Dispatch, SetStateAction } from "react";
import { MailingTableRow } from "..";
import { User } from "contexts/types/application-context-types";

export const getPipelineRootErrors = (higienizacoes: Pipeline[]) => {
  return higienizacoes
    .filter(
      (h) =>
        h.higienizacaoStatusResultList === null &&
        h.pipelineErrorDescription !== null
    )
    .map((h) => h.pipelineErrorDescription);
};

export const groupStepData = (
  steps: Pipeline[],
  stage: Pipeline["pipelineStage"]
) => {
  const filteredSteps = steps.filter(
    (pipeline) =>
      (pipeline.higienizacaoStatusResultList !== null ||
        pipeline.simulacaoStatusResult !== null ||
        pipeline.margemConvenioStatusResultList !== null) &&
      pipeline.pipelineStage === stage
  );

  const groupedData = filteredSteps.reduce(
    (acc, currentHigienizacao) => {
      // TODO: Create higienization type key
      let tipoHigienizacao = (currentHigienizacao.pipelineTipoHigienizacao ||
        "") as string;

      if (!getPipelineStepTitle(tipoHigienizacao as any)) {
        tipoHigienizacao = currentHigienizacao.pipelineStage;
      }

      const groupKey =
        currentHigienizacao.banco! ||
        currentHigienizacao.simulacaoStatusResult?.[0]?.banco! ||
        (currentHigienizacao.higienizacaoStatusResultList?.[0]?.banco! ??
          "Avisos:") ||
        (currentHigienizacao.margemConvenioStatusResultList
          ? "Avisos:"
          : undefined);

      if (groupKey)
        acc[tipoHigienizacao] = {
          ...acc[tipoHigienizacao],
          // TODO: Create bank key
          [groupKey]: {
            title: groupKey,
            data:
              currentHigienizacao.higienizacaoStatusResultList! ||
              currentHigienizacao.simulacaoStatusResult! ||
              currentHigienizacao.margemConvenioStatusResultList! ||
              [],
          },
        };

      return acc;
    },
    {} as {
      [higienizationType: string]: {
        [group: string]: {
          title: string | null;
          data:
            | Pipeline["higienizacaoStatusResultList"]
            | Pipeline["simulacaoStatusResult"]
            | Pipeline["margemConvenioStatusResultList"];
        };
      };
    }
  );

  // Filter keys with out data
  Object.keys(groupedData).forEach((k) => {
    const current = groupedData[k];
    if (typeof current === "object") {
      Object.keys(current).forEach((key) => {
        const inner = current[key];
        if (!Array.isArray(inner.data) || !inner.data.length) {
          delete groupedData[k][key];
        }
      });
      const isEmpty = Object.keys(groupedData[k]).length === 0;
      if (isEmpty) delete groupedData[k];
    }
  });

  return groupedData;
};

const groupBy = (items: any, key: any) =>
  items.reduce(
    (result: any, item: any) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export const getErrorMessage = (data: Pipeline | undefined) => {
  if (data?.pipelineItemStatus === "SUSPENSO") {
    return [data?.pipelineItemErrorDescription];
  } else if (
    (data?.pipelineStage === "HIGIENIZACAO" ||
      data?.pipelineStage === "TELEFONIA") &&
    data?.higienizacaoStatusResultList
  ) {
    let errorMessages = groupBy(
      data.higienizacaoStatusResultList.filter(
        (status) => status.lastError !== null
      ),
      "lastError"
    );

    return Object.keys(errorMessages);
  } else {
    return [data?.pipelineErrorDescription];
  }
};

export const getHigienizacaoIds = (
  data: Pipeline | undefined
): Array<string> | null => {
  if (data?.higienizacaoStatusResultList) {
    return data.higienizacaoStatusResultList
      .filter((result) => result.higienizacaoId)
      .map((result) => result.higienizacaoId);
  }
  return null;
};

export const advancePipeline = async ({
  setActiveStep,
  setIsLoading,
  row,
}: {
  setActiveStep: Dispatch<SetStateAction<number>>;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  row: MailingTableRow;
}) => {
  setIsLoading(true);
  try {
    const response = await api.post(`/pipeline/advance/${row.id}`);
    setActiveStep((activeStep) => activeStep + 1);
  } catch (e) {
    Toast({
      title: "Erro ao avançar pipeline",
      status: "error",
    });
  } finally {
    setIsLoading(false);
    makeEvent("update-mailings-table", true);
  }
};

export const replayPipeline = async ({
  higienizacaoIds,
  row,
  setIsLoading,
}: {
  higienizacaoIds: Array<string> | null;
  row: MailingTableRow;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}) => {
  if (higienizacaoIds) {
    setIsLoading(true);
    try {
      await api.post(`/pipeline/replay/${row.id}`, higienizacaoIds);
      // setActiveStep(activeStep + 1);
    } catch (e) {
      Toast({
        title: "Erro ao executar novamente o pipeline",
        status: "error",
      });
    } finally {
      setIsLoading(false);
      makeEvent("update-mailings-table", true);
    }
  }
};

export const verifyStageStatus = ({
  stage,
  status,
  data,
  modalData,
}: {
  stage: Pipeline["pipelineStage"];
  status: Pipeline["pipelineStatus"];
  data?: Pipeline[];
  modalData: Pipeline[];
}) => {
  if (data)
    return !!data
      .filter((crr) => crr.pipelineStage === stage)
      .filter((crr) => crr.pipelineStatus === status).length;
  return !!modalData
    .filter((crr) => crr.pipelineStage === stage)
    .filter((crr) => crr.pipelineStatus === status).length;
};

const steps: PipelineStep[] = [
  { title: "Higienização", key: "HIGIENIZACAO" },
  { title: "Simulação Portabilidade", key: "SIMULACAO_PORTABILIDADE" },
  { title: "Filtro", key: "FILTRO" },
  { title: "Telefonia", key: "TELEFONIA" },
  { title: "Digitação", key: "DIGITACAO" },
  { title: "Envio de campanha Whatsapp", key: "ENVIA_WHATS" },
  { title: "Envio de campanha SMS", key: "ENVIA_CAMPANHA_SMS" },
  { title: "Envio de campanha URA", key: "ENVIA_DISCADORA" },
  { title: "Envio para atendimento", key: "ENVIA_ATENDIMENTO" },
  { title: "Saque Complementar", key: "SAQUE_COMPLEMENTAR" },
  { title: "Refin.", key: "REFIN" },
  { title: "Margem Convênio", key: "MARGEM_CONVENIO" },
];

export const getFilteredPipelineSteps = ({
  modalData,
  user,
}: {
  modalData: Pipeline[];
  user: User;
}) => {
  // TEMPORARIO
  const isDVDUser =
    user.userData.customerId === 1 && user.userData.type === "SUPER";
  //

  return steps.filter(({ key }) => {
    // TEMPORARIO
    if (isDVDUser) {
      return !!modalData.find(({ pipelineStage }) => pipelineStage === key);
    } else {
      if (key === "SIMULACAO_PORTABILIDADE") return false;
      return !!modalData.find(({ pipelineStage }) => pipelineStage === key);
    }
  });
};

export function getPipelineStepTitle(key: Pipeline["pipelineStage"]) {
  return steps.find(({ key: k }) => k === key)?.title!;
}
