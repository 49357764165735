import { CloseIcon } from "@chakra-ui/icons";
import { Button, ButtonProps, Text } from "@chakra-ui/react";
import { dropdownSimNao } from "components/atendimentos-components/atendimento-form/fields-data";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import { RenewIcon } from "components/vectors/renew-icon";
import { ReactNode, useState } from "react";
import { useEventListener, makeEvent } from "services/events";

interface ModalDataProps {
  modalTitle: string;
  onConfirm: ({ possuiRecorrencia }: { possuiRecorrencia: boolean }) => void;
  confirmButtonProps?: ButtonProps;
  children?: JSX.Element | ReactNode;
}

export function ModalComDropdownRecorrencia() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [possuiRecorrencia, setPossuiRecorrencia] = useState(false);
  const [modalData, setModalData] = useState<Partial<ModalDataProps>>({});

  const onOpen = (modalData: ModalDataProps) => {
    setIsOpen(true);
    setModalData(modalData);
  };
  const onClose = () => {
    setIsOpen(false);
    setIsLoading(false);
  };

  useEventListener("ModalComDropdownRecorrencia", onOpen);

  const modalFooter = (
    <>
      <Button
        variant="outline"
        onClick={onClose}
        leftIcon={<CloseIcon w="12px" h="12px" />}
      >
        Cancelar
      </Button>
      <Button
        leftIcon={<RenewIcon />}
        isLoading={isLoading}
        loadingText="Confirmando"
        onClick={async () => {
          setIsLoading(true);
          try {
            await modalData.onConfirm?.({ possuiRecorrencia });
          } catch {
          } finally {
            onClose();
          }
        }}
        children={`Confirmar`}
        {...modalData.confirmButtonProps}
      />
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={modalData.modalTitle}
      modalFooter={modalFooter}
    >
      <Text mb="8px">Cobrança recorrente</Text>
      <Dropdown
        w="100%"
        value={possuiRecorrencia}
        options={dropdownSimNao}
        onChange={setPossuiRecorrencia}
        variant="outline-custom"
      />
      {modalData.children}
    </CustomModal>
  );
}

export const openModalComDropdownRecorrencia = (modalData: ModalDataProps) =>
  makeEvent("ModalComDropdownRecorrencia", modalData);
