import { Checkbox } from "components/checkbox";
import { CustomModal } from "components/custom-modal";
import { Dispatch, SetStateAction, useState } from "react";
import { makeEvent, useEventListener } from "services/events";
import { useAttendanceContext } from "contexts/attendance-context";
import { openModalConfirm } from "components/modal-confirm-new";
import { ConsultaCPFProps, mapeamentoConsultCPF } from "../variables";
import apiHigienizacao from "api/api-higienizacao";
import { Toast } from "components/toast";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { Button } from "@chakra-ui/react";
import { CloseIcon } from "components/vectors/close-icon";
import { DocumentIcon } from "components/vectors/document-icon";
import { fieldValidation } from "utils/field-validation";

interface ModalProps {
  setLoadingConsultaCPF: Dispatch<SetStateAction<boolean>>;
}

export function ModalConsultaCPF() {
  const [isOpen, setIsOpen] = useState(false);
  const [whatsapp, setWhatsapp] = useState(false);
  const [modalData, setModalData] = useState<Partial<ModalProps>>({});
  const { formControl, dispatch } = useAttendanceContext();
  const formValues = formControl.values as Attendance;
  //   const;

  const onOpen = (props: ModalProps) => {
    setIsOpen(true);
    setModalData(props);
  };
  const onClose = () => {
    setIsOpen(false);
    setModalData({});
    setWhatsapp(false);
  };

  useEventListener(`openModalConsultaCPF`, onOpen);

  const modalFooter = (
    <>
      <Button onClick={onClose} variant="outline" leftIcon={<CloseIcon />}>
        Cancelar
      </Button>
      <Button
        onClick={() => handleSubmit()}
        leftIcon={<DocumentIcon width="20px" height="20px" />}
      >
        Consultar
      </Button>
    </>
  );

  const handleSubmit = async () => {
    if (!fieldValidation({ value: formValues.cpf }).cpf().isValid) {
      Toast({ title: `Insira um CPF Válido` });
    }
    onClose();
    modalData.setLoadingConsultaCPF?.(true);
    try {
      const { data }: { data: ConsultaCPFProps } = await apiHigienizacao.get(
        `/telefonia/dados/${formValues.cpf}`,
        { params: { whatsapp: whatsapp } }
      );

      openModalConfirm({
        message: `Deseja passar dados de consulta para o atendimento?`,
        onConfirm: () => {
          mapeamentoConsultCPF({
            dispatch,
            consulta: data,
            attendance: formValues,
          });
          Toast({ title: "Dados da consulta transferidos", status: "success" });
        },
        confirmButtonStyle: { variant: undefined },
      });
    } catch (e: any) {
      const msgInterface = e?.response?.data?.msgInterface;
      const status = e?.response?.status;
      Toast({
        title: msgInterface ?? "Erro ao fazer consulta CPF",
        status: status === 500 ? "error" : "warning",
      });
    } finally {
      modalData.setLoadingConsultaCPF?.(false);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalFooter={modalFooter}
      modalTitle="Consultar CPF"
    >
      <Checkbox
        onChange={(isChecked) => setWhatsapp(isChecked)}
        isChecked={whatsapp}
      >
        Consultar somente se tiver WhatsApp
      </Checkbox>
    </CustomModal>
  );
}

export const openModalConsultaCPF = (props: ModalProps) =>
  makeEvent(`openModalConsultaCPF`, props);
