import { Box, Center, Flex, IconButton, Spinner, Text } from "@chakra-ui/react";
import { Droppable } from "react-beautiful-dnd";
import AttendanceKanbanCard from "../card";
import { AttendanceKandanColumnProps } from "../types";
import { DotsIcon } from "components/vectors/dots-icon";
import { useAttendanceKanbanContext } from "../../kanban-context";
import { SkeletonLoading } from "components/skeleton-loading";
import { defaultScroll, removeScroll } from "chakra/theme";
import { isOverflown } from "utils/relative-rect";
import { memo, useEffect, useState } from "react";
import { kanbanColumnWidth } from "../..";
import { MenuThreeDots, MenuThreeDotsOptions } from "./menu-three-dots";
import { RepeatIcon } from "@chakra-ui/icons";

const AttendanceKanbanColumn = ({
  colId,
  attendancesList,
  colName,
  title,
  color,
}: AttendanceKandanColumnProps) => {
  const {
    isLoadingAttendances,
    loadMoreAttendances,
    isDisabledColumns,
    loadingColumns,
  } = useAttendanceKanbanContext();
  const [isScrollOn, setIsScrollOn] = useState(false);
  const translate = 14;

  const menuDotsOptions: MenuThreeDotsOptions = [
    {
      label: (
        <Flex alignItems="center">
          <Center mr="8px">
            <RepeatIcon />
          </Center>{" "}
          Atualizar
        </Flex>
      ),
      onClick: () => {
        if (!loadingColumns[colId]) {
          loadMoreAttendances({ colId, limit: 30, customOffset: 0 });
        }
      },
    },
  ];

  useEffect(() => {
    const colEl = document.getElementById(`${colId}-container`);
    if (colEl) {
      const rect = colEl.getBoundingClientRect();
      colEl.style.height = `calc(100vh - ${rect.y}px - 7px)`;
      colEl.style.maxHeight = `calc(100vh - ${rect.y}px - 7px)`;
      setIsScrollOn(isOverflown(colEl));
    }
  }, [attendancesList]);

  return (
    <Box>
      <Box fontWeight="medium" mb="20px">
        <Text mb="5px" color="#6A6A6A" fontSize="12px">
          {title}
        </Text>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center">
            <Box w="13px" h="13px" borderRadius="full" bg={color} mr="5px" />
            <Text fontSize="17px">{colName}</Text>
          </Flex>
        </Flex>
      </Box>

      <Box w="100%">
        {isLoadingAttendances ? (
          Array.from({ length: 3 }).map((_, index) => {
            return (
              <SkeletonLoading
                key={index}
                isLoading={isLoadingAttendances}
                w="100%"
                h="207px"
                borderRadius="5px"
                mb="10px"
              />
            );
          })
        ) : (
          <Droppable
            isDropDisabled={loadingColumns[colId] || isDisabledColumns}
            droppableId={colId}
            children={(provided) => {
              let { droppableProps } = provided;
              return (
                <Box
                  ref={provided.innerRef}
                  {...droppableProps}
                  opacity={loadingColumns[colId] || isDisabledColumns ? 0.5 : 1}
                  pb={loadingColumns[colId] ? "" : "120px"}
                  overflow="overlay"
                  sx={{ ...removeScroll, ":hover": defaultScroll }}
                  id={`${colId}-container`}
                  onScroll={async (e) => {
                    const columnElement = e.currentTarget;
                    // Altura do elemento
                    const elementHeight = columnElement.clientHeight;
                    // Distancia percorrida pelo scroll
                    const distance = columnElement.scrollTop;
                    // Tamanho total do elemento
                    const totalElementHeight = columnElement.scrollHeight;
                    if (elementHeight + distance >= totalElementHeight) {
                      if (!loadingColumns[colId]) {
                        await loadMoreAttendances({ colId, limit: 30 });
                      }
                    }
                  }}
                >
                  <Box display="none">{provided.placeholder}</Box>
                  {attendancesList.map((item, index) => {
                    return (
                      <AttendanceKanbanCard
                        {...item}
                        key={`card-${item.id}`}
                        index={index}
                        isScrollOn={isScrollOn}
                      />
                    );
                  })}
                  {loadingColumns[colId] ? (
                    <Center w="100%" h="120px">
                      <Spinner />
                    </Center>
                  ) : null}
                </Box>
              );
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default memo(AttendanceKanbanColumn);
