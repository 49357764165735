import { Box } from "@chakra-ui/react";
import { AttendanceKanbanContextProvider } from "components/atendimentos-components/kanban-and-table-atendimento/kanban/kanban-context";
import { GetLayout } from "components/get-layout";
import { useEffect, useRef, useState } from "react";
import { NovoAttendanceModal } from "components/atendimentos-components/new-attendance-modal";
import { KanbanAndTableAtendimento } from "components/atendimentos-components/kanban-and-table-atendimento";
import { Navbar3cplusTemplate } from "components/atendimentos-components/navbar-3cplus-template";
import { ClientesAtendimento } from "components/atendimentos-components/aba-clientes";
import { PageSelect } from "components/atendimentos-components/page-select-tab";
import { use3cPlusContext } from "contexts/Context3cPlus";
import { useApplicationContext } from "contexts/ApplicationContext";
import { ClientsAttendanceContextProvider } from "components/atendimentos-components/aba-clientes/aba-clientes-context";

export function AtendimentoNovoKanban() {
  const [currentPage, setCurrentPage] = useState("Atendimento");
  const pageContainerRef = useRef<HTMLDivElement>(null);
  const { controls3cPlus } = use3cPlusContext();
  const { setMenuErrorMessage } = useApplicationContext();

  useEffect(() => {
    if (!controls3cPlus.inCall) {
      setMenuErrorMessage(undefined);
    }
  }, [controls3cPlus.inCall]);

  return (
    <GetLayout
      HeadRender={Navbar3cplusTemplate}
      pageContentProps={{ p: `0px`, overflow: "overlay" }}
    >
      <AttendanceKanbanContextProvider>
        <PageSelect currentPage={currentPage} setCurrentPage={setCurrentPage} />
        <Box w="100%" ref={pageContainerRef}>
          {currentPage === "Atendimento" ? (
            <KanbanAndTableAtendimento />
          ) : currentPage === "Clientes" ? (
            <ClientsAttendanceContextProvider>
              <ClientesAtendimento />
            </ClientsAttendanceContextProvider>
          ) : null}
        </Box>
      </AttendanceKanbanContextProvider>

      <NovoAttendanceModal />
    </GetLayout>
  );
}
