import { Box, BoxProps, Flex, IconButton, Text } from "@chakra-ui/react";
import { ChevronDownIcon } from "components/vectors/chevron-down-icon";
import { ReactNode, useEffect, useRef, useState } from "react";
import { AnimateElement } from "utils/animations";

export const Accordion = ({
  children,
  title,
  isOpen: externalIsOpen,
  containerProps,
  onOpen: externalOnOpen,
  onClose: externalOnClose,
  renderButton,
  isVisible,
}: {
  children?: JSX.Element | ReactNode;
  isOpen?: boolean;
  onOpen?: () => void;
  onClose?: () => void;
  title?: JSX.Element | ReactNode | string;
  containerProps?: BoxProps;
  isVisible?: () => boolean;
  renderButton?: ({
    onToggle,
    onOpen,
    onClose,
    isOpen,
  }: {
    onToggle: () => void;
    onOpen: () => void;
    onClose: () => void;
    isOpen: boolean;
  }) => JSX.Element | ReactNode;
}) => {
  let [isOpen, setIsOpen] = useState(false);
  const [isInsideDOM, setIsInsideDOM] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const labelContainerRef = useRef<HTMLDivElement>(null);

  const onOpen = () => {
    setIsOpen(true);
    setIsInsideDOM(true);
    isOpen = true;
  };

  const onClose = () => {
    setIsOpen(false);
    isOpen = false;
  };

  const onToggle = () => {
    if (externalIsOpen == null) {
      if (isOpen) onClose();
      else onOpen();
    } else {
      if (externalIsOpen) externalOnOpen?.();
      else externalOnClose?.();
    }
  };

  const openingAnimation = () => {
    const content = contentRef.current;
    const container = containerRef.current;
    const labelContainer = labelContainerRef.current;
    if (content && container && labelContainer) {
      const currentProgress = Number(getComputedStyle(container).opacity);
      const duration = (1 - currentProgress) * 200;
      container.style.overflow = `hidden`;
      AnimateElement(
        duration,
        [currentProgress, 1],
        (progress) => {
          if (content && container && labelContainer) {
            const maxHeight = content.clientHeight;
            container.style.opacity = `${progress}`;
            container.style.height = `${progress * maxHeight}px`;
          }
        },
        () => {
          if (isOpen && container) {
            container.style.overflow = `visible`;
            container.style.height = "auto";
          }
        }
      );
    }
  };

  const closingAnimation = () => {
    const content = contentRef.current;
    const container = containerRef.current;
    if (content && container) {
      const currentProgress = Number(getComputedStyle(container).opacity);
      const duration = currentProgress * 200;
      container.style.overflow = `hidden`;
      AnimateElement(
        duration,
        [1, 0],
        (progress) => {
          if (content && container) {
            const maxHeight = content.clientHeight;
            container.style.opacity = `${progress}`;
            container.style.height = `${progress * maxHeight}px`;
          }
        },
        () => (!isOpen ? setIsInsideDOM(false) : null)
      );
    }
  };

  useEffect(() => {
    if (isOpen) openingAnimation();
  });

  useEffect(() => {
    if (isOpen) openingAnimation();
    else closingAnimation();
  }, [isOpen]);

  useEffect(() => {
    if (externalIsOpen != null) {
      if (externalIsOpen) onOpen();
      else onClose();
    }
  }, [externalIsOpen]);

  return isVisible == null || isVisible?.() ? (
    <Box w="100%" {...containerProps}>
      <Flex
        ref={labelContainerRef}
        _hover={{ bg: "gray.50" }}
        transition="background 0.2s"
        px="22px"
        py="17px"
        justifyContent="space-between"
        alignItems="center"
        border="1px solid #E8EAED"
        borderRadius={isInsideDOM ? "5px 5px 0px 0px" : "5px"}
        onClick={() => onToggle()}
        cursor="pointer"
      >
        <Box
          fontSize={{ base: "17px", "2xl": "18px" }}
          mr="10px"
          fontWeight="semibold"
        >
          {title}
        </Box>
        {renderButton ? (
          renderButton({ onToggle, onOpen, onClose, isOpen })
        ) : (
          <IconButton
            aria-label=""
            w="28px"
            minW="28px"
            height="28px"
            isRound
            icon={<ChevronDownIcon />}
            transform={isOpen ? "rotate(180deg)" : ""}
          />
        )}
      </Flex>
      <Box
        ref={containerRef}
        opacity="0"
        overflow="hidden"
        height="0"
        borderLeft="1px solid #E8EAED"
        borderRight="1px solid #E8EAED"
        borderBottom="1px solid #E8EAED"
        borderRadius="0px 0px 5px 5px"
      >
        {isInsideDOM ? (
          <Box ref={contentRef} w="100%" px="22px" py="17px">
            {children}
          </Box>
        ) : null}
      </Box>
    </Box>
  ) : null;
};
