import { Box, Button, Center, Flex, Text } from "@chakra-ui/react";
import { hasSession } from "Session";
import api from "api/api";
import { FieldError } from "components/field-error";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useEffect, useState } from "react";
import { CustomInput } from "../../components/login-page/custom-input";
import { LockIcon } from "../../components/vectors/lock-icon";
import { UserIcon } from "../../components/vectors/user-icon";
import { getDomainKeySelect } from "utils/verify-domain";
import { userTypeRedirect } from "components/login-page/functions";
import { isMaisAgilDomain } from "utils/is-mais-agil-domain";
import { getLogo } from "components/white-label";
import { usePagePayload } from "routes/router-control/use-page-payload";
import { Router } from "routes/router-control/use-router";

const loginErrorList: any = {
  401: "Usuário ou Senha incorretos.",
};

export const Login = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loginError, setLoginError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { setUserAction, user } = useApplicationContext();
  const payloadParams = usePagePayload()!;

  useEffect(() => {
    setLoginError("");
  }, [username, password]);

  useEffect(() => {
    if (hasSession()) Router.push(userTypeRedirect(user));
  }, [user]);

  async function handleSubmit(authorization: string) {
    setIsLoading(true);
    try {
      const response = await api.get("/usuarios/login", {
        headers: { Authorization: "Basic " + authorization },
      });
      setIsLoading(false);
      setUserAction({
        type: "login",
        payload: { userData: response.data, token: authorization },
      });
      Router.push(userTypeRedirect({ userData: response.data }));
    } catch (error: any) {
      const errorMessage = error?.response?.data?.message;
      setLoginError(
        loginErrorList[error.response?.status] ?? "Algo deu errado."
      );

      setIsLoading(false);
    }
  }

  return (
    <Flex px="25px" w="100%" h="100vh" alignItems="center" fontSize="16">
      <Flex
        alignItems="center"
        flexDir="column"
        maxW="550px"
        w="100%"
        mx="auto"
      >
        <Center mb="73px" w={isMaisAgilDomain() ? "40%" : "80%"} mx="auto">
          {getLogo()}
        </Center>
        <Box w="100%" mb="18px">
          <CustomInput
            icon={<UserIcon />}
            placeholder="Usuário"
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            onKeyDown={(e) =>
              e.key === "Enter"
                ? handleSubmit(window.btoa(username + ":" + password))
                : undefined
            }
          />
        </Box>
        <CustomInput
          icon={<LockIcon />}
          placeholder="Senha"
          onChange={(e) => setPassword(e.target.value)}
          value={password}
          type="password"
          onKeyDown={(e) =>
            e.key === "Enter"
              ? handleSubmit(window.btoa(username + ":" + password))
              : undefined
          }
        />
        <FieldError isError={loginError !== ""}>{loginError}</FieldError>
        <Button
          isLoading={isLoading}
          mt="17px"
          h="55px"
          color="#fff"
          fontWeight="800"
          bg="primary.500"
          w="100%"
          borderRadius="10"
          _hover={{ bg: "primary.400" }}
          onClick={() => handleSubmit(window.btoa(username + ":" + password))}
          loadingText="Entrando"
        >
          Entrar
        </Button>
        {getDomainKeySelect() === "www" ||
        getDomainKeySelect() === "localhost" ||
        getDomainKeySelect() === "novapowerhub" ? (
          <Text mt="74px" fontWeight="500">
            POWERED BY <b>NOVA PROMOTORA</b>
          </Text>
        ) : null}
      </Flex>
    </Flex>
  );
};
