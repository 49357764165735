import { Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  Text,
} from "@chakra-ui/react";
import {
  CustomTableColor,
  CustomTableColorProps,
} from "components/dynamic-table/color-table";
import { getNameInitials } from "../kanban-and-table-atendimento/kanban/parts/utils";
import { useRouter } from "routes/router-control/use-router";
import { ClientsFilter } from "./clients-filter";
import { useClientsAttendanceContext } from "./aba-clientes-context";
import { Popover } from "components/popover";
import { DotsIcon } from "components/vectors/dots-icon";
import { useState } from "react";
import { useApplicationContext } from "contexts/ApplicationContext";
import { BotaoCriarMailing } from "./botao-criar-mailing";
import { ClientListRow } from "./aba-clientes-context/types";
import api from "api/api";
import { openModalConfirm } from "components/modal-confirm-new";
import { Toast } from "components/toast";

export const ClientesAtendimento = () => {
  let [searchValue, setSearchValue] = useState("");
  const {
    clientList,
    isLoadingClientsList,
    clientsFilter,
    getClients,
    setClientsFilter,
    setIsLoadingClientsList,
  } = useClientsAttendanceContext();
  const { user } = useApplicationContext();

  const router = useRouter();

  const createAttendance = async (row: ClientListRow) => {
    setIsLoadingClientsList(true);
    try {
      const { data } = await api.post(`/atendimentos/create-from-cliente`, row);
      openModalConfirm({
        message: `Atendimento criado, deseja iniciar atendimento?`,
        onConfirm: async () => {
          router.push(`/atendimento-cliente-page?id=${data}&init=true`);
        },
        confirmButtonStyle: { variant: undefined },
      });
    } catch (e: any) {
      Toast({ title: `Erro ao criar atendimento`, status: "error" });
    } finally {
      setIsLoadingClientsList(false);
    }
  };

  const columns: CustomTableColorProps["columns"] = [
    {
      name: "TIPO OPERAÇÃO",
      render: (row: ClientListRow) => <Text>{row.operacaoNome}</Text>,
    },
    { name: "CPF", render: (row: ClientListRow) => row.cpf },
    {
      name: "CLIENTE",
      render: (row: ClientListRow) =>
        row.nome ? (
          <Flex alignItems="center">
            <Center
              w="30px"
              h="30px"
              minW="30px"
              minH="30px"
              pos="relative"
              borderRadius="full"
              bg="#BCBCBC"
              mr="10px"
            >
              <Text fontSize="13px">{getNameInitials(row.nome)}</Text>
            </Center>
            <Text>{row.nome}</Text>
          </Flex>
        ) : (
          "(Sem nome)"
        ),
    },
    {
      name: "CIDADE",
      render: (row: ClientListRow) =>
        `${row.cidade ?? ""}${row.uf ? " - " + row.uf : ""}`,
    },
    {
      name: "MATRÍCULA",
      render: (row: ClientListRow) => row.matricula,
    },
    {
      name: "AÇÕES",
      render: (row: ClientListRow) => (
        <Flex>
          <Popover
            button={
              <IconButton
                aria-label=""
                bg="#F1F1F1"
                variant="none"
                _hover={{ bg: "#cccccc" }}
                size="xs"
                h="27px"
                w="27px"
                icon={<DotsIcon />}
              />
            }
            position="left-bottom"
            contentProps={{ padding: "0px" }}
          >
            {({ onClose }) => (
              <Flex
                _hover={{ bg: "gray.100" }}
                p="5px 10px"
                onClick={() => {
                  createAttendance(row);
                  onClose();
                }}
                cursor="pointer"
              >
                Iniciar Atendimento
              </Flex>
            )}
          </Popover>
        </Flex>
      ),
    },
  ];

  const onSearch = (searchValue: string) => {
    if (searchValue) {
      const newFilter = {
        ...clientsFilter,
        cpfNomeMatricula: searchValue.replace(/\D/g, ""),
      };
      setClientsFilter(newFilter);
      getClients(newFilter);
    } else {
      const newValue = { ...clientsFilter };
      delete newValue["cpfNomeMatricula"];
      setClientsFilter(newValue);
      getClients(newValue);
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.trim();
    e.currentTarget.value = value;
    setSearchValue(value);
    searchValue = value;
    if (!value.length && clientsFilter.cpfNomeMatricula) onSearch(value);
  };

  return (
    <Box w="100%">
      {/* TOP BAR */}
      <Flex
        justifyContent="space-between"
        w="100%"
        borderBottom="1px solid #E8EAED"
        p="11px 30px 9px"
        mb="30px"
      >
        <Flex alignItems="center">
          <InputGroup>
            <InputLeftElement w="36px">
              <Search2Icon color="#343B44" width="12px" height="12px" />
            </InputLeftElement>
            <Input
              p="0 10px 0 36px"
              placeholder="Pesquisar CPF ou matrícula"
              w="320px"
              onChange={onChange}
            />
            <Button
              variant="outline"
              size="sm"
              ml="10px"
              leftIcon={
                <Search2Icon color="#343B44" width="12px" height="12px" />
              }
              isLoading={isLoadingClientsList}
              loadingText="Pesquisando"
              onClick={() => {
                onSearch(searchValue);
              }}
            >
              Pesquisar
            </Button>
            <BotaoCriarMailing />
          </InputGroup>
        </Flex>
        <ClientsFilter />
      </Flex>
      {/* TABLE */}
      <Box px="30px" pb="30px">
        <CustomTableColor
          columns={columns}
          rows={clientList.contratos || []}
          isLoading={isLoadingClientsList}
          currentPage={clientsFilter.page - 1}
          onFirstPage={() => {
            const page = 1;
            getClients({ ...clientsFilter, page });
          }}
          onPrevPage={() => {
            const page = clientsFilter.page - 1;
            getClients({ ...clientsFilter, page });
          }}
          onNextPage={() => {
            const page = clientsFilter.page + 1;
            getClients({ ...clientsFilter, page });
          }}
        />
      </Box>
    </Box>
  );
};
