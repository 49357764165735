import { Box, Button, Grid, Text } from "@chakra-ui/react";
import { Popover } from "components/popover";
import { DownloadIcon } from "@chakra-ui/icons";
import { InputField } from "components/input-field";
import { Dispatch, SetStateAction, useState } from "react";
import api from "api/api";
import { createLinkAndDownload } from "utils/create-link-and-download";
import { InputSearch } from "components/input-search";
import { CampanhaSMSRow, FilterCampanhaSMS } from "pages/campanha-sms";
import { validateFilterCampanhaSMSBody } from "./util";
import { Filter2Icon } from "components/vectors/filter-icon";
import { Toast } from "components/toast";

export interface DownloadRelatoryProps {
  dataInicial: string;
  dataFinal: string;
  campanhaIds: string[];
}

const initialValue: DownloadRelatoryProps = {
  dataInicial: "",
  dataFinal: "",
  campanhaIds: [],
};

export function DownloadSMSRelatory({
  tableRows,
  filter,
  setFilter,
  update,
}: {
  tableRows: CampanhaSMSRow[];
  filter: FilterCampanhaSMS;
  setFilter: Dispatch<SetStateAction<FilterCampanhaSMS>>;
  update: () => void;
}) {
  const [fields, setFields] = useState<DownloadRelatoryProps>(initialValue);
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const [isLoading, setIsLoading] = useState(false);

  const isDateEmpty = !fields.dataFinal && !fields.dataInicial;

  const onChange = (field: keyof DownloadRelatoryProps, value: any) => {
    setFields({ ...fields, [field]: value });
    setFilter({ ...fields, [field]: value });
    setErrors((errors) => {
      delete errors[field];
      return errors;
    });
  };

  const onChangeMultiselect = (value: any, field: "campanhaIds") => {
    const currentEl = fields[field] as any[];
    if (currentEl?.length && currentEl != null) {
      const isInclude = currentEl.includes(value);
      if (isInclude)
        onChange(
          field,
          currentEl.filter((curr: any) => curr !== value)
        );
      else onChange(field, [...fields[field]!, value]);
    } else onChange(field, [value]);
  };

  const handleDownload = async ({ onClose }: { onClose: () => void }) => {
    const { isValid, body, errors } = validateFilterCampanhaSMSBody(fields);

    if (!isValid) return setErrors(errors);
    setIsLoading(true);
    try {
      const { data } = await api.post(`/campanha-sms/relatorio`, body);
      const fileText = data as string;
      const file = new Blob([fileText]);
      createLinkAndDownload(
        file,
        `relatório-${fields.dataInicial ? fields.dataInicial : ""}${
          fields.dataFinal ? "-" + fields.dataFinal : ""
        }.csv`
      );
      onClose();
    } catch (e) {
      Toast({ title: "Erro ao baixar" });
    } finally {
      setIsLoading(false);
    }
  };

  const handleFiltrar = ({ onClose }: { onClose: () => void }) => {
    onClose();
    update();
  };

  return (
    <Popover
      button={
        <Button variant="outline" leftIcon={<DownloadIcon />}>
          Filtrar / Baixar relatório
        </Button>
      }
      title="Filtrar / Baixar relatório"
      position="left-bottom"
      withOutCloseButton
      popupStyles={{ minWidth: "320px" }}
      contentProps={{ padding: "8px" }}
      onClose={() => {}}
    >
      {({ onClose }) => (
        <>
          <Box mb="10px">
            <Grid templateColumns="1fr 1fr" w="100%" gap="7px">
              <Box>
                <InputField
                  title="Data Inicial"
                  inputProps={{
                    type: "datetime-local",
                    onClick: (e) => e.stopPropagation(),
                    isDisabled: !!fields.campanhaIds.length,
                  }}
                  errorMessage={errors["dataInicial"]}
                  value={fields.dataInicial}
                  onChange={(e) => {
                    console.log(e.target.value);
                    onChange("dataInicial", e.target.value);
                  }}
                />
              </Box>
              <Box>
                <InputField
                  title="Data Final"
                  inputProps={{
                    type: "datetime-local",
                    onClick: (e) => e.stopPropagation(),
                    isDisabled: !!fields.campanhaIds.length,
                  }}
                  errorMessage={errors["dataFinal"]}
                  value={fields.dataFinal}
                  onChange={(e) => {
                    onChange("dataFinal", e.target.value);
                  }}
                />
              </Box>
            </Grid>
          </Box>
          <Box mb="10px">
            <Text mb="8px" fontWeight="medium">
              Campanhas
            </Text>
            <InputSearch
              value={fields.campanhaIds
                .map(
                  (currentId) =>
                    tableRows.find(({ id }) => currentId === id)!.nome
                )
                .join(", ")}
              options={tableRows.map((option) => {
                return { name: option.nome, value: option.id };
              })}
              checkIsInclude={(option) => {
                return fields.campanhaIds.includes(option.value);
              }}
              onChange={(value) => {
                onChangeMultiselect(value, "campanhaIds");
              }}
              inputProps={{
                onFocus: (e) => {
                  e.target.value = "";
                },
                onBlur: (e) => {
                  e.target.value =
                    fields.campanhaIds
                      .map(
                        (currentId) =>
                          tableRows.find(({ id }) => currentId === id)!.nome
                      )
                      .join(", ") || "";
                },
                isDisabled: !isDateEmpty,
              }}
              openOnFocus
              multiSelect
              clearInput={false}
              closeOnSelect={false}
            />
          </Box>
          {/* <Checkbox
            mb="20px"
            onChange={(isChecked) => onChange("replies", isChecked)}
            isChecked={fields.replies}
          >
            Trazer apenas SMSs com Resposta
          </Checkbox> */}
          <Button
            w="100%"
            // size="md"
            fontSize="14"
            borderRadius="4"
            onClick={() => {
              handleDownload({ onClose });
            }}
            isLoading={isLoading}
            loadingText="Carregando"
            leftIcon={<DownloadIcon />}
          >
            Download
          </Button>
          <Button
            w="100%"
            // size="md"
            mt="8px"
            fontSize="14"
            borderRadius="4"
            onClick={() => {
              handleFiltrar({ onClose });
            }}
            isLoading={isLoading}
            loadingText="Carregando"
            leftIcon={<Filter2Icon width="15px" height="15px" color="white" />}
          >
            Filtrar
          </Button>
        </>
      )}
    </Popover>
  );
}
