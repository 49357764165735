import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
} from "@chakra-ui/react";
import api from "api/api";
import { defaultScroll } from "chakra/theme";
import { filterArrayBy } from "utils/filter-array-by";
import { useApplicationContext } from "contexts/ApplicationContext";
import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { getRelativeRect } from "utils/relative-rect";
import { SelectedCustomer } from "pages/carteira";
import { makeEvent } from "services/events";
import { Toast } from "components/toast";

export function PartnersSearch({
  selectedCustomer,
  setIsLoading,
  isLoading,
  onChangePartner,
  defaultCustomerId,
}: {
  selectedCustomer: SelectedCustomer;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  onChangePartner: (selectedPartner: SelectedCustomer) => void;
  defaultCustomerId?: number | null;
}) {
  const [isOpenInputResults, setIsOpenInputResults] = useState(false);
  const [allPartners, setAllPartners] = useState<SelectedCustomer[]>([]);
  const [result, setResult] = useState<SelectedCustomer[]>([]);
  const [selectedIndex, setSelectedIndex] = useState(-1);
  const { user } = useApplicationContext();
  const userType = user.userData.type;
  const partnerListRef = useRef<HTMLDivElement>(null);
  const input = useRef<HTMLInputElement>(null);
  const isOpened = isOpenInputResults && result.length;

  const isVisible = ["SUPER", "FINANCEIRO", "COMERCIAL"].includes(userType!);
  defaultCustomerId =
    defaultCustomerId == null ? user.userData.customerId! : defaultCustomerId;

  const getPartners = async (firstRequest: boolean = false) => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`/customers/short`);
      setAllPartners(
        data.map((partner: SelectedCustomer) => {
          if (partner.cashback == null) partner.cashback = "DISABLED";
          return partner;
        })
      );
      if (firstRequest) {
        const userData = data.find(
          (customer: any) => customer?.id === defaultCustomerId
        );
        onChange(userData!);
        if (selectedCustomer.codigoNova && input.current)
          input.current.value = `${selectedCustomer.codigoNova} - ${selectedCustomer.name}`;
      } else onChange({ ...selectedCustomer });
    } catch {
      Toast({
        title: "Erro ao carregar dados",
        status: "error",
      });
    } finally {
      if (!firstRequest) setIsLoading(false);
    }
  };

  const onChange = (result: SelectedCustomer) => {
    onChangePartner(result);
    selectedCustomer = result;
  };

  const handleScroll = (element: HTMLElement, parent: HTMLElement) => {
    const relRef = getRelativeRect(element, parent);
    const elHeight = element.clientHeight;
    const top = relRef.top + parent.scrollTop!;
    const scrollPos = parent.scrollTop! + parent.clientHeight;
    if (top + elHeight > scrollPos)
      parent.scrollTop = top + elHeight - parent.clientHeight;
    if (top < parent.scrollTop) parent.scrollTop = top;
  };

  const handleKeyPress = ({ key }: KeyboardEvent<HTMLInputElement>) => {
    const list = partnerListRef.current;
    if (key === "Escape") setIsOpenInputResults(false);
    else if (result.length) setIsOpenInputResults(true);

    if (key === "Enter" && isOpened) {
      const currResult = result[selectedIndex];
      if (currResult) {
        onChange(currResult);
        input.current?.blur();
      }
    } else if (key === "ArrowUp" && isOpened) {
      const newCurrIndex = selectedIndex - 1;
      const currResult = result[newCurrIndex];
      if (!currResult) {
        const selectedHtml = list!.children[
          result.length - 1
        ] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(result.length - 1);
        // input.current!.value = "";
      } else {
        const selectedHtml = list!.children[newCurrIndex] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(newCurrIndex);
        // input.current!.value = "";
      }
    } else if (key === "ArrowDown" && isOpened) {
      const newCurrIndex = selectedIndex + 1;
      const currResult = result[newCurrIndex];
      if (!currResult) {
        const selectedHtml = list!.children[0] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(0);
        // input.current!.value = "";
      } else {
        const selectedHtml = list!.children[newCurrIndex] as HTMLDivElement;
        handleScroll(selectedHtml, list!);
        setSelectedIndex(newCurrIndex);
        // input.current!.value = "";
      }
    }
  };

  useEffect(() => {
    getPartners(true);
  }, []);

  return isVisible ? (
    <Box maxW="600px" gap="16px" mb="15px">
      <Text mb="8px">Pesquisar parceiros</Text>
      <Box w="100%" pos="relative">
        <InputGroup height="36px">
          <Input
            ref={input}
            onBlur={(e) => {
              setIsOpenInputResults(false);
              if (selectedCustomer.codigoNova)
                e.target.value = `${selectedCustomer.codigoNova} - ${selectedCustomer.name}`;
            }}
            onFocus={(e) => {
              setIsOpenInputResults(true);
              e.target.value = "";
            }}
            bg="#fff"
            placeholder="Pesquise por parceiros"
            onChange={async ({ target }) => {
              if (target.value) {
                setResult(
                  filterArrayBy(allPartners, target.value, [
                    "name",
                    "codigoNova",
                  ]).slice(0, 20)
                );
              } else setResult([]);
              setSelectedIndex(-1);
            }}
            isDisabled={isLoading}
            onKeyDown={handleKeyPress}
          />
          <InputRightElement>
            {isLoading ? <Spinner w="18px" h="18px" /> : <SearchIcon />}
          </InputRightElement>
        </InputGroup>

        {isOpened ? (
          <Box
            ref={partnerListRef}
            pos="absolute"
            bottom="-2px"
            left="0"
            right="0"
            zIndex="1"
            transform="translateY(100%)"
            bg="#fff"
            borderRadius="6px"
            overflow="auto"
            border="1px solid var(--chakra-colors-gray-200)"
            maxH={"240px"}
            sx={defaultScroll}
            onMouseDown={(e) => e.preventDefault()}
          >
            {result.map(({ id, codigoNova, name, cashback }, index) => {
              const isActive = selectedCustomer.id === id;
              const isSelected = index === selectedIndex;
              return (
                <Box
                  p="5px 10px"
                  key={id}
                  w="100%"
                  _hover={{ bg: "gray.100" }}
                  bg={
                    isActive ? "gray.200" : isSelected ? "gray.100" : undefined
                  }
                  cursor="pointer"
                  onClick={(e: any) => {
                    onChange({ id, name, codigoNova, cashback });
                    setIsOpenInputResults(false);
                    setSelectedIndex(index);
                    input.current!.value = `${selectedCustomer.codigoNova} - ${selectedCustomer.name}`;
                    e.currentTarget.focus();
                  }}
                  textAlign="start"
                  as="button"
                >
                  {`${codigoNova ? `${codigoNova}  - ` : ""}${name}`}
                </Box>
              );
            })}
          </Box>
        ) : null}
      </Box>
    </Box>
  ) : null;
}

export const openModalPartnersSearch = () =>
  makeEvent("openModalPartnersSearch");
