import { Box, Button, Flex, Grid, Text } from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";
import { InputField } from "components/input-field";
import { Calendar2Icon } from "components/vectors/calendar-icon";
import { WhatsAppIcon2 } from "components/vectors/whatsapp-icon";
import { useState } from "react";
import { useEventListener, makeEvent } from "services/events";

interface ModalDataProps {}

export const ModalAgendar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [props, setProps] = useState<ModalDataProps>({});
  const [isLoading, setIsLoading] = useState(false);

  const onOpen = (modaData: ModalDataProps) => {
    setIsOpen(true);
    setProps(modaData);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  useEventListener("openModalAgendar", onOpen);

  const modalFooter = (
    <>
      <Button
        leftIcon={<Calendar2Icon color="white" width="18px" height="18px" />}
        variant="outline"
      >
        Agendar
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      isLoading={isLoading}
      modalTitle={
        <Flex>
          <WhatsAppIcon2 />
          <Text ml="10px">Agendar atendimento</Text>
        </Flex>
      }
      size="2xl"
      modalFooter={modalFooter}
    >
      <Box mb="15px">
        <InputField
          title="Informe o benefício ou CPF ou telefone"
          inputProps={{ variant: "outline-custom" }}
        />
      </Box>
      <Grid templateColumns="1fr 1fr" gap="30px" mb="15px">
        <Box>
          <InputField
            title="Selecione a data"
            inputProps={{ variant: "outline-custom" }}
          />
        </Box>
        <Box>
          <InputField
            title="Selecione a horário"
            inputProps={{ variant: "outline-custom" }}
          />
        </Box>
      </Grid>
      <Box mb="15px">
        <InputField
          title="Observação"
          inputProps={{ variant: "outline-custom" }}
        />
      </Box>
    </CustomModal>
  );
};

export const openModalAgendar = (modalData: ModalDataProps) =>
  makeEvent("openModalAgendar");
