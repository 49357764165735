import { Button, Center, Flex, Spinner, Td, Tr } from "@chakra-ui/react";
import api from "api/api";
import { useEffect, useRef, useState } from "react";
import { useEventListener } from "services/events";
import { AnimateElement } from "utils/animations";
import { MailingTableRow } from "..";
import { DownloadIcon, EditIcon } from "@chakra-ui/icons";
import {
  MailingOptionFilter,
  openMailingOptions,
} from "components/mailing-components/mailing-options-modal";
import { createLinkAndDownload } from "utils/create-link-and-download";
import { PipelineStep } from "./pipeline-step";
import { getFilteredPipelineSteps } from "./utils";
import { useApplicationContext } from "contexts/ApplicationContext";
import { Toast } from "components/toast";

export interface Pipeline {
  pipelineStage:
    | "HIGIENIZACAO"
    | "FILTRO"
    | "TELEFONIA"
    | "ENVIA_WHATS"
    | "ENVIA_DISCADORA"
    | "ENVIA_CAMPANHA_SMS"
    | "SAQUE_COMPLEMENTAR"
    | "REFIN"
    | "ENVIA_ATENDIMENTO"
    | "SIMULACAO_PORTABILIDADE"
    | "MARGEM_CONVENIO"
    | "DIGITACAO";
  pipelineStatus:
    | "FINALIZADO"
    | "EM_ANDAMENTO"
    | "NAO_INICIADO"
    | "PAUSADO"
    | "ERROR"
    | null;
  banco: string | null;
  data: string;
  pipelineItemErrorDescription?: string | null;
  pipelineTipoHigienizacao:
    | "REFIN"
    | "IN_100"
    | "SIMULACAO_PORTABILIDADE"
    | null;
  pipelineItemStatus: "CANCELADO" | "SUSPENSO" | "EM_ANDAMENTO" | null;
  higienizacaoId: string | null;
  pipelineErrorDescription: string | null;
  configJson: string;
  mailingLeadCount: number;
  higienizacaoStatusResultList?: Array<{
    total: number;
    totalCompleted: number;
    tipoHigienizacao: "REFIN" | "CONSULTA_TOP";
    finished?: boolean;
    lastError?: string;
    higienizacaoId: string;
    banco: string;
    mensagem?: string;
  }> | null;
  simulacaoStatusResult?: Array<{
    id?: number | null;
    total: number;
    totalCompleted: number;
    finished?: boolean;
    lastError?: string | null;
    totalErrors?: null | number;
    banco: string | null;
    mensagem?: string | null;
    status?: "EM_ANDAMENTO" | null;
  }> | null;
  margemConvenioStatusResultList?: {
    total: number;
    totalCompleted: number;
    totalErrors: null;
    lastError: string;
    idBanco: number;
    mensagem: null;
    status: "EM_ANDAMENTO";
  }[];
  ordem?: number | null;
  currentPipelineOrdem?: number | null;
  pipelineItemBanco: null;
}

export type PipelineStep = { title: string; key: Pipeline["pipelineStage"] };

export interface MailingOptionsAutoComplete {
  higienizacao: MailingOptionFilter;
  filters: MailingOptionFilter;
  telefonia: MailingOptionFilter;
  enviaWhats: MailingOptionFilter;
  enviaDiscadora: MailingOptionFilter;
  enviaSMS: MailingOptionFilter;
  simulacaoPortabilidade: MailingOptionFilter;
  digitacao: MailingOptionFilter;
}

export const MailingTableRowDetails = ({
  columnsLength,
  row,
  rowIndex,
}: {
  row: MailingTableRow;
  columnsLength: number;
  rowIndex: number;
}) => {
  const [higienizacaoData, setHigienizacaoData] = useState<Pipeline[]>([]);
  const checkedFields = useRef<Partial<MailingOptionsAutoComplete>>({});
  const [isDownloading, setIsDownloading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const tableRow = useRef<HTMLTableRowElement>(null);
  const internalContainer = useRef<HTMLDivElement>(null);
  const [activeStep, setActiveStep] = useState(0);
  const { user } = useApplicationContext();

  const filteredPipelineSteps = getFilteredPipelineSteps({
    modalData: higienizacaoData,
    user,
  });

  const filtroIndex = filteredPipelineSteps.findIndex(
    (i) => i.key === "FILTRO"
  );
  const isFilterDisabled =
    !higienizacaoData.find((i) => i.pipelineStage === "FILTRO") ||
    activeStep > filtroIndex;

  const onOpen = async () => {
    if (row.currentPipelineOrdem == null) return;
    setIsOpen(true);
    getData();
  };

  const onClose = () => {
    setIsOpen(false);
    setIsLoading(true);
    checkedFields.current = {};
  };

  useEffect(() => {
    if (isOpen) onClose();
  }, [row.id]);

  const onToggle = () => (isOpen ? onCloseTableRow() : onOpen());

  const onOpenTableRow = () => {
    if (internalContainer.current) {
      const children = internalContainer.current.children[0];
      const childrenHeight = children.clientHeight;
      AnimateElement(
        200,
        [0, 1],
        (progress) => {
          if (tableRow.current && internalContainer.current) {
            const rowStyle = internalContainer.current.style;
            const currentHeight = progress * childrenHeight;
            rowStyle.opacity = `${progress}`;
            rowStyle.height = `${currentHeight}px`;
          }
        },
        () => {
          if (internalContainer.current)
            internalContainer.current.style.overflow = "visible";
        }
      );
    }
  };

  const onCloseTableRow = () => {
    if (internalContainer.current) {
      const children = internalContainer.current.children[0];
      const childrenHeight = children.clientHeight;
      AnimateElement(
        200,
        [1, 0],
        (progress) => {
          if (tableRow.current && internalContainer.current) {
            const rowStyle = internalContainer.current.style;
            const currentHeight = progress * childrenHeight;
            rowStyle.opacity = `${progress}`;
            rowStyle.height = `${currentHeight}px`;
          }
        },
        onClose
      );
    }
  };

  const getData = async () => {
    setIsLoading(true);
    try {
      let { data }: { data: Pipeline[] } = await api.get(
        `/pipeline/status/mailing/${row.id}`
      );

      setHigienizacaoData(data);

      const isFinished =
        data.filter((i) => i.pipelineStatus === "FINALIZADO").length ===
        data.length;
      setActiveStep(
        isFinished ? row.currentPipelineOrdem! : row.currentPipelineOrdem! - 1
      );

      let higienizacao: MailingOptionFilter = {};
      let filters: MailingOptionFilter = {};
      let telefonia: MailingOptionFilter = {};
      let enviaWhats: MailingOptionFilter = {};
      let enviaDiscadora: MailingOptionFilter = {};
      let enviaSMS: MailingOptionFilter = {};
      let enviaAtendimento: MailingOptionFilter = {};
      let simulacaoPortabilidade: MailingOptionFilter = {};
      let digitacao: MailingOptionFilter = {};

      if (data.length) {
        data.forEach((pipeline) => {
          switch (pipeline.pipelineStage) {
            case "HIGIENIZACAO":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  higienizacao[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
              break;
            case "FILTRO":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  filters[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
              break;
            case "TELEFONIA":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  telefonia[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
              break;
            case "DIGITACAO":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  digitacao[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
              break;
            case "ENVIA_WHATS":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  enviaWhats[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
              break;
            case "ENVIA_CAMPANHA_SMS":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  enviaSMS[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
              break;
            case "ENVIA_DISCADORA":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  enviaDiscadora[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
              break;
            case "ENVIA_ATENDIMENTO":
              if (pipeline.configJson) {
                const parser = JSON.parse(pipeline.configJson);
                const keys = Object.keys(parser);
                keys.forEach((key) => {
                  const currentFilter = parser[key];
                  enviaAtendimento[key] = {
                    isChecked: true,
                    value: currentFilter,
                  };
                });
              }
              break;
            case "SIMULACAO_PORTABILIDADE":
              if (pipeline.configJson) {
                const value = JSON.parse(pipeline.configJson);
                simulacaoPortabilidade["SIMULACAO_PORTABILIDADE"] = {
                  isChecked: true,
                  value: value,
                };
              }
              break;
          }
        });
        checkedFields.current = {
          higienizacao,
          filters,
          telefonia,
          enviaWhats,
          enviaDiscadora,
          enviaSMS,
          simulacaoPortabilidade,
          digitacao,
        };
      } else onCloseTableRow();
    } catch (e: any) {
      Toast({
        title: "Erro ao obter dados mailing",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isOpen) onOpenTableRow();
  }, [isOpen]);

  useEventListener(`mailing-update-${row.id}`, getData, undefined);

  useEventListener(
    `table-row`,
    (index: number) => {
      if (index === rowIndex) onToggle();
      else onCloseTableRow();
    },
    `${rowIndex}`
  );

  return isOpen ? (
    <Tr ref={tableRow}>
      <Td colSpan={columnsLength} padding={0}>
        <Flex
          flexDir="column"
          w="100%"
          ref={internalContainer}
          overflow="hidden"
          pos="relative"
        >
          <Flex flexDir="column" p="16px" minH="300px">
            <Flex w="100%" justifyContent="flex-end">
              <Button
                mr="8px"
                leftIcon={<DownloadIcon />}
                isLoading={isDownloading}
                onClick={async () => {
                  setIsDownloading(true);
                  try {
                    const { data } = await api.get(
                      `pipeline/mailing/${row.id}/download`
                    );
                    createLinkAndDownload(new Blob([data]), `${row.name}.csv`);
                  } catch (e) {
                  } finally {
                    setIsDownloading(false);
                  }
                }}
                loadingText="Baixando"
              >
                Baixar CSV
              </Button>
              <Button
                leftIcon={<EditIcon />}
                isDisabled={isFilterDisabled}
                onClick={() => {
                  openMailingOptions({
                    ...row,
                    enabledFields:
                      checkedFields.current as MailingOptionsAutoComplete,
                  });
                  console.log(checkedFields);
                }}
              >
                Editar Filtros
              </Button>
            </Flex>
            <Flex flexDir="column" p="8px 0px 16px">
              <Center p="16px" h="calc(45px + 32px)">
                {filteredPipelineSteps.map(({ title, key }, index) => {
                  return (
                    <PipelineStep
                      activeStep={activeStep}
                      index={index}
                      pipelineStage={key}
                      modalData={higienizacaoData}
                      row={row}
                      setActiveStep={setActiveStep}
                      setIsLoading={setIsLoading}
                      title={title}
                    />
                  );
                })}
              </Center>

              {isLoading ? (
                <Center inset="0" pos="absolute" bg="#fff">
                  <Spinner />
                </Center>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
      </Td>
    </Tr>
  ) : null;
};
