// import { Link } from 'react-router-dom';
import { Box, Flex, Text } from "@chakra-ui/react";
import { useApplicationContext } from "contexts/ApplicationContext";
import { ProfileMenu } from "./profile-menu";
import { getNavbarLogo } from "components/white-label";
import { useRouter } from "routes/router-control/use-router";
import { memo } from "react";
import { UserTypes } from "contexts/types/application-context-types";
import { parseUserType } from "utils/util";

export interface NavbarProps {
  withOutSearch?: boolean;
  HeadRender?: () => JSX.Element | null;
}

export const navbarWidth = "80px";

function Navbar({ HeadRender }: NavbarProps) {
  const { user, menuErrorMessage } = useApplicationContext();
  const { userData } = user;
  const router = useRouter();

  return (
    <Flex
      w="100%"
      h={navbarWidth}
      bg="#fff"
      justifyContent="space-between"
      alignItems="center"
      px="2rem"
      borderBottom="1px solid #E8EAED"
    >
      <Flex alignItems="center">
        <Box
          mr="70px"
          // onClick={() => {
          //   if (!menuErrorMessage) router.push("/");
          // }}
          cursor="pointer"
        >
          {getNavbarLogo()}
        </Box>
      </Flex>
      <Flex alignItems="center">
        {HeadRender ? <HeadRender /> : null}
        <Box color="custom-gray">
          <Text mr="10px" mb="4px" fontWeight="400" lineHeight="1.2">
            {userData.name}{" "}
            <Text as="span" textTransform="capitalize">
              ({parseUserType.get(userData.type!)})
            </Text>
          </Text>
          <Text mr="10px" fontWeight="800" lineHeight="1.2">
            {userData.customerName}
          </Text>
        </Box>
        <ProfileMenu />
      </Flex>
    </Flex>
  );
}

export default memo(Navbar);
