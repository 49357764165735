import { CustomModal } from "components/custom-modal";
import { ConsultaProps } from "../consulta-fields/types";
import { CSSProperties, MouseEvent, useState } from "react";
import { makeEvent, useEventListener } from "services/events";
import { Button } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { currencyMask } from "utils/string-formats";
import { MdLocalPrintshop } from "react-icons/md";
import { printDiv } from "utils/print-div";
import { StylesPDF } from "../consulta-pdf-modal/utils";

export function ConsultaDetalhamentoModal({
  consult,
}: {
  consult: ConsultaProps;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  useEventListener("openConsultaDetalhamentoModal", onOpen);

  const onPrint = (e: MouseEvent<HTMLButtonElement>) => {
    // window.print();
    printDiv("print-container");
  };
  const modalFooter = (
    <>
      <Button onClick={onPrint} leftIcon={<MdLocalPrintshop size="20px" />}>
        Imprimir
      </Button>
      <Button
        onClick={onClose}
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
      >
        Fechar
      </Button>
    </>
  );

  const debitos = [
    ...[
      ...(consult?.beneficio.contratosRcc || []),
      ...(consult?.beneficio.contratosCartao || []),
    ].map(({ valorReservado }) => valorReservado),
    ...(consult?.beneficio.contratosEmprestimo || []).map(
      ({ valorParcela }) => valorParcela
    ),
  ];

  const somaDebitos = debitos.reduce(function (accumulator, value) {
    accumulator = accumulator + value;
    return accumulator;
  }, 0);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Detalhamento da Consulta"
      size="5xl"
      modalFooter={modalFooter}
      scroll="inside"
      modalFooterProps={{ className: "print-display-none" }}
      modalCloseButtonProps={{ className: "print-display-none" }}
      modalHeaderProps={{ className: "print-display-none" }}
      modalBodyProps={{ className: "padding-none" }}
      sx={{
        "@media print": {
          ".print-display-none": { display: "none" },
          ".padding-none": { padding: "0px 0px 0px 0px!important" },
        },
      }}
    >
      <Box id="print-container">
        <StylesPDF />
        <Text
          fontWeight="700"
          fontSize="22px"
          textAlign="center"
          marginBottom="15px"
        >
          Extrato de Pagamentos
        </Text>
        <Text
          fontWeight="700"
          fontSize="18px"
          textAlign="center"
          marginBottom="15px"
        >
          Detalhamento de Crédito
        </Text>

        <Flex marginBottom="10px" width="100%" justifyContent="space-between">
          <Box padding="0rem 1.5rem">
            <Text fontWeight="700" fontSize="14px">
              Número do Benefício:
            </Text>
            <Text>{consult?.beneficio.beneficio}</Text>
          </Box>
          <Box padding="0rem 1.5rem">
            <Text fontWeight="700" fontSize="14px" textAlign="start">
              Nome do Beneficiário:
            </Text>
            <Text width="100%">{consult?.beneficio.nome}</Text>
          </Box>
        </Flex>
        <Flex justifyContent="space-between" marginBottom="10px">
          <Box padding="0rem 1.5rem">
            <Text fontWeight="700" fontSize="14px">
              Competência:
            </Text>
            <Text>{consult.beneficio.margem.competencia}</Text>
          </Box>

          <Box padding="0rem 1.5rem">
            <Text fontWeight="700" fontSize="14px">
              Pagamento através de:{" "}
            </Text>
            <Text>{consult.beneficio.dadosBancarios.meioPagamento.tipo}</Text>
          </Box>
        </Flex>
        <Flex justifyContent="space-between" marginBottom="10px">
          <Box padding="0rem 1.5rem">
            <Text fontWeight="700" fontSize="14px">
              Espécie:{" "}
            </Text>
            <Text>{`${consult.beneficio.especie.codigo} - ${consult.beneficio.especie.descricao}`}</Text>
          </Box>
        </Flex>
        <Flex justifyContent="space-between" marginBottom="10px">
          <Box padding="0rem 1.5rem">
            <Text fontWeight="700" fontSize="14px">
              Banco:{" "}
            </Text>
            <Text>{`${consult.beneficio.dadosBancarios.banco.codigo} - ${consult.beneficio.dadosBancarios.banco.nome}`}</Text>
          </Box>
          <Box padding="0rem 1.5rem">
            <Text fontWeight="700" fontSize="14px">
              Agência bancária:
            </Text>
            <Text>{consult.beneficio.dadosBancarios.agencia.codigo}</Text>
          </Box>
          <Box padding="0rem 1.5rem">
            <Text fontWeight="700" fontSize="14px">
              Número da conta:{" "}
            </Text>
            <Text>{consult.beneficio.dadosBancarios.meioPagamento.numero}</Text>
          </Box>
        </Flex>

        <TableContainer marginTop="15px">
          <Table width="100%">
            <Tbody width="100%">
              <Tr>
                <Td padding="0.8rem 1.5rem">
                  <Text fontSize="16px" fontWeight="700">
                    Créditos
                  </Text>
                </Td>
                <Td></Td>
              </Tr>
              <Tr>
                <Td>Mens. Reajustada</Td>
                <Td>
                  R$ {currencyMask(`${consult?.beneficio.valorBeneficio}`)}
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text fontSize="16px" fontWeight="700">
                    Débitos
                  </Text>
                </Td>
                <Td></Td>
              </Tr>
              {consult?.beneficio.contratosEmprestimo?.map((row, i) => (
                <Tr key={i + "consig"}>
                  <Td>Consig. Emprest</Td>
                  <Td>R$ {currencyMask(row.valorParcela.toFixed(2))}</Td>
                </Tr>
              ))}
              {consult?.beneficio.contratosRcc?.map((row, i) => (
                <Tr key={i + "consig"}>
                  <Td>Contrato Cartão RCC</Td>
                  <Td>R$ {currencyMask(row.valorReservado.toFixed(2))}</Td>
                </Tr>
              ))}
              {consult?.beneficio.contratosCartao?.map((row, i) => (
                <Tr key={i + "consig"}>
                  <Td>Contrato Cartão RMC</Td>
                  <Td>R$ {currencyMask(row.valorReservado.toFixed(2))}</Td>
                </Tr>
              ))}
            </Tbody>
          </Table>
        </TableContainer>
        <Grid
          gridTemplateColumns="1fr 1fr 1fr 1fr"
          gap="10px"
          padding="1.5rem 1.5rem 1.5rem 1.5rem"
        >
          <Box>
            <Text marginBottom="8px" fontWeight="700">
              Valor Bruto
            </Text>
            R$ {currencyMask(`${consult?.beneficio.valorBeneficio.toFixed(2)}`)}
          </Box>
          <Box>
            <Text marginBottom="8px" fontWeight="700">
              Valor Base Cálculo
            </Text>
            R$ {currencyMask(consult?.beneficio.valorBeneficio.toFixed(2))}
          </Box>
          <Box>
            <Text marginBottom="8px" fontWeight="700">
              Valor dos Descontos
            </Text>
            R$ {currencyMask(somaDebitos.toFixed(2))}
          </Box>
          <Box>
            <Text marginBottom="8px" fontWeight="700">
              Valor Líquido
            </Text>
            R${" "}
            {currencyMask(
              (consult?.beneficio.valorBeneficio - somaDebitos).toFixed(2)
            )}
          </Box>
        </Grid>
      </Box>
    </CustomModal>
  );
}

export const openConsultaDetalhamentoModal = () =>
  makeEvent("openConsultaDetalhamentoModal");

function Text({
  children,
  className,
  ...rest
}: { children?: any; className?: string } & CSSProperties) {
  return (
    <p className={className} style={{ ...rest }}>
      {children}
    </p>
  );
}

function Box({
  children,
  id,
  className,
  ...rest
}: { children?: any; id?: string; className?: string } & CSSProperties) {
  return (
    <div id={id} className={className} style={{ ...rest }}>
      {children}
    </div>
  );
}

function Grid({
  children,
  id,
  className,
  ...rest
}: { children?: any; id?: string; className?: string } & CSSProperties) {
  return (
    <div id={id} className={className} style={{ display: "grid", ...rest }}>
      {children}
    </div>
  );
}

function Flex({ children, ...rest }: { children?: any } & CSSProperties) {
  return <div style={{ display: "flex", ...rest }}>{children}</div>;
}

function TableContainer({
  children,
  ...rest
}: { children?: any } & CSSProperties) {
  return <div style={{ ...rest }}>{children}</div>;
}

function Table({ children, ...rest }: { children?: any } & CSSProperties) {
  return <table style={{ ...rest }}>{children}</table>;
}

function Tbody({ children, ...rest }: { children?: any } & CSSProperties) {
  return <tbody style={{ ...rest }}>{children}</tbody>;
}

function Tr({ children, ...rest }: { children?: any } & CSSProperties) {
  return <tr style={{ ...rest }}>{children}</tr>;
}

function Td({ children, ...rest }: { children?: any } & CSSProperties) {
  return (
    <td
      style={{
        padding: "0.8rem 1.5rem",
        borderBottom: "1px solid #EDF2F7",
        ...rest,
      }}
    >
      {children}
    </td>
  );
}
