import { isObject, objectSchema } from "utils/object-methods";
import { MailingOptionFilter, Stage } from ".";
import { Dispatch, SetStateAction } from "react";
import { MargemConvenioMailingProps } from "./parts/margem-convenio";
import { initialDigitacaoPipelineFilterValues } from "./variables";

interface GTELTE {
  gte: string;
  lte: string;
}

interface MailingFieldFormat {
  stage: Stage["stage"];
  configJson: any; // Geralmente { [key:string]: any (value) }
}

const valitadeGTELTERangeNumber =
  ({
    stage,
    setConfigJson,
  }: {
    stage: Stage["stage"];
    setConfigJson?: (v: any) => any;
  }) =>
  (
    { isChecked, value }: { isChecked: boolean; value: GTELTE },
    fullObj: any,
    currentKey: string
  ) => {
    if (isChecked) {
      const { gte, lte } = value;
      if (!`${gte}` && !`${lte}`) {
        return { valid: false, message: "preencha o campo", format: null };
      }
      if (!`${gte}`) {
        return {
          valid: false,
          message: "gte:preecha o campo;lte:",
          format: null,
        };
      } else if (!`${lte}`) {
        return {
          valid: false,
          message: "gte:;lte:preencha o campo",
          format: null,
        };
      } else if (Number(gte) <= Number(lte)) {
        // SUCCESS
        const configJson = setConfigJson
          ? setConfigJson(value)
          : { [currentKey]: value };
        const format: MailingFieldFormat = { stage, configJson };
        return { valid: true, format };
      } else {
        return { valid: false, message: "intervalo incorreto", format: null };
      }
    } else {
      return { valid: true, format: null };
    }
  };

const valitadeGTELTERangeDate =
  ({
    stage,
    setConfigJson,
  }: {
    stage: Stage["stage"];
    setConfigJson?: (v: any) => any;
  }) =>
  (
    { isChecked, value }: { isChecked: boolean; value: GTELTE },
    fullObj: any,
    currentKey: string
  ) => {
    if (isChecked) {
      const { gte, lte } = value;
      if (!`${gte}` && !`${lte}`) {
        return { valid: false, message: "preencha o campo", format: null };
      }
      if (!`${gte}`) {
        return {
          valid: false,
          message: "gte:preecha o campo;lte:",
          format: null,
        };
      } else if (!`${lte}`) {
        return {
          valid: false,
          message: "gte:;lte:preencha o campo",
          format: null,
        };
      } else if (new Date(gte).getTime() <= new Date(lte).getTime()) {
        // SUCCESS
        const configJson = setConfigJson
          ? setConfigJson(value)
          : { [currentKey]: value };
        const format: MailingFieldFormat = { stage, configJson };
        return { valid: true, format };
      } else {
        return { valid: false, message: "intervalo incorreto", format: null };
      }
    } else {
      return { valid: true, format: null };
    }
  };

const validateBoolean =
  ({
    stage,
    setConfigJson,
  }: {
    stage: Stage["stage"];
    setConfigJson?: (v: any) => any;
  }) =>
  (
    { isChecked, value }: { isChecked: boolean; value: boolean },
    fullObj: any,
    currentKey: string
  ) => {
    if (isChecked) {
      if (value === true || value === false) {
        const configJson = setConfigJson
          ? setConfigJson(value)
          : { [currentKey]: value };
        const format: MailingFieldFormat = { stage, configJson };
        return { valid: true, format };
      } else {
        return { valid: false, message: "Preencha o campo", format: null };
      }
    } else {
      return { valid: true, format: null };
    }
  };

const refinRule = ({ allFields }: { allFields: MailingOptionFilter }) => {
  const refinValue: { banco: string; idConvenio?: number }[] =
    allFields.REFIN?.value ?? [];
  const isConvenioEnabled = refinValue
    .map(({ banco }) => banco)
    .includes("PAN");
  if (
    isConvenioEnabled &&
    !refinValue.find((curr) => curr.banco === "PAN")?.idConvenio
  )
    return { isValid: false, errorMessage: "preencha o campo convênio" };
  else return { isValid: true };
};

const saqueComplementarRule = ({
  allFields,
}: {
  allFields: MailingOptionFilter;
}) => {
  const saqueValue: { banco: string; idConvenio?: number }[] =
    allFields.SAQUE_COMPLEMENTAR?.value ?? [];
  if (!saqueValue.find((curr) => !!curr.banco)?.banco) {
    return { isValid: false, errorMessage: "preencha o banco" };
  } else if (!saqueValue.find((curr) => !!curr.banco)?.idConvenio)
    return { isValid: false, errorMessage: "preencha o convênio" };
  else return { isValid: true };
};

const validateArray =
  ({
    stage,
    aditionalRule,
    setConfigJson,
  }: {
    stage: Stage["stage"];
    aditionalRule?: { isValid: boolean; errorMessage?: string };
    setConfigJson?: (v: any) => any;
  }) =>
  (
    { isChecked, value }: { isChecked: boolean; value: any[] },
    fullObj: any,
    currentKey: string
  ) => {
    if (isChecked) {
      if (Array.isArray(value) && value.length) {
        const { isValid, errorMessage } = aditionalRule || {};
        if (aditionalRule == null || isValid) {
          const configJson = setConfigJson
            ? setConfigJson(value)
            : { [currentKey]: value };
          const format: MailingFieldFormat = { stage, configJson };
          return { valid: true, format };
        } else {
          return {
            valid: false,
            message: errorMessage ?? "preencha o campo",
            format: null,
          };
        }
      } else {
        return {
          valid: false,
          message: "preencha o campo",
          format: null,
        };
      }
    } else {
      return { valid: true, format: null };
    }
  };

const validateString =
  ({
    stage,
    setConfigJson,
  }: {
    stage: Stage["stage"];
    setConfigJson?: (v: any) => any;
  }) =>
  (
    { isChecked, value }: { isChecked: boolean; value: string },
    fullObj: any,
    currentKey: string
  ) => {
    if (isChecked) {
      if (typeof value === "string" && value.length) {
        const configJson = setConfigJson
          ? setConfigJson(value)
          : { [currentKey]: value };
        const format: MailingFieldFormat = { stage, configJson };
        return { valid: true, format };
      } else {
        return {
          valid: false,
          message: "preencha o campo",
          format: null,
        };
      }
    } else {
      return { valid: true, format: null };
    }
  };
const validateSimulacaoAumento =
  ({
    stage,
    setConfigJson,
  }: {
    stage: Stage["stage"];
    setConfigJson?: (v: any) => any;
  }) =>
  (
    {
      isChecked,
      value,
    }: { isChecked: boolean; value: { coeficiente: number } },
    fullObj: any,
    currentKey: string
  ) => {
    if (isChecked) {
      if (value?.coeficiente) {
        const configJson = setConfigJson
          ? setConfigJson(value)
          : { [currentKey]: value };
        const format: MailingFieldFormat = { stage, configJson };
        return { valid: true, format };
      } else {
        return {
          valid: false,
          message: "preencha o campo",
          format: null,
        };
      }
    } else {
      return { valid: true, format: null };
    }
  };

const validateMargemConvenios =
  ({
    stage,
    setConfigJson,
  }: {
    stage: Stage["stage"];
    setConfigJson?: (v: any) => any;
  }) =>
  (
    {
      isChecked,
      value,
    }: { isChecked: boolean; value: MargemConvenioMailingProps },
    fullObj: any,
    currentKey: string
  ) => {
    if (isChecked) {
      if (
        !value.convenio &&
        !(typeof value.idBanco === "number") &&
        !(typeof value.idSubConvenio === "number")
      )
        return { valid: false, message: "preencha o campo" };
      if (!value.convenio)
        return { valid: false, message: "preencha o campo convênio" };
      if (!value.idSubConvenio)
        return { valid: false, message: "preencha o campo subconvênio" };
      if (!value.idBanco && value.convenio !== 4 && value.convenio !== 5)
        return { valid: false, message: "preencha o campo banco" };

      const configJson = setConfigJson
        ? setConfigJson(value)
        : { [currentKey]: value };
      const format: MailingFieldFormat = { stage, configJson };
      return { valid: true, format };
    } else {
      return { valid: true, format: null };
    }
  };

export const validateMailingOptions = (
  allFields: MailingOptionFilter,
  id: number
) => {
  // Schema & rules
  const { isValid, errors, body } = objectSchema(
    {
      //
      // HIGIENIZACAO ORDEM 1
      //
      REFIN: validateArray({
        stage: "HIGIENIZACAO",
        aditionalRule: refinRule({ allFields }),
      }),
      SAQUE_COMPLEMENTAR: validateArray({
        stage: "HIGIENIZACAO",
        aditionalRule: saqueComplementarRule({ allFields }),
      }),
      MARGEM_CONVENIO: validateMargemConvenios({ stage: "HIGIENIZACAO" }),
      SIMULACAO_AUMENTO: validateSimulacaoAumento({ stage: "HIGIENIZACAO" }),
      //
      // SIMULACAO PORTABILIDADE ORDEM 2
      //
      SIMULACAO_PORTABILIDADE: validateArray({
        stage: "SIMULACAO_PORTABILIDADE",
        setConfigJson: (v) => v,
      }),
      //
      // FILTROS ORDEM 3
      //
      idade: valitadeGTELTERangeNumber({ stage: "FILTRO" }),
      banco_operacao: validateArray({ stage: "FILTRO" }),
      saldo_devedor: valitadeGTELTERangeNumber({ stage: "FILTRO" }),
      taxa_contrato: valitadeGTELTERangeNumber({ stage: "FILTRO" }),
      valor_parcela: valitadeGTELTERangeNumber({ stage: "FILTRO" }),
      valor_liberado: valitadeGTELTERangeNumber({ stage: "FILTRO" }),
      quantidade_contratos: valitadeGTELTERangeNumber({ stage: "FILTRO" }),
      numero_parcelas_pagas: valitadeGTELTERangeNumber({ stage: "FILTRO" }),
      valor_antecipacao: validateString({ stage: "FILTRO" }),
      valor_liberado_saque_rcc: valitadeGTELTERangeNumber({ stage: "FILTRO" }),
      valor_liberado_saque_rmc: valitadeGTELTERangeNumber({ stage: "FILTRO" }),
      margem_aumento: valitadeGTELTERangeNumber({ stage: "FILTRO" }),
      contratos_enquadrados: validateBoolean({ stage: "FILTRO" }),
      especies: validateArray({ stage: "FILTRO" }),
      possui_representante_legal: validateBoolean({ stage: "FILTRO" }),
      meio_pagamento: validateString({ stage: "FILTRO" }),
      valor_beneficio: valitadeGTELTERangeNumber({ stage: "FILTRO" }),
      ddb: valitadeGTELTERangeDate({
        stage: "FILTRO",
        setConfigJson: ({ gte, lte }: { gte: string; lte: string }) => ({
          ddb: {
            gte: gte.split("-").reverse().join("/"),
            lte: lte.split("-").reverse().join("/"),
          },
        }),
      }),
      //
      // TELEFONIA ORDEM 4
      //
      telefoniaApi: validateBoolean({
        stage: "TELEFONIA",
        setConfigJson: () => ({ api: "API_TELEFONIA" }),
      }),
      //
      // DIGITACAO ORDEM 5
      //
      banco: validateString({ stage: "DIGITACAO" }),
      usuarioDigitacao: validateString({ stage: "DIGITACAO" }),
      usuarioDigitacaoSenha: validateString({ stage: "DIGITACAO" }),
      cpfUsuarioCertificado: validateString({ stage: "DIGITACAO" }),
      tabela: validateString({ stage: "DIGITACAO" }),
      //
      // ENVIAR PARA CAMPANHA ORDEM 6
      //
      enviarParaCampanhaWhatsApp: validateBoolean({
        stage: "ENVIA_WHATS",
        setConfigJson: () => ({}),
      }),
      enviarParaDiscadora: validateBoolean({
        stage: "ENVIA_DISCADORA",
        setConfigJson: () => ({}),
      }),
      enviarParaCampanhaSMS: validateBoolean({
        stage: "ENVIA_CAMPANHA_SMS",
        setConfigJson: () => ({}),
      }),
      enviaAtendimento: validateBoolean({
        stage: "ENVIA_ATENDIMENTO",
        setConfigJson: () => ({}),
      }),
    },
    allFields
  );

  // TODO: Organize stage titles in a object and set data
  let stagesBox: { [k: string]: { [k: string]: any } } = {};

  Object.keys(body).forEach((key) => {
    const current: MailingFieldFormat | null = body[key];
    if (current != null) {
      if (isObject(current.configJson)) {
        stagesBox[current.stage] = {
          ...stagesBox[current.stage],
          ...current.configJson,
        };
      } else {
        stagesBox[current.stage] = current.configJson;
      }
    }
  });

  // Final body structure
  let formatedBody: { mailingId: number; pipelines: Stage[] } = {
    mailingId: id,
    pipelines: [],
  };

  // TODO: set the stages in stage array
  Object.keys(stagesBox).forEach((stageEnum, index) => {
    const stageData = stagesBox[stageEnum];

    let configJson = stageData;

    formatedBody.pipelines.push({
      stage: stageEnum as Stage["stage"],
      configJson,
      ordem: index + 1,
    });
  });

  return { isValid, errors, formatedBody };
};

// MODAL CHANGE RULES
export const booleanRule = (stateValues: MailingOptionFilter) => {
  Object.keys(stateValues).forEach((k) => {
    const { isChecked, value } = stateValues[k];
    if (typeof value === "boolean") stateValues[k].value = isChecked;
  });
  return stateValues;
};
export const digitacaoRule = (stateValues: MailingOptionFilter) => {
  const isBancoChecked = stateValues.banco.isChecked;
  const bancoValue = stateValues.banco.value;

  stateValues = {
    ...stateValues,
    usuarioDigitacao: {
      ...stateValues.usuarioDigitacao,
      isChecked: isBancoChecked,
    },
    tabela: { ...stateValues.tabela, isChecked: isBancoChecked },
    usuarioDigitacaoSenha: {
      ...stateValues.usuarioDigitacaoSenha,
      isChecked: ["C6", "BMG"].includes(bancoValue) && isBancoChecked,
    },
    cpfUsuarioCertificado: {
      ...stateValues.cpfUsuarioCertificado,
      isChecked: ["C6"].includes(bancoValue) && isBancoChecked,
    },
  };

  if (!isBancoChecked) stateValues = initialDigitacaoPipelineFilterValues;

  return stateValues;
};

export const simulacaoPortabilidadeRule =
  (setAddedFilters: Dispatch<SetStateAction<MailingOptionFilter>>) =>
  (stateSimulacaoValues: MailingOptionFilter) => {
    if (!stateSimulacaoValues["SIMULACAO_PORTABILIDADE"].isChecked) {
      setAddedFilters((addedFilters) => ({
        ...addedFilters,
        contratos_enquadrados: {
          isChecked: false,
          value: false,
        },
      }));
    }
    return stateSimulacaoValues;
  };

export const higienizacaoRadioRule =
  ({ field }: { field: string }) =>
  (stateValues: MailingOptionFilter) => {
    Object.keys(stateValues)
      .filter((key) => key !== field)
      .forEach((key) => {
        if (stateValues[key]) {
          stateValues[key].isChecked = false;
        }
      });
    return stateValues;
  };
