export function calculateParcela(
  valorEmprestimo: number,
  taxaJuros: number | undefined,
  prazoMeses: number | undefined
) {
  if (!valorEmprestimo || !taxaJuros || !prazoMeses) {
    return undefined;
  }
  let taxaDecimal = taxaJuros / 100;
  let parcela =
    (valorEmprestimo * taxaDecimal) /
    (1 - Math.pow(1 + taxaDecimal, -prazoMeses));
  return Math.floor(parcela * 100) / 100;
}

export function calculateValorEmprestimo(
  valorParcela: number,
  taxaJuros: number | undefined,
  prazoMeses: number | undefined
) {
  if (!valorParcela || !taxaJuros || !prazoMeses) {
    return undefined;
  }
  let taxaDecimal = taxaJuros / 100;
  let valorEmprestimo =
    valorParcela / (taxaDecimal / (1 - Math.pow(1 + taxaDecimal, -prazoMeses)));
  return Math.floor(valorEmprestimo * 100) / 100;
}
