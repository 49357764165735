import axios, { AxiosError, AxiosRequestConfig } from "axios";
import { getToken, removeToken } from "Session";

const apiHigienizacao = axios.create({
  baseURL: process.env.REACT_APP_PUBLIC_HIGIENIZACAO,
  headers: { "Content-Type": "application/json" },
});

apiHigienizacao.interceptors.request.use((config: AxiosRequestConfig) => {
  if (config.url !== "/usuarios/login") {
    const token = getToken();
    if (token) {
      if (config.headers) config.headers.Authorization = `Basic ${token}`;
    }
  }
  return config;
});

apiHigienizacao.interceptors.response.use(
  (res: AxiosRequestConfig) => {
    return res;
  },
  (error: AxiosError) => {
    if (
      error.config.url !== "/usuarios/login" &&
      401 === error.response?.status
    ) {
      alert("Sem autorização de acesso!");
      removeToken();
      window.location.href = "/login";
      return Promise.reject(error);
    } else if (
      error.config.url !== "/usuarios/login" &&
      403 === error.response?.status
    ) {
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

export default apiHigienizacao;
