import { Icon, IconProps } from "@chakra-ui/react";

export function W2Promotora({ ...rest }: IconProps) {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 1373 230"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M342.176 227.587C297.374 226.736 259.358 226.033 257.699 226.033L254.72 226.012L254.21 223.096C253.614 219.479 253.401 213.371 253.763 209.689C254.89 198.346 259.294 187.045 267.293 174.936C272.739 166.7 279.334 158.783 288.46 149.526C298.714 139.119 308.543 130.414 327.604 114.815C340.836 103.983 351.771 94.7889 356.217 90.7666C368.705 79.4234 373.938 71.6769 373.13 65.718C371.364 52.9064 339.709 48.0542 269.208 49.7993C257.337 50.0972 243.062 50.6505 242.913 50.8208C242.85 50.9059 236.701 90.5538 229.256 138.948C221.81 187.343 215.683 226.948 215.662 226.97C215.555 227.055 162.711 224.373 162.648 224.288C162.563 224.203 90.2319 2.46867 90.2744 2.4261C90.317 2.36226 137.885 2.95815 137.949 3.02199C137.991 3.04327 147.416 28.7942 158.903 60.2273C170.391 91.6604 179.879 117.518 179.964 117.688C180.092 117.901 180.177 117.794 180.262 117.262C180.326 116.858 182.496 97.3214 185.07 73.8476L189.772 31.1777L190.729 28.0919C192.41 22.7714 194.069 19.2174 196.664 15.3228C200.706 9.25754 205.961 5.61837 213.151 3.89454C214.662 3.53275 218.597 3.51147 290.694 3.46891L366.641 3.40506L370.151 3.95839C385.702 6.42707 397.914 11.2793 407.806 18.9407C410.401 20.9625 415.379 25.9211 417.464 28.5601C421.102 33.1782 425.059 40.2438 427.144 45.7983C431.398 57.1202 433.845 72.3579 432.909 81.8496C431.866 92.6181 428.016 102.77 420.932 113.325C407.976 132.628 384.298 152.015 350.792 170.786C347.686 172.51 345.006 174.063 344.836 174.234C344.58 174.468 344.644 174.51 345.261 174.51C347.814 174.532 427.442 177.681 427.527 177.766C427.59 177.83 427.293 189.024 426.867 202.645C426.442 216.265 426.101 227.8 426.101 228.311V229.204L424.889 229.183C424.208 229.162 387 228.459 342.176 227.587Z"
        fill="#8B1314"
      />
      <path
        d="M97.6779 224.842C85.1264 224.565 74.8299 224.331 74.7874 224.288C74.5959 224.033 0.286812 0.851271 0.39318 0.851271C1.11649 0.787425 51.599 0.276664 51.6416 0.319227C51.7479 0.425636 127.631 224.969 127.631 225.203C127.631 225.437 124.376 225.395 97.6779 224.842Z"
        fill="#8B1314"
      />
      <path
        d="M162.541 34.8382C156.521 18.1959 151.373 4.00095 151.117 3.29866L150.649 2.00047L164.924 1.55355C172.795 1.31946 179.241 1.12792 179.263 1.1492C179.348 1.21305 173.646 64.9306 173.561 65.0157C173.519 65.0583 168.562 51.4805 162.541 34.8382Z"
        fill="#8B1314"
      />
      <path
        d="M767.324 177.793C756.647 176.906 747.699 172.717 740.755 165.365C734.222 158.403 730.681 149.914 729.309 137.736C728.842 133.852 728.925 124.281 729.419 120.259C731.422 104.114 738.065 93.2672 750.087 86.4707C760.681 80.4787 775.613 79.2859 787.36 83.5025C793.701 85.7772 797.955 88.4958 802.758 93.3782C809.401 100.119 813.271 108.913 814.698 120.536C814.863 121.923 815 125.724 815 128.997C814.973 143.117 812.475 153.16 807.04 160.899C805.229 163.479 801.468 167.335 798.751 169.416C796.144 171.385 789.886 174.659 786.592 175.741C780.581 177.738 774.515 178.376 767.324 177.793ZM777.424 164.755C782.804 163.646 786.702 161.454 790.654 157.237C794.991 152.632 797.214 147.611 798.669 139.123C799.327 135.211 799.355 123.338 798.696 119.565C797.296 111.604 794.689 106 790.242 101.506C785.823 97.04 780.993 94.682 774.927 94.0717C771.825 93.7388 767.708 94.0162 764.908 94.7652C762.548 95.3755 758.595 97.3174 756.592 98.8431C748.769 104.835 745.146 113.657 744.762 127.472C744.35 142.895 747.809 153.049 755.713 159.623C761.532 164.478 769.574 166.392 777.424 164.755Z"
        fill="#8B1314"
      />
      <path
        d="M1016.32 177.793C1005.65 176.906 996.699 172.717 989.782 165.365C983.222 158.43 979.681 149.886 978.309 137.736C977.842 133.88 977.925 124.587 978.419 120.398C980.313 104.308 987.037 93.2672 999.087 86.4707C1009.68 80.4787 1024.61 79.2859 1036.36 83.5025C1042.67 85.7495 1046.96 88.4958 1051.73 93.3504C1058.4 100.147 1062.24 108.885 1063.7 120.536C1063.86 121.923 1064 125.724 1064 128.997C1063.97 143.09 1061.47 153.16 1056.04 160.899C1054.23 163.479 1050.47 167.335 1047.75 169.416C1045.14 171.385 1038.89 174.659 1035.59 175.741C1029.58 177.738 1023.51 178.376 1016.32 177.793ZM1026.42 164.755C1031.83 163.646 1035.67 161.482 1039.6 157.293C1043.99 152.632 1046.21 147.639 1047.67 139.123C1048.33 135.211 1048.35 123.338 1047.7 119.565C1046.3 111.604 1043.69 106 1039.24 101.506C1034.82 97.04 1029.99 94.682 1023.93 94.0717C1020.83 93.7388 1016.71 94.0162 1013.91 94.7652C1011.55 95.3755 1007.6 97.3174 1005.59 98.8431C997.769 104.835 994.146 113.657 993.762 127.472C993.35 142.951 996.781 152.993 1004.8 159.706C1010.48 164.478 1018.63 166.364 1026.42 164.755Z"
        fill="#8B1314"
      />
      <path
        d="M1165.88 177.78C1154.94 176.892 1146.03 172.76 1138.96 165.355C1131.63 157.645 1127.94 147.883 1127.1 134.072C1126.85 129.912 1127.08 122.84 1127.52 119.567C1129.74 103.815 1136.51 93.0266 1148.25 86.5093C1160.46 79.7147 1177.06 79.16 1190.1 85.0949C1198.74 89.0331 1206.4 97.1034 1210.17 106.255C1212.89 112.911 1214 119.512 1214 128.997C1213.97 145.831 1210.48 156.702 1202.37 165.105C1199.04 168.572 1195.93 170.818 1191.35 173.093C1182.97 177.225 1175.64 178.584 1165.88 177.78ZM1175.89 164.8C1179.36 164.079 1181.92 163.053 1184.5 161.389C1190.3 157.59 1194.43 151.71 1196.35 144.472C1197.71 139.286 1197.99 136.845 1197.99 129.274C1197.99 121.592 1197.6 118.375 1196.07 113.522C1193.96 106.838 1190.02 101.43 1184.72 97.9632C1180.47 95.2176 1176.14 93.9973 1170.54 93.9973C1165.07 93.9973 1161.13 95.0234 1156.58 97.7136C1154.16 99.128 1149.42 104.009 1147.95 106.588C1145.7 110.61 1144.28 114.964 1143.42 120.399C1142.92 123.561 1142.95 135.542 1143.42 138.703C1144.28 144.167 1145.95 149.27 1148.03 152.709C1150 156.009 1153.5 159.531 1156.91 161.666C1161.93 164.856 1169.57 166.104 1175.89 164.8Z"
        fill="#8B1314"
      />
      <path
        d="M1314.96 177.947C1309.03 177.364 1305.07 176.365 1301.31 174.506C1295.1 171.454 1290.68 166.709 1288.59 160.855C1285.72 152.753 1286.78 143.902 1291.46 137.02C1294.74 132.193 1299.53 128.641 1306.05 126.199C1310.06 124.701 1313.62 123.98 1323.65 122.62C1337.3 120.789 1344.54 119.457 1349.88 117.82C1352.45 117.043 1352.5 116.904 1352.31 112.076C1352 105.722 1350.69 101.976 1347.82 99.3683C1343.67 95.5947 1338.13 93.9577 1329.44 93.9577C1315.52 93.9577 1309.39 97.8699 1305.74 109.107C1305.21 110.772 1304.82 111.521 1304.52 111.521C1303.88 111.521 1289.92 109.551 1289.81 109.468C1289.67 109.302 1290.56 106.055 1291.43 103.558C1292.49 100.534 1294.71 96.2884 1296.47 93.9577C1302.68 85.7447 1315.91 81 1332.51 81C1343.45 81.0277 1352.2 83.1642 1358.49 87.4095C1363.53 90.7946 1366.96 97.1208 1367.74 104.585C1367.93 106.333 1368.15 118.153 1368.29 133.857C1368.54 162.325 1368.63 163.907 1370.1 169.068C1370.52 170.455 1371.33 172.619 1371.91 173.896L1373 176.171H1364.84H1356.68L1356.1 174.784C1355.29 172.869 1354.45 169.817 1353.98 167.07C1353.78 165.794 1353.53 164.684 1353.45 164.628C1353.37 164.545 1352.31 165.239 1351.11 166.21C1343.09 172.564 1334.68 176.393 1326.02 177.558C1323.76 177.863 1316.66 178.113 1314.96 177.947ZM1326.94 165.627C1336.21 164.573 1343.87 160.272 1348.13 153.668C1350.92 149.395 1351.75 145.816 1352.17 136.271C1352.34 132.553 1352.42 129.473 1352.36 129.418C1352.31 129.362 1350.55 129.862 1348.46 130.528C1343.37 132.165 1337.21 133.497 1327.66 134.995C1314.77 137.048 1311.34 138.13 1308.03 141.21C1304.35 144.651 1302.93 149.479 1304.1 154.473C1306.08 162.769 1314.71 166.987 1326.94 165.627Z"
        fill="#8B1314"
      />
      <path
        d="M1101.17 176.717C1093.46 175.365 1088.47 171.225 1086.95 164.96C1085.92 160.792 1085.87 158.722 1085.73 126.679L1085.62 95.1321H1079.79H1074V89.1982V83.2642H1079.82H1085.64L1085.7 71.7551L1085.78 60.2735L1093.49 55.6368L1101.17 51V67.1183V83.2642H1109.07H1116.98V89.1982V95.1321H1109.07H1101.17V123.091C1101.17 150.663 1101.34 156.597 1102.11 158.419C1102.72 159.881 1104.11 161.261 1105.66 161.951C1106.91 162.476 1107.6 162.586 1110.18 162.586C1111.87 162.614 1114.12 162.503 1115.17 162.393L1117.14 162.172L1118.17 168.879C1118.72 172.55 1119.11 175.641 1118.97 175.751C1118.86 175.834 1117.53 176.138 1116.01 176.414C1112.32 177.021 1103.97 177.214 1101.17 176.717Z"
        fill="#8B1314"
      />
      <path
        d="M557 112V48H582.587C607.287 48 617.018 48.2207 621.703 48.8276C632.015 50.1793 638.724 52.9103 644.213 58.0138C646.957 60.5517 649.037 63.3931 650.977 67.2C652.696 70.5931 653.528 72.9379 654.387 76.9103C655.135 80.5241 655.219 88.8552 654.526 92.5517C653.001 100.524 649.896 106.759 644.684 112.248C640.332 116.855 635.342 119.531 627.414 121.49C620.012 123.338 616.075 123.586 593.399 123.586H573.91V149.793V176H565.455H557V112ZM616.824 108.303C627.414 106.566 633.152 102.648 636.09 95.1724C638.391 89.3241 638.225 80.6345 635.73 75.1448C633.845 70.9793 630.324 67.3103 626.471 65.4069C621.869 63.1172 618.986 62.8966 593.815 62.8966H573.91V85.9586V108.993L593.953 108.883C611.196 108.8 614.357 108.717 616.824 108.303Z"
        fill="#8B1314"
      />
      <path
        d="M675 129.456V82.9129H681.898H688.796V89.9776C688.796 93.8562 688.879 97.0422 689.017 97.0422C689.127 97.0422 689.817 96.0172 690.562 94.7705C692.852 90.9472 694.894 88.1491 696.991 86.0713C702.096 81.0013 709.518 79.6715 717.492 82.3589C719.562 83.0515 724.804 85.4895 724.997 85.8496C725.135 86.099 720.003 99.591 719.644 99.9512C719.562 100.034 718.927 99.8404 718.292 99.5079C714.623 97.624 709.601 96.7098 706.041 97.2639C703.475 97.6794 701.599 98.4275 699.392 99.9789C695.28 102.888 693.045 107.376 691.555 115.743C690.617 120.979 690.452 126.077 690.452 151.426V176H682.726H675V129.456Z"
        fill="#8B1314"
      />
      <path
        d="M834 129.456V82.9129H841.046H848.092L848.147 89.5066L848.23 96.0725L849.695 94.0778C852.541 90.1992 857.818 85.9327 862.378 83.8549C866.108 82.1372 869.7 81.3337 874.757 81.0567C887.578 80.3918 897.387 85.6002 901.587 95.3245L902.471 97.3746L904.129 95.186C909.821 87.7058 916.978 83.0791 925.322 81.4723C928.445 80.8905 936.347 81.029 939.442 81.7216C946.626 83.3839 951.489 86.5422 954.971 91.7506C957.486 95.4907 959.061 100.256 959.724 106.046C959.889 107.625 960 121.034 960 142.284V176H952.263H944.554L944.443 142.699C944.333 107.902 944.333 108.179 943.2 104.384C942.288 101.42 940.022 98.5936 937.176 96.876C934.8 95.463 933.142 94.909 930.158 94.5211C924.963 93.8839 919.188 95.2137 914.933 98.0396C909.545 101.613 906.947 106.074 905.372 114.358C905.013 116.241 904.93 121.173 904.82 146.301L904.709 176H896.972H889.263V143.974C889.263 116.352 889.208 111.532 888.821 109.121C887.246 98.7045 882.466 94.3826 872.684 94.4657C868.622 94.4934 865.997 95.1029 862.599 96.7374C854.834 100.478 851.242 106.794 850.026 118.901C849.861 120.646 849.75 132.199 849.75 148.877V176H841.875H834V129.456Z"
        fill="#8B1314"
      />
      <path
        d="M1233 129.881V83.7614H1239.91H1246.81V90.7616C1246.81 94.6049 1246.9 97.7619 1247.03 97.7619C1247.14 97.7619 1247.72 96.856 1248.33 95.7853C1252.34 88.6753 1256.59 84.5026 1261.59 82.7457C1263.58 82.0594 1264.16 81.977 1267.39 82.0044C1271.34 82.0044 1273.36 82.3888 1277.06 83.7888C1279.55 84.7496 1283 86.534 1283 86.8634C1283 87.138 1277.81 100.535 1277.64 100.699C1277.59 100.782 1276.9 100.507 1276.15 100.123C1274.02 99.0521 1270.18 98.0364 1267.56 97.8717C1262.81 97.5423 1258.75 99.0247 1255.49 102.264C1252.86 104.899 1251.67 107.123 1250.43 111.762C1248.58 118.735 1248.47 120.52 1248.47 149.866V176H1240.73H1233V129.881Z"
        fill="#8B1314"
      />
    </Icon>
  );
}
