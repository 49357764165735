import { Icon, IconProps } from "@chakra-ui/icons";

export function ErrorIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_32_2)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9866 23.8412C5.44988 23.8412 0.131866 18.5232 0.131866 11.9865C0.131866 5.44985 5.44988 0.131836 11.9866 0.131836C18.5232 0.131836 23.8412 5.44985 23.8412 11.9865C23.8412 18.5232 18.5232 23.8412 11.9866 23.8412ZM7.74573 17.5446C7.40853 17.5446 7.07159 17.416 6.81421 17.1586C6.29972 16.6444 6.29972 15.8104 6.81421 15.2961L10.1238 11.9865L6.81449 8.67727C6.3 8.16278 6.3 7.32874 6.81449 6.81451C7.32898 6.30028 8.16276 6.30028 8.67726 6.81451L11.9865 10.1238L15.2958 6.81451C15.8098 6.30028 16.6444 6.30028 17.1584 6.81451C17.6728 7.329 17.6728 8.16304 17.1584 8.67727L13.8492 11.9866L17.1586 15.2961C17.6731 15.8104 17.6731 16.6444 17.1586 17.1586C16.9015 17.4158 16.5643 17.5446 16.2274 17.5446C15.8904 17.5446 15.553 17.416 15.2961 17.1586L11.9866 13.8492L8.67725 17.1586C8.41987 17.4158 8.08293 17.5446 7.74573 17.5446Z"
          fill={"currentColor"}
        />
      </g>
      <defs>
        <clipPath id="clip0_32_2">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
