import { Box, Button, Input, Text, useDisclosure } from "@chakra-ui/react";
import api from "api/api";
import { CustomModal } from "components/custom-modal";
import { Dispatch, SetStateAction, useState, useRef } from "react";
import { useEventListener, makeEvent } from "services/events";
import { WhatsAppSessionListProps } from "../grid-servers";
import { CloseIcon, EditIcon } from "@chakra-ui/icons";
import { Toast } from "components/toast";

let serverId: number | undefined;
export function ModalEditSessionWhatsApp({
  setServers,
}: {
  setServers: Dispatch<SetStateAction<WhatsAppSessionListProps[]>>;
}) {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const nameRef = useRef<HTMLInputElement>(null);
  const [loading, setLoading] = useState(false);
  const onCloseModal = () => {
    serverId = undefined;
    nameRef.current!.value = "";
    onClose();
  };

  const handleEdit = async (newName: string, serverId: number) => {
    if (newName) {
      setLoading(true);
      try {
        const res = await api.put(`whatsapp-sessions/${serverId}/update-nome`, {
          nome: newName,
        });

        Toast({
          title: "Nome da sessão atualizada",
          status: "info",
        });
        setServers((servers) =>
          servers.map((server) => {
            if (server.id === serverId) return { ...server, nome: newName };
            return server;
          })
        );
      } catch (e) {
        Toast({ title: "Erro ao editar sessão" });
      } finally {
        setLoading(false);
        onCloseModal();
      }
    } else Toast({ title: "Preencha o nome" });
  };

  useEventListener("open-modal-edit-whatsapp", ({ id }: { id: number }) => {
    serverId = id;
    onOpen();
  });

  const modalFooter = (
    <>
      <Button
        leftIcon={<EditIcon />}
        onClick={() => handleEdit(nameRef.current?.value!, serverId!)}
        loadingText="Salvando"
        isLoading={loading}
      >
        Salvar
      </Button>
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        onClick={onCloseModal}
        variant="outline"
      >
        Cancelar
      </Button>
    </>
  );

  return (
    <CustomModal
      size="lg"
      isOpen={isOpen}
      onClose={onCloseModal}
      modalTitle="Editar Nome Servidor"
      modalFooter={modalFooter}
    >
      <Box>
        <Text mb="8px">Novo Nome:</Text>
        <Input ref={nameRef} placeholder="Nome do Servidor" />
      </Box>
    </CustomModal>
  );
}

export const openModalEditSessionWhatsApp = (id: number) => {
  makeEvent("open-modal-edit-whatsapp", { id });
};
