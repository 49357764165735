import { Router } from "./use-router";

let payloadBox: Map<string, any> = new Map();

export const usePagePayload = () => {
  const payload = payloadBox.get(Router.pathname);
  return payload;
};

export const setPagePayload = (key: string, payload: any) => {
  payloadBox.set(key, payload);
};

export const deletePagePayload = (key: string) => {
  payloadBox.delete(key);
};

export const deleteAllPagesPayloads = () => {
  return payloadBox.clear();
};
