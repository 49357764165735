import { SVGProps } from "react";

export function BirthIcon({ ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15px"
      height="16px"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M8.90625 4.75H7.5V3.66937C8.04559 3.47587 8.4375 2.95484 8.4375 2.34375C8.4375 2.15069 8.38234 1.79894 7.90547 0.976875C7.66459 0.561625 7.42694 0.216812 7.41694 0.202344C7.32941 0.075625 7.18525 0 7.03125 0C6.87725 0 6.73309 0.075625 6.64556 0.202344C6.63556 0.216812 6.39791 0.561625 6.15703 0.976875C5.68016 1.79894 5.625 2.15069 5.625 2.34375C5.625 2.95484 6.01691 3.47587 6.5625 3.66937V4.75H5.15625C4.89737 4.75 4.6875 4.95988 4.6875 5.21875V15.5312C4.6875 15.7901 4.89737 16 5.15625 16H8.90625C9.16512 16 9.375 15.7901 9.375 15.5312V5.21875C9.375 4.95988 9.16512 4.75 8.90625 4.75ZM7.03134 1.33753C7.30113 1.792 7.496 2.20031 7.5 2.34413C7.49981 2.60241 7.28959 2.8125 7.03125 2.8125C6.77278 2.8125 6.5625 2.60222 6.5625 2.34428C6.56631 2.201 6.76131 1.79247 7.03134 1.33753ZM8.4375 8.73438L5.625 10.8438V9.20312L8.4375 7.09375V8.73438ZM5.625 12.0156L8.4375 9.90625V11.5469L5.625 13.6562V12.0156ZM8.4375 5.6875V5.92188L5.625 8.03125V5.6875H8.4375ZM5.625 15.0625V14.8281L8.4375 12.7188V15.0625H5.625Z"
        fill="#4E5762"
      />
      <path
        d="M2.34375 1.875C2.60263 1.875 2.8125 1.66513 2.8125 1.40625V0.46875C2.8125 0.209875 2.60263 0 2.34375 0C2.08487 0 1.875 0.209875 1.875 0.46875V1.40625C1.875 1.66513 2.08487 1.875 2.34375 1.875Z"
        fill="#4E5762"
      />
      <path
        d="M1.40625 1.875H0.46875C0.209875 1.875 0 2.08487 0 2.34375C0 2.60263 0.209875 2.8125 0.46875 2.8125H1.40625C1.66513 2.8125 1.875 2.60263 1.875 2.34375C1.875 2.08487 1.66513 1.875 1.40625 1.875Z"
        fill="#4E5762"
      />
      <path
        d="M4.21875 1.875H3.28125C3.02237 1.875 2.8125 2.08487 2.8125 2.34375C2.8125 2.60263 3.02237 2.8125 3.28125 2.8125H4.21875C4.47763 2.8125 4.6875 2.60263 4.6875 2.34375C4.6875 2.08487 4.47763 1.875 4.21875 1.875Z"
        fill="#4E5762"
      />
      <path
        d="M2.34375 2.8125C2.08487 2.8125 1.875 3.02237 1.875 3.28125V4.21875C1.875 4.47763 2.08487 4.6875 2.34375 4.6875C2.60263 4.6875 2.8125 4.47763 2.8125 4.21875V3.28125C2.8125 3.02237 2.60263 2.8125 2.34375 2.8125Z"
        fill="#4E5762"
      />
      <path
        d="M12.6562 4.71875C12.9151 4.71875 13.125 4.50888 13.125 4.25V3.3125C13.125 3.05362 12.9151 2.84375 12.6562 2.84375C12.3974 2.84375 12.1875 3.05362 12.1875 3.3125V4.25C12.1875 4.50888 12.3974 4.71875 12.6562 4.71875Z"
        fill="#4E5762"
      />
      <path
        d="M11.7188 4.71875H10.7812C10.5224 4.71875 10.3125 4.92862 10.3125 5.1875C10.3125 5.44638 10.5224 5.65625 10.7812 5.65625H11.7188C11.9776 5.65625 12.1875 5.44638 12.1875 5.1875C12.1875 4.92862 11.9776 4.71875 11.7188 4.71875Z"
        fill="#4E5762"
      />
      <path
        d="M14.5312 4.71875H13.5938C13.3349 4.71875 13.125 4.92862 13.125 5.1875C13.125 5.44638 13.3349 5.65625 13.5938 5.65625H14.5312C14.7901 5.65625 15 5.44638 15 5.1875C15 4.92862 14.7901 4.71875 14.5312 4.71875Z"
        fill="#4E5762"
      />
      <path
        d="M12.6562 5.65625C12.3974 5.65625 12.1875 5.86612 12.1875 6.125V7.0625C12.1875 7.32138 12.3974 7.53125 12.6562 7.53125C12.9151 7.53125 13.125 7.32138 13.125 7.0625V6.125C13.125 5.86612 12.9151 5.65625 12.6562 5.65625Z"
        fill="#4E5762"
      />
    </svg>
  );
}
