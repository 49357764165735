import { RepeatIcon, Search2Icon, ViewIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Input,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { Dropdown } from "components/dropdown";
import { GetLayout } from "components/get-layout";
import { useEffect, useState } from "react";
import apiIn100 from "api/api-in100";
import { maskCPF } from "utils/string-formats";
import { ConsultaIN100Fields } from "components/consulta-in100-components/fields";
import { dropdownSimNao } from "components/atendimentos-components/atendimento-form/fields-data";
import { TitleWithBackButton } from "components/title-with-back-button";
import { openModalError } from "components/modal-error";
import { CustomModal } from "components/custom-modal";
import {
  CustomTableColor,
  CustomTableColorProps,
} from "components/dynamic-table/color-table";
import { useClientsAttendanceContext } from "components/atendimentos-components/aba-clientes/aba-clientes-context";
import { makeIn100CSVContent } from "components/consulta-in100-components/utils";
import { createLinkAndDownload } from "utils/create-link-and-download";
import { Toast } from "components/toast";

export interface IN100Props {
  agenciaPagadora: number;
  bloqueadoParaEmprestimo: boolean;
  cbcIfPagadora: number;
  concessaoJudicial: boolean;
  contaCorrente: number;
  cpf: string;
  cpfRepresentanteLegal: string;
  dataCessacaoBeneficio: string;
  dataConsulta: string;
  dataDespachoBeneficio: string;
  dataFimRepresentanteLegal: string;
  dataNascimento: string;
  dataUltimaPericia: string;
  elegivelEmprestimo: boolean;
  especieBeneficio: number;
  margemDisponivel: number;
  margemDisponivelCartao: number;
  margemDisponivelRcc: number;
  nomeBeneficiario: string;
  nomeRepresentanteLegal: string;
  numeroMatricula: string;
  observacao: string;
  pensaoAlimenticia: number;
  possuiEntidadeRepresentacao: string;
  possuiProcurador: boolean;
  possuiRepresentanteLegal: boolean;
  qtdEmprestimosAtivosSuspensos: number;
  situacaoBeneficio: number;
  tipoCredito: string;
  ufPagamento: string;
  valorLimiteCartao: number;
  valorLimiteRcc: number;
}

export interface ConsultHistory {
  beneficio: string;
  dataCriacao: string;
  cpf: string;
  cpfRepresentante: string;
  name: string;
  resultado: IN100Props;
}

export function ConsultaIn100() {
  const [isLoadingConsult, setIsLoadingConsult] = useState(false);
  const [isLoadingList, setIsLoadingList] = useState(false);
  const [possuiRepresentante, setPossuiRepresentante] = useState(false);
  const [inputCPF, setInputCPF] = useState("");
  const [inputCPFRepresentante, setInputCPFRepresentante] = useState("");
  const [inputNB, setInputNB] = useState("");
  const [consult, setConsult] = useState<IN100Props>();
  const [isOpen, setIsOpen] = useState(false);
  const [findIn100, setFindIn100] = useState<ConsultHistory[]>([]);

  const { clientsFilter, setClientsFilter } = useClientsAttendanceContext();

  const onClose = () => {
    setIsOpen(false);
  };

  const getIn100 = async (page: number) => {
    setIsLoadingList(true);
    try {
      const { data } = await apiIn100.get(
        `/consulta/find-all?page=${page - 1}`
      );
      setClientsFilter(data);
      setFindIn100(data);
      clientsFilter.page = page;
    } catch (e: any) {
      console.log(e);
      Toast({
        title: "Erro ao carregar as In100",
      });
    } finally {
      setIsLoadingList(false);
    }
  };

  const dateFormat = (data: string, withOutYears = false) => {
    data = data.slice(0, 10);
    const today = new Date();
    const birthDate = new Date(data);
    let age = today.getFullYear() - birthDate.getUTCFullYear();
    const month = today.getMonth() - birthDate.getUTCMonth();
    const day = today.getDate() - birthDate.getUTCDate();

    if (month < 0) age -= 1;
    else if (month === 0 && day < 0) age -= 1;

    return `${data?.split("-").reverse().join("/") ?? ""}${
      withOutYears ? "" : ` (${age} anos)`
    }`;
  };

  const onConsult = async (searchValue = inputCPF) => {
    let isValid = inputCPF.replace(/\D/g, "").length === 11 && !!inputNB;
    if (possuiRepresentante) {
      isValid =
        isValid && inputCPFRepresentante.replace(/\D/g, "").length === 11;
    }
    if (!isValid) return Toast({ title: "Preencha os campos corretamente" });
    setIsLoadingConsult(true);
    try {
      const { data } = await apiIn100.get(`/consulta/3/pre`, {
        params: {
          nb: inputNB,
          cpf: inputCPF.replace(/\D/g, ""),
          cpfRepresentante: possuiRepresentante
            ? inputCPFRepresentante.replace(/\D/g, "")
            : undefined,
        },
      });
      console.log(data);
      getIn100(1);
    } catch (e: any) {
      const isForbidden = e?.response?.status === 403;
      const data = e?.response?.data;
      const errorMessage = typeof data === "string" ? data : undefined;
      openModalError({
        message:
          errorMessage ??
          (isForbidden
            ? "Sem permissão para consultar. Verifique se o serviço está ativo."
            : "Erro ao consultar IN100."),
      });
    } finally {
      setIsLoadingConsult(false);
    }
  };

  const columnsTable: CustomTableColorProps["columns"] = [
    {
      name: "Data Criação",
      render: (row: ConsultHistory) => (
        <Text>{dateFormat(row.dataCriacao, true)}</Text>
      ),
    },
    {
      name: "Beneficio",
      render: (row: ConsultHistory) => <Text>{row.beneficio}</Text>,
    },
    {
      name: "Cpf",
      render: (row: ConsultHistory) => <Text>{row.cpf}</Text>,
    },
    {
      name: "Cpf Representante",
      render: (row: ConsultHistory) => <Text>{row.cpfRepresentante}</Text>,
    },
    {
      name: "Usuário",
      render: (row: ConsultHistory) => <Text>{row.name}</Text>,
    },
    {
      name: "Ações",
      render: (row: ConsultHistory) => {
        return (
          <Tooltip label="Visualizar Dados">
            <IconButton
              aria-label="Visualizar Dados"
              onClick={() => {
                setIsOpen(true);
                setConsult(row.resultado);
              }}
            >
              <ViewIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  const onClickDownload = (in100: IN100Props) => {
    const content = makeIn100CSVContent(in100);
    const file = new Blob([content]);
    console.log(content);
    createLinkAndDownload(
      file,
      `consulta-in100-${new Date().toLocaleDateString()}.csv`
    );
  };

  const modalFooter = (
    <Flex gap="10px" w="28%">
      <Button
        w="100%"
        onClick={() => onClose()}
        background="#FFFFFF"
        border="1px solid #141B3B"
        textColor="#000000"
        _hover={{
          background: "#141B3B15",
          border: "1px solid #141B3B",
          textColor: "#000000",
        }}
      >
        Cancelar
      </Button>
      <Button w="100%" onClick={() => onClickDownload(consult!)}>
        Baixar CSV
      </Button>
    </Flex>
  );

  useEffect(() => {
    getIn100(1);
  }, []);

  return (
    <GetLayout>
      <TitleWithBackButton title="Consulta IN100" />
      <Box borderRadius="8px" w="100%" mb="16px">
        <Grid
          templateColumns={
            possuiRepresentante
              ? "auto auto auto auto 200px 200px"
              : "auto auto auto 200px 200px"
          }
          gap="16px"
        >
          <Box>
            <Text mb="8px">CPF</Text>
            <Input
              value={inputCPF}
              onChange={(e) => {
                setInputCPF(maskCPF(e.target.value));
              }}
              onKeyDown={(e) => (e.key === "Enter" ? onConsult() : undefined)}
              variant="outline-custom"
            />
          </Box>

          <Box>
            <Text mb="8px">Número do benefício</Text>
            <Input
              value={inputNB}
              onChange={(e) => {
                e.currentTarget.value = e.currentTarget.value.replace(
                  /\D/g,
                  ""
                );
                setInputNB(e.target.value);
              }}
              onKeyDown={(e) => (e.key === "Enter" ? onConsult() : undefined)}
              variant="outline-custom"
            />
          </Box>
          <Box>
            <Text mb="8px">Possui representante</Text>
            <Dropdown
              w="100%"
              options={dropdownSimNao}
              onChange={setPossuiRepresentante}
              value={possuiRepresentante}
              variant="outline-custom"
            />
          </Box>
          {possuiRepresentante ? (
            <Box>
              <Text mb="8px">CPF representante</Text>
              <Input
                value={inputCPFRepresentante}
                onChange={(e) => {
                  setInputCPFRepresentante(maskCPF(e.target.value));
                }}
                onKeyDown={(e) => (e.key === "Enter" ? onConsult() : undefined)}
                variant="outline-custom"
              />
            </Box>
          ) : null}
          <Box>
            <Text mb="8px">&nbsp;</Text>
            <Button
              w="100%"
              leftIcon={<Search2Icon />}
              onClick={() => onConsult()}
              isLoading={isLoadingConsult}
              loadingText="Consultando"
              isDisabled={
                inputCPF === "" ||
                inputNB === "" ||
                (possuiRepresentante ? inputCPFRepresentante === "" : false)
              }
              size="sm"
            >
              Consultar
            </Button>
          </Box>
          <Box>
            <Text mb="8px">&nbsp;</Text>
            <Button
              w="100%"
              leftIcon={<RepeatIcon />}
              onClick={() => getIn100(1)}
              isLoading={isLoadingList}
              loadingText="Atualizando"
              size="sm"
            >
              Atualizar
            </Button>
          </Box>
        </Grid>
      </Box>

      <CustomTableColor
        columns={columnsTable}
        rows={findIn100}
        isLoading={isLoadingList}
        currentPage={clientsFilter.page - 1}
        onFirstPage={() => {
          const page = 1;
          getIn100(page);
        }}
        onPrevPage={() => {
          const page = clientsFilter.page - 1;
          getIn100(page);
        }}
        onNextPage={() => {
          const page = clientsFilter.page + 1;
          getIn100(page);
        }}
      />

      <CustomModal
        isOpen={isOpen}
        onClose={onClose}
        modalTitle="In100"
        size="4xl"
        scroll="inside"
        modalFooter={modalFooter}
      >
        <ConsultaIN100Fields consult={consult!} />
      </CustomModal>
    </GetLayout>
  );
}
