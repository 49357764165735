import { Icon, IconProps } from "@chakra-ui/react";

export function SendIcon({ ...rest }: IconProps) {
  return (
    <Icon
      xmlns="http://www.w3.org/2000/svg"
      width="14.998px"
      height="15px"
      viewBox="0 0 14.998 15"
      {...rest}
    >
      <path
        d="M14.451.551a1.854,1.854,0,0,0-1.75-.5L2.7,2.158a3.122,3.122,0,0,0-1.783,5.3L1.991,8.532a.625.625,0,0,1,.183.443v1.98a1.853,1.853,0,0,0,.188.8l-.005,0,.016.016a1.875,1.875,0,0,0,.851.848l.016.016,0-.005a1.853,1.853,0,0,0,.8.188h1.98a.625.625,0,0,1,.442.183L7.543,14.08a3.1,3.1,0,0,0,2.2.921,3.156,3.156,0,0,0,1-.165,3.083,3.083,0,0,0,2.09-2.491L14.946,2.322a1.859,1.859,0,0,0-.5-1.771ZM2.876,7.649,1.8,6.576A1.838,1.838,0,0,1,1.35,4.655,1.861,1.861,0,0,1,2.913,3.388l9.9-2.084L3.423,10.7V8.974A1.861,1.861,0,0,0,2.876,7.649Zm8.732,4.481A1.875,1.875,0,0,1,8.427,13.2L7.352,12.125a1.861,1.861,0,0,0-1.324-.549H4.306L13.7,2.188Z"
        transform="translate(-0.003 -0.001)"
        fill="#fff"
      />
    </Icon>
  );
}

export function SendIcon2({
  isRecording,
  ...rest
}: IconProps & { isRecording?: boolean }) {
  return (
    <Icon
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.10101 21.757L23.8 12.028L1.10101 2.30005L1.11201 10.212L14.735 12.028L1.11201 13.845L1.10101 21.757Z"
        fill="currentColor"
      />
      {isRecording ? (
        <g>
          <animate
            values="0;1;0"
            repeatCount="indefinite"
            attributeName="opacity"
            dur="1s"
          />
          <circle cx="17" cy="18" r="6" fill="#FF0000" />
        </g>
      ) : null}
    </Icon>
  );
}

export function SendClockIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="13px"
      height="12px"
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M1.64917 9.25001V2.75001C1.64917 2.56667 1.72216 2.42717 1.86814 2.33151C2.01412 2.23584 2.17027 2.22117 2.33658 2.28751L8.79826 5.00001H8.64828C8.35665 5.00001 8.08169 5.03334 7.82339 5.10001C7.56509 5.16667 7.31512 5.25834 7.07348 5.37501L2.64904 3.50001V5.25001L5.64866 6.00001L2.64904 6.75001V8.50001L5.3487 7.35001C5.28204 7.54167 5.23205 7.73134 5.19872 7.91901C5.16539 8.10667 5.14873 8.30034 5.14873 8.50001V8.52501L2.34908 9.71251C2.18244 9.77917 2.02412 9.76451 1.87414 9.66851C1.72416 9.57251 1.64917 9.43301 1.64917 9.25001ZM8.64828 11C7.9567 11 7.36728 10.7562 6.88001 10.2685C6.39273 9.78084 6.14893 9.19134 6.1486 8.50001C6.1486 7.80834 6.3924 7.21884 6.88001 6.73151C7.36761 6.24417 7.95704 6.00034 8.64828 6.00001C9.33986 6.00001 9.92945 6.24384 10.4171 6.73151C10.9047 7.21917 11.1483 7.80867 11.148 8.50001C11.148 9.19167 10.9042 9.78134 10.4166 10.269C9.92895 10.7567 9.33953 11.0003 8.64828 11ZM8.89825 8.40001V7.25001C8.89825 7.18334 8.87325 7.12501 8.82326 7.07501C8.77327 7.02501 8.71494 7.00001 8.64828 7.00001C8.58162 7.00001 8.5233 7.02501 8.4733 7.07501C8.42331 7.12501 8.39831 7.18334 8.39831 7.25001V8.38751C8.39831 8.45417 8.41081 8.51884 8.43581 8.58151C8.46081 8.64417 8.4983 8.70034 8.54829 8.75001L9.2982 9.50001C9.34819 9.55001 9.40652 9.57501 9.47318 9.57501C9.53983 9.57501 9.59816 9.55001 9.64815 9.50001C9.69815 9.45001 9.72315 9.39167 9.72315 9.32501C9.72315 9.25834 9.69815 9.20001 9.64815 9.15001L8.89825 8.40001ZM2.64904 7.35001V3.50001V8.50001V7.35001Z"
        fill="#4E5762"
      />
    </Icon>
  );
}
