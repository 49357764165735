import { Box, Button, Flex } from "@chakra-ui/react";
import api from "api/api";
import {
  StatusFormat,
  bgError,
  bgInfo,
  bgSucess,
  bgWarning,
} from "components/dynamic-table/row-status";
import DynamicTable from "components/dynamic-table";
import { useApplicationContext } from "contexts/ApplicationContext";
import { openModalConfirm } from "components/modal-confirm-new";
import { formatData } from "utils/string-formats";
import { Column } from "components/dynamic-table/types";
import { openModalChangeCobranca } from "../service-card/modal-change-cobranca";
import { openModalAtivoStatus } from "../service-card/modal-ativo-status";
import { useRouter } from "routes/router-control/use-router";
import { Toast, UseToastOptions } from "components/toast";

interface RowProps {
  ativo: boolean;
  banco: string;
  cobranca: "AVULSO" | "MENSAL";
  dataExpiracao: null | string;
  expirado: null | boolean;
  possuiRecorrencia: boolean | null;
}

export function TabelaHigienizacaoCartao({
  servicosList,
  setServicosList,
  isLoading,
}: {
  setServicosList: React.Dispatch<
    React.SetStateAction<
      {
        ativo: boolean;
        banco: string;
        cobranca: "MENSAL" | "AVULSO";
        dataExpiracao: "2023-04-03";
        expirado: boolean;
        possuiRecorrencia: boolean;
      }[]
    >
  >;
  servicosList: any[];
  isLoading: boolean;
}) {
  const { user } = useApplicationContext();
  const router = useRouter();

  const params = new URLSearchParams(window.location.search);
  const customerId = params.get("partnerId");

  const changeRecorrencia = async ({
    banco,
    customerId,
    possuiRecorrencia,
  }: {
    banco: string;
    customerId: string;
    possuiRecorrencia: boolean;
  }) => {
    openModalConfirm({
      onClose: () => {},
      message: `Deseja ${
        !possuiRecorrencia ? "ATIVAR" : "DESATIVAR"
      } a cobrança recorrente?`,
      onConfirm: async () => {
        try {
          const { data } = await api.get(
            `/services/api-cartao-banco/change-recorrencia`,
            {
              params: {
                banco,
                customerId,
                possuiRecorrencia: !possuiRecorrencia,
              },
            }
          );
          setServicosList((rows) =>
            rows.map((item) => {
              if (item.banco === banco) {
                return data;
              }
              return item;
            })
          );
        } catch (e) {
          Toast({
            title: `Erro ao ${!possuiRecorrencia ? "ativar" : "desativar"}?`,
            status: "error",
          });
        } finally {
        }
      },
      confirmButtonStyle: {
        variant: undefined,
      },
    });
  };

  const changeCobranca = ({ banco }: { banco: string }) => {
    openModalChangeCobranca({
      modalTitle: "Contratar mensalmente",
      onConfirm: async ({ possuiRecorrencia }) => {
        try {
          const { data } = await api.get(
            `/services/api-cartao-banco/create-mensal-license`,
            { params: { customerId, possuiRecorrencia, banco } }
          );
          setServicosList((list) =>
            list.map((item) => {
              if (item.banco === banco) {
                return data;
              }
              return item;
            })
          );
        } catch (e: any) {
          const errorStatus = e?.response?.data?.message ?? "";
          const errorMessage: { [k: string]: UseToastOptions } = {
            cannot_enable_avulso_when_cobranca_mensal: {
              title: "Erro ao mudar status do serviço",
              description: "É necessário que a cobrança esteja avulsa",
            },
            service_not_expired: { title: "Serviço não expirado" },
            sem_saldo: { title: "Saldo insuficiente" },
          };
          Toast({
            ...(errorMessage[errorStatus] ?? {
              title: "Erro ao mudar status de serviço",
            }),
            status: "error",
            duration: 7000,
          });
        } finally {
        }
      },
    });
  };

  const changeAtivoStatus = (row: RowProps) => {
    if (row.ativo) {
      const message = `Deseja ${!row.ativo ? "ATIVAR" : "DESATIVAR"}?`;
      openModalConfirm({
        message,
        onConfirm: async () => {
          try {
            const { data } = await api.get(
              `/services/api-cartao-banco/change-ativo-avulso?customerId=${customerId}&banco=${
                row.banco
              }&ativo=${!row.ativo}`
            );
            setServicosList((list) =>
              list.map((currentRow) => {
                if (currentRow.banco === row.banco) {
                  return data;
                }
                return currentRow;
              })
            );
          } catch (e: any) {
            const errorStatus = e?.response?.data?.message ?? "";
            const errorMessage: { [k: string]: UseToastOptions } = {
              cannot_enable_avulso_when_cobranca_mensal: {
                title: "Erro ao mudar status do serviço",
                description: "É necessário que a cobrança esteja avulsa",
              },
              service_not_expired: {
                title: "Serviço não expirado",
                description: "Não é possível desativar um serviço não expirado",
              },
              sem_saldo: { title: "Saldo insuficiente" },
            };
            Toast({
              ...(errorMessage[errorStatus] ?? {
                title: "Erro ao mudar status de serviço",
              }),
              status: "error",
              duration: 7000,
            });
          } finally {
          }
        },
        confirmButtonStyle: {
          variant: undefined,
        },
      });
    } else {
      openModalAtivoStatus({
        modalTitle: "Ativação",
        onConfirm: async ({ cobranca, possuiRecorrencia }) => {
          let url = "";
          if (cobranca === "MENSAL") {
            url = `/services/api-cartao-banco/create-mensal-license?customerId=${customerId}&banco=${row.banco}&possuiRecorrencia=${possuiRecorrencia}`;
          } else {
            url = `/services/api-cartao-banco/change-ativo-avulso?customerId=${customerId}&banco=${
              row.banco
            }&ativo=${!row.ativo}`;
          }
          try {
            const { data } = await api.get(url);
            setServicosList((list) =>
              list.map((currentRow) => {
                if (currentRow.banco === row.banco) {
                  return data;
                }
                return currentRow;
              })
            );
          } catch (e: any) {
            const errorStatus = e?.response?.data?.message ?? "";
            const errorMessage: { [k: string]: UseToastOptions } = {
              cannot_enable_avulso_when_cobranca_mensal: {
                title: "Erro ao mudar status do serviço",
                description: "É necessário que a cobrança esteja avulsa",
              },
              service_not_expired: {
                title: "Serviço não expirado",
                description: "Não é possível desativar um serviço não expirado",
              },
              sem_saldo: { title: "Saldo insuficiente" },
            };
            Toast({
              ...(errorMessage[errorStatus] ?? {
                title: "Erro ao status de serviço",
              }),
              status: "error",
              duration: 7000,
            });
          } finally {
          }
        },
      });
    }
  };

  const servicosColumns: Column[] = [
    {
      name: "Banco",
      render: (row) => {
        return <>{row.banco}</>;
      },
      cellStyle: () => ({ px: { base: "12px", "2xl": "20px" } }),
    },
    {
      name: "Status",
      render: (row) => {
        const bg = new Map([
          ["true", bgSucess],
          ["false", bgError],
        ]).get(row.expirado ? "false" : `${row.ativo}`);

        return (
          <StatusFormat bg={bg}>
            {row.expirado ? "Expirado" : row.ativo ? "Ativo" : "Inativo"}
          </StatusFormat>
        );
      },
      cellStyle: () => ({ px: { base: "12px", "2xl": "20px" } }),
    },
    {
      name: "Cobrança",
      render: (row: RowProps) => {
        return (
          <>
            <StatusFormat bg={bgInfo}>{row.cobranca}</StatusFormat>
          </>
        );
      },
      cellStyle: () => ({ px: { base: "12px", "2xl": "20px" } }),
    },
    {
      name: "Expiração",
      render: (row) => {
        return <>{row.dataExpiracao ? formatData(row.dataExpiracao) : null}</>;
      },
      cellStyle: () => ({ px: { base: "12px", "2xl": "20px" } }),
    },
    {
      name: "Cobrança Recorrente",
      render: (row: RowProps) => {
        const bgRecorrente = new Map([
          ["true", bgSucess],
          ["false", bgWarning],
          ["null", bgInfo],
        ]).get(`${row.possuiRecorrencia}`)!;
        return (
          <Flex alignItems="center">
            {row.possuiRecorrencia == null ? null : (
              <StatusFormat bg={bgRecorrente}>
                {row.possuiRecorrencia ? "SIM" : "NÃO"}
              </StatusFormat>
            )}
          </Flex>
        );
      },
      cellStyle: () => ({ px: { base: "12px", "2xl": "20px" } }),
    },
    {
      name: "Ações",
      render: (row: RowProps) => {
        const bgAtivo = new Map([
          ["true", bgSucess],
          ["false", bgError],
        ]).get(row.expirado ? "false" : `${!row.ativo}`)!;
        return (
          <>
            {row.cobranca === "AVULSO" ? (
              <Button
                aria-label=""
                // leftIcon={<DocumentDolarIcon width="15px" height="15px" />}
                onClick={async () => changeCobranca({ banco: row.banco })}
                // bg={bgInfo}
                // boxShadow={`0 0 0 2px ${changeColorIntensity(bgInfo, 0.1)}`}
                // _hover={{ bg: changeColorIntensity(bgInfo, 0.1) }}
                // color={changeColorIntensity(bgInfo, 1)}
                mr="8px"
                size="xs"
                // _disabled={{
                //   _hover: { bg: changeColorIntensity(bgInfo, 0.1) },
                //   opacity: 0.5,
                // }}
                variant="outline"
              >
                Contratar mensalmente
              </Button>
            ) : null}
            {row.cobranca === "MENSAL" ? (
              <Button
                aria-label=""
                // leftIcon={<CoinRecorrencyIcon />}
                onClick={async () =>
                  changeRecorrencia({
                    banco: row.banco,
                    customerId: customerId!,
                    possuiRecorrencia: row.possuiRecorrencia!,
                  })
                }
                // bg={bgWarning}
                // boxShadow={`0 0 0 2px ${changeColorIntensity(bgWarning, 0.1)}`}
                // _hover={{ bg: changeColorIntensity(bgWarning, 0.1) }}
                // color={changeColorIntensity(bgWarning, 1)}
                size="xs"
                mr="8px"
                // _disabled={{
                //   _hover: { bg: changeColorIntensity(bgWarning, 0.1) },
                //   opacity: 0.5,
                // }}
                variant="outline"
              >
                {!row.possuiRecorrencia
                  ? "Ativar recorrência"
                  : "Desativar recorrência"}
              </Button>
            ) : null}

            {row.cobranca === "AVULSO" || row.cobranca == null ? (
              <Button
                aria-label=""
                // tooltipMessage={!row.ativo ? "Ativar" : "Desativar"}
                // icon={<FaPowerOff size="18px" />}
                onClick={async () => changeAtivoStatus(row)}
                // bg={bgAtivo}
                // boxShadow={`0 0 0 2px ${changeColorIntensity(bgAtivo, 0.1)}`}
                // _hover={{ bg: changeColorIntensity(bgAtivo, 0.1) }}
                // color={changeColorIntensity(bgAtivo, 1)}
                size="xs"
                // _disabled={{
                //   _hover: { bg: changeColorIntensity(bgAtivo, 0.1) },
                //   opacity: 0.5,
                // }}
                variant="outline"
                w="71px"
              >
                {!row.ativo ? "Ativar" : "Desativar"}
              </Button>
            ) : null}
          </>
        );
      },
      cellStyle: () => ({
        px: { base: "12px", "2xl": "20px" },
      }),
    },
  ];

  return (
    <Box w="100%">
      <DynamicTable
        columns={servicosColumns}
        rows={servicosList}
        isLoading={isLoading}
        pageSize={15}
        headerStyle={(header) => ({
          textAlign: header.key === "valor" ? "end" : "center",
          bg: "#fff",
          color: "#656F7D",
          textTransform: "uppercase",
          fontSize: "12px",
          fontWeight: "medium",
          _hover: { bg: "gray.50" },
          px: { base: "12px", "2xl": "20px" },
        })}
        containerStyle={{ boxShadow: "none" }}
      />
    </Box>
  );
}
