import { CloseIcon } from "@chakra-ui/icons";
import { Box, BoxProps, IconButton, Text, TextProps } from "@chakra-ui/react";
import {
  CSSProperties,
  ReactNode,
  RefObject,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import {
  useEventListener,
  getEventListeners,
  makeEvent,
} from "services/events";
import { AnimateElement } from "utils/animations";

type Position = "right-bottom" | "left-bottom" | "center" | "right" | "left";

export interface PopoverProps {
  id?: string;
  title?: string;
  button: JSX.Element | ReactNode;
  children:
    | JSX.Element
    | ReactNode
    | (({
        onClose,
        isOpen,
      }: {
        onClose: () => void;
        isOpen: boolean;
      }) => JSX.Element | ReactNode);
  position?: Position;
  onOpen?: () => void;
  onClose?: () => void;
  containerStyles?: CSSProperties;
  popupStyles?: CSSProperties;
  closeOnBlur?: boolean;
  onClickClosePopoversList?: string[];
  isOpen?: boolean;
  isDisabled?: boolean;
  customArrowPos?: CSSProperties;
  variant?: "danger" | "default" | "blue";
  titleProps?: TextProps;
  withOutCloseButton?: boolean;
  contentProps?: CSSProperties;
  customPopupPos?: CSSProperties;
  customTranslate?: string;
  buttonTriggerContainerProps?: BoxProps;
}

export function Popover({
  id,
  button,
  title,
  children,
  onClose: onCloseEvent,
  onOpen: onOpenEvent,
  position = "center",
  containerStyles,
  popupStyles,
  closeOnBlur = true,
  onClickClosePopoversList,
  isOpen: externalIsOpen,
  contentProps,
  isDisabled,
  customArrowPos,
  customPopupPos,
  variant = "default",
  titleProps,
  withOutCloseButton,
  customTranslate,
  buttonTriggerContainerProps,
}: PopoverProps) {
  let [isOpen, setIsOpen] = useState(!!externalIsOpen);
  const [isInsertedNode, setIsInsertedNode] = useState(false);
  const popoverKey = useId();
  const openButton = useRef<HTMLDivElement>(null);
  const popoverContainerRef = useRef<HTMLDivElement>(null);
  const popupContainerRef = useRef<HTMLDivElement>(null);
  const animationDuration = 150;
  if (externalIsOpen != null) {
    closeOnBlur = false;
  }

  const background = {
    default: "#fff",
    blue: "var(--chakra-colors-primary-400)",
    danger: "var(--chakra-colors-secondary-700)",
  }[variant];

  const border = {
    default: "1px solid #EAEAEA",
    blue: "1px solid var(--chakra-colors-primary-200)",
    danger: "1px solid var(--chakra-colors-secondary-400)",
  }[variant];

  const onOpen = () => {
    setIsOpen(true);
    setIsInsertedNode(true);
    isOpen = true;
  };

  const onClose = () => {
    setIsOpen(false);
    isOpen = false;
  };

  const onToggle = () => {
    if (isOpen) onClose();
    else onOpen();
  };

  const translate: string =
    customTranslate ||
    {
      center: `translate(50%, 100%)`,
      "right-bottom": `translate(0%, 100%)`,
      "left-bottom": `translate(0%, 100%)`,
      right: `translate(100%, 50%)`,
      left: `translate(0%, 50%)`,
    }[position];

  const popupPos: CSSProperties =
    customPopupPos ||
    {
      center: { top: "auto", right: "50%", bottom: "-16px" },
      "right-bottom": { top: "auto", left: "0%", bottom: "-16px" },
      "left-bottom": { top: "auto", right: "0%", bottom: "-16px" },
      right: { top: "auto", right: "-16px", bottom: "50%" },
      left: { top: "auto", right: "calc(100% + 16px)", bottom: "50%" },
    }[position];

  const arrowPos: CSSProperties =
    customArrowPos ||
    {
      center: { right: "50%", transform: "translate(50%, calc(-100% + 1px))" },
      "right-bottom": {
        right: "80%",
        transform: "translate(50%, calc(-100% + 1px))",
      },
      "left-bottom": {
        right: "20%",
        transform: "translate(50%, calc(-100% + 1px))",
      },
      right: {
        top: "50%",
        right: "calc(100% - 3.2px)",
        transform: "translateY(-50%) rotate(-90deg)",
      },
      left: {
        top: "50%",
        right: "-16.8px",
        transform: "translateY(-50%) rotate(90deg)",
      },
    }[position];

  const fadeAnimation = (progress: number) => {
    const target = popupContainerRef.current;
    if (target) {
      const { style } = target;
      style.opacity = `${progress}`;
      style.transform = `scale(${0.9 + progress / 10}) ${translate}`;
    }
  };

  const onOpenAnimation = () => {
    const target = popupContainerRef.current;
    if (target) {
      const from = Number(getComputedStyle(target).opacity);
      const to = 1;
      if (from !== to) {
        AnimateElement(animationDuration, [from, to], fadeAnimation);
      }
    }
  };

  const onCloseAnimation = () => {
    const target = popupContainerRef.current;
    if (target) {
      const from = Number(getComputedStyle(target).opacity);
      const to = 0;
      if (from !== to) {
        AnimateElement(animationDuration, [from, to], fadeAnimation, () => {
          if (isOpen === false) {
            setIsInsertedNode(false);
          }
        });
      }
    }
  };

  const onExternalClose = (e: globalThis.MouseEvent) => {
    if (closeOnBlur) {
      if (isClickedOut(e, popoverContainerRef) && isOpen) {
        if (externalIsOpen == null) onClose();
        else onCloseEvent?.();
      }
    }
  };

  useEventListener("blur-popover", onExternalClose, id ?? popoverKey);

  useEffect(() => {
    if (isOpen) onOpenAnimation();
    else onCloseAnimation();
  }, [isOpen]);

  useEffect(() => {
    if (externalIsOpen != null) {
      if (externalIsOpen) onOpen();
      else onClose();
    }
  }, [externalIsOpen]);

  const { onClick } = buttonTriggerContainerProps || {};

  const popupStyleProps = { background, border, ...popupPos, ...popupStyles };

  return (
    <div
      ref={popoverContainerRef}
      className="popover-container"
      style={{ ...containerStyles }}
    >
      <Box
        ref={openButton}
        onClick={(e) => {
          onClick?.(e);
          if (externalIsOpen == null) onToggle();
          else {
            if (isOpen) onCloseEvent?.();
            else onOpenEvent?.();
          }
        }}
        aria-disabled={isDisabled}
      >
        {button}
      </Box>
      {isInsertedNode ? (
        <div
          ref={popupContainerRef}
          className="popover-popup"
          style={popupStyleProps}
        >
          {title ? (
            <div className="popover-title" style={{ borderBottom: border }}>
              <Text fontWeight="bold" fontSize="14px" {...titleProps}>
                {title}
              </Text>
              {withOutCloseButton ? null : (
                <IconButton
                  aria-label=""
                  size="xs"
                  variant="ghost"
                  onClick={() => {
                    if (externalIsOpen == null) onClose();
                    else onCloseEvent?.();
                  }}
                  icon={<CloseIcon width="8px" height="8px" />}
                />
              )}
            </div>
          ) : null}
          <div className="popover-arrow" style={arrowPos}>
            <PopoverArrow
              bg={popupStyleProps.background as string}
              border={popupStyleProps.border as string}
            />
          </div>
          <div className="popover-content" style={{ ...contentProps }}>
            {typeof children === "function"
              ? children({ onClose, isOpen })
              : children}
          </div>
        </div>
      ) : null}
    </div>
  );
}

function PopoverArrow({
  bg = "#fff",
  border = "#EAEAEA",
}: {
  border?: string;
  bg?: string;
}) {
  const parseBorder =
    border.split(" ").length > 1 ? border.split(" ")[2] : border;

  return (
    <svg
      width="20"
      height="15"
      viewBox="0 0 20 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M10 1L19 14H1L10 1Z" />
      <path
        d="M19 14C16 14 10 1 10 1C10 1 4 14 1 14"
        stroke={parseBorder}
        fill={bg}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export const isClickedOut = (event: any, ref: RefObject<HTMLElement>) =>
  ref.current && !ref.current.contains(event.target as Node);
