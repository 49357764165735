import { Icon } from "@chakra-ui/react";
import { SVGProps } from "react";

export function CreditCardIcon({ ...rest }) {
  return (
    <Icon
      width="29px"
      height="29px"
      viewBox="0 0 29 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M25.7778 4.83334H3.2222C2.7949 4.83334 2.38511 5.00309 2.08297 5.30523C1.78083 5.60737 1.61108 6.01716 1.61108 6.44445V22.5556C1.61108 22.9829 1.78083 23.3927 2.08297 23.6948C2.38511 23.9969 2.7949 24.1667 3.2222 24.1667H25.7778C26.205 24.1667 26.6148 23.9969 26.917 23.6948C27.2191 23.3927 27.3889 22.9829 27.3889 22.5556V6.44445C27.3889 6.01716 27.2191 5.60737 26.917 5.30523C26.6148 5.00309 26.205 4.83334 25.7778 4.83334ZM25.7778 6.44445V16.1111H3.2222V6.44445H25.7778ZM3.2222 22.5556V19.3333H25.7778V22.5556H3.2222Z"
        fill="currentColor"
      />
    </Icon>
  );
}

export const CreditCardIcon2 = ({ ...rest }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="14px"
      height="12px"
      viewBox="0 0 14 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.9284 1H2.87163C1.83935 1 1 1.84007 1 2.87163V9.35035C1 10.3826 1.84007 11.222 2.87163 11.222H11.9284C12.9606 11.222 13.8 10.3819 13.8 9.35035V2.87163C13.8 1.83935 12.9606 1 11.9284 1ZM12.9362 9.35035C12.9362 9.90608 12.4834 10.3581 11.9284 10.3581H2.87163C2.3159 10.3581 1.86383 9.90608 1.86383 9.35035V4.62376H12.9362V9.35035ZM12.9362 3.75921H1.86383V2.87163C1.86383 2.3159 2.3159 1.86383 2.87163 1.86383H11.9284C12.4834 1.86383 12.9362 2.3159 12.9362 2.87163V3.75921ZM8.61702 8.58586C8.61702 8.34687 8.81067 8.15394 9.04894 8.15394H10.4887C10.7269 8.15394 10.9206 8.34759 10.9206 8.58586C10.9206 8.82413 10.7269 9.01777 10.4887 9.01777H9.04894C8.81067 9.01777 8.61702 8.82413 8.61702 8.58586Z"
        fill="#4E5762"
        stroke="#4E5762"
        strokeWidth="0.32"
      />
    </svg>
  );
};
