import { AddIcon, ArrowBackIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Center, Flex, Grid, Text } from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";
import { useEffect, useState } from "react";
import { useEventListener, makeEvent } from "services/events";
import { PeopleHand } from "components/vectors/people-hand";
import { AddPerson } from "components/vectors/add-person";
import { CreateNewAttendanceParams } from "./variables";
import { Convenio } from "../atendimento-form/types";
import { CreateNewClient } from "./parts/create-new-client";
import { CreateWithRegisteredCustomer } from "./parts/create-with-registered-customer";
import { updateAttendances } from "../kanban-and-table-atendimento/kanban/kanban-context";
import { openModalConfirm } from "components/modal-confirm-new";
import api from "api/api";
import { Toast } from "components/toast";
import { GenerateOfflineConsultation } from "./parts/generate-offline-consultation";
import { ConsultationData } from "./parts/consultation-data";
import apiConsulta from "api/api-consulta";
import {
  ModalSelectBenefit,
  openModalSelectBenefit,
} from "components/consulta-components/modal-select-benefit";
import { ConsultaProps } from "components/consulta-components/consulta-fields/types";
import { useRouter } from "routes/router-control/use-router";

let historyPagination: CreateNewAttendanceParams["mode"] = "start";

export function NovoAttendanceModal() {
  const [redBarSize, setRedBarSize] = useState("33%");
  const [isLoading, setIsLoading] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [searchResult, setSearchResult] = useState<any[]>([]);
  const [dropdownConvenio, setDropdownConvenio] = useState<Convenio>(null);
  const [inputCpf, setInputCpf] = useState("");
  const [consultaMatriculaCPF, setConsultaMatriculaCPF] = useState("");
  const [tipoConsulta, setTipoConsulta] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [mode, setMode] = useState<CreateNewAttendanceParams["mode"]>("start");
  const [consultationData, setConsultationData] = useState<
    ConsultaProps | undefined
  >();
  const [dropdownCPFNBValue, setDropdownCPFNB] = useState<"CPF" | "NB">("CPF");

  const router = useRouter();

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    onChangeMode("start");
    historyPagination = "start";
  };

  useEventListener("openNewAttendanceModal", onOpen);

  const createAttendance = async (row: any) => {
    setModalLoading(true);
    try {
      const { data } = await api.post("/atendimentos", {
        cpf: dropdownCPFNBValue === "CPF" ? inputCpf : undefined,
        convenio: dropdownConvenio,
        nb: dropdownCPFNBValue === "NB" ? inputCpf : undefined,
      });
      updateAttendances();
      openModalConfirm({
        message: "Atendimento criado. Deseja iniciar o atendimento?",
        onConfirm: () =>
          router.push(`/atendimento-cliente-page?id=${data.id}&init=true`),
        confirmButtonStyle: { variant: undefined, leftIcon: <CheckIcon /> },
        rejectButtonStyle: { leftIcon: <CloseIcon w="12px" h="12px" /> },
      });

      onClose();
    } catch (e) {
      Toast({ title: "Erro ao criar atendimento", status: "error" });
    } finally {
      setModalLoading(false);
    }
  };

  const actions: any = {
    consultationData: () => {
      setRedBarSize("100%");
      setMode("consultationData");
    },
    createWithRegisteredCustomer: () => {
      setRedBarSize("66%");
    },
    createNewClient: () => {
      setRedBarSize("66%");
    },
    generateOfflineConsultation: () => {
      setRedBarSize("66%");
      setConsultationData(undefined);
    },
    start: () => {
      setRedBarSize("33%");
      setIsLoading(false);
      setDropdownConvenio(null);
      setInputCpf("");
      setTipoConsulta("");
      setConsultaMatriculaCPF("");
      setConsultationData(undefined);
      setSearchResult([]);
    },
  };

  const onChangeMode = (currentMode: CreateNewAttendanceParams["mode"]) => {
    historyPagination = mode;
    actions[currentMode]();
    setMode(currentMode);
  };

  useEffect(() => {
    if (consultationData != null) onChangeMode("consultationData");
  }, [consultationData]);

  const makeConsultation = async () => {
    const links: any = {
      INSS_CPF: `/consultas/inss/offline/cpf/${consultaMatriculaCPF}`,
      INSS_NB: `/consultas/inss/offline/nb/${consultaMatriculaCPF}`,
      SIAPE: `/consultas/siape/offline/cpf/${consultaMatriculaCPF}`,
    };
    setIsLoading(true);
    try {
      const { data } = await apiConsulta.get(links[tipoConsulta]);
      if (Array.isArray(data)) {
        if (data.length > 1) {
          openModalSelectBenefit({ consultList: data });
        } else {
          setConsultationData(data[0]);
        }
      } else {
        setConsultationData({ beneficio: data });
      }
      console.log(data);
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message;
      Toast({
        title:
          errorMessage?.indexOf("msg_interface:") !== -1
            ? errorMessage.replace("msg_interface:", "")
            : "Erro ao consultar",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleBack = () => {
    if (mode === "consultationData") {
      onChangeMode("generateOfflineConsultation");
      historyPagination = "start";
    } else onChangeMode(historyPagination);

    console.log(historyPagination);
  };

  const modalFooter =
    mode === "start" ? null : (
      <Flex alignItems="center" justifyContent="space-between" w="100%">
        <Button
          variant="outline"
          leftIcon={<ArrowBackIcon w="20px" h="20px" />}
          onClick={handleBack}
        >
          Voltar
        </Button>
        {mode === "createNewClient" ? (
          <Button
            leftIcon={<AddIcon w="12px" h="12px" />}
            isDisabled={
              (dropdownCPFNBValue === "CPF"
                ? inputCpf.replace(/\D/g, "").length !== 11
                : inputCpf.replace(/\D/g, "").length < 5) || !dropdownConvenio
            }
            onClick={createAttendance}
          >
            Criar Atendimento
          </Button>
        ) : null}
        {mode === "generateOfflineConsultation" ? (
          <Button
            px="25px"
            onClick={makeConsultation}
            isDisabled={!tipoConsulta || !consultaMatriculaCPF}
            isLoading={isLoading}
            loadingText="Consultando"
          >
            Consultar
          </Button>
        ) : null}
      </Flex>
    );

  return (
    <>
      <CustomModal
        isOpen={isOpen}
        modalTitle={
          <>
            <Box
              w="100%"
              h="7px"
              left="-1px"
              right="0px"
              pos="absolute"
              top="0"
            >
              <Box
                w={redBarSize}
                h="7px"
                bg="#B21F17"
                borderRadius="5px 5px 5px 0px"
                transition="0.2s"
                animation="widthProgress 0.2s"
              />
            </Box>
          </>
        }
        onClose={onClose}
        modalFooter={modalFooter}
        pos="relative"
        size="4xl"
        isLoading={modalLoading}
        containerProps={{ alignItems: "flex-start" }}
      >
        <Box pt="10px" w="100%">
          {mode === "start" ? (
            <Flex flexDir="column" w="100%" alignItems="center" pb="40px">
              <Text fontSize="24px" fontWeight="semibold" mb="50px">
                O que você deseja fazer?
              </Text>
              <Grid
                w="100%"
                templateColumns="1fr 1fr"
                maxW="calc(280px + 280px + 24px)"
                gap="24px"
              >
                <Center
                  flexDir="column"
                  alignItems="center"
                  border="1px solid #CED3DA"
                  borderRadius="5"
                  cursor="pointer"
                  h="132px"
                  _hover={{ border: "1px solid #B21F17" }}
                  onClick={() => {
                    onChangeMode("createWithRegisteredCustomer");
                  }}
                >
                  <PeopleHand />
                  <Text
                    textAlign="center"
                    fontSize="17"
                    mt="10px"
                    fontWeight="medium"
                  >
                    Iniciar atendimento com clientes cadastrados
                  </Text>
                </Center>
                <Center
                  flexDir="column"
                  alignItems="center"
                  border="1px solid #CED3DA"
                  borderRadius="5"
                  h="132px"
                  cursor="pointer"
                  _hover={{ border: "1px solid #B21F17" }}
                  onClick={() => {
                    onChangeMode("createNewClient");
                  }}
                >
                  <AddPerson />
                  <Text
                    textAlign="center"
                    fontSize="17"
                    mt="10px"
                    fontWeight="medium"
                  >
                    Cadastrar novo cliente
                  </Text>
                </Center>
                {/* <Center
                  gridArea="auto / span 2"
                  flexDir="column"
                  alignItems="center"
                  border="1px solid #CED3DA"
                  borderRadius="5"
                  h="132px"
                  cursor="pointer"
                  _hover={{ border: "1px solid #B21F17" }}
                  onClick={() => {
                    onChangeMode("generateOfflineConsultation");
                  }}
                >
                  <SearchIcon2 />
                  <Text
                    textAlign="center"
                    fontSize="17"
                    mt="10px"
                    fontWeight="medium"
                  >
                    Gerar consulta offline
                  </Text>
                </Center> */}
              </Grid>
            </Flex>
          ) : mode === "createNewClient" ? (
            <CreateNewClient
              inputCpf={inputCpf}
              setInputCpf={setInputCpf}
              dropdownConvenio={dropdownConvenio}
              setDropdownConvenio={setDropdownConvenio}
              dropdownCPFNBValue={dropdownCPFNBValue}
              setDropdownCPFNB={setDropdownCPFNB}
            />
          ) : mode === "createWithRegisteredCustomer" ? (
            <CreateWithRegisteredCustomer
              inputSearch={inputSearch}
              setInputSearch={setInputSearch}
              searchResult={searchResult}
              setSearchResult={setSearchResult}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setModalLoading={setModalLoading}
              onClose={onClose}
            />
          ) : mode === "generateOfflineConsultation" ? (
            <GenerateOfflineConsultation
              setTipoConsulta={setTipoConsulta}
              setConsultaMatriculaCPF={setConsultaMatriculaCPF}
              consultaMatriculaCPF={consultaMatriculaCPF}
              tipoConsulta={tipoConsulta}
              isLoading={isLoading}
              makeConsultation={makeConsultation}
            />
          ) : mode === "consultationData" ? (
            <ConsultationData
              consultationData={consultationData}
              isLoading={isLoading}
            />
          ) : null}
        </Box>
      </CustomModal>
      <ModalSelectBenefit
        setConsulta={setConsultationData}
        consulta={consultationData}
      />
    </>
  );
}

export const openNewAttendanceModal = () => makeEvent("openNewAttendanceModal");
