import {
  Box,
  Center,
  Grid,
  IconButton,
  InputProps,
  Text,
} from "@chakra-ui/react";
import { DropdownProps } from "components/dropdown";

import { SetStateAction, useEffect, useState } from "react";
import { CleanIcon } from "components/vectors/clean-icon";
import { InputField, InputFieldProps } from "components/input-field";
import { DropdownField } from "components/dropdown-field";
import { MailingOptionFilter } from "../..";

type FilterProps =
  | {
      type: "between";
      addedFilters: MailingOptionFilter;
      setAddedFilters: (
        newValue: SetStateAction<MailingOptionFilter>,
        key: string
      ) => void;
      valueType?: "number" | "date";
      componentProps?: { gte?: InputProps; lte?: InputProps };
      filterKey: string;
      isDisabled?: boolean;
      mask?: (value: any) => { raw: any; value: any };
      isChecked?: (value: any) => boolean;
      errorMessage: string | undefined;
    }
  | {
      type: "dropdown";
      addedFilters: MailingOptionFilter;
      setAddedFilters: (
        newValue: SetStateAction<MailingOptionFilter>,
        key: string
      ) => void;
      valueType?: "text";
      componentProps?: DropdownProps & { asyncOptions?: () => Promise<any> };
      filterKey: string;
      isDisabled?: boolean;
      mask?: (value: any) => { raw: any; value: any };
      isChecked?: (value: any) => boolean;
      errorMessage: string | undefined;
    }
  | {
      type: "input";
      addedFilters: MailingOptionFilter;
      setAddedFilters: (
        newValue: SetStateAction<MailingOptionFilter>,
        key: string
      ) => void;
      valueType?: "text" | "number";
      componentProps?: Partial<InputFieldProps>;
      filterKey: string;
      isDisabled?: boolean;
      mask?: (value: any) => { raw: any; value: any };
      isChecked?: (value: any) => boolean;
      errorMessage: string | undefined;
    };

export const Filter = ({
  type,
  addedFilters,
  setAddedFilters,
  valueType = "text",
  componentProps,
  filterKey,
  isDisabled,
  mask,
  isChecked,
  errorMessage,
}: FilterProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState<undefined | DropdownProps["options"]>(
    undefined
  );

  const set = ({ value }: { value: any }) => {
    setAddedFilters(
      (prev) => ({ ...prev, [filterKey]: { ...prev[filterKey], value } }),
      filterKey
    );
  };

  useEffect(() => {
    const props = componentProps as any;
    if (props?.asyncOptions) {
      const getData = async () =>
        setOptions(await props.asyncOptions(setIsLoading));
      getData();
    }
  }, []);

  if (type === "between") {
    const valueGte = addedFilters[filterKey].value.gte;
    const valueLte = addedFilters[filterKey].value.lte;
    const gte = mask?.(valueGte).value ?? valueGte ?? "";
    const lte = mask?.(valueLte).value ?? valueLte ?? "";
    const gteError = errorMessage?.includes(";")
      ? errorMessage
          .split(";")
          .find((curr) => curr.includes("gte:"))
          ?.replace("gte:", "")
      : errorMessage;
    const lteError = errorMessage?.includes(";")
      ? errorMessage
          .split(";")
          .find((curr) => curr.includes("lte:"))
          ?.replace("lte:", "")
      : errorMessage;

    return (
      <Grid templateColumns="1fr auto 1fr">
        <InputField
          onChange={({ target: { value } }) => {
            const filterValue = addedFilters[filterKey].value;
            const parseValue =
              valueType === "number"
                ? value.length
                  ? Number(value)
                  : ""
                : mask?.(value).raw ?? value;

            set({ value: { ...filterValue, gte: parseValue } });
          }}
          value={`${gte}` || ""}
          isDisabled={isDisabled}
          inputProps={{ ...componentProps?.gte, type: valueType }}
          errorMessage={gteError}
        />
        <Text mx="4px" my="auto">
          a
        </Text>
        <InputField
          onChange={({ target: { value } }) => {
            const filterValue = addedFilters[filterKey].value;
            const parseValue =
              valueType === "number"
                ? value.length
                  ? Number(value)
                  : ""
                : mask?.(value).raw ?? value;
            set({ value: { ...filterValue, lte: parseValue } });
          }}
          value={`${lte}` || ""}
          isDisabled={isDisabled}
          inputProps={{ ...componentProps?.lte, type: valueType }}
          errorMessage={lteError}
        />
      </Grid>
    );
  }
  if (type === "input") {
    const currentValue = addedFilters[filterKey].value;
    const value = mask?.(currentValue).value ?? currentValue;
    const { inputProps, ...inputFieldProps } = componentProps || {};
    return (
      <Box>
        <InputField
          onChange={({ target: { value } }) => {
            let maskParseValue = mask?.(value).raw ?? value;
            if (valueType === "number")
              maskParseValue = value.length ? Number(value) : "";
            set({ value: maskParseValue });
          }}
          value={`${value ?? ""}`}
          isDisabled={isDisabled}
          inputProps={{ type: "number", ...inputProps }}
          errorMessage={errorMessage}
          {...inputFieldProps}
        />
      </Box>
    );
  } else if (type === "dropdown") {
    const multiSelect = componentProps?.multiSelect ?? true;
    return (
      <Grid templateColumns="auto 32px" gap="8px">
        <DropdownField
          onChange={(value) => {
            const filterValue = addedFilters[filterKey].value as any[];
            const parsedValue = mask?.(value).raw ?? value;
            if (multiSelect) {
              if (filterValue?.length) {
                const isInclude = filterValue.includes(value);
                if (isInclude) {
                  const newValue = filterValue.filter(
                    (curr: any) => curr !== parsedValue
                  );
                  set({ value: newValue });
                } else {
                  const newValue = [...filterValue, parsedValue];
                  set({ value: newValue });
                }
              } else {
                const newValue = [parsedValue];
                set({ value: newValue });
              }
            } else {
              set({ value: parsedValue });
            }
          }}
          value={addedFilters[filterKey].value}
          options={componentProps?.options || options}
          errorMessage={errorMessage}
          dropdownProps={{
            children: multiSelect
              ? addedFilters[filterKey].value.length > 1
                ? `${addedFilters[filterKey].value.length} selecionados`
                : componentProps?.options
                    ?.filter(({ name, value }) =>
                      addedFilters[filterKey].value?.includes(value)
                    )
                    .map(({ name }) => name)
                    .join(",")
              : null,
            w: "100%",
            multiSelect: true,
            isDisabled,
            isLoading,
            loadingText: "Selecione",
            spinnerPlacement: "end",
            ...componentProps,
          }}
        />
        <Center>
          <IconButton
            aria-label=""
            variant="outline"
            size="sm"
            icon={<CleanIcon />}
            onClick={() => {
              set({ value: [] });
            }}
            disabled={isDisabled}
          />
        </Center>
      </Grid>
    );
  }
  return <></>;
};
