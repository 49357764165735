export const attandanceOrigemEnum = new Map([
  ["WABOX", "WhatsApp"],
  ["TELEFONIA", "Telefonia"],
  ["LANDING_PAGE", "Landing Page"],
  ["MANUAL", "Manual"],
  ["WHATSAPP", "WhatsApp"],
  ["URA_WHATSAPP", "WhatsApp URA"],
  ["SMS", "SMS"],
  ["CARTEIRA", "Carteira"],
  ["PIPELINE", "Pipeline"],
  ["SERODONTO", "Serodonto"],
]);
