export function PeopleHand() {
  return (
    <svg
      width="39px"
      height="31px"
      viewBox="0 0 39 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="13.5" cy="14.5" r="13.5" fill="#B21F17" fillOpacity="0.1" />
      <path
        d="M37.1302 17.6354C36.3618 17.1756 35.435 17.1601 34.6914 17.5619L26.6185 21.4906C26.3596 20.2983 25.2992 19.4021 24.0303 19.4021H19.6975L17.8874 17.5919C17.6885 17.3931 17.4193 17.2812 17.1376 17.2812H12.896V16.2187C12.896 15.6326 12.4217 15.1583 11.8355 15.1583H9.71471C9.12858 15.1583 8.6543 15.6326 8.6543 16.2187C8.6543 16.8049 9.12858 17.2792 9.71471 17.2792H10.7751V26.8229H9.71471C9.12858 26.8229 8.6543 27.2972 8.6543 27.8833C8.6543 28.4694 9.12858 28.9437 9.71471 28.9437H11.8355C12.4217 28.9437 12.896 28.4694 12.896 27.8833V26.8239H14.7683L19.6137 29.2254C20.6616 29.7453 21.7645 30 22.8508 30C24.3648 30 25.8477 29.505 27.1059 28.5388L37.2659 21.818C37.9421 21.352 38.3459 20.5867 38.3459 19.7697C38.3459 18.8863 37.8913 18.089 37.1302 17.6354ZM36.0801 20.0596L25.8757 26.8125C24.2882 28.0304 22.3475 28.2147 20.556 27.3262L15.488 24.8139C15.3409 24.7404 15.1804 24.7031 15.0168 24.7031H12.896V19.4021H16.6985L18.5087 21.2122C18.7075 21.411 18.9768 21.5229 19.2585 21.5229H24.0303C24.3224 21.5229 24.5605 21.7611 24.5605 22.0531C24.5605 22.3451 24.3224 22.5833 24.0303 22.5833H19.2585C18.6723 22.5833 18.198 23.0576 18.198 23.6437C18.198 24.2299 18.6723 24.7041 19.2585 24.7041H24.0303C24.1092 24.7041 24.1834 24.6877 24.2605 24.6809C24.5061 24.7542 24.7767 24.7467 25.0245 24.6254L35.6597 19.4487C35.8316 19.3555 35.9735 19.4145 36.0449 19.4569C36.1122 19.4963 36.2251 19.5905 36.2251 19.7697C36.2251 19.8888 36.165 20.0006 36.0801 20.0596Z"
        fill="black"
        stroke="white"
        strokeWidth="0.458558"
      />
      <path
        d="M26.6813 3.49375C26.6813 1.7395 25.2543 0.3125 23.5001 0.3125C21.7459 0.3125 20.3188 1.7395 20.3188 3.49375C20.3188 5.24799 21.7459 6.675 23.5001 6.675C25.2543 6.675 26.6813 5.24799 26.6813 3.49375ZM23.5001 4.55416C22.915 4.55416 22.4397 4.07884 22.4397 3.49375C22.4397 2.90865 22.915 2.43333 23.5001 2.43333C24.0852 2.43333 24.5605 2.90865 24.5605 3.49375C24.5605 4.07884 24.0852 4.55416 23.5001 4.55416Z"
        fill="black"
        stroke="white"
        strokeWidth="0.458558"
      />
      <path
        d="M18.1978 9.85624V11.9771C18.1978 12.5632 18.672 13.0375 19.2582 13.0375C19.8443 13.0375 20.3186 12.5632 20.3186 11.9771V9.85624C20.3186 9.27114 20.7939 8.79582 21.379 8.79582H25.6207C26.2058 8.79582 26.6811 9.27114 26.6811 9.85624V11.9771C26.6811 12.5632 27.1554 13.0375 27.7415 13.0375C28.3276 13.0375 28.8019 12.5632 28.8019 11.9771V9.85624C28.8019 8.10199 27.3749 6.67499 25.6207 6.67499H23.4998H21.379C19.6248 6.67499 18.1978 8.10199 18.1978 9.85624Z"
        fill="black"
        stroke="white"
        strokeWidth="0.458558"
      />
      <path
        d="M35.1647 3.49375C35.1647 1.7395 33.7377 0.3125 31.9835 0.3125C30.2293 0.3125 28.8022 1.7395 28.8022 3.49375C28.8022 5.24799 30.2293 6.675 31.9835 6.675C33.7377 6.675 35.1647 5.24799 35.1647 3.49375ZM31.9835 4.55416C31.3984 4.55416 30.9231 4.07884 30.9231 3.49375C30.9231 2.90865 31.3984 2.43333 31.9835 2.43333C32.5686 2.43333 33.0439 2.90865 33.0439 3.49375C33.0439 4.07884 32.5686 4.55416 31.9835 4.55416Z"
        fill="black"
        stroke="white"
        strokeWidth="0.458558"
      />
      <path
        d="M28.8022 7.7354C28.8022 8.32153 29.2765 8.79582 29.8627 8.79582H34.1043C34.6894 8.79582 35.1647 9.27114 35.1647 9.85624V11.9771C35.1647 12.5632 35.639 13.0375 36.2252 13.0375C36.8113 13.0375 37.2856 12.5632 37.2856 11.9771V9.85624C37.2856 8.10199 35.8586 6.67499 34.1043 6.67499H31.9835H29.8627C29.2765 6.67499 28.8022 7.14928 28.8022 7.7354Z"
        fill="black"
        stroke="white"
        strokeWidth="0.458558"
      />
      <path
        d="M18.1979 3.49375C18.1979 1.7395 16.7709 0.3125 15.0167 0.3125C13.2625 0.3125 11.8354 1.7395 11.8354 3.49375C11.8354 5.24799 13.2625 6.675 15.0167 6.675C16.7709 6.675 18.1979 5.24799 18.1979 3.49375ZM15.0167 4.55416C14.4316 4.55416 13.9563 4.07884 13.9563 3.49375C13.9563 2.90865 14.4316 2.43333 15.0167 2.43333C15.6018 2.43333 16.0771 2.90865 16.0771 3.49375C16.0771 4.07884 15.6018 4.55416 15.0167 4.55416Z"
        fill="black"
        stroke="white"
        strokeWidth="0.458558"
      />
      <path
        d="M10.7748 13.0375C11.3609 13.0375 11.8352 12.5632 11.8352 11.9771V9.85624C11.8352 9.27114 12.3105 8.79582 12.8956 8.79582H17.1373C17.7234 8.79582 18.1977 8.32153 18.1977 7.7354C18.1977 7.14928 17.7234 6.67499 17.1373 6.67499H15.0164H12.8956C11.1414 6.67499 9.71436 8.10199 9.71436 9.85624V11.9771C9.71436 12.5632 10.1886 13.0375 10.7748 13.0375Z"
        fill="black"
        stroke="white"
        strokeWidth="0.458558"
      />
    </svg>
  );
}
