import {
  Button,
  ButtonProps,
  IconButton,
  IconButtonProps,
  Tooltip,
} from "@chakra-ui/react";
import { MouseEvent, useState } from "react";

export function IconButtonWithLoading({
  children,
  onClick,
  tooltipMessage,
  ...rest
}: Omit<IconButtonProps, "onClick"> & {
  onClick?: (e: MouseEvent) => Promise<any>;
  tooltipMessage?: string;
}) {
  const [isLoading, setLoading] = useState(false);
  return tooltipMessage ? (
    <Tooltip label={tooltipMessage}>
      <IconButton
        onClick={async (e) => {
          setLoading(true);
          await onClick?.(e);
          setLoading(false);
        }}
        isLoading={isLoading}
        {...rest}
      >
        {children}
      </IconButton>
    </Tooltip>
  ) : (
    <IconButton
      onClick={async (e) => {
        setLoading(true);
        await onClick?.(e);
        setLoading(false);
      }}
      isLoading={isLoading}
      {...rest}
    >
      {children}
    </IconButton>
  );
}
export function ButtonWithLoading({
  children,
  onClick,
  tooltipMessage,
  ...rest
}: Omit<ButtonProps, "onClick"> & {
  onClick?: (e: MouseEvent) => Promise<any>;
  tooltipMessage?: string;
}) {
  const [isLoading, setLoading] = useState(false);
  return tooltipMessage ? (
    <Tooltip label={tooltipMessage}>
      <Button
        onClick={async (e) => {
          setLoading(true);
          await onClick?.(e);
          setLoading(false);
        }}
        isLoading={isLoading}
        {...rest}
      >
        {children}
      </Button>
    </Tooltip>
  ) : (
    <Button
      onClick={async (e) => {
        setLoading(true);
        await onClick?.(e);
        setLoading(false);
      }}
      isLoading={isLoading}
      {...rest}
    >
      {children}
    </Button>
  );
}
