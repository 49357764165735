import { Icon, IconProps } from "@chakra-ui/icons";

export function InfoIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="24px"
      height="24px"
      viewBox="0 0 93 93"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M46.5 93C72.1812 93 93 72.1812 93 46.5C93 20.8188 72.1812 0 46.5 0C20.8188 0 0 20.8188 0 46.5C0 72.1812 20.8188 93 46.5 93ZM42.56 27.04C43.5733 28.0533 44.9067 28.56 46.56 28.56C48.16 28.56 49.4933 28.0533 50.56 27.04C51.6266 25.9733 52.16 24.6133 52.16 22.96C52.16 21.3066 51.6266 19.9733 50.56 18.96C49.4933 17.9467 48.16 17.44 46.56 17.44C44.9067 17.44 43.5733 17.9467 42.56 18.96C41.5467 19.9733 41.04 21.3066 41.04 22.96C41.04 24.6133 41.5467 25.9733 42.56 27.04ZM35.84 35.44V37.68L40.24 38.24C41.0933 38.3467 41.6 38.5867 41.76 38.96C41.92 39.28 42 40.3467 42 42.16V69.28C42 71.0933 41.92 72.1866 41.76 72.56C41.6 72.88 41.0933 73.0933 40.24 73.2L35.84 73.76V76H57.36V73.76L52.96 73.2C52.1067 73.0933 51.6 72.88 51.44 72.56C51.28 72.1866 51.2 71.0933 51.2 69.28V35.44H35.84Z"
        fill={"currentColor"}
      />
    </Icon>
  );
}
