import {
  AddIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Checkbox } from "components/checkbox";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import DynamicTable from "components/dynamic-table";
import { GetLayout } from "components/get-layout";
import { ConfirmDialog } from "components/modal-confirm";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useEffect, useState } from "react";
import { FaMoneyBill, FaSave } from "react-icons/fa";
import api from "../api/api";
import { openModalConfirm } from "components/modal-confirm-new";
import { IconButtonWithLoading } from "components/mailing-components/mailing-table/icon-button-with-loading";
import { Toast } from "components/toast";

export function Empresas() {
  const [confirmDialogData, setConfirmDialogData] = useState(null);
  const { user } = useApplicationContext();
  const { userData } = user;
  const [empresas, setEmpresas] = useState([]);
  const [comerciais, setComerciais] = useState([]);
  const [regionais, setRegionais] = useState([]);
  const [empresaDialog, setEmpresaDialog] = useState({
    id: "",
    enabled: true,
    name: "",
    codigoNova: "",
    email: "",
    telefone: "",
    regionalCustomer: { id: userData.customerId },
    enabled3cplus: false,
    token3cplus: "",
  });
  const [openEmpresaDialog, setOpenEmpresaDialog] = useState(false);
  const [loadingCreditar, setLoadingCreditar] = useState(false);
  const [openSaldosDialog, setOpenSaldosDialog] = useState(false);
  const [dataSaldosDialog, setDataSaldosDialog] = useState(false);
  const [filtros, setFiltros] = useState({ nomeOrCodigo: "" });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({ name: false, token3cplus: false });
  const [loadingSave, setLoadingSave] = useState(false);
  const {
    isOpen: isOpenPopover,
    onToggle: onTogglePopover,
    onClose: onClosePopover,
  } = useDisclosure();

  const getFilteredEmpresas = () => {
    let filteredCustomers = [...empresas];
    if (filtros.nomeOrCodigo.trim() !== "") {
      filteredCustomers = filteredCustomers.filter(
        (c) =>
          (c.name || "")
            .toUpperCase()
            .includes(filtros.nomeOrCodigo.trim().toUpperCase()) ||
          (c.codigoNova || "")
            .toUpperCase()
            .includes(filtros.nomeOrCodigo.trim().toUpperCase())
      );
    }
    return filteredCustomers;
  };

  const onChangeFiltro = (field, e) => {
    setFiltros({ ...filtros, [field]: e.target.value });
  };

  const loadEmpresas = async () => {
    try {
      let response = await api.get("/customers");
      setEmpresas(response.data);
    } catch (error) {
      setEmpresas([]);
      Toast({
        title: "Erro ao carregar empresas",
        status: "error",
      });
    }
  };

  const atualizar = async () => {
    setLoading(true);
    await loadEmpresas();
    setLoading(false);
  };

  const loadRegionais = async () => {
    try {
      const { data } = await api.get("/customers/regionais");
      setRegionais(data);
    } catch (error) {
      Toast({
        title: "Erro ao carregar regionais",
        status: "error",
      });
    }
  };

  const loadComerciais = async () => {
    try {
      const { data } = await api.get("/usuarios/comerciais");
      setComerciais(data);
    } catch (error) {
      Toast({
        title: "Erro ao carregar comerciais",
        status: "error",
      });
    }
  };

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await loadComerciais();
      await loadRegionais();
      await loadEmpresas();
      setLoading(false);
    };
    load();
  }, []);

  const openNew = () => {
    setErrors({});
    setEmpresaDialog({
      id: "",
      enabled: true,
      name: "",
      codigoNova: "",
      email: "",
      telefone: "",
      enabled3cplus: false,
      token3cplus: "",
      regionalCustomer: { id: userData.customerId },
    });
    setOpenEmpresaDialog(true);
  };

  const hideDialog = () => {
    setOpenEmpresaDialog(false);
  };

  const hideSaldoDialog = () => {
    setOpenSaldosDialog(false);
  };

  const onChangeEmpresaDialog = (field, value) => {
    setErrors({ ...errors, [field]: false });

    if ("regionalCustomer" === field) {
      setEmpresaDialog((prevState) => ({
        ...prevState,
        regionalCustomer: { id: value },
      }));
    } else if ("comercialUser" === field) {
      setEmpresaDialog((prevState) => ({
        ...prevState,
        comercialUser: { id: value },
      }));
    } else {
      setEmpresaDialog((prevState) => ({ ...prevState, [field]: value }));
    }
  };

  const onChangeEmpresaDialogCheckbox = (field, isChecked) => {
    setEmpresaDialog({ ...empresaDialog, [field]: isChecked });
  };

  const saveOrUpdateEmpresa = async () => {
    const hasNameError =
      !empresaDialog.name || empresaDialog.name?.trim().length === 0;
    const hasTokenError =
      empresaDialog.enabled3cplus && !empresaDialog.token3cplus?.trim().length;
    setErrors({
      name: hasNameError,
      token3cplus: hasTokenError,
      codigoNova: !empresaDialog.codigoNova,
    });
    if ([hasNameError, hasTokenError, !empresaDialog.codigoNova].includes(true))
      return;
    let tempEmpresa = {
      ...empresaDialog,
      token3cplus: empresaDialog.token3cplus?.trim(),
      name: empresaDialog.name?.trim(),
      codigoNova: empresaDialog.codigoNova?.trim(),
      email: empresaDialog.email?.trim(),
      telefone: empresaDialog.telefone?.trim(),
    };
    setLoadingSave(true);
    try {
      if (!empresaDialog.id) {
        await api.post("/customers", tempEmpresa);
      } else {
        await api.put(`/customers/${tempEmpresa.id}`, tempEmpresa);
      }
      setOpenEmpresaDialog(false);
      await loadEmpresas();
    } catch (error) {
      const errorMessage = {
        codigo_nova_exists: "Código Nova já cadastrado",
        not_allowed_create_colombo: "Código Nova não permitido",
        allowed_only_create_colombo: "Código Nova não permitido",
      };
      if (error?.response?.data?.message === "codigo_nova_exists")
        setErrors({ ...errors, codigoNova: true });
      Toast({
        title:
          errorMessage[error?.response?.data?.message] ??
          "Erro ao salvar empresa!",
        status: "error",
      });
    } finally {
      setLoadingSave(false);
    }
  };

  const editEmpresa = (empresa) => {
    setErrors({ name: "" });
    setEmpresaDialog({ ...empresa });
    setOpenEmpresaDialog(true);
  };

  const editSaldos = async (empresa) => {
    let saldoExtratoOnline = 0;
    let responseExtratoOnline;
    try {
      responseExtratoOnline = await api.get("/extratos/saldo/" + empresa.id);
      saldoExtratoOnline = responseExtratoOnline.data;
    } catch (error) {
      Toast({
        title: "Não foi possível verificar o saldo do extrato online",
        status: "error",
      });
      return;
    }

    setDataSaldosDialog({
      qtdCreditoAddApiBmgCartaoSaque: 0,
      qtdCreditoAddApiInssHistOff: 0,
      creditoApiBmgCartaoSaque: empresa.creditoApiBmgCartaoSaque,
      creditoApiInssHistOff: empresa.creditoApiInssHistOff,
      creditoExtratoOnline: saldoExtratoOnline,
      customerId: empresa.id,
    });
    setOpenSaldosDialog(true);
  };

  const creditarInssHistOff = async () => {
    setLoading(true);
    let response = await api.get(
      "/customers/" +
        dataSaldosDialog.customerId +
        "/credito-inss-hist-off/" +
        dataSaldosDialog.qtdCreditoAddApiInssHistOff
    );
    setDataSaldosDialog({
      ...dataSaldosDialog,
      creditoApiInssHistOff: response.data,
    });
    await loadEmpresas();
    setLoading(false);
  };

  const creditarBmgSaque = async () => {
    setLoading(true);
    let response = await api.get(
      "/customers/" +
        dataSaldosDialog.customerId +
        "/credito-bmg-cartao-saque/" +
        dataSaldosDialog.qtdCreditoAddApiBmgCartaoSaque
    );
    setDataSaldosDialog({
      ...dataSaldosDialog,
      creditoApiBmgCartaoSaque: response.data,
    });
    await loadEmpresas();
    setLoading(false);
  };

  const creditarExtratoOnline = async () => {
    setLoading(true);
    setLoadingCreditar(true);
    try {
      await api.get(
        "/extratos/creditar/" +
          dataSaldosDialog.customerId +
          "?qtd=" +
          dataSaldosDialog.qtdCreditoAddExtratoOnline
      );
      let response = await api.get(
        "/extratos/saldo/" + dataSaldosDialog.customerId
      );
      setDataSaldosDialog({
        ...dataSaldosDialog,
        creditoExtratoOnline: response.data,
      });
      Toast({
        title: "Saldo creditado com sucesso",
        status: "success",
      });
      onClosePopover();
    } catch (e) {
      Toast({
        title: "Erro ao creditar saldo",
        status: "error",
      });
    } finally {
      setLoadingCreditar(false);
      setLoading(false);
      await loadEmpresas();
    }
  };

  const acceptDeleteEmpresa = async (rowData) => {
    setLoading(true);
    try {
      await api.delete("/customers/" + rowData.id);
    } catch (error) {
      Toast({
        title: "Erro ao excluir empresa!",
        status: "error",
      });
    }
    await loadEmpresas();
    setLoading(false);
  };

  const confirmDeleteEmpresa = (rowData) => {
    openModalConfirm({
      message: `Deseja excluir "${rowData.name}"?`,
      onConfirm: () => acceptDeleteEmpresa(rowData),
      confirmButtonStyle: { variant: "danger", leftIcon: <DeleteIcon /> },
    });
  };

  const empresaDialogFooter = (
    <>
      <Button
        leftIcon={<FaSave />}
        onClick={saveOrUpdateEmpresa}
        isLoading={loadingSave}
        loadingText="Salvando"
      >
        Salvar
      </Button>
      <Button
        variant="outline"
        leftIcon={<CloseIcon w="11px" h="11px" />}
        onClick={hideDialog}
      >
        Cancelar
      </Button>
    </>
  );

  const saldosDialogFooter = (
    <Button
      leftIcon={<CloseIcon w="11px" h="11px" />}
      variant="outline"
      onClick={hideSaldoDialog}
    >
      Fechar
    </Button>
  );

  const columns = [
    {
      name: "Ativo",
      render: (row) => (row.enabled ? "SIM" : "NÃO"),
    },
    {
      name: "Cód. Nova",
      key: "codigoNova",
    },
    {
      name: "Nome",
      key: "name",
    },
    {
      name: "Regional",
      render: (row) => row.regionalCustomer && row.regionalCustomer.name,
    },
    {
      name: "Ações",
      render: (row) => {
        return (
          <>
            <IconButton
              icon={<EditIcon />}
              mr="8px"
              onClick={() => editEmpresa(row)}
            />
            <IconButtonWithLoading
              icon={<FaMoneyBill />}
              mr="8px"
              onClick={async () => await editSaldos(row)}
            />
            <IconButton
              icon={<DeleteIcon />}
              variant="danger"
              onClick={() => confirmDeleteEmpresa(row)}
            />
          </>
        );
      },
    },
  ];

  return (
    <>
      <ConfirmDialog dialogData={confirmDialogData} />
      <GetLayout>
        <Text fontSize="25" fontWeight="bold" mb="20px">
          Empresas
        </Text>

        <Grid templateColumns="1fr 1fr 1fr" gap="16px" mb="30px">
          <Box>
            <Text mb="8px">Filtrar por Nome ou Código Nova:</Text>
            <Input
              value={filtros.nomeOrCodigo || ""}
              onChange={(e) => onChangeFiltro("nomeOrCodigo", e)}
            />
          </Box>
          <Box>
            <Text mb="8px">&nbsp;</Text>
            <Button w="100%" leftIcon={<AddIcon />} onClick={openNew}>
              Adicionar Empresa
            </Button>
          </Box>
          <Box>
            <Text mb="8px">&nbsp;</Text>
            <Button
              w="100%"
              leftIcon={<RepeatIcon />}
              onClick={atualizar}
              isLoading={loading}
              loadingText="Atualizando"
            >
              Atualizar
            </Button>
          </Box>
        </Grid>
        <DynamicTable
          rows={getFilteredEmpresas()}
          columns={columns}
          isLoading={loading}
        />
        <CustomModal
          isOpen={openEmpresaDialog}
          modalTitle="Empresa"
          size="4xl"
          modalFooter={empresaDialogFooter}
          onClose={hideDialog}
        >
          <Grid templateColumns="85px auto" gap="16px" mb="16px">
            <Checkbox
              isChecked={empresaDialog.enabled}
              onChange={(isChecked) =>
                onChangeEmpresaDialogCheckbox("enabled", isChecked)
              }
            >
              Ativo
            </Checkbox>
            <Box>
              <Text mb="8px">Nome da Empresa</Text>
              <Input
                value={empresaDialog.name || ""}
                isInvalid={errors.name}
                onChange={(e) => onChangeEmpresaDialog("name", e.target.value)}
              />
            </Box>
          </Grid>
          <Grid templateColumns="1fr 1fr" gap="16px" mb="16px">
            <Box>
              <Text mb="8px">CNPJ</Text>
              <Input
                value={empresaDialog.cnpj || ""}
                onChange={(e) => onChangeEmpresaDialog("cnpj", e.target.value)}
              />
            </Box>
            <Box>
              <Text mb="8px">Código Nova</Text>
              <Input
                value={empresaDialog.codigoNova || ""}
                autoComplete="new-password"
                onChange={(e) => {
                  onChangeEmpresaDialog("codigoNova", e.target.value);
                }}
                isInvalid={errors.codigoNova}
              />
            </Box>
          </Grid>
          <Grid templateColumns="1fr 1fr" gap="16px" mb="16px">
            <Box>
              <Text mb="8px">Regional Responsável</Text>
              <Dropdown
                w="100%"
                value={empresaDialog.regionalCustomer?.id}
                options={regionais}
                optionValue="id"
                disabled={regionais && regionais.length === 1}
                onChange={(value) =>
                  onChangeEmpresaDialog("regionalCustomer", value)
                }
                placeholder="Selecione..."
              />
            </Box>
            <Box>
              <Text mb="8px">Comercial Responsável</Text>
              <Dropdown
                w="100%"
                value={empresaDialog.comercialUser?.id}
                options={comerciais}
                optionValue="id"
                id="comercialUserDialog"
                onChange={(value) =>
                  onChangeEmpresaDialog("comercialUser", value)
                }
                placeholder="Selecione..."
              />
            </Box>
          </Grid>
          <Grid templateColumns="1fr 1fr" gap="16px" mb="16px">
            <Box>
              <Text mb="8px">E-mail</Text>
              <Input
                value={empresaDialog.email || ""}
                onChange={(e) => onChangeEmpresaDialog("email", e.target.value)}
              />
            </Box>
            <Box>
              <Text mb="8px">Telefone</Text>
              <Input
                value={empresaDialog.telefone || ""}
                onChange={(e) =>
                  onChangeEmpresaDialog("telefone", e.target.value)
                }
              />
            </Box>
          </Grid>
          <Grid templateColumns="85px auto" gap="16px" mb="16px">
            <Box>
              <Text mb="8px">&nbsp;</Text>
              <Checkbox
                isChecked={empresaDialog.enabled3cplus}
                onChange={(isChecked) =>
                  onChangeEmpresaDialogCheckbox("enabled3cplus", isChecked)
                }
              >
                3cplus
              </Checkbox>
            </Box>
            <Box>
              <Text mb="8px">Token 3cplus</Text>
              <Input
                value={empresaDialog.token3cplus || ""}
                isInvalid={errors.token3cplus}
                onChange={(e) =>
                  onChangeEmpresaDialog("token3cplus", e.target.value)
                }
              />
            </Box>
          </Grid>
          {/* {!empresaDialog.id && (
            <>
              <Box mb="16px">
                <Text mb="8px">Créditos Higienização</Text>
                <Input
                  id="creditoHigienizacaoBancoDialog"
                  value={empresaDialog.creditoHigienizacaoBanco || ""}
                  disabled
                  autoComplete="new-password"
                />
              </Box>
              <Box mb="16px">
                <Text mb="8px">Créditos Simulação</Text>
                <Input
                  id="creditoApiInssHistOffDialog"
                  value={empresaDialog.creditoApiInssHistOff || ""}
                  onChange={(e) =>
                    onChangeEmpresaDialog(
                      "creditoApiInssHistOff",
                      e.target.value
                    )
                  }
                  disabled
                  autoComplete="new-password"
                />
              </Box>
              <Box mb="16px">
                <Text mb="8px">Créditos Bmg Cartão Saque</Text>
                <Input
                  value={empresaDialog.creditoApiBmgCartaoSaque || ""}
                  onChange={(e) =>
                    onChangeEmpresaDialog(
                      "creditoApiBmgCartaoSaque",
                      e.target.value
                    )
                  }
                  disabled
                  autoComplete="new-password"
                />
              </Box>
            </>
          )} */}
        </CustomModal>
        <CustomModal
          isOpen={openSaldosDialog}
          size="md"
          modalTitle="Saldos"
          modalFooter={saldosDialogFooter}
          onClose={hideSaldoDialog}
        >
          <Box>
            <Text mb="8px">Saldo Extrato Online</Text>
            <Flex
              h="40px"
              alignItems="center"
              justifyContent="space-between"
              p="0 8px 0 16px"
              borderColor="gray.300"
              borderWidth="1px"
              borderRadius="6px"
              w="100%"
            >
              <Text>{dataSaldosDialog.creditoExtratoOnline}</Text>
              <Popover isOpen={isOpenPopover} onClose={onClosePopover}>
                <PopoverTrigger>
                  <IconButton
                    icon={<AddIcon />}
                    variant="outline"
                    size="xs"
                    disabled={"FINANCEIRO" !== userData.type}
                    title={
                      "FINANCEIRO" !== userData.type
                        ? "Opção não permitida"
                        : undefined
                    }
                    onClick={onTogglePopover}
                  />
                </PopoverTrigger>
                <PopoverContent w="400px">
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader>Adicionar Saldo Extrato Online</PopoverHeader>
                  <PopoverBody>
                    <Grid templateColumns="1fr 115px" gap="8px">
                      <Input
                        type="number"
                        id="addExtratoOnline"
                        value={
                          dataSaldosDialog.qtdCreditoAddExtratoOnline || ""
                        }
                        autoComplete="new-password"
                        disabled={"FINANCEIRO" !== userData.type}
                        onChange={({ target }) => {
                          target.value = target.value.replace(/\D/g, "");
                          setDataSaldosDialog({
                            ...dataSaldosDialog,
                            qtdCreditoAddExtratoOnline: Number(target.value),
                          });
                        }}
                        size="sm"
                        borderRadius="4"
                      />
                      <Button
                        leftIcon={<AddIcon w="11px" h="11px" />}
                        disabled={"FINANCEIRO" !== userData.type}
                        onClick={creditarExtratoOnline}
                        size="sm"
                        isLoading={loadingCreditar}
                        loadingText="Adicionando"
                      >
                        Adicionar
                      </Button>
                    </Grid>
                  </PopoverBody>
                </PopoverContent>
              </Popover>
            </Flex>
          </Box>
        </CustomModal>
      </GetLayout>
    </>
  );
}
