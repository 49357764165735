// passar array, texto para pesquisa e as chaves (nomes das propriedades) que seram procuradas

export function filterArrayBy(
  array: any[] = [{}],
  searchText: string = "",
  findKeys: string[] = [],
  options: { partialSearch: boolean; sortBy?: string } = {
    partialSearch: false,
  }
) {
  if (options.partialSearch) {
    let resultArray: any[] = [];
    let indexes: number[] = [];
    const search = searchText
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");

    for (let key of findKeys) {
      array.forEach((current, index) => {
        if (typeof current[key] === "string")
          if (
            current[key]
              ?.toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(search)
          )
            indexes.push(index);
      });
    }
    let uniqIndexes: number[] = [...new Set(indexes)];
    for (let index of uniqIndexes) resultArray.push(array[index]);
    if (options.sortBy) return sortArrayBy(resultArray, options.sortBy);
    return resultArray;
  } else {
    let resultArray: any[] = [];
    let indexList: { index: number; points: number }[] = [];
    const search = searchText.toLowerCase();

    for (let key of findKeys) {
      array.forEach((current, index) => {
        const targetText = (current[key] as string) || "";
        const searchTarget = normalizeText(targetText).split(" ");
        const words = search
          .split(" ")
          .filter((w) => !!w)
          .map((w) => normalizeText(w));

        let isMatched = false;
        let points = 0;

        words.forEach((word, i, wArr) => {
          if (searchTarget.includes(word)) {
            isMatched = true;
            points += 100;
            if (targetText.length >= searchText.length)
              points += 100 - (targetText.length - searchText.length);
          }
        });
        if (isMatched) {
          indexList.push({ index, points });
        }
      });
    }

    let uniqIndexList: number[] = [
      ...new Set(
        sortArrayBy(indexList, "points", "DESC").map(({ index }) => index)
      ),
    ];
    for (let resultPart of uniqIndexList) resultArray.push(array[resultPart]);

    return resultArray;
  }
}

export const normalizeText = (
  text: string,
  lowerUpper: "toLowerCase" | "toUpperCase" = "toLowerCase"
) =>
  `${text}`
    ?.[lowerUpper]()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .trim();

export function sortArrayBy(
  array: any[],
  sortBy: string,
  sortOrder: "ASC" | "DESC" = "ASC"
) {
  return array.sort((a, b) => {
    let curr = a[sortBy];
    let next = b[sortBy];
    if (curr === null) return sortOrder === "ASC" ? -1 : 1;
    if (next === null) return sortOrder === "ASC" ? 1 : -1;
    if (curr < next) return sortOrder === "ASC" ? -1 : 1;
    if (curr > next) return sortOrder === "ASC" ? 1 : -1;

    return 0;
    // return sortOrder === "ASC" ? curr - next : next - curr;
  });
}
