import { Stack } from "@chakra-ui/react";
import { useApplicationContext } from "contexts/ApplicationContext";
import { menuArray } from "./menu-array";
import { memo } from "react";
import { NewMenuItem } from "./new-menu-item";
import { checkIsVisible } from "./check-is-visible";

export const menuWidth = "290px";

function SideMenu() {
  const { user } = useApplicationContext();

  return (
    <Stack
      pos="relative"
      h="100%"
      borderRight="1px solid #E8EAED"
      pt="20px"
      bg="#fff"
      w={{ base: "100px" }}
      flexDir="column"
      alignItems="center"
      spacing="8px"
      zIndex={11}
    >
      {menuArray.map((item, index) => {
        const isVisible = checkIsVisible(item, user);
        return isVisible ? <NewMenuItem key={index} {...item} /> : null;
      })}
    </Stack>
  );
}

export default memo(SideMenu);
