export const paisesList = [
  { name: "Afeganistão", value: "1" },
  { name: "África do Sul", value: "2" },
  { name: "Albânia", value: "3" },
  { name: "Alemanha", value: "4" },
  { name: "Andorra", value: "5" },
  { name: "Angola", value: "6" },
  { name: "Antiga e Barbuda", value: "7" },
  { name: "Arábia Saudita", value: "8" },
  { name: "Argélia", value: "9" },
  { name: "Argentina", value: "10" },
  { name: "Arménia", value: "11" },
  { name: "Austrália", value: "12" },
  { name: "Áustria", value: "13" },
  { name: "Azerbaijão", value: "14" },
  { name: "Bahamas", value: "15" },
  { name: "Bangladexe", value: "16" },
  { name: "Barbados", value: "17" },
  { name: "Barém", value: "18" },
  { name: "Bélgica", value: "19" },
  { name: "Belize", value: "20" },
  { name: "Benim", value: "21" },
  { name: "Bielorrússia", value: "22" },
  { name: "Bolívia", value: "23" },
  { name: "Bósnia e Herzegovina", value: "24" },
  { name: "Botsuana", value: "25" },
  { name: "Brasil", value: "26" },
  { name: "Brunei", value: "27" },
  { name: "Bulgária", value: "28" },
  { name: "Burquina Faso", value: "29" },
  { name: "Burúndi", value: "30" },
  { name: "Butão", value: "31" },
  { name: "Cabo Verde", value: "32" },
  { name: "Camarões", value: "33" },
  { name: "Camboja", value: "34" },
  { name: "Canadá", value: "35" },
  { name: "Catar", value: "36" },
  { name: "Cazaquistão", value: "37" },
  { name: "Chade", value: "38" },
  { name: "Chile", value: "39" },
  { name: "China", value: "40" },
  { name: "Chipre", value: "41" },
  { name: "Colômbia", value: "42" },
  { name: "Comores", value: "43" },
  { name: "Congo-Brazzaville", value: "44" },
  { name: "Coreia do Norte", value: "45" },
  { name: "Coreia do Sul", value: "46" },
  { name: "Cosovo", value: "47" },
  { name: "Costa do Marfim", value: "48" },
  { name: "Costa Rica", value: "49" },
  { name: "Croácia", value: "50" },
  { name: "Cuaite", value: "51" },
  { name: "Cuba", value: "52" },
  { name: "Dinamarca", value: "53" },
  { name: "Dominica", value: "54" },
  { name: "Egito", value: "55" },
  { name: "Emirados Árabes Unidos", value: "56" },
  { name: "Equador", value: "57" },
  { name: "Eritreia", value: "58" },
  { name: "Eslováquia", value: "59" },
  { name: "Eslovénia", value: "60" },
  { name: "Espanha", value: "61" },
  { name: "Essuatíni", value: "62" },
  { name: "Estado da Palestina", value: "63" },
  { name: "Estados Unidos da América", value: "64" },
  { name: "Estónia", value: "65" },
  { name: "Etiópia", value: "66" },
  { name: "Fiji", value: "67" },
  { name: "Filipinas", value: "68" },
  { name: "Finlândia", value: "69" },
  { name: "França", value: "70" },
  { name: "Gabão", value: "71" },
  { name: "Gâmbia", value: "72" },
  { name: "Gana", value: "73" },
  { name: "Geórgia", value: "74" },
  { name: "Granada", value: "75" },
  { name: "Grécia", value: "76" },
  { name: "Guatemala", value: "77" },
  { name: "Guiana", value: "78" },
  { name: "Guiné", value: "79" },
  { name: "Guiné Equatorial", value: "80" },
  { name: "Guiné-Bissau", value: "81" },
  { name: "Haiti", value: "82" },
  { name: "Honduras", value: "83" },
  { name: "Hungria", value: "84" },
  { name: "Iémen", value: "85" },
  { name: "Ilhas Marechal", value: "86" },
  { name: "Índia", value: "87" },
  { name: "Indonésia", value: "88" },
  { name: "Irão", value: "89" },
  { name: "Iraque", value: "90" },
  { name: "Irlanda", value: "91" },
  { name: "Islândia", value: "92" },
  { name: "Israel", value: "93" },
  { name: "Itália", value: "94" },
  { name: "Jamaica", value: "95" },
  { name: "Japão", value: "96" },
  { name: "Jibuti", value: "97" },
  { name: "Jordânia", value: "98" },
  { name: "Laus", value: "99" },
  { name: "Lesoto", value: "100" },
  { name: "Letónia", value: "101" },
  { name: "Líbano", value: "102" },
  { name: "Libéria", value: "103" },
  { name: "Líbia", value: "104" },
  { name: "Listenstaine", value: "105" },
  { name: "Lituânia", value: "106" },
  { name: "Luxemburgo", value: "107" },
  { name: "Macedónia do Norte", value: "108" },
  { name: "Madagáscar", value: "109" },
  { name: "Malásia", value: "110" },
  { name: "Maláui", value: "111" },
  { name: "Maldivas", value: "112" },
  { name: "Mali", value: "113" },
  { name: "Malta", value: "114" },
  { name: "Marrocos", value: "115" },
  { name: "Maurícia", value: "116" },
  { name: "Mauritânia", value: "117" },
  { name: "México", value: "118" },
  { name: "Mianmar", value: "119" },
  { name: "Micronésia", value: "120" },
  { name: "Moçambique", value: "121" },
  { name: "Moldávia", value: "122" },
  { name: "Mónaco", value: "123" },
  { name: "Mongólia", value: "124" },
  { name: "Montenegro", value: "125" },
  { name: "Namíbia", value: "126" },
  { name: "Nauru", value: "127" },
  { name: "Nepal", value: "128" },
  { name: "Nicarágua", value: "129" },
  { name: "Níger", value: "130" },
  { name: "Nigéria", value: "131" },
  { name: "Noruega", value: "132" },
  { name: "Nova Zelândia", value: "133" },
  { name: "Omã", value: "134" },
  { name: "Países Baixos", value: "135" },
  { name: "Palau", value: "136" },
  { name: "Panamá", value: "137" },
  { name: "Papua Nova Guiné", value: "138" },
  { name: "Paquistão", value: "139" },
  { name: "Paraguai", value: "140" },
  { name: "Peru", value: "141" },
  { name: "Polónia", value: "142" },
  { name: "Portugal", value: "143" },
  { name: "Quénia", value: "144" },
  { name: "Quirguistão", value: "145" },
  { name: "Quiribáti", value: "146" },
  { name: "Reino Unido", value: "147" },
  { name: "República Centro-Africana", value: "148" },
  { name: "República Checa", value: "149" },
  { name: "República Democrática do Congo", value: "150" },
  { name: "República Dominicana", value: "151" },
  { name: "Roménia", value: "152" },
  { name: "Ruanda", value: "153" },
  { name: "Rússia", value: "154" },
  { name: "Salomão", value: "155" },
  { name: "Salvador", value: "156" },
  { name: "Samoa", value: "157" },
  { name: "Santa Lúcia", value: "158" },
  { name: "São Cristóvão e Neves", value: "159" },
  { name: "São Marinho", value: "160" },
  { name: "São Tomé e Príncipe", value: "161" },
  { name: "São Vicente e Granadinas", value: "162" },
  { name: "Seicheles", value: "163" },
  { name: "Senegal", value: "164" },
  { name: "Serra Leoa", value: "165" },
  { name: "Sérvia", value: "166" },
  { name: "Singapura", value: "167" },
  { name: "Síria", value: "168" },
  { name: "Somália", value: "169" },
  { name: "Sri Lanca", value: "170" },
  { name: "Sudão", value: "171" },
  { name: "Sudão do Sul", value: "172" },
  { name: "Suécia", value: "173" },
  { name: "Suíça", value: "174" },
  { name: "Suriname", value: "175" },
  { name: "Tailândia", value: "176" },
  { name: "Taiuã", value: "177" },
  { name: "Tajiquistão", value: "178" },
  { name: "Tanzânia", value: "179" },
  { name: "Timor-Leste", value: "180" },
  { name: "Togo", value: "181" },
  { name: "Tonga", value: "182" },
  { name: "Trindade e Tobago", value: "183" },
  { name: "Tunísia", value: "184" },
  { name: "Turcomenistão", value: "185" },
  { name: "Turquia", value: "186" },
  { name: "Tuvalu", value: "187" },
  { name: "Ucrânia", value: "188" },
  { name: "Uganda", value: "189" },
  { name: "Uruguai", value: "190" },
  { name: "Usbequistão", value: "191" },
  { name: "Vanuatu", value: "192" },
  { name: "Vaticano", value: "193" },
  { name: "Venezuela", value: "194" },
  { name: "Vietname", value: "195" },
  { name: "Zâmbia", value: "196" },
  { name: "Jersey", value: "197" },
];
