import { Icon, IconProps } from "@chakra-ui/react";

export const ChartIcon = ({ ...rest }: IconProps) => {
  return (
    <Icon
      width="22px"
      height="17px"
      viewBox="0 0 22 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M20.2812 14.75H2.40625V1.6875C2.40625 1.50516 2.33382 1.3303 2.20489 1.20136C2.07595 1.07243 1.90109 1 1.71875 1C1.53641 1 1.36155 1.07243 1.23261 1.20136C1.10368 1.3303 1.03125 1.50516 1.03125 1.6875V15.4375C1.03125 15.6198 1.10368 15.7947 1.23261 15.9236C1.36155 16.0526 1.53641 16.125 1.71875 16.125H20.2812C20.4636 16.125 20.6385 16.0526 20.7674 15.9236C20.8963 15.7947 20.9688 15.6198 20.9688 15.4375C20.9688 15.2552 20.8963 15.0803 20.7674 14.9514C20.6385 14.8224 20.4636 14.75 20.2812 14.75Z"
        fill="#4C545F"
        stroke="#4C545F"
        strokeWidth="0.3"
      />
      <path
        d="M4.12555 14C4.30759 13.9992 4.48189 13.9263 4.61023 13.7972L9.2818 9.12906L10.8596 10.7069C10.9235 10.7708 10.9993 10.8215 11.0827 10.8561C11.1662 10.8907 11.2557 10.9085 11.346 10.9085C11.4364 10.9085 11.5258 10.8907 11.6093 10.8561C11.6927 10.8215 11.7686 10.7708 11.8324 10.7069L17.188 5.34781V7.53406C17.188 7.7164 17.2605 7.89127 17.3894 8.0202C17.5183 8.14913 17.6932 8.22156 17.8755 8.22156C18.0579 8.22156 18.2328 8.14913 18.3617 8.0202C18.4906 7.89127 18.563 7.7164 18.563 7.53406V3.6875C18.563 3.50516 18.4906 3.3303 18.3617 3.20136C18.2328 3.07243 18.0579 3 17.8755 3H14.029C13.8466 3 13.6718 3.07243 13.5428 3.20136C13.4139 3.3303 13.3415 3.50516 13.3415 3.6875C13.3415 3.86984 13.4139 4.0447 13.5428 4.17364C13.6718 4.30257 13.8466 4.375 14.029 4.375H16.2152L11.3443 9.24938L9.76648 7.67156C9.70263 7.60764 9.62681 7.55693 9.54335 7.52233C9.45989 7.48774 9.37043 7.46993 9.28008 7.46993C9.18973 7.46993 9.10027 7.48774 9.0168 7.52233C8.93334 7.55693 8.85752 7.60764 8.79367 7.67156L3.63742 12.8278C3.54165 12.9242 3.47657 13.0467 3.45039 13.18C3.42421 13.3133 3.43811 13.4514 3.49033 13.5768C3.54255 13.7022 3.63076 13.8093 3.74381 13.8846C3.85686 13.96 3.9897 14.0001 4.12555 14Z"
        fill="#4C545F"
        stroke="#4C545F"
        strokeWidth="0.3"
      />
    </Icon>
  );
};
