import { SVGProps } from "react";

export function AddPerson({
  withOutFill,
  ...rest
}: SVGProps<SVGSVGElement> & { withOutFill?: boolean }) {
  return (
    <svg
      width="32px"
      height="28px"
      viewBox="0 0 32 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      {!withOutFill ? (
        <circle
          cx="13.5"
          cy="14.1875"
          r="13.5"
          fill="#B21F17"
          fillOpacity="0.1"
        />
      ) : null}
      <path
        d="M31.1926 18.8279H28.3657V16.0009C28.3657 15.7867 28.2806 15.5813 28.1292 15.4298C27.9777 15.2783 27.7722 15.1932 27.558 15.1932C27.3438 15.1932 27.1384 15.2783 26.9869 15.4298C26.8354 15.5813 26.7503 15.7867 26.7503 16.0009V18.8279H23.9234C23.7092 18.8279 23.5038 18.9129 23.3523 19.0644C23.2008 19.2159 23.1157 19.4213 23.1157 19.6355C23.1157 19.8498 23.2008 20.0552 23.3523 20.2067C23.5038 20.3581 23.7092 20.4432 23.9234 20.4432H26.7503V23.2702C26.7503 23.4844 26.8354 23.6898 26.9869 23.8413C27.1384 23.9928 27.3438 24.0779 27.558 24.0779C27.7722 24.0779 27.9777 23.9928 28.1292 23.8413C28.2806 23.6898 28.3657 23.4844 28.3657 23.2702V20.4432H31.1926C31.4069 20.4432 31.6123 20.3581 31.7638 20.2067C31.9152 20.0552 32.0003 19.8498 32.0003 19.6355C32.0003 19.4213 31.9152 19.2159 31.7638 19.0644C31.6123 18.9129 31.4069 18.8279 31.1926 18.8279Z"
        fill="currentColor"
      />
      <path
        d="M21.5 24.0779H13.4231C13.2089 24.0779 13.0034 23.9928 12.852 23.8413C12.7005 23.6898 12.6154 23.4844 12.6154 23.2702C12.6182 20.9147 13.5551 18.6565 15.2207 16.9909C16.8863 15.3253 19.1445 14.3883 21.5 14.3856C23.0611 14.3904 24.5715 13.8316 25.7537 12.8119C26.9358 11.7923 27.7102 10.3802 27.9345 8.83527C28.1588 7.29034 27.818 5.71635 26.9747 4.40258C26.1315 3.08881 24.8423 2.12353 23.3443 1.68414C21.8463 1.24476 20.24 1.3608 18.8207 2.01093C17.4014 2.66107 16.2644 3.80161 15.6187 5.22295C14.973 6.64429 14.862 8.25092 15.306 9.74756C15.7501 11.2442 16.7194 12.5303 18.0358 13.3695C15.9825 14.0886 14.2031 15.4272 12.9428 17.2005C11.6826 18.9739 11.0038 21.0946 11 23.2702C11 23.9128 11.2553 24.5291 11.7097 24.9835C12.1641 25.438 12.7804 25.6932 13.4231 25.6932H21.5C21.7142 25.6932 21.9197 25.6082 22.0711 25.4567C22.2226 25.3052 22.3077 25.0998 22.3077 24.8856C22.3077 24.6713 22.2226 24.4659 22.0711 24.3144C21.9197 24.163 21.7142 24.0779 21.5 24.0779ZM16.6538 7.92402C16.6538 6.96554 16.9381 6.02858 17.4706 5.23164C18.0031 4.43469 18.7599 3.81355 19.6455 3.44675C20.531 3.07996 21.5054 2.98399 22.4454 3.17098C23.3855 3.35797 24.249 3.81952 24.9267 4.49727C25.6045 5.17501 26.066 6.03852 26.253 6.97858C26.44 7.91864 26.3441 8.89304 25.9773 9.77856C25.6105 10.6641 24.9893 11.4209 24.1924 11.9534C23.3954 12.4859 22.4585 12.7702 21.5 12.7702C20.2151 12.7689 18.9832 12.2579 18.0747 11.3493C17.1661 10.4408 16.6551 9.2089 16.6538 7.92402Z"
        fill="currentColor"
      />
    </svg>
  );
}
