import { Button, Text } from "@chakra-ui/react";
import api from "api/api";
import { api3cPlus } from "api/wss-c3plus";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useAttendanceContext } from "contexts/attendance-context";
import { use3cPlusContext } from "contexts/Context3cPlus";
import { useEffect, useState } from "react";
import { useRouter } from "routes/router-control/use-router";
import {
  QualificationProps,
  getQualifications,
  openModalReturnToCampaign,
} from "services/api3cplus";
import { makeEvent, useEventListener } from "services/events";
import { Toast } from "components/toast";

export function ModalTabulacao3cPlus({
  setIsDisabledBackButton,
}: {
  setIsDisabledBackButton: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [tabulacoes, setTabulacoes] = useState<QualificationProps[]>([]);
  const [carregandoTabulacoes, setCarregandoTabulacoes] = useState(false);
  const { user } = useApplicationContext();
  const { controls3cPlus, dispatch3cPlus } = use3cPlusContext();
  const { formControl } = useAttendanceContext();
  const [tabulacaoKey, setTabulacaoKey] = useState<number>();
  const [tabulandoLoading, setTabulandoLoading] = useState(false);

  const router = useRouter();

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const getTabulacao = async () => {
    if (user.userData.enabled3cplus && controls3cPlus.inCall) {
      setCarregandoTabulacoes(true);
      setIsDisabledBackButton(true);
      try {
        const data = await getQualifications();
        setTabulacoes(data);
      } catch (e: any) {
        const errorMessage = e?.responde?.data.detail;
        Toast({
          title: errorMessage ?? "Erro ao carregar tabulações",
          status: "error",
        });
      } finally {
        setCarregandoTabulacoes(false);
      }
    }
  };

  useEffect(() => {
    if (controls3cPlus.inCall) getTabulacao();
  }, [controls3cPlus.inCall]);

  useEventListener("openModalTabulacao3cPlus", onOpen);

  const onClickTabulacao = async (qualification_id: number) => {
    // setModalLoading(true);
    setTabulandoLoading(true);
    try {
      const { id } = formControl.values;
      await api.put(`/atendimentos/${id}/kanban`, {
        ...formControl.values,
        idTabulacao3Cplus: qualification_id,
      });
      if (controls3cPlus.isManualMode) {
        await api3cPlus.post(
          `/3cplus/agent/manual_call/${controls3cPlus.idCall}/qualify`,
          { qualification_id }
        );
      } else {
        await api3cPlus.post(
          `/3cplus/agent/call/${controls3cPlus.idCall}/qualify`,
          { qualification_id }
        );
      }
      if (!controls3cPlus.inCall && controls3cPlus.isManualMode) {
        openModalReturnToCampaign({ dispatch3cPlus });
      }
      if (!controls3cPlus.inCall && controls3cPlus.isManualMode) {
        dispatch3cPlus({ type: "clearIdCall" });
      }
      setIsDisabledBackButton(false);
      router.push("/atendimentos");
      Toast({ title: "Tabulação Concluída" });
    } catch (e: any) {
      const message = e?.response?.data?.message;
      Toast({ title: "Erro ao tabular", description: message });
    } finally {
      // setModalLoading(false);
      setTabulandoLoading(false);
    }
  };

  const modalFooter = (
    <>
      <Button
        onClick={() => onClickTabulacao(tabulacaoKey!)}
        isLoading={tabulandoLoading}
        loadingText="Tabulando"
        isDisabled={tabulacaoKey == null}
      >
        Tabular
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={"Tabulação 3C Plus"}
      modalFooter={modalFooter}
    >
      <Text mb="8px">Selecione a tabulação</Text>
      <Dropdown
        options={tabulacoes.map((item) => {
          return {
            name: <Text color={item.color}>{item.name}</Text>,
            value: item.id,
          };
        })}
        value={tabulacaoKey}
        onChange={setTabulacaoKey}
        isLoading={carregandoTabulacoes}
        variant="outline-custom"
        w="100%"
      />
    </CustomModal>
  );
}

export const openModalTabulacao3cPlus = () =>
  makeEvent("openModalTabulacao3cPlus");
