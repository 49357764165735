import { Center, Flex } from "@chakra-ui/react";
import { SkeletonLoading } from "components/skeleton-loading";
import {
  BordaTriangular1,
  BordaTriangular2,
} from "components/vectors/borda-triangular";

export function FunilDeDados({
  data,
  colors = ["#202c5f", "#2d3d85", "#3E54B7", "#BEC6E9"],
  isLoading,
}: {
  data:
    | {
        name: string;
        value: number;
      }[]
    | undefined;
  colors?: string[];
  isLoading?: boolean;
}) {
  const pieChartOptions = {
    labels: data?.map(({ name }) => name) ?? [],
    colors: ["#000", "#000", "#000", "#000"],
    // chart: { width: "400px", height: "350px" },
    states: { hover: { filter: { type: "none" } } },
    legend: {
      show: false,
      position: "left",
    },
    dataLabels: {
      enabled: true,
      // style: { colors: ["#fff", "#fff", "#fff", "#fff"] },
    },
    hover: { mode: null },
    plotOptions: {
      //@ts-ignore
      donut: { expandOnClick: false, donut: { labels: { show: true } } },
    },
    fill: { colors },
    tooltip: { enabled: true, theme: "dark" },
  };

  return (
    <SkeletonLoading
      alignItems="center"
      display="flex"
      justifyContent="center"
      flexDir="column"
      minH="230px"
      w="100%"
      isLoading={!!isLoading}
    >
      {data?.map(({ name, value }, i) => {
        return (
          <Flex w={`${100 - i * 10}%`} mb="10px" key={i}>
            <BordaTriangular2 color={colors[i]} />
            <Center
              mx="-0.2px"
              w="calc(100% - 15.428px - 15.428px + 0.2px + 0.2px)"
              bg={colors[i]}
              color="#fff"
              fontWeight="medium"
            >
              {name}: {value}
            </Center>
            <BordaTriangular1 color={colors[i]} />
          </Flex>
        );
      })}
    </SkeletonLoading>
  );
}
