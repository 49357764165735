import { useEffect, useState } from "react";
import {
  AddIcon,
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  EditIcon,
  RepeatIcon,
} from "@chakra-ui/icons";
import { GetLayout } from "components/get-layout";
import { useApplicationContext } from "contexts/ApplicationContext";
import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";
import DynamicTable from "components/dynamic-table";
import { FaKey, FaPowerOff } from "react-icons/fa";
import api from "../../api/api";
import { openModalConfirm } from "components/modal-confirm-new";
import { isColomboUser } from "utils/is-colombo-user";
import { allowedUserTypesLoginColombo } from "components/login-page/functions";
import { openModalError } from "components/modal-error";
import { objectSchema } from "utils/object-methods";
import { fieldValidation } from "utils/field-validation";
import { User } from "contexts/types/application-context-types";
import { TitleWithBackButton } from "components/title-with-back-button";
import { PriceButton } from "components/servicos-components/service-card";
import { servicosList } from "../carteira";
import { InputField } from "components/input-field";
import { DropdownField } from "components/dropdown-field";
import { getErrorMessage } from "utils/util";
import { formatData } from "utils/string-formats";
import {
  bgError,
  changeColorIntensity,
} from "components/dynamic-table/row-status";
import { ModalComDropdownRecorrencia } from "components/servicos-components/modal-datafast/modal-renovacao-fila";
import { RenewIcon } from "components/vectors/renew-icon";
import { Column } from "components/dynamic-table/types";
import { Toast } from "components/toast";

interface UserListRow {
  id: number;
  username: string;
  password: null | string;
  enabled: boolean;
  type: User["userData"]["type"] | "";
  name: string;
  customer: {
    id: number | string;
    enabled?: boolean;
    name?: string;
    cnpj?: null | string;
    codigoNova?: null | string;
    email?: null | string;
    telefone?: null | string;
    enabled3cplus?: null | string;
    token3cplus?: null | string;
    comercialUser?: null | string;
    regionalCustomer?: null | string;
  };
  userId3cplus: 44607;
  dataExpiracao?: string;
}

export const Usuarios = () => {
  const { user } = useApplicationContext();
  const { userData } = user;
  const [usuarioDialog, setUsuarioDialog] = useState<Partial<UserListRow>>({});
  const [usuarioPasswordDialog, setUsuarioPasswordDialog] = useState<{
    id?: number;
    password?: string;
  }>({});
  const [customers, setCustomers] = useState<{ id: number; name: string }[]>(
    []
  );
  const [filtros, setFiltros] = useState({ nomeOrCodigo: "" });
  const [openUsuarioDialog, setOpenUsuarioDialog] = useState(false);
  const [openUsuarioPasswordDialog, setOpenUsuarioPasswordDialog] =
    useState(false);
  const [usuarios, setUsuarios] = useState<UserListRow[]>([]);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [updatingPassword, setUpdatingPassword] = useState(false);
  const [errors, setErrors] = useState<{
    customer?: boolean;
    name?: boolean;
    username?: boolean;
    password?: boolean;
    type?: boolean;
  }>({
    customer: false,
    name: false,
    username: false,
    password: false,
    type: false,
  });

  const isUpdate = !!usuarioDialog.id;
  const isComercial = userData.type === "COMERCIAL";
  const isParceiroMaster = userData.type === "PARCEIRO_MASTER";

  const isColomboUserRow = (row: UserListRow) => {
    return !!row.customer.codigoNova?.startsWith("1309");
  };
  const onOpenUsuarioModal = () => {
    setOpenUsuarioDialog(true);
    if (isParceiroMaster && customers.length === 1) {
      setUsuarioDialog((usuario) => ({ ...usuario, customer: customers[0] }));
    }
  };

  const onCloseUsuarioModal = () => {
    setOpenUsuarioDialog(false);
    setSaving(false);
  };

  const getFilteredUsuarios = () => {
    let filteredUsuarios = [...usuarios];
    if (filtros.nomeOrCodigo.trim() !== "") {
      filteredUsuarios = filteredUsuarios.filter(
        (c) =>
          (c.name || "")
            .toUpperCase()
            .includes(filtros.nomeOrCodigo.trim().toUpperCase()) ||
          (c.username || "")
            .toUpperCase()
            .includes(filtros.nomeOrCodigo.trim().toUpperCase())
      );
    }
    return filteredUsuarios;
  };

  const onChangeFiltro = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFiltros({ ...filtros, [field]: e.target.value });
  };

  const saveOrUpdateUsuarioPassword = async () => {
    const { fieldsErrors } = objectSchema(
      {
        password: (value) => {
          const { isValid } = fieldValidation({ value }).required();
          return { valid: isValid };
        },
      },
      usuarioPasswordDialog
    );

    if (fieldsErrors.includes("password")) return setErrors({ password: true });

    let tempUsuarioPassword = {
      ...usuarioPasswordDialog,
      password: usuarioPasswordDialog.password
        ? usuarioPasswordDialog.password.trim()
        : "",
    };
    setUpdatingPassword(true);
    try {
      await api.patch(
        "/usuarios/" + tempUsuarioPassword.id + "/password",
        tempUsuarioPassword
      );
    } catch (error: any) {
      Toast({
        title: getErrorMessage({
          error,
          defaultMessage: "Erro ao alterar senha do usuário!",
        }),
        status: "error",
      });
    }
    await loadUsuarios();
    setOpenUsuarioPasswordDialog(false);
    setUpdatingPassword(false);
  };

  const validateUsuarioSchema = () => {
    const { fieldsErrors, isValid } = objectSchema(
      {
        customer: {
          id: (value) => {
            const { isValid } = fieldValidation({ value }).required();
            return { valid: isValid };
          },
        },
        name: (value) => {
          const { isValid } = fieldValidation({ value }).required();
          return { valid: isValid };
        },
        username: (value) => {
          const { isValid } = fieldValidation({ value }).required();
          return { valid: isValid };
        },
        password: (value) => {
          const { isValid } = fieldValidation({
            value: value?.trim?.(),
          }).required();
          return { valid: isUpdate ? true : isValid };
        },
        type: (value) => {
          const { isValid } = fieldValidation({ value }).required();
          return { valid: isValid };
        },
      },
      usuarioDialog
    );
    let errors: any = {};
    (fieldsErrors as string[]).forEach((key) => {
      if (key === "id") key = "customer";
      errors[key] = true;
    });
    return { isValid, errors };
  };

  const saveOrUpdateUsuario = async () => {
    const { isValid, errors } = validateUsuarioSchema();

    if (!isValid) return setErrors(errors);

    openModalConfirm({
      message: `Deseja salvar?`,
      onConfirm: async () => {
        let body = {
          ...usuarioDialog,
          name: usuarioDialog.name?.trim(),
          username: usuarioDialog.username?.trim(),
          password: usuarioDialog.password ? usuarioDialog.password.trim() : "",
        };

        setSaving(true);
        try {
          const { data } = isUpdate
            ? await api.put(`/usuarios/${body.id}`, body)
            : await api.post("/usuarios?novo=true", body);
          onCloseUsuarioModal();
          if (isUpdate) updateRow(data);
          else addUserRow(data);
        } catch (error: any) {
          const tag = error?.response?.data?.tag;
          const errorMessage: any = {
            username_exists: "Usuário já cadastrado",
            number_of_contracted_accounts_already_reached:
              "Não é possivel criar o usuário! Verifique a quantidade de contas contratadas no menu carteira.",
            customer_already_has_registered_user:
              "O parceiro já possui usuário cadastrado",
          };
          openModalError({
            title: "Atenção",
            message: getErrorMessage({
              error,
              defaultMessage: errorMessage[tag] ?? "Erro ao salvar usuário!",
            }),
          });
        } finally {
          setSaving(false);
        }
      },
      confirmButtonStyle: { variant: undefined },
    });
  };

  const atualizar = async () => {
    setLoading(true);
    await loadCustomers();
    await loadUsuarios();
    setLoading(false);
  };

  useEffect(() => {
    atualizar();
  }, []);

  const loadUsuarios = async () => {
    try {
      let response = await api.get("/usuarios");
      setUsuarios(response.data);
    } catch (error) {
      Toast({
        title: "Erro ao carregar usuários",
        status: "error",
      });
    }
  };

  const loadCustomers = async () => {
    try {
      let response = await api.get("/customers");
      setCustomers(response.data);
    } catch (error) {
      Toast({
        title: "Não foi possível carregar as empresas",
        status: "error",
      });
    }
  };

  const acceptDeleteUsuario = async (rowData: UserListRow) => {
    try {
      await api.delete("/usuarios/" + rowData.id);
    } catch (error: any) {
      Toast({
        title: getErrorMessage({
          error,
          defaultMessage: "Erro ao excluir Usuário!",
        }),
        status: "error",
      });
    }
    await loadUsuarios();
  };

  const onChangeUsuarioDialogCheckbox = (field: string, value: any) => {
    setUsuarioDialog({ ...usuarioDialog, [field]: value });
  };

  const onChangeUsuarioDialog = (field: string, value: any) => {
    setErrors({ ...errors, [field]: false });
    if (["username", "password"].includes(field)) {
      setUsuarioDialog({ ...usuarioDialog, [field]: value.trim() });
    } else if ("customer" === field) {
      setUsuarioDialog({ ...usuarioDialog, customer: { id: value } });
    } else {
      setUsuarioDialog({ ...usuarioDialog, [field]: value });
    }
  };

  const onChangeUsuarioPasswordDialog = (field: string, value: any) => {
    setErrors({ ...errors, [field]: false });
    setUsuarioPasswordDialog({
      ...usuarioPasswordDialog,
      [field]: value.trim(),
    });
  };

  const openDialogNewUsuario = () => {
    setErrors({
      customer: false,
      name: false,
      username: false,
      password: false,
      type: false,
    });
    setUsuarioDialog({
      enabled: true,
      customer: { id: "" },
      name: "",
      type: "",
      username: "",
      password: "",
    });
    onOpenUsuarioModal();
  };

  const editUsuario = (usuario: UserListRow) => {
    setErrors({
      customer: false,
      name: false,
      username: false,
      password: false,
      type: false,
    });
    setUsuarioDialog({ ...usuario });
    onOpenUsuarioModal();
  };

  const updateRow = (newRow: UserListRow) => {
    setUsuarios((list) => {
      return list.map((currRow) => {
        if (newRow.id === currRow.id) return newRow;
        return currRow;
      });
    });
  };

  const addUserRow = (newRow: UserListRow) => {
    setUsuarios([...usuarios, newRow]);
  };

  const editUsuarioPassword = (usuario: UserListRow) => {
    setErrors({ password: false });
    setUsuarioPasswordDialog({ ...usuario, password: "" });
    setOpenUsuarioPasswordDialog(true);
  };

  const renewSession = async (row: UserListRow) => {
    openModalConfirm({
      title: "Renovação",
      onConfirm: async () => {
        try {
          const { data } = await api.patch(`/usuarios/${row.id}/renew`);
          atualizar();
        } catch (error: any) {
          Toast({
            title: getErrorMessage({
              error,
              defaultMessage: "Erro ao renovar usuário",
            }),
            status: "error",
          });
        } finally {
        }
      },
      message: (
        <PriceButton mt="15px">
          {servicosList.find((curr) => curr.product === "Conta Usuário")?.price}
        </PriceButton>
      ),
      confirmButtonStyle: {
        children: "Confirmar",
        leftIcon: <RenewIcon />,
        variant: undefined,
        loadingText: "Confirmando",
      },
      rejectButtonStyle: {
        children: "Cancelar",
        leftIcon: <CloseIcon w="12px" h="12px" />,
      },
    });
  };

  const getDescOfType = (type: string) => {
    if ("SUPER" === type) return "Super";
    else if ("FINANCEIRO" === type) return "Financeiro";
    else if ("ASCOM" === type) return "ASCOM";
    else if ("REGIONAL" === type) return "Regional";
    else if ("COMERCIAL" === type) return "Comercial";
    else if ("PARCEIRO_MASTER" === type) return "Parceiro (Master)";
    else if ("PARCEIRO_VENDEDOR" === type) return "Parceiro (Vendedor)";
    else if ("PARCEIRO_OPERACIONAL" === type) return "Parceiro (Operacional)";
    else return "";
  };

  const getDropdownUserType = () => {
    if (isUpdate && !canEdit()) {
      return [
        { name: "Super", value: "SUPER" },
        { name: "Regional", value: "REGIONAL" },
        { name: "Comercial", value: "COMERCIAL" },
        { name: "Parceiro (Master)", value: "PARCEIRO_MASTER" },
        { name: "Parceiro (Vendedor)", value: "PARCEIRO_VENDEDOR" },
        { name: "Parceiro (Operacional)", value: "PARCEIRO_OPERACIONAL" },
      ];
    } else {
      const userTypes: any = {
        SUPER: [
          { name: "Regional", value: "REGIONAL" },
          { name: "Comercial", value: "COMERCIAL" },
          { name: "Parceiro (Master)", value: "PARCEIRO_MASTER" },
          { name: "Parceiro (Vendedor)", value: "PARCEIRO_VENDEDOR" },
          { name: "Parceiro (Operacional)", value: "PARCEIRO_OPERACIONAL" },
        ],
        REGIONAL: [
          { name: "Comercial", value: "COMERCIAL" },
          { name: "Parceiro (Master)", value: "PARCEIRO_MASTER" },
          { name: "Parceiro (Vendedor)", value: "PARCEIRO_VENDEDOR" },
          { name: "Parceiro (Operacional)", value: "PARCEIRO_OPERACIONAL" },
        ],
        COMERCIAL: isColomboUser(user)
          ? [
              { name: "Parceiro (Master)", value: "PARCEIRO_MASTER" },
              { name: "Parceiro (Vendedor)", value: "PARCEIRO_VENDEDOR" },
            ]
          : [{ name: "Parceiro (Master)", value: "PARCEIRO_MASTER" }],
        PARCEIRO_MASTER: [
          { name: "Parceiro (Vendedor)", value: "PARCEIRO_VENDEDOR" },
          { name: "Parceiro (Operacional)", value: "PARCEIRO_OPERACIONAL" },
        ],
      };
      if (isColomboUser(user)) {
        const allowedTypesList = userTypes[userData.type ?? ""];
        return allowedTypesList?.filter(
          (curr: { name: string; value: User["userData"]["type"] }) =>
            allowedUserTypesLoginColombo.includes(curr.value) // Verifica os user types que a colombo tem roteamento de login configurado
        );
      } else {
        return userTypes[userData.type ?? ""];
      }
    }
  };

  const hideUsuarioPasswordDialog = () => {
    setOpenUsuarioPasswordDialog(false);
  };

  // const changeRecorrencySession = async (row: UserListRow) => {
  //   openModalConfirm({
  //     message: `Deseja ${
  //       row.cobrancaRecorrente ? "DESATIVAR" : "ATIVAR"
  //     } recorrência?`,
  //     onConfirm: async () => {
  //       try {
  //         const { data } = await api.get(
  //           `/services/conta-usuario/${
  //             row.serviceSubscriptionContaUsuarioId
  //           }/change-recorrencia?possuiRecorrencia=${!row.cobrancaRecorrente}`
  //         );
  //         atualizar();
  //       } catch (e: any) {
  //         Toast({
  //           title: "Erro ao mudar recorrência de usuário",
  //           status: "error",
  //         });
  //       } finally {
  //       }
  //     },
  //     confirmButtonStyle: { variant: undefined },
  //   });
  // };

  const usuarioDialogFooter = (
    <>
      <Button
        leftIcon={<CheckIcon />}
        className="p-button-text"
        onClick={saveOrUpdateUsuario}
        isLoading={saving}
        loadingText="Salvando"
      >
        Salvar
      </Button>
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
        onClick={onCloseUsuarioModal}
      >
        Cancelar
      </Button>
    </>
  );

  const usuarioPasswordDialogFooter = (
    <>
      <Button
        leftIcon={<CheckIcon />}
        onClick={saveOrUpdateUsuarioPassword}
        isLoading={updatingPassword}
        loadingText="Salvando"
      >
        Salvar
      </Button>
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        onClick={hideUsuarioPasswordDialog}
        variant="outline"
      >
        Cancelar
      </Button>
    </>
  );

  const canEdit = () => {
    if (usuarioDialog.id) {
      if (
        "SUPER" === userData.type &&
        [
          "REGIONAL",
          "FINANCEIRO",
          "COMERCIAL",
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ].includes(usuarioDialog.type as string)
      ) {
        return true;
      } else if (
        "REGIONAL" === userData.type &&
        [
          "COMERCIAL",
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ].includes(usuarioDialog.type as string)
      ) {
        return true;
      } else if (
        "COMERCIAL" === userData.type &&
        [
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ].includes(usuarioDialog.type as string)
      ) {
        return true;
      } else if (
        "PARCEIRO_MASTER" === userData.type &&
        ["PARCEIRO_VENDEDOR", "PARCEIRO_OPERACIONAL"].includes(
          usuarioDialog.type as string
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const changeEnableState = async (row: UserListRow) => {
    const isExpirado = row.dataExpiracao
      ? new Date().getTime() > new Date(row.dataExpiracao).getTime() + 86400000
      : false;
    openModalConfirm({
      onConfirm: async () => {
        try {
          const { data } = await api.patch(`/usuarios/${row.id}/enabled`, {
            enabled: !row.enabled,
          });
          updateRow(data);
        } catch (error: any) {
          Toast({
            title: getErrorMessage({
              error,
              defaultMessage: `Erro ao ${
                !row.enabled ? " Ativar" : " Desativar"
              } usuário`,
            }),
          });
        }
      },
      message: (
        <Box w="100%">
          <Text>
            Deseja <b>{!row.enabled ? "Ativar" : "Desativar"}</b> o usuário?
          </Text>
          {!row.enabled &&
          isExpirado &&
          userData.type === "PARCEIRO_MASTER" &&
          !isColomboUserRow(row) &&
          (row.type === "PARCEIRO_VENDEDOR" ||
            row.type === "PARCEIRO_OPERACIONAL") ? (
            <PriceButton mt="15px">
              {
                servicosList.find((curr) => curr.product === "Conta Usuário")
                  ?.price
              }
            </PriceButton>
          ) : null}
        </Box>
      ),
    });
  };

  const columns: Column[] = [
    {
      name: "Ativo",
      render: (row: UserListRow) => {
        if (row.enabled === true) return "SIM";
        if (row.enabled === false) return "NÃO";
      },
      cellStyle: (row) => ({ opacity: row.enabled ? 1 : 0.4 }),
    },
    {
      name: "Usuário",
      key: "username",
      cellStyle: (row) => ({ opacity: row.enabled ? 1 : 0.4 }),
    },
    {
      name: "Nome",
      key: "name",
      cellStyle: (row) => ({ opacity: row.enabled ? 1 : 0.4 }),
    },
    {
      name: "Tipo",
      render: (row: UserListRow) => getDescOfType(row.type as string),
      key: "type",
      cellStyle: (row) => ({ opacity: row.enabled ? 1 : 0.4 }),
    },
    {
      name: "Empresa",
      render: (row: UserListRow) => {
        return row.customer.name;
      },
      cellStyle: (row) => ({ opacity: row.enabled ? 1 : 0.4 }),
    },

    {
      name: (
        <Text>
          Próxima <br />
          Cobrança
        </Text>
      ),
      key: "dataExpiracao",
      render: (row: UserListRow) => {
        const isExpirado = row.dataExpiracao
          ? new Date().getTime() >
            new Date(row.dataExpiracao).getTime() + 86400000
          : false;

        if (
          row.dataExpiracao == null ||
          row.dataExpiracao === "" ||
          row.dataExpiracao === "2030-01-01"
        )
          return "Sem cobrança";
        if (isExpirado) {
          return (
            <Flex justifyContent="flex-start" alignItems="center">
              <Text color="secondary.600">
                {formatData(row.dataExpiracao || "")}
              </Text>
              {row.enabled ? (
                <Tooltip label="Renovar">
                  <IconButton
                    ml="6px"
                    aria-label=""
                    size="xs"
                    bg={bgError}
                    onClick={() => renewSession(row)}
                    color={changeColorIntensity(bgError, 1)}
                    fontSize="13px"
                    fontWeight="semibold"
                    px="8px"
                    boxShadow={`0 0 0 2px ${changeColorIntensity(
                      bgError,
                      0.1
                    )}`}
                    variant="outline"
                    icon={<RepeatIcon />}
                    _hover={{ bg: changeColorIntensity(bgError, 0.1) }}
                    border="none"
                  >
                    {/* Renovar */}
                  </IconButton>
                </Tooltip>
              ) : null}
            </Flex>
          );
        }
        return formatData(row.dataExpiracao || "");
      },
      cellStyle: (row) => ({ opacity: row.enabled ? 1 : 0.4 }),
    },
    {
      name: "Ações",
      render: (row: UserListRow) => {
        return (
          <>
            <Tooltip label={row.enabled ? "Desativar" : "Ativar"}>
              <IconButton
                aria-label=""
                icon={<FaPowerOff />}
                mr="8px"
                onClick={() => changeEnableState(row)}
              />
            </Tooltip>
            <Tooltip label="Editar">
              <IconButton
                aria-label=""
                icon={<EditIcon />}
                mr="8px"
                onClick={() => editUsuario(row)}
              />
            </Tooltip>
            <Tooltip label="Redefinir Senha">
              <IconButton
                aria-label=""
                icon={<FaKey />}
                mr="8px"
                onClick={() => editUsuarioPassword(row)}
              />
            </Tooltip>

            <Tooltip label="Excluir">
              <IconButton
                aria-label=""
                icon={<DeleteIcon />}
                variant="danger"
                onClick={() =>
                  openModalConfirm({
                    message: `Deseja excluir ${row.username} ("${row.name})" ?`,
                    title: "Confirmação",
                    onConfirm: () => acceptDeleteUsuario(row),
                    confirmButtonStyle: {
                      variant: "danger",
                      leftIcon: <DeleteIcon />,
                      loadingText: "Deletando",
                    },
                  })
                }
              />
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <>
      <GetLayout>
        <TitleWithBackButton title="Usuários" />
        <Grid mb="30px" templateColumns="1fr 1fr 1fr" gap="16px">
          <Box>
            <InputField
              title="Filtrar por Nome ou Usuário"
              value={filtros.nomeOrCodigo || ""}
              onChange={(e) => onChangeFiltro("nomeOrCodigo", e)}
            />
          </Box>
          <Box>
            <Text mb="8px">&nbsp;</Text>
            <Button
              w="100%"
              leftIcon={<AddIcon />}
              onClick={openDialogNewUsuario}
              isDisabled={!customers.length}
            >
              Adicionar Usuário
            </Button>
          </Box>
          <Box>
            <Text mb="8px">&nbsp;</Text>
            <Button
              w="100%"
              isLoading={loading}
              loadingText="Atualizando"
              leftIcon={<RepeatIcon />}
              onClick={atualizar}
            >
              Atualizar
            </Button>
          </Box>
        </Grid>
        <DynamicTable
          rows={getFilteredUsuarios()}
          columns={columns}
          isLoading={loading}
        />
        <CustomModal
          isOpen={openUsuarioPasswordDialog}
          size="md"
          modalTitle="Redefinir Senha"
          modalFooter={usuarioPasswordDialogFooter}
          onClose={hideUsuarioPasswordDialog}
        >
          <Box>
            <InputField
              title="Nova senha"
              value={usuarioPasswordDialog.password}
              errorMessage={
                errors.password ? "Informe a nova senha" : undefined
              }
              onChange={(e) =>
                onChangeUsuarioPasswordDialog("password", e.target.value)
              }
            />
          </Box>
        </CustomModal>

        <CustomModal
          isOpen={openUsuarioDialog}
          size="2xl"
          modalTitle="Usuários"
          modalFooter={usuarioDialogFooter}
          onClose={onCloseUsuarioModal}
        >
          <Grid gap="16px" templateColumns="1fr 1fr" mb="10px">
            <Box gridArea={!isUpdate ? "auto / span 2" : ""}>
              <DropdownField
                title="Empresa"
                value={usuarioDialog.customer?.id}
                options={customers.filter((customer) => {
                  if (userData.type === "COMERCIAL" && !isUpdate)
                    return customer.id !== userData.customerId;
                  return true;
                })}
                dropdownProps={{
                  w: "100%",
                  optionValue: "id",
                  optionLabel: "name",
                  isDisabled: isUpdate || isParceiroMaster,
                }}
                onChange={(value) => onChangeUsuarioDialog("customer", value)}
                errorMessage={
                  errors.customer ? "Selecione uma empresa" : undefined
                }
              />
            </Box>
            <Box>
              <InputField
                title="Nome"
                value={usuarioDialog.name}
                errorMessage={errors.name ? "Informe um nome" : undefined}
                onChange={(e) => onChangeUsuarioDialog("name", e.target.value)}
                isDisabled={!canEdit()}
              />
            </Box>
            <Box>
              <InputField
                title="Usuário"
                value={usuarioDialog.username}
                errorMessage={
                  errors.username ? "Informe um usuário" : undefined
                }
                onChange={(e) =>
                  onChangeUsuarioDialog("username", e.target.value)
                }
                isDisabled={!canEdit()}
              />
            </Box>

            {!isUpdate && (
              <Box>
                <InputField
                  title="Senha"
                  value={usuarioDialog.password || ""}
                  errorMessage={
                    errors.password ? "Informe uma senha" : undefined
                  }
                  onChange={(e) =>
                    onChangeUsuarioDialog("password", e.target.value)
                  }
                />
              </Box>
            )}

            <Box>
              <DropdownField
                title="Tipo"
                dropdownProps={{ w: "100%", isDisabled: !canEdit() }}
                value={usuarioDialog.type}
                onChange={(value) => onChangeUsuarioDialog("type", value)}
                options={getDropdownUserType()}
                errorMessage={errors.type ? "Selecione um tipo" : undefined}
              />
            </Box>
          </Grid>
          {userData.type === "PARCEIRO_MASTER" &&
          !isColomboUser(user) &&
          !isUpdate ? (
            <PriceButton mb="10px">
              {
                servicosList.find((curr) => curr.product === "Conta Usuário")
                  ?.price
              }
            </PriceButton>
          ) : null}
          {/* {!isUpdate &&
          !isColomboUser(user) &&
          userData.type === "PARCEIRO_MASTER" ? (
            <Checkbox
              isChecked={usuarioDialog.cobrancaRecorrente}
              onChange={(value) =>
                onChangeUsuarioDialogCheckbox("cobrancaRecorrente", value)
              }
              isDisabled={!canEdit()}
            >
              Cobrança Recorrente
            </Checkbox>
          ) : null} */}
        </CustomModal>
        <ModalComDropdownRecorrencia />
      </GetLayout>
    </>
  );
};
