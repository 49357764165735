import { Box, Button, Center, Flex, Grid, Text } from "@chakra-ui/react";
import { Popover } from "components/popover";
import { Filter2Icon } from "components/vectors/filter-icon";
import { useEffect, useState } from "react";
import { use3cPlusContext } from "contexts/Context3cPlus";
import { InputField } from "components/input-field";
import { UFsBrasil } from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/const";
import { factaCitiesArray } from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/cities-array";
import { InputSearch } from "components/input-search";
import { NewDropdown } from "components/dropdown/new-dropdown";
import { dropdownTiposOperacao, validateClientsFilter } from "./utils";
import { currencyMaskFormat } from "utils/string-formats";
import { useClientsAttendanceContext } from "../aba-clientes-context";

enum OrderFieldsContrato {
  "DATA_PAGAMENTO",
  "DATA_ULTIMA_VERIFICACAO",
  "VALOR_BRUTO",
  "VALOR_LIQUIDO",
}

export interface ClientsFilterProps {
  idadeGte?: number;
  idadeLte?: number;
  prazoGte?: number;
  prazoLte?: number;
  valorBrutoGte?: number;
  valorBrutoLte?: number;
  valorLiquidoGte?: number;
  valorLiquidoLte?: number;
  page: number;
  pageSize: number;
  estados?: string[];
  cidades?: string[];
  idTipoOperacao?: number[];
  cpfNomeMatricula?: string;
  dataPagamentoClienteLte?: string;
  dataPagamentoClienteGte?: string;
  orderBy?: {
    field: OrderFieldsContrato;
    direction: "DESC" | "ASC";
  };
}

export function ClientsFilter() {
  let [currentFilter, setCurrentFilter] = useState<ClientsFilterProps>({
    page: 1,
    pageSize: 10,
  });
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  let {
    clientsFilter,
    setClientsFilter,
    getClients,
    clientList,
    isLoadingClientsList,
  } = useClientsAttendanceContext();

  const { controls3cPlus } = use3cPlusContext();

  useEffect(() => {
    setCurrentFilter(clientsFilter);
  }, [clientsFilter]);

  const onChange = (value: any, key: keyof ClientsFilterProps) => {
    setErrors((err) => {
      if (key.includes("Lte") || key.includes("Gte")) {
        delete err[key.replace("Lte", "Gte")];
        delete err[key.replace("Gte", "Lte")];
      } else delete err[key];
      return err;
    });
    if (Array.isArray(value)) {
      if (!value.length) {
        delete currentFilter[key];
        currentFilter = { ...currentFilter };
      } else {
        currentFilter = { ...currentFilter, [key]: value };
      }
      setCurrentFilter(currentFilter);
    } else {
      if (value == null || value === "") {
        delete currentFilter[key];
        currentFilter = { ...currentFilter };
        setCurrentFilter(currentFilter);
      } else {
        currentFilter = { ...currentFilter, [key]: value };
        setCurrentFilter(currentFilter);
      }
    }
  };

  const onChangeMultiselect = (
    value: any,
    field: "estados" | "cidades" | "idTipoOperacao"
  ) => {
    const currentEl = currentFilter[field] as any[];

    if (currentEl?.length && currentEl != null) {
      const isInclude = currentEl.includes(value);
      if (isInclude)
        onChange(
          currentEl.filter((curr: any) => curr !== value),
          field
        );
      else onChange([...currentFilter[field]!, value], field);
    } else onChange([value], field);
  };

  const handleApply = async (onClose: () => void) => {
    const { errors, isValid, body } = validateClientsFilter(currentFilter);
    if (isValid) {
      setClientsFilter(currentFilter);
      clientsFilter = currentFilter;
      getClients(body);
      onClose();
    } else setErrors(errors);
  };

  return (
    <Flex alignItems="center">
      <Text mr="10px" fontWeight="semibold">
        {" "}
        Total retornado: {clientList.total}{" "}
      </Text>
      <Popover
        button={
          <Button
            variant="ghost"
            alignItems="center"
            cursor="pointer"
            _hover={{ bg: "gray.200" }}
            px="10px"
            borderRadius="6px"
            leftIcon={<Filter2Icon />}
            fontSize="14px"
            fontWeight="medium"
            isDisabled={controls3cPlus.inCall || isLoadingClientsList}
          >
            Filtrar por
          </Button>
        }
        title="Filtrar por"
        position="left-bottom"
        onClose={() => setCurrentFilter(clientsFilter)}
        withOutCloseButton
        popupStyles={{ minWidth: "420px" }}
      >
        {({ onClose }) => (
          <Grid templateColumns="1fr 1fr" gap="10px">
            <Box gridArea="auto / span 2">
              <Text mb="8px" fontWeight="medium">
                Tipo de operação
              </Text>
              <NewDropdown
                multiSelect
                height={36}
                options={dropdownTiposOperacao}
                value={currentFilter.idTipoOperacao}
                onChange={(option) => {
                  onChangeMultiselect(option.value, "idTipoOperacao");
                }}
              />
            </Box>
            <Box gridArea="auto / span 2">
              <Text mb="8px" fontWeight="medium">
                Data pagamento
              </Text>
              <Grid templateColumns="1fr auto 1fr" gap="7px">
                <InputField
                  popoverProps={{ customArrowPos: undefined }}
                  onChange={(e) => {
                    onChange(e.target.value, "dataPagamentoClienteGte");
                  }}
                  inputProps={{ type: "date" }}
                  value={currentFilter.dataPagamentoClienteGte}
                  errorMessage={errors["dataPagamentoClienteGte"]}
                />
                <Center>
                  <Text textAlign="center">á</Text>
                </Center>
                <InputField
                  popoverProps={{ customArrowPos: undefined }}
                  onChange={(e) => {
                    onChange(e.target.value, "dataPagamentoClienteLte");
                  }}
                  inputProps={{ type: "date" }}
                  value={currentFilter.dataPagamentoClienteLte}
                  errorMessage={errors["dataPagamentoClienteLte"]}
                />
              </Grid>
            </Box>
            <Box>
              <Text mb="8px" fontWeight="medium">
                Idade
              </Text>
              <Grid templateColumns="1fr auto 1fr" gap="7px">
                <InputField
                  popoverProps={{ customArrowPos: undefined }}
                  onChange={(e) => {
                    const value =
                      e.target.value === "" ? null : Number(e.target.value);
                    onChange(value, "idadeGte");
                  }}
                  inputProps={{ type: "number" }}
                  value={currentFilter.idadeGte}
                  errorMessage={errors["idadeGte"]}
                />
                <Center>
                  <Text textAlign="center">á</Text>
                </Center>
                <InputField
                  popoverProps={{ customArrowPos: undefined }}
                  onChange={(e) => {
                    const value =
                      e.target.value === "" ? null : Number(e.target.value);
                    onChange(value, "idadeLte");
                  }}
                  inputProps={{ type: "number" }}
                  value={currentFilter.idadeLte}
                  errorMessage={errors["idadeLte"]}
                />
              </Grid>
            </Box>
            <Box>
              <Text mb="8px" fontWeight="medium">
                Prazo
              </Text>
              <Grid templateColumns="1fr auto 1fr" gap="7px">
                <InputField
                  popoverProps={{ customArrowPos: undefined }}
                  onChange={(e) => {
                    const value =
                      e.target.value === "" ? null : Number(e.target.value);
                    onChange(value, "prazoGte");
                  }}
                  inputProps={{ type: "number" }}
                  value={currentFilter.prazoGte}
                  errorMessage={errors["prazoGte"]}
                />
                <Center>
                  <Text textAlign="center">á</Text>
                </Center>
                <InputField
                  popoverProps={{ customArrowPos: undefined }}
                  onChange={(e) => {
                    const value =
                      e.target.value === "" ? null : Number(e.target.value);
                    onChange(value, "prazoLte");
                  }}
                  inputProps={{ type: "number" }}
                  value={currentFilter.prazoLte}
                  errorMessage={errors["prazoLte"]}
                />
              </Grid>
            </Box>
            <Box>
              <Text mb="8px" fontWeight="medium">
                Valor Bruto
              </Text>
              <Grid templateColumns="1fr auto 1fr" gap="7px">
                <InputField
                  popoverProps={{ customArrowPos: undefined }}
                  onChange={(e) => {
                    onChange(
                      currencyMaskFormat(e.target.value).raw,
                      "valorBrutoGte"
                    );
                  }}
                  value={currencyMaskFormat(currentFilter.valorBrutoGte).value}
                  errorMessage={errors["valorBrutoGte"]}
                />
                <Center>
                  <Text textAlign="center">á</Text>
                </Center>
                <InputField
                  popoverProps={{ customArrowPos: undefined }}
                  onChange={(e) => {
                    onChange(
                      currencyMaskFormat(e.target.value).raw,
                      "valorBrutoLte"
                    );
                  }}
                  value={currencyMaskFormat(currentFilter.valorBrutoLte).value}
                  errorMessage={errors["valorBrutoLte"]}
                />
              </Grid>
            </Box>
            <Box>
              <Text mb="8px" fontWeight="medium">
                Valor Líquido
              </Text>
              <Grid templateColumns="1fr auto 1fr" gap="7px">
                <InputField
                  popoverProps={{ customArrowPos: undefined }}
                  onChange={(e) => {
                    onChange(
                      currencyMaskFormat(e.target.value).raw,
                      "valorLiquidoGte"
                    );
                  }}
                  value={
                    currencyMaskFormat(currentFilter.valorLiquidoGte).value
                  }
                  errorMessage={errors["valorLiquidoGte"]}
                />
                <Center>
                  <Text textAlign="center">á</Text>
                </Center>
                <InputField
                  popoverProps={{ customArrowPos: undefined }}
                  onChange={(e) => {
                    onChange(
                      currencyMaskFormat(e.target.value).raw,
                      "valorLiquidoLte"
                    );
                  }}
                  value={
                    currencyMaskFormat(currentFilter.valorLiquidoLte).value
                  }
                  errorMessage={errors["valorLiquidoLte"]}
                />
              </Grid>
            </Box>
            <Box>
              <Text mb="8px" fontWeight="medium">
                Estados
              </Text>
              <NewDropdown
                value={currentFilter.estados}
                multiSelect
                height={36}
                options={UFsBrasil}
                onChange={(option) => {
                  onChangeMultiselect(option.value, "estados");
                }}
              />
            </Box>
            <Box>
              <Text mb="8px" fontWeight="medium">
                Cidades
              </Text>
              <InputSearch
                options={factaCitiesArray
                  .filter((curr) =>
                    currentFilter.estados?.length
                      ? currentFilter.estados.includes(curr.value.uf)
                      : true
                  )
                  .map(({ name, value }) => ({
                    name: `${name} - ${value.uf}`,
                    value,
                  }))}
                checkIsInclude={(option: {
                  name: string;
                  value: { cityName: string; uf: string };
                }) =>
                  !!currentFilter.cidades?.includes(option.value.cityName) &&
                  !!currentFilter.estados?.includes(option.value.uf)
                }
                onChange={(value) => {
                  onChangeMultiselect(value.cityName, "cidades");
                  if (!currentFilter.estados?.includes(value.uf)) {
                    onChangeMultiselect(value.uf, "estados");
                  }
                }}
                value={currentFilter.cidades?.join(", ")}
                inputProps={{
                  onFocus: (e) => {
                    e.target.value = "";
                  },
                  onBlur: (e) => {
                    e.target.value = currentFilter.cidades?.join(", ") || "";
                  },
                }}
                multiSelect
                clearInput={false}
                openOnFocus
              />
            </Box>

            <Button
              w="100%"
              gridArea="auto / span 2"
              fontSize="14"
              borderRadius="4"
              onClick={() => handleApply(onClose)}
            >
              Aplicar
            </Button>
          </Grid>
        )}
      </Popover>
    </Flex>
  );
}
