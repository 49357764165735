import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Toast } from "components/toast";
import { Client } from "@stomp/stompjs";
import { getToken } from "Session";
import { api3cPlus } from "api/wss-c3plus";
import { openModalConfirm } from "components/modal-confirm-new";
import { useApplicationContext } from "contexts/ApplicationContext";
import {
  Controls3cPlusActions,
  Use3cPlusConnectionParams,
} from "contexts/types/context-3cplus-types";
import { stomp3cPlusEvents } from "./stomp-3cplus-events";
import { current3cplusControls } from "contexts/Context3cPlus";
import { Dispatch } from "react";
import { UA, WebSocketInterface } from "jssip";
import { UAConfiguration } from "jssip/lib/UA";
import { exportVariable } from "./get-external-function";
import { useRouter } from "routes/router-control/use-router";

export interface QualificationProps {
  allow_schedule: null;
  allow_schedule_to_another_number: null;
  behavior: 2;
  color: string;
  conversion: false;
  days_limit: null;
  emoji: null;
  id: number;
  name: string;
  readable_behavior: "not-call-identifier";
  should_insert_blacklist: boolean;
}

export const get3CplusAuthenticatedUser = async () => {
  const { data } = await api3cPlus.get(`/3cplus/me`);
  return data;
};

export const enterCampaign = async (campaign: number, mode: string) => {
  const { data } = await api3cPlus.post(`/3cplus/agent/login`, {
    campaign,
    mode,
  });
  return data;
};

export const logoutCampaign = async () => {
  const { data } = await api3cPlus.post(`/3cplus/agent/logout`, {});
  return data;
};

export const getQualifications = async () => {
  const { data }: { data: QualificationProps[] } = await api3cPlus.get(
    `/3cplus/qualification-lists/agent/qualifications`
  );
  return data;
};

export const listCampaigns = async () => {
  const { data } = await api3cPlus.get(`/3cplus/campaigns/campaigns`);
  return data;
};

export const enterManualMode = async () => {
  const { lastEventName, isManualMode } = current3cplusControls;
  if (!isManualMode) {
    try {
      const { data } = await api3cPlus.post("/3cplus/agent/manual_call/enter");
      return data;
    } catch (e) {
      const { data } = await api3cPlus.post(
        `/3cplus/agent/manual_call_acw/enter`
      );
      return data;
    }
  }
};

let conexao_jssip: UA | null = null;

export const useSipServiceConection = ({
  onAuthenticatedUserError,
  onMicrophonePermissionDenied,
  onConnected,
  onDisconnected,
  dispatch3cPlus,
  onLigacaoChanged,
  onRamalChanged,
  getAudioElement,
}: {
  onMicrophonePermissionDenied?: () => void;
  onAuthenticatedUserError?: (error: Error) => void;
  onConnected?: () => void;
  onDisconnected?: () => void;
  onLigacaoChanged?: (emLigacao: boolean) => void;
  dispatch3cPlus: React.Dispatch<Controls3cPlusActions>;
  onRamalChanged: (mutted: boolean) => void;
  getAudioElement: () => HTMLAudioElement | null;
}) => {
  const { user } = useApplicationContext();
  const router = useRouter();

  if (user.userData.enabled3cplus && conexao_jssip == null) {
    get3CplusAuthenticatedUser()
      .then((response) => {
        let dominio_3cplus = "3c.fluxoti.com"; //"3c.fluxoti.com"
        let dominio_da_empresa = response.company.domain; //"novapromotora";
        let host = dominio_da_empresa + "." + dominio_3cplus + ":4443";
        let usuario_do_ramal = response.telephony_id; //"YoQ5glQ7NZ";
        let senha_do_ramal = response.extension_password; //"2dDgfU8S2Z";
        let tentativas_de_conexao = 0;
        let em_ligacao = false;
        let session = null;
        let ramal_mutado = false;
        let registro_do_ramal = false;

        let socket = new WebSocketInterface("wss://" + host);
        let configuration: UAConfiguration = {
          sockets: [socket],
          uri: "sip:" + usuario_do_ramal + "@" + host,
          password: senha_do_ramal,
          register: true,
          register_expires: 30,
          session_timers: false,
        };

        conexao_jssip = new UA(configuration);
        // inicia o registro do ramal
        conexao_jssip.start();

        navigator.mediaDevices
          .getUserMedia({ audio: true })
          .then(() => console.log("Permission is granted!"))
          .catch(() => {
            alert("sem permissao para usar o microfone");
            if (onMicrophonePermissionDenied) {
              onMicrophonePermissionDenied();
            }
          });
        // evento de ramal conectado
        conexao_jssip.on("connected", () => {
          console.log("connected JSSIP!");
          if (onConnected) onConnected();
        });

        // evento de ramal desconetado, aqui fazemos um tratamento para tentar reconectar 10x
        conexao_jssip.on("disconnected", (e) => {
          console.log("disconnected, trying reconnect...", e);
          if (onDisconnected) {
            onDisconnected();
            dispatch3cPlus({ type: "campaignLogout" });
          }
          //$("#status").text('Desconectado, tentando conectar...');
          tentativas_de_conexao++;
          let attempts = em_ligacao ? 10 : 5;
          if (tentativas_de_conexao > attempts) {
            alert("Desconectado, porfavor recarregue a pagina");
            conexao_jssip?.stop();
          }
        });

        // evento recebido quando o ramal do 3cplus é atendido para funcionamento
        conexao_jssip.on("newRTCSession", (data: any) => {
          session = data.session;
          exportVariable("session-3cplus", session);

          session.on("accepted", () => {
            console.log("accepted");
            em_ligacao = true;
            onLigacaoChanged?.(true);
          });

          session.on("peerconnection", (data: any) => {
            data.peerconnection.addEventListener("addstream", (data: any) => {
              console.log("RECEBIDO STREAM AUDIO");
              const audioElement = getAudioElement();
              if (audioElement) {
                audioElement.srcObject = data.stream;
                audioElement.play();
              }
            });
          });

          session.on("getusermediafailed", () => {
            alert("sem permissao para usar o microfone");
            if (onMicrophonePermissionDenied) {
              onMicrophonePermissionDenied();
            }
          });

          session.on("ended", () => {
            console.log("ended");
            session = null;
            em_ligacao = false;
            onLigacaoChanged?.(false);
            // $("#registrado").show();
            // $("#logado_na_campanha").hide();
            // $("#status").text('Ramal registrado');
          });

          session.on("muted", () => {
            ramal_mutado = true;
            console.log("mutado");
            dispatch3cPlus({ type: "mute" });
            // setRamalMutado(true);
            onRamalChanged(true);
          });

          session.on("unmuted", () => {
            ramal_mutado = false;
            console.log("desmutado");
            dispatch3cPlus({ type: "unMute" });
            // setRamalMutado(false);
            onRamalChanged(false);
          });

          session.answer({
            mediaConstraints: { audio: true, video: false },
          });
        });

        conexao_jssip.on("registered", () => {
          console.log("ramal registrado");
          //   $("#status").text("Ramal registrado");
          registro_do_ramal = true;
          //   $("#registrado").show();
        });

        conexao_jssip.on("unregistered", () => {
          console.log("ramal não registrado");
          //   $("#status").text("Ramal desconectado, tentando conectar...");
          registro_do_ramal = false;
          //   $("#registrado").hide();
        });

        conexao_jssip.on("registrationFailed", (e) => {
          console.log("registrationFailed", e);
          //   $("#status").text("Tentando registrar...");
          registro_do_ramal = false;
          //   $("#registrado").hide();
          setTimeout(() => {
            if (conexao_jssip?.isConnected() && !conexao_jssip.isRegistered()) {
              if (tentativas_de_conexao <= 10) {
                console.log("trying register...");
                // $("#status").text(
                //   "Tentando registrar... tentativa: " + tentativas_de_conexao
                // );
                conexao_jssip.register();
                tentativas_de_conexao++;
              } else {
                console.log("Register 10 attempts");
                alert("Erro no registro, recarregue a página.");
              }
            }
          }, 5000);
        });
      })
      .catch((e) => {
        console.log("Erro ao buscar informações do usuário autenticado 3cplus");
        if (onAuthenticatedUserError) {
          onAuthenticatedUserError(e);
        }
      });
  }

  const deslogarAgenteSipService = () => {
    console.log("Desconectando ramal Sip..");
    conexao_jssip?.unregister();
    conexao_jssip?.removeAllListeners();
    conexao_jssip?.terminateSessions();
    conexao_jssip?.stop();
    conexao_jssip = null;
  };

  return { deslogarAgenteSipService, conexao_jssip };
};

// export const deslogarAgenteSipService = () => {
//   console.log("Desconectando ramal Sip..");
//   conexao_jssip?.unregister();
//   conexao_jssip?.removeAllListeners();
//   conexao_jssip?.terminateSessions();
//   conexao_jssip?.stop();
// };

export const exitManualCallState = async ({
  dispatch3cPlus,
}: {
  dispatch3cPlus: Dispatch<Controls3cPlusActions>;
}) => {
  const { data } = await api3cPlus.post(`/3cplus/agent/manual_call/exit`);
  dispatch3cPlus({ type: "notInManualMode" });
  return data;
};

export const manualCall = async (phone: string) => {
  const { data } = await api3cPlus.post(`/3cplus/agent/manual_call/dial`, {
    phone,
  });
  return data;
};

export const makeManualCall = async ({
  phone,
  dispatch3cPlus,
}: {
  phone: string;
  dispatch3cPlus: Dispatch<Controls3cPlusActions>;
}) => {
  if (phone.startsWith("55")) phone = phone.replace("55", "");
  try {
    await enterManualMode();
    dispatch3cPlus({ type: "inManualMode" });
  } catch (e) {}
  await manualCall(phone);
};

export const desligarChamada3cPlus = async ({
  dispatch3cPlus,
  idCall,
}: {
  idCall: number;
  dispatch3cPlus: Dispatch<Controls3cPlusActions>;
}) => {
  try {
    await api3cPlus.post(`3cplus/agent/call/${idCall}/hangup`);
    if (
      window.location.pathname === "/atendimentos" &&
      current3cplusControls.isManualMode
    ) {
      openModalReturnToCampaign({ dispatch3cPlus });
    }
    if (
      window.location.pathname !== "/atendimento-cliente-page" &&
      current3cplusControls.isManualMode
    ) {
      dispatch3cPlus({ type: "clearIdCall" });
    }
    // dispatch3cPlus({ type: "notHangingUpCall" });
  } catch (e: any) {
    const errorMessage = e?.response?.data?.detail;
    Toast({
      title: errorMessage ?? "Erro ao desligar chamada",
    });
  } finally {
  }
};

// Connection Part
let stompClientInstance: Client | null = null;

export const use3cPlusWSConnection = ({
  dispatch3cPlus,
}: Use3cPlusConnectionParams) => {
  const {
    user: { userData },
  } = useApplicationContext();
  const [user, pass] = window.atob(getToken()! || "")?.split(":");

  if (userData.enabled3cplus) {
    if (stompClientInstance == null) {
      stompClientInstance = new Client({
        connectHeaders: { login: user, passcode: pass },
        brokerURL: process.env.REACT_APP_PUBLIC_ATTENDANCE_WEBSOCKET,
        debug: function (str: string) {
          // console.log("STOMP: " + str);
        },
        reconnectDelay: 500,
        onConnect: (frame: any) => {
          console.log("STOMP CONNECTED");
          stomp3cPlusEvents.forEach(({ eventType, callback }) => {
            stompClientInstance?.subscribe(eventType, (message) => {
              const eventName = eventType.split("/").splice(-1)[0];
              dispatch3cPlus({ type: "setStatus", payload: eventName });
              console.log(eventName);
              callback(message, { dispatch3cPlus });
            });
          });
        },
      });
      stompClientInstance.activate();
    }
  }
  return stompClientInstance;
};

export function disconnect3cPlusStomp() {
  stompClientInstance?.deactivate();
  stompClientInstance = null;
}

// Modal
export const openModalReturnToCampaign = ({
  dispatch3cPlus,
}: {
  dispatch3cPlus: Dispatch<Controls3cPlusActions>;
}) => {
  openModalConfirm({
    message: "Deseja voltar para campanha?",
    onConfirm: async () => await exitManualCallState({ dispatch3cPlus }),
    confirmButtonStyle: { variant: undefined, leftIcon: <CheckIcon /> },
    rejectButtonStyle: { leftIcon: <CloseIcon w="12px" h="12px" /> },
  });
};
