import { Box, Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/atendimentos-components/kanban-and-table-atendimento/kanban/parts/card";
import { SkeletonLoading } from "components/skeleton-loading";
import { useRef } from "react";
import {
  MouseInfoBox,
  onMouseCardDataLeave,
  setMouseCardData,
} from "../mouse-info-box";

interface GraphicDataProps {
  data: { name: string; value: number }[] | undefined;
  isLoading: boolean;
  id: string;
}

export default function BarsChart({ data, isLoading, id }: GraphicDataProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const hoverBar = useColorModeValue(
    { bg: "rgba(50,70,200,0.05)" },
    { bg: "rgba(255,255,255,0.05)" }
  );
  const mouseCardDataId = `BarsChart-${id}`;

  let maxValue = 0;
  data?.forEach(({ value }) => {
    if (value > maxValue) maxValue = value;
  });

  return (
    <SkeletonLoading isLoading={isLoading} h="100%" w="100%" borderRadius="8px">
      <Flex w="100%" h="100%">
        <Box mx="auto" w="100%">
          <Flex
            ref={containerRef}
            justifyContent="space-between"
            gap="1px"
            w="100%"
            h="100%"
            pos="relative"
            onMouseLeave={() => {
              onMouseCardDataLeave(mouseCardDataId);
            }}
            fontSize="12"
          >
            {data?.map(({ value, name }, i) => {
              return (
                <Flex
                  flexDir="column"
                  alignItems="center"
                  key={i}
                  _hover={hoverBar}
                  onMouseMove={(event) => {
                    setMouseCardData(mouseCardDataId, {
                      title: name,
                      content: `${value}`,
                      event,
                    });
                  }}
                >
                  <Flex
                    alignItems="center"
                    mt="auto"
                    pos="relative"
                    cursor="default"
                  >
                    <Box
                      h={isLoading ? 0 : `${(value / maxValue) * 180}px`}
                      w="12px"
                      transition="0.4s height ease"
                      borderRadius="80px 80px 0 0"
                      bg="linear-gradient(to top, #3E54B7, #202c5f)"
                    />
                  </Flex>
                  <Text fontWeight="bold">{name}</Text>
                </Flex>
              );
            })}
            <MouseInfoBox id={mouseCardDataId} />
          </Flex>
        </Box>
      </Flex>
    </SkeletonLoading>
  );
}
