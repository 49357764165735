import { AddIcon, CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex } from "@chakra-ui/react";
import api from "api/api";
import apiCarteira from "api/api-carteira";
import DynamicTable from "components/dynamic-table";
import {
  StatusFormat,
  bgError,
  bgSucess,
} from "components/dynamic-table/row-status";
import { Column } from "components/dynamic-table/types";
import { GetLayout } from "components/get-layout";
import { openModalConfirm } from "components/modal-confirm-new";
import { ModalComDropdownRecorrencia } from "components/servicos-components/modal-datafast/modal-renovacao-fila";
import { SkeletonLoading } from "components/skeleton-loading";
import { TitleWithBackButton } from "components/title-with-back-button";
import { useApplicationContext } from "contexts/ApplicationContext";
import { SelectedCustomer } from "pages/carteira";
import { useEffect, useState } from "react";
import { useRouter } from "routes/router-control/use-router";
import { currencyMask } from "utils/string-formats";
import { Toast } from "components/toast";

interface PackagesList {
  id: number;
  servico: "MARGEM_CONVENIO";
  quantidadeContratada: number;
  saldo: number;
  dataCriacao: string;
  dataExpiracao: string;
  habilitado: boolean;
}

export function MargemConvenioServicePage() {
  const [selectedCustomer, setSelectedCustomer] = useState<SelectedCustomer>();
  const [packagesList, setPackagesList] = useState<PackagesList[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();
  const params = new URLSearchParams(window.location.search);
  const partnerId = params.get("partnerId");

  const { user } = useApplicationContext();

  const createNew = async () => {
    const thirtyDays = 86400000 * 30;
    const expDate = new Date(
      new Date().getTime() + thirtyDays
    ).toLocaleString();
    const price = 350;
    openModalConfirm({
      message: (
        <Box>
          <b>Preço:</b> R$ {currencyMask(price.toFixed(2))} <br />
          <b>Quantidade:</b> 15000 <br />
          <b>Data de expiração dos créditos:</b> {expDate}
        </Box>
      ),
      onConfirm: async () => {
        try {
          const { data } = await apiCarteira.post(`/pacote-servico`, {
            servico: "MARGEM_CONVENIO",
          });
          setPackagesList((list) => [...list, data]);
        } catch (e: any) {
          const message = e?.response?.data?.message;
          if (message?.includes("CarteiraSemSaldoException")) {
            Toast({
              title: message.replace(
                "br.com.novapowerhub.exception.CarteiraSemSaldoException: ",
                ""
              ),
              status: "error",
            });
          } else {
            Toast({
              title: "Erro ao contratar nova sessão whatsapp",
              status: "error",
            });
          }
        } finally {
        }
      },
      confirmButtonStyle: {
        variant: undefined,
        children: "Criar",
        leftIcon: <CheckIcon />,
      },
      rejectButtonStyle: {
        children: "Cancelar",
        leftIcon: <CloseIcon w="12px" h="12px" />,
      },
    });
  };

  const changeAtivo = async (row: PackagesList) => {
    openModalConfirm({
      message: (
        <Box>
          Deseja <b>{row.habilitado ? "DESATIVAR" : "ATIVAR"}</b> pacote?
        </Box>
      ),
      onConfirm: async () => {
        try {
          const { data } = await apiCarteira.put(`/pacote-servico/${row.id}`, {
            habilitado: !row.habilitado,
          });
          setPackagesList((list) => {
            return list.map((currRow) => {
              if (row.id === currRow.id) return data;
              return currRow;
            });
          });
        } catch (e: any) {
          Toast({
            title: "Erro",
            status: "error",
          });
        } finally {
        }
      },
      confirmButtonStyle: { variant: undefined },
    });
  };

  const columns: Column[] = [
    {
      name: "Data Expiração",
      render: (row: PackagesList) => {
        const isExpirado =
          new Date().getTime() > new Date(row.dataExpiracao).getTime();
        return (
          <>
            <StatusFormat bg={isExpirado ? bgError : bgSucess}>
              {row.dataExpiracao
                ? new Date(row.dataExpiracao).toLocaleString()
                : null}
            </StatusFormat>
          </>
        );
      },
    },
    {
      name: "Quantidade",
      render: (row: PackagesList) => {
        return row.quantidadeContratada;
      },
    },
    {
      name: "Saldo",
      render: (row: PackagesList) => {
        return row.saldo;
      },
    },
    {
      name: "Status",
      render: (row: PackagesList) => {
        const isExpirado =
          new Date().getTime() > new Date(row.dataExpiracao).getTime();

        return (
          <>
            <StatusFormat
              w="82px"
              bg={isExpirado ? bgError : row.habilitado ? bgSucess : bgError}
            >
              {isExpirado ? "Expirado" : row.habilitado ? "Ativo" : "Inativo"}
            </StatusFormat>
          </>
        );
      },
    },

    {
      name: "Ações",
      render: (row: PackagesList) => {
        return (
          <>
            <Button
              mr="10px"
              size="xs"
              onClick={() => changeAtivo(row)}
              variant="outline"
              w="145px"
            >
              {row.habilitado ? "Desativar" : "Ativar"}
            </Button>
          </>
        );
      },
    },
  ];

  const getPackages = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`/customers/short`);
      const selectedCustomer = data?.find?.(
        (curr: SelectedCustomer) => `${curr.id}` === partnerId
      );
      setSelectedCustomer(selectedCustomer);
      if (!selectedCustomer) {
        Toast({ title: "Usuário não permitido" });
        router.push("/servicos");
      }
    } catch {
    } finally {
    }
    console.log(`${user.userData.customerId}` !== partnerId);
    if (`${user.userData.customerId}` !== partnerId) {
      if (["SUPER", "FINANCEIRO", "COMERCIAL"].includes(user.userData.type!)) {
      } else {
        Toast({ title: "Usuário não permitido" });
        router.push("/");
      }
    }
    try {
      const { data } = await apiCarteira.get(
        `/pacote-servico?servico=MARGEM_CONVENIO&habilitado=true`
      );
      setPackagesList(data);
    } catch (e) {
      setPackagesList([]);
      Toast({ title: "Erro ao obter serviços", status: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getPackages();
  }, []);

  return (
    <GetLayout>
      <TitleWithBackButton
        title="Pacotes contratados"
        onClick={() => {
          router.push(`/servicos?partnerId=${partnerId}`);
        }}
      />
      <Flex
        alignItems="center"
        justifyContent="flex-start"
        fontSize="20px"
        fontWeight="semibold"
      >
        Parceiro:&nbsp;
        <SkeletonLoading isLoading={!selectedCustomer} borderRadius="5px">
          {selectedCustomer ? (
            `${selectedCustomer?.codigoNova} - ${selectedCustomer?.name}`
          ) : (
            <Box w="150px" h="30px" ml="10px"></Box>
          )}
        </SkeletonLoading>
      </Flex>
      <Box
        my="10px"
        w="100%"
        borderBottom="1px solid var(--chakra-colors-gray-200)"
      />
      <Box w="100%" bg="#fff" p="20px 30px" borderRadius="5px">
        <Flex justifyContent="flex-end">
          <Button
            variant="outline"
            ml="auto"
            leftIcon={<AddIcon />}
            onClick={() => createNew()}
          >
            Adicionar
          </Button>
        </Flex>
        <DynamicTable
          columns={columns}
          rows={packagesList}
          isLoading={isLoading}
          pageSize={15}
          headerStyle={(header) => ({
            textAlign: header.key === "valor" ? "end" : "center",
            bg: "#fff",
            color: "#656F7D",
            textTransform: "uppercase",
            fontSize: "12px",
            fontWeight: "medium",
            _hover: { bg: "gray.50" },
            px: { base: "12px", "2xl": "20px" },
          })}
          containerStyle={{ boxShadow: "none" }}
        />
      </Box>
      <ModalComDropdownRecorrencia />
    </GetLayout>
  );
}
