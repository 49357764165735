import { Box, Center, Flex, Grid, Input, Text } from "@chakra-ui/react";
import { Dropdown } from "components/dropdown";
import { AddPerson } from "components/vectors/add-person";
import { Dispatch, SetStateAction } from "react";
import { maskCPF } from "utils/string-formats";
import { dropdownConvenioAtendimento } from "../../atendimento-form/fields-data";
import { Convenio } from "../../atendimento-form/types";

export const CreateNewClient = ({
  inputCpf,
  setInputCpf,
  dropdownConvenio,
  setDropdownConvenio,
  setDropdownCPFNB,
  dropdownCPFNBValue,
}: {
  inputCpf: string;
  setInputCpf: Dispatch<SetStateAction<string>>;
  dropdownConvenio: Convenio;
  setDropdownConvenio: Dispatch<SetStateAction<Convenio>>;
  setDropdownCPFNB: Dispatch<SetStateAction<"CPF" | "NB">>;
  dropdownCPFNBValue: "CPF" | "NB";
}) => {
  return (
    <Flex flexDir="column" w="100%" alignItems="center">
      <Center mb="20px">
        <AddPerson />
        <Text ml="13px" fontSize="24px" fontWeight="semibold" mb="50px">
          Cadastrar novo cliente
        </Text>
      </Center>
      <Grid templateColumns="1fr 1fr" w="100%" gap="24px">
        <Grid templateColumns="1fr 1fr" gap="10px">
          <Box>
            <Text fontSize="16" fontWeight="medium" mb="8px">
              Convênio
            </Text>
            <Dropdown
              w="100%"
              variant="outline-custom"
              options={dropdownConvenioAtendimento}
              value={dropdownConvenio}
              onChange={setDropdownConvenio}
            />
          </Box>
          <Box>
            <Text fontSize="16" fontWeight="medium" mb="8px">
              CPF / Número do ben.
            </Text>
            <Dropdown
              w="100%"
              variant="outline-custom"
              options={[
                { name: "CPF", value: "CPF" },
                { name: "Número benefício", value: "NB" },
              ]}
              value={dropdownCPFNBValue}
              onChange={(value) => {
                setDropdownCPFNB(value);
                if (value === "CPF")
                  setInputCpf((prevValue) => maskCPF(prevValue));
                if (value === "NB")
                  setInputCpf((prevValue) => prevValue.replace(/\D/g, ""));
              }}
            />
          </Box>
        </Grid>
        <Box>
          <Text fontSize="16" fontWeight="medium" mb="8px">
            {dropdownCPFNBValue === "CPF" ? "CPF" : "Número do ben."}
          </Text>
          <Input
            variant="outline-custom"
            value={inputCpf}
            onChange={(e) => {
              const newValue =
                dropdownCPFNBValue === "CPF"
                  ? maskCPF(e.target.value)
                  : e.target.value.replace(/\D/g, "");
              setInputCpf(newValue);
            }}
          />
        </Box>
      </Grid>
    </Flex>
  );
};
