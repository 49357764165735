import {
  Dispatch,
  ReactNode,
  SetStateAction,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  AttendanceCardArrayProps,
  AttendanceDropOptions,
  AttendanceKanbanColumnArrayProps,
  AttendanceKanbanContextProps,
  LoadingColumns,
  VisualizationMode,
  onLoadMoreAttendancesParams,
} from "./parts/types";
import { initialKanbanColumns } from "./parts/utils";
import api from "api/api";
import { useApplicationContext } from "contexts/ApplicationContext";
import { Toast } from "components/toast";
import { useEventListener, makeEvent } from "services/events";
import { AttendanceFilterProps } from "components/atendimentos-components/attendance-filter";

const defaultValue: AttendanceKanbanContextProps = {
  handleDrop: () => undefined,
  setKanbanColumns: () => undefined,
  kanbanColumns: [],
  isLoadingAttendances: false,
  loadMoreAttendances: async () => [],
  isDisabledColumns: false,
  getAllColumnAttendances: async () => undefined,
  visualizationMode: "kanban",
  setVisualizationMode: () => undefined,
  loadingColumns: {},
  setLoadingColumns: () => undefined,
  attendanceFilter: {},
  setAttendanceFilter: () => undefined,
  setCheckedAttendances: () => undefined,
  checkedAttendances: [],
};

const attendanceKanbanContext =
  createContext<AttendanceKanbanContextProps>(defaultValue);

let lastMovement: AttendanceDropOptions | null = null;
let currentVisualizationMode: VisualizationMode = "kanban";
let lastFilter: AttendanceFilterProps = {};

export const AttendanceKanbanContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const { user } = useApplicationContext();
  const [attendanceFilter, setAttendanceFilterState] =
    useState<AttendanceFilterProps>(lastFilter);
  const [isLoadingAttendances, setIsLoadingAttendances] = useState(false);
  const [loadingColumns, setLoadingColumns] = useState<LoadingColumns>({});
  const [isDisabledColumns, setIsDisabledColumns] = useState(false);
  const [checkedAttendances, setCheckedAttendances] = useState<number[]>([]);
  const [visualizationMode, changeVisualizationMode] =
    useState<VisualizationMode>("kanban");
  const [kanbanColumns, setKanbanColumns] = useState<
    AttendanceKanbanColumnArrayProps[]
  >(initialKanbanColumns.filter((column) => column.isVisible(user)));

  const setAttendanceFilter: Dispatch<SetStateAction<AttendanceFilterProps>> = (
    value
  ) => {
    const newValue =
      typeof value === "function" ? value(attendanceFilter) : value;
    lastFilter = newValue;
    setAttendanceFilterState(value);
  };

  const setVisualizationMode: Dispatch<SetStateAction<VisualizationMode>> = (
    value
  ) => {
    const newValue =
      typeof value === "function" ? value(visualizationMode) : value;
    currentVisualizationMode = newValue;
    changeVisualizationMode(newValue);
  };

  const groupAttendancesByColumns = ({
    attendances,
    columns,
    mode = "reset",
  }: {
    attendances: AttendanceCardArrayProps[];
    columns: AttendanceKanbanColumnArrayProps[];
    mode?: "add" | "reset";
  }) => {
    return columns.map((column) => {
      if (mode === "reset") column.attendancesList = [];
      attendances
        .filter((attendance) => attendance.etapa === column.colId)
        .forEach((attendance) => {
          column.attendancesList = [
            ...column.attendancesList,
            attendance,
          ].filter(
            (currAtt, index, arr) =>
              arr.findIndex((att) => att.id === currAtt.id) === index
          );
        });
      return column;
    });
  };

  const getAllColumnAttendances = async ({
    limit = 30,
    offset = 0,
    filter = attendanceFilter,
  }: {
    limit?: number;
    offset?: number;
    filter?: AttendanceFilterProps;
  } = {}) => {
    setIsLoadingAttendances(true);
    try {
      const { data: attendances }: { data: AttendanceCardArrayProps[] } =
        await api.post(`/atendimentos/kanban`, { offset, limit, ...filter });
      if (currentVisualizationMode === "kanban") {
        setKanbanColumns(
          groupAttendancesByColumns({
            attendances,
            columns: initialKanbanColumns.filter((column) =>
              column.isVisible(user)
            ),
          })
        );
      } else if (currentVisualizationMode === "tables") {
        setKanbanColumns(
          groupAttendancesByColumns({
            attendances,
            columns: initialKanbanColumns.filter((column) =>
              column.isVisible(user)
            ),
          }).map((col) => {
            col.attendancesList = col.attendancesList.slice(0, 5);
            return col;
          })
        );
      }
    } catch (e) {
    } finally {
      setIsLoadingAttendances(false);
    }
  };

  const handleDrop = async ({
    source,
    destination,
    persistMovement = true,
  }: AttendanceDropOptions) => {
    let fromColId = source.droppableId;
    let sourceIndex = source.index;

    let toColId = destination?.droppableId;
    let destinationIndex = destination?.index;

    if (!destination) return;
    if (fromColId === toColId && sourceIndex === destinationIndex) return;
    if (fromColId === toColId) return;
    // Send to first index
    destinationIndex = 0;
    //
    let modifiedColumns = kanbanColumns;
    try {
      const sourceCol = modifiedColumns.find((col) => col.colId === fromColId);
      const destinationCol = modifiedColumns.find(
        (col) => col.colId === toColId
      );
      let movedCard: AttendanceCardArrayProps | undefined;
      if (sourceCol) {
        // TODO: Save the item
        let sourceColCards = sourceCol.attendancesList;
        movedCard = { ...sourceColCards[sourceIndex] };
        // TODO: Delete the moved card
        sourceCol.attendancesList = sourceColCards.filter((card) => {
          return card.id !== movedCard!.id;
        });
      }
      if (destinationCol && movedCard) {
        const destinationColCards = destinationCol.attendancesList;
        const firstPartCol = destinationColCards.slice(0, destinationIndex);
        const secondPartCol = destinationColCards.slice(
          destinationIndex,
          destinationColCards.length
        );
        // TODO: Update card
        movedCard.etapa = destinationCol.colId;
        // TODO: Insert moved card into destination column
        destinationCol.attendancesList = [
          ...firstPartCol,
          movedCard,
          ...secondPartCol,
        ];
        // TODO: Replace the modified column
        setKanbanColumns(
          modifiedColumns.map((column) => {
            if (column.colId === sourceCol?.colId) return sourceCol;
            if (column.colId === destinationCol.colId) return destinationCol;
            return column;
          })
        );
        lastMovement = { source, destination };
        // TODO: Save in API, if it works, proceed
        if (persistMovement) {
          setIsDisabledColumns(true);
          const { data } = await api.post(
            `/atendimentos/${movedCard.id}/change-etapa`,
            { fromEtapa: fromColId, toEtapa: toColId }
          );
        }
      }
    } catch (e) {
      handleDrop({
        source: lastMovement?.destination!,
        destination: lastMovement?.source!,
        persistMovement: false,
      });
      Toast({ title: "Não foi possível mover atendimento", status: "error" });
      //
    } finally {
      setIsDisabledColumns(false);
    }
  };

  const loadMoreAttendances = async ({
    colId,
    limit,
    customOffset,
    filter = attendanceFilter,
  }: onLoadMoreAttendancesParams) => {
    const offset =
      customOffset ||
      kanbanColumns.find((col) => col.colId === colId)?.attendancesList.length!;
    setLoadingColumns((loadingColumns) => ({
      ...loadingColumns,
      [colId]: true,
    }));
    try {
      const { data: attendances }: { data: AttendanceCardArrayProps[] } =
        await api.post(`/atendimentos/kanban`, {
          etapa: colId,
          offset,
          limit,
          ...filter,
        });
      setKanbanColumns((columns) => {
        if (customOffset != null) {
          columns = columns.map((column) => {
            if (column.colId === colId)
              column.attendancesList = column.attendancesList.slice(
                0,
                customOffset
              );
            return column;
          });
        }
        return groupAttendancesByColumns({ attendances, columns, mode: "add" });
      });
      return attendances;
    } catch (e) {
      return [];
    } finally {
      setLoadingColumns((loadingColumns) => ({
        ...loadingColumns,
        [colId]: false,
      }));
    }
  };

  useEffect(() => {
    getAllColumnAttendances();
  }, []);

  useEventListener("updateAttendances", getAllColumnAttendances);

  return (
    <attendanceKanbanContext.Provider
      value={{
        handleDrop,
        kanbanColumns,
        setKanbanColumns,
        isLoadingAttendances,
        loadMoreAttendances,
        isDisabledColumns,
        getAllColumnAttendances,
        visualizationMode,
        setVisualizationMode,
        setLoadingColumns,
        loadingColumns,
        attendanceFilter,
        setAttendanceFilter,
        setCheckedAttendances,
        checkedAttendances,
      }}
    >
      {children}
    </attendanceKanbanContext.Provider>
  );
};

export const useAttendanceKanbanContext = () => {
  const context = useContext(attendanceKanbanContext);
  if (context === undefined)
    throw new Error(
      "useApplicationContext must be used within a ApplicationContextProvider"
    );
  return context;
};

export const updateAttendances = () => makeEvent("updateAttendances");
