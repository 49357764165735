import { Icon, IconProps } from "@chakra-ui/react";

export function EntrouNaConta({ ...rest }: IconProps) {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 165 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M72.2815 38.0895C71.4016 37.7205 70.0675 36.5 69.5849 35.5633C68.0522 32.5546 69.6701 28.524 72.7924 27.6157C73.9278 27.2467 74.7226 27.3602 76.4824 28.0698C76.6528 28.155 76.8231 28.0415 76.8231 27.786C76.8231 27.417 77.1353 27.3319 78.3842 27.3319H79.9454V32.7249V38.1179H78.3842C77.5327 38.1179 76.8231 38.0043 76.8231 37.8624C76.8231 37.6921 76.3689 37.7773 75.8296 38.0043C74.7226 38.4585 73.3317 38.4869 72.2815 38.0895ZM76.1986 35.1091C76.7379 34.7402 76.8231 34.4279 76.8231 32.8668C76.8231 31.3057 76.7379 30.9934 76.1986 30.6244C75.2335 29.9432 73.7859 30.0567 72.9911 30.8799C72.4518 31.3908 72.2815 31.8733 72.2815 32.8668C72.2815 33.8602 72.4518 34.3428 72.9911 34.8537C73.7859 35.6768 75.2335 35.7904 76.1986 35.1091Z"
        fill="#B2CC09"
      />
      <path
        d="M57.2375 32.7249V27.3319H58.7987C59.9909 27.3319 60.3599 27.417 60.3599 27.7576C60.3599 28.2969 60.4734 28.2969 61.552 27.7292C62.659 27.1616 64.6744 27.3319 65.7246 28.0982C67.1438 29.1484 67.4561 30.2271 67.4561 34.3712V38.1179H65.9233H64.3905L64.277 34.6834C64.1918 31.6747 64.1351 31.2205 63.6525 30.8515C62.9145 30.3406 61.7791 30.3406 60.9843 30.9083C60.3883 31.3057 60.3599 31.5327 60.3599 34.7402V38.1179H58.7987H57.2375V32.7249Z"
        fill="#B2CC09"
      />
      <path
        d="M83.7206 22.4781C82.6136 22.1659 81.1375 20.7183 80.5698 19.3843C80.3144 18.7882 80.0873 17.1419 80.0021 15.2118L79.8602 12.0043H78.6397H77.3907V10.0174V8.03055H78.6681H79.9454V5.61788V3.20522H82.2162H84.4869V5.61788V8.03055H86.7577H89.0285V10.0174V12.0043H86.7577H84.4869V14.6725C84.4869 17.2554 84.5153 17.369 85.2533 17.965C85.9346 18.476 86.2752 18.5611 87.5241 18.3908C89.1988 18.1921 89.0853 18.0786 89.4543 20.8319L89.6246 22.1091L88.177 22.4498C86.5023 22.8471 84.9979 22.8471 83.7206 22.4781Z"
        fill="#B2CC09"
      />
      <path
        d="M106.826 22.393C104.754 21.655 103.618 20.6332 102.682 18.7882C100.155 13.7642 103.079 8.25762 108.472 7.80347C110.771 7.63317 112.474 8.22924 114.177 9.79037C117.328 12.6856 117.129 18.3057 113.808 21.0589C111.935 22.6201 109.04 23.1594 106.826 22.393ZM110.431 18.4192C112.162 17.7096 112.843 15.0131 111.736 13.1965C110.658 11.4367 108.501 11.2096 107.025 12.6856C106.173 13.5371 106.059 13.8209 106.059 15.1266C106.059 16.8864 106.372 17.5393 107.564 18.2489C108.642 18.9301 109.182 18.9585 110.431 18.4192Z"
        fill="#B2CC09"
      />
      <path
        d="M122.437 22.5065C121.132 22.1092 119.798 20.917 119.145 19.583C118.605 18.476 118.549 17.8515 118.549 13.1965V8.03055H120.649H122.778L122.863 12.6004L122.948 17.1703L123.743 17.8515C124.737 18.703 126.156 18.7314 127.178 17.9367L127.916 17.369V12.6856V8.03055H130.186H132.457V15.1266V22.2227H130.186C128.455 22.2227 127.916 22.1375 127.916 21.8253C127.916 21.5415 127.575 21.6266 126.582 22.1092C125.248 22.7904 123.857 22.9323 122.437 22.5065Z"
        fill="#B2CC09"
      />
      <path
        d="M15.3132 21.655C10.204 20.3777 8.01834 18.8166 7.13841 15.8646C6.14494 12.5436 6.88295 9.5633 9.26727 7.15063C10.8001 5.5895 13.1844 4.34059 14.6604 4.34059C15.1713 4.34059 15.2281 4.1419 15.2281 2.49561C15.2281 1.41701 15.3416 0.650635 15.5119 0.650635C15.6822 0.650635 16.2783 0.849325 16.8176 1.0764C17.6976 1.44539 17.953 1.44539 18.8329 1.0764C19.3723 0.849325 19.94 0.650635 20.0819 0.650635C20.2238 0.650635 20.3373 1.47378 20.3373 2.46723V4.28382L21.8417 4.73797C23.7719 5.36243 26.0427 6.92356 27.0078 8.31439C27.6606 9.25107 28.8528 12.4017 28.8528 13.1681C28.8528 13.31 27.3768 13.4236 25.5885 13.4236H22.3527L21.785 12.1747C21.1037 10.6419 20.167 9.96068 18.3788 9.79037C14.8875 9.47815 12.6167 12.0043 14.4617 14.1616C15.001 14.7576 15.7958 15.0698 18.9465 15.8646C23.4597 16.9716 24.9924 17.5677 26.4117 18.845C27.4052 19.6965 28.5689 21.5415 28.5689 22.2227C28.5689 22.393 26.8375 22.5065 23.5448 22.4781C18.9749 22.4781 18.1801 22.393 15.3132 21.655Z"
        fill="#B2CC09"
      />
      <path
        d="M50.1413 21.9105C48.4666 21.1441 47.7002 20.4061 46.7919 18.6747C45.2024 15.6092 46.1675 11.0677 48.8072 9.13754C52.1566 6.72487 56.9537 7.5764 59.0825 10.9825C59.7638 12.1179 59.9341 12.7423 60.0192 14.4738L60.1328 16.5458H55.279C49.9994 16.5458 49.7439 16.631 51.1348 18.0218C52.2418 19.1288 54.4842 19.3275 55.279 18.3624C55.5061 18.0786 56.2724 17.965 57.8336 17.965H60.076L59.5367 19.0436C58.8271 20.4061 57.6633 21.5131 56.3292 22.0524C54.7964 22.7052 51.7309 22.6201 50.1413 21.9105ZM55.9034 13.1681C55.648 12.1747 54.4842 11.3515 53.3204 11.3515C51.9296 11.3515 51.1064 11.8057 50.5671 12.8559L50.1129 13.7074H53.0933C55.8751 13.7074 56.0454 13.679 55.9034 13.1681Z"
        fill="#B2CC09"
      />
      <path
        d="M62.063 14.8428V7.46286H64.3338C66.5762 7.46286 66.6046 7.46286 66.6046 8.17247C66.6046 8.99561 66.6897 9.024 67.598 8.42793C70.181 6.72487 73.9562 7.80347 75.489 10.6703C76.0567 11.7489 76.1135 12.3166 76.1986 17.0567L76.3122 22.2227H74.013H71.7138V17.8515C71.7138 13.7074 71.6854 13.4236 71.0894 12.7423C70.1527 11.6637 68.9605 11.5218 67.7399 12.345L66.7465 13.0262L66.6613 17.6244L66.6046 22.2227H64.3338H62.063V14.8428Z"
        fill="#B2CC09"
      />
      <path
        d="M91.5831 15.1266V8.03055H93.8539C96.0963 8.03055 96.1247 8.03055 96.1247 8.74015V9.47814L97.3452 8.79692C97.9981 8.39954 99.0199 8.00216 99.616 7.91701L100.666 7.71832V10.0174C100.666 12.2598 100.666 12.2882 99.9566 12.2882C99.0767 12.2882 97.3169 12.9978 96.6356 13.6222C96.1815 14.0196 96.1247 14.6157 96.1247 18.1637V22.2227H93.8539H91.5831V15.1266Z"
        fill="#B2CC09"
      />
      <path
        d="M13.1843 42.7729C9.01172 41.7795 4.49854 38.2882 2.53999 34.5698C1.34782 32.2991 0.467896 29.0349 0.467896 26.8493V25.0611H4.44177C6.6274 25.0611 8.41564 25.1463 8.41564 25.2882C8.47241 26.7074 9.12526 29.6878 9.69296 30.8799C11.5096 34.7118 16.1079 36.6987 20.1953 35.4214C23.8286 34.2576 26.5819 30.1703 26.5819 25.9126V25.0611H30.7261H34.8703L34.7 26.7074C34.331 29.8297 33.9052 31.476 32.7982 33.8319C31.9183 35.7336 31.2654 36.6135 29.5055 38.3733C27.0645 40.786 24.8221 42.0633 21.7281 42.8013C19.4857 43.3122 15.3699 43.3122 13.1843 42.7729Z"
        fill="#2DAFE5"
      />
      <path
        d="M107.11 42.262C104.072 41.4105 101.972 38.9126 101.631 35.6768C101.007 29.6878 106.684 25.5153 112.247 27.8995C116.732 29.8297 118.009 36.1594 114.717 40.0196C113.014 42.0065 109.693 42.9716 107.11 42.262ZM110.828 37.9476C111.708 37.3515 112.304 36.0742 112.304 34.8537C112.304 32.072 109.863 30.4825 107.507 31.7314C105.293 32.8668 105.321 36.7838 107.535 38.0327C108.444 38.5153 110.062 38.4869 110.828 37.9476Z"
        fill="#2DAFE5"
      />
      <path
        d="M141.824 42.3188C140.291 41.893 139.383 41.0698 138.588 39.4803C137.935 38.1463 137.85 37.6921 137.85 34.7969V31.5895H136.431H135.012V29.6026V27.6157H136.431H137.85V25.0611V22.5065H140.121H142.392V25.0611V27.6157H144.805H147.217V29.6026V31.5895H144.805H142.392V34.286C142.392 36.6419 142.477 37.0677 142.988 37.5786C143.499 38.0895 143.84 38.1747 145.259 38.0611C147.161 37.9192 147.132 37.8908 147.274 40.5305L147.359 41.9498L145.798 42.2904C143.868 42.6878 143.102 42.6878 141.824 42.3188Z"
        fill="#2DAFE5"
      />
      <path
        d="M90.0503 41.9781C88.3189 41.3253 86.9564 40.0764 86.0765 38.3166C84.0611 34.3144 85.821 29.4039 89.8516 27.7009C91.0722 27.1899 94.1945 27.1332 95.4151 27.6157C97.402 28.3821 99.2754 30.4825 99.6728 32.3843L99.8715 33.2926H97.7142C96.4085 33.2926 95.557 33.179 95.557 33.0087C95.557 32.8384 95.2447 32.3843 94.8474 32.0153C94.308 31.476 93.8539 31.3057 92.8604 31.3057C92.1224 31.3057 91.2993 31.4476 90.987 31.5895C90.1639 32.0437 89.5962 33.4061 89.5962 35.024C89.5962 36.3013 89.7097 36.5851 90.5613 37.4367C91.3276 38.203 91.7534 38.4017 92.6617 38.4017C93.8823 38.4017 95.1312 37.6354 95.4151 36.6987C95.5854 36.1878 95.8408 36.131 97.7426 36.131H99.8999L99.7012 37.0677C99.3889 38.572 98.0548 40.4454 96.6072 41.3537C95.5286 42.0065 94.8757 42.2052 93.201 42.2904C91.8953 42.3471 90.7599 42.2336 90.0503 41.9781Z"
        fill="#2DAFE5"
      />
      <path
        d="M153.916 41.9781C152.554 41.4672 150.794 39.7642 150.056 38.2598C147.671 33.2642 151.078 27.2183 156.272 27.2467C157.209 27.2751 158.287 27.4738 158.798 27.7292C159.905 28.2969 159.99 28.2969 159.99 27.6157C159.99 27.1048 160.189 27.048 162.261 27.048H164.532V34.7118V42.3755H162.261C160.218 42.3755 159.99 42.3188 159.99 41.8362C159.99 41.2969 159.962 41.2969 159.082 41.7511C157.833 42.3755 155.279 42.4891 153.916 41.9781ZM159.252 37.8057C159.962 37.2664 159.99 37.0961 159.99 34.6834V32.1572L159.082 31.5895C156.811 30.1987 154.115 31.5611 153.831 34.2292C153.661 35.7904 154.086 37.0393 155.108 37.834C156.073 38.6288 158.231 38.6004 159.252 37.8057Z"
        fill="#2DAFE5"
      />
      <path
        d="M118.549 34.8537V27.6157H120.819C122.636 27.6157 123.09 27.7009 123.09 28.0415C123.09 28.5808 122.977 28.5808 124.623 27.7576C126.951 26.5655 129.335 26.9629 131.265 28.8362C132.713 30.2271 133.025 31.7882 133.025 37.3231V42.0917H130.783H128.512L128.427 37.4083C128.341 33.1506 128.285 32.6965 127.745 32.1572C126.837 31.2205 125.389 31.1354 124.226 31.9301L123.232 32.6113L123.147 37.3515L123.09 42.0917H120.819H118.549V34.8537Z"
        fill="#2DAFE5"
      />
    </Icon>
  );
}
