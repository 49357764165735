import { Box, Flex } from "@chakra-ui/react";
import api from "api/api";
import { GetLayout } from "components/get-layout";
import { TabelaDatafast } from "components/servicos-components/tabela-servicos/tabela-higienizacao-bancos";
import { SkeletonLoading } from "components/skeleton-loading";
import { TitleWithBackButton } from "components/title-with-back-button";
import { useApplicationContext } from "contexts/ApplicationContext";
import { SelectedCustomer } from "pages/carteira";
import { useEffect, useState } from "react";
import { useRouter } from "routes/router-control/use-router";
import { Toast } from "components/toast";

export const RefinHigienizacaoBanco = () => {
  const [selectedCustomer, setSelectedCustomer] = useState<SelectedCustomer>();
  const [isLoading, setIsLoading] = useState<any>(false);
  const [tableList, setTableList] = useState<any[]>([]);
  const router = useRouter();
  const { user } = useApplicationContext();

  const params = new URLSearchParams(window.location.search);
  const partnerId = params.get("partnerId");

  const onChangePartner = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`/customers/short`);
      const selectedCustomer = data?.find?.(
        (curr: SelectedCustomer) => `${curr.id}` === partnerId
      );
      setSelectedCustomer(selectedCustomer);
      if (!selectedCustomer) {
        Toast({ title: "Usuário não permitido" });
        router.push("/servicos");
      }
    } catch {
    } finally {
    }

    try {
      const { data } = await api.get(
        `/services?customerId=${partnerId ?? user.userData.customerId}`
      );
      setTableList(
        Object.keys(data.servicesDatafast || {}).map((key) => {
          const currentBank = data.servicesDatafast[key];
          return currentBank;
        })
      );
    } catch (e) {
      setTableList([]);
      Toast({ title: "Erro ao obter serviços", status: "error" });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    onChangePartner();
  }, []);

  return (
    <GetLayout>
      <TitleWithBackButton
        title="Higienização Bancos"
        onClick={() => {
          router.push(`/servicos?partnerId=${partnerId}`);
        }}
      />

      <Flex
        alignItems="center"
        justifyContent="flex-start"
        fontSize="20px"
        fontWeight="semibold"
      >
        Parceiro:&nbsp;
        <SkeletonLoading isLoading={!selectedCustomer} borderRadius="5px">
          {selectedCustomer ? (
            `${selectedCustomer?.codigoNova} - ${selectedCustomer?.name}`
          ) : (
            <Box w="150px" h="30px" ml="10px"></Box>
          )}
        </SkeletonLoading>
      </Flex>
      <Box
        my="10px"
        w="100%"
        borderBottom="1px solid var(--chakra-colors-gray-200)"
      />
      <Flex
        flexDir="column"
        alignItems="flex-start"
        w="100%"
        bg="#fff"
        p="20px 30px"
        borderRadius="5px"
      >
        <TabelaDatafast
          setServicosList={setTableList}
          servicosList={tableList}
        />
      </Flex>
    </GetLayout>
  );
};
