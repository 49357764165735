import api from "api/api";
import { UsuarioBancoErrors } from ".";
import { updateUsuarioBancosTable, UsuarioBanco } from "../page";
import { Toast } from "components/toast";

export const saveOrUpdateUsuarioBanco = async ({
  errors,
  modalData,
  setIsLoading,
  onClose,
  setErrors,
}: {
  errors: UsuarioBancoErrors;
  modalData: UsuarioBanco;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: () => void;
  setErrors: React.Dispatch<React.SetStateAction<UsuarioBancoErrors>>;
}) => {
  let isError = false;
  let errorsCopy: any = { ...errors };

  modalData["ativoDigitador"] = !!modalData["ativoDigitador"];
  modalData["ativoHigienizador"] = !!modalData["ativoHigienizador"];
  modalData["ativoHigienizadorConvenios"] =
    !!modalData["ativoHigienizadorConvenios"];

  // RULES
  // Campos a serem checados de acordo com cada banco
  const checkFields: any = {
    PAN: [
      "banco",
      "username",
      "password",
      "cpfUsuarioCertificado",
      "nomeUsuarioCertificado",
      "codigoDigitador",
      "codigoPromotora",
      "ativoDigitador",
      "ativoHigienizador",
      "ativoHigienizadorConvenios",
    ],
    BMG: [
      "banco",
      "username",
      "password",
      "ativoDigitador",
      "ativoHigienizador",
      "ativoHigienizadorConvenios",
    ],
    SANTANDER: [
      "banco",
      "username",
      "password",
      "ativoDigitador",
      "ativoHigienizador",
      "ativoHigienizadorConvenios",
      "nomePromotora",
    ],
    BRADESCO_DIGIO: [
      "banco",
      "username",
      "password",
      "ativoDigitador",
      "ativoHigienizador",
      "ativoHigienizadorConvenios",
    ],
    LOTUS_TAYA: [
      "banco",
      "username",
      "password",
      "ativoDigitador",
      "ativoHigienizador",
      "ativoHigienizadorConvenios",
    ],
    VCTEX: [
      "banco",
      "username",
      "password",
      "ativoDigitador",
      "ativoHigienizador",
      "ativoHigienizadorConvenios",
    ],
    MASTER: [
      "banco",
      "username",
      "password",
      "ativoDigitador",
      "ativoHigienizador",
      "ativoHigienizadorConvenios",
    ],
    BANRISUL: modalData.ativoHigienizador
      ? [
          "banco",
          "username",
          "password",
          "tokenModulo",
          "ativoDigitador",
          "ativoHigienizador",
          "ativoHigienizadorConvenios",
        ]
      : [
          "banco",
          "username",
          "password",
          "ativoDigitador",
          "ativoHigienizador",
          "ativoHigienizadorConvenios",
        ],
  };
  let fieldsForCheck: (keyof UsuarioBanco)[] = [];

  if (modalData.ativoHigienizadorConvenios) {
    fieldsForCheck = [
      "ativoDigitador",
      "ativoHigienizador",
      "ativoHigienizadorConvenios",
      "botIdCronowise",
      "username",
      "password",
    ];
  } else if (modalData.ativoDigitador) {
    fieldsForCheck = checkFields[modalData.banco] ?? [
      "banco",
      "username",
      "password",
      "cpfUsuarioCertificado",
      "ativoDigitador",
      "ativoHigienizador",
      "ativoHigienizadorConvenios",
    ];
  } else if (modalData.banco === "PAN") {
    fieldsForCheck = [
      "banco",
      "username",
      "password",
      "codigoPromotora",
      "ativoDigitador",
      "ativoHigienizador",
      "ativoHigienizadorConvenios",
    ];
  } else {
    fieldsForCheck = [
      "banco",
      "username",
      "password",
      "ativoDigitador",
      "ativoHigienizador",
      "ativoHigienizadorConvenios",
    ];
  }

  if (modalData.ativoHigienizador && modalData.banco === "BANRISUL") {
    fieldsForCheck = [
      "banco",
      "username",
      "password",
      "tokenModulo",
      "ativoDigitador",
      "ativoHigienizador",
      "ativoHigienizadorConvenios",
    ];
  }

  fieldsForCheck.forEach((field) => {
    if (typeof modalData[field] === "string") {
      if (!(modalData[field] as string).trim()) {
        isError = true;
        errorsCopy[field] = true;
      }
    } else {
      if (modalData[field] == null) {
        isError = true;
        errorsCopy[field] = true;
      }
    }
  });

  if (isError) {
    setErrors(errorsCopy);
    return;
  }

  let body: any = {};

  // Body construct
  ["id", ...fieldsForCheck].forEach((sendKey) => {
    if (sendKey === "username" || sendKey === "password") {
      body[sendKey] = modalData[sendKey]?.trim();
    } else {
      body[sendKey] = (modalData as any)[sendKey];
    }
  });

  setIsLoading(true);
  try {
    if (!modalData.id) {
      await api.post("/usuarios-bancos", body);
    } else {
      await api.put(`/usuarios-bancos/${modalData.id}`, body);
    }
    onClose();
    updateUsuarioBancosTable();
  } catch (error: any) {
    Toast({
      title: error?.response?.data?.message ?? "Erro ao salvar o Usuario Banco",
      status: "error",
    });
  } finally {
    setIsLoading(false);
  }
};

export const higienizadorConveniosOptions = [
  {
    name: "Gov. SP",
    value: "GOV_SP",
  },
  {
    name: "Consigi",
    value: "CONSIGI",
  },
  {
    name: "Consiglog",
    value: "CONSIGLOG",
  },
  {
    name: "Consignet",
    value: "CONSIGNET",
  },
  {
    name: "Econsig",
    value: "ECONSIG",
  },
  {
    name: "Gov. AP",
    value: "GOV_AP",
  },
  {
    name: "Gov. AM",
    value: "GOV_AM",
  },
  {
    name: "Gov. BA",
    value: "GOV_BA",
  },
  {
    name: "Gov. RJ",
    value: "GOV_RJ",
  },
];
