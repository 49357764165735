import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { SkeletonLoading } from "components/skeleton-loading";
import { SelectedCustomer } from "pages/carteira";
import { PriceButton, ServiceDataType } from ".";
import { CardBgIcon } from "./icons";
import { SettingsIcon } from "@chakra-ui/icons";
import {
  bgError,
  bgSucess,
  StatusFormat,
} from "components/dynamic-table/row-status";
import apiCarteira from "api/api-carteira";
import { useEffect, useState } from "react";
import { useRouter } from "routes/router-control/use-router";
import { Toast } from "components/toast";

interface Package {
  id: number;
  servico: "MARGEM_CONVENIO";
  quantidadeContratada: number;
  saldo: number;
  dataCriacao: string;
  dataExpiracao: string;
  habilitado: boolean;
}

export function MargemConvênioServiceCard({
  isLoading,
  priceDesc,
  title,
  serviceData,
  selectedCustomer,
}: {
  isLoading?: boolean;
  title: string;
  priceDesc: string;
  serviceData: any;
  selectedCustomer: SelectedCustomer;
}) {
  const [isLoadingPackages, setIsLoadingPackages] = useState(false);
  const [packagesList, setPackagesList] = useState<Package[]>([]);
  const router = useRouter();
  const px = "25px";
  const cardData = serviceData?.["margemConvenio"] as ServiceDataType;

  const getPackages = async () => {
    setIsLoadingPackages(true);
    try {
      const { data } = await apiCarteira.get(
        `/pacote-servico?servico=MARGEM_CONVENIO&habilitado=true`
      );
      setPackagesList(data);
    } catch (e) {
      Toast({ title: "Erro ao obter serviços", status: "error" });
    } finally {
      setIsLoadingPackages(false);
    }
  };

  useEffect(() => {
    getPackages();
  }, []);

  const statusActive = !!packagesList.find((crr) => crr.habilitado);
  const quantidadePacotes = packagesList.length;
  const quantidadePacotesAtivos = packagesList.filter(
    (crr) => crr.habilitado
  ).length;
  const titleContainerHeight = "80px";
  return (
    <SkeletonLoading
      isLoading={!!isLoading}
      borderRadius="5px"
      bg="#fff"
      overflow="hidden"
    >
      <Box
        bg="#fff"
        borderRadius="5px"
        overflow="hidden"
        h="100%"
        border="1px solid var(--chakra-colors-custom-gray)"
      >
        <Flex
          px={px}
          w="100%"
          h={titleContainerHeight}
          // bg="linear-gradient(to right, #141B3B, #B21F17)"
          alignItems="center"
          pos="relative"
          overflow="hidden"
          justifyContent="flex-start"
        >
          <Text
            fontSize={{ base: "18px", "2xl": "20px" }}
            // color="#fff"
            fontWeight="semibold"
          >
            {title}
          </Text>
          <Box pos="absolute" bottom="-20px" right="35px">
            <CardBgIcon />
          </Box>
        </Flex>
        <Flex flexDir="column" px={px} py="15px" minH="215px">
          {!isLoadingPackages ? (
            <Grid
              templateColumns="1fr 2fr"
              gap="10px"
              alignItems="center"
              w="100%"
              mb="10px"
            >
              <Text
                fontWeight="semibold"
                textTransform="capitalize"
                whiteSpace="nowrap"
              >
                Status:
              </Text>
              <Flex alignItems="center" justifyContent="flex-end">
                <StatusFormat w="82px" bg={statusActive ? bgSucess : bgError}>
                  {statusActive ? "Ativo" : "Inativo"}
                </StatusFormat>
              </Flex>
              <Text fontWeight="semibold" whiteSpace="nowrap">
                Quantidade:
              </Text>
              <Flex alignItems="center" justifyContent="flex-end">
                <Text alignItems="center" justifyContent="flex-end">
                  {quantidadePacotes} ({quantidadePacotesAtivos} Ativos)
                </Text>
              </Flex>

              {/* {packagesList.map((currPackage, i) => {
                const isActive = currPackage.habilitado;
                return (
                  <Fragment key={i}>
                    <Text
                      fontWeight="semibold"
                      textTransform="capitalize"
                      whiteSpace="nowrap"
                    >
                      {currPackage.servico?.replace("_", " ").toLowerCase()}:
                    </Text>
                    <Flex alignItems="center" justifyContent="flex-end">
                      <StatusFormat w="82px" bg={isActive ? bgSucess : bgError}>
                        {isActive ? "Ativo" : "Inativo"}
                      </StatusFormat>
                    </Flex>
                  </Fragment>
                );
              })} */}
            </Grid>
          ) : (
            <Center w="100%" h="52px">
              <Spinner />
            </Center>
          )}

          <Button
            my="10px"
            w="100%"
            size="sm"
            variant="outline"
            bg="#fff"
            onClick={() =>
              router.push(
                `/margem-convenio-service?partnerId=${selectedCustomer.id}`
              )
            }
            leftIcon={<SettingsIcon />}
          >
            Configurar
          </Button>
          <PriceButton mt="auto">{priceDesc}</PriceButton>
        </Flex>
      </Box>
    </SkeletonLoading>
  );
}
