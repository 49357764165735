import { AddIcon } from "@chakra-ui/icons";
import { Box, Button, Input, Text } from "@chakra-ui/react";
import { Popover } from "components/popover";
import { DocumentIcon } from "components/vectors/document-icon";
import { useApplicationContext } from "contexts/ApplicationContext";
import { allRouters } from "routes/routes-array";
import { useClientsAttendanceContext } from "../aba-clientes-context";
import { useState } from "react";
import apiCarteira from "api/api-carteira";
import { validateClientsFilter } from "../clients-filter/utils";
import { openModalConfirm } from "components/modal-confirm-new";
import { Toast } from "components/toast";

export function BotaoCriarMailing() {
  const [mailingName, setMailingName] = useState("");
  const [criandoMailing, setCriandoMailing] = useState(false);
  const { user } = useApplicationContext();
  const { isLoadingClientsList, clientsFilter, clientList } =
    useClientsAttendanceContext();

  const { body } = validateClientsFilter(clientsFilter);

  const handleSubmit = async () => {
    if (clientList?.total! > 10000) {
      openModalConfirm({
        message: `Seu mailing será limitado ao máximo de 10.000, deseja continuar?`,
        onConfirm: async () => {
          setCriandoMailing(true);
          try {
            await apiCarteira.post(`/contratos/clientes/mailing`, {
              mailingName,
              filter: body,
            });
            Toast({ title: "Mailing Criado com sucesso", status: "success" });
          } catch (e) {
            Toast({ title: "Erro ao criar mailing", status: "error" });
          } finally {
            setCriandoMailing(false);
          }
        },
        confirmButtonStyle: { variant: undefined },
      });
    } else {
      setCriandoMailing(true);
      try {
        await apiCarteira.post(`/contratos/clientes/mailing`, {
          mailingName,
          filter: body,
        });
        Toast({ title: "Mailing Criado com sucesso", status: "success" });
      } catch (e) {
        Toast({ title: "Erro ao criar mailing", status: "error" });
      } finally {
        setCriandoMailing(false);
      }
    }
  };

  return allRouters
    .find(({ path }) => path === "/mailing")
    ?.permission(user) ? (
    <Popover
      button={
        <Button
          variant="outline"
          size="sm"
          ml="10px"
          leftIcon={<DocumentIcon color="#343B44" width="20px" height="20px" />}
          opacity={isLoadingClientsList ? 0.5 : 1}
          isLoading={criandoMailing}
          loadingText="Gerando Mailing"
          onClick={(e) => {
            if (isLoadingClientsList) e.stopPropagation();
          }}
        >
          Criar Mailing
        </Button>
      }
      onClose={() => setMailingName("")}
      position="right-bottom"
      popupStyles={{ width: "350px" }}
      title="Criar Mailing"
    >
      {({ onClose }) => (
        <Box>
          <Text mb="8px">Nome do mailing:</Text>

          <Input
            value={mailingName}
            onChange={(e) => setMailingName(e.target.value)}
          />

          <Button
            mt="10px"
            h="36px"
            size="sm"
            w="100%"
            leftIcon={<AddIcon />}
            onClick={() => {
              handleSubmit();
              onClose();
            }}
            loadingText="Criando"
            isLoading={criandoMailing}
          >
            Criar Mailing
          </Button>
        </Box>
      )}
    </Popover>
  ) : null;
}
