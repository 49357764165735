import { Icon, IconProps } from "@chakra-ui/react";

export function CorbexLogo({ ...rest }: IconProps) {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 1020 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M88.2999 124.8C88.6999 123 89.1999 120.5 89.5999 119.3L90.1999 117H56.2999C18.5999 117 14.9999 116.5 8.39985 110.7C-0.800147 102.7 -1.30015 93.2 5.29985 57C9.89985 32.3 11.6999 25.5 16.2999 17.8C20.9999 9.7 29.6999 4 39.8999 2C43.1999 1.4 67.6999 1 102.1 1C156.5 1 159 1.1 159 2.8C159 4.6 155.6 24.9 154.5 29.8L154 32L101.6 31.9C63.7999 31.8 48.6999 32.1 47.1999 32.9C45.9999 33.5 44.2999 35.4 43.3999 37.1C41.8999 40.2 34.9999 75.9 34.9999 81C34.9999 86.3 35.7999 86.5 67.9999 86.8L97.5999 87.1L99.4999 80.5L101.5 73.9L119.7 87.2C129.7 94.5 137.9 100.8 137.9 101.1C138 101.7 89.4999 128 88.2999 128C87.9999 128 87.9999 126.5 88.2999 124.8Z"
        fill="#2C4799"
      />
      <path
        d="M172.7 115.6C170.1 114.9 166.3 113 164.3 111.3C154.7 103.6 153.9 94.2001 160 60C165.1 31.4 166.8 25.1001 171.9 17.2001C176.2 10.7001 180.6 7.20005 189.1 3.70005C194.4 1.60005 195.7 1.50005 243.5 1.20005C277.5 0.900051 294.5 1.20005 299.2 2.00005C313.1 4.40005 319.9 13.0001 320 28.1001C320 33.2001 311.7 80.8001 309.5 88.3001C305.9 100.4 297.7 110 287.4 114.1L281.5 116.5L229.5 116.7C189.2 116.9 176.4 116.6 172.7 115.6ZM273.7 85.0001C277.1 82.8001 278.7 77.8 282 59C285.4 39.4 285.6 34.8001 283.6 33.1001C282.6 32.2001 271.3 31.9001 243.3 31.8001C200.9 31.7001 200.8 31.7001 198.2 37.5001C196.4 41.4001 189.8 80.1001 190.5 82.7001C190.8 84.0001 192.1 85.5001 193.3 86.0001C194.5 86.5001 212.2 86.8001 233.5 86.7001C264.8 86.5001 271.9 86.2001 273.7 85.0001Z"
        fill="#2C4799"
      />
      <path
        d="M317.4 115.3C317.7 114.3 322.2 88.8001 327.5 58.5001C332.7 28.3001 337.3 2.90006 337.5 2.20006C337.9 1.20006 351.6 1.00006 406.2 1.20006L474.5 1.50006L479 3.70006C484.4 6.40006 486.6 9.80007 487.5 16.6001C488.5 23.9001 484.4 47.2001 480.9 54.1001C478.8 58.4001 472.7 64.0001 470.1 64.0001C466.7 64.0001 467.1 65.3001 471.3 67.4001C476.2 69.8001 479 74.6001 479 80.4001C479 82.5001 477.8 90.5001 476.4 98.3001C474.4 109 474.1 112.9 474.9 114.7L476 117H459H442L441.4 114.6C441.1 113.3 441.9 105.7 443.3 97.8001C445 88.2001 445.5 82.9001 444.9 81.8001C444 80.1001 440.9 80.0001 400.6 80.0001H357.2L356.7 82.3001C356.4 83.5001 354.9 91.8001 353.4 100.8L350.5 117H333.7C318.1 117 316.9 116.9 317.4 115.3ZM447.9 52.5001C450.2 50.1001 453.9 31.5001 452.4 30.0001C451.5 29.1001 367.2 28.4001 366.4 29.3001C365.9 29.8001 362 50.9001 362 53.2001C362 55.0001 363.8 55.1001 404 54.8001C444.4 54.5001 446 54.4001 447.9 52.5001Z"
        fill="#2C4799"
      />
      <path
        d="M484 115.7C484 115 485.1 108.1 486.5 100.5C487.8 92.8 492.1 68.3 496 46C499.8 23.7 503.3 4.50002 503.5 3.20002L504.1 0.900024L572.8 1.20002L641.5 1.50002L645.8 3.80002C653.9 8.10002 655.7 17 652.1 34.8C649.3 48.3 644.8 54.9 637.1 56.9C634.6 57.6 634.6 57.7 638.5 59.6C644.7 62.7 646.5 66 646.3 73.8C646 82.9 643.6 95.1 640.7 101.3C637.8 107.7 633.9 111.6 628 114.4C623.6 116.5 622.3 116.5 553.8 116.8C496.6 117 484 116.8 484 115.7ZM607.4 89C610.5 87.9 611.8 85.5 613 78.9C614.8 69.3 618.2 70 568.6 70H525.2L524.1 76.8C523.4 80.5 522.7 85 522.4 86.8L521.8 90H563.3C587.2 90 605.9 89.6 607.4 89ZM616 45.2C617.9 42.9 619.7 33.2 618.6 30.5C618.1 29.2 612.6 29 575.2 29H532.3L531.1 36.3C530.4 40.2 529.6 44.5 529.3 45.8L528.8 48.1L571.5 47.8L614.2 47.5L616 45.2Z"
        fill="#2C4799"
      />
      <path
        d="M672.099 115.1C664.999 112.6 661.999 110.2 659.099 104.7C655.199 97.0001 655.199 89.6001 659.399 65.0001C667.399 17.9001 668.299 14.7001 675.199 8.40006C683.199 1.20006 679.999 1.50006 745.299 1.20006C809.899 0.900059 811.799 1.00006 817.199 6.70006C822.099 11.8001 822.699 17.0001 820.099 32.7001C818.799 40.3001 816.899 48.8001 815.899 51.6001C813.099 59.1001 807.999 64.5001 801.499 66.9001C796.199 68.9001 793.899 69.0001 744.499 69.0001L692.899 69.1001L691.899 71.7001C690.399 75.8001 690.299 83.7001 691.799 85.2001C692.799 86.2001 704.899 86.6001 750.199 86.8001C781.599 86.9001 807.399 87.1001 807.499 87.3001C807.699 87.4001 806.499 94.0001 805.099 102L802.399 116.5L739.999 116.7C681.399 116.9 677.199 116.8 672.099 115.1ZM780.599 48.0001C783.699 46.8001 785.799 41.9001 786.199 34.9001L786.499 29.5001L745.499 29.2001C722.999 29.1001 703.499 29.2001 702.399 29.5001C701.199 29.7001 699.799 31.2001 699.199 32.7001C697.799 36.3001 695.899 47.0001 696.499 48.0001C697.199 49.1001 777.599 49.1001 780.599 48.0001Z"
        fill="#2C4799"
      />
      <path
        d="M814.7 115.2C815.9 114.3 832.2 101.8 850.9 87.5C869.6 73.2 884.8 61.2 884.7 60.8C884.6 60.4 871.5 46.8 855.7 30.6L826.8 1H850.3H873.8L892.1 20.8C902.2 31.6 910.9 40.6 911.5 40.7C912 40.9 913.8 39.9 915.4 38.6L918.3 36.2L905.6 22.7C898.7 15.3 893 8.9 893 8.6C893 8.3 895.3 8 898.1 8C903.2 8 903.3 8.1 909.9 15.2C913.5 19.2 918.3 24.5 920.6 26.9L924.7 31.3L944.1 16.2L963.5 1H985C1005.6 1 1006.4 1.1 1004.3 2.8C1003.1 3.7 985.9 16.9 966.1 32C946.3 47.1 930 60 930 60.7C930 61.3 930.4 62 930.8 62.2C931.2 62.4 940.3 71.7 951 83C961.6 94.3 973.3 106.5 976.9 110.3L983.4 117H960.5L937.5 116.9L925.5 103.5C918.9 96.1 913.3 90.1 913 90C912.7 90 906.8 94.5 899.8 100C887.5 109.6 886.8 110 882 110C876.6 110 875.8 109.2 879.1 107.2C880.2 106.5 887.2 101.1 894.6 95.4L908 84.9L906.4 82.4C905.5 81.1 904.3 80 903.8 80C903.3 80 892.2 88.3 879.2 98.5L855.5 117H834C813.4 117 812.6 116.9 814.7 115.2Z"
        fill="#2C4799"
      />
      <path
        d="M964.9 85.4C952 71.9 941.4 60.5 941.3 60.1C941.2 59.8 956.4 47.9 975.1 33.7L1009 7.90002L1014.1 8.20002L1019.3 8.50002L987.9 32.4C970.6 45.6 955.5 57.2 954.3 58.3L952.1 60.3L968.3 77.4C977.2 86.7 987.8 97.9 991.9 102.2L999.3 110H993.7H988.2L964.9 85.4Z"
        fill="#2C4799"
      />
    </Icon>
  );
}
