import { objectSchema } from "utils/object-methods";
import { ClientsFilterProps } from ".";

export function validateClientsFilter(clientsFilter: ClientsFilterProps) {
  return objectSchema(
    {
      cidades: (value: ClientsFilterProps["cidades"]) => {
        let format = null;
        let valid = value == null || !!value?.length;

        if (value == null) format = null;
        else if (value.length) format = value;

        return { valid, format, message: "Preencha corretamente" };
      },
      estados: (value: ClientsFilterProps["estados"]) => {
        let format = null;
        let valid = value == null || !!value?.length;

        if (value == null) format = null;
        else if (value.length) format = value;

        return { valid, format, message: "Preencha corretamente" };
      },
      orderBy: (value: ClientsFilterProps["orderBy"]) => {
        let format = null;
        let valid = value == null || value instanceof Object;

        if (valid) format = value;

        return { valid, format, message: "Preencha corretamente" };
      },
      page: (value: ClientsFilterProps["page"]) => {
        let format = null;
        let valid = value == null || typeof value === "number";

        if (value == null) format = 1;
        else if (typeof value === "number") format = value;

        return { valid, format, message: "Preencha corretamente" };
      },
      pageSize: (value: ClientsFilterProps["pageSize"]) => {
        let format = null;
        let valid = value == null || typeof value === "number";

        if (value == null) format = 10;
        else if (typeof value === "number") format = value;

        return { valid, format, message: "Preencha corretamente" };
      },
      idadeGte: (value: ClientsFilterProps["idadeGte"]) => {
        let format = null;
        let valid =
          (typeof clientsFilter.idadeLte === "number" &&
            clientsFilter.idadeLte >= value! &&
            value! >= 0) ||
          value == null;

        if (value == null) format = null;
        else if (valid) format = value;

        if (!clientsFilter.idadeLte && !value) format = null;

        return { valid, format, message: "Preencha corretamente" };
      },
      idadeLte: (value: ClientsFilterProps["idadeLte"]) => {
        let format = null;
        let valid =
          (typeof clientsFilter.idadeGte === "number" &&
            clientsFilter.idadeGte <= value! &&
            value! >= 0) ||
          value == null;

        if (value == null) format = null;
        else if (valid) format = value;

        if (!clientsFilter.idadeGte && !value) format = null;

        return { valid, format, message: "Preencha corretamente" };
      },
      prazoGte: (value: ClientsFilterProps["prazoGte"]) => {
        let format = null;
        let valid =
          (typeof clientsFilter.prazoLte === "number" &&
            clientsFilter.prazoLte >= value! &&
            value! >= 0) ||
          value == null;

        if (value == null) format = null;
        else if (valid) format = value;

        if (!clientsFilter.prazoLte && !value) format = null;

        return { valid, format, message: "Preencha corretamente" };
      },
      prazoLte: (value: ClientsFilterProps["prazoLte"]) => {
        let format = null;
        let valid =
          (typeof clientsFilter.prazoGte === "number" &&
            clientsFilter.prazoGte <= value! &&
            value! >= 0) ||
          value == null;

        if (value == null) format = null;
        else if (valid) format = value;

        if (!clientsFilter.prazoGte && !value) format = null;

        return { valid, format, message: "Preencha corretamente" };
      },
      valorBrutoGte: (value: ClientsFilterProps["valorBrutoGte"]) => {
        let format = null;
        let valid =
          (typeof clientsFilter.valorBrutoLte === "number" &&
            clientsFilter.valorBrutoLte >= value! &&
            value! >= 0) ||
          value == null;

        if (value == null) format = null;
        else if (valid) format = value;

        if (!clientsFilter.valorBrutoLte && !value) format = null;

        return { valid, format, message: "Preencha corretamente" };
      },
      valorBrutoLte: (value: ClientsFilterProps["valorBrutoLte"]) => {
        let format = null;
        let valid =
          (typeof clientsFilter.valorBrutoGte === "number" &&
            clientsFilter.valorBrutoGte <= value! &&
            value! >= 0) ||
          value == null;

        if (value == null) format = null;
        else if (valid) format = value;

        if (!clientsFilter.valorBrutoGte && !value) format = null;

        return { valid, format, message: "Preencha corretamente" };
      },
      valorLiquidoGte: (value: ClientsFilterProps["valorLiquidoGte"]) => {
        let format = null;
        let valid =
          (typeof clientsFilter.valorLiquidoLte === "number" &&
            clientsFilter.valorLiquidoLte >= value! &&
            value! >= 0) ||
          value == null;

        if (value == null) format = null;
        else if (valid) format = value;

        if (!clientsFilter.valorLiquidoLte && !value) format = null;

        return { valid, format, message: "Preencha corretamente" };
      },
      valorLiquidoLte: (value: ClientsFilterProps["valorLiquidoLte"]) => {
        let format = null;
        let valid =
          (typeof clientsFilter.valorLiquidoGte === "number" &&
            clientsFilter.valorLiquidoGte <= value! &&
            value! >= 0) ||
          value == null;

        if (value == null) format = null;
        else if (valid) format = value;

        if (!clientsFilter.valorLiquidoGte && !value) format = null;

        return { valid, format, message: "Preencha corretamente" };
      },
      cpfNomeMatricula: (value: ClientsFilterProps["cpfNomeMatricula"]) => {
        let format = null;
        let valid = value == null || typeof value === "string";

        if (value == null || value === "") format = null;
        else if (valid) format = value;

        return { valid, format, message: "Preencha corretamente" };
      },
      dataPagamentoClienteGte: (
        value: ClientsFilterProps["dataPagamentoClienteGte"]
      ) => {
        let format = null;
        let valid =
          (value == null && clientsFilter.dataPagamentoClienteLte == null) ||
          (!!value &&
            !!clientsFilter.dataPagamentoClienteLte &&
            new Date(clientsFilter.dataPagamentoClienteLte).getTime() >=
              new Date(value).getTime());

        if (value == null || value === "") format = null;
        else if (valid) format = value;

        return { valid, format, message: "Preencha corretamente" };
      },
      dataPagamentoClienteLte: (
        value: ClientsFilterProps["dataPagamentoClienteLte"]
      ) => {
        let format = null;
        let valid =
          (value == null && clientsFilter.dataPagamentoClienteGte == null) ||
          (!!value &&
            !!clientsFilter.dataPagamentoClienteGte &&
            new Date(clientsFilter.dataPagamentoClienteGte).getTime() <=
              new Date(value).getTime());

        if (value == null || value === "") format = null;
        else if (valid) format = value;

        return { valid, format, message: "Preencha corretamente" };
      },
      idTipoOperacao: (value: ClientsFilterProps["idTipoOperacao"]) => {
        let format = null;
        let valid = value == null || !!value?.length;

        if (value == null) format = null;
        else if (value.length) format = value;

        return { valid, format, message: "Preencha corretamente" };
      },
    },
    clientsFilter
  );
}

export const dropdownTiposOperacao: {
  name: string;
  value: number;
}[] = [
  {
    name: "Cartão c/ saque",
    value: 8,
  },
  {
    name: "Cartão c/ saque complementar à vista",
    value: 14,
  },
  {
    name: "Cartão s/ saque",
    value: 5,
  },
  {
    name: "Empréstimo Complementar INSS",
    value: 16,
  },
  {
    name: "Margem Livre (Novo)",
    value: 1,
  },
  {
    name: "Margem Livre + Refinanciamento",
    value: 6,
  },
  {
    name: "Portabilidade",
    value: 17,
  },
  {
    name: "Portabilidade + Margem Livre + Refin",
    value: 19,
  },
  {
    name: "Portabilidade + Refin",
    value: 18,
  },
  {
    name: "Refinanciamento",
    value: 2,
  },
  {
    name: "Refinanciamento da Portabilidade",
    value: 29,
  },
  {
    name: "Refinanciamento Rec",
    value: 10,
  },
];
