import apiConsulta from "api/api-consulta";
import {
  Attendance,
  AttendanceFactaFGTS,
  ContratoInss,
  ContratoRMCInss,
} from "components/atendimentos-components/atendimento-form/types";
import { openModalConfirm } from "components/modal-confirm-new";
import { openModalError } from "components/modal-error";
import { Dispatch, SetStateAction } from "react";
import { formatData } from "utils/string-formats";
import { getErrorByMessage, mapObject } from "./mapeamentos";
import {
  AttendanceActionsTypes,
  controllerAttendanceRequests,
} from "../../../../contexts/attendance-context/actions-reducer";
import {
  ConsultaOfflineINSS,
  ConsultaOfflineSiape,
  KeyForward,
} from "./mapeamentos/types";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { AxiosResponse } from "axios";
import { openModalConsultaOfflineSelectBenefit } from "components/atendimentos-components/pagina-atendimento/attendance-data-form/modal-consulta-offline-select-benefit";
import { ConsultaProps } from "components/consulta-components/consulta-fields/types";
import { Toast } from "components/toast";

export interface ConsultaOfflineOptions {
  url: string;
  mapFunction: (
    res: any,
    dispatch: Dispatch<AttendanceActionsTypes>,
    attendance: Attendance
  ) => void;
}

interface ConsultaFGTS {
  valorLiberadoAntecipacaoFGTS: number;
  statusSaldo: AttendanceFactaFGTS["statusSaldo"];
  simulacaoAntecipacaoFGTS: Attendance["simulacaoAntecipacaoFactaFGTS"];
}

export function parseContratoEmprestimoInss(contract: any): ContratoInss {
  delete contract.excluidoAps;
  delete contract.excluidoBanco;
  delete contract.dataInclusao;
  contract.codTipoEmprestimo = contract.tipoEmprestimo?.codigo;
  contract.codBanco = contract.banco?.codigo;
  return contract;
}

export function parseContratoRMCorRCC(contract: any): ContratoRMCInss {
  contract.codTipoEmprestimo = contract.tipoEmprestimo?.codigo;
  delete contract.tipoEmprestimo;
  contract.codBanco = contract.banco?.codigo;
  delete contract.banco;
  delete contract.excluidoAps;
  delete contract.excluidoBanco;
  return contract;
}

const getConvenioURLAndMapeamento = (formValues: Attendance) => {
  return new Map<Attendance["convenio"], ConsultaOfflineOptions>([
    [
      "INSS",
      {
        url: `/consultas/inss/offline/nb/${formValues.nb}`,
        mapFunction: mapConsultaOfflineINSSModeNB,
      },
    ],
    [
      "SIAPE",
      {
        url: `/consultas/siape/offline/cpf/${formValues.cpf}`,
        mapFunction: mapConsultaOfflineSIAPE,
      },
    ],
  ]).get(formValues.convenio);
};

async function requestLoop({
  promise,
  actionByStep,
  onFinish,
  onError,
  delay = 5000,
}: {
  promise: () => Promise<AxiosResponse<any, any>>;
  actionByStep: (response: AxiosResponse<ConsultaFGTS, any>) => {
    finalize: boolean;
  };
  onFinish?: (response: AxiosResponse<any, ConsultaFGTS>) => any;
  onError?: () => any;
  delay?: number;
}) {
  let response: any;
  let finalizeState: any;
  try {
    response = await promise();
    const { finalize } = actionByStep(response);
    finalizeState = finalize;
  } catch (e) {
    onError?.();
  }
  if (!finalizeState) {
    setTimeout(() => requestLoop({ promise, actionByStep, onFinish }), delay);
  } else onFinish?.(response);
}

export async function consultaOffline(
  dispatch: Dispatch<AttendanceActionsTypes>,
  formValues: Attendance,
  loading?: Dispatch<SetStateAction<boolean>>
) {
  const { signal } = controllerAttendanceRequests;
  loading?.(true);
  const { url, mapFunction } = getConvenioURLAndMapeamento(formValues) || {};
  try {
    const res = await apiConsulta.get(url || "", { signal });
    openModalConfirm({
      onConfirm: () => mapFunction?.(res?.data, dispatch, formValues),
      message: "Deseja sobrescrever com os dados da consulta offline?",
      confirmButtonStyle: { leftIcon: <CheckIcon />, variant: undefined },
      rejectButtonStyle: { leftIcon: <CloseIcon w="12px" h="12px" /> },
    });
  } catch (e: any) {
    openModalError({
      message: getErrorByMessage(e, "Erro ao fazer consulta offline"),
    });
  } finally {
    loading?.(false);
  }
}

export async function consultaFGTS({
  loading,
  consultar,
  verificar,
  errorMessage = "Erro ao fazer consulta FGTS",
  isEndLoop,
  onFinish,
}: {
  loading: Dispatch<SetStateAction<boolean>>;
  consultar?: () => Promise<AxiosResponse<any, any>>;
  verificar?: () => Promise<AxiosResponse<any, any>>;
  errorMessage?: string;
  isEndLoop: (response: AxiosResponse<any, any>) => boolean;
  onFinish: (response: AxiosResponse<any, any>) => void;
}) {
  const { signal } = controllerAttendanceRequests;
  loading(true);

  let isStopedConsultation = false;

  signal.addEventListener("abort", () => {
    isStopedConsultation = true;
    loading(false);
  });

  try {
    if (consultar) {
      const res = await consultar();
      const data = res?.data;
      if (data) onFinish(res);
    }

    if (verificar) {
      requestLoop({
        promise: verificar,
        actionByStep: (response) => {
          if (isStopedConsultation) return { finalize: true };
          let finalize = isEndLoop(response);
          return { finalize };
        },
        onFinish: (response) => {
          onFinish(response);
          loading(false);
        },
      });
    }
  } catch (e: any) {
    if (e?.message !== "canceled")
      Toast({
        title: errorMessage,
        status: "error",
      });
    loading(false);
  } finally {
    // loading(false);
  }
}

export function mapConsultaFGTS(
  consulta: ConsultaFGTS,
  dispatch: Dispatch<AttendanceActionsTypes>,
  formValues: Attendance
) {
  let atendimentoFactaFGTS = formValues.atendimentoFactaFGTS!;
  const keyForward: KeyForward<Attendance> = {
    valorLiberadoAntecipacaoFGTS: (data) => {
      atendimentoFactaFGTS = {
        ...atendimentoFactaFGTS,
        valorLiberadoAntecipacao: data,
      };
      return {
        data: atendimentoFactaFGTS,
        field: "atendimentoFactaFGTS",
        dispatchOptions: { type: "changeFieldWithInitial" },
      };
    },
    statusSaldo: (data) => {
      atendimentoFactaFGTS = { ...atendimentoFactaFGTS, statusSaldo: data };
      return {
        data: atendimentoFactaFGTS,
        field: "atendimentoFactaFGTS",
        dispatchOptions: { type: "changeFieldWithInitial" },
      };
    },
    observacaoSaldo: (data) => {
      atendimentoFactaFGTS = { ...atendimentoFactaFGTS, observacaoSaldo: data };
      return {
        data: atendimentoFactaFGTS,
        field: "atendimentoFactaFGTS",
        dispatchOptions: { type: "changeFieldWithInitial" },
      };
    },
    simulacaoAntecipacaoFGTS: (data) => {
      return {
        data: data ?? formValues.simulacaoAntecipacaoFactaFGTS,
        field: "simulacaoAntecipacaoFactaFGTS",
        dispatchOptions: { type: "changeFieldWithInitial" },
      };
    },
  };

  dispatch({
    type: "changeFieldWithInitial",
    payload: { data: "FACTA", fieldName: "bancoDigitacao" },
  });

  mapObject({ keyForward, data: consulta, dispatch, ignoreNullFields: false });
}

export function mapConsultaOfflineSIAPE(
  consulta: ConsultaOfflineSiape,
  dispatch: Dispatch<AttendanceActionsTypes>,
  attendance: Attendance
) {
  let margens = attendance.margens! || {};

  const keyForward: KeyForward<Attendance> = {
    AGENCIA_RECEBIMENTO: (data) => {
      if (data != null) {
        data = `${data}`.trim();
        while (data.length < 11) data = "0" + data;
      }
      return {
        data,
        field: "agenciaPagamento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    BAIRRO: (data) => {
      return {
        data,
        field: "bairro",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    BANCO_RECEBIMENTO: (data) => {
      return {
        data,
        field: "codBancoPagamento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    CELULAR_01: (data) => {
      return {
        data,
        field: "telefoneCelular",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    CEP: (data) => {
      return { data, field: "cep", assignJson: { flag: "CONSULTA OFFLINE" } };
    },
    CIDADE: (data) => {
      return {
        data,
        field: "cidade",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    COMPLEMENTO: (data) => {
      return {
        data,
        field: "complemento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    CONTA_RECEBIMENTO: (data) => {
      return {
        data,
        field: "contaPagamento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    CPF: (data) => {
      return { data, field: "cpf", assignJson: { flag: "CONSULTA OFFLINE" } };
    },
    EMAIL: (data) => {
      return { data, field: "email", assignJson: { flag: "CONSULTA OFFLINE" } };
    },
    ENDERECO: (data) => {
      return {
        data,
        field: "endereco",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    // "ESTADO CIVIL": (data) => {
    //   return { data, field: "estadoCivil" };
    // },
    MAE: (data) => {
      return {
        data,
        field: "nomeMae",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    MATRICULA: (data) => {
      return { data, field: "nb", assignJson: { flag: "CONSULTA OFFLINE" } };
    },
    NASC: (data?: string) => {
      const [ano, mes, dia] = data?.split("-") || [];
      return {
        data: `${dia}/${mes}/${ano}`,
        field: "dataNascimento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    NOME: (data) => {
      return { data, field: "nome", assignJson: { flag: "CONSULTA OFFLINE" } };
    },
    ORGAO: (data) => {
      return {
        data,
        field: "orgaoSIAPE",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    RG: (data) => {
      return {
        data,
        field: "documento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    SEXO: (data) => {
      return { data, field: "sexo", assignJson: { flag: "CONSULTA OFFLINE" } };
    },
    UF: (data) => {
      return { data, field: "uf", assignJson: { flag: "CONSULTA OFFLINE" } };
    },
    "RESUMO_FINANCEIRO.BASECALC": (data) => {
      return {
        data,
        field: "valorSalario",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "RESUMO_FINANCEIRO.SALDO35": (data) => {
      margens = { ...margens, valorMargemLivreNovo: data };
      return {
        data: margens,
        field: "margens",
        assignJson: {
          attendanceKeyPath: "margens.valorMargemLivreNovo",
          flag: "CONSULTA OFFLINE",
        },
      };
    },
    "RESUMO_FINANCEIRO.SITUACAOFUNCIONAL": (data) => {
      return {
        data,
        field: "situacaoFuncionalSIAPE",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "RESUMO_FINANCEIRO.UPAG": (data) => {
      return {
        data,
        field: "unidadePagadoraSIAPE",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
  };

  mapObject({ keyForward, data: consulta, dispatch });
}

export function mapConsultaOfflineINSSModeNB(
  consulta: ConsultaOfflineINSS,
  dispatch: Dispatch<AttendanceActionsTypes>,
  attendance: Attendance
) {
  let margens = attendance.margens! || {};

  const keyForward: KeyForward<Attendance> = {
    cpf: (data) => {
      data = completeCPF(data);
      return {
        data,
        field: "cpf",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    bloqueioEmprestismo: (data) => {
      return {
        data,
        field: "bloqueadoEmprestimo",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    dataNascimento: (data) => {
      return {
        data: formatData(data),
        field: "dataNascimento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    ddb: (data) => {
      return {
        data: formatData(data),
        field: "ddb",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    nome: (data) => {
      return { data, field: "nome", assignJson: { flag: "CONSULTA OFFLINE" } };
    },
    nomeMae: (data) => {
      return {
        data,
        field: "nomeMae",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    pensaoAlimenticia: (data) => {
      return {
        data,
        field: "pensaoAlimenticia",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    possuiRepresentanteLegalProcurador: (data) => {
      return {
        data,
        field: "possuiRepresentante",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    CpfRepresentanteLegal: (data) => {
      return {
        data,
        field: "cpfRepresentanteLegal",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    NomeRepresentanteLegal: (data) => {
      return {
        data,
        field: "nomeRepresentanteLegal",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    beneficioPermiteEmprestimo: (data) => {
      return {
        data,
        field: "elegivelEmprestimo",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    sexo: (data: string) => {
      const parseData =
        data?.toUpperCase() === "MASCULINO"
          ? "MASCULINO"
          : data?.toUpperCase() === "FEMININO"
          ? "FEMININO"
          : null;
      return {
        data: parseData,
        field: "sexo",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    situacaoBeneficio: (data) => {
      return {
        data,
        field: "situacaoNB",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    valorBeneficio: (data) => {
      return {
        data,
        field: "valorSalario",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "enderecoPessoal.bairro": (data) => {
      return {
        data,
        field: "bairro",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "enderecoPessoal.cep": (data) => {
      return { data, field: "cep", assignJson: { flag: "CONSULTA OFFLINE" } };
    },
    "enderecoPessoal.cidade": (data) => {
      return {
        data,
        field: "cidade",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "enderecoPessoal.endereco": (data) => {
      return {
        data,
        field: "endereco",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "enderecoPessoal.uf": (data) => {
      return { data, field: "uf", assignJson: { flag: "CONSULTA OFFLINE" } };
    },
    "dadosBancarios.banco.codigo": (data) => {
      return {
        data,
        field: "codBancoPagamento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "dadosBancarios.meioPagamento.tipo": (data: string) => {
      const parsedData =
        data?.toUpperCase() === "CARTAO MAGNETICO"
          ? "CARTAO_MAGNETICO"
          : data?.toUpperCase() === "CONTA CORRENTE"
          ? "CONTA_CORRENTE"
          : null;
      return {
        data: parsedData,
        field: "tipoPagamento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "especie.codigo": (data) => {
      data = Number(data);
      return {
        data: isNaN(data) ? null : data,
        field: "especieNB",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    nomeRepresentanteLegal: (data) => {
      return {
        data,
        field: "nomeRepresentanteLegal",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "dadosBancarios.agencia.codigo": (data) => {
      return {
        data,
        field: "agenciaPagamento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "dadosBancarios.meioPagamento.numero": (data) => ({
      data: data === "0" ? "" : data,
      field: "contaPagamento",
      assignJson: { flag: "CONSULTA OFFLINE" },
    }),
    contratosCartao: (data: any[]) => {
      let parsedData = null;
      if (data?.length) {
        parsedData = data.map((contract: any) =>
          parseContratoRMCorRCC(contract)
        );
      }
      return {
        data: parsedData,
        field: "contratosRMCInss",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    contratosRcc: (data: any[]) => {
      let parsedData = null;
      if (data?.length)
        parsedData = data.map((contract: any) =>
          parseContratoRMCorRCC(contract)
        );
      return {
        data: parsedData,
        field: "contratosRCCInss",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    Rg: (data) => {
      const parseData = data?.length > 4 ? data : null;
      return {
        data: parseData,
        field: "documento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "margem.possuiCartao": (data) => {
      return {
        data,
        field: "possuiCartaoRmc",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "margem.quantidadeEmprestimo": (data) => {
      return {
        data,
        field: "qtdEmprestimos",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "margem.margemDisponivelCartao": (data) => {
      margens = { ...margens, valorMargemCartaoRMC: data };
      return {
        data: margens,
        field: "margens",
        assignJson: {
          attendanceKeyPath: "margens.valorMargemCartaoRMC",
          flag: "CONSULTA OFFLINE",
        },
      };
    },
    "margem.margemDisponivelRcc": (data) => {
      return {
        data: data === 0 ? true : typeof data === "number" ? false : null,
        field: "possuiCartaoBeneficio",
        assignJson: {
          attendanceKeyPath: "possuiCartaoBeneficio",
          flag: "CONSULTA OFFLINE",
        },
      };
    },
    "margem.margemDisponivelEmprestimo": (data) => {
      const parsedData = typeof data === "number" ? data : null;
      margens = { ...margens, valorMargemLivreNovo: parsedData };
      return {
        data: margens,
        field: "margens",
        assignJson: {
          attendanceKeyPath: "margens.valorMargemLivreNovo",
          flag: "CONSULTA OFFLINE",
        },
      };
    },
    contratosEmprestimo: (data: any[]) => {
      const parsedData = data.map((contract: any) =>
        parseContratoEmprestimoInss(contract)
      );
      return {
        data: parsedData,
        field: "contratosEmprestimosInss",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
  };

  mapObject({ keyForward, data: consulta, dispatch });
}

export function mapConsultaOfflineINSSModeCPF(
  consultaList: ConsultaProps[],
  dispatch: Dispatch<AttendanceActionsTypes>,
  attendance: Attendance
) {
  if (consultaList.length > 1) {
    openModalConsultaOfflineSelectBenefit({
      benefitList: consultaList,
      selectBenefit: (selectedBenefit) => {
        mapBenefitConsultaOfflineINSSModeCPF({
          attendance,
          dispatch,
          selectedBenefit,
        });
        console.log(selectedBenefit);
      },
    });
  } else {
    mapBenefitConsultaOfflineINSSModeCPF({
      attendance,
      dispatch,
      selectedBenefit: consultaList[0],
    });
  }
}

function mapBenefitConsultaOfflineINSSModeCPF({
  attendance,
  dispatch,
  selectedBenefit,
}: {
  selectedBenefit: ConsultaProps;
  dispatch: Dispatch<AttendanceActionsTypes>;
  attendance: Attendance;
}) {
  let margens = attendance.margens || {};
  selectedBenefit = {
    ...selectedBenefit,
    beneficio: {
      ...selectedBenefit.beneficio,
      nb: selectedBenefit.beneficio.beneficio,
    },
  } as any;

  const keyForward: KeyForward<Attendance> = {
    "beneficio.nb": (value) => {
      return {
        data: value,
        field: "nb",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.nome": (value) => {
      return {
        data: value,
        field: "nome",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.dataNascimento": (value) => {
      value = parseData(value);
      return {
        data: value,
        field: "dataNascimento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.cpf": (value) => {
      value = completeCPF(value);
      return {
        data: value,
        field: "cpf",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.especie.codigo": (value) => {
      return {
        data: Number(value),
        field: "especieNB",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.dadosBancarios.banco.codigo": (value) => {
      return {
        data: value,
        field: "codBancoPagamento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.situacaoBeneficio": (value) => {
      return {
        data: value,
        field: "situacaoNB",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.sexo": (value) => {
      return {
        data: value,
        field: "sexo",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.pensaoAlimenticia": (value) => {
      return {
        data: value,
        field: "pensaoAlimenticia",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.margem.margemDisponivelEmprestimo": (value) => {
      margens = { ...margens, valorMargemLivreNovo: value };
      return {
        data: margens,
        field: "margens",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.margem.margemDisponivelRcc": (value) => {
      margens = { ...margens, valorMargemCartaoRCC: value };
      return {
        data: margens,
        field: "margens",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.margem.possuiCartao": (value) => {
      return {
        data: value,
        field: "possuiCartaoRmc",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.margem.margemDisponivelCartao": (value) => {
      margens = { ...margens, valorMargemCartaoRMC: value };
      return {
        data: margens,
        field: "margens",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.contratosEmprestimo": (value) => {
      value = parseContrato(value);
      return {
        data: value,
        field: "contratosEmprestimosInss",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.contratosCartao": (value) => {
      value = parseContratoRMCOrRCCConsultaOffline(value);
      return {
        data: value,
        field: "contratosRMCInss",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.contratosRcc": (value) => {
      value = parseContratoRMCOrRCCConsultaOffline(value);
      return {
        data: value,
        field: "contratosRCCInss",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.nomeMae": (value) => {
      return {
        data: value,
        field: "nomeMae",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.ddb": (value) => {
      value = parseData(value);
      return {
        data: value,
        field: "ddb",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.Rg": (value) => {
      return {
        data: value,
        field: "documento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.enderecoPessoal.endereco": (value) => {
      return {
        data: value,
        field: "endereco",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.enderecoPessoal.cidade": (value) => {
      return {
        data: value,
        field: "cidade",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.enderecoPessoal.uf": (value) => {
      return {
        data: value,
        field: "uf",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.enderecoPessoal.cep": (value) => {
      return {
        data: value,
        field: "cep",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.enderecoPessoal.bairro": (value) => {
      return {
        data: value,
        field: "bairro",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.valorBeneficio": (value) => {
      return {
        data: value,
        field: "valorSalario",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.possuiRepresentanteLegalProcurador": (value) => {
      return {
        data: value,
        field: "possuiRepresentante",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.bloqueioEmprestismo": (value) => {
      return {
        data: value,
        field: "bloqueadoEmprestimo",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.dadosBancarios.agencia.codigo": (value) => {
      return {
        data: value,
        field: "agenciaPagamento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
    "beneficio.dadosBancarios.meioPagamento.numero": (value) => {
      return {
        data: value,
        field: "contaPagamento",
        assignJson: { flag: "CONSULTA OFFLINE" },
      };
    },
  };

  mapObject({ keyForward, data: selectedBenefit, dispatch });
}

const parseContrato = (
  contratosList:
    | {
        contrato: string;
        tipoEmprestimo: { codigo: string; descricao: string };
        banco: { codigo: string; nome: string };
        dataInicioContrato: string;
        competenciaInicioDesconto: string;
        competenciaFimDesconto: string;
        dataInclusao: string;
        situacao: string;
        excluidoAps: boolean;
        excluidoBanco: boolean;
        valorEmprestado: number;
        valorParcela: number;
        quantidadeParcelas: number;
        quantidadeParcelasEmAberto: number;
        saldoQuitacao: number;
        taxa: number;
        TaxaCalculada: string;
      }[]
    | null
): ContratoInss[] | null | undefined => {
  return contratosList?.map((contrato) => ({
    id: Math.random(),
    codBanco: contrato?.banco?.codigo,
    codTipoEmprestimo: contrato?.tipoEmprestimo?.codigo,
    competenciaFimDesconto: parseData(contrato?.competenciaFimDesconto)!,
    competenciaInicioDesconto: parseData(contrato?.competenciaInicioDesconto)!,
    contrato: contrato?.contrato,
    dataInicioContrato: parseData(contrato?.dataInicioContrato)!,
    origem: null,
    quantidadeParcelas: contrato?.quantidadeParcelas,
    quantidadeParcelasEmAberto: contrato?.quantidadeParcelasEmAberto,
    saldoQuitacao: contrato?.saldoQuitacao,
    situacao: contrato?.situacao,
    taxa: contrato?.taxa,
    valorEmprestado: contrato?.valorEmprestado,
    valorParcela: contrato?.valorParcela,
  }));
};

function completeCPF(value: string) {
  if (value) {
    value = `${value}`.trim();
    while (value.length < 11) value = "0" + value;
  }
  return value;
}

const parseContratoRMCOrRCCConsultaOffline = (
  contratosList:
    | {
        contrato: string;
        tipoEmprestimo: {
          codigo: string;
          descricao: string;
        };
        banco: {
          codigo: string;
          nome: string;
        };
        dataInicioContrato: string;
        dataInclusao: string;
        situacao: string;
        excluidoAps: boolean;
        excluidoBanco: boolean;
        limiteCartao: number;
        valorReservado: number;
      }[]
    | null
): ContratoRMCInss[] | null | undefined => {
  return contratosList?.map((contrato) => ({
    id: Math.random(),
    codBanco: contrato?.banco?.codigo,
    codTipoEmprestimo: contrato?.tipoEmprestimo?.codigo,
    contrato: contrato?.contrato,
    dataInclusao: parseData(contrato?.dataInclusao)!,
    dataInicioContrato: parseData(contrato?.dataInicioContrato)!,
    situacao: contrato?.situacao,
    excluidoAps: contrato?.excluidoAps,
    excluidoBanco: contrato?.excluidoBanco,
    limiteCartao: contrato?.limiteCartao,
    oportunidade: null,
    origem: null,
    valorReservado: contrato?.valorReservado,
  }));
};

const parseData = (data: string | null) => {
  if (data) {
    let dia = "";
    const mes = data.match(/\D\d{2}\D/g)?.[0].replace(/\D/g, "");
    const ano = data.match(/\d{4}/g)?.[0].replace(/\D/g, "");
    if (ano && data.indexOf(ano) === 0) {
      dia = data.match(/\D\d{2}$/g)?.[0].replace(/\D/g, "") || "";
    } else {
      dia = data.match(/^\d{2}\D/g)?.[0].replace(/\D/g, "") || "";
    }
    return `${dia}/${mes}/${ano}`;
  }
};

// {
//   "beneficio": {
//       "beneficio": "5542340349",
//       "nome": "NAIR MARIAN DA CRUZ",
//       "dataNascimento": "1955-09-04",
//       "cpf": "69465614904",
//       "especie": {
//           "codigo": "32",
//           "descricao": "APOSENTADORIA POR INVALIDEZ PREVIDENCIARIA"
//       },
//       "dadosBancarios": {
//           "banco": {
//               "codigo": "341",
//               "tipo": "2",
//               "numero": "6551",
//               "nome": " Banco Itaú S"
//           },
//           "agencia": {
//               "codigo": "7732",
//               "nome": null,
//               "endereco": {
//                   "endereco": "",
//                   "bairro": null,
//                   "cidade": null,
//                   "cep": null,
//                   "uf": null
//               },
//               "orgaoPagador": "579842"
//           },
//           "meioPagamento": {
//               "tipo": "CONTA CORRENTE",
//               "numero": "6551"
//           }
//       },
//       "situacaoBeneficio": "ATIVO",
//       "nit": "13798308720",
//       "identidade": 0,
//       "sexo": "FEMININO",
//       "dib": "2012-10-03",
//       "valorBeneficio": 1458.35,
//       "possuiRepresentanteLegalProcurador": false,
//       "pensaoAlimenticia": false,
//       "bloqueioEmprestismo": false,
//       "beneficioPermiteEmprestimo": true,
//       "margem": {
//           "competencia": "2024/04",
//           "baseCalculoMargemConsignavel": 1458.35,
//           "margemDisponivelEmprestimo": 29.42,
//           "percentualMargemDisponivelEmprestimo": 0,
//           "percentualMargemTotalEmprestimo": 35,
//           "quantidadeEmprestimo": "7",
//           "possuiCartao": true,
//           "margemDisponivelCartao": 0,
//           "percentualmargemDisponivelCartao": 0,
//           "percentualMargemTotalCartao": 5,
//           "margemDisponivelRcc": 0
//       },
//       "contratosEmprestimo": [
//           {
//               "contrato": "596392707",
//               "tipoEmprestimo": {
//                   "codigo": "98",
//                   "descricao": ""
//               },
//               "banco": {
//                   "codigo": "29",
//                   "nome": " Banco Itaú Consignado S.A."
//               },
//               "dataInicioContrato": "2019-09-27",
//               "competenciaInicioDesconto": "2019-10-01",
//               "competenciaFimDesconto": "2025-09-01",
//               "dataInclusao": "2019-09-27",
//               "situacao": "Ativo",
//               "excluidoAps": false,
//               "excluidoBanco": false,
//               "valorEmprestado": 585.71,
//               "valorParcela": 16.4,
//               "quantidadeParcelas": 72,
//               "quantidadeParcelasEmAberto": 16,
//               "saldoQuitacao": 241.16,
//               "taxa": 2.08,
//               "TaxaCalculada": "2.23"
//           },
//           {
//               "contrato": "37899570805",
//               "tipoEmprestimo": {
//                   "codigo": "98",
//                   "descricao": ""
//               },
//               "banco": {
//                   "codigo": "33",
//                   "nome": " Banco Santander"
//               },
//               "dataInicioContrato": "2023-11-01",
//               "competenciaInicioDesconto": "2023-11-01",
//               "competenciaFimDesconto": "2030-11-01",
//               "dataInclusao": "2023-11-01",
//               "situacao": "Ativo",
//               "excluidoAps": false,
//               "excluidoBanco": false,
//               "valorEmprestado": 2639.16,
//               "valorParcela": 60.98,
//               "quantidadeParcelas": 84,
//               "quantidadeParcelasEmAberto": 77,
//               "saldoQuitacao": 2588.8,
//               "taxa": 1.79,
//               "TaxaCalculada": "12.57"
//           },
//           {
//               "contrato": "2009956170",
//               "tipoEmprestimo": {
//                   "codigo": "98",
//                   "descricao": ""
//               },
//               "banco": {
//                   "codigo": "41",
//                   "nome": " Banco do Estado do Rio Grande do Sul S.A."
//               },
//               "dataInicioContrato": "2021-06-25",
//               "competenciaInicioDesconto": "2021-11-01",
//               "competenciaFimDesconto": "2028-10-01",
//               "dataInclusao": "2021-06-25",
//               "situacao": "Ativo",
//               "excluidoAps": false,
//               "excluidoBanco": false,
//               "valorEmprestado": 1060.89,
//               "valorParcela": 22.61,
//               "quantidadeParcelas": 84,
//               "quantidadeParcelasEmAberto": 53,
//               "saldoQuitacao": 835.53,
//               "taxa": 1.54,
//               "TaxaCalculada": "1.54"
//           },
//           {
//               "contrato": "4946404320190301",
//               "tipoEmprestimo": {
//                   "codigo": "98",
//                   "descricao": ""
//               },
//               "banco": {
//                   "codigo": "341",
//                   "nome": " Banco Itaú S"
//               },
//               "dataInicioContrato": "2019-03-08",
//               "competenciaInicioDesconto": "2019-03-01",
//               "competenciaFimDesconto": "2025-02-01",
//               "dataInclusao": "2019-03-08",
//               "situacao": "Ativo",
//               "excluidoAps": false,
//               "excluidoBanco": false,
//               "valorEmprestado": 637.63,
//               "valorParcela": 18,
//               "quantidadeParcelas": 72,
//               "quantidadeParcelasEmAberto": 9,
//               "saldoQuitacao": 174.56,
//               "taxa": 2.08,
//               "TaxaCalculada": "2.2599999999999998"
//           },
//           {
//               "contrato": "10792160320220613",
//               "tipoEmprestimo": {
//                   "codigo": "98",
//                   "descricao": ""
//               },
//               "banco": {
//                   "codigo": "341",
//                   "nome": " Banco Itaú S"
//               },
//               "dataInicioContrato": "2022-06-14",
//               "competenciaInicioDesconto": "2022-07-01",
//               "competenciaFimDesconto": "2029-06-01",
//               "dataInclusao": "2022-06-14",
//               "situacao": "Ativo",
//               "excluidoAps": false,
//               "excluidoBanco": false,
//               "valorEmprestado": 1886.54,
//               "valorParcela": 43.59,
//               "quantidadeParcelas": 84,
//               "quantidadeParcelasEmAberto": 61,
//               "saldoQuitacao": 1705.52,
//               "taxa": 2.14,
//               "TaxaCalculada": "2.3500000000000001"
//           },
//           {
//               "contrato": "4784810587420",
//               "tipoEmprestimo": {
//                   "codigo": "98",
//                   "descricao": ""
//               },
//               "banco": {
//                   "codigo": "739",
//                   "nome": " Banco Cetelem S.A."
//               },
//               "dataInicioContrato": "2020-09-29",
//               "competenciaInicioDesconto": "2020-10-01",
//               "competenciaFimDesconto": "2027-09-01",
//               "dataInclusao": "2020-09-29",
//               "situacao": "Ativo",
//               "excluidoAps": false,
//               "excluidoBanco": false,
//               "valorEmprestado": 16436.45,
//               "valorParcela": 303.92,
//               "quantidadeParcelas": 84,
//               "quantidadeParcelasEmAberto": 40,
//               "saldoQuitacao": 9900.33,
//               "taxa": 1.12,
//               "TaxaCalculada": "1.1299999999999999"
//           },
//           {
//               "contrato": "5184217696420",
//               "tipoEmprestimo": {
//                   "codigo": "98",
//                   "descricao": ""
//               },
//               "banco": {
//                   "codigo": "739",
//                   "nome": " Banco Cetelem S.A."
//               },
//               "dataInicioContrato": "2020-03-03",
//               "competenciaInicioDesconto": "2020-03-01",
//               "competenciaFimDesconto": "2026-02-01",
//               "dataInclusao": "2020-03-03",
//               "situacao": "Ativo",
//               "excluidoAps": false,
//               "excluidoBanco": false,
//               "valorEmprestado": 699.26,
//               "valorParcela": 15.5,
//               "quantidadeParcelas": 72,
//               "quantidadeParcelasEmAberto": 21,
//               "saldoQuitacao": 290.47,
//               "taxa": 1.4,
//               "TaxaCalculada": "1.3999999999999999"
//           }
//       ],
//       "contratosCartao": [
//           {
//               "contrato": "0229014828793",
//               "tipoEmprestimo": {
//                   "codigo": "76",
//                   "descricao": "Reserva de Margem para Cartão (RMC)"
//               },
//               "banco": {
//                   "codigo": "623",
//                   "nome": " Banco PAN S.A."
//               },
//               "dataInicioContrato": "2017-06-01",
//               "dataInclusao": "",
//               "situacao": "Ativo",
//               "excluidoAps": false,
//               "excluidoBanco": false,
//               "limiteCartao": 1450,
//               "valorReservado": 59
//           }
//       ],
//       "contratosRcc": [],
//       "html": "",
//       "NaoPerturbe": "0",
//       "VlrIrrf": ".0000",
//       "Rg": "00017527589",
//       "ddb": "2012-11-19",
//       "nomeMae": "OLGA DOS SANTOS",
//       "enderecoPessoal": {
//           "endereco": "R DO MARISCO 85 AP 23",
//           "bairro": "INGLESES DO RIO V",
//           "cidade": "FLORIANOPOLIS",
//           "cep": "88058090",
//           "uf": "SC"
//       }
//   }
// }
