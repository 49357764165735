export function getRelativeRect(
  element: HTMLElement | Element,
  parent: HTMLElement | Element
) {
  const elementRect = element.getBoundingClientRect();
  const parentRect = parent.getBoundingClientRect();

  const bottom = elementRect.bottom;
  const height = elementRect.height;
  const left = elementRect.left - parentRect.left;
  const right = elementRect.right - parentRect.right;
  const top = elementRect.top - parentRect.top;
  const width = elementRect.width;
  const x = elementRect.x - parentRect.x;
  const y = elementRect.y - parentRect.y;

  return { bottom, height, left, right, top, width, x, y };
}

export function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}
