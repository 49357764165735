import { Flex, Spinner } from "@chakra-ui/react";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useLayoutEffect, useState } from "react";
import { RouterType } from "routes/routes-array";
import { PageTemplate } from "routes/template";
import { makeEvent, useEventListener } from "services/events";
import { hasSession } from "Session";
import {
  getUrlData,
  Router,
  RouterChangeEventParam,
  updateRouterVar,
} from "./use-router";
import { deleteAllPagesPayloads, setPagePayload } from "./use-page-payload";

export function RouterSelect({
  allRouters,
  redirectIfNotLogged,
  notFoundPage,
}: {
  allRouters: RouterType[];
  redirectIfNotLogged: string;
  notFoundPage: () => JSX.Element;
}) {
  let [currentPath, setCurrentPath] = useState<string>(
    window.location.pathname
  );
  const { user } = useApplicationContext();

  const visibleRoutes = allRouters.filter(({ permission }) => permission(user));

  const isTokenPresent = hasSession();

  const currentRoute = visibleRoutes.find(({ path }) => path === currentPath);

  const PageComponent = currentRoute?.Component ?? notFoundPage;

  const changeRouter = ({ href, payload }: { href: string; payload?: any }) => {
    const completedUrl = getUrlData(href);
    const url = new URL(completedUrl.fullUrl);
    setCurrentPath(url.pathname);
    updateRouterVar(url);
    window.history.pushState(null, "", href);
    if (payload) setPagePayload(href, payload);
    else deleteAllPagesPayloads();
  };

  useEventListener("routerChange", changeRouter, "AppRootListener", {
    removeDidUnMount: false,
  });

  useLayoutEffect(() => {
    if (!isTokenPresent) {
      Router.push(redirectIfNotLogged);
    }
  }, [isTokenPresent]);

  useLayoutEffect(() => {
    window.onpopstate = function (e) {
      emitRouterChangeEvent({ href: window.location.href });
    };
  }, []);

  return (
    <>
      {user.isLogging && isTokenPresent ? (
        <Flex w="100%" h="100vh" justifyContent="center" alignItems="center">
          <Spinner />
        </Flex>
      ) : (
        <PageTemplate PageComponent={PageComponent} />
      )}
    </>
  );
}

export const emitRouterChangeEvent = (params: RouterChangeEventParam) =>
  makeEvent("routerChange", params);
