import { SVGProps } from "react";

export function PIXIcon({ ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_731_789)">
        <path
          d="M10.4159 12.5686C10.6479 12.3366 11.0475 12.3366 11.2795 12.5686L14.5881 15.8772C15.1983 16.4873 16.0104 16.8225 16.8698 16.8225H17.5186L13.3463 20.9948C12.0444 22.2623 9.93031 22.2623 8.62836 20.9948L4.4389 16.8096H4.83851C5.69789 16.8096 6.51 16.4744 7.12015 15.8643L10.4159 12.5686ZM11.2795 9.40608C11.0045 9.64241 10.6522 9.64671 10.4159 9.40608L7.12015 6.11038C6.51 5.46155 5.69789 5.16507 4.83851 5.16507H4.4389L8.62406 0.978191C9.93031 -0.325738 12.0444 -0.325738 13.3463 0.978191L17.5229 5.15218H16.8698C16.0104 5.15218 15.1983 5.48733 14.5881 6.09749L11.2795 9.40608ZM4.83851 6.13186C5.43148 6.13186 5.97718 6.37249 6.43265 6.79358L9.72835 10.0893C10.0377 10.36 10.4416 10.5533 10.8498 10.5533C11.2537 10.5533 11.6577 10.36 11.967 10.0893L15.2756 6.78069C15.6967 6.36389 16.2768 6.12327 16.8698 6.12327H18.4897L20.9948 8.62835C22.2967 9.9303 22.2967 12.0444 20.9948 13.3463L18.4897 15.8514H16.8698C16.2768 15.8514 15.6967 15.6108 15.2756 15.1897L11.967 11.8811C11.3698 11.2838 10.3256 11.2838 9.72835 11.8854L6.43265 15.1768C5.97718 15.5979 5.43148 15.8385 4.83851 15.8385H3.47125L0.978199 13.3463C-0.325731 12.0444 -0.325731 9.9303 0.978199 8.62835L3.47125 6.13186H4.83851Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_731_789">
          <rect width="22" height="22" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
