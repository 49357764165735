import { ReactPortal } from "components/portal";
import { CheckIcon } from "components/vectors/check-icon";
import { CloseIcon } from "components/vectors/close-icon";
import { ErrorIcon } from "components/vectors/error-icon";
import { InfoIcon } from "components/vectors/info-icon";
import { WarningIcon } from "components/vectors/warning-icon";
import { ReactNode, useEffect, useRef, useState } from "react";
import { AnimateElement } from "utils/animations";
import { IconButton, useColorMode } from "@chakra-ui/react";
import { makeEvent, useEventListener } from "services/events";

export interface UseToastOptions {
  status?: "error" | "info" | "success" | "warning";
  title: string | ReactNode | JSX.Element;
  description?: string | ReactNode | JSX.Element;
  duration?: number;
  isClosable?: boolean;
  position?:
    | "top"
    | "bottom"
    | "top-right"
    | "top-left"
    | "bottom-right"
    | "bottom-left";
}

function MakeToast({
  description,
  position = "top",
  status = "info",
  title,
  duration = 4000,
  isClosable = true,
}: UseToastOptions) {
  const { colorMode: theme } = useColorMode();
  const toastRef = useRef<HTMLDivElement>(null);

  const statusIcon = {
    info: <InfoIcon color="#fff" />,
    error: <ErrorIcon color="#fff" />,
    success: <CheckIcon color="#fff" />,
    warning: <WarningIcon color="#fff" />,
  };

  const onClose = () => {
    AnimateElement(
      320,
      [1, 0],
      (value) => {
        if (toastRef.current) toastRef.current.style.opacity = `${value}`;
      },
      () => toastRef.current?.remove()
    );
  };

  const onShow = () => {
    const { clientHeight, style } = toastRef.current!;
    const beginAnimation = {
      top: [-15, 0],
      bottom: [15, 0],
      "top-right": [clientHeight, -10],
      "top-left": [-clientHeight, 10],
      "bottom-right": [clientHeight, -10],
      "bottom-left": [-clientHeight, 10],
    }[position] as [number, number];

    const finishAnimation = {
      top: undefined,
      bottom: undefined,
      "top-right": [-10, 0],
      "top-left": [10, 0],
      "bottom-right": [-10, 0],
      "bottom-left": [10, 0],
    }[position] as [number, number];

    const translate: string = {
      top: "translateY",
      bottom: "translateY",
      "top-right": "translateX",
      "top-left": "translateX",
      "bottom-right": "translateX",
      "bottom-left": "translateX",
    }[position];

    AnimateElement(320, [0, 1], (value) => {
      style.opacity = `${value}`;
    });
    AnimateElement(
      240,
      beginAnimation,
      (progress) => {
        style.transform = `${translate}(${progress}px)`;
      },
      () => {
        if (finishAnimation)
          AnimateElement(80, finishAnimation, (progress) => {
            style.transform = `translateX(${progress}px)`;
          });
      }
    );
    //close
    AnimateElement(duration - 320, [0, 1], () => {}, onClose);
  };

  useEffect(() => {
    onShow();
  }, []);

  return (
    <div ref={toastRef} className={`toast ${status} ${position} ${theme}`}>
      <div className={`toast-container`}>
        <div className="toast-icon icon-bg-toast">{statusIcon[status]}</div>
        <div className={`toast-content${isClosable ? " closable" : ""}`}>
          <p className="toast-title">{title}</p>
          {description ? (
            <p className="toast-description">{description}</p>
          ) : null}
        </div>
        {isClosable ? (
          <IconButton
            aria-label=""
            onClick={onClose}
            className="close-toast-button"
            size="xs"
            w="24px"
            minW="24px"
            h="24px"
            icon={<CloseIcon width="10px" height="10px" />}
            variant="none"
            style={{ border: 0 }}
          />
        ) : null}
      </div>
    </div>
  );
}

export function Toast(options: UseToastOptions) {
  makeEvent("createToast", options);
}

export function ToastContainer() {
  const [toasts, setToasts] = useState<UseToastOptions[]>([]);

  useEventListener("createToast", (options: UseToastOptions) => {
    setToasts((toasts) => [...toasts, options]);
  });

  if (!toasts.length) return null;

  return (
    <ReactPortal wrapperId="react-portal-toast-container">
      {toasts.map((options, index) => (
        <MakeToast key={index} {...options} />
      ))}
    </ReactPortal>
  );
}
