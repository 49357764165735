import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { Button, Flex, IconButton, Text } from "@chakra-ui/react";
import api from "api/api";
import { invalidTextStyle } from "chakra/theme";
import DynamicTable from "components/dynamic-table";
import { openModalConfirm } from "components/modal-confirm-new";
import { useEffect, useState } from "react";
import {
  openModalUsuariosBancos,
  UsuariosBancosModal,
} from "../usuarios-bancos-modal";
import { makeEvent, useEventListener } from "services/events";
import { Toast } from "components/toast";
import { dropdownBancos } from "../consts";

export interface UsuarioBanco {
  banco: string;
  codigoDigitador?: string;
  cpfUsuarioCertificado?: string;
  customer?: string;
  id?: number | string;
  lastErrorMsg?: string;
  nomeUsuarioCertificado: null | string;
  password: string;
  username: string;
  codigoPromotora?: string;
  tokenModulo?: string;
  botIdCronowise: string;
  nomePromotora?: string;
  ativoDigitador?: boolean;
  ativoHigienizador: boolean;
  ativoHigienizadorConvenios?: boolean;
}

export function PageContent() {
  const [usuariosBancosList, setUsuariosBancosList] = useState<UsuarioBanco[]>(
    []
  );

  const [loading, setLoading] = useState(false);

  const loadUsuariosBanco = async () => {
    setLoading(true);
    try {
      let response = await api.get("/usuarios-bancos");
      setUsuariosBancosList(response.data);
    } catch (error) {
      Toast({
        title: "Erro ao buscar lista de Usuario Banco",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEventListener("updateUsuarioBancosTable", loadUsuariosBanco);

  useEffect(() => {
    loadUsuariosBanco();
  }, []);

  const openNew = () => {
    openModalUsuariosBancos();
  };

  const acceptDeleteUsuarioBanco = async (rowData: UsuarioBanco) => {
    setLoading(true);
    try {
      await api.delete(`/usuarios-bancos/${rowData.id}`);
      setUsuariosBancosList((prev) =>
        prev.filter((curr) => curr.id !== rowData.id)
      );
    } catch (error) {
      Toast({
        title: "Erro ao excluir Usuario Banco",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmDeleteUsuarioBanco = (rowData: UsuarioBanco) => {
    openModalConfirm({
      message: `Deseja excluir ${rowData.username}${
        rowData.banco ? ` (${rowData.banco}) ` : ""
      }?`,
      onConfirm: () => acceptDeleteUsuarioBanco(rowData),
      confirmButtonStyle: { variant: "danger", leftIcon: <DeleteIcon /> },
    });
  };

  const editUsuarioBanco = (usuarioBanco: UsuarioBanco) => {
    openModalUsuariosBancos(usuarioBanco);
  };

  const columns = [
    {
      name: "Banco",
      render: (row: UsuarioBanco) =>
        dropdownBancos.find((crr) => crr.value === row.banco)?.name,
    },
    {
      name: "Usuário",
      key: "username",
      render: (row: UsuarioBanco) => (
        <>
          <Text>{row.username}</Text>
          {row.lastErrorMsg ? (
            <Text sx={invalidTextStyle}>({row.lastErrorMsg})</Text>
          ) : null}
        </>
      ),
    },
    {
      name: "Higienizador",
      render: (row: UsuarioBanco) =>
        row.ativoHigienizador ? <b>SIM</b> : "NÃO",
    },
    {
      name: "Digitador",
      render: (row: UsuarioBanco) => (row.ativoDigitador ? <b>SIM</b> : "NÃO"),
    },
    {
      name: "Margem Convênios",
      render: (row: UsuarioBanco) =>
        row.ativoHigienizadorConvenios ? <b>SIM</b> : "NÃO",
    },
    {
      name: "Senha",
      key: "password",
    },
    {
      name: "Ações",
      render: (row: UsuarioBanco) => (
        <Flex gap="10px">
          <IconButton
            aria-label=""
            icon={<EditIcon />}
            onClick={() => editUsuarioBanco(row)}
          />
          <IconButton
            aria-label=""
            icon={<DeleteIcon />}
            variant="danger"
            onClick={() => confirmDeleteUsuarioBanco(row)}
          />
        </Flex>
      ),
    },
  ];

  return (
    <>
      <Flex justifyContent="space-between" px="10px" mb="30px">
        <Text fontSize="25" fontWeight="bold">
          Usuários Bancos
        </Text>
        <Flex gap="20px" alignItems="center">
          <Button leftIcon={<AddIcon />} onClick={openNew}>
            Novo
          </Button>
        </Flex>
      </Flex>

      <DynamicTable
        columns={columns}
        rows={usuariosBancosList}
        isLoading={loading}
        emptyMessage="Não há usuários cadastrados"
      />

      <UsuariosBancosModal />
    </>
  );
}

export const updateUsuarioBancosTable = async () => {
  await makeEvent("updateUsuarioBancosTable");
};
