import { SVGProps } from "react";

export function PaperAssignIcon({ ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_1238_113)">
        <path
          d="M13.6388 4.40582C13.1591 3.92604 12.3784 3.92607 11.8985 4.40593C11.7509 4.55366 10.8085 5.49683 10.6646 5.64093V2.39055C10.6646 2.06188 10.5366 1.7529 10.3041 1.5205L9.14407 0.360391C8.91168 0.127969 8.60267 0 8.274 0H1.23096C0.552477 0 0.000488281 0.551988 0.000488281 1.23047V12.7695C0.000488281 13.448 0.552477 14 1.23096 14H9.43408C10.1126 14 10.6646 13.448 10.6646 12.7695V9.12272L13.6388 6.14597C14.1197 5.66511 14.1198 4.88674 13.6388 4.40582ZM8.20364 0.820312C8.28113 0.820312 8.43046 0.806887 8.56403 0.940434L9.72412 2.10055C9.85427 2.23073 9.84427 2.37338 9.84427 2.46094H8.20364V0.820312ZM9.84424 12.7695C9.84424 12.9957 9.66027 13.1797 9.43408 13.1797H1.23096C1.0048 13.1797 0.820801 12.9957 0.820801 12.7695V1.23047C0.820801 1.00431 1.0048 0.820312 1.23096 0.820312H7.3833V2.87109C7.3833 3.09761 7.56694 3.28125 7.79346 3.28125H9.84424V6.46196C9.84424 6.46196 8.63417 7.67307 8.63411 7.67309L8.05434 8.25289C8.00934 8.2979 7.97537 8.35281 7.95525 8.41321L7.37518 10.1534C7.32604 10.3007 7.36441 10.4633 7.47427 10.5731C7.58428 10.6831 7.74684 10.7213 7.894 10.6722L9.63416 10.0921C9.69456 10.072 9.74946 10.0381 9.79447 9.99305L9.84424 9.94328V12.7695ZM8.92439 8.54293L9.50444 9.12297L9.28287 9.34454L8.41282 9.63454L8.70283 8.76446L8.92439 8.54293ZM10.0844 8.54279L9.50433 7.96275C9.8132 7.65363 11.185 6.28064 11.4759 5.98946L12.056 6.5695L10.0844 8.54279ZM13.0586 5.56604L12.6358 5.98921L12.0558 5.40917L12.4787 4.98586C12.6387 4.82593 12.8989 4.82595 13.0588 4.98586C13.2187 5.14582 13.2195 5.40512 13.0586 5.56604Z"
          fill="#4E5762"
        />
        <path
          d="M7.79346 4.10156H2.05127C1.82475 4.10156 1.64111 4.2852 1.64111 4.51172C1.64111 4.73823 1.82475 4.92188 2.05127 4.92188H7.79346C8.01997 4.92188 8.20361 4.73823 8.20361 4.51172C8.20361 4.2852 8.01997 4.10156 7.79346 4.10156Z"
          fill="#4E5762"
        />
        <path
          d="M6.15283 5.74219H2.05127C1.82475 5.74219 1.64111 5.92583 1.64111 6.15234C1.64111 6.37886 1.82475 6.5625 2.05127 6.5625H6.15283C6.37935 6.5625 6.56299 6.37886 6.56299 6.15234C6.56299 5.92583 6.37935 5.74219 6.15283 5.74219Z"
          fill="#4E5762"
        />
        <path
          d="M6.15283 7.38281H2.05127C1.82475 7.38281 1.64111 7.56645 1.64111 7.79297C1.64111 8.01948 1.82475 8.20312 2.05127 8.20312H6.15283C6.37935 8.20312 6.56299 8.01948 6.56299 7.79297C6.56299 7.56645 6.37935 7.38281 6.15283 7.38281Z"
          fill="#4E5762"
        />
        <path
          d="M6.15283 9.02344H2.05127C1.82475 9.02344 1.64111 9.20708 1.64111 9.43359C1.64111 9.66011 1.82475 9.84375 2.05127 9.84375H6.15283C6.37935 9.84375 6.56299 9.66011 6.56299 9.43359C6.56299 9.20708 6.37935 9.02344 6.15283 9.02344Z"
          fill="#4E5762"
        />
        <path
          d="M7.79346 11.5391H5.33252C5.106 11.5391 4.92236 11.7227 4.92236 11.9492C4.92236 12.1757 5.106 12.3594 5.33252 12.3594H7.79346C8.01997 12.3594 8.20361 12.1757 8.20361 11.9492C8.20361 11.7227 8.01997 11.5391 7.79346 11.5391Z"
          fill="#4E5762"
        />
      </g>
      <defs>
        <clipPath id="clip0_1238_113">
          <rect width="14" height="14" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
