import { Button, ButtonProps, Flex } from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";

import { useState } from "react";
import { useEventListener, makeEvent } from "services/events";

export type ModalConfirmOptions = {
  onConfirm?: () => any;
  onOpen?: () => any;
  onClose?: () => any;
  onReject?: () => any;
  message?:
    | string
    | JSX.Element
    | (({ onClose }: { onClose: () => void }) => string | JSX.Element);
  title?: string;
  confirmButtonStyle?: ButtonProps;
  rejectButtonStyle?: ButtonProps;
  variant?: "normal" | "danger";
};

export function ModalConfirm() {
  const [isLoading, setIsLoading] = useState(false);
  const [modalConfirmOptions, setModalConfirmOptions] =
    useState<ModalConfirmOptions | null>(null);

  useEventListener("open-modal-confirm", (data: ModalConfirmOptions) => {
    if (modalConfirmOptions !== null) {
      new Error("The confirmation modal is already open");
    } else setModalConfirmOptions(data);
  });

  function onCloseModal() {
    modalConfirmOptions?.onClose?.();
    setModalConfirmOptions(null);
  }

  const { onClick: rejectButtonOnClick, ...rejectButtonStyle } =
    modalConfirmOptions?.rejectButtonStyle || {};
  const { onClick: confirmButtonOnClick, ...confirmButtonStyle } =
    modalConfirmOptions?.confirmButtonStyle || {};

  const modalFooter = modalConfirmOptions ? (
    <>
      <Button
        variant="outline"
        onClick={(e) => {
          modalConfirmOptions.onReject?.();
          onCloseModal();
          rejectButtonOnClick?.(e);
        }}
        children="Não"
        isDisabled={isLoading}
        {...rejectButtonStyle}
      />
      <Button
        variant={
          modalConfirmOptions.variant === "normal"
            ? undefined
            : modalConfirmOptions.variant ?? "danger"
        }
        onClick={async (e) => {
          setIsLoading(true);
          try {
            await modalConfirmOptions.onConfirm?.();
          } catch {}
          setIsLoading(false);
          onCloseModal();
          confirmButtonOnClick?.(e);
        }}
        isLoading={isLoading}
        children="Sim"
        {...confirmButtonStyle}
      />
    </>
  ) : undefined;

  return (
    <>
      <CustomModal
        isOpen={modalConfirmOptions !== null}
        onClose={onCloseModal}
        modalFooter={modalFooter}
        modalTitle={modalConfirmOptions?.title ?? "Confirmação"}
        size="md"
      >
        <Flex alignItems="center" gap="10px">
          {typeof modalConfirmOptions?.message === "function"
            ? modalConfirmOptions.message({ onClose: onCloseModal })
            : modalConfirmOptions?.message
            ? modalConfirmOptions.message
            : "Confirmar Operação?"}
        </Flex>
      </CustomModal>
    </>
  );
}

export function openModalConfirm(options: ModalConfirmOptions) {
  makeEvent("open-modal-confirm", options);
}
