import { Box, Center, Grid, IconButton, Text } from "@chakra-ui/react";
import { DropdownOptionProps } from "components/dropdown";
import { CleanIcon } from "components/vectors/clean-icon";
import { SetStateAction, useEffect, useState } from "react";
import api from "api/api";
import axios, { AxiosError } from "axios";
import { InputSearch } from "components/input-search";
import { DropdownField } from "components/dropdown-field";
import { Toast } from "components/toast";
import { MailingData, MailingOptionFilter } from "../..";

interface Option {
  name: string | undefined;
  value: any;
}

type FilterProps = {
  addedFilters: MailingOptionFilter;
  onChangeHigienizacao: (
    newValue: SetStateAction<MailingOptionFilter>,
    key: string
  ) => void;
  filterKey: string;
  isDisabled?: boolean;
  allModalData: MailingOptionFilter;
  mailingId?: number;
  errorMessage: string | undefined;
  mailingData: Partial<MailingData>;
};

export function RefinMailing({
  addedFilters,
  onChangeHigienizacao,
  filterKey,
  isDisabled,
  allModalData,
  mailingId,
  errorMessage,
  mailingData,
}: FilterProps) {
  const [isLoadingBanks, setIsLoadingBanks] = useState(false);
  const [convenioOptions, setConvenioOptions] = useState<Option[]>([]);
  const [isLoadingConvenio, setIsLoadingConvenio] = useState(false);
  const [bankOptions, setBankOptions] = useState<DropdownOptionProps[]>([]);

  const filterValue: { banco: string; idConvenio?: number }[] =
    addedFilters[filterKey]?.value ?? [];
  const selectedBanks = filterValue.map(({ banco }) => banco);
  const isConvenioEnabled = selectedBanks.includes("PAN");
  const selectedConvenio = convenioOptions.find(
    (crr) =>
      crr.value === filterValue.find((crr) => crr.banco === "PAN")?.idConvenio
  );
  const convenioName = selectedConvenio?.name;
  const idConvenio = selectedConvenio?.value;

  const set = ({ value }: { value: any }) => {
    onChangeHigienizacao(
      (prev) => ({
        ...prev,
        [filterKey]: { ...prev[filterKey], value },
      }),
      filterKey
    );
  };

  const onChangeBank = (banco: any) => {
    const isPANSelected = selectedBanks.includes("PAN") || banco === "PAN";
    const isInclude = selectedBanks.includes(banco);
    if (isInclude) {
      const newValue = filterValue.filter((curr) => curr.banco !== banco);

      set({ value: newValue });
    } else {
      const newValue = [...filterValue, { banco }];
      set({ value: newValue });
    }
  };

  const onChangeConvenio = (idConvenio: number | null) => {
    if (idConvenio == null) {
      const newValue = filterValue.map((curr) => {
        if (curr.banco === "PAN") delete curr["idConvenio"];
        return curr;
      });

      set({ value: newValue });
    } else {
      const newValue = filterValue.map((curr) => {
        if (curr.banco === "PAN") curr.idConvenio = idConvenio;
        return curr;
      });
      set({ value: newValue });
    }
  };

  const getBanksOptions = async () => {
    setIsLoadingBanks(true);
    try {
      const { data } = await api.get(`/datafast/mailing/${mailingId}`);
      setBankOptions(
        data.map((key: string) => {
          return { name: key, value: key };
        })
      );
    } catch (e) {
      Toast({ title: "Erro ao carregar bancos", status: "error" });
    } finally {
      setIsLoadingBanks(false);
    }
  };

  const getConvenioOptions = async (bank = "PAN") => {
    setIsLoadingConvenio(true);
    try {
      const { data } = await api.get(`/pipeline/saque-complementar-convenios`, {
        params: {
          tipoOperacao: mailingData.tipoOperacao,
          convenio: mailingData.convenio,
          banco: bank,
        },
      });
      const options = data.map(
        (item: { nome: string; idConvenio: string }) => ({
          name: item.nome,
          value: item.idConvenio,
        })
      );

      setConvenioOptions(options);
    } catch (err) {
      const errors = err as Error | AxiosError;
      if (axios.isAxiosError(errors)) {
        Toast({
          title: errors.response?.data,
        });
      } else {
        Toast({
          title: errors.message,
        });
      }
    } finally {
      setIsLoadingConvenio(false);
    }
  };

  const cleanFilter = () => {
    set({ value: [] });
  };

  useEffect(() => {
    getBanksOptions();
  }, []);

  useEffect(() => {
    if (isConvenioEnabled) getConvenioOptions();
  }, [isConvenioEnabled]);

  const errorMessageBanco = errorMessage?.includes("banco")
    ? errorMessage
    : errorMessage?.includes("convênio")
    ? ""
    : errorMessage;

  const errorMessageConvenio = errorMessage?.includes("convênio")
    ? errorMessage
    : errorMessage?.includes("banco")
    ? ""
    : errorMessage;

  return (
    <>
      <Grid templateColumns="auto 32px" gap="8px">
        <DropdownField
          dropdownProps={{
            w: "100%",
            onChange: (value) => {
              onChangeBank(value);
            },
            disabled: isDisabled,
            loadingText: "Selecione",
            spinnerPlacement: "end",
            multiSelect: true,
            isLoading: isLoadingBanks,
          }}
          options={bankOptions}
          value={selectedBanks}
          errorMessage={errorMessageBanco}
        />
        <Center>
          <IconButton
            aria-label=""
            variant="outline"
            size="sm"
            icon={<CleanIcon />}
            onClick={() => cleanFilter()}
            disabled={isDisabled}
          />
        </Center>
      </Grid>
      {isConvenioEnabled ? (
        <Box mt="10px">
          <Text mb="8px">Convênio PAN</Text>
          <InputSearch
            options={convenioOptions}
            onChange={(value) => {
              onChangeConvenio(value);
            }}
            openOnFocus
            clearInput={false}
            searchKeys={["name"]}
            value={convenioName ?? ""}
            checkIsInclude={(option) => idConvenio === option.value}
            isLoading={isLoadingConvenio}
            inputProps={{
              isDisabled: isDisabled || isLoadingConvenio,
              w: "100%",
              autoComplete: "off",
              placeholder: isLoadingConvenio
                ? "Carregando Convênios"
                : "Selecione o convênio",
              onChange: (e) => {
                if (e.target.value === "") {
                  onChangeConvenio(null);
                }
              },
            }}
            errorMessage={errorMessageConvenio}
          />
        </Box>
      ) : null}
    </>
  );
}
