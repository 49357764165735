import { Box, Button, Text } from "@chakra-ui/react";
import api from "api/api";
import { useEffect, useState } from "react";
import {
  StatusFormat,
  bgError,
  bgInfo,
  bgSucess,
} from "components/dynamic-table/row-status";
import DynamicTable from "components/dynamic-table";
import { useApplicationContext } from "contexts/ApplicationContext";
import { openModalConfirm } from "components/modal-confirm-new";
import { Column } from "components/dynamic-table/types";
import { ModalChangeCobranca } from "../service-card/modal-change-cobranca";
import { ModalFilasDatafast, openModalDatafast } from "../modal-datafast";
import { PriceButton } from "../service-card";
import { useRouter } from "routes/router-control/use-router";
import { Toast, UseToastOptions } from "components/toast";

interface Fila {
  dataExpiracao: string;
  expirado: boolean;
  id: number;
  possuiRecorrencia: boolean;
}

interface RowProps {
  ativo: true;
  banco: string;
  cobranca: "MENSAL" | "AVULSO";
  filas: Fila[];
  id: 39441;
  qtdFilas: number;
  qtdFilasAtivas: number;
}

export function TabelaDatafast({
  servicosList,
  setServicosList,
}: {
  setServicosList: React.Dispatch<React.SetStateAction<RowProps[]>>;
  servicosList: RowProps[];
}) {
  const [isLoadingServicos, setIsLoadingServicos] = useState(false);
  const { user } = useApplicationContext();
  const router = useRouter();

  const params = new URLSearchParams(window.location.search);
  const partnerId = params.get("partnerId");

  const loadDatafast = async () => {
    setIsLoadingServicos(true);
    if (`${user.userData.customerId}` !== partnerId) {
      if (["SUPER", "FINANCEIRO", "COMERCIAL"].includes(user.userData.type!)) {
      } else {
        Toast({ title: "Usuário não permitido" });
        router.push("/");
      }
    }
    try {
      const { data } = await api.get(`/services?customerId=${partnerId}`);

      setServicosList(
        Object.keys(data.servicesDatafast || {}).map((key) => {
          const currentBank = data.servicesDatafast[key];
          return currentBank;
        })
      );
    } catch (e) {
      setServicosList([]);
      Toast({ title: "Erro ao obter serviços", status: "error" });
    } finally {
      setIsLoadingServicos(false);
    }
  };

  useEffect(() => {
    loadDatafast();
  }, []);

  const changeAtivoStatus = (row: RowProps) => {
    const message = (
      <Box w="100%">
        <Text mb="10px">{`Deseja ${
          !row.ativo ? "ATIVAR" : "DESATIVAR"
        } consulta avulsa para o banco "${row.banco}"?`}</Text>
        <PriceButton>R$ 0,05 por consulta</PriceButton>
      </Box>
    );
    openModalConfirm({
      message,
      onConfirm: async () => {
        try {
          const { data } = await api.get(
            `/services/datafast/change-ativo-avulso?customerId=${partnerId}&banco=${
              row.banco
            }&ativo=${!row.ativo}`
          );
          setServicosList((list) =>
            list.map((currentRow) => {
              if (currentRow.banco === row.banco) {
                return data;
              }
              return currentRow;
            })
          );
        } catch (e: any) {
          const errorStatus = e?.response?.data?.message ?? "";
          const errorMessage: { [k: string]: UseToastOptions } = {
            cannot_enable_avulso_when_cobranca_mensal: {
              title: "Erro ao mudar status do serviço",
              description: "É necessário que a cobrança esteja avulsa",
            },
            service_not_expired: {
              title: "Serviço não expirado",
              description: "Não é possível desativar um serviço não expirado",
            },
            sem_saldo: { title: "Saldo insuficiente" },
          };
          Toast({
            ...(errorMessage[errorStatus] ?? {
              title: "Erro ao mudar status de serviço",
            }),
            status: "error",
            duration: 7000,
          });
        } finally {
        }
      },
      confirmButtonStyle: {
        variant: undefined,
      },
    });
  };

  const servicosColumns: Column[] = [
    {
      name: "Banco",
      render: (row) => {
        return <>{row.banco}</>;
      },
      cellStyle: () => ({ px: { base: "12px", "2xl": "20px" } }),
    },
    {
      name: "Cobrança",
      render: (row: RowProps) => {
        return (
          <>
            <StatusFormat bg={bgInfo}>{row.cobranca}</StatusFormat>
          </>
        );
      },
      cellStyle: () => ({ px: { base: "12px", "2xl": "20px" } }),
    },
    {
      name: "Qtd. Filas",
      render: (row: RowProps) => {
        const singular = row.qtdFilasAtivas === 1;
        if (row.qtdFilas != null && row.qtdFilasAtivas != null)
          return `${row.qtdFilas} (${row.qtdFilasAtivas} ativa${
            singular ? "" : "s"
          })`;
        return null;
      },
      cellStyle: () => ({ px: { base: "12px", "2xl": "20px" } }),
    },
    {
      name: "Status",
      render: (row: RowProps) => {
        const bg = new Map([
          ["true", bgSucess],
          ["false", bgError],
        ]).get(`${row.ativo}`);

        return (
          <StatusFormat bg={bg}>{row.ativo ? "Ativo" : "Inativo"}</StatusFormat>
        );
      },
      cellStyle: () => ({ px: { base: "12px", "2xl": "20px" } }),
    },
    {
      name: "Ações",
      render: (row: RowProps) => {
        const bgAtivo = new Map([
          ["true", bgSucess],
          ["false", bgError],
        ]).get(`${!row.ativo}`)!;

        return (
          <>
            <Button
              onClick={async () => openModalDatafast(row)}
              size="xs"
              mr="8px"
              variant="outline"
            >
              Configurar filas mensais
            </Button>
            {row.cobranca === "AVULSO" || row.cobranca == null ? (
              <Button
                onClick={async () => changeAtivoStatus(row)}
                variant="outline"
                size="xs"
              >
                {row.cobranca == null
                  ? "Ativar avulso"
                  : !row.ativo
                  ? "Ativar"
                  : "Desativar"}
              </Button>
            ) : null}
          </>
        );
      },
      cellStyle: () => ({ px: { base: "12px", "2xl": "20px" } }),
    },
  ];

  return (
    <Box w="100%">
      <DynamicTable
        columns={servicosColumns}
        rows={servicosList}
        isLoading={isLoadingServicos}
        pageSize={15}
        headerStyle={(header) => ({
          textAlign: header.key === "valor" ? "end" : "center",
          bg: "#fff",
          color: "#656F7D",
          textTransform: "uppercase",
          fontSize: "12px",
          fontWeight: "medium",
          _hover: { bg: "gray.50" },
          px: { base: "12px", "2xl": "20px" },
        })}
        containerStyle={{ boxShadow: "none" }}
      />
      <ModalChangeCobranca />
      <ModalFilasDatafast loadDatafast={loadDatafast} />
    </Box>
  );
}
