import { Box, Button, Flex, Tooltip } from "@chakra-ui/react";
import { getToken } from "Session";
import { TelefoneContatoDialog } from "atendimento/TelefoneContatoDialog";
import { defaultScroll } from "chakra/theme";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useEffect, useRef, useState } from "react";
import { ReadyState } from "react-use-websocket";
import { useWebSocket } from "react-use-websocket/dist/lib/use-websocket";
import { exportFunction } from "services/get-external-function";
import {
  createWebSocketEventListener,
  makeWebSocketEvent,
} from "utils/web-socket-events";
import { ChatInput } from "./chat-input";
import { ChatHeader } from "./components/chat-header";
import { MessagesChat } from "./components/messages-chat";
import { useAttendanceContext } from "contexts/attendance-context";
import { PhoneIcon } from "components/vectors/phone-icon";
import { makeManualCall } from "services/api3cplus";
import { openModalTabulacao3cPlus } from "components/atendimentos-components/modal-tabulacao-3cplus";
import { use3cPlusContext } from "contexts/Context3cPlus";
import { Toast } from "components/toast";

export interface Message {
  body: string;
  direction: "i" | "o";
  from: string;
  id: string;
  to: string;
  type: "chat" | "revoked" | "document" | "image" | "video" | "ptt" | "audio";
  mimetype?: string;
  filename?: string;
  duration?: string;
  userFullName?: string;
  url?: string;
  mediaKey?: string;
}

export interface ContactInfo {
  isWAContact: boolean;
  name: string;
  profilePicUrl: string;
}

export type ChatStatus = "authenticated" | "not-authenticated";

function ChatWindow() {
  const chatContainerRef = useRef<HTMLDivElement>(null);
  const { controls3cPlus, dispatch3cPlus } = use3cPlusContext();
  const { dispatch, formControl, setTransparentLoading, transparentLoading } =
    useAttendanceContext();
  const [isUpdatingNumber, setIsUpdatingNumber] = useState(false);
  const { user } = useApplicationContext();
  const [chatStatus, setChatStatus] = useState<ChatStatus>("not-authenticated");
  const { telefoneContatoNormalizado: phone, whatsAppSession } =
    formControl.values as Attendance;
  const url = process.env.REACT_APP_PUBLIC_WEB_SOCKET!;
  const formValues = formControl.values;

  const ligarManualmenteDisabled =
    transparentLoading ||
    controls3cPlus.inCall ||
    !formValues.telefoneContatoNormalizado ||
    !controls3cPlus.campaign.isLogged;

  const { sendJsonMessage, readyState } = useWebSocket<any>(
    url,
    {
      retryOnError: true,
      queryParams: {
        info: window.btoa(
          JSON.stringify({
            token: getToken(),
            phone,
            client: "tela-atendimento",
            whatsAppObjectId: whatsAppSession?.id,
            attendanceId: formControl.values.id,
          })
        ),
      },
      reconnectInterval: 5000,
      reconnectAttempts: Number.MAX_VALUE,
      shouldReconnect: () => true,
      onMessage: ({ data }) => {
        const parsedData = JSON.parse(data);
        if (parsedData.contentType === "requested-media") {
          const listenerId = parsedData.requestedMedia.messageId;
          makeWebSocketEvent("requested-media", parsedData, listenerId);
        } else
          makeWebSocketEvent(
            parsedData.state ?? parsedData.contentType,
            parsedData
          );
      },
    },
    user.isLogged && !!phone
  );

  exportFunction("sendJsonMessage", sendJsonMessage);

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting", // 0
    [ReadyState.OPEN]: "Open", // 1
    [ReadyState.CLOSING]: "Closing", // 2
    [ReadyState.CLOSED]: "Closed", // 3
    [ReadyState.UNINSTANTIATED]: "Uninstantiated", //-1
  }[readyState];

  useEffect(() => {
    console.log(`${readyState} (${connectionStatus}) Websocket Whatsapp`);
  }, [readyState, connectionStatus]);

  createWebSocketEventListener("whatsapp-qrcode", (data) => {
    setChatStatus("not-authenticated");
  });

  createWebSocketEventListener("chat", (state) =>
    setChatStatus("authenticated")
  );

  createWebSocketEventListener("qrcode-generated", () =>
    setChatStatus("not-authenticated")
  );

  createWebSocketEventListener("qrcode-not-generated", (state) => {
    setChatStatus("not-authenticated");
  });

  return (
    <Box
      h="100%"
      pos="sticky"
      maxH="calc(100vh - 30px - 36px - 30px - 25px)"
      top="-5px"
      w="100%"
    >
      <Flex
        flexDir="column"
        w="100%"
        h={user.userData?.enabled3cplus ? "calc(100% - 40px)" : "100%"}
        // maxH="calc(100vh - 62px - 16px - 72px)"
        bgImg="/assets/layout/images/back-whatsapp.png"
        bgSize="cover"
        borderRadius="5px"
        overflow="hidden"
      >
        <ChatHeader />
        {/* MESSAGES CONTAINER */}
        <Flex
          ref={chatContainerRef}
          flexDir="column-reverse"
          maxH={"calc(100% - 50px - 53px - 37px)"}
          h="100%"
          overflow="auto"
          p="15px 10px 10px 10px"
          sx={defaultScroll}
          pos="relative"
        >
          <MessagesChat
            setChatStatus={setChatStatus}
            isUpdatingNumber={isUpdatingNumber}
          />
        </Flex>
        {/* END MESSAGES CONTAINER */}

        <ChatInput chatStatus={chatStatus} phone={phone} />
      </Flex>
      <Flex mt="8px">
        {" "}
        {user.userData?.enabled3cplus ? (
          <Tooltip
            label={
              ligarManualmenteDisabled
                ? "Entre em uma campanha para fazer chamadas"
                : undefined
            }
          >
            <Button
              leftIcon={<PhoneIcon width="16px" height="16px" />}
              size="sm"
              ml="8px"
              onClick={async () => {
                if (!ligarManualmenteDisabled) {
                  if (formValues.telefoneContatoNormalizado) {
                    setTransparentLoading(true);
                    try {
                      await makeManualCall({
                        phone: formValues.telefoneContatoNormalizado,
                        dispatch3cPlus,
                      });
                    } catch (e: any) {
                      const errorMessage = e?.response?.data?.detail;
                      Toast({
                        title: errorMessage ?? "Erro ao ligar manualmente",
                        status: "error",
                      });
                    } finally {
                      setTransparentLoading(false);
                    }
                  }
                }
              }}
              opacity={ligarManualmenteDisabled ? 0.5 : undefined}
              isLoading={transparentLoading}
              loadingText="Solicitando Ligação"
            >
              Ligar Manualmente
            </Button>
          </Tooltip>
        ) : null}
        {controls3cPlus.idCall != null ? (
          <Button
            ml="8px"
            size="sm"
            onClick={() => openModalTabulacao3cPlus()}
            animation={controls3cPlus.inCall ? "" : "pulseBlue 2s infinite"}
          >
            Tabulação 3C Plus
          </Button>
        ) : null}
      </Flex>
      <TelefoneContatoDialog
        isLoading={isUpdatingNumber}
        setLoading={setIsUpdatingNumber}
      />
    </Box>
  );
}

export default ChatWindow;
