import {
  CheckIcon,
  CloseIcon,
  DeleteIcon,
  Search2Icon,
  SearchIcon,
} from "@chakra-ui/icons";
import { Box, Button, Center, Flex, Grid, Text } from "@chakra-ui/react";
import apiDigitacao from "api/api-digitacao";
import { dropdownConvenioAtendimento } from "components/atendimentos-components/atendimento-form/fields-data";
import { dropdownBancosSimulacaoFGTS } from "components/atendimentos-components/pagina-atendimento/pagined-form/parts/simulacoes/utils";
import { DropdownField } from "components/dropdown-field";
import {
  CustomTableColor,
  CustomTableColorColumn,
} from "components/dynamic-table/color-table";
import {
  bgError,
  bgSucess,
  StatusFormat,
} from "components/dynamic-table/row-status";
import { GetLayout } from "components/get-layout";
import { InputField } from "components/input-field";
import { openModalConfirm } from "components/modal-confirm-new";
import {
  tipoOperacaoINSSArray,
  tipoOperacaoSIAPEArray,
} from "components/tabelas-digitacao-components/arrays";
import { Toast } from "components/toast";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useEffect, useState } from "react";
import { filterArrayBy } from "utils/filter-array-by";

interface Filter {
  convenio: string;
  tipoOperacao: string;
  banco: string;
}

interface Tabela {
  banco: string;
  codigo: string;
  convenio: string;
  descricao: string;
  enabledCustomer: boolean;
  enabledPromotora: boolean;
  id: number;
  prazo: null;
  tipoOperacao: null;
  availableBanco?: boolean;
}

export function TabelasDigitacaoPage() {
  let [filter, setFilter] = useState<Partial<Filter>>({});
  const [searchValue, setSearchValue] = useState("");
  const [tabelasList, setTabelasList] = useState<Tabela[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loadingRow, setLoadingRow] = useState<number>();
  const { user } = useApplicationContext();
  const filteredTabelasList = filterArrayBy(
    tabelasList,
    searchValue,
    ["descricao"],
    { partialSearch: true }
  );
  const isDisabled = !filter.convenio || !filter.banco;

  const onChangeFilter = (k: keyof Filter, value: any) => {
    filter = { ...filter, [k]: value };
    setFilter(filter);
    setTabelasList([]);
    setSearchValue("");
  };

  const getResults = async ({
    withOutLoading = false,
    currFilter = filter,
  } = {}) => {
    const params = {
      convenio: currFilter.convenio ?? "",
      banco: currFilter.banco ?? "",
    };

    if (!withOutLoading) setIsLoading(true);
    try {
      const { data } = await apiDigitacao.get(`/api/tabelas-digitacao`, {
        params,
      });
      setTabelasList(data);
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message;
      Toast({
        title: errorMessage ?? "Erro ao obter resultados",
        status: "error",
      });
    } finally {
      if (!withOutLoading) setIsLoading(false);
    }
  };

  const handleEnableOrDisable = async ({
    id,
    enabledCustomer,
    enabledPromotora,
    availableBanco,
  }: Tabela) => {
    openModalConfirm({
      onConfirm: async () => {
        const isRemove = enabledPromotora === false || availableBanco === false;
        setLoadingRow(id);
        try {
          const { data } = await apiDigitacao.patch(
            isRemove
              ? `/api/tabelas-digitacao/${id}/disable`
              : enabledCustomer
              ? `/api/tabelas-digitacao/${id}/disable`
              : `/api/tabelas-digitacao/${id}/enable`
          );
          await getResults({ withOutLoading: true });
        } catch (e) {
          Toast({
            title: `Erro ao ${
              isRemove
                ? "remover"
                : enabledCustomer
                ? "desabilitar"
                : "habilitar"
            }`,
            status: "error",
          });
        } finally {
          setLoadingRow(undefined);
        }
      },
    });
  };

  const columns: CustomTableColorColumn[] = [
    {
      name: "Código",
      render: (row: Tabela) => {
        return row.codigo;
      },
    },
    { name: "Descrição", render: (row: Tabela) => row.descricao },
    {
      name: "Convênio",
      render: (row: Tabela) =>
        dropdownConvenioAtendimento.find((crr) => crr.value === row.convenio)
          ?.name,
    },
    {
      name: "Banco",
      render: (row: Tabela) =>
        parseBancos.find(({ value }) => value === row.banco)?.name,
    },
    {
      name: "Status",
      headerStyles: { textAlign: "center" },
      render: (row: Tabela) => {
        const enabledPromotora = row.enabledPromotora;
        const availableBanco = row.availableBanco;
        let color = bgError;
        let status = "Desabilitado";

        if (!availableBanco) {
          status = "Indisponível Banco";
          color = bgError;
        } else if (!enabledPromotora) {
          status = "Desabilitado Promotora";
          color = bgError;
        } else if (row.enabledCustomer) {
          status = "Habilitado";
          color = bgSucess;
        }

        return (
          <Center>
            <StatusFormat minW="100px" bg={color}>
              {status}
            </StatusFormat>
          </Center>
        );
      },
    },
    {
      name: "Ações",
      headerStyles: { textAlign: "center" },
      render: (row: Tabela) => {
        const isRemove =
          row.enabledPromotora === false || row.availableBanco === false;
        let buttonDesc = "Habilitar";
        let icon = <CheckIcon />;

        if (isRemove) {
          buttonDesc = "Remover";
          icon = <DeleteIcon />;
        } else if (row.enabledCustomer) {
          buttonDesc = "Desabilitar";
          icon = <CloseIcon w="10px" h="10px" />;
        }

        return (
          <Center>
            <Button
              w="103px"
              leftIcon={icon}
              size="xs"
              variant="outline"
              onClick={() => handleEnableOrDisable(row)}
              isLoading={loadingRow === row.id}
              loadingText={"Carregando"}
            >
              {buttonDesc}
            </Button>
          </Center>
        );
      },
    },
  ];

  useEffect(() => {
    if (!isDisabled) getResults();
  }, [filter]);

  return (
    <GetLayout>
      <Flex justifyContent="space-between" mb="30px">
        <Text fontSize="25" fontWeight="bold">
          Tabelas Digitação
        </Text>
      </Flex>
      <Grid templateColumns="1fr 1fr 1fr 1fr" w="100%" gap="12px" mb="12px">
        <Box>
          <DropdownField
            title="Convênio"
            onChange={(value) => {
              onChangeFilter("convenio", value);
              onChangeFilter("banco", undefined);
            }}
            value={filter.convenio}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            options={conveniosList}
          />
        </Box>
        {/* <Box>
          <DropdownField
            title="Tipo Operação"
            onChange={(value) => {
              onChangeFilter("tipoOperacao", value);
              onChangeFilter("banco", undefined);
            }}
            value={filter.tipoOperacao}
            options={tipoOperacaoOptions}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            isDisabled={
              !tipoOperacaoOptions.length ||
              !filter.convenio ||
              filter.convenio === "ANTECIPACAO_FGTS"
            }
          />
        </Box> */}
        <Box>
          <DropdownField
            title="Banco"
            onChange={(value) => onChangeFilter("banco", value)}
            value={filter.banco}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            options={
              filter.convenio === "INSS"
                ? user.userData.customerId === 1
                  ? [...INSSBancosList, { name: "iCred", value: "ICRED" }]
                  : INSSBancosList
                : dropdownBancosSimulacaoFGTS
            }
            isDisabled={!filter.convenio}
          />
        </Box>
        <Box>
          <InputField
            title="Pesquisar tabelas"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
            value={searchValue}
            rightIcon={<SearchIcon />}
            inputProps={{
              bg: "#fff",
              _placeholder: { opacity: 0.8 },
            }}
            isDisabled={tabelasList.length === 0}
          />
        </Box>
        <Box>
          <Text mb="8px">&nbsp;</Text>
          <Button
            w="100%"
            leftIcon={<Search2Icon />}
            onClick={() => getResults()}
            isLoading={isLoading}
            isDisabled={isDisabled}
            loadingText="Carregando Tabelas"
          >
            Pesquisar
          </Button>
        </Box>
      </Grid>

      <CustomTableColor rows={filteredTabelasList} columns={columns} />
    </GetLayout>
  );
}

const INSSBancosList = [
  { name: "Facta", value: "FACTA" },
  { name: "PAN", value: "PAN" },
  { name: "C6", value: "C6" },
  { name: "Bmg", value: "BMG" },
  // { name: "iCred", value: "ICRED" },
];

const tipoOperacaoAntecipacaoFGTSArray = [
  { name: "Antecipação FGTS", value: "ANTECIPACAO_FGTS" },
];
const conveniosList = [
  { name: "INSS", value: "INSS" },
  { name: "Antecipação FGTS", value: "ANTECIPACAO_FGTS" },
];

const parseBancos = [
  { name: "Facta", value: "FACTA" },
  { name: "PAN", value: "PAN" },
  { name: "Safra", value: "SAFRA" },
  { name: "Master", value: "MASTER" },
  { name: "Itaú", value: "ITAU" },
  { name: "Bmg", value: "BMG" },
  { name: "Novo Saque", value: "NOVO_SAQUE" },
  { name: "Lotus", value: "LOTUS_TAYA" },
  { name: "UY3", value: "UY3" },
  { name: "VCTeX", value: "VCTEX" },
  { name: "Tá Quitado", value: "TA_QUITADO" },
  { name: "V8", value: "V8" },
  { name: "iCred", value: "ICRED" },
];
