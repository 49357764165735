import { Box, Center, Flex, Grid, Text } from "@chakra-ui/react";
import { bancosContratos } from "components/atendimentos-components/atendimento-form/contracts-table/consts";
import {
  ConsultaProps,
  EmprestimoBancario,
} from "components/consulta-components/consulta-fields/types";
import CustomTable from "components/custom-table-pagined";
import { CustomTableColor } from "components/dynamic-table/color-table";
import { BankIcon, BankIcon2 } from "components/vectors/bank-icon";
import { BirthIcon } from "components/vectors/birth-icon";
import { CreditCardIcon2 } from "components/vectors/credit-card-icon";
import { DolarBag } from "components/vectors/dolar-bag";
import { DolarIcon3 } from "components/vectors/dolar-icon";
import { HandMoney } from "components/vectors/hand-money";
import { PersonIcon } from "components/vectors/menu-icons/person-icon";
import { PaperAssignIcon } from "components/vectors/paper-assign";
import { ShieldIcon } from "components/vectors/shield-icon";
import { calcBirthday, maskCPF } from "utils/string-formats";

export function ConsultationData({
  isLoading,
  consultationData,
}: {
  isLoading: boolean;
  consultationData: ConsultaProps | undefined;
}) {
  console.log(consultationData);
  const isNaoPertube = Number(consultationData?.beneficio.NaoPerturbe);
  const fistName = consultationData?.beneficio.nome
    ?.split(" ")[0]
    ?.toLocaleLowerCase();
  const rawCpf = consultationData?.beneficio.cpf;
  const cpf =
    rawCpf != null
      ? Array.from({ length: 11 - rawCpf.length })
          .map(() => "0")
          .join("") + rawCpf
      : "--";
  const isCartaoBeneficio = !!consultationData?.beneficio.contratosRcc?.length;
  // const somaContratosRcc = consultationData?.beneficio.contratosRcc.map((cartao) => cartao)
  console.log(consultationData?.beneficio.contratosRcc);

  return (
    <Flex flexDir="column" w="100%" alignItems="center">
      <Grid templateColumns="150px auto" w="100%" gap="24px">
        <Box>
          <Center
            boxShadow="0 0 1em rgba(0,0,0,0.05)"
            textAlign="center"
            p="20px 10px"
            borderRadius="5px"
          >
            <Text
              fontSize="20px"
              fontWeight="semibold"
              textTransform="capitalize"
            >
              {consultationData?.beneficio.nome}
            </Text>
          </Center>
          {isNaoPertube ? (
            <Box bg="#B21F170D" p="10px" borderRadius="5px" mt="20px">
              <Text color="#B21F17">
                CPF localizado na base de Dados{" "}
                <Text as="span" fontWeight="semibold">
                  Não Perturbe
                </Text>
                . De acordo com a Lei Nº 17334 DE 09/03/2021 não é permitido o
                contato com os telefones cadastrados no não perturbe.
              </Text>
            </Box>
          ) : null}
        </Box>
        <Box>
          <Text
            textTransform="capitalize"
            fontSize="18px"
            fontWeight="semibold"
            mb="20px"
          >
            Sobre {fistName}
          </Text>

          <Flex alignItems="center" mb="10px">
            <Center mr="10px">
              <PersonIcon width="14px" height="14px" />
            </Center>
            <Text fontWeight="medium">
              Número benefício: {consultationData?.beneficio.beneficio}
            </Text>
          </Flex>

          <Flex alignItems="center" mb="10px">
            <Center mr="10px">
              <PersonIcon width="14px" height="14px" />
            </Center>
            <Text fontWeight="medium">CPF: {maskCPF(cpf)}</Text>
          </Flex>

          <Flex alignItems="center" mb="10px">
            <Center mr="10px">
              <BirthIcon width="14px" height="14px" />
            </Center>
            <Text fontWeight="medium">
              Idade:{" "}
              {consultationData?.beneficio.dataNascimento
                ? calcBirthday(consultationData?.beneficio.dataNascimento)
                : "--"}
            </Text>
          </Flex>

          <Flex alignItems="center" mb="10px">
            <Center mr="10px">
              <HandMoney width="14px" height="14px" />
            </Center>
            <Text fontWeight="medium">
              Valor benefício: R$ {consultationData?.beneficio.valorBeneficio}
            </Text>
          </Flex>

          <Flex alignItems="center" mb="10px">
            <Center mr="10px">
              <CreditCardIcon2 width="14px" height="14px" />
            </Center>
            <Text fontWeight="medium">
              Cartão benefício (RCC): {isCartaoBeneficio ? "SIM" : "NÃO"}
            </Text>
          </Flex>

          <Flex alignItems="center" mb="10px">
            <Center mr="10px">
              <DolarIcon3 width="14px" height="14px" />
            </Center>
            <Text fontWeight="medium">
              Espécie: {consultationData?.beneficio.especie.codigo}
            </Text>
          </Flex>

          <Box w="100%" borderTop="1px solid #E8EAED" my="16px" />
          {consultationData?.beneficio?.contratosCartao?.length ? (
            <>
              <Text
                textTransform="capitalize"
                fontSize="18px"
                fontWeight="semibold"
                mb="20px"
              >
                Margem cartão RMC
              </Text>

              <Flex alignItems="center" mb="10px">
                <Center mr="10px">
                  <BankIcon2 width="14px" height="14px" />
                </Center>
                <Text fontWeight="medium">
                  Banco:{" "}
                  {
                    bancosContratos[
                      `${consultationData?.beneficio.contratosCartao[0].banco.codigo}`
                    ]
                  }
                </Text>
              </Flex>
              <Flex alignItems="center" mb="10px">
                <Center mr="10px">
                  <BankIcon width="14px" height="14px" />
                </Center>
                <Text fontWeight="medium">
                  Idade:{" "}
                  {consultationData?.beneficio.dataNascimento
                    ? calcBirthday(consultationData?.beneficio.dataNascimento)
                    : "--"}
                </Text>
              </Flex>
              <Flex alignItems="center" mb="10px">
                <Center mr="10px">
                  <CreditCardIcon2 />
                </Center>
                <Text fontWeight="medium">
                  Limite Cartão: R${" "}
                  {consultationData?.beneficio.contratosCartao[0].limiteCartao
                    .toFixed(2)
                    .replace(".", ",")}
                </Text>
              </Flex>
              <Flex alignItems="center" mb="10px">
                <Center mr="10px">
                  <PaperAssignIcon />
                </Center>
                <Text fontWeight="medium">
                  Início Contrato:{" "}
                  {consultationData?.beneficio.contratosCartao[0].dataInicioContrato.replace(
                    /-/g,
                    "/"
                  )}
                </Text>
              </Flex>
              <Flex alignItems="center" mb="10px">
                <Center mr="10px">
                  <DolarBag />
                </Center>
                <Text fontWeight="medium">
                  Valor reservado: R${" "}
                  {consultationData?.beneficio.contratosCartao[0].valorReservado
                    .toFixed(2)
                    .replace(".", ",")}
                </Text>
              </Flex>
              <Flex alignItems="center" mb="10px">
                <Center mr="10px">
                  <ShieldIcon />
                </Center>
                <Text fontWeight="medium">
                  Situação:{" "}
                  {consultationData?.beneficio.contratosCartao[0].situacao}
                </Text>
              </Flex>
            </>
          ) : null}
          <Box w="100%" borderTop="1px solid #E8EAED" my="16px" />
          {consultationData?.beneficio.contratosEmprestimo?.length ? (
            <>
              <Text
                textTransform="capitalize"
                fontSize="18px"
                fontWeight="semibold"
                mb="20px"
              >
                Empréstimo Bancário
              </Text>

              <CustomTableColor
                columns={[
                  {
                    name: "Banco",
                    render: (row: EmprestimoBancario) => (
                      <>{bancosContratos[`${row.banco.codigo}`]}</>
                    ),
                  },
                  {
                    name: "Contrato",
                    render: (row: EmprestimoBancario) => <></>,
                  },
                  { name: "", render: (row: EmprestimoBancario) => <></> },
                  { name: "", render: (row: EmprestimoBancario) => <></> },
                ]}
                rows={consultationData.beneficio.contratosEmprestimo}
              />
            </>
          ) : null}
        </Box>
      </Grid>
    </Flex>
  );
}
