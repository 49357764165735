import { CustomModal } from "components/custom-modal";
import { ConsultaProps } from "../consulta-fields/types";
import { CSSProperties, MouseEvent, ReactNode, useState } from "react";
import { makeEvent, useEventListener } from "services/events";
import { BoxProps, Button, HStack, Input, TextProps } from "@chakra-ui/react";
import { CloseIcon } from "@chakra-ui/icons";
import { MdLocalPrintshop } from "react-icons/md";
import { FaDownload } from "react-icons/fa";
import {
  completeCPF,
  getContratosCartaoTableColumns,
  getDemonstrativosEmprestimosColumns,
  StylesPDF,
} from "./utils";
import { currencyMask } from "utils/string-formats";
import CustomTable from "components/custom-table-pagined";
import DynamicTable from "components/dynamic-table";
import { CustomTablePDF } from "components/pdf-table";
import { printDiv } from "utils/print-div";

export function ConsultaPDFModal({ consult }: { consult: ConsultaProps }) {
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onPrint = (e: MouseEvent<HTMLButtonElement>) => {
    // window.print();
    printDiv("print-container");
  };

  useEventListener("openConsultaPDFModal", onOpen);

  const modalFooter = (
    <>
      <Button onClick={onPrint} leftIcon={<MdLocalPrintshop size="20px" />}>
        Imprimir
      </Button>

      <Button
        onClick={onClose}
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
      >
        Fechar
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={<Text className="print-display-none">PDF da Consulta</Text>}
      size="5xl"
      modalFooter={modalFooter}
      // containerProps={{
      //   alignItems: "center",
      //   justifyContent: "flex-start",
      //   flexDirection: "column",
      // }}

      scroll="inside"
      modalFooterProps={{ className: "print-display-none" }}
      modalCloseButtonProps={{ className: "print-display-none" }}
      modalHeaderProps={{ className: "print-display-none" }}
      modalBodyProps={{ className: "padding-none" }}
      sx={{
        "@media print": {
          ".print-display-none": { display: "none" },
          ".padding-none": { padding: "0px 0px 0px 0px!important" },
        },
      }}
    >
      <Box id="print-container">
        <StylesPDF />
        <Center width="100%">
          <Text fontWeight="600" fontSize="16px">
            Demonstrativo Offline de Empréstimos Consignados
          </Text>
        </Center>
        <Card title="Idêntificação do Afiliado" margin={"25px 0px 15px"}>
          <Flex width="100%" justifyContent="space-evenly">
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                Nome do beneficiário:
              </Text>
              <Text textAlign="center">{consult?.beneficio.nome}</Text>
            </Center>
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                Benefício:
              </Text>
              <Text textAlign="center">{consult?.beneficio.beneficio}</Text>
            </Center>
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                CPF:
              </Text>
              <Text textAlign="center">
                {completeCPF(consult?.beneficio.cpf)}
              </Text>
            </Center>
          </Flex>
        </Card>
        <Card title="Dados do Benefício" marginBottom="15px">
          <Flex width="100%" justifyContent="space-evenly">
            <Flex alignItems="center">
              <Text textAlign="center" fontWeight="600">
                Espécie:&nbsp;
              </Text>
              {`${consult?.beneficio?.especie?.codigo} - ${consult?.beneficio?.especie?.descricao}`}
            </Flex>
            <Flex alignItems="center">
              <Text textAlign="center" fontWeight="600">
                Situação:&nbsp;
              </Text>
              {consult?.beneficio?.situacaoBeneficio}
            </Flex>
          </Flex>
          <Box
            borderBottom="1px solid var(--chakra-colors-gray-200)"
            margin="10px 0px"
          />

          <Flex width="100%" justifyContent="space-evenly">
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                É pensão Alimentícia:
              </Text>
              <Text textAlign="center">
                {consult?.beneficio?.pensaoAlimenticia != null
                  ? consult?.beneficio?.pensaoAlimenticia
                    ? "SIM"
                    : "NÃO"
                  : ""}
              </Text>
            </Center>
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                Possui representante legal:
              </Text>
              <Text textAlign="center">
                {consult?.beneficio?.possuiRepresentanteLegalProcurador != null
                  ? consult?.beneficio?.possuiRepresentanteLegalProcurador
                    ? "SIM"
                    : "NÃO"
                  : ""}
              </Text>
            </Center>
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                Bloqueado para empréstimo:
              </Text>
              <Text textAlign="center">
                {consult?.beneficio?.bloqueioEmprestismo != null
                  ? consult?.beneficio.bloqueioEmprestismo
                    ? "SIM"
                    : "NÃO"
                  : ""}
              </Text>
            </Center>
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                Elegível para empréstimo:
              </Text>
              <Text textAlign="center">
                {consult?.beneficio.beneficioPermiteEmprestimo != null
                  ? consult?.beneficio.beneficioPermiteEmprestimo
                    ? "SIM"
                    : "NÃO"
                  : ""}
              </Text>
            </Center>
          </Flex>
        </Card>
        <Card title="Margem Consignável" marginBottom="15px">
          <Flex width="100%" justifyContent="space-evenly">
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                Base de cálculo:
              </Text>
              <Text textAlign="center">
                {typeof consult?.beneficio?.margem
                  ?.baseCalculoMargemConsignavel === "number"
                  ? currencyMask(
                      consult?.beneficio.margem.baseCalculoMargemConsignavel.toFixed(
                        2
                      )
                    )
                  : ""}
              </Text>
            </Center>
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                Margem para empréstimo:
              </Text>
              <Text textAlign="center">
                {typeof consult?.beneficio.margem.margemDisponivelEmprestimo ===
                "number"
                  ? `R$ ${currencyMask(
                      consult?.beneficio.margem.margemDisponivelEmprestimo.toFixed(
                        2
                      )
                    )}`
                  : ""}
              </Text>
            </Center>
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                Margem RMC:
              </Text>
              <Text textAlign="center">
                {typeof consult?.beneficio.margem.margemDisponivelCartao ===
                "number"
                  ? `R$ ${currencyMask(
                      consult?.beneficio.margem.margemDisponivelCartao.toFixed(
                        2
                      )
                    )}`
                  : ""}
              </Text>
            </Center>
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                Margem RCC:
              </Text>
              <Text textAlign="center">
                {typeof consult?.beneficio.margem.margemDisponivelRcc ===
                "number"
                  ? `R$ ${currencyMask(
                      consult?.beneficio.margem.margemDisponivelRcc.toFixed(2)
                    )}`
                  : ""}
              </Text>
            </Center>
          </Flex>
        </Card>
        <Card title="Instituição Pagadora" marginBottom="15px">
          <Flex width="100%" justifyContent="space-evenly">
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                CBC/Banco:
              </Text>
              <Text textAlign="center">
                {`${consult?.beneficio.dadosBancarios.banco.codigo} -${consult?.beneficio.dadosBancarios.banco.nome}`}
              </Text>
            </Center>
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                Tipo:
              </Text>
              <Text textAlign="center">
                {consult?.beneficio.dadosBancarios.meioPagamento.tipo}
              </Text>
            </Center>
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                Ag:
              </Text>
              <Text textAlign="center">
                {consult?.beneficio.dadosBancarios.agencia.codigo}
              </Text>
            </Center>
            <Center flexDirection="column">
              <Text textAlign="center" marginBottom="8px" fontWeight="600">
                C/C:
              </Text>
              <Text textAlign="center">
                {Number(
                  consult.beneficio.dadosBancarios.meioPagamento.numero
                ) || ""}
                &nbsp;
              </Text>
            </Center>
          </Flex>
        </Card>
        <Card title="Contratos de Empréstimos" marginBottom="15px">
          <CustomTablePDF
            rows={consult?.beneficio.contratosEmprestimo || []}
            columns={getDemonstrativosEmprestimosColumns()}
            size="sm"
          />
        </Card>
        <Card title="Contratos de Cartão RMC" marginBottom="15px">
          <CustomTablePDF
            rows={consult?.beneficio.contratosCartao || []}
            columns={getContratosCartaoTableColumns()}
            size="sm"
          />
        </Card>
        <Card title="Contratos de Cartão RCC">
          <CustomTablePDF
            rows={consult?.beneficio.contratosRcc || []}
            columns={getContratosCartaoTableColumns()}
            size="sm"
          />
        </Card>
      </Box>
    </CustomModal>
  );
}

export const openConsultaPDFModal = () => makeEvent("openConsultaPDFModal");

function Card({
  title,
  children,

  ...rest
}: {
  title: string;
  children?: ReactNode;
} & CSSProperties) {
  return (
    <Flex
      border="1px solid var(--chakra-colors-gray-200)"
      borderRadius="6px"
      padding="10px"
      flexDirection="column"
      {...rest}
    >
      <Center width="100%" height="21px">
        <Text
          textAlign="center"
          fontWeight="600"
          background="#fff"
          padding="0 5px"
        >
          {title}
        </Text>
      </Center>
      <Box width="100%" padding="10px 0px">
        {children}
      </Box>
    </Flex>
  );
}

function Center({ children, ...rest }: { children?: any } & CSSProperties) {
  return (
    <div
      style={{
        justifyContent: "center",
        display: "flex",
        alignItems: "center",
        ...rest,
      }}
    >
      {children}
    </div>
  );
}

function Flex({ children, ...rest }: { children?: any } & CSSProperties) {
  return <div style={{ display: "flex", ...rest }}>{children}</div>;
}

function Text({
  children,
  className,
  ...rest
}: { children?: any; className?: string } & CSSProperties) {
  return (
    <p className={className} style={{ ...rest }}>
      {children}
    </p>
  );
}

function Box({
  children,
  id,
  className,
  ...rest
}: { children?: any; id?: string; className?: string } & CSSProperties) {
  return (
    <div id={id} className={className} style={{ ...rest }}>
      {children}
    </div>
  );
}
