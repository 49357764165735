import {
  AttendanceActionsTypes,
  dispatchAttendance,
  FormControl,
} from "contexts/attendance-context/actions-reducer";
import React, {
  createContext,
  ReactNode,
  useContext,
  useReducer,
  useState,
} from "react";

interface FormControlContext {
  formControl: FormControl;
  dispatch: React.Dispatch<AttendanceActionsTypes>;
  setConsultandoFGTS: React.Dispatch<React.SetStateAction<boolean>>;
  consultandoFGTS: boolean;
  setReConsultandoFGTS: React.Dispatch<React.SetStateAction<boolean>>;
  reConsultandoFGTS: boolean;
  setTransparentLoading: React.Dispatch<React.SetStateAction<boolean>>;
  transparentLoading: boolean;
}

export const INITIAL_ATTENDANCE_VALUES: FormControl = {
  values: {},
  initialValues: {},
  pendingChanges: false,
  invalidFields: [],
  isExtratoCopiado: false,
};

const attendanceContext = createContext<FormControlContext>({
  formControl: INITIAL_ATTENDANCE_VALUES,
  dispatch: () => undefined,
  setConsultandoFGTS: () => undefined,
  consultandoFGTS: false,
  setReConsultandoFGTS: () => undefined,
  reConsultandoFGTS: false,
  setTransparentLoading: () => undefined,
  transparentLoading: false,
});

export const AttendanceProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [formControl, dispatch] = useReducer(
    dispatchAttendance,
    INITIAL_ATTENDANCE_VALUES
  );
  const [consultandoFGTS, setConsultandoFGTS] = useState(false);
  const [reConsultandoFGTS, setReConsultandoFGTS] = useState(false);
  const [transparentLoading, setTransparentLoading] = useState(false);

  return (
    <attendanceContext.Provider
      value={{
        dispatch,
        formControl,
        setConsultandoFGTS,
        consultandoFGTS,
        setReConsultandoFGTS,
        reConsultandoFGTS,
        setTransparentLoading,
        transparentLoading,
      }}
    >
      {children}
    </attendanceContext.Provider>
  );
};

export const useAttendanceContext = () => {
  const context = useContext(attendanceContext);
  if (context === undefined)
    throw new Error(
      "useApplicationContext must be used within a ApplicationContextProvider"
    );
  return context;
};
