import { Grid } from "@chakra-ui/react";
import { DragDropContext } from "react-beautiful-dnd";
import AttendanceKanbanColumn from "./parts/column";
import { useAttendanceKanbanContext } from "./kanban-context";

export const kanbanColumnWidth = "320px";

export function Kanban() {
  const { handleDrop, kanbanColumns } = useAttendanceKanbanContext();

  return (
    <DragDropContext onDragEnd={handleDrop}>
      <Grid
        w={`100%`}
        overflow="auto"
        templateColumns={`repeat(${kanbanColumns.length}, ${kanbanColumnWidth})`}
        gap="20px"
        pl="30px"
      >
        {kanbanColumns.map(
          ({ colId, attendancesList, colName, title, color }) => {
            return (
              <AttendanceKanbanColumn
                key={colId}
                attendancesList={attendancesList}
                colId={colId}
                colName={colName}
                title={title}
                color={color}
              />
            );
          }
        )}
      </Grid>
    </DragDropContext>
  );
}
