import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { LogoutIcon } from "components/vectors/logout-icon";
import { UserIcon } from "components/vectors/user-icon";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useRouter } from "routes/router-control/use-router";

export function ProfileMenu() {
  const { user, setUserAction, menuErrorMessage } = useApplicationContext();
  const { userData } = user;
  const router = useRouter();

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        bg={userData.image ? undefined : "bg-gray"}
        bgImage={userData.image}
        borderRadius="full"
        w="45px"
        h="45px"
        isDisabled={!!menuErrorMessage}
      >
        {userData.image ? null : <UserIcon stroke="custom-gray" />}
      </MenuButton>
      <MenuList py="0" overflow="hidden" zIndex={100}>
        <MenuItem
          icon={<LogoutIcon width="15px" height="15px" />}
          _focus={{}}
          onClick={() => {
            setUserAction({ type: "logout" });
            router.push("/login");
          }}
          _hover={{ bg: "gray.100" }}
        >
          Sair
        </MenuItem>
      </MenuList>
    </Menu>
  );
}
