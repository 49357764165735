import "components/global-styles/index.css";
import { ApplicationContextProvider } from "contexts/ApplicationContext";
import "styles/imports.scss";
import "components/slider/style.css";
import { getFavIcon, getTitle } from "components/white-label";

import { NotFound } from "pages/not-found";
import { allRouters } from "routes/routes-array";
import { useEffect } from "react";
import { RouterSelect } from "routes/router-control/router-select";

const App = () => {
  document.title = getTitle();

  useEffect(() => {
    getFavIcon();
  }, []);

  return (
    <ApplicationContextProvider>
      <RouterSelect
        allRouters={allRouters}
        notFoundPage={NotFound}
        redirectIfNotLogged="/login"
      />
    </ApplicationContextProvider>
  );
};

export default App;
