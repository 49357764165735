import { Box, Text } from "@chakra-ui/react";
import { ConsultaProps } from "components/consulta-components/consulta-fields/types";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import { Dispatch, SetStateAction, useState } from "react";
import { useEventListener, makeEvent } from "services/events";

interface ModalData {
  consultList: ConsultaProps[];
}

export function ModalSelectBenefit({
  consulta,
  setConsulta,
}: {
  consulta: ConsultaProps | undefined;
  setConsulta: Dispatch<SetStateAction<ConsultaProps | undefined>>;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<Partial<ModalData>>({});

  const onOpen = (props: ModalData) => {
    setIsOpen(true);
    setModalData(props);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  useEventListener("openModalSelectBenefit", onOpen);

  return (
    <CustomModal
      modalTitle="Selecione o benefício"
      isOpen={isOpen}
      //   onClose={() => {}}
    >
      <Text mb="8px">Selecione o benefício que você deseja visualizar.</Text>
      <Dropdown
        w="100%"
        options={
          modalData.consultList?.map((consulta) => ({
            name: `${consulta.beneficio.beneficio} - Esp: ${consulta.beneficio.especie.codigo}`,
            value: consulta.beneficio.especie.codigo,
          })) || []
        }
        onChange={(value) => {
          const selected = modalData.consultList?.find(
            (crr) => crr.beneficio.especie.codigo === value
          );
          onClose();
          setConsulta(selected);
        }}
        value={consulta}
      />
      <Box mb="16px" />
    </CustomModal>
  );
}

export const openModalSelectBenefit = (props: ModalData) =>
  makeEvent("openModalSelectBenefit", props);
