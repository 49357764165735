import { Box, Button } from "@chakra-ui/react";
import api from "api/api";
import { CustomModal } from "components/custom-modal";
import { DropdownField } from "components/dropdown-field";
import { CleanIcon } from "components/vectors/clean-icon";
import { useAttendanceContext } from "contexts/attendance-context";
import { useEffect, useState } from "react";
import { makeEvent, useEventListener } from "services/events";
import { validateCronowiseHigienizacao } from "./utils";
import { CloseIcon } from "@chakra-ui/icons";
import { Toast } from "components/toast";

export interface CronowiseHigienizacaoProps {
  idBanco?: number;
  convenio?: number;
  idSubConvenio?: number;
}

interface OptionsProps {
  nomeConvenio: string;
  botId: number;
  dadosConvenioDTOList: {
    id: number;
    name: string;
    idSubconvenio: number;
    bancos: { id: number; name: string; idBanco: number }[];
  }[];
}

export function ModalCronowise({
  onUpdate,
}: {
  onUpdate: () => Promise<void>;
}) {
  const [isLoading, setIsLoading] = useState(false);
  let [modalData, setModalData] = useState<CronowiseHigienizacaoProps>({});

  const [isOpen, setIsOpen] = useState(false);
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const [options, setOptions] = useState<OptionsProps[] | null>(null);
  const { formControl, dispatch } = useAttendanceContext();

  const selectedConvenio = options?.find(
    (convenio) => convenio.botId === modalData.convenio
  );
  const subconvenios = selectedConvenio?.dadosConvenioDTOList;

  const selectedSubConvenio = subconvenios?.find(
    (subC) => subC.idSubconvenio === modalData.idSubConvenio
  );

  const onOpen = () => {
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
    setErrors({});
    setModalData({});
    setIsLoading(false);
  };

  const getOptions = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.get(`/pipeline/cronowise-convenios`);
      setOptions(data);
      console.log(data);
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message;
      Toast({
        title: "Erro ao obter dados",
        description: errorMessage ?? undefined,
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getOptions();
  }, []);

  const onChange = (key: keyof CronowiseHigienizacaoProps, value: any) => {
    const newValue = { ...modalData, [key]: value };
    modalData = newValue;
    setModalData(newValue);
    setErrors((prev) => {
      delete prev[key];
      return prev;
    });
  };

  const handleHigienizar = async () => {
    const { isValid, errors, body } = validateCronowiseHigienizacao(modalData);
    if (!isValid) return setErrors(errors);

    setIsLoading(true);
    try {
      const { data } = await api.post(
        `/atendimento-margem-convenio/${formControl.values.id}`,
        "",
        { params: body }
      );
      Toast({ title: "Higienização iniciada", status: "success" });
      onClose();
      onUpdate();
    } catch (e: any) {
      Toast({
        title: "Erro ao iniciar higienização",
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEventListener("openModalCronowise", onOpen);

  const modalFooter = (
    <>
      <Button
        onClick={() => handleHigienizar()}
        leftIcon={<CleanIcon width="16px" height="16px" />}
      >
        Higienizar
      </Button>
      <Button
        variant="outline"
        onClick={onClose}
        leftIcon={<CloseIcon w="12px" h="12px" />}
      >
        Fechar
      </Button>
    </>
  );

  return (
    <CustomModal
      modalTitle="Higienizar Margem Convênio"
      modalFooter={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
    >
      <Box>
        <DropdownField
          title="Convênios"
          dropdownProps={{
            w: "100%",
            onChange: (value) => {
              console.log(value);
              if (value === 5 && !formControl.values.nb) {
                Toast({ title: "Insira o número benefício no atendimento" });
                dispatch({
                  type: "setInvalidField",
                  payload: { fieldName: "nb", action: "append" },
                });
              } else {
                onChange("convenio", value);
                onChange("idSubConvenio", undefined);
                onChange("idBanco", undefined);
              }
            },
            loadingText: "Selecione",
            spinnerPlacement: "end",
            isLoading: isLoading,
          }}
          options={options?.map((c) => ({
            name: c.nomeConvenio,
            value: c.botId,
          }))}
          value={modalData.convenio}
          errorMessage={errors["convenio"]}
        />
      </Box>
      {subconvenios ? (
        <Box my="10px">
          <DropdownField
            title="Subconvênio"
            dropdownProps={{
              w: "100%",
              onChange: (value) => {
                onChange("idSubConvenio", value);
                onChange("idBanco", undefined);
              },
              loadingText: "Selecione",
              spinnerPlacement: "end",
              isLoading: isLoading,
            }}
            options={subconvenios?.map((subC) => ({
              name: subC.name,
              value: subC.idSubconvenio,
            }))}
            value={modalData.idSubConvenio}
            errorMessage={errors["idSubConvenio"]}
          />
        </Box>
      ) : null}
      {selectedSubConvenio &&
      modalData.convenio !== 4 &&
      modalData.convenio !== 5 ? (
        <Box>
          <DropdownField
            title="Banco"
            dropdownProps={{
              w: "100%",
              onChange: (value) => {
                onChange("idBanco", value);
              },
              loadingText: "Selecione",
              spinnerPlacement: "end",
              isLoading: isLoading,
            }}
            options={selectedSubConvenio.bancos?.map((banco) => ({
              name: banco.name,
              value: banco.idBanco,
            }))}
            value={modalData.idBanco}
            errorMessage={errors["idBanco"]}
          />
        </Box>
      ) : null}
    </CustomModal>
  );
}

export const openModalCronowise = () => makeEvent("openModalCronowise");
