import { Icon, IconProps } from "@chakra-ui/react";

export function WepayLogo({ ...rest }: IconProps) {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 1572 397"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M993.2 395.8C992.3 394.9 992 384.7 992 356C992 327.3 992.3 317.1 993.2 316.2C993.9 315.5 995.8 315 997.5 315C1001.2 315 1003 316.6 1003 319.8V322.2L1006.1 319.6C1011.3 315.2 1015.8 313.7 1024.5 313.7C1030.8 313.7 1033.5 314.2 1037.2 315.9C1059.8 326.7 1060.9 361.1 1039.1 373.4C1034.1 376.1 1032.8 376.4 1025.1 376.4C1015.8 376.3 1011.5 375 1006.1 370.4L1003 367.8V381.2C1003 395.9 1002.6 397 997.5 397C995.8 397 993.9 396.5 993.2 395.8ZM1033.8 364.6C1047.1 357.7 1047 332.2 1033.6 325.3C1028.6 322.7 1018.4 323.1 1013.2 326C1005.3 330.5 1001.3 341.3 1003.9 351.6C1005.9 359.4 1009.4 362.9 1019 366.4C1021.6 367.4 1030.6 366.2 1033.8 364.6Z"
        fill="#2CCD67"
      />
      <path
        d="M1127 376.1C1118 373.2 1110.5 366.6 1106.8 358.2C1103.8 351.6 1103.7 338.3 1106.7 331.9C1109.7 325.3 1115.3 319.5 1121.8 316.3C1126.4 314 1128.6 313.6 1135.5 313.6C1142.4 313.6 1144.6 314 1149.2 316.3C1155.7 319.5 1161.3 325.3 1164.3 331.9C1167.3 338.4 1167.2 351.5 1164.2 358.2C1161.2 364.9 1154.9 371.4 1148.7 374C1143.1 376.5 1131.4 377.6 1127 376.1ZM1145 364C1156.7 358 1159.7 340.6 1150.8 330.4C1138 315.8 1115 325.2 1115 345C1115 352.4 1119.3 360.4 1125.4 364.1C1129.7 366.7 1139.7 366.7 1145 364Z"
        fill="#2CCD67"
      />
      <path
        d="M1297.9 375.7C1289.1 372.4 1282.4 366.3 1278.7 358.1C1275.7 351.5 1275.7 338.2 1278.6 331.9C1281.7 325.3 1287.3 319.5 1293.8 316.3C1298.4 314 1300.6 313.6 1307.5 313.6C1314.4 313.6 1316.6 314 1321.2 316.3C1327.7 319.5 1333.3 325.3 1336.3 331.9C1339.3 338.4 1339.3 351.6 1336.3 358.2C1333.3 364.8 1326.9 371.4 1320.7 374C1314.3 376.8 1303.1 377.6 1297.9 375.7ZM1317.7 363.7C1323.6 360.6 1328 352.6 1328 345.1C1328 326.3 1307.5 316.4 1294 328.6C1290 332.3 1287 339 1287 344.4C1287 351.9 1291.1 360.1 1296.6 363.7C1301.8 367 1311.3 367.1 1317.7 363.7Z"
        fill="#2CCD67"
      />
      <path
        d="M1409.9 375.7C1401.1 372.4 1394.4 366.3 1390.7 358.1C1387.7 351.5 1387.7 338.2 1390.6 331.9C1393.7 325.3 1399.3 319.5 1405.7 316.4C1410.2 314.2 1412.5 313.7 1419.4 313.7C1426.4 313.6 1428.5 314 1433.2 316.3C1439.7 319.5 1445.3 325.3 1448.3 331.9C1451.3 338.4 1451.3 351.6 1448.3 358C1443.1 369.4 1433.2 376 1420.7 376.7C1416.2 376.9 1412.1 376.5 1409.9 375.7ZM1429.7 363.7C1435.6 360.6 1440 352.6 1440 345.1C1440 326.3 1419.5 316.4 1406 328.6C1402 332.3 1399 339 1399 344.4C1399 351.9 1403.1 360.1 1408.6 363.7C1413.8 367 1423.3 367.1 1429.7 363.7Z"
        fill="#2CCD67"
      />
      <path
        d="M1522.7 375.9C1514.2 373.9 1507.9 368.6 1503.7 360.1C1500.9 354.3 1500.5 352.6 1500.5 345C1500.5 337.4 1500.8 335.7 1503.8 329.8C1509.4 318.4 1517.4 313.6 1530.6 313.7C1537.2 313.8 1539.6 314.3 1543.6 316.3C1546.4 317.7 1549.3 319.6 1550.1 320.6C1551.4 322.2 1551.5 322 1552 318.9C1552.5 315.8 1552.8 315.5 1556.3 315.2C1563.1 314.6 1563 314.1 1563 344.5C1563 359.6 1562.7 372.5 1562.4 373.4C1561.7 375.3 1555 375.6 1553.2 373.8C1552.5 373.1 1552 371.5 1552 370.2V367.8L1548.9 370.4C1547.2 371.9 1544.3 373.7 1542.5 374.4C1538 376.3 1527.4 377.1 1522.7 375.9ZM1537.4 365.9C1542.7 364.4 1546.3 361.6 1549.1 356.7C1551.1 353.3 1551.5 351.1 1551.5 344.9C1551.5 338.2 1551.1 336.8 1548.5 332.5C1544.5 326 1539.7 323.6 1530.8 323.6C1524.9 323.6 1523.5 324 1519.9 326.5C1514.9 330.1 1512.2 334.9 1511.3 341.8C1509.3 358.8 1521.7 370.3 1537.4 365.9Z"
        fill="#2CCD67"
      />
      <path
        d="M1069.2 373.8C1068.3 372.9 1068 365.1 1068 345C1068 324.9 1068.3 317.1 1069.2 316.2C1069.9 315.5 1071.8 315 1073.5 315C1077.4 315 1079 316.6 1079 320.4V323.4L1082.8 319.8C1087.3 315.5 1089.3 314.4 1094.8 313.5L1099 312.8V318.8V324.7L1093.8 325.3C1087.5 326.1 1084.2 328.3 1081.5 333.6C1079.8 336.9 1079.5 339.8 1079.2 354C1078.9 375.2 1078.9 375 1074.2 375C1072.1 375 1069.9 374.5 1069.2 373.8Z"
        fill="#2CCD67"
      />
      <path
        d="M1181.2 373.8C1180.3 372.9 1180 365.1 1180 345C1180 324.9 1180.3 317.1 1181.2 316.2C1181.9 315.5 1183.8 315 1185.5 315C1189.1 315 1191 316.5 1191 319.4C1191 321.2 1191.2 321.2 1193.8 318.8C1197.5 315.4 1199.7 314.3 1205.1 313.5C1210.4 312.7 1217.6 315.3 1222.1 319.6L1224.7 322L1226.6 319.9C1232.5 313.4 1245.8 311.5 1254 316C1256.5 317.4 1259.8 320.5 1261.5 323L1264.5 327.5L1264.8 349.8C1265.2 374.2 1265 375 1259.6 375C1254.2 375 1254 374.3 1254 353.3C1254 332.9 1253.4 329.4 1249.2 325.7C1246.2 322.9 1238 322.9 1234.3 325.6C1228.6 329.7 1228 332.6 1228 353.5C1228 374.3 1227.8 375 1222.5 375C1217.2 375 1217 374.3 1217 353.3C1217 332.1 1216.3 328.6 1211.5 325.4C1207.3 322.6 1199.8 322.9 1196.5 326C1191.6 330.5 1191 333.3 1191 353.8C1191 374.3 1190.8 375 1185.5 375C1183.8 375 1181.9 374.5 1181.2 373.8Z"
        fill="#2CCD67"
      />
      <path
        d="M1365.7 373.6C1360.9 371.8 1359.6 370.7 1357.3 366.2C1355.8 363.2 1355.4 359.2 1355 344.1L1354.5 325.8L1350.7 325.5C1346 325.2 1344.7 324 1344.8 320.1C1344.9 315.6 1345.6 315 1350.6 315H1355V309.1V303.2L1358.5 302.6C1364.2 301.5 1366 303.1 1366 309.5V315H1371.8C1378.4 315 1380 316.1 1380 320.7C1380 324.8 1377.5 326.2 1371.2 325.9L1366.5 325.6V343.3C1366.5 363.6 1366.7 364 1374.7 365L1379.5 365.5V370V374.5L1374.5 374.7C1371.8 374.9 1367.8 374.3 1365.7 373.6Z"
        fill="#2CCD67"
      />
      <path
        d="M1465.2 373.8C1464.3 372.9 1464 365.1 1464 345C1464 324.9 1464.3 317.1 1465.2 316.2C1465.9 315.5 1467.8 315 1469.5 315C1473.4 315 1475 316.6 1475 320.4V323.4L1478.8 319.8C1483.3 315.5 1485.3 314.4 1490.8 313.5L1495 312.8V318.8V324.7L1489.8 325.3C1483.5 326.1 1480.2 328.3 1477.5 333.6C1475.8 337 1475.5 339.9 1475.2 355C1475 365.1 1474.4 373 1473.8 373.8C1472.5 375.3 1466.8 375.4 1465.2 373.8Z"
        fill="#2CCD67"
      />
      <path
        d="M1474 278C1446.9 275.3 1420.1 255.4 1407.3 228.4C1404.2 221.8 1403.6 219.5 1403.6 214.4C1403.8 199.5 1417.1 189.6 1431.7 193.4C1438.9 195.3 1443.3 199.5 1447.5 208.5C1452.1 218.5 1458.5 225.1 1467.8 229.7C1475.1 233.4 1475.8 233.5 1485.9 233.5C1495.8 233.5 1497 233.3 1503.3 230.3C1510.8 226.7 1519.6 218.7 1522.8 212.4C1525.8 206.6 1527.6 198.5 1527.9 190.3C1528 186.3 1528 183 1527.8 183C1527.6 183 1524.4 184.3 1520.5 185.9C1507.3 191.2 1500.8 192.5 1485.5 192.4C1474.7 192.3 1469.8 191.8 1463.8 190.2C1455.5 187.9 1444.6 182.9 1437.3 177.9C1424.2 168.8 1411.4 152.9 1405.8 138.3C1400.4 124.4 1400.1 120.4 1400.3 75.3L1400.5 34.2L1403.5 29.8C1407.8 23.7 1413.5 20.8 1421.3 20.7C1428.9 20.6 1433.8 22.8 1438.4 28.1C1443.8 34.4 1444 36.3 1444 76.1C1444 115.1 1444.2 116.5 1449.3 126.7C1454.7 137.2 1466.8 145.8 1479.7 148C1495.6 150.8 1515 140.9 1522.3 126.4C1526.8 117.3 1527.2 113.2 1527.4 73.7C1527.5 33.3 1527.5 33.6 1533.4 27.2C1538.3 21.7 1548 19.2 1556.3 21.3C1562 22.7 1569.2 30 1570.9 36C1571.8 39.5 1572 59.2 1571.7 124C1571.4 197.5 1571.2 208.2 1569.7 213.5C1565.5 229.1 1558.3 241.6 1547 253.1C1527.6 272.7 1503.6 280.9 1474 278Z"
        fill="#2CCD67"
      />
      <path
        d="M1039.5 237C1031.9 235.8 1025.5 230.8 1022.5 223.7C1021.2 220.5 1021 212.1 1021.1 157.2C1021.2 89.5 1021.4 87.3 1026.7 74.6C1037.5 48.8 1059.1 29.8 1085.5 23C1095.1 20.5 1115.6 20.2 1126.2 22.5C1150.2 27.5 1171.7 44.4 1183.9 68C1189.6 78.9 1192 88.4 1192.7 102.5C1193.8 127.3 1186.7 147.3 1170.4 164.8C1151.3 185.5 1125.2 195.3 1097.2 192.3C1087.7 191.2 1073.9 187.3 1069.2 184.2C1068 183.4 1066.6 183.1 1066.2 183.4C1065.8 183.8 1065.5 191.9 1065.4 201.4C1065.3 220.3 1064.5 224.2 1059.7 229.6C1054.7 235.4 1047.2 238.1 1039.5 237ZM1120.3 146C1133.4 141.9 1143.7 130.5 1147.4 115.8C1150.2 104.5 1147.9 93.1 1140.8 82.9C1135.9 75.8 1130.6 71.5 1123.1 68.4C1107 61.9 1089.5 65.4 1077.5 77.4C1053.4 101.5 1066.9 142 1101 148C1106.5 149 1112.6 148.3 1120.3 146Z"
        fill="#2CCD67"
      />
      <path
        d="M72.2 156.7C32.5 117 0 84 0 83.5C0 82.2 81.2 1 82.5 1C83 1 97.1 14.6 113.7 31.2L144 61.5L174.3 31.2C190.9 14.6 205 1 205.5 1C206.1 1 220 14.5 236.5 31C253 47.5 266.9 61 267.5 61C268.1 61 282.2 47.3 299 30.5L329.5 0L367.5 38L405.5 76H443.8H482V102.3V128.5H465.8H449.5L449.2 144.8L449 161L430.7 161.2C420.7 161.3 408.9 161.4 404.5 161.4L396.5 161.3L396.2 131.7C396.1 115.3 395.6 102 395.2 102C394.8 102 366 130.5 331.3 165.2L268 228.5L237.7 198.2C221.1 181.6 207 168 206.5 168C206 168 191.8 181.7 175 198.5L144.5 229L72.2 156.7ZM176 177.5L206.5 147L237.2 177.7L268 208.4L325.8 150C357.6 117.8 385.4 89.6 387.5 87.2L391.4 82.9L360.9 52.4C344.2 35.7 330.1 22 329.5 22C328.9 22 315 35.5 298.5 52C282 68.5 268 82 267.3 82C266.7 82 252.8 68.3 236.4 51.5C220 34.7 206.1 21 205.5 21C205 21 190.8 34.7 174 51.5L143.5 82L113.5 52C97 35.5 83.1 22 82.5 22C82 22 67.7 35.8 50.7 52.8L20 83.5L82.2 145.8C116.5 180 144.7 208 145 208C145.3 208 159.2 194.3 176 177.5ZM435 131V115L438.8 114.9C440.8 114.9 448 114.8 454.7 114.8C463 114.8 467 114.5 467.3 113.7C467.5 113 467.8 107.7 467.9 101.9C468.1 93.3 467.8 91.1 466.6 90.7C464.3 89.8 411 89.9 410.9 90.7C410.8 91.2 410.7 104 410.6 119.3L410.3 147H422.7H435V131Z"
        fill="#2CCD67"
      />
      <path
        d="M638.8 191.4C614.6 186.8 594.6 168.3 586.4 143C584.8 138.1 584.6 132.1 584.2 89.1C583.8 33.8 583.7 34.2 591 26.9C601.7 16.2 619.1 19 626.3 32.5C628.4 36.4 628.4 37.7 628.6 82C628.7 112.8 629.2 128.9 630 131.8C631.3 137 636.9 143.4 642.4 146.2C648.3 149.2 657.4 149.1 663.6 145.9C669.1 143.1 674.4 136.8 676 131.2C676.6 128.9 677 110.7 677 83.5C677 56.3 677.4 38.1 678 35.8C679.3 31.2 683.1 26.6 688.3 23.3C691.7 21.2 693.5 20.8 699.3 20.8C708.1 20.9 714 24.2 718.5 31.4L721.5 36.3L721.6 82.9C721.7 134.2 721.5 133 728 140.4C733 146 738.3 148.4 746 148.4C756.2 148.4 763.6 143.5 767.9 134C769.9 129.7 770 127.6 770 83.2C770 39.6 770.1 36.7 771.9 33.2C780.4 16.6 804.2 16.3 812.1 32.6C814.3 37.3 814.3 37.4 814.2 85.1C814.2 117.5 813.8 134.8 813 138.6C808.7 159.2 795.2 176.5 776.1 185.7C751.8 197.4 723.9 194.4 702.8 177.8L699 174.8L694.9 178.3C689.7 182.8 678.3 188.4 670.2 190.5C661.8 192.6 647.1 193.1 638.8 191.4Z"
        fill="#2CCD67"
      />
      <path
        d="M904.501 191.9C886.501 189.6 871.101 181.4 856.501 166.6C847.701 157.8 845.801 155.2 841.201 146C838.301 140.2 835.301 132.8 834.401 129.5C832.001 120.3 831.401 100.2 833.301 90.4C840.001 55 869.001 27.1 904.201 21.9C914.201 20.5 931.201 21.2 940.001 23.5C964.201 29.8 986.301 48.8 997.001 72.5C1001.9 83.2 1003.4 91.2 1003.4 106.2C1003.5 118.5 1003.3 120.1 1001.4 122.7C997.401 128.1 998.701 128 938.201 128C874.301 128 879.301 127.2 888.701 136.4C898.001 145.6 913.201 150.5 924.701 148C937.801 145.2 938.301 145.2 943.001 146.6C948.401 148.2 957.101 156.6 959.501 162.5C962.401 169.4 960.401 178.7 954.801 183.8C947.501 190.5 923.201 194.4 904.501 191.9ZM951.001 82.1C951.001 80.2 940.501 71.9 934.201 68.8C928.001 65.7 926.901 65.5 917.501 65.5C906.801 65.6 903.301 66.6 895.501 71.7C890.801 74.8 884.001 81 884.001 82.2C884.001 82.6 899.101 83 917.501 83C936.901 83 951.001 82.6 951.001 82.1Z"
        fill="#2CCD67"
      />
      <path
        d="M1285.5 192.1C1263.4 189.2 1243.4 178.1 1229.6 161.1C1216.1 144.4 1209.9 126.1 1210.5 104.6C1210.8 93.1 1211.8 89.9 1215.7 87.3C1218.2 85.6 1222.3 85.5 1274.8 85.3C1305.8 85.2 1331.4 84.9 1331.7 84.6C1332.8 83.6 1319.4 71.4 1315 69.4C1304.7 64.7 1295.2 63.8 1283.8 66.5C1272.4 69.1 1267.4 67.4 1259.5 58.1C1252.4 49.8 1251.3 41.1 1256.3 33.5C1261.6 25.4 1276.2 20.7 1296.3 20.8C1309.2 20.9 1316 22 1326.4 25.7C1351 34.6 1372.9 58.4 1379.5 83.5C1383.5 98.8 1382.8 119.6 1377.8 134.6C1368.8 161.2 1345.2 183 1317.6 190C1308.6 192.3 1294.2 193.2 1285.5 192.1ZM1308 146.4C1316.1 144 1326.3 136.9 1329.1 131.8C1330 130.1 1328.1 130 1296 130H1261.9L1264.5 133.4C1268 137.9 1276.7 143.8 1283.2 146.1C1289.8 148.4 1300.9 148.5 1308 146.4Z"
        fill="#2CCD67"
      />
    </Icon>
  );
}
