import { Icon, IconProps } from "@chakra-ui/react";

export function LogoNexttPromotora({ ...rest }: IconProps) {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 407 179"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M134.045 174.977C129.062 174.475 125.321 170.352 125.321 165.324C125.321 162.675 126.177 160.463 127.955 158.552C129.146 157.245 130.438 156.424 132.199 155.82C133.424 155.385 133.86 155.318 135.387 155.318C138.356 155.318 140.588 156.223 142.702 158.318C146.594 162.139 146.594 168.206 142.702 172.028C140.37 174.341 137.451 175.329 134.045 174.977ZM137.82 172.38C141.259 171.408 143.54 167.687 142.953 163.999C142.282 159.843 138.524 157.144 134.246 157.731C128.374 158.536 125.74 165.977 129.8 170.301C131.059 171.642 132.535 172.396 134.464 172.698C135.219 172.799 136.863 172.648 137.82 172.38Z"
        fill="#020B50"
      />
      <path
        d="M202.41 174.977C200.162 174.709 198.215 173.821 196.722 172.346C194.659 170.352 193.736 168.19 193.736 165.34C193.719 162.357 194.541 160.413 196.689 158.301C198.87 156.139 200.849 155.318 203.836 155.318C206.855 155.334 208.852 156.156 210.982 158.284C213.079 160.379 213.952 162.39 213.952 165.173C213.952 171.19 208.65 175.681 202.41 174.977ZM205.01 172.648C206.688 172.363 207.812 171.827 208.952 170.754C210.848 168.994 211.469 167.452 211.352 164.77C211.301 163.396 211.217 162.994 210.764 162.039C209.624 159.608 207.577 158.1 204.926 157.731C201.571 157.245 198.333 159.005 196.89 162.072C196.185 163.564 196.068 165.826 196.605 167.569C197.662 171.072 201.302 173.251 205.01 172.648Z"
        fill="#020B50"
      />
      <path
        d="M259.534 174.978C256.212 174.642 253.159 172.514 251.75 169.564C251.045 168.072 250.81 166.983 250.81 165.206C250.81 161.838 252.488 158.787 255.39 156.876C258.276 154.983 262.386 154.765 265.473 156.323C268.241 157.731 270.237 160.262 270.858 163.162C271.311 165.307 271.043 167.469 270.103 169.396C268.174 173.302 264.114 175.447 259.534 174.978ZM263.325 172.38C264.416 172.061 266.077 170.972 266.832 170.05C269.264 167.067 269.029 162.575 266.295 159.843C263.342 156.893 258.427 156.91 255.541 159.86C253.595 161.838 252.89 164.502 253.578 167.151C253.914 168.475 254.4 169.346 255.289 170.301C257.336 172.48 260.272 173.235 263.325 172.38Z"
        fill="#020B50"
      />
      <path
        d="M72.5588 174.609C72.5085 174.492 72.4917 170.167 72.5085 165.005L72.5588 155.619L75.8303 155.569C80.7961 155.502 82.2054 155.837 83.6985 157.463C84.8561 158.72 85.1413 159.525 85.1413 161.486C85.1413 162.994 85.0909 163.245 84.6715 164.05C84.0508 165.223 83.0442 166.195 81.8866 166.732C80.9974 167.151 80.7626 167.184 77.9777 167.234L74.9914 167.301V171.056V174.81H73.8171C73.0286 174.81 72.6091 174.743 72.5588 174.609ZM80.3096 164.838C82.7925 164.1 83.6314 160.865 81.8027 158.972C80.8968 158.033 80.3096 157.882 77.3905 157.882H74.9914V161.486V165.089H77.2227C78.7326 165.089 79.7392 165.005 80.3096 164.838Z"
        fill="#020B50"
      />
      <path
        d="M99.2334 174.609C99.183 174.492 99.1663 170.167 99.183 165.005L99.2334 155.619L102.589 155.569C107.504 155.502 108.863 155.837 110.373 157.463C111.212 158.385 111.648 159.307 111.816 160.513C112.151 163.061 110.39 165.793 108.008 166.463L107.185 166.698L109.685 170.285C111.044 172.262 112.302 174.089 112.47 174.358L112.772 174.81H111.245H109.719L107.118 170.871L104.535 166.933H103.092H101.666V170.871V174.81H100.492C99.7031 174.81 99.2837 174.743 99.2334 174.609ZM106.699 164.402C109.366 163.832 110.272 160.245 108.175 158.653C107.336 158.033 106.498 157.882 103.981 157.882H101.666V161.234V164.586H103.763C104.904 164.586 106.229 164.502 106.699 164.402Z"
        fill="#020B50"
      />
      <path
        d="M160.049 165.173V155.536H160.535C161.072 155.536 160.753 155.1 167.866 165.391L169.645 167.938L173.906 161.787C178.033 155.804 178.184 155.619 178.704 155.619H179.258V165.173V174.726L178.05 174.776L176.825 174.827L176.792 168.206L176.741 161.586L173.302 166.514C171.121 169.665 169.779 171.458 169.594 171.458C169.326 171.458 168.873 170.821 164.394 164.301C163.437 162.893 162.599 161.737 162.532 161.737C162.448 161.737 162.397 164.687 162.397 168.273V174.81H161.223H160.049V165.173Z"
        fill="#020B50"
      />
      <path
        d="M231.181 166.346V157.882H228.245H225.31V156.709V155.536H232.356H239.402V156.709V157.882H236.55H233.698L233.664 166.313L233.614 174.726L232.406 174.776L231.181 174.827V166.346Z"
        fill="#020B50"
      />
      <path
        d="M285.538 165.173V155.536H288.943C293.725 155.536 295.1 155.854 296.509 157.295C297.583 158.402 298.036 159.558 298.036 161.234C298.036 162.458 297.969 162.793 297.566 163.614C296.946 164.871 295.687 165.994 294.496 166.38C293.993 166.53 293.59 166.715 293.59 166.782C293.59 166.849 294.798 168.659 296.275 170.771C297.751 172.899 298.959 174.676 298.959 174.726C298.959 174.776 298.288 174.81 297.483 174.81H295.989L293.372 170.871L290.738 166.933H289.312H287.886V170.871V174.81H286.712H285.538V165.173ZM292.919 164.402C294.429 164.067 295.369 163.044 295.553 161.603C295.738 159.994 294.765 158.469 293.305 158.066C292.953 157.966 291.594 157.882 290.285 157.882H287.886V161.234V164.586H290.034C291.208 164.586 292.5 164.502 292.919 164.402Z"
        fill="#020B50"
      />
      <path
        d="M311.139 174.139C311.29 173.754 313.101 169.43 315.148 164.536L318.873 155.619L319.46 155.569L320.03 155.519L323.922 164.905C326.07 170.067 327.865 174.408 327.915 174.542C327.999 174.776 327.814 174.81 326.674 174.81H325.332L324.275 172.212L323.218 169.614L319.426 169.648L315.651 169.698L314.628 172.212L313.621 174.726L312.246 174.776L310.87 174.827L311.139 174.139ZM322.278 167.268C322.278 167.016 319.544 160.228 319.443 160.228C319.359 160.228 317.061 165.893 316.641 167.15C316.541 167.419 316.742 167.435 319.409 167.435C321.154 167.435 322.278 167.368 322.278 167.268Z"
        fill="#020B50"
      />
      <path
        d="M91.4995 113.819C90.0903 113.434 89.0166 112.914 87.7751 112.026C87.104 111.54 79.3365 102.322 57.745 76.3599L28.6041 41.3476L28.5202 56.3314C28.4699 64.5774 28.4195 71.3318 28.386 71.3653C28.3692 71.3821 28.235 71.3989 28.1008 71.3989C27.933 71.3989 27.8491 71.2313 27.8491 70.8625C27.8324 69.7396 27.3291 67.9127 26.6412 66.5384C24.7455 62.7506 20.2997 60.2365 16.4914 60.7896C11.9617 61.46 8.25408 65.0802 7.4488 69.5888C7.33136 70.2927 6.97905 70.4268 6.81129 69.8569C6.64352 69.22 6.71063 14.6317 6.8784 13.0227C7.18037 10.14 8.32118 7.76001 10.284 5.9499C12.1798 4.19007 15.0989 3.01685 17.6154 3.01685C19.8635 3.01685 22.8833 4.12303 24.5609 5.56441C25.0139 5.9499 27.6143 8.94999 30.3321 12.2182C33.0499 15.5033 35.4489 18.3358 35.6335 18.5201C35.8348 18.7045 46.6389 31.6602 59.6408 47.3143L83.2958 75.7565L83.3796 44.0293C83.4803 8.64831 83.3629 11.7657 84.6379 9.13435C86.5336 5.22921 90.6775 2.76544 94.8716 3.06713C99.6697 3.41909 103.528 6.58679 104.803 11.2629C105.072 12.2518 105.105 15.3189 105.156 57.3202C105.189 86.3155 105.139 102.925 105.038 103.998C104.837 105.925 104.3 107.718 103.478 109.059C102.27 111.054 100.005 112.847 97.6397 113.652C96.0795 114.188 93.1436 114.255 91.4995 113.819Z"
        fill="#020B50"
      />
      <path
        d="M131.495 113.97C125.824 113.283 121.58 108.188 122.016 102.59C122.234 99.8579 123.157 97.8634 125.153 95.8522C126.328 94.6622 126.831 94.2767 128.005 93.7236C130.472 92.5504 129.582 92.6007 148.641 92.6007H165.837L167.179 93.0532C170.752 94.2767 173.319 96.9081 174.409 100.495C174.829 101.852 174.829 104.802 174.409 106.16C173.873 107.953 173.101 109.277 171.859 110.601C170.484 112.059 169.108 112.948 167.179 113.601L165.837 114.054L149.228 114.071C140.101 114.087 132.116 114.037 131.495 113.97Z"
        fill="#020B50"
      />
      <path
        d="M279.666 113.953C277.535 113.719 275.74 113.032 274.045 111.758C273.324 111.221 268.442 105.707 257.017 92.5504C244.653 78.3042 240.962 74.1308 240.794 74.2817C240.627 74.4158 240.576 74.3487 240.576 73.9632C240.576 73.561 241.314 72.6224 244.435 68.9519C246.565 66.4714 248.646 63.9573 249.048 63.354C250.541 61.2087 251.129 58.4767 250.575 56.3482C249.904 53.8174 249.552 53.2978 244.401 47.3982C241.583 44.1802 241.079 43.5098 241.079 43.0573C241.079 42.6047 241.113 42.5544 241.331 42.7388C241.499 42.8729 241.65 42.8897 241.734 42.8058C241.818 42.722 246.465 37.2582 252.068 30.6714C257.688 24.0846 262.436 18.5369 262.637 18.3526C262.839 18.1682 265.171 15.4866 267.805 12.4027C270.422 9.30201 272.989 6.40248 273.492 5.93319C274.515 5.01138 275.841 4.25716 277.485 3.65379L278.575 3.26831L334.156 3.21803C387.086 3.1845 389.72 3.20127 389.72 3.46943C389.72 3.70407 389.452 3.83816 388.579 4.03928C386.633 4.50857 385.257 5.2963 383.748 6.78797C382.137 8.3802 381.298 9.78806 380.812 11.749C380.174 14.2295 380.61 16.6765 382.07 18.9224C383.697 21.4365 386.381 23.3304 388.881 23.7326C389.385 23.8164 389.552 23.917 389.552 24.1516C389.552 24.4701 388.26 24.4701 337.662 24.4701H285.772L271.177 41.5656C263.157 50.9681 256.581 58.7449 256.547 58.8287C256.514 58.9125 263.996 67.6279 273.156 78.1868C282.333 88.7291 290.084 97.7796 290.386 98.2824C292.03 100.998 292.298 104.299 291.124 107.249C289.346 111.707 284.598 114.49 279.666 113.953Z"
        fill="#020B50"
      />
      <path
        d="M364.723 113.618C360.311 113.232 356.57 110.015 355.378 105.59C355.093 104.567 355.076 102.472 355.076 72.3206C355.076 41.6995 355.093 40.0905 355.378 39.0682C357.643 31.107 367.156 28.3751 373.094 33.9563C374.638 35.3977 375.645 37.0737 376.131 38.9508C376.467 40.2581 376.467 40.7274 376.467 72.3374C376.467 95.0978 376.416 104.651 376.282 105.288C376.03 106.512 374.99 108.69 374.135 109.746C372.876 111.322 370.679 112.763 368.749 113.3C367.676 113.601 365.981 113.735 364.723 113.618Z"
        fill="#020B50"
      />
      <path
        d="M329.157 101.115C328.435 97.4443 325.147 94.0754 321.356 93.1034C317.195 92.0475 312.363 94.4107 310.249 98.5169C309.864 99.2711 309.444 100.344 309.327 100.897C309.176 101.685 309.058 101.903 308.823 101.903C308.538 101.903 308.521 100.645 308.521 71.1138C308.521 36.9228 308.454 39.3363 309.662 36.8725C310.551 35.0624 312.481 33.135 314.343 32.2132C316.222 31.2914 317.883 30.9394 319.846 31.0567C324.275 31.3081 327.999 34.1909 329.459 38.4648L329.912 39.8056L329.962 71.03C329.995 101.199 329.995 102.238 329.693 102.238C329.442 102.238 329.341 102.02 329.157 101.115Z"
        fill="#020B50"
      />
      <path
        d="M133.374 70.2257C133.374 69.9743 133.491 69.8905 133.793 69.8905C134.397 69.8905 136.159 69.2703 137.232 68.667C137.736 68.382 138.759 67.544 139.497 66.8066C143.608 62.6668 143.574 56.9683 139.413 52.7782C137.954 51.32 136.025 50.2306 134.246 49.8954C133.441 49.7446 133.206 49.644 133.206 49.4094C133.206 49.1244 133.995 49.1077 148.942 49.1077C166.122 49.1077 165.719 49.0909 168.118 50.0965C170.568 51.1189 172.581 53.0296 173.705 55.3928C174.476 56.985 174.728 58.1247 174.711 60.0019C174.711 61.7785 174.342 63.2031 173.436 64.8959C172.027 67.5608 168.856 69.8067 165.686 70.3765C165.014 70.4938 159.545 70.5609 149.026 70.5609C133.491 70.5609 133.374 70.5609 133.374 70.2257Z"
        fill="#020B50"
      />
      <path
        d="M130.606 24.3024C126.428 23.4476 123.04 20.0788 122.201 15.9222C121.278 11.3132 123.61 6.51971 127.838 4.39116C130.321 3.15089 129.582 3.18441 148.49 3.18441C164.746 3.18441 165.417 3.20117 166.659 3.51962C168.622 4.02243 170.165 4.91073 171.625 6.36887C175.064 9.78797 175.785 15.0004 173.369 19.1067C172.094 21.2855 169.947 23.0621 167.565 23.9337L166.34 24.3862L148.808 24.4197C139.162 24.4197 130.975 24.3694 130.606 24.3024Z"
        fill="#020B50"
      />
      <path
        d="M200.111 113.953C197.695 113.685 195.581 112.612 193.686 110.718C191.588 108.623 190.699 106.461 190.683 103.495C190.666 101.534 191.085 100.009 192.075 98.366C192.41 97.8297 200.161 88.6116 209.288 77.9017C218.431 67.1919 225.897 58.3425 225.897 58.2251C225.897 58.1078 218.431 49.4092 209.288 38.9005C200.161 28.375 192.394 19.291 192.041 18.7211C188.938 13.626 191.135 6.97214 196.806 4.27373C200.765 2.39657 205.865 3.08375 208.952 5.89948C209.489 6.38553 212.106 9.30182 214.79 12.4025C217.475 15.4864 219.857 18.2015 220.092 18.4362C220.327 18.6708 225.175 24.2185 230.862 30.755C236.55 37.3083 242.841 44.5487 244.854 46.8449C250.122 52.8954 250.407 53.3144 251.078 55.8452C251.481 57.4039 251.347 59.8676 250.759 61.5102C250.139 63.27 249.401 64.2588 244.502 69.9741C242.12 72.7396 233.261 83.1142 224.806 93.0195C214.891 104.634 209.103 111.288 208.516 111.741C206.234 113.501 203.164 114.305 200.111 113.953Z"
        fill="#72F5A7"
      />
      <path
        d="M317.564 113.635C315.282 113.266 313.403 112.294 311.625 110.534C309.578 108.506 308.521 105.975 308.521 103.059C308.521 98.1148 311.81 93.8241 316.574 92.6006C317.95 92.2487 320.5 92.2319 321.859 92.6006C324.661 93.3213 327.412 95.5002 328.704 97.9807C332.327 105.003 327.647 113.249 319.728 113.702C319.057 113.752 318.084 113.719 317.564 113.635Z"
        fill="#72F5A7"
      />
      <path
        d="M14.5956 81.3041C12.5656 80.6504 10.3846 79.209 9.21028 77.7341C8.38823 76.695 7.34808 74.5664 7.02933 73.2424C6.91189 72.7396 6.81123 71.6837 6.82801 70.8959C6.87834 66.0857 9.81424 62.1638 14.5117 60.5883C15.8203 60.1526 18.32 60.0688 19.8634 60.4207C24.8293 61.5102 28.3356 65.9181 28.3524 71.0635C28.3524 74.9016 25.8359 78.8738 22.3128 80.5666C21.5243 80.9521 20.4842 81.3376 19.9976 81.4382C18.5884 81.7231 15.6693 81.656 14.5956 81.3041Z"
        fill="#72F5A7"
      />
      <path
        d="M129.599 70.2591C126.697 69.3876 124.13 67.2758 122.838 64.6947C119.349 57.7224 124.029 49.6607 131.864 49.1579C136.612 48.873 140.957 51.8563 142.618 56.5995C143.104 57.9571 143.222 60.5549 142.869 62.1639C142.45 64.1416 141.561 65.7339 140.001 67.3093C137.753 69.572 135.857 70.3932 132.619 70.4938C131.025 70.5441 130.388 70.4938 129.599 70.2591Z"
        fill="#72F5A7"
      />
      <path
        d="M387.925 24.1348C384.536 23.2465 381.617 20.5481 380.476 17.2463C379.604 14.7155 379.939 11.1455 381.265 8.73206C382.137 7.17335 384.268 5.11183 385.761 4.39114C390.056 2.31286 394.77 3.00003 398.108 6.20125C401.128 9.10078 402.219 13.6931 400.776 17.4977C400.289 18.8218 398.947 20.7492 397.806 21.8051C396.733 22.794 394.384 23.9839 392.941 24.2856C391.599 24.5705 389.301 24.5035 387.925 24.1348Z"
        fill="#72F5A7"
      />
    </Icon>
  );
}
