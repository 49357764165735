import { Box, Button, ButtonProps, Text } from "@chakra-ui/react";
import { dropdownSimNao } from "components/atendimentos-components/atendimento-form/fields-data";
import { CustomModal } from "components/custom-modal";
import { Dropdown } from "components/dropdown";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useState } from "react";
import { useEventListener, makeEvent } from "services/events";
import { PriceButton } from ".";
import { CheckIcon, CloseIcon } from "@chakra-ui/icons";

interface ModalDataProps {
  modalTitle: string;
  onConfirm?: ({ possuiRecorrencia }: { possuiRecorrencia: boolean }) => void;
  confirmButtonProps?: ButtonProps;
}

export function ModalChangeCobranca() {
  const [isOpen, setIsOpen] = useState(false);
  const [recorrente, setRecorrente] = useState<boolean>();
  const [modalData, setModalData] = useState<Partial<ModalDataProps>>({});
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useApplicationContext();

  const onOpen = (modalData: ModalDataProps) => {
    setIsOpen(true);
    setModalData(modalData);
  };

  const onClose = () => {
    setIsOpen(false);
    setRecorrente(undefined);
    setModalData({});
    setIsLoading(false);
  };

  useEventListener("openModalChangeCobranca", onOpen);

  const modalFooter = (
    <>
      <Button
        variant="outline"
        leftIcon={<CloseIcon w="12px" h="12px" />}
        onClick={onClose}
      >
        Cancelar
      </Button>
      <Button
        onClick={async () => {
          setIsLoading(true);
          try {
            await modalData.onConfirm?.({ possuiRecorrencia: recorrente! });
            onClose();
          } catch (e) {
          } finally {
            setIsLoading(false);
          }
        }}
        isLoading={isLoading}
        loadingText="Confirmando"
        leftIcon={<CheckIcon />}
        isDisabled={recorrente == null}
        children="Confirmar"
        {...modalData.confirmButtonProps}
      />
    </>
  );

  return (
    <CustomModal
      modalTitle={modalData.modalTitle}
      modalFooter={modalFooter}
      isOpen={isOpen}
      onClose={onClose}
      size="lg"
    >
      <Box w="100%">
        <Text mb="8px" fontWeight="semibold">
          Cobrança Recorrente:
        </Text>

        <Dropdown
          options={dropdownSimNao}
          value={recorrente}
          onChange={(value) => {
            setRecorrente(value);
          }}
          variant="outline-custom"
          w="100%"
        />
      </Box>
      <PriceButton mt="40px">R$ 19,90 mensal</PriceButton>
    </CustomModal>
  );
}

export const openModalChangeCobranca = (modalData: ModalDataProps) =>
  makeEvent("openModalChangeCobranca", modalData);
