import { Box, Flex, Grid, Input, Text, Tooltip } from "@chakra-ui/react";
import { maskCPF } from "utils/string-formats";
import { dropdownEspecies } from "components/atendimentos-components/atendimento-form/fields-data";
import { IN100Props } from "pages/consulta/consulta-in100";
import { parseSituacaoBeneficio } from "../utils";

export function ConsultaIN100Fields({ consult }: { consult: IN100Props }) {
  const dateFormat = (data: string, withOutYears = false) => {
    data = data.slice(0, 10);
    const today = new Date();
    const birthDate = new Date(data);
    let age = today.getFullYear() - birthDate.getUTCFullYear();
    const month = today.getMonth() - birthDate.getUTCMonth();
    const day = today.getDate() - birthDate.getUTCDate();

    if (month < 0) age -= 1;
    else if (month === 0 && day < 0) age -= 1;

    return `${data?.split("-").reverse().join("/") ?? ""}${
      withOutYears ? "" : ` (${age} anos)`
    }`;
  };

  const tipoCreditoFormat = (value: string) => {
    if (value === "1") {
      return "CARTÃO MAGNETICO";
    } else if (value === "2") {
      return "CONTA CORRENTE";
    } else {
      return "";
    }
  }

  const cpfFormatted = (value: string) => {
    if (!value) return "";
    while (value.length < 11) {
      value = "0" + value;
    }
    return maskCPF(value);
  }

  return (
    <Grid w="100%" templateColumns="1fr 1fr 1fr" gap="16px">
      <Box gridArea="auto / span 3">
        <Flex w="100%" justifyContent="space-between">
          <Text fontWeight="semibold" fontSize="20">
            Dados Cadastrais
          </Text>
        </Flex>
        <Box
          borderTop="1px solid var(--chakra-colors-gray-200)"
          style={{ margin: "10px 0 0px 0" }}
        />
      </Box>

      <Box>
        <Text mb="8px">Nome:</Text>
        <Input
          variant="outline-custom"
          value={consult?.nomeBeneficiario}
          isReadOnly
        />
      </Box>

      <Box>
        <Text mb="8px">CPF:</Text>
        <Input
          variant="outline-custom"
          value={cpfFormatted(consult?.cpf)}
          isReadOnly
        />
      </Box>

      <Box>
        <Text mb="8px">Data Nascimento:</Text>
        <Input
          value={dateFormat(consult?.dataNascimento)}
          variant="outline-custom"
          isReadOnly
        />
      </Box>

      <Flex flexDir="column" gridArea={"auto / span 3"}>
        <Text fontSize="20" fontWeight="semibold">
          Benefício
        </Text>
        <Box
          borderTop="1px solid var(--chakra-colors-gray-200)"
          style={{ margin: "10px 0 0px 0" }}
        />
      </Flex>

      <Box>
        <Text mb="8px">Número benefício:</Text>
        <Input
          variant="outline-custom"
          value={consult?.numeroMatricula}
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">Espécie benefício:</Text>
        <Tooltip
          label={dropdownEspecies.find(
            ({ value }) => value === consult?.especieBeneficio
            )?.name
          }
        >
          <Input
            value={`${
              dropdownEspecies.find(
                ({ value }) => value === consult?.especieBeneficio
              )?.name
            }`}
            variant="outline-custom"
            isReadOnly
          />
        </Tooltip>
      </Box>
      <Box>
        <Text mb="8px">Situação benefício:</Text>
        <Input
          variant="outline-custom"
          value={parseSituacaoBeneficio[consult?.situacaoBeneficio ?? ""] ?? ""}
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">Possui representante legal:</Text>
        <Input
          variant="outline-custom"
          value={consult?.possuiRepresentanteLegal ? "SIM" : "NÃO" || ""}
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">Possui pensão alimentícia:</Text>
        <Input
          variant="outline-custom"
          value={consult.pensaoAlimenticia ? "SIM" : "NÃO"}
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">Elegível para empréstimos:</Text>
        <Input
          variant="outline-custom"
          value={
            consult?.elegivelEmprestimo != null
              ? consult?.elegivelEmprestimo
                ? "SIM"
                : "NÃO"
              : ""
          }
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">Bloqueado para empréstimos:</Text>
        <Input
          variant="outline-custom"
          value={
            consult?.bloqueadoParaEmprestimo != null
              ? consult?.bloqueadoParaEmprestimo
                ? "SIM"
                : "NÃO"
              : ""
          }
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">Qtd. Empréstimos ativos/suspensos:</Text>
        <Input
          variant="outline-custom"
          value={consult?.qtdEmprestimosAtivosSuspensos || 0}
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">UF Pagamento:</Text>
        <Input
          variant="outline-custom"
          value={consult?.ufPagamento || ""}
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">DDB:</Text>
        <Input
          variant="outline-custom"
          value={dateFormat(consult?.dataDespachoBeneficio, true)}
          isReadOnly
        />
      </Box>

      <Box>
        <Text mb="8px">Data da Última Pericia:</Text>
        <Input
          variant="outline-custom"
          value={dateFormat(consult?.dataUltimaPericia).split(" ", 1)[0]}
          isReadOnly
        />
      </Box>

      {consult?.possuiRepresentanteLegal ? (
        <>
          <Flex flexDir="column" gridArea={"auto / span 3"}>
            <Text fontSize="20" fontWeight="semibold">
              Dados Representante Legal
            </Text>
            <Box
              borderTop="1px solid var(--chakra-colors-gray-200)"
              style={{ margin: "10px 0 0px 0" }}
            />
          </Flex>
          <Box>
            <Text mb="8px">CPF Representante Legal:</Text>
            <Input
              variant="outline-custom"
              value={cpfFormatted(consult?.cpfRepresentanteLegal)}
              isReadOnly
            />
          </Box>

          <Box>
            <Text mb="8px">Nome Representante Legal:</Text>
            <Input
              variant="outline-custom"
              value={consult?.nomeRepresentanteLegal}
              isReadOnly
            />
          </Box>
        </>
      ) : null}

      <Flex flexDir="column" gridArea={"auto / span 3"}>
        <Text fontSize="20" fontWeight="semibold">
          Dados bancarios
        </Text>
        <Box
          borderTop="1px solid var(--chakra-colors-gray-200)"
          style={{ margin: "10px 0 0px 0" }}
        />
      </Flex>

      <Box>
        <Text mb="8px">Tipo conta pagamento:</Text>
        <Input
          variant="outline-custom"
          value={tipoCreditoFormat(consult?.tipoCredito)}
          isReadOnly
        />
      </Box>

      <Box>
        <Text mb="8px">Código Banco:</Text>
        <Input
          variant="outline-custom"
          value={consult?.cbcIfPagadora}
          isReadOnly
        />
      </Box>

      <Box>
        <Text mb="8px">Agencia:</Text>
        <Input
          variant="outline-custom"
          value={consult?.agenciaPagadora}
          isReadOnly
        />
      </Box>

      {tipoCreditoFormat(consult?.tipoCredito) !== "CARTÃO MAGNETICO" ? (
        <Box>
          <Text mb="8px">Número da conta + DV:</Text>
          <Input
            variant="outline-custom"
            value={consult?.contaCorrente}
            isReadOnly
          />
        </Box>
      ) : null}

      <Flex flexDir="column" gridArea={"auto / span 3"}>
        <Text fontSize="20" fontWeight="semibold">
          Margens
        </Text>
        <Box
          borderTop="1px solid var(--chakra-colors-gray-200)"
          style={{ margin: "10px 0 0px 0" }}
        />
      </Flex>

      <Box>
        <Text mb="8px">Margem livre novo:</Text>
        <Input
          variant="outline-custom"
          value={`R$ ${consult?.margemDisponivel?.toFixed(2) || ""}`}
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">Margem livre RMC:</Text>
        <Input
          variant="outline-custom"
          value={`R$ ${consult?.margemDisponivelCartao?.toFixed(2) || ""}`}
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">Valor limite RMC:</Text>
        <Input
          variant="outline-custom"
          value={`R$ ${consult?.valorLimiteCartao?.toFixed(2) || ""}`}
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">Margem livre RCC:</Text>
        <Input
          variant="outline-custom"
          value={`R$ ${consult?.margemDisponivelRcc?.toFixed(2) || ""}`}
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">Valor limite RCC:</Text>
        <Input
          variant="outline-custom"
          value={`R$ ${consult?.valorLimiteRcc?.toFixed(2) || ""}`}
          isReadOnly
        />
      </Box>
    </Grid>
  );
}
