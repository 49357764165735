import { Dispatch, SetStateAction } from "react";
import { MailingOptionFilter } from ".";

export const initialDigitacaoPipelineFilterValues: MailingOptionFilter = {
  banco: { isChecked: false, value: "" },
  usuarioDigitacao: { isChecked: false, value: "" },
  tabela: { isChecked: false, value: "" },
  usuarioDigitacaoSenha: { isChecked: false, value: "" },
  cpfUsuarioCertificado: { isChecked: false, value: "" },
};

export const initialFilterValues: MailingOptionFilter = {
  idade: { isChecked: false, value: { gte: "", lte: "" } },
  margemRMC: { isChecked: false, value: { gte: "", lte: "" } },
  margemRCC: { isChecked: false, value: { gte: "", lte: "" } },
  possui_rmc: { isChecked: false, value: false },
  possui_rcc: { isChecked: false, value: false },
  banco_operacao: { isChecked: false, value: [] },
  saldo_devedor: { isChecked: false, value: { gte: "", lte: "" } },
  taxa_contrato: { isChecked: false, value: { gte: "", lte: "" } },
  valor_parcela: { isChecked: false, value: { gte: "", lte: "" } },
  valor_liberado: { isChecked: false, value: { gte: "", lte: "" } },
  quantidade_contratos: { isChecked: false, value: { gte: "", lte: "" } },
  numero_parcelas_pagas: { isChecked: false, value: { gte: "", lte: "" } },
  valor_antecipacao: { isChecked: false, value: "" },
  valor_liberado_saque_rcc: { isChecked: false, value: "" },
  valor_liberado_saque_rmc: { isChecked: false, value: "" },
  margem_aumento: { isChecked: false, value: { gte: "", lte: "" } },
  especies: { isChecked: false, value: [] },
  possui_representante_legal: { isChecked: false, value: true },
  meio_pagamento: { isChecked: false, value: "" },
  valor_beneficio: { isChecked: false, value: { gte: "", lte: "" } },
  ddb: { isChecked: false, value: { gte: "", lte: "" } },
  contratos_enquadrados: { isChecked: false, value: true },
  margem_disponivel_emprestimo: {
    isChecked: false,
    value: { gte: "", lte: "" },
  },
  margem_disponivel_rcc: { isChecked: false, value: { gte: "", lte: "" } },
  margem_disponivel_rmc: { isChecked: false, value: { gte: "", lte: "" } },
};

export const initialHigienizacaoValues: MailingOptionFilter = {
  REFIN: { isChecked: false, value: [] },
  IN_100: { isChecked: false, value: [] },
  SAQUE_COMPLEMENTAR: { isChecked: false, value: [] },
  ANTECIPACAO_FGTS: { isChecked: false, value: [] },
  SIMULACAO_AUMENTO: { isChecked: false, value: { coeficiente: 0.05 } },
  MARGEM_CONVENIO: { isChecked: false, value: {} },
};

export const initialSimulacaoPortabilidade: MailingOptionFilter = {
  SIMULACAO_PORTABILIDADE: { isChecked: false, value: [] },
};

export const initialTelefoniaValues: MailingOptionFilter = {
  telefoniaApi: { isChecked: false, value: false },
};

export const initialEnviarParaValues: MailingOptionFilter = {
  enviarParaCampanhaWhatsApp: { isChecked: false, value: false },
  enviarParaDiscadora: { isChecked: false, value: false },
  enviarParaCampanhaSMS: { isChecked: false, value: false },
  enviaAtendimento: { isChecked: false, value: false },
};

export const dropdownBancosSimulacaoPortabilidade = [
  { name: "C6", value: "C6" },
  { name: "DAYCOVAL", value: "DAYCOVAL" },
];

export const dropdownBancosSaqueComplementar = [
  { name: "BMG", value: "BMG" },
  { name: "MASTER", value: "MASTER" },
  { name: "PAN", value: "PAN" },
];

export const formatTitle = ({
  title,
  presentFields,
}: {
  title: string;
  presentFields: string[];
}) => {
  const isHigienizacoesPresentFields = !!presentFields.find((k) =>
    Object.keys(initialHigienizacaoValues).includes(k)
  );
  const isSimulacaoPresentFields = !!presentFields.find((k) =>
    Object.keys(initialSimulacaoPortabilidade).includes(k)
  );
  const isFiltersPresentFields = !!presentFields.find((k) =>
    Object.keys(initialFilterValues).includes(k)
  );
  const isDigitacaoPresentFields = !!presentFields.find((k) =>
    Object.keys(initialDigitacaoPipelineFilterValues).includes(k)
  );
  const isTelefoniaPresentFields = !!presentFields.find((k) =>
    Object.keys(initialTelefoniaValues).includes(k)
  );
  const isEnviarParaPresentFields = !!presentFields.find((k) =>
    Object.keys(initialEnviarParaValues).includes(k)
  );
  let i = 1;

  if (title === "Higienizações") {
    return `1 - Higienizações:`;
  } else if (title === "Simulação Portabilidade") {
    if (isHigienizacoesPresentFields) i += 1;
    return `${i} - Simulação Portabilidade:`;
  } else if (title === "Filtros") {
    if (isHigienizacoesPresentFields) i += 1;
    if (isSimulacaoPresentFields) i += 1;
    return `${i} - Filtros:`;
  } else if (title === "Telefonia") {
    if (isHigienizacoesPresentFields) i += 1;
    if (isSimulacaoPresentFields) i += 1;
    if (isFiltersPresentFields) i += 1;
    return `${i} - Telefonia:`;
  } else if (title === "Digitação") {
    if (isHigienizacoesPresentFields) i += 1;
    if (isSimulacaoPresentFields) i += 1;
    if (isFiltersPresentFields) i += 1;
    if (isTelefoniaPresentFields) i += 1;
    return `${i} - Digitação:`;
  } else if (title === "Enviar para") {
    if (isHigienizacoesPresentFields) i += 1;
    if (isSimulacaoPresentFields) i += 1;
    if (isFiltersPresentFields) i += 1;
    if (isTelefoniaPresentFields) i += 1;
    if (isDigitacaoPresentFields) i += 1;
    return `${i} - Enviar para:`;
  }
};

export const onChangeMailingStage =
  ({
    setState,
    rule,
  }: {
    setState: Dispatch<SetStateAction<MailingOptionFilter>>;
    rule?: (stateValues: MailingOptionFilter) => MailingOptionFilter;
  }) =>
  (value: SetStateAction<MailingOptionFilter>) => {
    setState((prev) => {
      const getValue = typeof value === "function" ? value(prev) : value;
      return rule ? rule(getValue) : getValue;
    });
  };

// const higienizacoes = {
//   refin: [  ],
//   saque: [  ],
//   simulacaoAumento: [],
//   margemConvenio: [],
// }
