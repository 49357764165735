import {
  Box,
  Button,
  Center,
  Flex,
  ListItem,
  Text,
  Tooltip,
  UnorderedList,
} from "@chakra-ui/react";
import { defaultScroll } from "chakra/theme";
import { Popover } from "components/popover";
import { Pipeline } from ".";
import { FaPlay, FaRedo } from "react-icons/fa";
import { MailingTableRow } from "..";
import {
  advancePipeline,
  getErrorMessage,
  getFilteredPipelineSteps,
  getHigienizacaoIds,
  replayPipeline,
  verifyStageStatus,
} from "./utils";
import {
  ContentPopoverPipelineStage,
  PIPELINE_STAGES_WITH_CONTENT,
} from "./higienizacao-progress";
import { Dispatch, Fragment, SetStateAction } from "react";
import { useApplicationContext } from "contexts/ApplicationContext";

export function PipelineStep({
  pipelineStage,
  title,
  modalData,
  row,
  setIsLoading,
  setActiveStep,
  index,
  activeStep,
}: {
  title: string;
  pipelineStage: Pipeline["pipelineStage"];
  modalData: Pipeline[];
  row: MailingTableRow;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  setActiveStep: Dispatch<SetStateAction<number>>;
  index: number;
  activeStep: number;
}) {
  const { user } = useApplicationContext();
  const filteredPipelineSteps = getFilteredPipelineSteps({ modalData, user });

  const currentStage = modalData.find((p) => p.pipelineStage === pipelineStage);
  const isRunning = verifyStageStatus({
    stage: pipelineStage,
    status: "EM_ANDAMENTO",
    modalData,
  });
  const isError = verifyStageStatus({
    stage: pipelineStage,
    status: "ERROR",
    modalData,
  });
  const isFinished = currentStage?.pipelineStatus === "FINALIZADO";
  const isSuspended = currentStage?.pipelineItemStatus === "SUSPENSO";
  const isPassed = activeStep > index || isFinished;

  const isLast = filteredPipelineSteps.length === index + 1;
  const isCurrentStep = activeStep === index;
  const leadCount = currentStage?.mailingLeadCount;
  const showLeadCount = isPassed || isCurrentStep;
  const showTotal =
    currentStage?.pipelineStage === "ENVIA_ATENDIMENTO" && !!currentStage?.data;

  let stageData = modalData.find((p) => p.pipelineStage === pipelineStage);

  const openPopover =
    isCurrentStep &&
    !isFinished &&
    (pipelineStage !== "FILTRO" ||
      (pipelineStage === "FILTRO" && stageData?.pipelineStatus !== "ERROR"));

  // POPOVER VARIABLES
  let shouldShowError = (isSuspended || isError) && isCurrentStep;
  let errorMessage = null;
  if (shouldShowError) errorMessage = getErrorMessage(stageData);
  //
  let higienizacaoIds = getHigienizacaoIds(stageData);

  const buttonText =
    shouldShowError && higienizacaoIds !== null
      ? "Executar novamente"
      : "Avançar etapa";

  const tooltipText = showTotal
    ? `Total: ${JSON.parse(currentStage?.data)?.total}`
    : showLeadCount
    ? `Quantidade de leads: ${leadCount ?? 0}`
    : undefined;

  const button = (
    <Tooltip label={tooltipText}>
      <Center
        borderRadius="90px"
        bg={isPassed ? "primary.300" : undefined}
        color={isPassed ? "#fff" : undefined}
        w="45px"
        h="45px"
        border={
          isCurrentStep ? undefined : "3px solid var(--chakra-colors-gray-200)"
        }
        pos="relative"
      >
        <Text>{index + 1}</Text>
        {isCurrentStep ? (
          <Box
            inset="0"
            border={
              isError || isSuspended
                ? "3px dashed var(--chakra-colors-secondary-400)"
                : "3px dashed var(--chakra-colors-primary-200)"
            }
            animation={isRunning ? "spin 5s linear infinite" : undefined}
            pos="absolute"
            borderRadius="90px"
          />
        ) : null}
      </Center>
    </Tooltip>
  );

  return (
    <Fragment key={pipelineStage}>
      {!(index === 0) ? (
        <Box
          width="30px"
          height="2px"
          marginRight="12px"
          bg={
            isPassed
              ? "var(--chakra-colors-primary-200)"
              : "var(--chakra-colors-gray-200)"
          }
        />
      ) : null}

      <Flex alignItems="center" mr={isLast ? undefined : "12px"} fontSize={14}>
        {openPopover ? (
          <Popover position="right-bottom" title={title} button={button}>
            <Flex
              flexDir="column"
              p="0 0 0px 0"
              overflow="overlay"
              maxH="250px"
              maxW="350px"
              sx={defaultScroll}
            >
              {shouldShowError && pipelineStage !== "HIGIENIZACAO" ? (
                <UnorderedList flexDir="column" mb="6px">
                  <ListItem>
                    <Text fontWeight="semibold">Erros:</Text>
                  </ListItem>
                  <UnorderedList>
                    {errorMessage?.map((message) => (
                      <ListItem wordBreak="break-word" whiteSpace="normal">
                        <Text color="red.500">{message}</Text>
                      </ListItem>
                    ))}
                  </UnorderedList>
                </UnorderedList>
              ) : null}

              {PIPELINE_STAGES_WITH_CONTENT.includes(pipelineStage) ? (
                <ContentPopoverPipelineStage
                  modalData={modalData}
                  pipelineStage={currentStage?.pipelineStage!}
                />
              ) : null}

              {["FILTRO"].includes(pipelineStage) ? (
                <>
                  <UnorderedList mb="8px">
                    <ListItem>{tooltipText}</ListItem>
                  </UnorderedList>
                  <Box
                    w="100%"
                    bg="gray.100"
                    border={"1px solid var(--chakra-colors-gray-200)"}
                    p="4px"
                    borderRadius="4px"
                    mb="8px"
                  >
                    <Text fontSize="12" whiteSpace="pre-wrap">
                      <Text as="span" fontWeight="bold">
                        Atenção:
                      </Text>
                      <br />
                      Se preferir você pode editar os filtros ou{" "}
                      {buttonText.toLocaleLowerCase()}
                    </Text>
                  </Box>
                </>
              ) : null}

              <Button
                pos="sticky"
                bottom="0"
                minH="40px"
                leftIcon={shouldShowError ? <FaRedo /> : <FaPlay />}
                w="100%"
                onClick={
                  shouldShowError
                    ? () =>
                        replayPipeline({
                          higienizacaoIds,
                          row,
                          setIsLoading,
                        })
                    : () =>
                        advancePipeline({
                          row,
                          setActiveStep,
                          setIsLoading,
                        })
                }
              >
                {buttonText}
              </Button>
            </Flex>
          </Popover>
        ) : (
          button
        )}
        <Text ml="8px">{title}</Text>
      </Flex>
    </Fragment>
  );
}
