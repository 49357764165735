import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { Checkbox } from "components/checkbox";
import { CustomModal } from "components/custom-modal";
import { Dropdown, DropdownOptionProps } from "components/dropdown";
import { InputField } from "components/input-field";
import { useLayoutEffect, useState } from "react";
import {
  higienizadorConveniosOptions,
  saveOrUpdateUsuarioBanco,
} from "./utils";
import { makeEvent, useEventListener } from "services/events";
import { UsuarioBanco } from "../page";
import { dropdownBancos } from "../consts";
import { DropdownField } from "components/dropdown-field";

export interface UsuarioBancoErrors {
  banco: boolean;
  username: boolean;
  password: boolean;
  cpfUsuarioCertificado: boolean;
  nomeUsuarioCertificado: boolean;
  codigoDigitador: boolean;
  codigoPromotora: boolean;
  tokenModulo: boolean;
  botIdCronowise: boolean;
  nomePromotora: boolean;
}

const initialErrorsValue = {
  banco: false,
  username: false,
  password: false,
  cpfUsuarioCertificado: false,
  nomeUsuarioCertificado: false,
  codigoDigitador: false,
  codigoPromotora: false,
  tokenModulo: false,
  botIdCronowise: false,
  nomePromotora: false,
};

const initialModalDataValue: UsuarioBanco = {
  id: "",
  banco: "",
  username: "",
  password: "",
  cpfUsuarioCertificado: "",
  nomeUsuarioCertificado: "",
  codigoDigitador: "",
  tokenModulo: "",
  botIdCronowise: "",

  ativoDigitador: false,
  ativoHigienizador: false,
  ativoHigienizadorConvenios: false,
};

export function UsuariosBancosModal() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [modalData, setModalData] = useState<UsuarioBanco>(
    initialModalDataValue
  );
  const [errors, setErrors] = useState<UsuarioBancoErrors>(initialErrorsValue);
  const [isUpdate, setIsUpdate] = useState(false);

  const allFalse =
    !modalData.ativoDigitador &&
    !modalData.ativoHigienizador &&
    !modalData.ativoHigienizadorConvenios;

  let dropdownSelectUserType: string[] = [];
  if (modalData.ativoDigitador) dropdownSelectUserType.push("DIGITADOR");
  if (modalData.ativoHigienizador) dropdownSelectUserType.push("REFIN_SAQUE");
  if (modalData.ativoHigienizadorConvenios)
    dropdownSelectUserType.push("MARGEM_CONVENIOS");

  const onOpen = (props?: UsuarioBanco) => {
    setIsOpen(true);
    setIsUpdate(!!props);
    setModalData(props ?? initialModalDataValue);
  };

  const onClose = () => {
    setIsOpen(false);
    setErrors(initialErrorsValue);
  };

  const onChangeUsuarioBancoDialog = (
    field: keyof UsuarioBanco,
    value: any
  ) => {
    setErrors({ ...errors, [field]: false });
    const newValue = { ...modalData, [field]: value };
    setModalData(newValue);
    console.log(newValue);
  };

  useEventListener("openModalUsuariosBancos", onOpen);

  const usuarioBancoDialogFooter = (
    <>
      <Button
        leftIcon={<CheckIcon />}
        onClick={() =>
          saveOrUpdateUsuarioBanco({
            errors,
            modalData,
            setIsLoading,
            onClose,
            setErrors,
          })
        }
        isLoading={isLoading}
        loadingText="Salvando"
      >
        Salvar
      </Button>
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
        onClick={onClose}
      >
        Cancelar
      </Button>
    </>
  );

  const dropdownUserType: DropdownOptionProps[] = [
    {
      name: "Refin e Saque",
      value: "REFIN_SAQUE",
      isDisabled: () => !!modalData.ativoHigienizadorConvenios,
    },
    {
      name: "Digitador",
      value: "DIGITADOR",
      isDisabled: () => !!modalData.ativoHigienizadorConvenios,
    },
    {
      name: "Margem Convênios",
      value: "MARGEM_CONVENIOS",
      isDisabled: () => modalData.ativoDigitador || modalData.ativoHigienizador,
    },
  ];

  const getNameUserType = (value: string) =>
    dropdownUserType.find((c) => c.value === value)?.name;

  return (
    <CustomModal
      isOpen={isOpen}
      minW="450px"
      modalTitle="Usuário Banco"
      modalFooter={usuarioBancoDialogFooter}
      onClose={onClose}
      containerProps={{
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box mb="15px">
        <DropdownField
          options={dropdownUserType}
          value={dropdownSelectUserType}
          onChange={(value) => {
            if (value === "REFIN_SAQUE") {
              onChangeUsuarioBancoDialog(
                "ativoHigienizador",
                !modalData.ativoHigienizador
              );
            }
            if (value === "DIGITADOR") {
              onChangeUsuarioBancoDialog(
                "ativoDigitador",
                !modalData.ativoDigitador
              );
            }
            if (value === "MARGEM_CONVENIOS") {
              onChangeUsuarioBancoDialog(
                "ativoHigienizadorConvenios",
                !modalData.ativoHigienizadorConvenios
              );
            }
          }}
          title="Tipo Usuário"
          dropdownProps={{
            w: "100%",
            multiSelect: true,
            children: dropdownSelectUserType
              .map((v) => getNameUserType(v))
              .join(", "),
          }}
        />
      </Box>

      {allFalse ? null : modalData.ativoHigienizador ||
        modalData.ativoDigitador ? (
        <RefinSaqueAndDigitadorFields
          modalData={modalData}
          onChangeUsuarioBancoDialog={onChangeUsuarioBancoDialog}
          errors={errors}
          isUpdate={isUpdate}
        />
      ) : (
        <MargemConveniosFields
          modalData={modalData}
          onChangeUsuarioBancoDialog={onChangeUsuarioBancoDialog}
          errors={errors}
        />
      )}
    </CustomModal>
  );
}

export function RefinSaqueAndDigitadorFields({
  modalData,
  onChangeUsuarioBancoDialog,
  errors,
  isUpdate,
}: {
  modalData: UsuarioBanco;
  onChangeUsuarioBancoDialog: (
    field: keyof UsuarioBancoErrors,
    value: string
  ) => void;
  errors: UsuarioBancoErrors;
  isUpdate: boolean;
}) {
  return (
    <>
      <Box mb="15px">
        <DropdownField
          title="Banco"
          value={modalData.banco}
          onChange={(value) => onChangeUsuarioBancoDialog("banco", value)}
          options={
            isUpdate
              ? dropdownBancos
              : dropdownBancos.filter((crr) => crr.value !== "CETELEM")
          }
          dropdownProps={{ placeholder: "Selecione", w: "full" }}
          isInvalid={errors.banco}
          errorMessage={errors.banco ? "Selecione um banco" : undefined}
        />
      </Box>
      <Box mb="15px">
        <InputField
          title="Usuário"
          value={modalData.username}
          onChange={(e) =>
            onChangeUsuarioBancoDialog("username", e.target.value)
          }
          errorMessage={errors.username ? "Informe um usuário" : undefined}
        />
      </Box>
      <Box mb="15px">
        <InputField
          title="Senha"
          value={modalData.password}
          onChange={(e) =>
            onChangeUsuarioBancoDialog("password", e.target.value)
          }
          errorMessage={errors.password ? "Informe uma senha" : undefined}
        />
      </Box>
      {modalData.banco === "BANRISUL" ? (
        <Box mb="15px">
          <InputField
            title="Token Módulo"
            value={modalData.tokenModulo}
            onChange={(e) =>
              onChangeUsuarioBancoDialog("tokenModulo", e.target.value)
            }
            errorMessage={
              errors.tokenModulo ? "Informe um token módulo" : undefined
            }
          />
        </Box>
      ) : null}
      {modalData.banco === "PAN" ? (
        <Box mb="15px">
          <InputField
            title="Código Promotora"
            value={modalData.codigoPromotora}
            onChange={(e) =>
              onChangeUsuarioBancoDialog(
                "codigoPromotora",
                e.target.value.trim()
              )
            }
            errorMessage={
              errors.codigoPromotora
                ? "Informe o código da promotora"
                : undefined
            }
          />
        </Box>
      ) : null}
      {modalData.banco === "SANTANDER" ? (
        <Box mb="15px">
          <InputField
            title="Nome Promotora"
            value={modalData.nomePromotora}
            onChange={(e) =>
              onChangeUsuarioBancoDialog("nomePromotora", e.target.value.trim())
            }
            errorMessage={
              errors.nomePromotora ? "Informe o nome da promotora" : undefined
            }
          />
        </Box>
      ) : null}
      {modalData.ativoDigitador &&
      ![
        "BMG",
        "MASTER",
        "VCTEX",
        "LOTUS_TAYA",
        "SANTANDER",
        "BRADESCO_DIGIO",
      ].includes(modalData.banco) ? (
        <>
          <Box mb="15px">
            <InputField
              title={
                modalData.banco === "PAN"
                  ? "CPF Usuário Certificado"
                  : modalData.banco === "C6" && modalData.ativoDigitador
                  ? "Código Digitador"
                  : "Login Certificado"
              }
              value={modalData.cpfUsuarioCertificado}
              onChange={(e) =>
                onChangeUsuarioBancoDialog(
                  "cpfUsuarioCertificado",
                  e.target.value
                )
              }
              errorMessage={
                errors.cpfUsuarioCertificado
                  ? `Informe um ${
                      modalData.banco === "PAN"
                        ? "CPF Usuário Certificado"
                        : modalData.banco === "C6" && modalData.ativoDigitador
                        ? "Código Digitador"
                        : "Login Certificado"
                    }`
                  : undefined
              }
            />
          </Box>
          {modalData.banco === "PAN" ? (
            <>
              <Box mb="15px">
                <InputField
                  title="Nome Usuário Certificado"
                  value={modalData.nomeUsuarioCertificado || ""}
                  onChange={(e) =>
                    onChangeUsuarioBancoDialog(
                      "nomeUsuarioCertificado",
                      e.target.value
                    )
                  }
                  errorMessage={
                    errors.nomeUsuarioCertificado
                      ? "Informe um Nome Usuário Certificado"
                      : undefined
                  }
                />
              </Box>
              <Box mb="15px">
                <InputField
                  title="Código Digitador"
                  value={modalData.codigoDigitador}
                  onChange={(e) =>
                    onChangeUsuarioBancoDialog(
                      "codigoDigitador",
                      e.target.value
                    )
                  }
                  errorMessage={
                    errors.codigoDigitador
                      ? "Informe um Código Digitador"
                      : undefined
                  }
                />
              </Box>
            </>
          ) : null}
        </>
      ) : null}
    </>
  );
}

export function MargemConveniosFields({
  modalData,
  onChangeUsuarioBancoDialog,
  errors,
}: {
  modalData: UsuarioBanco;
  onChangeUsuarioBancoDialog: (
    field: keyof UsuarioBancoErrors,
    value: string
  ) => void;
  errors: UsuarioBancoErrors;
}) {
  return (
    <>
      <Box mb="15px">
        <InputField
          title="Usuário"
          value={modalData.username}
          onChange={(e) =>
            onChangeUsuarioBancoDialog("username", e.target.value)
          }
          errorMessage={errors.username ? "Informe um usuário" : undefined}
        />
      </Box>
      <Box mb="15px">
        <InputField
          title="Senha"
          value={modalData.password}
          onChange={(e) =>
            onChangeUsuarioBancoDialog("password", e.target.value)
          }
          errorMessage={errors.password ? "Informe uma senha" : undefined}
        />
      </Box>

      <Box mb="15px">
        <Text mb="8px">Convenios</Text>
        <DropdownField
          value={modalData.botIdCronowise}
          onChange={(value) =>
            onChangeUsuarioBancoDialog("botIdCronowise", value)
          }
          options={higienizadorConveniosOptions}
          dropdownProps={{ w: "100%" }}
          errorMessage={
            errors.botIdCronowise ? "Informe um convênio" : undefined
          }
        />
      </Box>
    </>
  );
}

export const openModalUsuariosBancos = (props?: UsuarioBanco) =>
  makeEvent("openModalUsuariosBancos", props);
