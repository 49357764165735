import { Icon, IconProps } from "@chakra-ui/icons";

export function PopCashLogo({ ...rest }: IconProps) {
  return (
    <Icon
      width="80%"
      height="auto"
      viewBox="0 0 954 680"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M445 119.1C445 118.6 445.6 117.9 446.3 117.7C447.3 117.3 447.3 117.1 446.3 117.1C445.4 117 445 115.4 445 112.4C445 108.7 444.7 107.9 443.5 108.4C442.5 108.7 442 108.3 442 107.2C442 104.9 449.3 102.5 460.7 100.8C468.9 99.6 474.6 100.3 470.5 102C469.1 102.6 470.1 102.9 473.9 102.9C478.9 103 479.2 103.1 478.6 105.3C478.3 106.5 478 108.6 478 110C478 112.4 477.5 112.7 470.9 113.8C466.9 114.5 460.4 116.2 456.3 117.5C448.6 120.1 445 120.6 445 119.1Z"
        fill="#8E1124"
      />
      <path
        d="M3.5 675.8C2.4 675.2 1.2 674.2 0.8 673.6C0.4 673 0 631.2 0 580.8V489.1L3 487.5C6.1 485.9 22.5 485.3 24.6 486.8C26.6 488.1 27.8 491.4 29 499.2L30.3 506.9L37.5 500.2C49.8 488.7 61.2 484 76.8 484C104.4 484 129.8 499.8 142.1 524.5C148.4 537.4 150.4 546.9 149.7 562.5C149.1 577.4 147.6 583.3 140.9 596.1C134.9 607.6 122.8 619.8 111.5 625.7C100 631.7 89.7 634.3 77.5 634.4C61.5 634.5 51.2 630.4 37.8 618.8L32 613.9V643.9C32 679.9 33.4 677 16.5 677C10.2 677 4.6 676.5 3.5 675.8ZM92.4 601.2C96.5 599.3 101.1 596.1 104.3 592.8C132.8 563.9 114.2 514 74.9 514C53.2 514 34.5 530.2 30.7 552.4C27.6 570.8 38.1 591.5 55 600C63.3 604.2 67.1 605 77.2 604.6C84.3 604.3 87 603.7 92.4 601.2Z"
        fill="#CC0518"
      />
      <path
        d="M310.9 675.1L308.5 673.2V581.6C308.5 495.1 308.6 490 310.3 488.3C311.7 486.9 314.1 486.4 322.3 486.2C334.9 485.8 335.4 486.1 337.7 498.4L339.3 507L346.4 500.3C358.5 488.8 370.2 484 386.1 484C419.1 484 448.6 507.6 457 540.8C457.9 544.4 458.4 544.9 459.6 544C460.7 543 461 543.2 461 545.2C461 546.8 459.5 548.8 456.4 551.4C446.5 559.7 438.3 573.7 434.3 588.9C432.1 597.4 431.9 599.8 432.5 609.7C432.9 617.1 432.8 621 432.1 621.5C431.5 621.8 431 621.4 431 620.5C431 619.3 430 619.6 426.4 622C415 629.9 400.9 634.2 386 634.3C370.6 634.5 358.8 629.8 346.8 619L341 613.8V643.4C341 679.8 342.3 677 325.1 677C314.9 677 312.9 676.7 310.9 675.1ZM401.3 601.1C434.8 585.1 435.3 534.6 402.2 518.3C394.8 514.6 394.3 514.5 383.6 514.5C373.2 514.5 372.1 514.7 365.7 517.7C349.8 525.2 339.1 541.9 339 559C338.9 578.7 351.5 596.2 370.5 603C379.7 606.2 392.2 605.4 401.3 601.1Z"
        fill="#CC0518"
      />
      <path
        d="M215 633.6C199 630.1 188.2 624.2 176.5 612.5C161.1 597.1 155.1 583.6 154.2 562.4C153.8 552.4 154.1 548.9 155.9 541.9C162.6 515.2 181.7 495.3 209 486.9C215.3 484.9 218.6 484.5 229.5 484.5C241.5 484.6 243.2 484.8 251.9 487.8C278 496.8 295.5 515.7 302.1 541.9C303.9 549 304.2 552.3 303.8 562.4C302.9 583.6 296.9 597.1 281.5 612.6C273.8 620.3 271 622.4 263 626.3C257.8 628.9 250.8 631.7 247.5 632.6C239.1 634.8 223 635.3 215 633.6ZM243.8 602.2C270.1 592.1 279.8 557.2 263.3 532.3C256.6 522.2 246.5 515.7 235.3 514.2C221.8 512.4 210.1 516 200.4 525.2C190.5 534.6 186 545.2 186 559.5C186 581.3 198.2 597.9 218.3 603.5C225.1 605.4 236.9 604.8 243.8 602.2Z"
        fill="#CC0518"
      />
      <path
        d="M574 240.2C574 239.6 574.6 239 575.3 238.7C575.9 238.5 570.9 238.3 564 238.3C532.6 238.2 504.6 226.9 481.8 205.2C460.5 184.8 447.2 156.8 445.4 128L444.7 118.3L450.6 116.2C457.8 113.6 466.8 111.2 472.9 110.4L477.5 109.8L477.7 121.2C478.2 151.6 493.6 178.1 520.2 194.2C533.3 202.2 545.4 205.4 562.5 205.5C579.3 205.5 584.8 204.6 586.2 201.5C586.9 200 589.4 198.4 593.9 196.6C597.6 195.1 604.1 191.7 608.2 189C612.4 186.2 616.3 184 616.9 184C619.5 184 617.6 186.7 613 189.9C607.3 193.8 606.4 195 611.3 192.3C613 191.3 619 186 624.5 180.5C636 169 641.1 160.8 646.1 146C650.2 133.7 651.2 116.2 648.6 102.9C637.4 47.8 577.7 17.6 527.1 41.5C516.7 46.5 508.4 52.6 499.8 62L492.3 70L483.4 70.1C478.5 70.2 475.3 70.4 476.3 70.7C477.2 70.9 478 71.7 478 72.4C478 73.2999 475 73.9 467.4 74.2999C456.9 75 452.7 74.4 456.6 72.9C458.3 72.2 458.3 72.1 456.7 72.1C455.1 72 455.5 70.9 458.9 64.3C475.9 31.7 508.1 8.59995 545.2 2.39995C565.7 -1.00005 589.7 1.79995 609.3 9.99995C663.9 32.7 694.2 93.9 678.7 150C669.4 183.2 646.2 211.8 616.8 226.1C613.8 227.5 612 229.1 611.8 230.4C611.4 233 608.7 234.3 597.1 237.4C586.3 240.3 574 241.8 574 240.2ZM603 197C605.2 195.9 607 194.7 607 194.5C607 194 599.1 197.7 598.1 198.6C597 199.6 599.5 198.8 603 197Z"
        fill="#CC0518"
      />
      <path
        d="M471 323.6C471 317.5 470.7 316 469.5 316C468.7 316 468 315.3 468 314.5C468 313.2 470.5 313 484.9 313C495.2 313 502.1 313.4 502.5 314C502.8 314.6 502.3 315 501.3 315.1C500.3 315.1 500 315.4 500.8 315.7C501.5 315.9 502 317.9 502 320.1C502 323.5 501.7 324 499.8 324.1C497.7 324.1 497.7 324.2 499.4 324.9C502.5 326.1 499.4 327.4 493 327.6C490 327.7 486.8 328 486 328.3C485.2 328.6 482.9 329.1 481 329.4C479.1 329.6 476 330.2 474.3 330.6L471 331.3V323.6ZM489.8 325.3C488.8 325.1 487.2 325.1 486.3 325.3C485.3 325.6 486.1 325.8 488 325.8C489.9 325.8 490.7 325.6 489.8 325.3Z"
        fill="#CC7E17"
      />
      <path
        d="M570.4 245.4C574.2 239 574.6 238.8 583.7 237.3C588.5 236.6 596.9 234.4 602.3 232.6L612.2 229.2L611.5 231.9C611.1 233.3 609.9 236.6 608.9 239.1L606.9 243.7L599.8 245C595.9 245.7 590 246.4 586.6 246.6C583.2 246.9 577.9 247.3 574.6 247.7L568.7 248.3L570.4 245.4ZM583.8 244.3C582.8 244.1 581.2 244.1 580.3 244.3C579.3 244.6 580.1 244.8 582 244.8C583.9 244.8 584.7 244.6 583.8 244.3Z"
        fill="#CC7E17"
      />
      <path
        d="M486.5 677C458.7 669.7 438.4 649 431.1 620.6C424.1 593.7 435.2 562 457.5 545.3C481.8 527.1 511.7 523.8 537.5 536.4C546.6 540.9 553 546.4 553 549.6C553 553.4 541.4 570 538.9 570C537.7 570 534.1 568.2 530.9 566.1C510.9 552.7 489.2 554.6 473.5 571.1C451.4 594.2 458.1 632.6 486.7 646.7C492.1 649.3 493.3 649.5 504.5 649.5C516.1 649.5 516.7 649.4 522.5 646.3C525.8 644.5 530.5 641.7 533 640.1C540.1 635.2 540.5 635.4 550 649.2C554.7 656 555.2 657.3 554.3 659.3C552.1 664.1 534.3 674 522.2 677.1C512.3 679.6 496.2 679.6 486.5 677Z"
        fill="#EDA53E"
      />
      <path
        d="M609.1 677.1C586 671.2 566.1 654.2 557 632.5C539.5 590.4 563 543.4 607.7 530.8C617.8 528 633.7 527.8 642.5 530.4C651.2 533 660.7 538.3 666.9 544C669.9 546.7 672.5 548.8 672.7 548.6C672.9 548.4 673.5 545.5 674 542.2C675.4 532.1 677.6 530 686.8 530C694.1 530 698.7 531.1 700.9 533.2C703 535.1 703 535.7 703 603.9C703 685.2 704.8 676.3 687.9 676.8C677.6 677.2 677 677.1 674.7 674.8C672.9 673 672.1 670.8 671.6 666.1L670.9 659.9L667.2 663.2C661.3 668.5 653.6 673 645.8 675.8C635.9 679.2 619.9 679.8 609.1 677.1ZM645.2 644.7C673.7 630.6 679.7 593.5 657 571C631.3 545.5 590 559.5 583.8 595.9C580.8 613.5 588.5 632.4 602.7 642.1C610.6 647.6 616.2 649.1 627.6 648.7C636.9 648.5 638.2 648.2 645.2 644.7Z"
        fill="#EDA53E"
      />
      <path
        d="M750.5 678C743.4 676.6 734.7 673.1 729.3 669.5C719 662.7 710.2 651.4 711.4 646.6C712 644 727.1 632 729.7 632C730.5 632 733.6 634.6 736.6 637.8C742.3 644.1 750 648.5 757.3 649.9C767.5 651.8 781.4 647.6 785.1 641.6C787.9 637 787.6 629.9 784.5 626.2C781.2 622.3 777.7 620.7 762.9 616.5C731.8 607.6 717.7 594.5 716.3 573.1C714.6 548.4 732.9 530.4 761.9 528.3C772.7 527.6 782.9 529.2 791 533.1C798.4 536.6 814 551.2 814 554.7C814 558 799.1 570.9 795.2 571C794.5 571 792.1 569 789.9 566.6C787.7 564.3 783.6 561 780.7 559.4C776.3 557 774.3 556.6 768 556.6C755.8 556.5 748 562 748 570.5C748 574.2 748.5 575.2 751.9 578.3C755.1 581.2 758.7 582.7 770.7 586.5C795.3 594.3 806.1 600.4 813.1 610.6C821.1 622.3 822.2 639 815.8 652.8C812.9 659.1 802.8 669 796 672.3C783.9 678.2 764 680.7 750.5 678Z"
        fill="#EDA53E"
      />
      <path
        d="M832 676.3C827.9 674.9 828 676.2 828 582.3V491.9L830.5 489.5C832.8 487.1 833.5 487 844.5 487C855.5 487 856.2 487.1 858.5 489.5L861 491.9V518.9V545.9L865.3 541.7C879.6 527.8 906.6 524.7 925.4 534.8C937.7 541.3 948.1 556 952 572.1C953.8 579.9 954 583.9 954 626.6C954 664.8 953.8 672.9 952.6 674.6C951.3 676.3 949.9 676.5 938.1 676.5C928.8 676.5 924.7 676.1 923.8 675.2C922.8 674.2 922.4 663.8 922 628.2L921.5 582.5L919 577.5C916 571.4 909.9 565.1 904.5 562.5C902.1 561.4 897.6 560.4 893.3 560.2C882.9 559.6 877 561.8 869.8 569C861 577.9 861 577.7 861 627C861 670 860.7 674 857 676C855.1 677 834.7 677.3 832 676.3Z"
        fill="#EDA53E"
      />
      <path
        d="M406.2 420C394.5 416.9 383.7 410.7 375.1 402C366.6 393.5 362 386.4 358.4 376.2C345 337.6 366.2 298.1 406.6 286.4C411.6 285 420 284.6 463 284.1L513.5 283.4L522 280.6C532.5 277.1 540.7 273.1 548.5 267.5C554.4 263.3 565.9 251.9 569.3 247.1C570.8 244.9 571.6 244.6 577.7 244.7C581.5 244.7 585 244.5 585.5 244.2C586.4 243.6 590.2 243.2 595.7 243C596.9 243 598.2 242.5 598.5 242C598.8 241.5 599.7 241.3 600.5 241.6C601.3 241.9 602.5 241.8 603.2 241.4C603.9 241 605.3 240.5 606.2 240.5C608.6 240.3 608 242.3 602.8 251.9C588.3 278.4 563.3 299.3 534.2 309.1C516.9 315 513.7 315.3 461 315.9L412.5 316.5L406.9 319.3C400.2 322.6 391.7 331.2 388.8 337.6C381.3 354.6 387.4 375.1 402.8 384.8C411.3 390.3 415.9 391 441.2 391C455.7 391 464.9 390.6 466.2 389.9C470.5 387.6 471 383.7 471 355C471 328.9 471.1 328 472.9 328C474 328 475.2 327.6 475.5 327C475.8 326.5 477.4 326.3 478.9 326.6C480.5 326.9 482 326.7 482.4 326.2C482.7 325.6 484.5 325.4 486.4 325.7C488.2 326 490.7 325.7 491.9 325C493.3 324.3 495.7 324.1 498.1 324.5L502.1 325.2L501.8 363.8C501.5 406.5 501.6 405.9 494.9 413.5C493 415.6 489.3 418.4 486.5 419.6C481.6 422 481.1 422 447.5 421.9C417.4 421.9 412.7 421.7 406.2 420Z"
        fill="#EDA53E"
      />
      <path
        d="M362 272.8C360.2 271.5 357.7 269.2 356.6 267.7C354.5 264.9 354.5 263.9 354.5 222.7C354.6 176.9 354.9 172.6 360.6 155.3C370.1 126 392 100.2 420 85.1C430.2 79.7 446.7 74.3 459.2 72.4C472.5 70.4 509.8 71 520.3 73.4C573.5 85.7 611.2 127.5 617.4 181L618.1 186.5L611.8 190.7C604.3 195.6 598.4 198.6 591.5 201L586.5 202.7L586.4 191.1C586 150.6 558.5 115.8 518.5 105C508.3 102.3 479.2 101.1 465.5 102.9C445.9 105.5 427.3 114.4 413.7 127.8C401.8 139.4 394.8 150.7 389.4 167L386.6 175.5L386 219.6L385.5 263.7L382.8 267.7C378.1 274.9 368.5 277.2 362 272.8Z"
        fill="#EDA53E"
      />
    </Icon>
  );
}
