import { InfoIcon, WarningIcon } from "@chakra-ui/icons";
import { Button, ListItem, UnorderedList } from "@chakra-ui/react";
import { Popover } from "components/popover";

export type ErrorMessageList = { errorTitle: string; errorList: string[] }[];
export type InfoMessageList = { messageTitle: string; messageList: string[] }[];

export interface ErrorMessageProps {
  isError: boolean;
  errorMessage?: string | ErrorMessageList;
}

export function ErrorMessage({ isError, errorMessage }: ErrorMessageProps) {
  const isErrorList = Array.isArray(errorMessage);

  return isError ? (
    <Popover
      button={
        <Button
          leftIcon={<WarningIcon />}
          size="sm"
          h="28px"
          border="1px solid #FFD6D6"
          bg="#FFF3F3"
          color="#B21F17"
          fontSize={{ base: "13", "2xl": "14" }}
          _hover={{ bg: "#FFD6D6" }}
        >
          Ver Erros
        </Button>
      }
      position="left-bottom"
      popupStyles={{
        width: `320px`,
        background: "#FFF3F3",
        border: "1px solid #FFD6D6",
        color: "#B21F17",
        overflow: "auto",
        maxHeight: "200px",
      }}
    >
      {isErrorList ? (
        <>
          {errorMessage.map((error, index) => {
            return (
              <UnorderedList key={index}>
                <ListItem fontWeight="semibold">{error.errorTitle}</ListItem>
                <ListItem listStyleType="none" mb="8px">
                  <UnorderedList>
                    {error.errorList.map((error, i) => {
                      return (
                        <ListItem key={i} fontWeight="medium">
                          {error}
                        </ListItem>
                      );
                    })}
                  </UnorderedList>
                </ListItem>
              </UnorderedList>
            );
          })}
        </>
      ) : (
        <UnorderedList>
          <ListItem fontWeight="semibold">{errorMessage}</ListItem>
        </UnorderedList>
      )}
    </Popover>
  ) : null;
}

export interface InfoMessageProps {
  isMessage: boolean;
  infoMessage?: string | InfoMessageList;
}

export function InfoMessage({ isMessage, infoMessage }: InfoMessageProps) {
  const isErrorList = Array.isArray(infoMessage);

  return isMessage ? (
    <Popover
      button={
        <Button
          leftIcon={<InfoIcon />}
          size="sm"
          h="28px"
          bg="primary.500"
          color="#fff"
          fontSize={{ base: "13", "2xl": "14" }}
          _hover={{ bg: "primary.400" }}
        >
          Ver Informações
        </Button>
      }
      position="left-bottom"
      popupStyles={{ width: `320px`, overflow: "auto", maxHeight: "200px" }}
    >
      {isErrorList ? (
        <>
          {infoMessage.map((error, index) => {
            return (
              <UnorderedList key={index}>
                <ListItem fontWeight="semibold">{error.messageTitle}</ListItem>
                <ListItem listStyleType="none" mb="8px">
                  <UnorderedList>
                    {error.messageList.map((error, i) => {
                      return (
                        <ListItem key={i} fontWeight="medium">
                          {error}
                        </ListItem>
                      );
                    })}
                  </UnorderedList>
                </ListItem>
              </UnorderedList>
            );
          })}
        </>
      ) : (
        <UnorderedList>
          <ListItem fontWeight="semibold">{infoMessage}</ListItem>
        </UnorderedList>
      )}
    </Popover>
  ) : null;
}
