import { SVGProps } from "react";

export function BordaTriangular1({ ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15.428px"
      height="36px"
      viewBox="0 0 60 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M60 0L0 140V0H60Z" fill="currentColor" />
    </svg>
  );
}
export function BordaTriangular2({ ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="15.428px"
      height="36px"
      viewBox="0 0 60 140"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path d="M0 0L60 140V0H0Z" fill="currentColor" />
    </svg>
  );
}
