import { Icon, IconProps } from "@chakra-ui/react";

export function MendesPromotora({ ...rest }: IconProps) {
  return (
    <Icon
      width="100%"
      height="auto"
      viewBox="0 0 3191 2154"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M410 1964.5V1859H557H704V1880V1901H578H452V1964.5V2028H578H704V2049V2070H557H410V1964.5Z"
        fill="#404070"
      />
      <path
        d="M747 1964.5V1859H894H1041V1964.5V2070H894H747V1964.5ZM1000 1964.5V1901H894.5H789V1964.5V2028H894.5H1000V1964.5Z"
        fill="#404070"
      />
      <path
        d="M1083 1964.5V1859H1103.8H1124.5L1229.5 1938C1287.3 1981.4 1334.8 2017 1335.3 2017C1335.7 2017 1336 1981.5 1336 1938V1859H1356.5H1377V1964.5V2070H1356.6H1336.2L1231.1 1991C1173.3 1947.6 1125.8 1912 1125.5 1912C1125.2 1912 1125 1947.6 1125 1991V2070H1104H1083V1964.5Z"
        fill="#404070"
      />
      <path
        d="M1420 1964.5V1859H1567H1714V1880V1901H1588H1462V1922.5V1944H1588H1714V1965V1986H1588H1462V2007V2028H1588H1714V2049V2070H1567H1420V1964.5Z"
        fill="#404070"
      />
      <path
        d="M1757 1964.5V1859H1904H2051V1880V1901H1925H1799V1964.5V2028H1925H2051V2049V2070H1904H1757V1964.5Z"
        fill="#404070"
      />
      <path
        d="M2220 1985.5V1901H2156.5H2093V1880V1859H2240H2387V1880V1901H2324H2261V1985.5V2070H2240.5H2220V1985.5Z"
        fill="#404070"
      />
      <path
        d="M2975 1647C2910.7 1642 2863.5 1621.8 2819.1 1580.3L2810.7 1572.5L2834.5 1538C2847.6 1519 2859.2 1502.3 2860.2 1500.7L2862.2 1498L2868.8 1504.1C2896.1 1529.1 2949 1550.9 2994.2 1555.9C3026.8 1559.5 3055.9 1551.9 3072.7 1535.4C3081.3 1527 3085.2 1518.6 3085.8 1507.5C3087.3 1478.4 3065.5 1465.3 3008.5 1461.1C2924.4 1454.8 2870 1431.8 2843.2 1391C2821.9 1358.8 2819.6 1312.5 2837.5 1276.5C2844.1 1263.1 2850.3 1254.8 2861.5 1244.2C2893.8 1213.6 2947.1 1196 3007.1 1196C3071.2 1196 3119.6 1211.1 3160.4 1243.8C3164.2 1247 3168.1 1250 3168.9 1250.6C3170 1251.5 3165 1257.9 3141.5 1285.6C3125.7 1304.2 3112.6 1319.6 3112.5 1319.8C3112.3 1320 3107.6 1316.5 3101.9 1312C3084.6 1298.5 3064.5 1289.7 3041 1285.3C3029.5 1283.2 2995.8 1282.4 2983.3 1284C2956.9 1287.3 2939.2 1296.3 2931.9 1310.1C2929.8 1314 2929.5 1315.9 2929.5 1325.5C2929.5 1335.6 2929.7 1336.9 2932.3 1341.9C2938 1352.7 2949.4 1360.5 2966.5 1365.5C2978.5 1368.9 2989.7 1370.6 3013 1372.5C3051.1 1375.5 3076.6 1379.9 3099.6 1387.5C3125.5 1396 3143.9 1406.9 3159.2 1422.9C3173.3 1437.5 3181.6 1453.2 3186.7 1475.3C3189.1 1485.3 3189.4 1488.6 3189.4 1506C3189.5 1521.1 3189.1 1527.4 3187.7 1533.7C3180.3 1568.1 3161.3 1595.7 3131.5 1615.7C3109.7 1630.2 3082.1 1640.1 3049.4 1645C3033.4 1647.4 2994.2 1648.5 2975 1647Z"
        fill="#404070"
      />
      <path
        d="M956 1642.9C855.6 1631.9 786.8 1575.6 764 1485.6C757.7 1460.4 756.5 1450.4 756.5 1418.5C756.5 1387.3 757.5 1378.1 763.5 1354.5C780.7 1286.4 826.6 1234.7 891.3 1210.6C928.8 1196.6 976.6 1191.8 1020.5 1197.5C1114.2 1209.7 1174.1 1264.7 1192.4 1355.4C1197 1377.8 1198.3 1395.3 1197.8 1427.1C1197.5 1442.8 1197 1456.2 1196.6 1456.9C1196.1 1457.7 1153.7 1458 1029.9 1458C890 1458 864 1458.2 864 1459.4C864 1462.5 869 1479.9 871.4 1485.4C875.9 1495.6 884.5 1507.4 893.9 1516.2C910.5 1531.8 931.3 1541.5 958.7 1546.5C971.1 1548.8 997.2 1549.5 1010 1548.1C1045.9 1543.9 1082.1 1530.4 1099.8 1514.5L1105 1509.8L1138.5 1542.6L1172 1575.4L1168.3 1579.3C1148.3 1600 1109 1621.9 1072.9 1632.4C1061.7 1635.6 1043.9 1639.5 1031 1641.6C1018.7 1643.5 969.7 1644.4 956 1642.9ZM1095.4 1366.8C1094.2 1359.3 1090.1 1346.1 1086.9 1339.7C1069.1 1304.2 1031.4 1286.4 978.5 1288.3C956.5 1289.1 941.7 1292.5 924.2 1300.6C898.7 1312.3 878.7 1334.1 869 1360.9C867.3 1365.4 866 1369.5 866 1370C866 1370.7 907.3 1371 981 1371H1096L1095.4 1366.8Z"
        fill="#404070"
      />
      <path
        d="M2512 1642.9C2412.5 1632.6 2342.8 1575.5 2320 1485.6C2313.7 1460.4 2312.5 1450.4 2312.5 1418.5C2312.5 1387.3 2313.5 1378.1 2319.5 1354.5C2340.6 1271 2403.4 1214 2491 1199C2517.3 1194.5 2549.4 1194 2576.5 1197.5C2649.7 1207.1 2702.7 1242.9 2730.9 1302C2743 1327.5 2750 1354.5 2753.1 1388.4C2754.3 1401.7 2753.9 1454.7 2752.6 1456.9C2752.1 1457.7 2707.7 1458 2585.9 1458C2446 1458 2420 1458.2 2420 1459.4C2420 1462.8 2425.1 1480.2 2427.8 1486.2C2437.4 1507.5 2458.1 1526.9 2481.7 1536.9C2498.8 1544.2 2516.8 1547.9 2539.5 1548.7C2557.4 1549.3 2569.9 1548.2 2588.2 1544.4C2615.4 1538.7 2644.1 1525.7 2657.2 1513.2L2660.9 1509.7L2694.5 1542.6L2728 1575.4L2724.3 1579.3C2722.2 1581.4 2717.2 1585.9 2713.3 1589.1C2679.3 1617.1 2630.6 1636.4 2578.4 1642.5C2564.3 1644.1 2526.6 1644.4 2512 1642.9ZM2651.4 1366.8C2650.2 1359.3 2646.1 1346.1 2642.9 1339.7C2625.1 1304.2 2587.4 1286.4 2534.5 1288.3C2518 1288.9 2506.2 1290.9 2493.9 1295C2460.7 1306.2 2436.5 1329.4 2425 1360.9C2423.3 1365.4 2422 1369.5 2422 1370C2422 1370.7 2463.3 1371 2537 1371H2652L2651.4 1366.8Z"
        fill="#404070"
      />
      <path
        d="M1965.5 1642C1916.6 1637.6 1876.8 1622.2 1842.3 1594.3C1803.9 1563.3 1779.2 1517.2 1770.3 1460C1767.7 1443.6 1767.8 1395.7 1770.3 1378.5C1785.3 1277.8 1846.3 1215.5 1944.5 1200.4C1953.7 1199 1962.1 1198.5 1980.5 1198.5C2002.8 1198.6 2005.4 1198.7 2017.2 1201.3C2053.7 1209.1 2087.2 1225.5 2107.4 1245.3C2112 1249.8 2117.5 1256.2 2119.6 1259.4L2123.5 1265.3L2123.8 1147.7L2124 1030H2176.5H2229V1331.5V1633H2180.1H2131.3L2130.2 1624.3C2129.6 1619.4 2128.1 1607 2127 1596.5C2125.8 1586.1 2124.6 1576.9 2124.4 1576.1C2124.1 1575.3 2121.4 1578.2 2117.7 1583.1C2094.5 1614.4 2060.2 1634 2017.4 1640.5C2004.9 1642.4 1978.6 1643.1 1965.5 1642ZM2020.2 1542.9C2056.9 1535.5 2087.6 1512.4 2103.9 1480.2C2108.6 1471 2113.7 1454.9 2115.5 1444C2117.4 1431.7 2117.5 1408.9 2115.5 1397.4C2110.8 1370.6 2098.6 1347.2 2079.7 1329.3C2064.1 1314.5 2046.4 1304.9 2024.5 1299.4C2011 1295.9 1987.1 1295 1972.4 1297.3C1920.1 1305.6 1883.4 1342.6 1874.5 1395.9C1873.1 1404.1 1872.8 1410 1873.3 1424.9C1873.7 1441.4 1874.1 1444.8 1876.8 1455.3C1891.5 1512 1940.1 1547.8 1999 1545.4C2005.3 1545.1 2014.9 1544 2020.2 1542.9Z"
        fill="#404070"
      />
      <path
        d="M1 1420V1207H49.4H97.8L98.3 1209.3C98.6 1210.5 100.3 1221.6 102.1 1234C103.9 1246.4 105.6 1256.9 106 1257.3C106.4 1257.7 108.4 1255.3 110.5 1251.9C115.5 1243.7 126.6 1232.1 136.1 1225C171.4 1198.7 230.1 1192.7 275.5 1210.7C304.2 1222.1 325.3 1241.6 339.5 1269.9C342 1274.9 344.2 1279 344.4 1279C344.6 1279 347.9 1274.6 351.6 1269.3C360.5 1256.9 377 1239.7 387.9 1231.5C416.3 1210.3 451.4 1200.8 495.5 1202.3C519.5 1203.2 537.3 1206.1 556 1212.2C621.3 1233.5 658.7 1288.7 665.9 1374.3C666.6 1382.5 667 1431 667 1510.1V1633H614.5H562V1510.9C562 1405.7 561.8 1387.3 560.4 1378.3C554.4 1337.3 535.4 1311.7 504.5 1302.8C497.6 1300.8 494.3 1300.5 481 1300.6C467.2 1300.7 464.6 1300.9 456.7 1303.3C446.7 1306.4 440.1 1309.5 431.6 1315.3C409.7 1330.1 393.9 1358.5 390 1390C389.4 1395.4 389 1440.7 389 1515.7V1633H336.5H284V1512.8C284 1382.5 284 1383.5 278.5 1364.6C273 1345.8 264.6 1331.6 251.6 1319.3C242.1 1310.2 231.4 1304.3 218.5 1300.8C208.1 1298.1 187 1297.8 176.8 1300.3C140.1 1309.3 114.2 1341 107.5 1385C106.3 1392.9 106 1414.2 106 1513.8V1633H53.5H1V1420Z"
        fill="#404070"
      />
      <path
        d="M1275 1420V1207H1322H1368.9L1369.4 1210.3C1369.7 1212 1371.1 1223.4 1372.5 1235.5C1373.9 1247.6 1375.3 1259.2 1375.6 1261.3L1376.2 1265L1384.8 1257.2C1432 1214.3 1480 1196.6 1534.1 1202C1614.3 1210.2 1672.7 1265.7 1690.4 1350.5C1695.9 1377.2 1696 1379.2 1696 1511.3V1633H1644.1H1592.1L1591.7 1513.3C1591.4 1430 1590.9 1391.5 1590.1 1387C1579.9 1328.5 1544.1 1296.7 1488.5 1296.8C1457.7 1296.9 1433.6 1307.1 1412.4 1329.1C1396.7 1345.5 1386.5 1366.2 1381.4 1392C1379.9 1399.6 1379.6 1412.5 1379.3 1516.8L1378.9 1633H1326.9H1275V1420Z"
        fill="#404070"
      />
      <path
        d="M2598.5 2126.6C2583.5 2122.9 2569 2111 2562.9 2097.4C2558.5 2087.7 2558 2082.9 2558 2052.5C2558 2021.3 2558.6 2016.8 2563.3 2008.7C2566.6 2003.1 2570.4 1999.7 2576.5 1996.7C2584.7 1992.6 2590.4 1992 2618.5 1992H2645V1964.1C2645 1939.1 2645.2 1935.5 2647 1929.8C2650.5 1918.5 2657.9 1910.8 2668.4 1907.3C2674.9 1905.2 2723.7 1904.2 2735.2 1906C2754.6 1908.9 2771.2 1922.7 2777.8 1941.3C2781.3 1951.1 2781.4 1964.5 2778.1 1974.2C2772.2 1991.6 2758 2004.9 2740.3 2009.5C2735.8 2010.7 2727.3 2011 2699.3 2011H2664V2044.8C2664 2070.6 2663.6 2080.1 2662.5 2085.5C2658.3 2105.4 2643 2121.4 2623.3 2126.5C2616.5 2128.3 2605.5 2128.3 2598.5 2126.6Z"
        fill="#FFD000"
      />
      <path
        d="M2481.4 2004C2469 2002.1 2459.4 1997.1 2450.7 1988.1C2440.3 1977.4 2435.7 1965.9 2435.7 1951C2435.8 1927.4 2452.2 1906.6 2475.7 1900.5C2480.2 1899.3 2488.7 1899 2516.6 1899H2551.8L2552.2 1862.3L2552.6 1825.5L2555.3 1818.1C2568.7 1780.6 2614.7 1769.8 2642.6 1797.6C2649.4 1804.4 2655.2 1814.7 2657 1823.4C2657.7 1827 2658 1839.6 2657.8 1860.2L2657.5 1891.5L2654.7 1897.5C2649.5 1908.6 2640.8 1914.7 2627 1916.9C2623.4 1917.5 2609.4 1918 2595.8 1918H2571V1946.3C2571 1977.7 2570.5 1981 2564.7 1989.6C2560.7 1995.7 2554.4 2000.5 2547.7 2002.6C2543.4 2004 2537.6 2004.4 2514.5 2004.6C2499.1 2004.7 2484.2 2004.5 2481.4 2004Z"
        fill="#FFD000"
      />
      <path
        d="M940 680.5V550.9L953.8 561.3C961.3 567 1038.4 624.9 1125 690C1211.6 755 1282.9 808.6 1283.5 809.1C1284 809.6 1210.1 810 1112.2 810H940V680.5Z"
        fill="#FFD000"
      />
      <path
        d="M1515 749C1470.7 715.7 1323.2 604.9 1187.2 502.7L940 316.9V182.8C940 75.7 940.3 49 941.2 49.7C951.4 57.7 1595.1 541 1595.5 541C1596 541 1947.5 277 2183.8 99.3L2252 47.9L2251.8 182.2L2251.5 316.5L2017 492.8C1888 589.7 1740.4 700.6 1689 739.3L1595.5 809.5L1515 749Z"
        fill="#FFD000"
      />
      <path
        d="M1907.6 809.1C1909.8 807.1 2251 551 2251.5 551C2251.8 551 2252 609.3 2252 680.5V810H2079.3C1982.7 810 1907 809.6 1907.6 809.1Z"
        fill="#FFD000"
      />
      <path
        d="M1552 273.3C1503 236.5 1493.2 227.7 1481.6 210.2C1466.9 188.1 1460 164.9 1460 137.5C1460 121.3 1462 109.1 1467 93.9C1471.6 80 1475.8 71.5 1483.9 59.2C1504 29.3 1535.9 8.70003 1571.9 2.60002C1584.2 0.500024 1606.8 0.500024 1619.1 2.60002C1638.1 5.80002 1657.1 13.5 1673 24.3C1683.4 31.4 1700.2 48.6 1707.4 59.4C1717.3 74.3 1724.5 91.5 1728.6 109.3C1731.1 120.4 1731.6 147.5 1729.6 159.5C1727.4 172.1 1723.1 185.3 1717.8 196C1704.8 221.8 1695.1 231.3 1640 272.5C1616.6 290 1597 304.6 1596.5 305.1C1595.9 305.6 1577.7 292.6 1552 273.3Z"
        fill="#FFD000"
      />
    </Icon>
  );
}
