import { dropdownEspecies } from "components/atendimentos-components/atendimento-form/fields-data";
import { csvFields } from "components/campanha-sms-components/modal-campanha-sms/csv-cols";
import { IN100Props } from "pages/consulta/consulta-in100";
import { normalizeText } from "utils/filter-array-by";

export const parseSituacaoBeneficio: any = {
  0: "ATIVO",
  1: "EXCLUÍDO",
  2: "CESSADO",
  3: "SUSPENSO",
  4: "SUSPENSO POR MARCA DE ERRO",
  5: "CESSADO POR CESS. DO ORIGEM",
  6: "SUSPENSO P/ SUSP DO ORIGEM",
  7: "SUSPENSO PELO CONPAG",
  8: "CESSADO PELO SISOBI",
  9: "ESTAT. TRANF. ORGÃO ORIGEM",
  10: "RECEBENDO MENSALID DE RECUPER 6 MESES",
  11: "RECEVENDO MENSALID DE RECUPER 18 MESES",
  12: "SUSPENSO REVISÃO",
  13: "SUSPENSO POR ERRO DE NOME (POVOAMENTO)",
  14: "SUSPENSO - ORGÃO PAGADOR DESCREDENCIADO",
  15: "SUSPENSO PELA INSPETORIA",
  16: "SUSPENSO PELA AUDITORIA",
  17: "CESSADO PELA INSPETORIA",
  18: "CESSADO PELA AUDITORIA",
  19: "RECEB MENSALID RECUP 6 MESES-INSPETORIA",
  20: "RECEB MENSALID RECUP 18 MESES-INSPETORIA",
  21: "SUSPENSO REVISAO RURAL/95",
  22: "CESSADO REVISAO RURAL/95",
  23: "SUSPENSO PELO SISOBI",
  24: "CANCELADO PELA AUDITORIA",
};

export const parseIn100SituacaoBeneficioToAttendanceFormat: any = {
  0: "ATIVO",
  1: "CESSADO",
  2: "CESSADO",
  3: "SUSPENSO",
  4: "SUSPENSO",
  5: "CESSADO",
  6: "SUSPENSO",
  7: "SUSPENSO",
  8: "CESSADO",
  9: "INATIVO",
  10: "ATIVO",
  11: "ATIVO",
  12: "SUSPENSO",
  13: "SUSPENSO",
  14: "SUSPENSO",
  15: "SUSPENSO",
  16: "SUSPENSO",
  17: "CESSADO",
  18: "CESSADO",
  19: "ATIVO",
  20: "ATIVO",
  21: "SUSPENSO",
  22: "CESSADO",
  23: "SUSPENSO",
  24: "CESSADO",
};

export function makeIn100CSVContent(in100: IN100Props) {
  const header = [
    { name: "Nome", field: "nomeBeneficiario" },
    { name: "CPF", field: "cpf" },
    { name: "CPF representante", field: "cpfRepresentanteLegal" },
    { name: "Número benefício", field: "numeroMatricula" },
    {
      name: "Espécie benefício",
      render: (line) =>
        `${
          dropdownEspecies.find(({ value }) => value === line.especieBeneficio)
            ?.name
        }`,
    },
    {
      name: "Situação benefício",
      render: (line) =>
        parseSituacaoBeneficio[line?.situacaoBeneficio ?? ""] ?? "",
    },
    {
      name: "Possui representante legal",
      field: "possuiRepresentanteLegal",
      render: (line) => (line.possuiRepresentanteLegal ? "SIM" : "NÃO"),
    },
    {
      name: "Possui pensão alimentícia",
      render: (line) => (line.pensaoAlimenticia ? "SIM" : "NÃO"),
    },
    {
      name: "Elegível para emprestimos",
      render: (line) =>
        line?.elegivelEmprestimo != null
          ? line?.elegivelEmprestimo
            ? "SIM"
            : "NÃO"
          : "",
    },
    {
      name: "Qtd. Emprestimos ativos/suspensos",
      render: (line) => line?.qtdEmprestimosAtivosSuspensos || "",
    },
    {
      name: "UF Pagamento",
      render: (line) => line?.ufPagamento || "",
    },
    {
      name: "DDB",
      render: (line) => line?.dataDespachoBeneficio || "",
    },
    {
      name: "Margem livre novo",
      render: (line) => line?.margemDisponivel?.toFixed(2) || "",
    },
    {
      name: "Margem livre RMC",
      render: (line) => line?.margemDisponivelCartao?.toFixed(2) || "",
    },
    {
      name: "Margem livre RCC",
      render: (line) => line?.margemDisponivelCartao?.toFixed(2) || "",
    },
    {
      name: "Valor limite RCC",
      render: (line) => line?.valorLimiteRcc?.toFixed(2) || "",
    },
  ] as CSVHeader<IN100Props>[];
  const lines = [in100];

  const csvContent = jsonToCSV(lines, header);

  return csvContent;
}

export type CSVHeader<T = {}> = {
  name: string;
  field?: keyof T;
  render?: (line: any) => string;
};
export function jsonToCSV(
  arr: any[],
  headersParse?: CSVHeader<any>[],
  sep: string = ";"
) {
  let headers: CSVHeader<any>[] =
    headersParse ||
    Object.keys(arr[0]).map((key) => ({ name: key, field: key }));
  let content = "";

  let constructHeader = `${headers?.map(({ name }) => name).join(sep)}\n`; // header
  content += constructHeader;
  let constructContent = arr
    .map((line) => {
      return headers
        .map(
          ({ field, render }) =>
            render ? render(line) : `${line[field ?? ""] ?? ""}` // making cell
        )
        .join(sep); // making line
    })
    .join("\n"); // making lines group
  content += constructContent;

  return content;
}

export async function csvToJSON(
  file: File,
  headersParse: { fieldInCSV: string; fieldInJson: string }[] | null = null,
  separator = ";"
) {
  const text = await file.text();
  const lines = text.split("\n").filter((l) => l);
  console.log(lines);

  // Make Header
  const jsonResult = lines.reduce((json, line, i) => {
    if (i !== 0) {
      // Split Line
      const currSplitedLine = line.split(separator);
      // Make Object model with indexes ref
      let currentJSONObject = lines[0]
        ?.split(separator)
        .reduce((acc, currCsvField, i) => {
          const normalizedField = normalizeText(currCsvField);
          const parsedField: any = headersParse?.find(
            (curr) => curr.fieldInCSV === normalizedField
          ) || { fieldInCSV: normalizedField, fieldInJson: normalizedField };

          acc[parsedField.fieldInJson] = i;
          return acc;
        }, {} as any);
      // Change indexes to respective content
      Object.keys(currentJSONObject).forEach((key) => {
        const targetIndex = currentJSONObject[key];
        currentJSONObject[key] = currSplitedLine[targetIndex];
      });
      json.push(currentJSONObject);
    }
    return json;
  }, [] as any[]);

  return jsonResult;
}
