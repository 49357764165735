import { Box, BoxProps, Center, Flex, Text } from "@chakra-ui/react";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { AttendanceKey } from "contexts/attendance-context/actions-reducer";
import { InputField, InputFieldProps } from "components/input-field";
import { useAttendanceContext } from "contexts/attendance-context";
import { memo } from "react";

interface AttendanceInputProps extends Omit<InputFieldProps, "onChange"> {
  placeholder?: string;
  mask?: (value: any) => { raw: any; value: any };
  onChange?: (value: any) => void;
  isVisible?: () => boolean;
  containerProps?: BoxProps;
  fieldName?: AttendanceKey;
  source?: AttendanceKey;
  customTag?: string;
}

const AttendanceInput = ({
  title,
  placeholder,
  mask,
  value,
  isVisible,
  containerProps,
  fieldName,
  source,
  customTag,
  ...rest
}: AttendanceInputProps) => {
  let { onChange, inputProps, ...inputFieldProps } = rest;
  const { formControl, dispatch } = useAttendanceContext();
  const isInvalid = formControl.invalidFields.includes(fieldName!);

  let specificSourceValue: any;

  if (source) {
    let attValue: any = formControl.values;
    let currentAttendancePart = { ...attValue };
    source.split(".").forEach((key, i, arr) => {
      const isLastLoop = arr.length === i + 1;
      currentAttendancePart = currentAttendancePart?.[key];
      if (currentAttendancePart == null) return;
      if (isLastLoop) {
        specificSourceValue = currentAttendancePart;
      }
    });
  }

  const jsonSerialized = JSON.parse(
    formControl.values.sourceFieldsJson || "{}"
  );

  let sourceTagValue =
    specificSourceValue ?? jsonSerialized[fieldName || ""]?.updatedBy;
  sourceTagValue = sourceTagValue !== "CONSULTA OFFLINE" ? sourceTagValue : "";

  const color = colorsMap.get(customTag || sourceTagValue);
  const text = tagTextMap.get(customTag || sourceTagValue);

  if (!onChange && !inputProps?.onChange) {
    if (inputProps) {
      inputProps.isReadOnly = true;
    } else {
      inputProps = {};
      inputProps.isReadOnly = true;
    }
  }

  const removeKeyOfJSON = () => {
    if (source) {
      const isInnerKey = source.split(".").length > 1;
      const newValue = (formControl.values as any)[source.split(".")[0]];
      if (isInnerKey) newValue[source.split(".")[1]] = null;
      dispatch({
        type: "changeField",
        payload: {
          data: newValue,
          fieldName: source.split(".")[0] as keyof Attendance,
        },
      });
    } else if (fieldName && jsonSerialized[fieldName]) {
      delete jsonSerialized[fieldName];
      const stringfy = JSON.stringify(jsonSerialized);
      dispatch({
        type: "changeField",
        payload: {
          data: stringfy,
          fieldName: "sourceFieldsJson",
        },
      });
    }
  };

  // console.log(formControl.values.sourceFieldsJson);

  if (isVisible == null || isVisible())
    return (
      <Box {...containerProps}>
        {title ? (
          <Flex mb="8px" alignItems="center" justifyContent="space-between">
            <Text fontWeight="medium" {...inputFieldProps?.titleProps}>
              {title}
            </Text>
            {text ? (
              <Center
                p="2px 5px"
                fontSize="11"
                borderRadius="5px"
                bg={color}
                color="#fff"
              >
                {text}
              </Center>
            ) : null}
          </Flex>
        ) : null}
        <InputField
          onChange={(e) => {
            onChange?.(mask ? mask(e.target.value).raw : e.target.value);
            removeKeyOfJSON();
          }}
          isInvalid={isInvalid}
          inputProps={{
            variant: "outline-custom",
            placeholder,
            _placeholder: { opacity: 0.7 },
            cursor: !onChange ? "not-allowed" : undefined,
            ...inputProps,
          }}
          value={mask ? mask(value).value : value || ""}
          {...inputFieldProps}
        />
      </Box>
    );
  else return null;
};

export default memo(AttendanceInput);

export const colorsMap = new Map([
  ["DATAFAST", "#D1A347"],
  ["EXTRATO_ONLINE", "#255F84"],
  ["API_BANCO_CARTAO", "#7BB6DA"],
  ["IN100", "#327119"],
  ["API_MARGEM_CONVENIO", "#7BB6AF"],
  ["CONSULTA_CPF", "#255Faa"],
  ["SIMULADO", "#f09a1d"],
]);

export const tagTextMap = new Map([
  ["API_MARGEM_CONVENIO", "Api Margem Convênio"],
  ["CONSULTA OFFLINE", ""],
  ["EXTRATO_ONLINE", "EXTR. ONLINE"],
  ["IN100", "IN100"],
  ["CONSULTA_CPF", "Consulta CPF"],
  ["SIMULADO", "Simulado"],
]);
