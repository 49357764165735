import { RepeatIcon, SettingsIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Button,
  Flex,
  FlexProps,
  Grid,
  Icon,
  IconProps,
  Text,
} from "@chakra-ui/react";
import {
  StatusFormat,
  bgError,
  bgInfo,
  bgSucess,
  bgWarning,
} from "components/dynamic-table/row-status";
import { openModalConfirm } from "components/modal-confirm-new";
import { useApplicationContext } from "contexts/ApplicationContext";
import { Dispatch, Fragment, ReactNode, SetStateAction } from "react";
import { SkeletonLoading } from "components/skeleton-loading";
import { SelectedCustomer } from "pages/carteira";
import { useRouter } from "routes/router-control/use-router";
import api from "api/api";
import { Toast, UseToastOptions } from "components/toast";

export type ServiceDataType = {
  [key: string]: {
    ativo: boolean;
    cobranca?: "MENSAL" | "AVULSO";
    dataExpiracao?: string;
    possuiRecorrencia?: boolean;
    expirado?: boolean;
  } | null;
};

export function ServiceCard({
  title,
  priceDesc,
  serviceData,
  setServiceData,
  field,
  bgIcon,
  isLoading,
  selectedPartner,
  containerProps,
  priceButtonProps,
}: {
  title: string;
  priceDesc: string;
  serviceData: ServiceDataType;
  setServiceData: Dispatch<SetStateAction<ServiceDataType>>;
  field:
    | "in100"
    | "telefonia"
    | "sms"
    | "extratoOnline"
    | "consultaFGTS"
    | "consultaOffline"
    | "servicesDatafast"
    | "servicesAPICartaoBancoDTO"
    | "apiCartaoBanco"
    | "geracaoMailing"
    | "margemConvenio";
  bgIcon: ReactNode;
  isLoading: boolean;
  selectedPartner: SelectedCustomer;
  containerProps?: BoxProps;
  priceButtonProps?: PriceButtonProps;
}) {
  const { user } = useApplicationContext();

  const router = useRouter();

  const cardData = serviceData[field];
  const isActive = cardData?.ativo;
  const bgRecorrente = cardData?.possuiRecorrencia ? bgInfo : bgWarning;
  const parseToEnum = (
    {
      in100: "IN100",
      telefonia: "TELEFONIA",
      sms: "SMS",
      extratoOnline: "EXTRATO_ONLINE",
      consultaFGTS: "API_CONSULTA_FGTS",
      consultaOffline: "CONSULTA_OFFLINE",
      geracaoMailing: "GERACAO_MAILING",
    } as any
  )[field];

  let changeAtivoStatusUrl = "";

  if (
    [
      "in100",
      "telefonia",
      "sms",
      "extratoOnline",
      "consultaFGTS",
      "consultaOffline",
      "geracaoMailing",
    ].includes(field!)
  ) {
    changeAtivoStatusUrl = `/services/only-avulsos/change-ativo?customerId=${
      selectedPartner.id
    }&service=${parseToEnum}&ativo=${!isActive}`;
  }

  const px = "25px";

  const changeAtivoStatus = () => {
    const message = `Deseja ${
      !isActive ? "ATIVAR" : "DESATIVAR"
    } serviço "${title}"?`;

    openModalConfirm({
      message,
      onConfirm: async () => {
        try {
          const { data } = await api.get(changeAtivoStatusUrl);
          setServiceData((prevValue) => ({
            ...prevValue,
            [field]: {
              ...prevValue[field],
              ativo: !isActive,
            },
          }));
        } catch (e: any) {
          const errorStatus = e?.response?.data?.message ?? "";
          const errorMessage: { [k: string]: any } = {
            cannot_enable_avulso_when_cobranca_mensal: {
              title: "Erro ao mudar status do serviço",
              description: "É necessário que a cobrança esteja avulsa",
            },
            service_not_expired: {
              title: "Serviço não expirado",
              description: "Não é possível desativar um serviço não expirado",
            },
            sem_saldo: { title: "Saldo insuficiente" },
          };
          Toast({
            ...(errorMessage[errorStatus] ?? {
              title: "Erro ao mudar status de serviço",
            }),
            status: "error",
            duration: 7000,
          });
        } finally {
        }
      },
      confirmButtonStyle: {
        variant: undefined,
      },
    });
  };

  const titleContainerHeight = "80px";

  return (
    <SkeletonLoading
      isLoading={isLoading}
      borderRadius="5px"
      bg="#fff"
      overflow="hidden"
      {...containerProps}
    >
      <Box
        bg="#fff"
        borderRadius="5px"
        overflow="hidden"
        h="100%"
        border="1px solid var(--chakra-colors-custom-gray)"
        {...containerProps}
      >
        <Flex
          px={px}
          w="100%"
          h={titleContainerHeight}
          // bg="linear-gradient(to right, #141B3B, #B21F17)"
          alignItems="center"
          pos="relative"
          overflow="hidden"
          justifyContent="flex-start"
        >
          <Text
            fontSize={{ base: "18px", "2xl": "20px" }}
            // color="#fff"
            fontWeight="semibold"
          >
            {title}
          </Text>
          <Box pos="absolute" bottom="-20px" right="35px">
            {bgIcon}
          </Box>
        </Flex>
        <Flex
          flexDir="column"
          px={px}
          py="15px"
          minH={`calc(100% - ${titleContainerHeight})`}
        >
          {isActive != null ? (
            <Grid
              templateColumns="1fr 2fr"
              gap="10px"
              alignItems="center"
              w="100%"
              mb="10px"
            >
              <Text fontWeight="semibold">Status:</Text>
              <Flex alignItems="center" justifyContent="flex-end">
                <StatusFormat
                  mr="10px"
                  w="82px"
                  bg={isActive ? bgSucess : bgError}
                >
                  {isActive ? "Ativo" : "Inativo"}
                </StatusFormat>
                <Button
                  w="calc(100% - 92px)"
                  leftIcon={<RepeatIcon w="13px" h="13px" />}
                  variant={"outline"}
                  size="xs"
                  onClick={changeAtivoStatus}
                >
                  {!isActive ? "Ativar" : "Desativar"}
                </Button>
              </Flex>
            </Grid>
          ) : null}

          {field === "servicesAPICartaoBancoDTO" ||
          field === "servicesDatafast" ||
          field === "margemConvenio" ? (
            <>
              <Grid templateColumns="1fr 1fr" w="100%" gap="10px" mb="10px">
                {Object.keys(cardData ?? {}).map((key) => {
                  const propData = (serviceData?.[field] as any)[key] as {
                    ativo: boolean;
                    banco: string;
                    cobranca: "MENSAL" | "AVULSO";
                    dataExpiracao: string;
                    expirado: boolean;
                    possuiRecorrencia: boolean;
                  };
                  return (
                    <Fragment key={key}>
                      <Text fontWeight="semibold">{propData.banco}:</Text>
                      <Flex alignItems="center">
                        <Grid templateColumns="1fr 1fr" w="155px" gap="10px">
                          <StatusFormat
                            w="100%"
                            justifyContent="center"
                            bg={propData.ativo ? bgSucess : bgError}
                          >
                            {propData.ativo ? "Ativo" : "Inativo"}
                          </StatusFormat>
                          <StatusFormat
                            w="100%"
                            justifyContent="center"
                            bg={bgInfo}
                            textTransform="capitalize"
                          >
                            {propData.cobranca?.toLocaleLowerCase()}
                          </StatusFormat>
                        </Grid>
                      </Flex>
                    </Fragment>
                  );
                })}
              </Grid>
              <Button
                // pos="sticky"
                // bottom="0"
                my="10px"
                w="100%"
                size="sm"
                variant="outline"
                bg="#fff"
                onClick={() =>
                  router.push(
                    field === "servicesAPICartaoBancoDTO"
                      ? `/higienizacao-api-cartao-banco?partnerId=${selectedPartner.id}`
                      : field === "servicesDatafast"
                      ? `/higienizacao-refin?partnerId=${selectedPartner.id}`
                      : field === "margemConvenio"
                      ? `/margem-convenio-service?partnerId=${selectedPartner.id}`
                      : ""
                  )
                }
                leftIcon={<SettingsIcon />}
              >
                Configurar
              </Button>
            </>
          ) : null}
          <PriceButton mt="auto" {...priceButtonProps}>
            {priceDesc}
          </PriceButton>
        </Flex>
      </Box>
    </SkeletonLoading>
  );
}

interface PriceButtonProps extends FlexProps {
  children?: ReactNode;
  ticketDotProps?: IconProps;
  contentProps?: FlexProps;
}

export function PriceButton({
  children,
  ticketDotProps,
  contentProps,
  ...rest
}: PriceButtonProps) {
  return (
    <Flex
      alignItems="center"
      h="40px"
      role="group"
      w="100%"
      cursor="pointer"
      {...rest}
    >
      <Flex
        borderRadius="5px 0 0 5px"
        color="#fff"
        w="calc(100% - 24px)"
        bg="#6FB56D"
        _groupHover={{ bg: "#529C4F" }}
        transition="0.2s"
        minH="40px"
        alignItems="center"
        pos="relative"
        pl="25px"
        pr="8px"
        {...contentProps}
      >
        <Text
          transform="rotate(-90deg)"
          fontSize="11px"
          fontWeight="semibold"
          pos="absolute"
          left="-7px"
        >
          Preço:
        </Text>
        <Text fontWeight="semibold" fontSize="13px">
          {children}
        </Text>
      </Flex>
      <TicketDot {...ticketDotProps} />
    </Flex>
  );
}

function TicketDot({ ...rest }: IconProps) {
  return (
    <Icon
      width="24px"
      height="40px"
      viewBox="0 0 26 43"
      fill="#6FB56D"
      xmlns="http://www.w3.org/2000/svg"
      _groupHover={{ fill: "#529C4F" }}
      transition="0.2s"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0L25.8031 20.8485L0 43V0ZM9.92437 27.3637C13.213 27.3637 15.8789 24.7385 15.8789 21.5001C15.8789 18.2617 13.213 15.6365 9.92437 15.6365C6.63575 15.6365 3.9698 18.2617 3.9698 21.5001C3.9698 24.7385 6.63575 27.3637 9.92437 27.3637Z"
      />
    </Icon>
  );
}
