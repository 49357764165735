import { Box, Button, Flex, Grid, Text } from "@chakra-ui/react";
import { SkeletonLoading } from "components/skeleton-loading";
import { WhatsAppBgIcon } from "./icons";
import {
  StatusFormat,
  bgError,
  bgSucess,
} from "components/dynamic-table/row-status";
import { SettingsIcon } from "@chakra-ui/icons";
import { SelectedCustomer } from "pages/carteira";
import { PriceButton } from ".";
import { useRouter } from "routes/router-control/use-router";

type ServiceDataType = {
  sessions: null;
  ativo: boolean;
  qtdSessionsAtivas: number;
  qtdSessions: number;
} | null;

export function WhatsAppServiceCard({
  isLoading,
  priceDesc,
  title,
  serviceData,
  selectedCustomer,
}: {
  isLoading?: boolean;
  title: string;
  priceDesc: string;
  serviceData: any;
  selectedCustomer: SelectedCustomer;
}) {
  const router = useRouter();
  const px = "25px";
  const cardData = serviceData?.["servicesWhatsappDTO"] as ServiceDataType;
  const isActive = cardData?.ativo;
  const titleContainerHeight = "80px";
  return (
    <SkeletonLoading
      isLoading={!!isLoading}
      borderRadius="5px"
      bg="#fff"
      overflow="hidden"
    >
      <Box
        bg="#fff"
        borderRadius="5px"
        overflow="hidden"
        h="100%"
        border="1px solid var(--chakra-colors-custom-gray)"
      >
        <Flex
          px={px}
          w="100%"
          h={titleContainerHeight}
          // bg="linear-gradient(to right, #141B3B, #B21F17)"
          alignItems="center"
          pos="relative"
          overflow="hidden"
          justifyContent="flex-start"
        >
          <Text
            fontSize={{ base: "18px", "2xl": "20px" }}
            // color="#fff"
            fontWeight="semibold"
          >
            {title}
          </Text>
          <Box pos="absolute" bottom="-20px" right="35px">
            <WhatsAppBgIcon />
          </Box>
        </Flex>
        <Flex flexDir="column" px={px} py="15px" minH="215px">
          {isActive != null ? (
            <Grid
              templateColumns="1fr 2fr"
              gap="10px"
              alignItems="center"
              w="100%"
              mb="10px"
            >
              <Text fontWeight="semibold">Status:</Text>
              <Flex alignItems="center" justifyContent="flex-end">
                <StatusFormat w="82px" bg={isActive ? bgSucess : bgError}>
                  {isActive ? "Ativo" : "Inativo"}
                </StatusFormat>
              </Flex>
            </Grid>
          ) : null}
          {cardData?.qtdSessions != null ? (
            <Grid
              templateColumns="1fr 2fr"
              gap="10px"
              alignItems="center"
              w="100%"
              mb="10px"
            >
              <Text fontWeight="semibold">Quantidade:</Text>
              <Flex alignItems="center" justifyContent="flex-end">
                <Text>{`${cardData.qtdSessions} (${cardData.qtdSessionsAtivas} ativas)`}</Text>
              </Flex>
            </Grid>
          ) : null}
          <Button
            my="10px"
            w="100%"
            size="sm"
            variant="outline"
            bg="#fff"
            onClick={() =>
              router.push(`/whatsapp-service?partnerId=${selectedCustomer.id}`)
            }
            leftIcon={<SettingsIcon />}
          >
            Configurar
          </Button>
          <PriceButton mt="auto">{priceDesc}</PriceButton>
        </Flex>
      </Box>
    </SkeletonLoading>
  );
}
