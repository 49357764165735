import { SVGProps } from "react";

export function ShieldIcon({ ...rest }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="14"
      height="15"
      viewBox="0 0 14 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.90747 9.18231C6.79018 9.18251 6.67693 9.13942 6.58942 9.06132L5.15243 7.784C5.05792 7.69952 5.00075 7.58101 4.99347 7.45446C4.98618 7.3279 5.02937 7.20362 5.11357 7.10885C5.19777 7.01409 5.31611 6.95657 5.44264 6.94891C5.56918 6.94125 5.69359 6.98408 5.7886 7.068L6.87694 8.03534L8.95323 5.82123C9.04007 5.7286 9.16016 5.67426 9.28706 5.67017C9.41397 5.66608 9.53731 5.71257 9.62994 5.79941C9.72257 5.88625 9.77691 6.00634 9.781 6.13324C9.7851 6.26015 9.73861 6.38349 9.65176 6.47612L7.25679 9.03076C7.21207 9.07863 7.15798 9.11678 7.09789 9.14286C7.03779 9.16893 6.97298 9.18236 6.90747 9.18231Z"
        fill="#4E5762"
        stroke="#4E5762"
        strokeWidth="0.3"
      />
      <path
        d="M7.22694 14.5204C7.03669 14.5201 6.84968 14.4711 6.6837 14.3782L4.53135 13.1819C3.46137 12.5871 2.5698 11.7171 1.94893 10.662C1.32806 9.60685 1.00045 8.40499 1 7.18077V3.86273C0.999842 3.58633 1.10226 3.3197 1.28742 3.11449C1.47259 2.90928 1.72731 2.78009 2.00228 2.75193C2.85824 2.66076 3.70069 2.4703 4.51263 2.18439C5.25697 1.92148 5.97102 1.57962 6.64257 1.16465C6.81848 1.05698 7.02072 1 7.22697 1C7.43321 1 7.63545 1.05698 7.81137 1.16465C8.48267 1.57961 9.19649 1.92147 9.94061 2.18439C10.753 2.4703 11.5958 2.66077 12.4522 2.75195C12.7271 2.78024 12.9817 2.90949 13.1667 3.11468C13.3517 3.31988 13.4541 3.58642 13.4539 3.86273V7.18077C13.4534 8.40499 13.1258 9.60685 12.5049 10.662C11.8841 11.7171 10.9925 12.5871 9.92253 13.1819L7.76955 14.3781C7.60383 14.4712 7.417 14.5202 7.22694 14.5204ZM7.22694 1.95672C7.19895 1.95656 7.17149 1.96434 7.14775 1.97918H7.14711C6.41691 2.43013 5.64057 2.80167 4.83132 3.08746C3.94884 3.39855 3.03312 3.60577 2.10267 3.70491C2.06308 3.70807 2.02615 3.7261 1.99931 3.75538C1.97247 3.78466 1.95771 3.82301 1.95799 3.86273V7.18077C1.95836 8.23422 2.24026 9.26844 2.7745 10.1764C3.30874 11.0843 4.07591 11.833 4.99662 12.3449L7.1496 13.5411C7.17328 13.5539 7.19975 13.5606 7.22663 13.5606C7.25352 13.5606 7.27998 13.5539 7.30366 13.5411L9.45726 12.3449C10.378 11.833 11.1451 11.0843 11.6794 10.1764C12.2136 9.26844 12.4955 8.23422 12.4959 7.18077V3.86273C12.4962 3.8231 12.4815 3.78483 12.4548 3.75557C12.4281 3.7263 12.3913 3.70822 12.3518 3.70493C11.421 3.60578 10.5048 3.39856 9.62192 3.08746C8.81293 2.80154 8.03681 2.42999 7.30677 1.97916C7.28277 1.9644 7.25512 1.95663 7.22694 1.95672Z"
        fill="#4E5762"
        stroke="#4E5762"
        strokeWidth="0.3"
      />
    </svg>
  );
}
