import { Box, Flex, FlexProps, Text } from "@chakra-ui/react";
import { useEffect, useId, useRef } from "react";
import { useEventListener, makeEvent } from "services/events";
import { getRelativeRect } from "utils/relative-rect";

export interface Tab<T = string> {
  name: string;
  icon: JSX.Element;
  key: T;
  isVisible?: () => boolean;
}

interface TabsProps {
  id?: string;
  tabs: Tab[];
  activeTab: Tab["key"];
  onChangeTab: (key: any, tab: Tab) => void;
  styles?: FlexProps;
  isDisabled?: boolean;
}

export function Tabs({
  tabs,
  activeTab,
  onChangeTab,
  styles,
  isDisabled,
  id,
}: TabsProps) {
  const barRef = useRef<HTMLDivElement>(null);
  const tabsContainer = useRef<HTMLDivElement>(null);
  const lastTabSelected = useRef(tabs[0].key);
  const filteredTabs = tabs.filter(
    (tab) => tab.isVisible == null || tab.isVisible?.()
  );
  const autoId = useId();

  const onChange = (key: any, tab: Tab) => {
    onChangeTab(key, tab);
    moveBar(key);
  };

  const moveBar = (toTab: string = activeTab) => {
    const prevTarget = lastTabSelected.current;
    // if (toTab === prevTarget) return;
    // TODO: move the bar to the active position;
    const targetIndex = filteredTabs.findIndex((tab) => tab.key === toTab);
    const targetElement = tabsContainer.current?.children[targetIndex];
    if (targetElement) {
      const rect = getRelativeRect(targetElement, tabsContainer.current!);
      barRef.current!.style.width = `${rect.width}px`;
      barRef.current!.style.transform = `translateX(${rect.left - 15}px)`;
    }
    // TODO: update the lastTabSelected to current target
    lastTabSelected.current = toTab;
  };

  useEffect(() => {
    const isVisibleTab = tabs.find((curr) => curr.key === activeTab)?.isVisible;
    if (isVisibleTab != null && !isVisibleTab()) {
      const searchVisibleTab = filteredTabs[0];
      onChange(searchVisibleTab?.key!, searchVisibleTab!);
    }
  });

  useEventListener(
    "resize",
    () => {
      if (tabsContainer.current) moveBar();
    },
    id ?? autoId
  );

  const getTabByKey = (tabKey: string) => {
    const tabDesc = filteredTabs.find((crr) => crr.key === tabKey)?.name;
    if (tabsContainer.current) {
      for (let el of tabsContainer.current
        .children as HTMLCollectionOf<HTMLDivElement>) {
        if ((el.children[1] as HTMLParagraphElement)?.innerText === tabDesc) {
          return el;
        }
      }
    }
  };

  useEffect(() => {
    if (tabsContainer.current) {
      const target = getTabByKey(activeTab);
      if (target && barRef.current) {
        barRef.current.style.width = `${target.clientWidth}px`;
      }
    }
  }, []);

  // useEffect(() => {
  //   moveBar();
  // }, [tabs]);

  return (
    <Flex
      ref={tabsContainer}
      w="100%"
      pos="relative"
      borderBottom="1px solid #E8EAED"
      px="15px"
      overflow="auto"
      pb="8px"
      pt="8px"
      {...styles}
    >
      {filteredTabs.map((tab, i) => {
        const last = i + 1 === tabs.length;
        const isActive = activeTab === tab.key;

        return (
          <Flex
            key={tab.key}
            mr={last ? undefined : "10px"}
            px={{ base: "8px", "2xl": "10px" }}
            alignItems="center"
            color={isActive ? "#141B3B" : "#4C545F"}
            cursor="pointer"
            onClick={() => onChange(tab.key, tab)}
            _hover={{ color: "#141B3B" }}
            h="40px"
            pointerEvents={isDisabled ? "none" : undefined}
            opacity={isDisabled ? 0.5 : 1}
          >
            <Box mr="10px">{tab.icon}</Box>
            <Text
              fontWeight="semibold"
              fontSize={{ base: "16px", "2xl": "18px" }}
              lineHeight="1.2"
            >
              {tab.name}
            </Text>
          </Flex>
        );
      })}
      <Box
        ref={barRef}
        pos="absolute"
        left="calc(0px + 15px)"
        bottom="0px"
        bg="#141B3B"
        h="4px"
        borderRadius="5px 5px 0px 0px"
        transition="0.2s"
        opacity={isDisabled ? 0.5 : 1}
      />
    </Flex>
  );
}

window.addEventListener("resize", (e) => {
  makeEvent("resize", e, { throwsError: false });
});
