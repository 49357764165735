import { Box, Button, Flex } from "@chakra-ui/react";
import { useEffect, useRef } from "react";
import { getRelativeRect } from "utils/relative-rect";
import { VisualizationModeSwitch } from "../visualization-mode-switch";
import { AddIcon } from "@chakra-ui/icons";
import { openNewAttendanceModal } from "../new-attendance-modal";
import { use3cPlusContext } from "contexts/Context3cPlus";
import { useApplicationContext } from "contexts/ApplicationContext";
import { isColomboUser } from "utils/is-colombo-user";

let lastPage = "Atendimento";

export const PageSelect = ({
  currentPage,
  setCurrentPage,
}: {
  currentPage: string;
  setCurrentPage: React.Dispatch<React.SetStateAction<string>>;
}) => {
  const lineRef = useRef<HTMLDivElement>(null);
  const { controls3cPlus } = use3cPlusContext();
  const { user } = useApplicationContext();
  const pageDividersContainerRef = useRef<HTMLDivElement>(null);
  const padding = 30;

  const onChangePageDivider = (destinationEl: HTMLDivElement, name: string) => {
    if (lineRef.current && destinationEl && pageDividersContainerRef.current) {
      const line = lineRef.current;
      const container = pageDividersContainerRef.current;
      const rect = getRelativeRect(destinationEl, container);

      line.style.width = `${rect.width}px`;
      line.style.transform = `translateX(${rect.x - padding}px)`;

      // Change Page
      lastPage = currentPage;
      setCurrentPage(name);
    }
  };

  const pageDividers = [
    { name: "Atendimento" },
    { name: "Clientes", isVisible: () => !isColomboUser(user) },
  ];

  // const passPageAnimation = () => {
  //   const fromIndex = pageDividers.findIndex((curr) => curr.name === lastPage);
  //   const toIndex = pageDividers.findIndex((curr) => curr.name === currentPage);
  //   if (fromIndex < toIndex) {
  //     // TODO: move page to right
  //     AnimateElement(200, [0, 100], (progress) => {});
  //   } else {
  //     // TODO: move page to left
  //   }
  // };

  useEffect(() => {
    const index = 0;
    const target = pageDividersContainerRef.current?.children?.[
      index
    ] as HTMLDivElement;
    if (target) onChangePageDivider(target, pageDividers[index].name);
  }, []);

  return (
    <Flex
      w="100%"
      bg="#fff"
      h="55px"
      borderBottom="1px solid #E8EAED"
      justifyContent="space-between"
      alignItems="center"
    >
      <Flex
        ref={pageDividersContainerRef}
        px={`${padding}px`}
        pos="relative"
        h="100%"
        alignItems="center"
      >
        {pageDividers.map(({ name, isVisible }, index) => {
          if (isVisible == null || isVisible())
            return (
              <Box
                key={index}
                cursor="pointer"
                px="10px"
                onClick={(e) => {
                  if (currentPage !== name)
                    onChangePageDivider(e.currentTarget, name);
                }}
                fontWeight="semibold"
                fontSize={{ base: "16px" }}
                opacity={controls3cPlus.inCall ? 0.5 : undefined}
                pointerEvents={controls3cPlus.inCall ? "none" : undefined}
              >
                {name}
              </Box>
            );
          else return null;
        })}
        <Box
          transition="0.3s"
          ref={lineRef}
          pos="absolute"
          bottom={0}
          h="4px"
          borderRadius="6px 6px 0 0"
          bg="primary.400"
          opacity={controls3cPlus.inCall ? 0.5 : undefined}
        />
      </Flex>
      <Flex alignItems="center" pr={`${padding}px`}>
        {currentPage === "Atendimento" ? <VisualizationModeSwitch /> : null}
        <Button
          onClick={openNewAttendanceModal}
          leftIcon={<AddIcon w="13px" h="13px" />}
          ml="15px"
          isDisabled={controls3cPlus.inCall}
          size="sm"
        >
          Novo
        </Button>
      </Flex>
    </Flex>
  );
};
