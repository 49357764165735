import { Flex } from "@chakra-ui/react";
import { ModalChangeUser } from "../modal-change-user";
import { Kanban } from "./kanban";
import { useAttendanceKanbanContext } from "./kanban/kanban-context";
import { TableMode } from "./table-mode";
import { AttendanceFilter } from "../attendance-filter";
import { ModalAgendar } from "../modal-agendar";
import { InputSearchNbCpf } from "./search-nb-cpf";
import { DownloadSMSRelatory } from "../../campanha-sms-components/download-sms-relatory";

export function KanbanAndTableAtendimento() {
  const { visualizationMode, setKanbanColumns } = useAttendanceKanbanContext();

  return (
    <>
      <Flex
        justifyContent="space-between"
        w="100%"
        borderBottom="1px solid #E8EAED"
        p="11px 30px 9px"
        mb="30px"
      >
        <InputSearchNbCpf />
        <Flex alignItems="center">
          <AttendanceFilter />
        </Flex>
      </Flex>
      {visualizationMode === "kanban" ? (
        <Kanban />
      ) : visualizationMode === "tables" ? (
        <TableMode />
      ) : null}
      <ModalChangeUser
        onUpdate={({ newUserName, attendanceId }) => {
          setKanbanColumns((columns) =>
            columns.map((column) => {
              const searchId = column.attendancesList.find(
                (curr) => curr.id === attendanceId
              );
              if (searchId) {
                column.attendancesList = column.attendancesList.map(
                  (attendace) => {
                    if (attendace.id === attendanceId)
                      attendace.userName = newUserName;
                    return attendace;
                  }
                );
              }
              return column;
            })
          );
        }}
      />
      <ModalAgendar />
    </>
  );
}
