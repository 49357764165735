import {
  Box,
  Button,
  Flex,
  Grid,
  IconButton,
  Link,
  Text,
} from "@chakra-ui/react";
import { Toast } from "components/toast";
import apiDigitacao from "api/api-digitacao";
import {
  Attendance,
  Convenio,
} from "components/atendimentos-components/atendimento-form/types";
import { useAttendanceContext } from "contexts/attendance-context";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DocumentIcon } from "components/vectors/document-icon";
import {
  ModalDigitarPropostaFGTS,
  openModalDigitarProposta,
} from "../modal-digitar-proposta";
import { SkeletonLoading } from "components/skeleton-loading";
import {
  ModalAcompanharProposta,
  openModalAcompanharProposta,
} from "./modal-acompanhar-proposta";
import { CopyIcon, DeleteIcon } from "@chakra-ui/icons";
import { openModalConfirm } from "components/modal-confirm-new";
import { dropdownConvenioAtendimento } from "components/atendimentos-components/atendimento-form/fields-data";
import { dropdownBancosSimulacaoFGTS } from "../../simulacoes/utils";
import {
  ModalDigitacaoINSS,
  openModalDigitacaoINSS,
} from "../../margens/modal-digitacao-inss";
import { bancosParse } from "../modal-digitar-proposta/utils";

export interface DigitadoRow {
  convenio: Convenio;
  banco: string;
  ade: string;
  lastStatusADE?: string;
  linkFormalizacao: string;
  dataDigitacao: string;
  id: number;
  status: "PENDING" | "FINISHED" | "SYSTEM_ERROR" | null;
  messageDigitacao: string | null;
  codeRequestDigitacao: "SUCCESS" | "SYSTEM_ERROR" | null;
  isLoading?: boolean;
  stormContratoFF: string | null;
}

export function DigitadosAtendimentoPart({
  isLoading,
  setIsLoading,
}: {
  isLoading: boolean;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
}) {
  const [loadingTabela, setLoadingTabela] = useState(false);
  const [digitadosList, setDigitadosList] = useState<DigitadoRow[]>([]);
  const { formControl } = useAttendanceContext();
  const attendance = formControl.values as Attendance;

  const getDigitados = async () => {
    const url =
      attendance.convenio === "INSS"
        ? `/api/digitacoes/inss?atendimento_id=${attendance.id}`
        : `/api/digitacoes?atendimento_id=${attendance.id}`;
    setLoadingTabela(true);
    try {
      const { data } = await apiDigitacao.get(url);
      setDigitadosList(data);
    } catch (e) {
      Toast({ title: "Erro ao obter lista", status: "error" });
    } finally {
      setLoadingTabela(false);
    }
  };

  useEffect(() => {
    getDigitados();
  }, []);

  const consultarProposta = async (
    row: DigitadoRow,
    onChange: (k: keyof DigitadoRow | null, v: any) => void
  ) => {
    onChange("isLoading", true);
    try {
      const { data } = await apiDigitacao.get(
        `/api/digitacoes/fgts/consultar-proposta?digitacao_id=${row.id}`
      );

      if (data.status === "PENDING") {
        Toast({ title: "Digitação em andamento", status: "info" });
      } else if (
        data.status === "FINISHED" &&
        data.codeRequestDigitacao === "SUCCESS"
      ) {
        Toast({ title: "Consultado com sucesso", status: "success" });
      }
      onChange(null, data);
    } catch (e) {
      Toast({ title: "Erro ao consultar", status: "error" });
    } finally {
      onChange("isLoading", false);
    }
  };

  const deleteDigitacao = async ({
    onChange,
    index,
    row,
  }: {
    onChange: (k: keyof DigitadoRow | null, v: any) => void;
    index: number;
    row: DigitadoRow;
  }) => {
    onChange("isLoading", true);
    try {
      const { data } = await apiDigitacao.delete(
        `/api/digitacoes/fgts/${row.id}`
      );
      setDigitadosList((prev) => prev.filter((r, i) => i !== index));
    } catch (e) {
      Toast({ title: "Erro ao excluir digitação" });
    } finally {
      onChange("isLoading", false);
    }
  };

  return (
    <>
      {loadingTabela ? (
        <SkeletonLoading isLoading w="100%" h="192px" borderRadius="4px" />
      ) : (
        <>
          {digitadosList.map((row, index, arr) => {
            const isLast = index + 1 === arr.length;
            const onChange = (k: keyof DigitadoRow | null, v: any) =>
              setDigitadosList((prev) =>
                prev.map((r, i) =>
                  i === index ? (k == null ? v : { ...r, [k]: v }) : r
                )
              );
            const isVisibleAcompanharProposta =
              ["BMG", "C6"].includes(row.banco) ||
              attendance.convenio === "ANTECIPACAO_FGTS";
            return (
              <Box
                key={row.id}
                border="1px solid var(--chakra-colors-custom-gray)"
                bg="var(--chakra-colors-gray-100)"
                p="10px 15px"
                borderRadius="4px"
                mb={isLast ? "" : "20px"}
                pos="relative"
                fontWeight="medium"
              >
                {row.codeRequestDigitacao === "SYSTEM_ERROR" ? (
                  <IconButton
                    pos="absolute"
                    top="10px"
                    right="15px"
                    aria-label=""
                    size="xs"
                    variant="danger"
                    icon={<DeleteIcon />}
                    isLoading={row.isLoading}
                    onClick={() => {
                      openModalConfirm({
                        message: "Deseja excluir digitação?",
                        onConfirm: () => {
                          deleteDigitacao({ onChange, index, row });
                        },
                      });
                    }}
                  />
                ) : null}
                <Grid templateColumns="4fr 3fr 5fr" gap="14px">
                  <Text>
                    <b>Convênio: </b>
                    <Text as="span" whiteSpace="nowrap">
                      {dropdownConvenioAtendimento.find(
                        ({ value }) => value === row.convenio
                      )?.name || ""}
                    </Text>
                  </Text>
                  <Text>
                    <b>Banco: </b>
                    <Text as="span" whiteSpace="nowrap">
                      {dropdownBancosSimulacaoFGTS.find(
                        ({ value }) => value === row.banco
                      )?.name ||
                        bancosParse.find(({ value }) => value === row.banco)
                          ?.name ||
                        ""}
                    </Text>
                  </Text>
                  <Text>
                    <b>Data digitação: </b>
                    <Text as="span" whiteSpace="nowrap">
                      {row.dataDigitacao || ""}
                    </Text>
                  </Text>
                  <Text>
                    <b>Storm FF: </b>
                    <Text as="span" whiteSpace="nowrap">
                      {row.stormContratoFF || ""}
                    </Text>
                  </Text>
                  <Text>
                    <b>ADE: </b>
                    <Text as="span" whiteSpace="nowrap">
                      {row.ade || ""}
                    </Text>
                  </Text>
                  <Text>
                    <b>Status ADE: </b>
                    <Text as="span" whiteSpace="nowrap">
                      {row.lastStatusADE || ""}
                    </Text>
                  </Text>

                  <Text gridArea="auto / span 3">
                    <b>Mensagem Digitação: </b>
                    <Text
                      as="span"
                      fontWeight="semibold"
                      color={
                        row.codeRequestDigitacao === "SYSTEM_ERROR"
                          ? "red.600"
                          : row.codeRequestDigitacao === "SUCCESS"
                          ? "green.600"
                          : ""
                      }
                    >
                      {row.messageDigitacao || ""}
                    </Text>
                  </Text>
                  <Flex
                    flexWrap="wrap"
                    alignItems="center"
                    gridArea="auto / span 3"
                  >
                    <b>Link Formalização: </b>
                    {row.linkFormalizacao ? (
                      <Flex
                        w="calc(100% - 135px - 6px)"
                        bg="var(--chakra-colors-custom-gray)"
                        px="5px"
                        py="2px"
                        ml="6px"
                        borderRadius="3px"
                      >
                        {row.linkFormalizacao.includes(".") ? (
                          <Link
                            target="_blank"
                            href={"https://" + row.linkFormalizacao}
                            // whiteSpace="nowrap"
                            fontWeight="500"
                          >
                            {row.linkFormalizacao}
                          </Link>
                        ) : (
                          <Text>{row.linkFormalizacao}</Text>
                        )}
                        <Button
                          aria-label=""
                          size="xs"
                          leftIcon={<CopyIcon />}
                          variant="outline"
                          px="5px"
                          ml="auto"
                          border="none"
                          h="20px"
                          onClick={() => {
                            navigator.clipboard.writeText(row.linkFormalizacao);
                          }}
                        >
                          Copiar
                        </Button>
                      </Flex>
                    ) : null}
                  </Flex>
                  <Grid
                    templateColumns={
                      attendance.convenio === "ANTECIPACAO_FGTS"
                        ? "1fr 1fr 1fr"
                        : isVisibleAcompanharProposta
                        ? "1fr 1fr"
                        : "1fr"
                    }
                    gap="12px"
                    gridArea="auto / span 3"
                    mt="12px"
                  >
                    {attendance.convenio === "ANTECIPACAO_FGTS" ? (
                      <Button
                        rightIcon={<DocumentIcon width="20px" height="20px" />}
                        onClick={() => consultarProposta(row, onChange)}
                        isDisabled={row.status !== "PENDING"}
                        isLoading={row.isLoading}
                      >
                        Consultar Proposta
                      </Button>
                    ) : null}
                    {isVisibleAcompanharProposta ? (
                      <Button
                        rightIcon={<DocumentIcon width="20px" height="20px" />}
                        isDisabled={
                          attendance.convenio === "ANTECIPACAO_FGTS"
                            ? !row.ade || row.isLoading
                            : false
                        }
                        onClick={() => {
                          openModalAcompanharProposta({
                            row,
                            onChangeRow: onChange,
                          });
                        }}
                      >
                        Acompanhar Proposta
                      </Button>
                    ) : null}
                    <Button
                      rightIcon={<DocumentIcon width="20px" height="20px" />}
                      onClick={() => {
                        if (attendance.convenio === "ANTECIPACAO_FGTS") {
                          openModalDigitarProposta({
                            bancoDigitacao: row.banco,
                            updateOptions: {
                              allDisabled:
                                row.codeRequestDigitacao !== "SYSTEM_ERROR",
                              digitacaoData: row,
                              onUpdate: (newRow: DigitadoRow) => {
                                onChange(null, newRow);
                              },
                            },
                            digitacaoId: row.id,
                          });
                        } else if (attendance.convenio === "INSS") {
                          openModalDigitacaoINSS({
                            modalConfig: {
                              allDisabled: row.status !== "SYSTEM_ERROR",
                              idDigitacao: row.id,
                            },
                            simulacao: { banco: row.banco },
                          });
                        }
                      }}
                      isDisabled={row.isLoading}
                    >
                      {row.codeRequestDigitacao === "SYSTEM_ERROR"
                        ? "Corrigir Fomulário"
                        : "Ver Fomulário"}
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            );
          })}
        </>
      )}
      {attendance.convenio === "ANTECIPACAO_FGTS" ? (
        <ModalDigitarPropostaFGTS />
      ) : (
        <ModalDigitacaoINSS />
      )}
      <ModalAcompanharProposta attendance={attendance} />
    </>
  );
}
