export const DepositoIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2034_797)">
        <path
          d="M15.9897 9.87072C15.9892 9.86794 15.9886 9.86516 15.988 9.86241C15.984 9.84369 15.9793 9.82498 15.9736 9.80626C15.972 9.80123 15.9702 9.79639 15.9686 9.79142C15.9658 9.78323 15.9633 9.77501 15.9602 9.76686L13.9604 4.43392C13.8628 4.17375 13.6141 4.00136 13.3362 4.00136H11.3363C10.9682 4.00136 10.6697 4.2998 10.6697 4.66796C10.6697 5.03612 10.9682 5.33457 11.3363 5.33457H12.8742L14.3741 9.33426H11.3363C10.9682 9.33426 10.6697 9.63271 10.6697 10.0009V11.3341H5.3368V10.0009C5.3368 9.63271 5.03835 9.33426 4.67019 9.33426H1.63242L3.13231 5.33457H4.67019C5.03835 5.33457 5.3368 5.03612 5.3368 4.66796C5.3368 4.2998 5.03835 4.00136 4.67019 4.00136H2.67034C2.39246 4.00136 2.14373 4.17372 2.04617 4.43392L0.0463254 9.76686C0.0432631 9.77501 0.040732 9.78317 0.0380135 9.79135C0.0363574 9.79635 0.0344825 9.80123 0.0329514 9.80629C0.0272018 9.82498 0.0225772 9.84369 0.018515 9.86241C0.0179213 9.86519 0.0173588 9.86797 0.0167964 9.87079C0.00642216 9.922 0.00223498 9.97306 0.0038911 10.0232V15.3339C0.0038911 15.7021 0.302337 16.0005 0.670497 16.0005H15.336C15.7042 16.0005 16.0026 15.7021 16.0026 15.3339V10.0232C16.0043 9.97303 16.0001 9.92197 15.9897 9.87072ZM14.6694 14.6672H1.3371V10.6675H4.00356V12.0008C4.00356 12.3689 4.302 12.6674 4.67016 12.6674H11.3363C11.7045 12.6674 12.0029 12.3689 12.0029 12.0008V10.6675H14.6694V14.6672H14.6694Z"
          fill="#0D9A00"
        />
        <path
          d="M5.53161 7.13927L7.53146 9.13912C7.53158 9.13925 7.53171 9.13931 7.5318 9.13943C7.54724 9.15484 7.56342 9.16943 7.5803 9.18328C7.58801 9.18962 7.59614 9.19512 7.60407 9.20106C7.61354 9.20815 7.62282 9.21549 7.63267 9.22208C7.64217 9.22843 7.65201 9.23393 7.66173 9.23974C7.67066 9.24508 7.67938 9.25068 7.6886 9.25561C7.69851 9.26093 7.70869 9.26539 7.71882 9.27017C7.72844 9.2747 7.73788 9.27952 7.74772 9.28361C7.75753 9.28767 7.76756 9.29095 7.7775 9.29452C7.78806 9.29833 7.7985 9.30236 7.80931 9.30564C7.81925 9.30864 7.82937 9.31086 7.83943 9.31339C7.85046 9.31617 7.86134 9.31926 7.87259 9.32148C7.88421 9.3238 7.89596 9.32514 7.90765 9.32683C7.91746 9.32823 7.92708 9.33011 7.93705 9.33108C7.9587 9.3332 7.98042 9.33436 8.00214 9.33436C8.00236 9.33436 8.00261 9.33439 8.00283 9.33439C8.00304 9.33439 8.00329 9.33436 8.00351 9.33436C8.02523 9.33433 8.04695 9.3332 8.0686 9.33108C8.07854 9.33011 8.08819 9.32823 8.09801 9.32683C8.10972 9.32514 8.12144 9.3238 8.13307 9.32148C8.14428 9.31926 8.15519 9.31617 8.16622 9.31339C8.17628 9.31086 8.18637 9.30864 8.19634 9.30564C8.20715 9.30236 8.21759 9.29833 8.22815 9.29452C8.23809 9.29095 8.24812 9.28767 8.25793 9.28361C8.26777 9.27952 8.27724 9.27474 8.28684 9.27017C8.29696 9.26539 8.30715 9.26093 8.31705 9.25561C8.32624 9.25068 8.33499 9.24508 8.34392 9.23974C8.35364 9.23393 8.36349 9.22843 8.37298 9.22208C8.38283 9.21549 8.39211 9.20815 8.40158 9.20106C8.40951 9.19512 8.41764 9.18962 8.42536 9.18328C8.44223 9.16943 8.45845 9.15484 8.47385 9.13943C8.47398 9.13931 8.4741 9.13925 8.4742 9.13912L10.474 7.13927C10.7344 6.87895 10.7344 6.45686 10.474 6.19653C10.2137 5.93621 9.79163 5.93621 9.5313 6.19653L8.66943 7.0584V0.66836C8.66943 0.300201 8.37099 0.00175476 8.00283 0.00175476C7.63467 0.00175476 7.33622 0.300201 7.33622 0.66836V7.0584L6.47435 6.19653C6.21402 5.93621 5.79193 5.93621 5.53161 6.19653C5.27128 6.45686 5.27128 6.87892 5.53161 7.13927Z"
          fill="#0D9A00"
        />
      </g>
      <defs>
        <clipPath id="clip0_2034_797">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
