import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Toast } from "components/toast";
import apiCarteira from "api/api-carteira";
import { ClientsFilterProps } from "components/atendimentos-components/aba-clientes/clients-filter";
import { validateClientsFilter } from "components/atendimentos-components/aba-clientes/clients-filter/utils";
import { ClientsAttendanceContextProps, ClientsDataProps } from "./types";

const defaultValue: ClientsAttendanceContextProps = {
  clientsFilter: { page: 1, pageSize: 10 },
  setClientsFilter: () => undefined,
  getClients: async () => undefined,
  setClientList: () => undefined,
  clientList: {},
  isLoadingClientsList: false,
  setIsLoadingClientsList: () => undefined,
};

const clientsAttendanceContext =
  createContext<ClientsAttendanceContextProps>(defaultValue);

export const ClientsAttendanceContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [clientList, setClientList] = useState<ClientsDataProps>({});
  const [clientsFilter, setClientsFilter] = useState<ClientsFilterProps>(
    defaultValue.clientsFilter
  );
  const [isLoadingClientsList, setIsLoadingClientsList] = useState(false);

  async function getClients(filterBody: ClientsFilterProps = clientsFilter) {
    const { body } = validateClientsFilter(filterBody);
    setIsLoadingClientsList(true);
    try {
      const { data } = await apiCarteira.post(
        "/contratos/clientes/filter",
        body
      );
      setClientList(data);
      setClientsFilter({ ...filterBody, page: data.page });
    } catch (e) {
      Toast({ title: "Erro ao obter clientes", status: "error" });
    } finally {
      setIsLoadingClientsList(false);
    }
  }

  useEffect(() => {
    getClients();
  }, []);

  return (
    <clientsAttendanceContext.Provider
      value={{
        clientsFilter,
        setClientsFilter,
        getClients,
        clientList,
        setClientList,
        isLoadingClientsList,
        setIsLoadingClientsList,
      }}
    >
      {children}
    </clientsAttendanceContext.Provider>
  );
};

export const useClientsAttendanceContext = () => {
  const context = useContext(clientsAttendanceContext);
  if (context === undefined)
    throw new Error(
      "useClientsAttendanceContext must be used within a ApplicationContextProvider"
    );
  return context;
};
