import { CloseIcon, Search2Icon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Center,
  Flex,
  Grid,
  IconButton,
  Input,
  Spinner,
  Text,
} from "@chakra-ui/react";
import apiConsulta from "api/api-consulta";
import { ConsultaFields } from "components/consulta-components/consulta-fields";
import { ConsultaProps } from "components/consulta-components/consulta-fields/types";
import { Dropdown } from "components/dropdown";
import { GetLayout } from "components/get-layout";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from "react";
import { maskCPF } from "utils/string-formats";
import {
  ModalSelectBenefit,
  openModalSelectBenefit,
} from "../../components/consulta-components/modal-select-benefit";
import { getErrorByMessage } from "components/atendimentos-components/atendimento-form/functions/mapeamentos";
import Cookies from "js-cookie";
import { TitleWithBackButton } from "components/title-with-back-button";
import { Toast } from "components/toast";

export interface ConsultHistory {
  nb: string;
}

export function Consulta() {
  const [isLoading, setIsLoading] = useState(false);
  const [inputSearch, setInputSearch] = useState("");
  const [searchMode, setSearchMode] = useState<"nb" | "cpf">("cpf");
  const [consult, setConsult] = useState<ConsultaProps>();
  const [history, setHistory] = useState<ConsultHistory[]>([]);

  useEffect(() => {
    const prev = Cookies.get("consult-history");
    if (prev) setHistory(JSON.parse(prev));
  }, []);

  const onChangeConsulta: Dispatch<
    SetStateAction<ConsultaProps | undefined>
  > = (newValue) => {
    const value = typeof newValue === "function" ? newValue(consult) : newValue;
    if (value) {
      const newHistory = [
        ...history.slice(0, 9),
        { nb: value.beneficio.beneficio },
      ]
        .filter(({ nb }, index, array) => {
          const firstIndex = array.findIndex(({ nb: crrNb }) => crrNb === nb);
          return firstIndex === index;
        })
        .slice(0, 10);
      // .reverse();
      Cookies.set("consult-history", JSON.stringify(newHistory));
      setHistory(newHistory);
    }
    setConsult(newValue);
  };

  const deleteHistory = (nb: string) => {
    const newHistory = history.filter((crr) => crr.nb !== nb);
    Cookies.set("consult-history", JSON.stringify(newHistory));
    setHistory(newHistory);
  };

  const dropdownOptions = [
    { name: "CPF", value: "cpf" },
    { name: "Benefício", value: "nb" },
  ];

  const selectedMask = {
    cpf: (value: string) => {
      value = value.replace(/\D/g, "");
      return { raw: value, value: maskCPF(value) };
    },
    nb: (value: string) => {
      value = value.replace(/\D/g, "");
      return { raw: value, value };
    },
  }[searchMode];

  const selectedPlaceholder = { cpf: "Digite o CPF", nb: "Digite o benefício" }[
    searchMode
  ];

  const onChange =
    (setChange: Dispatch<SetStateAction<string>>) =>
    (e: ChangeEvent<HTMLInputElement>) => {
      setChange(selectedMask(e.target.value).raw);
    };

  const onConsult = async (mode = searchMode, searchValue = inputSearch) => {
    setIsLoading(true);
    try {
      const { data }: { data: ConsultaProps[] | ConsultaProps["beneficio"] } =
        await apiConsulta.get(`consultas/inss/offline/${mode}/${searchValue}`);
      if (Array.isArray(data)) {
        if (data.length > 1) {
          onChangeConsulta(undefined);
          openModalSelectBenefit({ consultList: data });
        } else onChangeConsulta(data[0]);
      } else {
        onChangeConsulta({ beneficio: data });
      }
    } catch (e) {
      Toast({
        title: "Erro ao consultar",
        description: getErrorByMessage(e, ""),
        status: "error",
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <GetLayout>
      <TitleWithBackButton title="Consulta offline" />
      <Box borderRadius="8px" w="100%" mb="16px">
        <Text mb="10px">Consulta Offline:</Text>
        <Grid templateColumns="200px auto 200px" gap="16px">
          <Dropdown
            w="100%"
            options={dropdownOptions}
            onChange={setSearchMode}
            variant="outline-custom"
            value={searchMode}
          />
          <Input
            value={selectedMask(inputSearch).value}
            onChange={onChange(setInputSearch)}
            placeholder={selectedPlaceholder}
            onKeyDown={(e) => (e.key === "Enter" ? onConsult() : undefined)}
            variant="outline-custom"
          />
          <Button
            leftIcon={<Search2Icon />}
            onClick={() => onConsult()}
            isLoading={isLoading}
            loadingText="Consultando"
            isDisabled={inputSearch === ""}
            size="sm"
          >
            Consultar
          </Button>
        </Grid>
      </Box>

      {history.length ? (
        <Box
          w="100%"
          bg="white"
          borderRadius={"8"}
          p={consult ? "10px 20px 15px 20px" : "10px 20px 10px 20px"}
        >
          <Flex
            w="100%"
            pos="relative"
            mb={consult ? "16px" : undefined}
            overflow="auto"
          >
            {history.map(({ nb }, index) => {
              const isActive = consult?.beneficio.beneficio === nb;
              return (
                <Center
                  flexDir="column"
                  key={`${index}`}
                  p="10px 12px 10px"
                  onClick={() => onConsult("nb", nb)}
                  cursor="pointer"
                  justifyContent="flex-start"
                  userSelect="none"
                  pos="relative"
                  borderLeft={
                    index === 0
                      ? undefined
                      : "1px solid var(--chakra-colors-gray-200)"
                  }
                >
                  <Center>
                    <Text>{nb}</Text>
                    <IconButton
                      aria-label=""
                      size="xs"
                      ml="8px"
                      variant="outline"
                      w="20px"
                      h="20px"
                      minW="20px"
                      icon={<CloseIcon w="8px" h="8px" />}
                      onClick={(e) => {
                        e.stopPropagation();
                        deleteHistory(nb);
                      }}
                    />
                  </Center>
                  {isActive ? (
                    <Box
                      pos="absolute"
                      bottom="0px"
                      left="0px"
                      right="0px"
                      borderRadius="10px"
                      h="4px"
                      bg="primary.300"
                    />
                  ) : null}
                </Center>
              );
            })}
          </Flex>
          {isLoading ? (
            <Center w="100%" h="400px">
              <Spinner />
            </Center>
          ) : consult ? (
            <ConsultaFields consult={consult} />
          ) : null}
        </Box>
      ) : null}
      <ModalSelectBenefit consulta={consult} setConsulta={onChangeConsulta} />
    </GetLayout>
  );
}
