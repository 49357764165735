import { Button, Grid } from "@chakra-ui/react";
import { AttendanceFilterProps } from "components/atendimentos-components/attendance-filter";
import { useAttendanceKanbanContext } from "components/atendimentos-components/kanban-and-table-atendimento/kanban/kanban-context";
import { CheckIcon2 } from "components/vectors/checked-box-icon";

export function GanhosPerdidosSwitch({
  onChange,
  attendanceFilter,
}: {
  attendanceFilter: AttendanceFilterProps;
  onChange: (value: any, key: keyof AttendanceFilterProps) => void;
}) {
  const { isLoadingAttendances } = useAttendanceKanbanContext();
  const resultado = attendanceFilter.resultado;

  const onChangeKanbanEnabled = (state: "GANHO" | "PERDIDO" | undefined) => {
    onChange(state, "resultado");
  };

  return (
    <Grid
      templateColumns="1fr 1fr"
      mr="15px"
      borderRadius="5px"
      overflow="hidden"
      w="100%"
    >
      <Button
        borderRadius="5px 0px 0px 5px"
        aria-label=""
        variant="outline"
        loadingText="Consultando"
        size="sm"
        bg={resultado === "GANHO" ? "green.700" : undefined}
        color={resultado === "GANHO" ? "white" : undefined}
        isDisabled={isLoadingAttendances}
        _hover={resultado === "GANHO" ? { bg: "green.600" } : undefined}
        _active={resultado === "GANHO" ? { bg: "green.700" } : undefined}
        transform="translateX(1px)"
        onClick={() =>
          onChangeKanbanEnabled(resultado === "GANHO" ? undefined : "GANHO")
        }
        leftIcon={<CheckIcon2 isChecked={resultado === "GANHO"} />}
      >
        Ganho
      </Button>
      <Button
        borderRadius="0px 5px 5px 0px"
        aria-label=""
        variant="outline"
        isDisabled={isLoadingAttendances}
        loadingText="Consultando"
        size="sm"
        bg={resultado === "PERDIDO" ? "red.700" : undefined}
        color={resultado === "PERDIDO" ? "white" : undefined}
        _hover={resultado === "PERDIDO" ? { bg: "red.600" } : undefined}
        _active={resultado === "PERDIDO" ? { bg: "red.700" } : undefined}
        onClick={() =>
          onChangeKanbanEnabled(resultado === "PERDIDO" ? undefined : "PERDIDO")
        }
        leftIcon={<CheckIcon2 isChecked={resultado === "PERDIDO"} />}
      >
        Perdido
      </Button>
    </Grid>
  );
}
