import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import { Box, Button, Flex } from "@chakra-ui/react";
import api from "api/api";
import { CustomModal } from "components/custom-modal";
import DynamicTable from "components/dynamic-table";
import {
  StatusFormat,
  bgError,
  bgInfo,
  bgSucess,
  bgWarning,
} from "components/dynamic-table/row-status";
import { Column } from "components/dynamic-table/types";
import { openModalConfirm } from "components/modal-confirm-new";
import { useState } from "react";
import { useEventListener, makeEvent } from "services/events";
import { formatData } from "utils/string-formats";
import { openModalChangeCobranca } from "../service-card/modal-change-cobranca";
import { Toast, UseToastOptions } from "components/toast";

interface Fila {
  dataExpiracao: string;
  expirado: boolean;
  id: number;
  possuiRecorrencia: boolean;
}
interface ModalDataProps {
  ativo: true;
  banco: string;
  cobranca: "MENSAL" | "AVULSO";
  filas: Fila[];
  id: 39441;
}

export function ModalFilasDatafast({
  loadDatafast,
}: {
  loadDatafast?: () => Promise<void>;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useState<Partial<ModalDataProps>>({});
  const [possuiRecorrencia, setPossuiRecorrencia] = useState<boolean>();

  const params = new URLSearchParams(window.location.search);
  const customerId = params.get("partnerId");

  const onOpen = (row: ModalDataProps) => {
    setIsOpen(true);
    setModalData(row);
  };
  const onClose = () => {
    setIsOpen(false);
  };
  useEventListener("openModalDatafast", onOpen);

  function renovar(row: Fila) {
    openModalChangeCobranca({
      modalTitle: `Renovar Datafast`,
      confirmButtonProps: { children: "Renovar", loadingText: "Renovando" },
      onConfirm: async () => {
        try {
          const { data } = await api.get(
            `/services/datafast/fila/${row.id}/renew-mensal-license`,
            { params: { possuiRecorrencia: row.possuiRecorrencia } }
          );
          setModalData({
            ...modalData,
            filas: modalData.filas?.map((crrRow) => {
              if (crrRow.id === row.id) return data;
              return crrRow;
            }),
          });
        } catch (e: any) {
          const codError = e?.response?.data?.message;
          const errorMessage = (
            {
              sem_saldo: "Saldo insuficiente",
            } as any
          )[codError ?? ""];
          Toast({
            title: errorMessage ?? "Erro ao trocar recorrência",
            status: "error",
          });
        } finally {
          loadDatafast?.();
        }
      },
    });
  }

  function deleteRow(row: Fila) {
    openModalConfirm({
      message: `Deletar datafast?`,
      onConfirm: async () => {
        try {
          const { data } = await api.delete(
            `/services/datafast/fila/${row.id}`
          );
          setModalData({
            ...modalData,
            filas: modalData.filas?.filter((crrRow) => crrRow.id !== row.id),
          });
          Toast({ title: "Deletado com sucesso" });
        } catch (e) {
          Toast({ title: "Erro ao deletar", status: "error" });
        } finally {
          loadDatafast?.();
        }
      },
      confirmButtonStyle: { leftIcon: <DeleteIcon /> },
    });
  }

  function trocarRecorrencia(row: Fila) {
    openModalConfirm({
      message: `Deseja ${
        !row.possuiRecorrencia ? "ATIVAR" : "DESATIVAR"
      } cobrança recorrente?`,
      confirmButtonStyle: { variant: undefined },
      onConfirm: async () => {
        try {
          const { data } = await api.get(
            `/services/datafast/fila/${row.id}/change-recorrencia`,
            { params: { possuiRecorrencia: !row.possuiRecorrencia } }
          );
          setModalData({
            ...modalData,
            filas: modalData.filas?.map((crrRow) => {
              if (crrRow.id === row.id) {
                return data;
              }
              return crrRow;
            }),
          });
        } catch (e: any) {
          const codError = e?.response?.data?.message;
          const errorMessage: { [k: string]: Partial<UseToastOptions> } = {
            cannot_change_recorrencia_when_license_expired: {
              title: "Erro ao trocar recorrência",
              description:
                "Não é permitido trocar recorrêcia de licensas expiradas",
            },
          };
          Toast({
            title: "Erro ao trocar recorrência",
            status: "error",
            duration: 7000,
            ...errorMessage[codError],
          });
        } finally {
          loadDatafast?.();
        }
      },
    });
  }

  const columns: Column[] = [
    {
      name: "Banco",
      render: (row: Fila) => {
        return modalData.banco;
      },
    },
    {
      name: "Data expiração",
      render: (row: Fila) => {
        return row.dataExpiracao ? (
          <StatusFormat bg={row.expirado ? bgError : bgSucess}>
            {formatData(row.dataExpiracao)}
          </StatusFormat>
        ) : null;
      },
    },
    {
      name: "Cobrança Recorrente",
      render: (row: Fila) => {
        return (
          <>
            <StatusFormat bg={row.possuiRecorrencia ? bgInfo : bgWarning}>
              {row.possuiRecorrencia ? "SIM" : "NÃO"}
            </StatusFormat>
          </>
        );
      },
    },
    {
      name: "Ações",
      render: (row: Fila) => {
        const bgRecorrente = new Map([
          ["true", bgSucess],
          ["false", bgWarning],
          ["null", bgInfo],
        ]).get(`${!row.possuiRecorrencia}`)!;

        return (
          <>
            {row.expirado ? (
              <Button
                mr="8px"
                onClick={async () => renovar(row)}
                size="xs"
                variant="outline"
              >
                Renovar
              </Button>
            ) : null}
            {row.expirado ? (
              <Button
                // aria-label=""
                mr="8px"
                onClick={async () => deleteRow(row)}
                size="xs"
                variant="outline"
              >
                Excluir
              </Button>
            ) : null}
            {!row.expirado ? (
              <Button
                onClick={async () => trocarRecorrencia(row)}
                size="xs"
                variant="outline"
              >
                {!row.possuiRecorrencia
                  ? `Ativar cobrança recorrente`
                  : "Desativar cobrança recorrente"}
              </Button>
            ) : null}
          </>
        );
      },
    },
  ];

  const handleCreateNew = () => {
    openModalChangeCobranca({
      modalTitle: "Adicionar Fila",
      onConfirm: async ({ possuiRecorrencia }) => {
        try {
          const { data } = await api.get(
            `/services/datafast/create-mensal-license`,
            {
              params: { customerId, banco: modalData.banco, possuiRecorrencia },
            }
          );
          setModalData(data);
          Toast({ title: "Criado com sucesso", status: "success" });
          loadDatafast?.();
        } catch (e: any) {
          const codError = e?.response?.data?.message ?? "";
          const errorMessage: any = (
            {
              max_20_filas_reached: "Limite de 20 filas atingido",
              sem_saldo: "Saldo insuficiente",
            } as any
          )[codError];
          Toast({
            title: errorMessage ?? "Erro ao criar datafast",
            status: "error",
          });
        } finally {
          setPossuiRecorrencia(undefined);
        }
      },
    });
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={`Filas Banco ${modalData.banco}`}
      size="5xl"
      containerProps={{ alignItems: "flex-start", pt: "50px" }}
    >
      <Flex w="100%" justifyContent="flex-end">
        <Button
          size="sm"
          leftIcon={<AddIcon w="12px" h="12px" />}
          onClick={handleCreateNew}
          variant={"outline"}
        >
          Adicionar Fila
        </Button>
      </Flex>

      <Box
        my="20px"
        mx="-24px"
        w="calc(100% + 48px)"
        borderBottom="1px solid var(--chakra-colors-gray-200)"
      />
      <DynamicTable
        rows={modalData.filas || []}
        columns={columns}
        headerStyle={(header) => ({
          textAlign: header.key === "valor" ? "end" : "center",
          bg: "#fff",
          color: "#656F7D",
          textTransform: "uppercase",
          fontSize: "12px",
          fontWeight: "medium",
          _hover: { bg: "gray.50" },
          px: { base: "12px", "2xl": "20px" },
        })}
        containerStyle={{ boxShadow: "none" }}
      />
    </CustomModal>
  );
}

export const openModalDatafast = (row: ModalDataProps) =>
  makeEvent("openModalDatafast", row);
