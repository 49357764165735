import { Icon } from "@chakra-ui/react";
import { SVGProps } from "react";

export function CheckedBoxIcon({ ...rest }) {
  return (
    <Icon
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_2_1220)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.3469 2.03064L14.3007 2.03814L3.22131 9.60751L3.22881 11.16L14.3063 18.5906L15.3376 18.5981L26.7601 11.1675L26.7657 9.60001L15.3469 2.03064V2.03064ZM5.42256 10.3744L14.8369 3.94314L24.5419 10.3744L14.8369 16.6894L5.42256 10.3744V10.3744ZM14.3082 23.2781L3.36194 15.9375H6.72756L14.8369 21.375L23.1976 15.9375H26.6344L25.1344 16.9125C23.4301 16.7388 21.7176 17.1536 20.2816 18.0878C18.8456 19.0221 17.7725 20.4196 17.2407 22.0481L15.3413 23.2856L14.3082 23.2781V23.2781ZM16.8826 24.7331L14.8388 26.0644L6.72569 20.625H3.36194L14.3082 27.9656L15.3394 27.9731L17.2519 26.73C17.0386 26.0843 16.9143 25.4125 16.8826 24.7331V24.7331ZM21.2494 19.6988C21.8628 19.267 22.5564 18.9624 23.2893 18.8027C24.0222 18.643 24.7797 18.6315 25.5171 18.7688C26.2545 18.9062 26.957 19.1897 27.5832 19.6026C28.2095 20.0155 28.7468 20.5494 29.1636 21.1731C29.5804 21.7967 29.8683 22.4974 30.0102 23.234C30.1522 23.9705 30.1455 24.728 29.9903 25.4619C29.8352 26.1958 29.5349 26.8913 29.107 27.5074C28.6791 28.1235 28.1323 28.6477 27.4988 29.0494C26.2587 29.8356 24.7603 30.1057 23.3237 29.8021C21.8871 29.4984 20.6262 28.6451 19.8102 27.4243C18.9943 26.2035 18.6881 24.712 18.9571 23.2685C19.226 21.825 20.0487 20.5439 21.2494 19.6988V19.6988ZM24.4332 26.8556L27.7894 22.38L26.2894 21.255L23.5144 24.9563L21.8719 23.6438L20.7019 25.1063L23.0982 27.0244L24.4332 26.8556Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_2_1220">
          <rect width="30" height="30" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}

export function CheckIcon2({
  isChecked = true,
  ...rest
}: { isChecked?: boolean } & SVGProps<SVGSVGElement>) {
  return isChecked ? (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M6.5 9L8.16667 10.6667L11.5 7.33333M16.5 9C16.5 9.98491 16.306 10.9602 15.9291 11.8701C15.5522 12.7801 14.9997 13.6069 14.3033 14.3033C13.6069 14.9997 12.7801 15.5522 11.8701 15.9291C10.9602 16.306 9.98491 16.5 9 16.5C8.01509 16.5 7.03982 16.306 6.12987 15.9291C5.21993 15.5522 4.39314 14.9997 3.6967 14.3033C3.00026 13.6069 2.44781 12.7801 2.0709 11.8701C1.69399 10.9602 1.5 9.98491 1.5 9C1.5 7.01088 2.29018 5.10322 3.6967 3.6967C5.10322 2.29018 7.01088 1.5 9 1.5C10.9891 1.5 12.8968 2.29018 14.3033 3.6967C15.7098 5.10322 16.5 7.01088 16.5 9Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ) : (
    <svg
      width="18px"
      height="18px"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M16 8.5C16 9.48491 15.806 10.4602 15.4291 11.3701C15.0522 12.2801 14.4997 13.1069 13.8033 13.8033C13.1069 14.4997 12.2801 15.0522 11.3701 15.4291C10.4602 15.806 9.48491 16 8.5 16C7.51509 16 6.53982 15.806 5.62987 15.4291C4.71993 15.0522 3.89314 14.4997 3.1967 13.8033C2.50026 13.1069 1.94781 12.2801 1.5709 11.3701C1.19399 10.4602 1 9.48491 1 8.5C1 6.51088 1.79018 4.60322 3.1967 3.1967C4.60322 1.79018 6.51088 1 8.5 1C10.4891 1 12.3968 1.79018 13.8033 3.1967C15.2098 4.60322 16 6.51088 16 8.5Z"
        stroke="currentColor"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
