export const csvFields = [
  "valorBeneficio",
  "documento",
  "situacaoBeneficio",
  "uf",
  "possuiCartaoRMC",
  "complemento",
  "ufEmissaoDoc",
  "codigoEspecie",
  "cpf",
  "valorParcelas",
  "margemCartaoRCC",
  "margemCartaoRMC",
  "meioPagamento",
  "cpfRepresentanteLegal",
  "orgaoEmissorDoc",
  "mailingLeadINSSContratos",
  "id",
  "urlFormalizacao",
  "contaPagamentoDV",
  "dataNascimento",
  "agenciaPagamentoDV",
  "contaPagamento",
  "totalContratos",
  "municipio",
  "bairro",
  "nome",
  "nomeRepresentanteLegal",
  "tipoOrgao",
  "beneficio",
  "matricula",
  "nomePai",
  "agenciaPagamento",
  "ufBeneficio",
  "numero",
  "possuiCartaoRCC",
  "naoPerturbe",
  "codigoBancoPagamento",
  "mailing",
  "cep",
  "naturalidade",
  "mailingLeadPhones",
  "margem35",
  "ufOrgao",
  "dataEmissaoDoc",
  "email",
  "valorEmprestimos",
  "ufNaturalidade",
  "ddb",
  "endereco",
  "descricaoOrgao",
  "municipioOrgao",
  "higienizadoTelefonia",
  "codigoFamiliar",
  "sexo",
  "nomeMae",
].sort();

export const firstCsvRow: any = {
  codigoBancoPagamento: "239549",
  mailing: "23848",
  naturalidade: "SP",
  mailingLeadPhones: "84999493443",
  margem35: "",
  ufOrgao: "SP",
  dataEmissaoDoc: "2013-09-03",
  email: "emailteste@gmail.com",
  valorEmprestimos: "1533.06",
  ufNaturalidade: "SP",
  descricaoOrgao: "",
  municipioOrgao: "",
  higienizadoTelefonia: "1",
  codigoFamiliar: "23349",
  sexo: "MASCULINO",
  nomeMae: "MARIA LIMA DA ROCHA",
  naoPerturbe: "0",
  possuiCartaoRCC: "1",
  numero: "3929584",
  ufBeneficio: "SC",
  agenciaPagamento: "0001",
  nomePai: "Alderaldo Lima da Rocha",
  matricula: "12312312",
  tipoOrgao: "",
  nomeRepresentanteLegal: "Maria de Fátima",
  totalContratos: "12",
  contaPagamento: "12345667",
  agenciaPagamentoDV: "2",
  dataNascimento: "2013-09-03",
  contaPagamentoDV: "1",
  id: "123",
  mailingLeadINSSContratos: "",
  orgaoEmissorDoc: "SSP",
  cpfRepresentanteLegal: "123.545.223-35",
  meioPagamento: "Cartão Magnético",
  margemCartao: "450.34",
  margemCartaoRMC: "450.34",
  margemCartaoRCC: "450.34",
  valorParcelas: "85.02",
  codigoEspecie: "44",
  ufEmissaoDoc: "SP",
  complemento: "apto 123",
  situacaoBeneficio: "ATIVO",
  possuiCartaoRMC: "1",
  documento: "456.123.654-34",
  valorBeneficio: 340.65,
  beneficio: 1446215889,
  cpf: 36492353900,
  nome: "MANOEL DO CARMO NASCIMENTO SAM",
  idade: 72,
  rg: 6610270,
  especie: 42,
  meio_pagamento: "CARTAO MAGNETICO",
  banco: 104,
  agencia: "000000",
  conta: "0000000000",
  municipio: "TIJUCAS",
  uf: "SC",
  bairro: "PRACA",
  endereco: "RUA GENERAL DE BRITO 41 CASA",
  cep: 88200000,
  valor_parcelas: 699.87,
  valor_emprestimos: 32305.2,
  margem_35: 35.88,
  total_contratos: 9,
  cartao: "Sim",
  mr_cartao: 0,
  data_nascimento: "1951-07-16",
  nome_mae: "MARIA JOSE SANTANA NASCIMENTO",
  ddb: "2013-09-03",
  vl_beneficio: 2118.22,
  cpf_representante_legal: "123.545.223-35",
  nome_representante_legal: "Eduardo Golsalves Vasquez",
  nao_pertube: 0,
  possui_telefone: 1,
  Telefones: 4832631124,
};
