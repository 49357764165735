import { Pipeline } from ".";
import {
  Box,
  Flex,
  ListItem,
  Progress,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { Fragment } from "react";
import {
  getPipelineRootErrors,
  getPipelineStepTitle,
  groupStepData,
} from "./utils";

export const PIPELINE_STAGES_WITH_CONTENT = [
  "HIGIENIZACAO",
  "SIMULACAO_PORTABILIDADE",
  "TELEFONIA",
  "DIGITACAO",
];

export function ContentPopoverPipelineStage({
  modalData,
  pipelineStage,
}: {
  modalData: Pipeline[] | undefined;
  pipelineStage: Pipeline["pipelineStage"];
}) {
  let pipelinesWithContent = modalData?.filter((higienizacao) =>
    PIPELINE_STAGES_WITH_CONTENT.includes(higienizacao.pipelineStage)
  );

  if (pipelinesWithContent && pipelinesWithContent.length > 0) {
    const pipelineRootErrors = getPipelineRootErrors(pipelinesWithContent);
    const groupedData = groupStepData(pipelinesWithContent, pipelineStage);
    const isEmptyData = Object.keys(groupedData).length === 0;

    if (isEmptyData) return null;
    return (
      <Flex flexDir="column" px="5px" mb={"8px"}>
        <UnorderedList my="8px">
          {Object.keys(groupedData).map((stageKey, i) => {
            const tipoHigienizacaoTitle = getPipelineStepTitle(
              stageKey as Pipeline["pipelineStage"]
            );
            const group = groupedData[stageKey];

            return (
              <ListItem key={`tipoHigienizacao-${i}`}>
                <Text fontSize="15" mb="5px" fontWeight="semibold">
                  {tipoHigienizacaoTitle}
                </Text>
                <UnorderedList>
                  {Object.keys(group).map((keyBanco, i) => {
                    const currentGroup = group[keyBanco];
                    const currentGroupData = currentGroup.data! as any[];
                    const successHigienizacaoProgress =
                      currentGroupData?.filter(
                        ({ lastError, mensagem }) => !lastError && !mensagem
                      ) || [];
                    const errors =
                      currentGroupData?.filter(({ lastError }) => lastError) ||
                      [];
                    const messages =
                      currentGroupData?.filter(({ mensagem }) => mensagem) ||
                      [];

                    return (
                      <Fragment key={`banco-${i}`}>
                        {currentGroup.title ? (
                          <ListItem>
                            <Text mb="5px" fontWeight="semibold">
                              {currentGroup.title}
                            </Text>
                          </ListItem>
                        ) : null}

                        <UnorderedList key={`bank-data-${i}`} my="5px">
                          {successHigienizacaoProgress?.map(
                            ({ totalCompleted = 0, total = 0 }, i) => {
                              return (
                                <ListItem mb="10px" key={`bank-data-${i}`}>
                                  <Text mb="5px" fontWeight="semibold">
                                    Progresso {totalCompleted} de {total}
                                  </Text>
                                  <Box>
                                    <Progress
                                      colorScheme="progressColor"
                                      value={(totalCompleted / total) * 100}
                                      hasStripe
                                      borderRadius="full"
                                      size="sm"
                                    />
                                  </Box>
                                </ListItem>
                              );
                            }
                          )}
                          {errors.length ? (
                            <>
                              <ListItem color="red">
                                <Text fontWeight="semibold">Erros:</Text>
                              </ListItem>
                              <UnorderedList mb="8px">
                                {errors.map(
                                  ({ lastError, totalCompleted }, i) => {
                                    return (
                                      <ListItem
                                        color="red.700"
                                        key={`error-${i}`}
                                        whiteSpace="normal"
                                      >
                                        <Text>{lastError}</Text>
                                        <Text>
                                          Quantidade: {totalCompleted}
                                        </Text>
                                      </ListItem>
                                    );
                                  }
                                )}
                              </UnorderedList>
                            </>
                          ) : null}
                          {messages.length ? (
                            <>
                              <ListItem>
                                <Text fontWeight="semibold">Mensagens:</Text>
                              </ListItem>
                              <UnorderedList>
                                {messages.map(
                                  ({ mensagem, totalCompleted }, i) => {
                                    return (
                                      <ListItem key={`error-${i}`}>
                                        <Text
                                          dangerouslySetInnerHTML={{
                                            __html: mensagem!,
                                          }}
                                          whiteSpace="normal"
                                          sx={{ br: { fontWeight: "bold" } }}
                                        />
                                        <Text>
                                          Quantidade: {totalCompleted}
                                        </Text>
                                      </ListItem>
                                    );
                                  }
                                )}
                              </UnorderedList>
                            </>
                          ) : null}
                        </UnorderedList>
                      </Fragment>
                    );
                  })}
                  {pipelineRootErrors?.map((err, i) => (
                    <Flex flexDir="column">
                      {err !== null ? (
                        <UnorderedList my="8px">
                          <ListItem mb="10px" key={`bank-data-${i}`}>
                            <Text fontWeight="semibold">Outros:</Text>
                          </ListItem>
                          <UnorderedList>
                            <ListItem whiteSpace="normal">
                              <Text color="red.700">{err}</Text>
                            </ListItem>
                          </UnorderedList>
                        </UnorderedList>
                      ) : null}
                    </Flex>
                  ))}
                </UnorderedList>
              </ListItem>
            );
          })}
        </UnorderedList>
      </Flex>
    );
  } else return null;
}
