import { CheckIcon } from "@chakra-ui/icons";
import {
  Box,
  BoxProps,
  Flex,
  FlexProps,
  Text,
  TextProps,
} from "@chakra-ui/react";
import { Popover, PopoverProps } from "components/popover";
import { ReactNode, memo, useEffect, useRef, useState } from "react";

export interface CheckboxProps extends Omit<BoxProps, "onChange" | "p"> {
  onChange?: (isChecked: boolean) => void;
  isChecked?: boolean;
  isDisabled?: boolean;
  p?: string;
  children?: JSX.Element | ReactNode;
  boxProps?: FlexProps;
  checkedIcon?: (isChecked: boolean) => JSX.Element | ReactNode;
  popoverProps?: PopoverProps;
  errorMessage?: string;
  messageErrorProps?: TextProps;
  disabled?: boolean;
}

export function Checkbox({
  onChange,
  isChecked,
  p = "4px",
  isDisabled,
  children,
  boxProps,
  checkedIcon,
  errorMessage,
  popoverProps,
  messageErrorProps,
  disabled,
  ...rest
}: CheckboxProps) {
  const checkRef = useRef<SVGSVGElement>(null);

  let isInvalid = false;
  if (errorMessage) isInvalid = true;
  if (disabled != null) isDisabled = disabled;

  return (
    <Box pos="relative" role="group">
      <Flex
        p={p}
        onClick={(e) => {
          e.stopPropagation();
          if (isDisabled) return;
          const el = checkRef.current!;
          const { style } = el;
          const isChecked = window.getComputedStyle(el).opacity === "1";
          if (isChecked) {
            if (isChecked === undefined) style.opacity = "0";
            onChange?.(false);
          } else {
            if (isChecked === undefined) style.opacity = "1";
            onChange?.(true);
          }
        }}
        // onMouseEnter={() => errorMessage && setIsOpen(true)}
        // onMouseLeave={() => errorMessage && setIsOpen(false)}
        alignItems="center"
        cursor="pointer"
        userSelect="none"
        borderRadius="6px"
        border={
          isInvalid ? "3px solid var(--chakra-colors-secondary-600)" : undefined
        }
        opacity={isDisabled ? 0.4 : 1}
        pointerEvents={isDisabled ? "none" : "auto"}
        {...rest}
      >
        <Flex
          w="16px"
          h="16px"
          border={!isChecked ? "1px solid #C8C8C8" : ""}
          justifyContent="center"
          alignItems="center"
          borderRadius="2px"
          mr={children ? "8px" : undefined}
          bg={isChecked ? "#27D144" : ""}
          {...boxProps}
        >
          {checkedIcon?.(!!isChecked) ?? (
            <CheckIcon
              ref={checkRef}
              opacity={isChecked ? "1" : "0"}
              w="10px"
              h="auto"
              color="white"
            />
          )}
        </Flex>

        {children}
      </Flex>
      {errorMessage ? (
        <Box
          bg="secondary.700"
          minW="26px"
          maxW="80%"
          pos="absolute"
          borderRadius="4px"
          p="3px"
          right={0}
          top="25px"
          zIndex="10"
          border="1px solid var(--chakra-colors-secondary-400)"
          transition="0.2s"
          transform="scale(0.8)"
          visibility="hidden"
          opacity={0}
          _groupHover={{
            transform: "scale(1)",
            visibility: "visible",
            opacity: "1",
          }}
        >
          <Box
            w="10px"
            h="10px"
            zIndex="-1"
            transform="rotate(45deg)"
            top="-6px"
            bg="secondary.700"
            right="10px"
            pos="absolute"
            borderTop="1px solid var(--chakra-colors-secondary-400)"
            borderLeft="1px solid var(--chakra-colors-secondary-400)"
            borderRadius="0 0 10px 0"
          />
          <Text
            color="#fff"
            fontWeight="bold"
            fontSize="9"
            whiteSpace="pre-wrap"
            {...messageErrorProps}
          >
            {errorMessage}&nbsp;
          </Text>
        </Box>
      ) : null}
    </Box>
  );
}

export default memo(Checkbox);
