import { Icon, IconProps } from "@chakra-ui/react";

export function KanbanIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="15px"
      height="17px"
      viewBox="0 0 15 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <rect
        x="0.5"
        y="3.80829"
        width="5.37373"
        height="11.9607"
        rx="1.27679"
        stroke="currentColor"
      />
      <rect
        x="8.46533"
        y="3.80829"
        width="5.36689"
        height="5.48035"
        rx="1.27679"
        stroke="currentColor"
      />
      <path
        d="M0.567627 1.15843L5.76927 1.15843"
        stroke="currentColor"
        strokeLinecap="round"
      />
      <path
        d="M8.5603 1.15843L13.762 1.15843"
        stroke="currentColor"
        strokeLinecap="round"
      />
    </Icon>
  );
}
