import { Icon, IconProps } from "@chakra-ui/react";

export function DiagonalArrowIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="6px"
      height="6px"
      viewBox="0 0 6 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M5.01766 5.02977L5.01766 1.15671C5.01609 1.08461 5.0003 1.01354 4.97119 0.947562C4.91564 0.81453 4.80989 0.708765 4.67688 0.653209C4.61091 0.6241 4.53984 0.608306 4.46776 0.606732L0.595183 0.606732C0.522968 0.606732 0.451461 0.620957 0.384743 0.648596C0.318026 0.676235 0.257404 0.716746 0.206341 0.767816C0.103213 0.870956 0.0452771 1.01084 0.0452771 1.15671C0.0452771 1.30257 0.103214 1.44246 0.206341 1.5456C0.309468 1.64874 0.449339 1.70668 0.595183 1.70668L3.14721 1.70281L0.207926 4.64247C0.105219 4.74519 0.0475183 4.88451 0.0475183 5.02977C0.0475183 5.17504 0.105219 5.31436 0.207926 5.41708C0.310633 5.5198 0.449933 5.57751 0.595183 5.57751C0.740433 5.57751 0.879734 5.5198 0.982441 5.41708L3.92172 2.47742L3.91785 5.02977C3.91756 5.10208 3.93158 5.17373 3.95911 5.24059C3.98664 5.30745 4.02713 5.3682 4.07825 5.41933C4.12938 5.47045 4.19012 5.51095 4.25697 5.53849C4.32382 5.56602 4.39546 5.58004 4.46776 5.57975C4.54005 5.58004 4.6117 5.56602 4.67855 5.53849C4.7454 5.51095 4.80614 5.47045 4.85726 5.41933C4.90838 5.3682 4.94888 5.30745 4.97641 5.24059C5.00394 5.17373 5.01796 5.10208 5.01766 5.02977Z"
        fill="currentColor"
      />
    </Icon>
  );
}
