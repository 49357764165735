import api from "api/api";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useReducer,
  useState,
} from "react";
import { getToken, hasSession, removeToken, setSession } from "Session";
import {
  ContextProps,
  User,
  UserActions,
} from "./types/application-context-types";
import { useRouter } from "routes/router-control/use-router";
import { ModalErrorOptions } from "components/modal-error";

const initialValues: ContextProps = {
  setUserAction: () => undefined,
  user: { isLogged: false, userData: {}, isLogging: true },
  setMenuErrorMessage: () => undefined,
  menuErrorMessage: undefined,
};

const applicationContext = createContext<ContextProps>(initialValues);

const initialState = { isLogged: false, isLogging: true, userData: {} };

function reducer(state: User, action: UserActions) {
  switch (action.type) {
    case "login":
      const { token, userData } = action.payload;
      setSession(token);
      return { ...state, userData, isLogging: false, isLogged: true };
    case "logout":
      removeToken();
      return { ...state, userData: {}, isLogging: false, isLogged: false };
    case "logging":
      return { ...state, isLogging: action.payload };
    default:
      return state;
  }
}

export const ApplicationContextProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const [user, setUserAction] = useReducer(reducer, initialState);
  const [menuErrorMessage, setMenuErrorMessage] = useState<ModalErrorOptions>();
  const router = useRouter();
  const params = new URLSearchParams(window.location.search);
  const autoLogin = params.get("a");

  if (autoLogin) {
    setSession(autoLogin);
    params.delete("a");
    const otherParams = !!params.toString();
    window.history.pushState(
      null,
      "",
      router.pathname + (otherParams ? "?" + params.toString() : "")
    );
  }

  useEffect(() => {
    const thisRouter =
      window.location.pathname !== "/login" ? window.location.pathname : null;

    if (!hasSession()) {
      router.push({ pathname: "/login", payload: { prevPath: thisRouter } });
    } else {
      const login = async () => {
        try {
          const token = getToken();
          const response = await api.get("/usuarios/login", {
            headers: { Authorization: `Basic ${token}` },
          });
          setUserAction({
            type: "login",
            payload: { token: token!, userData: response.data },
          });
        } catch {
          removeToken();
          router.push({
            pathname: "/login",
            payload: { prevPath: thisRouter },
          });
        } finally {
          setUserAction({ type: "logging", payload: false });
        }
      };
      login();
    }
  }, []);

  return (
    <applicationContext.Provider
      value={{
        setUserAction,
        user,
        menuErrorMessage,
        setMenuErrorMessage,
      }}
    >
      {children}
    </applicationContext.Provider>
  );
};

export const useApplicationContext = () => {
  const context = useContext(applicationContext);
  if (context === undefined)
    throw new Error(
      "useApplicationContext must be used within a ApplicationContextProvider"
    );
  return context;
};
