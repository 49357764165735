import { Box, BoxProps, Button, Text, TextProps } from "@chakra-ui/react";
import { Popover, PopoverProps } from "components/popover";
import { useEffect, useState } from "react";

export function WithInvalidBox({
  isInvalid,
  errorMessage,
  messageErrorProps,
  popoverProps,
  ...rest
}: BoxProps & {
  isInvalid?: boolean;
  errorMessage?: string;
  messageErrorProps?: TextProps;
  popoverProps?: Partial<PopoverProps>;
}) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (!errorMessage) setIsOpen(false);
    else setIsOpen(true);
  }, [!!errorMessage]);

  const { border, ...boxProps } = rest;

  return (
    <Popover
      button={
        <Box
          w="100%"
          border={
            isInvalid || !!errorMessage
              ? "4px solid var(--chakra-colors-red-600)"
              : border
          }
          {...boxProps}
        />
      }
      onClose={() => setIsOpen(false)}
      isOpen={isOpen}
      // position="right-bottom"
      variant="danger"
      {...popoverProps}
    >
      <Text color="#fff" fontWeight="bold" fontSize="12" {...messageErrorProps}>
        {errorMessage}&nbsp;
      </Text>
    </Popover>
  );
}
