import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { openModalConfirm } from "components/modal-confirm-new";
import { openModalError } from "components/modal-error";
import { Dispatch, SetStateAction } from "react";
import { mapObject } from ".";
import { AttendanceActionsTypes } from "../../../../../contexts/attendance-context/actions-reducer";
import { KeyForward } from "./types";
import apiIn100 from "api/api-in100";
import { parseIn100SituacaoBeneficioToAttendanceFormat } from "components/consulta-in100-components/utils";

export function in100Map(
  data: any,
  dispatch: Dispatch<AttendanceActionsTypes>,
  attendance: Attendance
) {
  let margens = attendance.margens! || {};
  data.possuiCartaoRmc = data.margemDisponivelCartao === 0;
  data.possuiCartaoBeneficio = data.margemDisponivelRcc === 0;

  const keyForward: KeyForward<Attendance> = {
    cpf: (data) => {
      if (data != null) {
        data = `${data}`.trim();
        while (data.length < 11) data = "0" + data;
      }
      return { data, field: "cpf", assignJson: { flag: "IN100" } };
    },
    cbcIfPagadora: (data) => {
      return {
        field: "codBancoPagamento",
        data,
        assignJson: { flag: "IN100" },
      };
    },
    especieBeneficio: (data) => {
      return { data, field: "especieNB", assignJson: { flag: "IN100" } };
    },
    nomeBeneficiario: (data) => {
      return { data, field: "nome", assignJson: { flag: "IN100" } };
    },
    dataNascimento: (data) => ({
      data: data ? new Date(data).toLocaleDateString() : undefined,
      field: "dataNascimento",
      assignJson: { flag: "IN100" },
    }),
    ufPagamento: (data) => {
      return { data, field: "ufNB", assignJson: { flag: "IN100" } };
    },
    contaCorrente: (data) => {
      return { data, field: "contaPagamento", assignJson: { flag: "IN100" } };
    },
    pensaoAlimenticia: (data) => ({
      data: !!data,
      field: "pensaoAlimenticia",
      assignJson: { flag: "IN100" },
    }),
    possuiRepresentanteLegal: (data) => ({
      data: !!data,
      field: "possuiRepresentante",
      assignJson: { flag: "IN100" },
    }),
    // valorLimiteCartao: "valor",
    bloqueadoParaEmprestimo: (data) => {
      return {
        data,
        field: "bloqueadoEmprestimo",
        assignJson: { flag: "IN100" },
      };
    },
    nomeRepresentanteLegal: (data) => {
      return {
        data,
        field: "nomeRepresentanteLegal",
        assignJson: { flag: "IN100" },
      };
    },
    cpfRepresentanteLegal: (data) => {
      return {
        data,
        field: "cpfRepresentanteLegal",
        assignJson: { flag: "IN100" },
      };
    },
    elegivelEmprestimo: (data) => {
      return {
        data,
        field: "elegivelEmprestimo",
        assignJson: { flag: "IN100" },
      };
    },
    margemDisponivel: (value) => {
      margens = { ...margens, valorMargemLivreNovo: value };
      return {
        data: margens,
        field: "margens",
        assignJson: {
          attendanceKeyPath: "margens.valorMargemLivreNovo",
          flag: "IN100",
        },
      };
    },
    margemDisponivelCartao: (value) => {
      margens = { ...margens, valorMargemCartaoRMC: value };
      return {
        data: margens,
        field: "margens",
        assignJson: {
          flag: "IN100",
          attendanceKeyPath: `margens.valorMargemCartaoRMC`,
        },
      };
    },
    margemDisponivelRcc: (value) => {
      margens = { ...margens, valorMargemCartaoRCC: value };
      return {
        data: margens,
        field: "margens",
        assignJson: {
          flag: "IN100",
          attendanceKeyPath: `margens.valorMargemCartaoRCC`,
        },
      };
    },
    possuiCartaoRmc: (value) => {
      return {
        data: value,
        field: "possuiCartaoRmc",
        assignJson: { flag: "IN100" },
      };
    },
    possuiCartaoBeneficio: (value) => {
      return {
        data: value,
        field: "possuiCartaoBeneficio",
        assignJson: { flag: "IN100" },
      };
    },
    tipoCredito: (data) => {
      if (data === "1") data = "CARTAO_MAGNETICO";
      else if (data === "2") data = "CONTA_CORRENTE";
      else data = null;
      return { data, field: "tipoPagamento", assignJson: { flag: "IN100" } };
    },
    agenciaPagadora: (data) => {
      return { data, field: "agenciaPagamento", assignJson: { flag: "IN100" } };
    },
    dataDespachoBeneficio: (data: string | null) => {
      if (data?.includes("T"))
        data = data.split("T")[0].split("-").reverse().join("/");
      return { data, field: "ddb", assignJson: { flag: "IN100" } };
    },
    situacaoBeneficio: (data) => {
      data = parseIn100SituacaoBeneficioToAttendanceFormat[data ?? ""];
      return { data, field: "situacaoNB", assignJson: { flag: "IN100" } };
    },
  };

  mapObject({ keyForward, data, dispatch });
}

export async function in100(
  dispatch: Dispatch<AttendanceActionsTypes>,
  formValues: Attendance,
  loading: Dispatch<SetStateAction<boolean>>,
  { signal }: AbortController
) {
  const { possuiRepresentante, cpfRepresentanteLegal, nb, cpf } = formValues;
  loading(true);
  try {
    const { data } = await apiIn100.get(`/consulta/3/pre`, {
      signal,
      params: {
        nb,
        cpf: cpf,
        cpfRepresentante: possuiRepresentante
          ? cpfRepresentanteLegal
          : undefined,
      },
    });
    openModalConfirm({
      onConfirm: () => {
        in100Map(data, dispatch, formValues);
      },
      message: "Deseja sobrescrever com os dados da consulta IN100?",
    });
  } catch (e: any) {
    const data = e?.response?.data;
    const errorMessage = typeof data === "string" ? data : undefined;
    openModalError({ message: errorMessage ?? "Erro ao consultar IN100" });
  } finally {
    loading(false);
  }
}
