import { Icon, IconProps } from "@chakra-ui/icons";
import { SVGProps } from "react";

export function WarningIcon({ ...rest }: IconProps) {
  return (
    <Icon
      width="24px"
      height="22px"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_19_11978)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.84492 2.01165L0.458889 16.6514H0.456936C0.396806 16.7452 0.346413 16.8449 0.306557 16.9492C0.0944463 17.4982 -0.0096996 18.0837 -5.82496e-05 18.6729C0.00935398 19.3468 0.212943 20.0033 0.585832 20.5623C0.95694 21.1111 1.48593 21.5317 2.10133 21.7673C2.48866 21.914 2.89852 21.9907 3.31217 21.9941H3.44107H20.3303C20.9411 22.0128 21.5485 21.8956 22.1095 21.6509L22.2227 21.5996C22.7958 21.3152 23.2688 20.8602 23.5781 20.296C23.8519 19.7909 23.9968 19.225 23.9999 18.6492C24.0062 17.9517 23.8476 17.2627 23.5371 16.6395L23.4922 16.5528L15.2428 2.08857C15.2214 2.04518 15.1979 2.00377 15.1725 1.96235C14.8284 1.38797 14.3613 0.898713 13.8055 0.530524C13.2754 0.184324 12.6576 0.000100501 12.0263 0C11.3945 0.00274634 10.7766 0.187476 10.2452 0.532497C9.69053 0.899657 9.22359 1.3867 8.87813 1.9584L8.84492 2.01165ZM11.9228 15.9986C11.6667 16.0172 11.4257 16.1278 11.2432 16.3102L11.2393 16.3161C11.1449 16.4017 11.0697 16.5066 11.0186 16.6238C10.9597 16.748 10.9221 16.8813 10.9073 17.0182C10.9016 17.1045 10.9055 17.1912 10.919 17.2766C10.932 17.3625 10.955 17.4465 10.9874 17.5271C11.0917 17.7837 11.2885 17.9909 11.5381 18.1069C11.6773 18.1717 11.8282 18.2066 11.9814 18.2094C12.2425 18.218 12.4979 18.1309 12.7004 17.9641C12.9028 17.7974 13.0387 17.5623 13.0829 17.3022C13.0987 17.2111 13.1039 17.1184 13.0985 17.0261C13.0808 16.7617 12.9683 16.5128 12.7821 16.326C12.671 16.2135 12.538 16.1255 12.3915 16.0676C12.2419 16.0112 12.0822 15.9877 11.9228 15.9986ZM11.1768 14.2907C11.2139 15.2373 12.7978 15.2353 12.8368 14.2907L13.3954 7.04278V6.99545C13.3954 6.51423 12.997 6.23023 12.4872 6.11979C12.1649 6.05512 11.8331 6.05512 11.5108 6.11979C11.0108 6.22629 10.6339 6.50437 10.6202 6.97376C10.6065 8.49433 11.0167 12.6715 11.1768 14.2907Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_19_11978">
          <rect width="24" height="22" fill="white" />
        </clipPath>
      </defs>
    </Icon>
  );
}
