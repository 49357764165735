import { SessoesWhatsApp } from "pages/sessoes-whatsapp";
import { User, UserTypes } from "contexts/types/application-context-types";
import { CampanhaNova } from "pages/campanha-page";
import { Empresas } from "pages/Empresas";
import { ExtratoOnlineLote } from "pages/extrato-online-lote";
import { ExtratoOnlineAvulso } from "pages/ExtratoOnline";
import { In100Page } from "pages/in100";
import { Login } from "pages/login";
import { MailingPage } from "pages/mailing";
import { NotFound } from "pages/not-found";
import { Usuarios } from "pages/usuarios";
import { UsuariosBancos } from "pages/usuarios-bancos";
import { CampanhaURAWhatsapp } from "pages/campanha-ura-whatsapp";
import { CampanhaSMS } from "pages/campanha-sms";
import { IndexPage } from "pages/index-page";
import { Consulta } from "pages/consulta/consulta-offline";
import { WalletPage } from "pages/carteira";
import { ServicosPage } from "pages/servicos";
import { RefinHigienizacaoCartao } from "pages/servicos/higienizacao-api-cartao-banco";
import { RefinHigienizacaoBanco } from "pages/servicos/higienizacao-refin";
import { WhatsAppServicePage } from "pages/servicos/whatsapp-service";
import { AtendimentoNovoKanban } from "pages/atendimento-novo-kanban";
import { AtendimentoClientePage } from "pages/atendimento-novo-kanban/atendimento";
import { ConsultaIn100 } from "pages/consulta/consulta-in100";
import { MargemConvenioServicePage } from "pages/servicos/margem-convenio-service";
import { TabelasDigitacaoPage } from "pages/tabelas";
import { ConsultaTelefonia } from "pages/consulta/telefonia";
import { isColomboUser } from "utils/is-colombo-user";

export interface RouterType {
  path: string;
  exact?: boolean;
  Component: () => JSX.Element;
  permission: (a: User | undefined) => boolean;
}

function checkPermission(
  user: User | undefined,
  validUserTypes: UserTypes[],
  colomboValidUserTypes?: UserTypes[]
) {
  if (user?.userData.customerCodigoNova?.startsWith("1309"))
    return !!colomboValidUserTypes?.includes(user?.userData.type!);
  return validUserTypes.includes(user?.userData.type!);
}

export const allRouters: RouterType[] = [
  {
    path: "/login",
    Component: Login,
    permission: () => true,
  },
  {
    path: "/atendimentos",
    Component: AtendimentoNovoKanban,
    permission: (user) =>
      checkPermission(
        user,
        [
          "SUPER",
          "REGIONAL",
          "COMERCIAL",
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ],
        ["PARCEIRO_MASTER", "PARCEIRO_VENDEDOR"]
      ),
  },
  {
    path: "/atendimento-cliente-page",
    Component: AtendimentoClientePage,
    permission: (user) =>
      checkPermission(
        user,
        [
          "SUPER",
          "REGIONAL",
          "COMERCIAL",
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ],
        ["PARCEIRO_MASTER", "PARCEIRO_VENDEDOR"]
      ),
  },
  {
    path: "/",
    exact: true,
    Component: IndexPage,
    permission: (user) => !!user?.isLogged,
  },
  {
    path: "/in100",
    Component: In100Page,
    permission: (user) => !!user?.isLogged,
  },
  {
    path: "/mailing",
    Component: MailingPage,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "PARCEIRO_MASTER"],
        ["COMERCIAL", "PARCEIRO_MASTER", "PARCEIRO_VENDEDOR"]
      ),
  },
  {
    path: "/carteira",
    Component: WalletPage,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "PARCEIRO_MASTER", "FINANCEIRO"],
        ["COMERCIAL"]
      ),
  },
  {
    path: "/higienizacao-api-cartao-banco",
    Component: RefinHigienizacaoCartao,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "PARCEIRO_MASTER", "FINANCEIRO"],
        ["COMERCIAL"]
      ),
  },
  {
    path: "/higienizacao-refin",
    Component: RefinHigienizacaoBanco,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "PARCEIRO_MASTER", "FINANCEIRO"],
        ["COMERCIAL"]
      ),
  },
  {
    path: "/servicos",
    Component: ServicosPage,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "PARCEIRO_MASTER", "FINANCEIRO"],
        ["COMERCIAL"]
      ),
  },
  {
    path: "/margem-convenio-service",
    Component: MargemConvenioServicePage,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "PARCEIRO_MASTER", "FINANCEIRO"],
        ["COMERCIAL"]
      ),
  },
  // {
  //   path: "/contas-usuarios",
  //   Component: ContasUsuarioServicePage,
  //   permission: (user) =>
  //     checkPermission(
  //       user,
  //       ["SUPER", "REGIONAL", "COMERCIAL", "PARCEIRO_MASTER", "FINANCEIRO"],
  //       ["COMERCIAL"]
  //     ),
  // },

  {
    path: "/whatsapp-service",
    Component: WhatsAppServicePage,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "PARCEIRO_MASTER", "FINANCEIRO"],
        ["COMERCIAL"]
      ),
  },
  {
    path: "/usuarios-bancos",
    Component: UsuariosBancos,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "PARCEIRO_MASTER"],
        ["COMERCIAL"]
      ),
  },
  {
    path: "/extrato-online-avulso",
    Component: ExtratoOnlineAvulso,
    permission: (user) =>
      checkPermission(
        user,
        [
          "SUPER",
          "REGIONAL",
          "COMERCIAL",
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ],
        ["COMERCIAL"]
      ),
  },
  {
    path: "/extrato-online-lote",
    Component: ExtratoOnlineLote,
    permission: (user) => !!user?.isLogged,
  },
  {
    path: "/campanha-whatsapp",
    Component: CampanhaNova,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "PARCEIRO_MASTER"],
        ["COMERCIAL"]
      ),
  },
  {
    path: "/campanha-sms",
    Component: CampanhaSMS,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "PARCEIRO_MASTER"],
        ["COMERCIAL"]
      ),
  },
  {
    path: "/campanha-ura-whatsapp",
    Component: CampanhaURAWhatsapp,
    permission: (user) =>
      checkPermission(user, [
        "SUPER",
        "REGIONAL",
        "COMERCIAL",
        "PARCEIRO_MASTER",
      ]),
  },
  // {
  //   path: "/atendimentos",
  //   Component: AtendimentoPage,
  //   permission: (user) =>
  //     checkPermission(
  //       user,
  //       [
  //         "SUPER",
  //         "REGIONAL",
  //         "COMERCIAL",
  //         "PARCEIRO_MASTER",
  //         "PARCEIRO_VENDEDOR",
  //         "PARCEIRO_OPERACIONAL",
  //       ],
  //       ["PARCEIRO_MASTER", "PARCEIRO_VENDEDOR"]
  //     ),
  // },
  {
    path: "/consulta",
    Component: Consulta,
    permission: (user) =>
      checkPermission(
        user,
        [
          "SUPER",
          "REGIONAL",
          "COMERCIAL",
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ],
        ["COMERCIAL"]
        // ["PARCEIRO_MASTER", "PARCEIRO_VENDEDOR"]
      ),
  },
  {
    path: "/consulta-in100",
    Component: ConsultaIn100,
    permission: (user) =>
      checkPermission(
        user,
        [
          "SUPER",
          "REGIONAL",
          "COMERCIAL",
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ],
        ["COMERCIAL"]
        // ["PARCEIRO_MASTER", "PARCEIRO_VENDEDOR"]
      ),
  },
  {
    path: "/consulta-telefonia",
    Component: ConsultaTelefonia,
    permission: (user) =>
      checkPermission(
        user,
        [
          "SUPER",
          "REGIONAL",
          "COMERCIAL",
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ],
        ["COMERCIAL"]
        // ["PARCEIRO_MASTER", "PARCEIRO_VENDEDOR"]
      ),
  },
  // {
  //   path: "/tabelas-digitacao",
  //   Component: TebelasComissao,
  //   permission: (user) =>
  //     checkPermission(user, [
  //       "SUPER",
  //       "REGIONAL",
  //       "COMERCIAL",
  //       "PARCEIRO_MASTER",
  //     ]),
  // },
  {
    path: "/tabelas",
    Component: TabelasDigitacaoPage,
    permission: (user) =>
      checkPermission(user, [
        "SUPER",
        "REGIONAL",
        "COMERCIAL",
        "PARCEIRO_MASTER",
      ]),
  },
  {
    path: "/sessoes-whatsapp",
    Component: SessoesWhatsApp,
    permission: (user) =>
      checkPermission(
        user,
        [
          "SUPER",
          "REGIONAL",
          "COMERCIAL",
          "PARCEIRO_MASTER",
          "PARCEIRO_VENDEDOR",
          "PARCEIRO_OPERACIONAL",
        ],
        ["COMERCIAL"]
      ),
  },
  {
    path: "/empresas",
    Component: Empresas,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "FINANCEIRO"],
        ["COMERCIAL"]
      ),
  },
  {
    path: "/usuarios",
    Component: Usuarios,
    permission: (user) =>
      checkPermission(
        user,
        ["SUPER", "REGIONAL", "COMERCIAL", "PARCEIRO_MASTER"],
        ["COMERCIAL", "PARCEIRO_MASTER"]
      ),
  },
  {
    path: "*",
    Component: NotFound,
    permission: () => true,
  },
];
