import { Box, BoxProps, LinkProps } from "@chakra-ui/react";
import { AnchorHTMLAttributes, forwardRef } from "react";

export const SkeletonLoading = forwardRef<
  HTMLDivElement,
  { isLoading: boolean } & BoxProps & { href?: string }
>(({ children, isLoading, ...rest }, ref) => {
  return isLoading ? (
    <Box ref={ref} overflow="hidden" pos="relative" {...rest}>
      <Box zIndex="-1">{children}</Box>
      <Box pos="absolute" inset="0" zIndex="9" bg="gray.300" />
      <Box
        // ref={animatedDivRef}
        pos="absolute"
        inset="0"
        zIndex="10"
        animation="skeleton 1.2s infinite"
        bgImage="linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0))"
      />
    </Box>
  ) : Object.keys(rest).length || ref ? (
    <Box ref={ref} {...rest}>
      {children}
    </Box>
  ) : (
    <>{children}</>
  );
});
