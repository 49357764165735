import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Text } from "@chakra-ui/react";
import api from "api/api";
import axios, { AxiosError } from "axios";
import { toastDefaultStyle } from "chakra/theme";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import { CustomModal } from "components/custom-modal";
import { DropdownField } from "components/dropdown-field";
import { InputSearch } from "components/input-search";
import { useAttendanceContext } from "contexts/attendance-context";
import { useEffect, useState } from "react";
import { useEventListener, makeEvent } from "services/events";
import { Toast } from "components/toast";

interface ModalDataProps {
  multiSelect?: boolean;
  type: "RCC" | "RMC" | "REFIN";
  idAtendimento: number;
  onClose?: () => void;
}

interface ModalProps {}

// {
//   "banco": "C6",
//   "tipoHigienizacao": "REFIN",
//   "tipoCartao": null,
//   "finalizado": true,
//   "dataFinalizacao": "2024-02-07T01:49:08.000-03:00",
//   "error": null,
//   "contratos": [
//       {
//           "id": 406457,
//           "contrato": "1",
//           "codTipoEmprestimo": 98,
//           "codBanco": 626,
//           "dataInicioContrato": "",
//           "competenciaInicioDesconto": "",
//           "competenciaFimDesconto": null,
//           "situacao": null,
//           "valorEmprestado": 0.00,
//           "valorParcela": 550.00,
//           "quantidadeParcelas": 84,
//           "quantidadeParcelasEmAberto": 77,
//           "saldoQuitacao": 24007.17,
//           "taxa": 1.63,
//           "simular": null
//       }
//   ]
// }

const banks: any = {
  RCC: [{ name: "243 - Master", value: "MASTER" }],
  RMC: [
    { name: "243 - Master", value: "MASTER" },
    { name: "623 - Pan", value: "PAN" },
    { name: "318 - Bmg", value: "BMG" },
  ],
  REFIN: [
    { name: "001 - Banco do Brasil", value: "BB" },
    { name: "318 - Bmg", value: "BMG" },
    { name: "626 - C6", value: "C6" },
    { name: "394 - Bradesco Promotora", value: "BRADESCO" },
    { name: "955 - Olé", value: "OLE" },
    { name: "623 - Pan", value: "PAN" },
    { name: "29 - Itau Consignado", value: "ITAU" },
    { name: "041 - Banrisul", value: "BANRISUL" },
    { name: "69 - Crefisa", value: "CREFISA" },
    { name: "81 - Pagbank", value: "PAGBANK" },
    { name: "422 - Safra", value: "SAFRA" },
    { name: "707 - Daycoval", value: "DAYCOVAL" },
  ],
};

export function ModalUpdateContract({}: ModalProps) {
  const [modalData, setModalData] = useState<Partial<ModalDataProps>>({});
  const { dispatch, formControl } = useAttendanceContext();
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingConvenioList, setIsLoadingConvenioList] = useState(false);
  const [bankListOptions, setBankListOptions] = useState<
    { name: string; value: string }[]
  >([]);
  const [bank, setBank] = useState<any | any[]>(null);
  const [idConvenio, setIdConvenio] = useState<any>(null);
  const [convenioList, setConvenioList] = useState<
    { name: string; value: any }[]
  >([]);
  const withConvenio =
    ["BMG", "PAN", "MASTER"].includes(bank) ||
    (modalData.type === "REFIN" ? bank?.includes?.("PAN") : false);

  let { statusHigienizacoesAtendimento } = formControl.values;
  statusHigienizacoesAtendimento =
    statusHigienizacoesAtendimento ||
    ({} as Attendance["statusHigienizacoesAtendimento"]);

  let convenioName =
    convenioList?.find((curr) => curr.value === idConvenio)?.name ?? "";

  const onOpen = (modalData: ModalDataProps) => {
    setIsOpen(true);
    setModalData(modalData);
    setBankListOptions(banks[modalData?.type ?? ""]);
  };

  const onClose = () => {
    setIsOpen(false);
    setIsLoading(false);
    setBankListOptions([]);
    setBank(null);
    setIdConvenio(null);
    modalData.onClose?.();
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post(
        `/atendimento-higienizacao/${modalData?.idAtendimento}/${modalData.type}`,
        undefined,
        {
          params: {
            bancos: Array.isArray(bank) ? bank.join(",") : bank,
            idConvenio: withConvenio ? idConvenio : undefined,
          },
        }
      );
      const field = {
        RCC: () => (statusHigienizacoesAtendimento!.hasPendingRCC = true),
        RMC: () => (statusHigienizacoesAtendimento!.hasPendingRMC = true),
        REFIN: () =>
          (statusHigienizacoesAtendimento!.pendingEmprestimos = bank),
      }[modalData.type!]();
      dispatch({
        type: "changeField",
        payload: {
          data: { ...statusHigienizacoesAtendimento },
          fieldName: "statusHigienizacoesAtendimento",
        },
      });
      onClose();
      Toast({ title: "Higienização iniciada" });
    } catch (e: any) {
      const message = e?.response?.data?.message;
      Toast({ title: message ?? "Erro ao obter bancos", duration: 7000 });
    } finally {
      setIsLoading(false);
    }
  };

  const onChangeMultiSelectDropdownBank = (value: string) => {
    const currentValue = bank ?? [];
    if (currentValue?.length && currentValue != null) {
      const isInclude = currentValue?.includes(value);
      if (isInclude)
        setBank(currentValue.filter((curr: any) => curr !== value));
      else setBank([...currentValue, value]);
    } else setBank([value]);
  };

  const onChangeBank = (value: string) => {
    setBank(value);
    setConvenioList([]);
  };

  const formatConvenioList = (bank: "MASTER" | "BMG" | "PAN", data: any) => {
    return {
      MASTER: () => {
        return data.map((item: { nome: string; idConvenio: number }) => ({
          name: item.nome,
          value: item.idConvenio,
        }));
      },
      BMG: () => {
        return Object.keys(data).map((key) => {
          const currentValue = key;
          const currentName = data[key];
          return { name: currentName, value: currentValue };
        });
      },
      PAN: () => {
        return data.map((item: { nome: string; idConvenio: string }) => ({
          name: item.nome,
          value: item.idConvenio,
        }));
      },
    }[bank]();
  };

  const getConvenioOptions = async (bank: "MASTER" | "BMG" | "PAN") => {
    setIsLoadingConvenioList(true);
    try {
      const { data } = await api.get(
        `/pipeline/saque-complementar-convenios?banco=${bank}`
      );
      const options = formatConvenioList(bank, data);
      setConvenioList(options);
    } catch (err) {
      const errors = err as Error | AxiosError;
      if (axios.isAxiosError(errors)) {
        Toast({
          title: errors.response?.data,
        });
      } else {
        Toast({
          title: errors.message,
        });
      }
    } finally {
      setIsLoadingConvenioList(false);
    }
  };

  useEffect(() => {
    if (withConvenio) {
      if (Array.isArray(bank)) {
        getConvenioOptions("PAN");
      } else getConvenioOptions(bank);
    }
  }, [bank]);

  useEventListener("openModalUpdateContract", onOpen);

  const modalFooter = (
    <>
      <Button
        leftIcon={<CheckIcon />}
        onClick={handleSubmit}
        isDisabled={!bank || (withConvenio && !idConvenio)}
      >
        Ok
      </Button>
      <Button
        variant="outline"
        onClick={onClose}
        leftIcon={<CloseIcon width="12px" height="12px" />}
      >
        Fechar
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Higienizar contratos"
      modalFooter={modalFooter}
      size="xl"
      isLoading={isLoading}
    >
      <Box mb="10px">
        <DropdownField
          title="Selecione os bancos"
          options={bankListOptions}
          value={modalData?.multiSelect ? bank || [] : bank}
          onChange={(value) => {
            if (modalData?.multiSelect) {
              onChangeMultiSelectDropdownBank(value);
            } else {
              onChangeBank(value);
            }
          }}
          dropdownProps={{
            w: "100%",
            multiSelect: modalData?.multiSelect,
            variant: "outline-custom",
          }}
        />
      </Box>
      {withConvenio ? (
        <Box>
          <Text mb="8px">
            Convênio {modalData.type === "REFIN" ? "PAN" : bank}
          </Text>
          <InputSearch
            options={convenioList}
            onChange={(value) => {
              const newConvenioName = convenioList.find(
                (curr) => curr.value === value
              )?.name!;
              convenioName = newConvenioName;
              setIdConvenio(value);
            }}
            openOnFocus
            resultLimit={100}
            clearInput={false}
            isLoading={isLoadingConvenioList}
            checkIsInclude={(option) => idConvenio === option.value}
            inputProps={{
              isDisabled: isLoadingConvenioList,
              w: "100%",
              autoComplete: "off",
              placeholder: isLoadingConvenioList
                ? "Carregando Convênios"
                : "Selecione o convênio",
              onBlur: (e) => {
                e.target.value = convenioName;
              },
              variant: "outline-custom",
              onChange(e) {
                const currentInputValue = e.target.value;
                if (!currentInputValue) {
                  setIdConvenio(null);
                }
              },
            }}
            value={convenioName}
          />
        </Box>
      ) : null}
    </CustomModal>
  );
}

export const openModalUpdateContract = (modalData?: ModalDataProps) =>
  makeEvent("openModalUpdateContract", modalData);
