import { CheckIcon, CloseIcon } from "@chakra-ui/icons";
import { Toast } from "components/toast";
import apiConsulta from "api/api-consulta";
import apiIn100 from "api/api-in100";
import apiHigienizacao from "api/api-higienizacao";
import { Attendance } from "components/atendimentos-components/atendimento-form/types";
import {
  AttendanceActionsTypes,
  FormControl,
} from "contexts/attendance-context/actions-reducer";
import {
  getErrorByMessage,
  mapObject,
} from "components/atendimentos-components/atendimento-form/functions/mapeamentos";
import {
  ConsultaOfflineOptions,
  mapConsultaOfflineINSSModeCPF,
  mapConsultaOfflineINSSModeNB,
  mapConsultaOfflineSIAPE,
} from "components/atendimentos-components/atendimento-form/functions/consulta";
import { in100Map } from "components/atendimentos-components/atendimento-form/functions/mapeamentos/in100";
import { openModalConfirm } from "components/modal-confirm-new";
import { openModalError } from "components/modal-error";
import { Dispatch, SetStateAction } from "react";
import { openLastStatementModal } from "./last-statement-modal";
import api from "api/api";
import { objectSchema } from "utils/object-methods";
import { KeyForward } from "components/atendimentos-components/atendimento-form/functions/mapeamentos/types";
import { makeEvent } from "services/events";
import { sortArrayBy } from "utils/filter-array-by";

export const numerosdeBenefico: any = {
  default: "Matrícula",
  INSS: "Número do benefício",
  SIAPE: "Matrícula",
};

export const getConsultaOfflineMessage = ({
  formControl,
  dispatch,
  mode,
}: {
  formControl: FormControl;
  dispatch: Dispatch<AttendanceActionsTypes>;
  mode: "nb" | "cpf";
}) =>
  [
    {
      condition:
        ["SIAPE"].includes(formControl.values.convenio!) &&
        !formControl.values.cpf,
      message: `Preecha o campo "CPF" para fazer a consulta`,
      invalidateFields: () =>
        dispatch({
          type: "setInvalidField",
          payload: { fieldName: "cpf", action: "append" },
        }),
    },
    {
      condition: mode === "nb" && !formControl.values.nb,
      message: `Preecha o campo "${
        numerosdeBenefico[formControl.values.convenio ?? "default"] ??
        "Número do Benefício"
      }" para fazer a consulta`,
      invalidateFields: () =>
        dispatch({
          type: "setInvalidField",
          payload: { fieldName: "nb", action: "append" },
        }),
    },
    {
      condition: mode === "cpf" && !formControl.values.cpf,
      message: `Preecha o campo "${
        numerosdeBenefico[formControl.values.convenio ?? "default"] ??
        "Número do Benefício"
      }" para fazer a consulta`,
      invalidateFields: () =>
        dispatch({
          type: "setInvalidField",
          payload: { fieldName: "nb", action: "append" },
        }),
    },
    {
      condition: !["SIAPE", "GOV", "PREF", "INSS"].includes(
        formControl.values.convenio!
      ),
      message: `Consulta disponível apenas para convênio: "INSS" ou "SIAPE"`,
      invalidateFields: () =>
        dispatch({
          type: "setInvalidField",
          payload: { fieldName: "convenio", action: "append" },
        }),
    },
    {
      condition: formControl.pendingChanges,
      message: `Salve as alterações antes de fazer a consulta`,
      invalidateFields: () => {},
    },
  ].find(({ condition }) => condition);

export const fazerConsultaOffline = async ({
  formControl,
  dispatch,
  setIsLoadingStatus,
  mode,
}: {
  formControl: FormControl;
  dispatch: Dispatch<AttendanceActionsTypes>;
  setIsLoadingStatus: Dispatch<SetStateAction<string>>;
  mode: "nb" | "cpf";
}) => {
  const message = getConsultaOfflineMessage({
    dispatch,
    formControl,
    mode,
  });
  if (["GOV", "PREF"].includes(formControl.values.convenio!))
    return Toast({
      title: `Serviço em desenvolvimento para o convenio ${formControl.values
        .convenio!}`,
    });
  if (message) {
    dispatch({
      type: "setInvalidField",
      payload: { action: "clear" },
    });
    message.invalidateFields();
    return Toast({
      title: message.message,
    });
  }
  setIsLoadingStatus("consulta-offline");

  const { url, mapFunction } =
    getConvenioURLAndMapeamento(formControl.values as Attendance, mode) || {};

  try {
    const res = await apiConsulta.get(url || "");
    openModalConfirm({
      onConfirm: () =>
        mapFunction?.(res?.data, dispatch, formControl.values as Attendance),
      message: "Deseja sobrescrever com os dados da consulta offline?",
      confirmButtonStyle: { leftIcon: <CheckIcon />, variant: undefined },
      rejectButtonStyle: { leftIcon: <CloseIcon w="12px" h="12px" /> },
    });
  } catch (e: any) {
    openModalError({
      message: getErrorByMessage(e, "Erro ao fazer consulta offline"),
    });
  } finally {
    setIsLoadingStatus("");
  }
};

export const fazerConsultaIn100 = async ({
  formControl,
  dispatch,
  setIsLoadingStatus,
}: {
  formControl: FormControl;
  dispatch: Dispatch<AttendanceActionsTypes>;
  setIsLoadingStatus: Dispatch<SetStateAction<string>>;
}) => {
  const { possuiRepresentante, cpfRepresentanteLegal, nb, cpf } =
    formControl.values;

  const { isValid, errors } = objectSchema(
    {
      nb: (value) => ({
        valid: !!value,
        message: !value ? "número benefício" : undefined,
      }),
      cpf: (value) => ({ valid: !!value, message: !value ? "CPF" : undefined }),
    },
    formControl.values
  );

  if (!isValid) {
    console.log(errors);
    return openModalError({
      title: "Erro ao consultar IN100",
      message: `Preencha os campo(s): ${Object.keys(errors)
        .map((key, i, arr) => {
          const fieldName = errors[key];
          const withVirgula = i + 1 !== arr.length;
          return `${fieldName}${withVirgula ? "," : "."}`;
        })
        .join(" ")}`,
    });
  }

  setIsLoadingStatus("consulta-in100");
  try {
    const { data } = await apiIn100.get(`/consulta/3/pre`, {
      params: {
        nb,
        cpf: cpf?.replace(/\D/g, ""),
        cpfRepresentante: cpfRepresentanteLegal
          ? cpfRepresentanteLegal
          : undefined,
      },
    });

    openModalConfirm({
      onConfirm: () => {
        in100Map(data, dispatch, formControl.values as Attendance);
      },
      message: "Deseja sobrescrever com os dados da consulta IN100?",
      confirmButtonStyle: { variant: undefined },
    });
  } catch (e: any) {
    const isForbidden = e?.response?.status === 403;
    const data = e?.response?.data;
    const errorMessage = typeof data === "string" ? data : undefined;
    openModalError({
      message:
        errorMessage ??
        (isForbidden
          ? "Sem permissão para consultar. Verifique se o serviço está ativo."
          : "Erro ao consultar IN100."),
    });
  } finally {
    setIsLoadingStatus("");
  }
};

export async function gerarExtrato({
  formValues,
  setIsLoadingStatus,
  dispatch,
}: {
  formValues: Attendance;
  setIsLoadingStatus: Dispatch<SetStateAction<string>>;
  dispatch: Dispatch<AttendanceActionsTypes>;
}) {
  if (!formValues.nb || formValues.nb.trim() === "") {
    Toast({
      title: "Informe o benefício",
      status: "error",
    });
    return;
  }
  setIsLoadingStatus("gerando-extrato");
  let response;
  try {
    response = await api.get(
      `/extratos/create/${formValues.nb}?atendimentoId=${formValues.id}`
    );
  } catch (error: any) {
    let hasMessage = error?.response?.data?.message;
    const errors: any = {
      sem_saldo: "Sem saldo!",
      matricula_invalida: "Benefício inválido!",
      fora_horario_consulta: "Fora do horário de consulta!",
      fora_do_horario_permitido: "Fora do horário permitido!",
      informe_matricula: "Informe o benefício!",
      sem_credito_api: "Serviço indisponível. Contate o administrador!",
      service_unavailable: "Serviço indisponível!",
    };
    if (errors[hasMessage]) {
      Toast({
        title: errors[hasMessage],
        status: "error",
      });
    } else {
      Toast({
        title: "Não foi possível gerar o extrato. Contate o administrador!",
        status: "error",
      });
    }
    setIsLoadingStatus("");
    return;
  }

  dispatch({
    type: "changeFieldWithInitial",
    payload: { fieldName: "extratoOnline", data: response.data },
  });

  openLastStatementModal();
  setIsLoadingStatus("");
}

const getConvenioURLAndMapeamento = (
  formValues: Attendance,
  mode: "nb" | "cpf"
) => {
  const fieldParam = mode === "nb" ? formValues.nb : formValues.cpf;
  return new Map<Attendance["convenio"], ConsultaOfflineOptions>([
    [
      "INSS",
      {
        url: `/consultas/inss/offline/${mode}/${fieldParam}`,
        mapFunction:
          mode === "nb"
            ? mapConsultaOfflineINSSModeNB
            : mapConsultaOfflineINSSModeCPF,
      },
    ],
    [
      "SIAPE",
      {
        url: `/consultas/siape/offline/cpf/${formValues.cpf}`,
        mapFunction: mapConsultaOfflineSIAPE,
      },
    ],
  ]).get(formValues.convenio);
};

export interface ConsultaCPFProps {
  cpf: string;
  nomeCompleto: string;
  dataNascimento: string;
  idade: string;
  nomeMae: string;
  obito: boolean;
  sexo: null;
  flgRep: null;
  email: string | null;
  emails: { email: string }[];
  politicamenteExposta: boolean | null;
  telefones: { phone: string; whatsApp: boolean | null }[];
  endereco: {
    tipo: string;
    titulo: string;
    logradouro: string;
    numero: string;
    complemento: string;
    bairro: string;
    cidade: string;
    uf: string;
    cep: string;
    areaRisco: string;
    latitude: string;
    longitude: string;
  };
}

const changeWhatsAppNumber = async ({
  phone,
  id,
  dispatch,
}: {
  phone: string;
  id: number;
  dispatch: Dispatch<AttendanceActionsTypes>;
}) => {
  try {
    await api.patch(`/atendimentos/${id}/telefone-contato`, {
      telefoneContatoRaw: phone.replace(/\D/g, ""),
    });
    dispatch({
      type: "changeFieldWithInitial",
      payload: {
        fieldName: "telefoneContatoNormalizado",
        data: phone.replace(/\D/g, ""),
      },
    });
    makeEvent("clear-whatsapp-chat");
  } catch (error: any) {
    if (
      error?.response?.data?.message?.includes("not connected with waboxapp")
    ) {
      Toast({
        title: "Não foi possível enviar. WhatsApp desconectado!",
        status: "error",
      });
    } else {
      Toast({
        title: "Erro ao atualizar telefone do contato!",
        status: "error",
      });
    }
  }
};

export const mapeamentoConsultCPF = ({
  consulta,
  dispatch,
  attendance,
}: {
  consulta: ConsultaCPFProps;
  dispatch: Dispatch<AttendanceActionsTypes>;
  attendance: Attendance;
}) => {
  const keyForward: KeyForward<Attendance> = {
    cpf: (data) => {
      return { data: data, field: "cpf", assignJson: { flag: "CONSULTA_CPF" } };
    },
    nomeCompleto: (data) => {
      return {
        data: data,
        field: "nome",
        assignJson: { flag: "CONSULTA_CPF" },
      };
    },
    dataNascimento: (data) => {
      return {
        data: data,
        field: "dataNascimento",
        assignJson: { flag: "CONSULTA_CPF" },
      };
    },
    nomeMae: (data) => {
      return {
        data: data,
        field: "nomeMae",
        assignJson: { flag: "CONSULTA_CPF" },
      };
    },
    sexo: (data) => {
      if (data === "M") data = "MASCULINO";
      else if (data === "F") data = "FEMININO";
      else data = null;
      return {
        data: data,
        field: "sexo",
        assignJson: { flag: "CONSULTA_CPF" },
      };
    },
    // telefones: (data: null | ConsultaCPFProps["telefones"]) => {
    //   let telefone: null | string = null;

    //   return {
    //     data: telefone,
    //     field: "telefoneCelular",
    //     assignJson: { flag: "CONSULTA_CPF" },
    //   };
    // },
    email: (data: string[] | null) => {
      return {
        data: data,
        field: "email",
        assignJson: { flag: "CONSULTA_CPF" },
      };
    },
    "endereco.logradouro": (data) => {
      return {
        data: data,
        field: "endereco",
        assignJson: { flag: "CONSULTA_CPF" },
      };
    },
    "endereco.numero": (data) => {
      return {
        data: data,
        field: "numero",
        assignJson: { flag: "CONSULTA_CPF" },
      };
    },
    "endereco.complemento": (data) => {
      return {
        data: data,
        field: "complemento",
        assignJson: { flag: "CONSULTA_CPF" },
      };
    },
    "endereco.bairro": (data) => {
      return {
        data: data,
        field: "bairro",
        assignJson: { flag: "CONSULTA_CPF" },
      };
    },
    "endereco.cidade": (data) => {
      return {
        data: data,
        field: "cidade",
        assignJson: { flag: "CONSULTA_CPF" },
      };
    },
    "endereco.uf": (data) => {
      return { data: data, field: "uf", assignJson: { flag: "CONSULTA_CPF" } };
    },
    "endereco.cep": (data) => {
      return { data: data, field: "cep", assignJson: { flag: "CONSULTA_CPF" } };
    },
  };

  if (Array.isArray(consulta.telefones)) {
    const telefonesList = sortArrayBy(
      consulta.telefones,
      "whatsApp",
      "DESC"
    ) as ConsultaCPFProps["telefones"];
    const attendanceFields: (keyof Attendance)[] = [
      "telefoneResidencial",
      "telefoneComercial",
      "telefoneCelular",
    ];
    if (telefonesList.length) {
      let setouZap = false;
      telefonesList.forEach(({ phone, whatsApp }, index) => {
        if (whatsApp && index === 0 && !attendance.telefoneContatoNormalizado) {
          changeWhatsAppNumber({ dispatch, id: attendance.id, phone });
          setouZap = true;
        } else {
          const currIndex = setouZap ? index - 1 : index;
          const fieldName = attendanceFields[currIndex];
          if (fieldName) {
            dispatch({
              type: "changeField",
              payload: { data: phone, fieldName },
            });
            setSourceFieldsJson({
              attendance,
              dispatch,
              attendanceKeyPath: fieldName,
              flag: "CONSULTA_CPF",
            });
          }
        }
      });
    }
  }

  mapObject({ keyForward, data: consulta, dispatch });
};

export function setSourceFieldsJson({
  dispatch,
  attendance,
  attendanceKeyPath,
  flag,
}: {
  dispatch: Dispatch<AttendanceActionsTypes>;
  attendance: Attendance;
  attendanceKeyPath: string;
  flag: string;
}) {
  let json = attendance?.sourceFieldsJson || "{}";
  let sourceFieldsJson: { [k: string]: { updatedBy: string } } =
    JSON.parse(json);
  let currentValues = sourceFieldsJson[attendanceKeyPath];
  sourceFieldsJson = {
    ...sourceFieldsJson,
    [attendanceKeyPath]: { ...currentValues, updatedBy: flag },
  };
  json = JSON.stringify(sourceFieldsJson);
  attendance.sourceFieldsJson = json;

  dispatch({
    type: "changeField",
    payload: { fieldName: "sourceFieldsJson", data: json },
  });
}
