import { Box } from "@chakra-ui/react";
import { MailingOptionFilter } from "../..";
import React, { Dispatch, ReactNode, SetStateAction, useEffect } from "react";
import { Checkbox } from "components/checkbox";

export function MailingField({
  option,
  field,
  set,
  title,
  children,
  isVisible,
  initialMalingOptionValue,
  setInvalidFields,
  isDisabledCheckBox,
  setPresentFields,
}: {
  title: string;
  option: MailingOptionFilter;
  initialMalingOptionValue: MailingOptionFilter;
  field: string;
  set: Dispatch<SetStateAction<MailingOptionFilter>>;
  children: ReactNode;
  isVisible: boolean;
  setInvalidFields: Dispatch<SetStateAction<{ [k: string]: string }>>;
  isDisabledCheckBox?: boolean;
  setPresentFields: React.Dispatch<React.SetStateAction<string[]>>;
}) {
  const isChecked = !!option[field].isChecked;

  const onChangeFilter = ({
    key,
    set,
    isChecked,
  }: {
    key: string;
    isChecked: boolean;
    set: Dispatch<SetStateAction<MailingOptionFilter>>;
  }) => {
    const value = isChecked
      ? option[field].value
      : initialMalingOptionValue[field].value;

    set((prev) => ({ ...prev, [key]: { ...prev[key], isChecked, value } }));
    setInvalidFields((errors) => {
      delete errors[field];
      return { ...errors };
    });
  };
  useEffect(() => {
    if (!isVisible) onChangeFilter({ isChecked: false, key: field, set });

    if (isVisible) setPresentFields((keyList) => [...keyList, field]);
    else setPresentFields((keyList) => keyList.filter((k) => k !== field));
  }, [isVisible]);

  useEffect(() => {
    setInvalidFields((errors) => {
      delete errors[field];
      return { ...errors };
    });
  }, [option[field].value]);

  return isVisible ? (
    <Box>
      <Checkbox
        mb="10px"
        onChange={(isChecked) => onChangeFilter({ key: field, set, isChecked })}
        isChecked={isChecked}
        isDisabled={isDisabledCheckBox}
      >
        {title}
      </Checkbox>

      {isChecked ? children : null}
    </Box>
  ) : null;
}
