import { RepeatIcon } from "@chakra-ui/icons";
import { IconButton } from "@chakra-ui/react";
import api from "api/api";
import { toastDefaultStyle } from "chakra/theme";
import { AttendanceActionsTypes } from "contexts/attendance-context/actions-reducer";
import { useState, Dispatch } from "react";
import { Attendance } from "../types";
import { Toast } from "components/toast";

export function BtnStatusProposta({
  formValues,
  dispatch,
}: {
  formValues: Attendance;
  dispatch: Dispatch<AttendanceActionsTypes>;
}) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <IconButton
      aria-label=""
      size="sm"
      icon={<RepeatIcon />}
      isLoading={isLoading}
      onClick={async () => {
        setIsLoading(true);
        try {
          const { data } = await api.get(
            `/propostas/update-attendance/${formValues.id}`
          );
          dispatch({
            type: "changeFieldWithInitial",
            payload: {
              fieldName: "statusProposta",
              data: data.statusProposta,
            },
          });
          dispatch({
            type: "changeFieldWithInitial",
            payload: {
              fieldName: "valorLiquidoCliente",
              data: data.valorLiquidoCliente,
            },
          });
          dispatch({
            type: "changeFieldWithInitial",
            payload: {
              fieldName: "atividade",
              data: data.atividade,
            },
          });
          dispatch({
            type: "changeFieldWithInitial",
            payload: {
              fieldName: "statusAssinatura",
              data: data.statusAssinatura,
            },
          });
          setIsLoading(false);
        } catch (e: any) {
          const message = e?.response?.data?.message;
          Toast({
            title: "Erro ao atualizar situação da proposta",
          });
          setIsLoading(false);
        }
      }}
      disabled={!formValues.digitadoApi}
    />
  );
}
