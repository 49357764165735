import { CloseIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { CustomModal } from "components/custom-modal";
import { FieldError } from "components/field-error";
import { CampanhaSMSRow } from "pages/campanha-sms";
import { useState } from "react";
import { useEventListener, makeEvent } from "services/events";

export function ModalErrorsCampanhaSMS() {
  const [modalData, setModalData] = useState<Partial<CampanhaSMSRow>>({});
  const [isOpen, setIsOpen] = useState(false);

  const onOpen = (row: CampanhaSMSRow) => {
    setIsOpen(true);
    setModalData(row);
  };
  const onClose = () => {
    setIsOpen(false);
    setModalData({});
  };

  useEventListener("openModalErrorsCampanhaSMS", onOpen);

  const modalFooter = (
    <>
      <Button
        variant="outline"
        leftIcon={<CloseIcon w="12px" h="12px" />}
        onClick={onClose}
      >
        Fechar
      </Button>
    </>
  );

  return (
    <CustomModal
      modalTitle="Mensagens de erro"
      isOpen={isOpen}
      onClose={onClose}
      modalFooter={modalFooter}
    >
      {modalData.errorMessageCampanha ? (
        <Flex
          py="12px"
          borderLeft="4px solid red"
          borderRadius="4px"
          bg="rgba(255, 100, 0, 0.1)"
          justifyContent="flex-start"
          px="10px"
          fontWeight="600"
          fontSize="14"
        >
          <Box mr="10px" mt="-1.5px">
            <WarningTwoIcon width="17px" height="17px" color="red" />
          </Box>
          <Flex flexDir="column" color="var(--chakra-colors-secondary-700)">
            <Text>Erro:</Text>
            <Text fontSize="14">{modalData.errorMessageCampanha}</Text>
          </Flex>
        </Flex>
      ) : (
        modalData.errorsMessages?.map(({ message, count }, index) => {
          const isLast = index + 1 === modalData.errorsMessages?.length;
          return (
            <Flex
              key={index}
              mb={isLast ? undefined : "10px"}
              py="12px"
              borderLeft="4px solid red"
              borderRadius="4px"
              bg="rgba(255, 100, 0, 0.1)"
              justifyContent="flex-start"
              px="10px"
              fontWeight="600"
              fontSize="14"
            >
              <Box mr="10px" mt="-1.5px">
                <WarningTwoIcon width="17px" height="17px" color="red" />
              </Box>
              <Flex flexDir="column" color="var(--chakra-colors-secondary-700)">
                <Text>Erro:</Text>
                <Text fontSize="14">
                  {count}x - {message}
                </Text>
              </Flex>
            </Flex>
          );
        })
      )}
    </CustomModal>
  );
}

export const openModalErrorsCampanhaSMS = (row: CampanhaSMSRow) =>
  makeEvent("openModalErrorsCampanhaSMS", row);
