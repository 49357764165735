import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react";
import api from "api/api";
import { CustomModal } from "components/custom-modal";
import { DocumentIcon } from "components/vectors/document-icon";
import { useState } from "react";
import { useEventListener, makeEvent } from "services/events";
import { Toast } from "components/toast";

export function ModalExtratoOnline({
  loadExtratosOnline,
}: {
  loadExtratosOnline: () => void;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [benefit, setBenefit] = useState("");

  const onOpen = () => {
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    setBenefit("");
  };

  useEventListener("openModalExtratoOnline", onOpen);

  const gerarExtrato = async () => {
    setIsLoading(true);
    try {
      await api.get(`/extratos/create/${benefit}`);
      await loadExtratosOnline();
      onClose();
    } catch (error: any) {
      let hasMessage = error?.response?.data?.message;
      if ("sem_saldo" === hasMessage) {
        Toast({
          title: "Sem saldo!",
          status: "error",
        });
      } else if ("matricula_invalida" === hasMessage) {
        Toast({
          title: "Benefício inválido!",
          status: "error",
        });
      } else if ("fora_horario_consulta" === hasMessage) {
        Toast({
          title: "Fora do horário de consulta!",
          status: "error",
        });
      } else if ("fora_do_horario_permitido" === hasMessage) {
        Toast({
          title: "Fora do horário permitido!",
          status: "error",
        });
      } else if ("informe_matricula" === hasMessage) {
        Toast({
          title: "Informe o benefício!",
          status: "error",
        });
      } else if ("sem_credito_api" === hasMessage) {
        Toast({
          title: "Serviço indisponível. Contate o administrador!",
          status: "error",
        });
      } else if ("service_unavailable" === hasMessage) {
        Toast({
          title: "Serviço indisponível!",
          status: "error",
        });
      } else {
        Toast({
          title: "Não foi possível gerar o extrato. Contate o administrador!",
          status: "error",
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const modalFooter = (
    <>
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        onClick={onClose}
        variant="outline"
      >
        Cancelar
      </Button>
      <Button
        leftIcon={<DocumentIcon width="22px" height="22px" />}
        onClick={gerarExtrato}
        isLoading={isLoading}
        loadingText="Gerando Extrato"
        isDisabled={benefit.trim() === ""}
      >
        Gerar Extrato
      </Button>
    </>
  );

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle="Gerar Benefício"
      modalFooter={modalFooter}
    >
      <Box>
        <Text mb="10px">Informe o Benefício</Text>
        <Flex>
          <Input onChange={(e) => setBenefit(e.target.value)} value={benefit} />
        </Flex>
      </Box>
    </CustomModal>
  );
}

export const openModalExtratoOnline = () => makeEvent("openModalExtratoOnline");
