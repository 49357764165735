import { Box, Button, Flex, Grid, Input, Text } from "@chakra-ui/react";
import { ConsultaProps, ContratoCartao, EmprestimoBancario } from "./types";
import { currencyMask, maskCPF } from "utils/string-formats";
import DynamicTable from "components/dynamic-table";
import { ViewIcon } from "@chakra-ui/icons";
import { FaFilePdf } from "react-icons/fa";
import { ConsultaPDFModal, openConsultaPDFModal } from "../consulta-pdf-modal";
import {
  ConsultaDetalhamentoModal,
  openConsultaDetalhamentoModal,
} from "../consulta-detalhamento-modal";
import { useApplicationContext } from "contexts/ApplicationContext";

export function ConsultaFields({ consult }: { consult: ConsultaProps }) {
  const { user } = useApplicationContext();
  const completeCPF = (cpf: string) => {
    if (cpf != null && cpf)
      for (let i = 0; i <= 11; i++) if (i > cpf.length) cpf = "0" + cpf;
    return maskCPF(cpf);
  };

  const dateFormat = (data: string, withOutYears = false) => {
    const today = new Date();
    const birthDate = new Date(data);
    let age = today.getFullYear() - birthDate.getUTCFullYear();
    const month = today.getMonth() - birthDate.getUTCMonth();
    const day = today.getDate() - birthDate.getUTCDate();

    if (month < 0) age -= 1;
    else if (month === 0 && day < 0) age -= 1;

    return `${data?.split("-").reverse().join("/") ?? ""}${
      withOutYears ? "" : ` (${age} anos)`
    }`;
  };

  const addressColumns = [
    {
      name: "UF",
      render: (row: ConsultaProps["beneficio"]["enderecoPessoal"]) => row.uf,
    },
    {
      name: "Cidade",
      render: (row: ConsultaProps["beneficio"]["enderecoPessoal"]) =>
        row.cidade,
    },
    {
      name: "Bairro",
      render: (row: ConsultaProps["beneficio"]["enderecoPessoal"]) =>
        row.bairro,
    },
    {
      name: "Endereco",
      render: (row: ConsultaProps["beneficio"]["enderecoPessoal"]) =>
        row.endereco,
    },
    {
      name: "CEP",
      render: (row: ConsultaProps["beneficio"]["enderecoPessoal"]) => row.cep,
    },
  ];

  const contratoCartaoColumns = [
    {
      name: "Banco",
      render: (row: ContratoCartao) =>
        `${row.banco.codigo} - ${row.banco.nome}`,
    },
    {
      name: "Contrato",
      render: (row: ContratoCartao) => row.contrato,
    },
    {
      name: "Tipo de empréstimo",
      render: (row: ContratoCartao) => row.tipoEmprestimo.descricao,
    },
    {
      name: "Limite Cartão",
      render: (row: ContratoCartao) =>
        typeof row.limiteCartao === "number" ? (
          <Text color="whatsapp.400">
            R$ {currencyMask(row.limiteCartao.toFixed(2))}
          </Text>
        ) : null,
    },
    {
      name: "Início contrato",
      render: (row: ContratoCartao) =>
        row.dataInicioContrato
          ? dateFormat(row.dataInicioContrato, true)
          : null,
    },
    {
      name: "Valor Reservado",
      render: (row: ContratoCartao) =>
        typeof row.valorReservado === "number" ? (
          <Text color="whatsapp.400">
            R$ {currencyMask(row.valorReservado.toFixed(2))}
          </Text>
        ) : null,
    },
    {
      name: "Situação",
      render: (row: ContratoCartao) =>
        row.situacao?.toLocaleLowerCase() === "ativo" ? (
          <Text color="whatsapp.400">{row.situacao}</Text>
        ) : (
          row.situacao
        ),
    },
  ];

  const contratosBancariosColumns = [
    {
      name: "Banco",
      render: (row: EmprestimoBancario) =>
        `${row.banco.codigo} - ${row.banco.nome}`,
    },
    {
      name: "Contrato",
      render: (row: EmprestimoBancario) => row.contrato,
    },
    {
      name: "Tipo de Empréstimo",
      render: (row: EmprestimoBancario) =>
        `${row.tipoEmprestimo.codigo} - ${row.tipoEmprestimo.descricao}`,
    },
    {
      name: "Valor Empréstimo",
      render: (row: EmprestimoBancario) =>
        typeof row.valorEmprestado === "number" ? (
          <Text>R$ {currencyMask(row.valorEmprestado.toFixed(2))}</Text>
        ) : null,
    },
    {
      name: "Início Desconto",
      render: (row: EmprestimoBancario) =>
        row.competenciaInicioDesconto
          ? dateFormat(row.competenciaInicioDesconto, true)
          : null,
    },
    {
      name: "Fim Desconto",
      render: (row: EmprestimoBancario) =>
        row.competenciaFimDesconto
          ? dateFormat(row.competenciaFimDesconto, true)
          : null,
    },
    {
      name: "Em aberto parcelas",
      render: (row: EmprestimoBancario) => (
        <Text>
          {row.quantidadeParcelasEmAberto}/{row.quantidadeParcelas}
        </Text>
      ),
    },
    {
      name: "Valor da parcela",
      render: (row: EmprestimoBancario) =>
        typeof row.valorParcela === "number" ? (
          <Text>R$ {currencyMask(row.valorParcela.toFixed(2))}</Text>
        ) : null,
    },
    {
      name: "Data Averbação",
      render: (row: EmprestimoBancario) =>
        row.dataInclusao ? dateFormat(row.dataInclusao, true) : null,
    },
    {
      name: "Situação",
      render: (row: EmprestimoBancario) =>
        row.situacao?.toLocaleLowerCase() === "ativo" ? (
          <Text color="whatsapp.400">{row.situacao}</Text>
        ) : (
          row.situacao
        ),
    },
    {
      name: "Taxa Sugerida",
      render: (row: EmprestimoBancario) => (
        <Text>{Number(row.taxa).toFixed(2)}%</Text>
      ),
    },
    {
      name: "Taxa Calculada",
      render: (row: EmprestimoBancario) => (
        <Text>{Number(row.TaxaCalculada).toFixed(2)}%</Text>
      ),
    },
    {
      name: "Saldo Dev. Aprox.",
      render: (row: EmprestimoBancario) => (
        <Text>R$ {currencyMask(row.saldoQuitacao.toFixed(2))}</Text>
      ),
    },
    // {
    //   name: "Ação",
    //   render: (row: EmprestimoBancario) => (
    //     <IconButton
    //       aria-label=""
    //       variant="outline"
    //       icon={<AddIcon w="12px" h="12px" />}
    //     />
    //   ),
    // },
  ];

  return (
    <Grid w="100%" templateColumns="1fr 1fr 1fr" gap="16px" py="10px">
      <Flex
        alignItems="center"
        justifyContent="space-between"
        gridArea="auto / span 3"
      >
        <Text fontWeight="semibold" fontSize="20">
          Dados Cadastrais
        </Text>
        <Flex alignItems="center">
          <Button
            leftIcon={<ViewIcon />}
            onClick={() => openConsultaDetalhamentoModal()}
          >
            Detalhamento
          </Button>
          <Button
            ml="8px"
            leftIcon={<FaFilePdf size="18px" />}
            onClick={() => openConsultaPDFModal()}
          >
            PDF
          </Button>
        </Flex>
      </Flex>
      <Box
        gridArea="auto / span 3"
        borderBottom="1px solid var(--chakra-colors-gray-200)"
      ></Box>
      <Box>
        <Text mb="8px">Benefício:</Text>
        <Input value={consult?.beneficio.beneficio} isReadOnly />
      </Box>
      <Box>
        <Text mb="8px">Nome:</Text>
        <Input value={consult?.beneficio.nome} isReadOnly />
      </Box>
      <Box>
        <Text mb="8px">Sexo:</Text>
        <Input value={consult?.beneficio.sexo} isReadOnly />
      </Box>
      <Box>
        <Text mb="8px">CPF:</Text>
        <Input value={completeCPF(consult?.beneficio.cpf)} isReadOnly />
      </Box>
      <Box>
        <Text mb="8px">RG:</Text>
        <Input value={consult?.beneficio.Rg} isReadOnly />
      </Box>
      <Box>
        <Text mb="8px">Data Nascimento:</Text>
        <Input
          value={dateFormat(consult?.beneficio.dataNascimento)}
          isReadOnly
        />
      </Box>

      <Grid templateColumns="1fr 1fr" gridArea="auto / span 3" gap="16px">
        <Box>
          <Text mb="8px">DDB:</Text>
          <Input value={dateFormat(consult?.beneficio.ddb, true)} isReadOnly />
        </Box>
        <Box>
          <Text mb="8px">Nome Mãe:</Text>
          <Input value={consult?.beneficio.nomeMae} isReadOnly />
        </Box>
        <Box>
          <Text mb="8px">Espécie:</Text>
          <Input
            value={`${consult?.beneficio?.especie?.codigo} - ${consult?.beneficio?.especie?.descricao}`}
            isReadOnly
          />
        </Box>
        <Box>
          <Text mb="8px">NIT:</Text>
          <Input value={consult?.beneficio?.nit} isReadOnly />
        </Box>
      </Grid>
      <Box>
        <Text mb="8px">Situação:</Text>
        <Input value={consult?.beneficio?.situacaoBeneficio} isReadOnly />
      </Box>
      <Box>
        <Text mb="8px">É pensão alimentícia:</Text>
        <Input
          value={
            consult?.beneficio?.pensaoAlimenticia != null
              ? consult?.beneficio?.pensaoAlimenticia
                ? "SIM"
                : "NÃO"
              : ""
          }
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">Permite Empréstimo:</Text>
        <Input
          value={
            consult?.beneficio.beneficioPermiteEmprestimo != null
              ? consult?.beneficio.beneficioPermiteEmprestimo
                ? "SIM"
                : "NÃO"
              : ""
          }
          isReadOnly
        />
      </Box>
      <Grid
        templateColumns="1fr 1fr 1fr 1fr"
        gridArea="auto / span 3"
        gap="16px"
      >
        <Box>
          <Text mb="8px">DIB:</Text>
          <Input value={dateFormat(consult?.beneficio.dib, true)} isReadOnly />
        </Box>
        <Box>
          <Text mb="8px">MR:</Text>
          <Input
            value={
              typeof consult?.beneficio.valorBeneficio === "number"
                ? `R$ ${currencyMask(
                    consult?.beneficio.valorBeneficio.toFixed(2)
                  )}`
                : ""
            }
            isReadOnly
          />
        </Box>
        <Box>
          <Text mb="8px">Bloqueado Empréstimo:</Text>
          <Input
            value={
              consult?.beneficio?.bloqueioEmprestismo != null
                ? consult?.beneficio.bloqueioEmprestismo
                  ? "SIM"
                  : "NÃO"
                : ""
            }
            isReadOnly
          />
        </Box>
        <Box>
          <Text mb="8px">Possui representante legal:</Text>
          <Input
            value={
              consult?.beneficio?.possuiRepresentanteLegalProcurador != null
                ? consult?.beneficio?.possuiRepresentanteLegalProcurador
                  ? "SIM"
                  : "NÃO"
                : ""
            }
            isReadOnly
          />
        </Box>
      </Grid>
      {consult?.beneficio?.possuiRepresentanteLegalProcurador ? (
        <Grid templateColumns="1fr 1fr" gridArea="auto / span 3" gap="16px">
          <Box>
            <Text mb="8px">CPF representante legal:</Text>
            <Input
              value={completeCPF(consult?.beneficio?.CpfRepresentanteLegal)}
              isReadOnly
            />
          </Box>
          <Box>
            <Text mb="8px">Nome representante legal:</Text>
            <Input
              value={consult?.beneficio?.NomeRepresentanteLegal}
              isReadOnly
            />
          </Box>
        </Grid>
      ) : null}
      <Box gridArea="auto / span 3">
        <Text fontWeight="semibold" fontSize="20">
          Endereços do Cliente
        </Text>
      </Box>
      <Box
        gridArea="auto / span 3"
        borderBottom="1px solid var(--chakra-colors-gray-200)"
      ></Box>

      <Box gridArea="auto / span 3">
        <DynamicTable
          columns={addressColumns}
          rows={[consult?.beneficio.enderecoPessoal]}
          containerStyle={{
            boxShadow: "0 0 1em rgba(0,0,0, 0.03)",
          }}
        />
      </Box>

      <Box gridArea="auto / span 3">
        <Text fontWeight="semibold" fontSize="20">
          Dados Bancários
        </Text>
      </Box>
      <Box
        gridArea="auto / span 3"
        borderBottom="1px solid var(--chakra-colors-gray-200)"
      ></Box>
      <Grid
        templateColumns="1fr 1fr 1fr 1fr"
        gap="16px"
        gridArea="auto / span 3"
      >
        <Box>
          <Text mb="8px">Banco:</Text>
          <Input
            value={`${consult?.beneficio.dadosBancarios.banco.codigo} -${consult?.beneficio.dadosBancarios.banco.nome}`}
            isReadOnly
          />
        </Box>
        <Box>
          <Text mb="8px">Agencia:</Text>
          <Input
            value={consult?.beneficio.dadosBancarios.agencia.codigo}
            isReadOnly
          />
        </Box>
        <Box>
          <Text mb="8px">Conta:</Text>
          <Input
            value={consult?.beneficio.dadosBancarios.banco.numero}
            isReadOnly
          />
        </Box>
        <Box>
          <Text mb="8px">Tipo de conta:</Text>
          <Input
            value={consult?.beneficio.dadosBancarios.meioPagamento.tipo}
            isReadOnly
          />
        </Box>
      </Grid>
      <Box gridArea="auto / span 3">
        <Text fontWeight="semibold" fontSize="20">
          Margem
        </Text>
        <hr style={{ margin: "10px 0 0px 0" }} />
      </Box>
      <Box>
        <Text mb="8px">Base de cálculo:</Text>
        <Input
          value={
            typeof consult?.beneficio?.margem?.baseCalculoMargemConsignavel ===
            "number"
              ? currencyMask(
                  consult?.beneficio.margem.baseCalculoMargemConsignavel.toFixed(
                    2
                  )
                )
              : ""
          }
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">Competência:</Text>
        <Input
          value={
            typeof consult?.beneficio.margem.competencia === "string"
              ? consult?.beneficio.margem.competencia
              : ""
          }
          isReadOnly
        />
      </Box>
      <Box>
        <Text mb="8px">Possui cartão:</Text>
        <Input
          value={
            consult?.beneficio.margem.possuiCartao != null
              ? consult?.beneficio.margem.possuiCartao
                ? "SIM"
                : "NÃO"
              : ""
          }
          isReadOnly
        />
      </Box>
      <Grid
        gridArea={"auto / span 3"}
        templateColumns="1fr 1fr 1fr 1fr"
        gap="16px"
      >
        <Box>
          <Text mb="8px">Quantidade de empréstimos:</Text>
          <Input
            value={consult?.beneficio.contratosEmprestimo?.length}
            isReadOnly
          />
        </Box>
        {/* <Box>
                <Text mb="8px">Cartão Benefício:</Text>
                <Input
                  value={consultLista?.beneficio.margem.cart}
                  isReadOnly
                />
              </Box> */}
        <Box>
          <Text mb="8px">Margem livre:</Text>
          <Input
            value={
              typeof consult?.beneficio.margem.margemDisponivelEmprestimo ===
              "number"
                ? `R$ ${currencyMask(
                    consult?.beneficio.margem.margemDisponivelEmprestimo.toFixed(
                      2
                    )
                  )}`
                : ""
            }
            isReadOnly
          />
        </Box>
        <Box>
          <Text mb="8px">Margem cartão RMC:</Text>
          <Input
            value={
              typeof consult?.beneficio.margem.margemDisponivelCartao ===
              "number"
                ? `R$ ${currencyMask(
                    consult?.beneficio.margem.margemDisponivelCartao.toFixed(2)
                  )}`
                : ""
            }
            isReadOnly
          />
        </Box>
        <Box>
          <Text mb="8px">Margem cartão RCC:</Text>
          <Input
            value={
              typeof consult?.beneficio.margem.margemDisponivelRcc === "number"
                ? `R$ ${currencyMask(
                    consult?.beneficio.margem.margemDisponivelRcc.toFixed(2)
                  )}`
                : ""
            }
            isReadOnly
          />
        </Box>
      </Grid>
      <Grid
        gridArea={"auto / span 3"}
        templateColumns="1fr 1fr 1fr 1fr"
        gap="16px"
      >
        <Box>
          <Text mb="8px">% Disponível empréstimo:</Text>
          <Input
            value={
              typeof consult?.beneficio.margem
                .percentualMargemDisponivelEmprestimo === "number"
                ? `${consult?.beneficio.margem.percentualMargemDisponivelEmprestimo}%`
                : ""
            }
            isReadOnly
          />
        </Box>
        <Box>
          <Text mb="8px">% Disponível cartão:</Text>
          <Input
            value={
              typeof consult?.beneficio.margem
                .percentualmargemDisponivelCartao === "number"
                ? `${consult?.beneficio.margem.percentualmargemDisponivelCartao}%`
                : ""
            }
            isReadOnly
          />
        </Box>
        <Box>
          <Text mb="8px">% Total disponível empréstimo:</Text>
          <Input
            value={
              typeof consult?.beneficio.margem
                .percentualMargemTotalEmprestimo === "number"
                ? `${consult?.beneficio.margem.percentualMargemTotalEmprestimo}%`
                : ""
            }
            isReadOnly
          />
        </Box>
        <Box>
          <Text mb="8px">% Total disponível cartão:</Text>
          <Input
            value={
              typeof consult?.beneficio.margem.percentualMargemTotalCartao ===
              "number"
                ? `${consult?.beneficio.margem.percentualMargemTotalCartao}%`
                : ""
            }
            isReadOnly
          />
        </Box>
      </Grid>
      <Box gridArea="auto / span 3">
        <Text fontWeight="semibold" fontSize="20">
          Margem cartão de crédito
        </Text>
        <hr style={{ margin: "10px 0 0px 0" }} />
      </Box>
      <Box gridArea="auto / span 3">
        <DynamicTable
          columns={contratoCartaoColumns}
          rows={consult?.beneficio.contratosCartao || []}
          containerStyle={{
            boxShadow: "0 0 1em rgba(0,0,0, 0.03)",
          }}
        />
      </Box>
      <Box gridArea="auto / span 3">
        <Text fontWeight="semibold" fontSize="20">
          Emprestimo Bancário
        </Text>
        <hr style={{ margin: "10px 0 0px 0" }} />
      </Box>
      <Box gridArea="auto / span 3">
        <DynamicTable
          columns={contratosBancariosColumns}
          rows={consult?.beneficio.contratosEmprestimo || []}
          containerStyle={{
            boxShadow: "0 0 1em rgba(0,0,0, 0.03)",
          }}
        />
      </Box>
      <ConsultaDetalhamentoModal consult={consult} />
      <ConsultaPDFModal consult={consult} />
    </Grid>
  );
}
