import { CloseIcon, CopyIcon, Search2Icon } from "@chakra-ui/icons";
import { Button, Center, Flex, Grid, Text } from "@chakra-ui/react";
import api from "api/api";
import apiDigitacao from "api/api-digitacao";
import { factaCitiesArray } from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/cities-array";
import {
  CEPProps,
  copyAttendanceDataToDigitacao,
  digitacaoPropostaErrors,
  dropdownOrgaosEmissoresDocDigitacaoFGTSFacta,
  getCEPData,
  maskDateFormat,
  UFsBrasil,
} from "components/atendimentos-components/atendimento-form/step-form/tabs-components/digitacao-fields/parts/const";
import {
  Attendance,
  DigitationDataProps,
} from "components/atendimentos-components/atendimento-form/types";
import { CustomModal } from "components/custom-modal";
import { DropdownOptionProps } from "components/dropdown";
import { DropdownField } from "components/dropdown-field";
import { InputField } from "components/input-field";
import { InputSearch } from "components/input-search";
import { ButtonWithLoading } from "components/mailing-components/mailing-table/icon-button-with-loading";
import { openModalError } from "components/modal-error";
import { Toast } from "components/toast";
import { KeyboardIcon } from "components/vectors/keyboard-icon";
import { useApplicationContext } from "contexts/ApplicationContext";
import { useAttendanceContext } from "contexts/attendance-context";
import { useState } from "react";
import { makeEvent, useEventListener } from "services/events";
import { paisesList } from "utils/country-list";
import { normalizeText } from "utils/filter-array-by";
import { isColomboUser } from "utils/is-colombo-user";
import {
  cnpjMask,
  currencyMaskFormat,
  maskCEPFormat,
  maskCPFFormat,
  maskPhoneFormat,
  phoneFormatMask,
} from "utils/string-formats";
import { DigitadoRow } from "../digitados-part";
import {
  dropdownTipoConta,
  statusCivilOptions,
  validateDigitacao,
  yesOrNoOptions,
} from "./utils";

export interface ModalDataDigitarPropostaProps
  extends Partial<DigitationDataProps> {
  atendimentoId?: number;
  bancoDigitacao?: string;
  updateOptions?: {
    allDisabled: boolean;
    digitacaoData: DigitadoRow;
    onUpdate: (row: DigitadoRow) => void;
  };
  usuariosDigitadores?: DropdownOptionProps[];
  sellerCode?: string;
  digitacaoId?: number;
}

export function ModalDigitarPropostaFGTS() {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  let [digitacaoFields, setDigitacaoFields] =
    useState<ModalDataDigitarPropostaProps>({});
  const [errors, setErrors] = useState<{ [k: string]: string }>({});
  const { formControl } = useAttendanceContext();
  const { user } = useApplicationContext();

  const attendance = formControl.values as Attendance;
  const allDisabled = !!digitacaoFields?.updateOptions?.allDisabled;
  const isUpdate = !!digitacaoFields.updateOptions;

  const pixMask = {
    CPF: maskCPFFormat,
    CNPJ: cnpjMask,
    EMAIL: (v?: string) => ({ raw: v, value: v }),
    PHONE: phoneFormatMask,
    RANDOM: (v?: string) => ({ raw: v, value: v }),
  }[digitacaoFields["pixKeyType"] || ""];

  const onOpen = async (props: ModalDataDigitarPropostaProps) => {
    setIsOpen(true);
    const isUpdate = !!props.updateOptions;
    const atendimentoId = attendance.id;
    onChangeDigitacao({ key: null, value: { atendimentoId, ...props } });

    if (!isColomboUser(user) && !props.updateOptions?.allDisabled)
      await getUsuariosDigitadores();
    if (isUpdate) {
      loadFormData(props.updateOptions?.digitacaoData!);
    }
  };

  const onClose = () => {
    setIsOpen(false);
    setDigitacaoFields({});
    setErrors({});
    setIsLoading(false);
  };

  const onChangeDigitacao = ({
    key,
    value,
  }: {
    key: keyof ModalDataDigitarPropostaProps | null;
    value: any;
  }) => {
    if (key == null) {
      digitacaoFields = value;
      setDigitacaoFields(digitacaoFields);
      setErrors({});
    } else {
      digitacaoFields = { ...digitacaoFields, [key]: value };
      setDigitacaoFields(digitacaoFields);
      setErrors((errors) => {
        delete errors[key];
        return { ...errors };
      });
    }
  };

  const loadFormData = async (row: DigitadoRow) => {
    const url = `/api/digitacoes/fgts/formulario?digitacao_id=${row.id}`;
    setIsLoading(true);
    try {
      const { data }: { data: ModalDataDigitarPropostaProps } =
        await apiDigitacao.get(url);
      onChangeDigitacao({ key: null, value: { ...data, ...digitacaoFields } });
      if (
        !digitacaoFields.usuariosDigitadores?.find(
          (usu) => usu.value === data.sellerCode
        )
      ) {
        onChangeDigitacao({ key: "usuariosDigitadores", value: undefined });
      }
    } catch (e) {
      Toast({ title: "Erro ao obter formulário" });
    } finally {
      setIsLoading(false);
    }
  };

  const loadCEPData = async (cep: string) => {
    const { error, errorMessage, ...cpfData } = await getCEPData(cep);
    if (error) {
      Toast({ title: errorMessage, status: "error" });
    } else {
      setCpfData(cpfData as CEPProps);
    }
  };

  const setCpfData = (cep: CEPProps) => {
    onChangeDigitacao({ key: "addressStreet1", value: cep.logradouro });
    onChangeDigitacao({ key: "addressNeigh1", value: cep.bairro });

    const possiveisCidades = factaCitiesArray.filter((crr) => {
      return (
        crr.value.uf === cep.uf &&
        normalizeText(crr.value.cityName).includes(
          normalizeText(cep.localidade!)
        )
      );
    });
    if (possiveisCidades.length === 1) {
      onChangeDigitacao({
        key: "addressCity1",
        value: possiveisCidades[0].value.cityName,
      });
      onChangeDigitacao({
        key: "addressStateCode1",
        value: possiveisCidades[0].value.uf,
      });
    }
  };

  useEventListener("openModalDigitarProposta", onOpen);

  const handleDigitar = async () => {
    const { isValid, body, errors } = validateDigitacao(digitacaoFields, user);

    if (!isValid) {
      return setErrors(errors);
    }
    setIsLoading(true);
    try {
      const { data } = await apiDigitacao.post(
        isUpdate
          ? `/api/digitacoes/fgts/redigitar-proposta`
          : `/api/digitacoes/fgts/digitar-proposta`,
        body
      );
      if (isUpdate) digitacaoFields.updateOptions?.onUpdate?.(data);

      onClose();
    } catch (e: any) {
      const errorMessage =
        e?.response.data.msgInterface ||
        digitacaoPropostaErrors.get(e?.response?.data?.tag) ||
        "Erro ao digitar proposta";
      openModalError({ message: errorMessage });
    } finally {
      setIsLoading(false);
    }
  };

  function phoneDDDMask(value: any) {
    const ddd = digitacaoFields["phoneDDD1"] ?? "";
    const result = maskPhoneFormat(ddd + value);
    return { raw: "", value: result.formatedValue };
  }

  async function getUsuariosDigitadores() {
    setIsLoading(true);
    try {
      const { data }: { data: { username: string }[] } = await api.get(
        `/usuarios-bancos/digitadores/${digitacaoFields.bancoDigitacao}`
      );
      onChangeDigitacao({
        key: "usuariosDigitadores",
        value: data.map((u) => ({ name: u.username, value: u.username })),
      });
    } catch (e) {
    } finally {
      setIsLoading(false);
    }
  }

  const modalFooter = (
    <>
      {!isColomboUser(user) ? (
        <Center>
          <Text mr="12px">Usuário digitador banco: </Text>
          <DropdownField
            onChange={(value) => {
              onChangeDigitacao({ key: "sellerCode", value: value });
            }}
            dropdownProps={{
              w: "300px",
              children: allDisabled
                ? digitacaoFields.sellerCode ?? undefined
                : undefined,
            }}
            value={digitacaoFields.sellerCode}
            options={digitacaoFields.usuariosDigitadores || []}
            errorMessage={errors["sellerCode"]}
            isDisabled={isLoading || allDisabled}
          />
        </Center>
      ) : null}

      {isUpdate && allDisabled ? null : (
        <Button
          leftIcon={<KeyboardIcon width="20px" height="20px" />}
          onClick={() => handleDigitar()}
          isDisabled={isLoading}
        >
          {isUpdate && !allDisabled ? "Redigitar" : "Digitar"}
        </Button>
      )}
      <Button
        leftIcon={<CloseIcon w="12px" h="12px" />}
        variant="outline"
        onClick={onClose}
      >
        Fechar
      </Button>
    </>
  );
  maskCEPFormat(digitacaoFields["addressZipCode1"]);

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      size="4xl"
      containerProps={{ justifyContent: "center", alignItems: "flex-start" }}
      modalTitle="Digitação"
      modalFooter={modalFooter}
      scroll="inside"
      isLoading={isLoading}
    >
      <Grid
        templateColumns="1fr"
        gap="16px"
        border="1px solid var(--chakra-colors-custom-gray)"
        bg="var(--chakra-colors-gray-100)"
        p="20px 10px 10px 10px"
        borderRadius="4px"
      >
        <Flex pos="relative" mb="10px">
          <Center w="100%">
            <Text fontSize="20px" fontWeight="medium">
              Formulário {attendance.bancoDigitacao ?? "Banco"}
            </Text>
          </Center>
          <Button
            pos="absolute"
            right="0px"
            top="-5px"
            w="261px"
            leftIcon={<CopyIcon />}
            size="sm"
            onClick={() => {
              setDigitacaoFields({
                ...digitacaoFields,
                ...copyAttendanceDataToDigitacao(attendance),
                // ...{
                //   cpf: "07919272963",
                //   name: "Deivide",
                //   email: "askdkaskd@gmail.com",
                //   birthDay: "28/04/1993",
                //   gender: "M",
                //   civilStatus: "SOLTEIRO",
                //   nationality: "BRASILEIRO",
                //   documentNumber: "12313132",
                //   documentEmissionState: "SC",
                //   documentIssuer: "SSP",
                //   documentDate: "01/01/2008",
                //   mothersName: "Fulana de Tal",
                //   fathersName: "Cricano de Tal",
                //   birthCity: "FLORIANOPOLIS",
                //   incomeValue: 10000,
                //   bankDigit: "1",
                //   pixKey: "70848960408",
                //   pixKeyType: "CPF",
                //   birthState: "SC",
                //   equityValue: 150000,
                //   illiterate: "NÃO",
                //   phoneNumber1: "912312312",
                //   phoneDDD1: "47",
                //   addressStreet1: "Av. Teste Rua ",
                //   addressNumber1: "102301",
                //   addressComplement1: "smadksa",
                //   addressNeigh1: "intermares",
                //   addressCity1: "CABEDELO",
                //   addressStateCode1: "PB",
                //   addressZipCode1: "58102138",
                //   bankNumber: "104",
                //   bankAgency: "1230",
                //   bankAccountNumber: "103213",
                //   bankType: "CONTA_CORRENTE_INDIVIDUAL",
                // },
              });
            }}
            isDisabled={allDisabled}
          >
            Copiar dados do atendimento
          </Button>
        </Flex>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="CPF"
            onChange={(e) =>
              onChangeDigitacao({
                key: "cpf",
                value: maskCPFFormat(e.target.value).raw,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={maskCPFFormat(digitacaoFields["cpf"]).value}
            errorMessage={errors["cpf"]}
          />
        </Grid>

        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Nome"
            onChange={(e) =>
              onChangeDigitacao({ key: "name", value: e.target.value })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["name"]}
            errorMessage={errors["name"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Data Nascimento"
            onChange={(e) =>
              onChangeDigitacao({
                key: "birthDay",
                value: maskDateFormat(e.target.value).raw,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={maskDateFormat(digitacaoFields["birthDay"]).value}
            errorMessage={errors["birthDay"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Email"
            onChange={(e) =>
              onChangeDigitacao({
                key: "email",
                value: e.target.value,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["email"]}
            errorMessage={errors["email"]}
          />
        </Grid>

        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Sexo"
            onChange={(value) =>
              onChangeDigitacao({ key: "gender", value: value })
            }
            value={digitacaoFields["gender"]}
            errorMessage={errors["gender"]}
            options={[
              { name: "Feminino", value: "F" },
              { name: "Masculino", value: "M" },
            ]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Estado Civil"
            onChange={(value) =>
              onChangeDigitacao({ key: "civilStatus", value: value })
            }
            value={digitacaoFields["civilStatus"]}
            errorMessage={errors["civilStatus"]}
            options={statusCivilOptions}
          />
        </Grid>
        {digitacaoFields.civilStatus === "CASADO" ? (
          <Grid templateColumns="220px auto" alignItems="center" gap="20px">
            <InputField
              isDisabled={allDisabled}
              titleProps={{ textAlign: "end", mb: "0px" }}
              title="CPF do Cônjuge"
              onChange={(e) =>
                onChangeDigitacao({
                  key: "cpfPartner",
                  value: maskDateFormat(e.target.value).raw,
                })
              }
              inputProps={{ bg: "#fff" }}
              value={maskDateFormat(digitacaoFields["cpfPartner"]).value}
              errorMessage={errors["cpfPartner"]}
            />
          </Grid>
        ) : null}
        {digitacaoFields.civilStatus === "CASADO" ? (
          <Grid templateColumns="220px auto" alignItems="center" gap="20px">
            <InputField
              isDisabled={allDisabled}
              titleProps={{ textAlign: "end", mb: "0px" }}
              title="Nome do Cônjuge"
              onChange={(e) =>
                onChangeDigitacao({ key: "namePartner", value: e.target.value })
              }
              inputProps={{ bg: "#fff" }}
              value={digitacaoFields["namePartner"]}
              errorMessage={errors["namePartner"]}
            />
          </Grid>
        ) : null}
        {digitacaoFields.civilStatus === "CASADO" ? (
          <Grid templateColumns="220px auto" alignItems="center" gap="20px">
            <InputField
              isDisabled={allDisabled}
              titleProps={{ textAlign: "end", mb: "0px" }}
              title="Data de nascimento Cônjuge"
              onChange={(e) =>
                onChangeDigitacao({
                  key: "birthDayPartner",
                  value: e.target.value,
                })
              }
              inputProps={{ bg: "#fff" }}
              value={digitacaoFields["birthDayPartner"]}
              errorMessage={errors["birthDayPartner"]}
            />
          </Grid>
        ) : null}
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Nacionalidade"
            onChange={(value) => {
              if (value === "BRASILEIRO") {
                onChangeDigitacao({ key: "nationality", value: value });
                onChangeDigitacao({ key: "originCountry", value: undefined });
              } else if (value === "ESTRANGEIRO") {
                onChangeDigitacao({ key: "nationality", value: value });
              }
            }}
            value={digitacaoFields["nationality"]}
            errorMessage={errors["nationality"]}
            options={[
              { name: "Brasileiro", value: "BRASILEIRO" },
              { name: "Estrangeiro", value: "ESTRANGEIRO" },
            ]}
          />
        </Grid>
        {digitacaoFields.nationality === "ESTRANGEIRO" ? (
          <Grid templateColumns="220px auto" alignItems="center" gap="20px">
            <DropdownField
              isDisabled={allDisabled}
              titleProps={{ textAlign: "end", mb: "0px" }}
              dropdownProps={{ w: "100%", bg: "#fff" }}
              title="País Origem"
              onChange={(value) =>
                onChangeDigitacao({ key: "originCountry", value: value })
              }
              value={digitacaoFields["originCountry"]}
              errorMessage={errors["originCountry"]}
              options={paisesList}
            />
          </Grid>
        ) : null}
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Número do Documento"
            onChange={(e) =>
              onChangeDigitacao({
                key: "documentNumber",
                value: e.target.value,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["documentNumber"]}
            errorMessage={errors["documentNumber"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="UF Emissão Documento"
            onChange={(value) =>
              onChangeDigitacao({ key: "documentEmissionState", value: value })
            }
            value={digitacaoFields["documentEmissionState"]}
            errorMessage={errors["documentEmissionState"]}
            options={UFsBrasil}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Orgão Emissor Documento"
            onChange={(value) =>
              onChangeDigitacao({ key: "documentIssuer", value: value })
            }
            value={digitacaoFields["documentIssuer"]}
            errorMessage={errors["documentIssuer"]}
            options={dropdownOrgaosEmissoresDocDigitacaoFGTSFacta}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Data Emissão Documento"
            onChange={(e) =>
              onChangeDigitacao({
                key: "documentDate",
                value: maskDateFormat(e.target.value).raw,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={maskDateFormat(digitacaoFields["documentDate"]).value}
            errorMessage={errors["documentDate"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Nome Mãe"
            onChange={(e) =>
              onChangeDigitacao({ key: "mothersName", value: e.target.value })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["mothersName"]}
            errorMessage={errors["mothersName"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Nome Pai"
            onChange={(e) =>
              onChangeDigitacao({ key: "fathersName", value: e.target.value })
            }
            value={digitacaoFields["fathersName"]}
            inputProps={{ bg: "#fff" }}
            errorMessage={errors["fathersName"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <Text fontWeight="medium" textAlign="end">
            Cidade Nascimento
          </Text>
          <InputSearch
            onChange={(optionValue: { cityName: string; uf: string }) => {
              onChangeDigitacao({
                key: "birthCity",
                value: optionValue.cityName,
              });
              onChangeDigitacao({ key: "birthState", value: optionValue.uf });
            }}
            clearInput={false}
            searchKeys={["name"]}
            errorMessage={errors["birthCity"]}
            checkIsInclude={(option) =>
              digitacaoFields["birthCity"] === option.value.cityName
            }
            optionLabelRender={(option) =>
              `${option.name} - ${option.value.uf}`
            }
            value={
              digitacaoFields["birthCity"] && digitacaoFields["birthState"]
                ? `${digitacaoFields["birthCity"]} - ${digitacaoFields["birthState"]}`
                : undefined
            }
            options={factaCitiesArray}
            inputProps={{
              id: "InputSearch-birthCity",
              bg: "#fff",
              onBlur: (e) => {
                if (
                  digitacaoFields["birthState"] &&
                  digitacaoFields["birthCity"]
                )
                  e.target.value = `${digitacaoFields["birthCity"]} - ${digitacaoFields["birthState"]}`;
                else e.target.value = "";
              },
              isDisabled: allDisabled,
            }}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Salário"
            onChange={(e) =>
              onChangeDigitacao({
                key: "incomeValue",
                value: currencyMaskFormat(e.target.value).raw,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={currencyMaskFormat(digitacaoFields["incomeValue"]).value}
            errorMessage={errors["incomeValue"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Patrimônio"
            onChange={(e) =>
              onChangeDigitacao({
                key: "equityValue",
                value: currencyMaskFormat(e.target.value).raw,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={currencyMaskFormat(digitacaoFields["equityValue"]).value}
            errorMessage={errors["equityValue"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <DropdownField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            dropdownProps={{ w: "100%", bg: "#fff" }}
            title="Pessoa Analfabeta"
            onChange={(value) =>
              onChangeDigitacao({ key: "illiterate", value: value })
            }
            value={digitacaoFields["illiterate"]}
            errorMessage={errors["illiterate"]}
            options={yesOrNoOptions}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Celular"
            onChange={(e) => {
              const result = maskPhoneFormat(e.target.value);
              const ddd = result.parts[1];
              const numberPart1 = result.parts[2];
              const numberPart2 = result.parts[3];
              const number = numberPart1 + numberPart2;
              onChangeDigitacao({ key: "phoneNumber1", value: number });
              onChangeDigitacao({ key: "phoneDDD1", value: ddd });
            }}
            inputProps={{ bg: "#fff" }}
            value={phoneDDDMask(digitacaoFields["phoneNumber1"]).value}
            errorMessage={errors["phoneNumber1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto 140px" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="CEP"
            onChange={(e) =>
              onChangeDigitacao({
                key: "addressZipCode1",
                value: maskCEPFormat(e.target.value).raw,
              })
            }
            inputProps={{
              bg: "#fff",
              onKeyDown: (e) => {
                if (e.key === "Enter") {
                  loadCEPData(e.currentTarget.value.replace(/\D/g, ""));
                }
              },
            }}
            value={maskCEPFormat(digitacaoFields["addressZipCode1"]).value}
            errorMessage={errors["addressZipCode1"]}
          />
          <ButtonWithLoading
            isDisabled={(digitacaoFields["addressZipCode1"]?.length ?? 0) < 8}
            onClick={async () =>
              loadCEPData(digitacaoFields["addressZipCode1"]!)
            }
            rightIcon={<Search2Icon />}
          >
            Buscar CEP
          </ButtonWithLoading>
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Rua"
            onChange={(e) =>
              onChangeDigitacao({
                key: "addressStreet1",
                value: e.target.value,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["addressStreet1"]}
            errorMessage={errors["addressStreet1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Número"
            onChange={(e) =>
              onChangeDigitacao({
                key: "addressNumber1",
                value: e.target.value,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["addressNumber1"]}
            errorMessage={errors["addressNumber1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Complemento"
            onChange={(e) =>
              onChangeDigitacao({
                key: "addressComplement1",
                value: e.target.value,
              })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["addressComplement1"]}
            errorMessage={errors["addressComplement1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <InputField
            isDisabled={allDisabled}
            titleProps={{ textAlign: "end", mb: "0px" }}
            title="Bairro"
            onChange={(e) =>
              onChangeDigitacao({ key: "addressNeigh1", value: e.target.value })
            }
            inputProps={{ bg: "#fff" }}
            value={digitacaoFields["addressNeigh1"]}
            errorMessage={errors["addressNeigh1"]}
          />
        </Grid>
        <Grid templateColumns="220px auto" alignItems="center" gap="20px">
          <Text fontWeight="medium" textAlign="end">
            Cidade
          </Text>
          <InputSearch
            onChange={(optionValue: { cityName: string; uf: string }) => {
              onChangeDigitacao({
                key: "addressCity1",
                value: optionValue.cityName,
              });
              onChangeDigitacao({
                key: "addressStateCode1",
                value: optionValue.uf,
              });
            }}
            clearInput={false}
            searchKeys={["name"]}
            errorMessage={errors["addressCity1"]}
            checkIsInclude={(option) =>
              digitacaoFields["addressCity1"] === option.value.cityName
            }
            optionLabelRender={(option) =>
              `${option.name} - ${option.value.uf}`
            }
            value={
              digitacaoFields["addressCity1"] &&
              digitacaoFields["addressStateCode1"]
                ? `${digitacaoFields["addressCity1"]} - ${digitacaoFields["addressStateCode1"]}`
                : undefined
            }
            options={factaCitiesArray}
            inputProps={{
              id: "InputSearch-addressCity1",
              bg: "#fff",
              onBlur: (e) => {
                if (
                  digitacaoFields["addressStateCode1"] &&
                  digitacaoFields["addressCity1"]
                )
                  e.target.value = `${digitacaoFields["addressCity1"]} - ${digitacaoFields["addressStateCode1"]}`;
                else e.target.value = "";
              },
              isDisabled: allDisabled,
            }}
          />
        </Grid>

        {digitacaoFields.bancoDigitacao === "TA_QUITADO" ? (
          <>
            <Grid templateColumns="220px auto" alignItems="center" gap="20px">
              <DropdownField
                dropdownProps={{ w: "100%", bg: "#fff" }}
                isDisabled={allDisabled}
                titleProps={{ textAlign: "end", mb: "0px" }}
                title="Tipo da chave PIX"
                onChange={(value) =>
                  onChangeDigitacao({ key: "pixKeyType", value })
                }
                options={[
                  { name: "CPF", value: "CPF" },
                  { name: "CNPJ", value: "CNPJ" },
                  { name: "Email", value: "EMAIL" },
                  { name: "Telefone/Celular", value: "PHONE" },
                  { name: "Chave Aleatória", value: "RANDOM" },
                ]}
                value={digitacaoFields["pixKeyType"]}
                errorMessage={errors["pixKeyType"]}
              />
            </Grid>

            <Grid templateColumns="220px auto" alignItems="center" gap="20px">
              <InputField
                isDisabled={allDisabled || !digitacaoFields["pixKeyType"]}
                titleProps={{ textAlign: "end", mb: "0px" }}
                title="Chave PIX"
                onChange={(e) => {
                  console.log(digitacaoFields["pixKeyType"]);
                  if (digitacaoFields["pixKeyType"]) {
                    onChangeDigitacao({
                      key: "pixKey",
                      value: pixMask?.(e.target.value).raw,
                    });
                  }
                }}
                inputProps={{
                  bg: "#fff",
                  placeholder: !digitacaoFields["pixKeyType"]
                    ? `Preencha o campo: "Tipo da chave PIX"`
                    : "",
                }}
                value={pixMask?.(digitacaoFields["pixKey"]).value || ""}
                errorMessage={errors["pixKey"]}
              />
            </Grid>
          </>
        ) : (
          <>
            <Grid templateColumns="220px auto" alignItems="center" gap="20px">
              <InputField
                isDisabled={allDisabled}
                titleProps={{ textAlign: "end", mb: "0px" }}
                title="Código Banco"
                onChange={(e) =>
                  onChangeDigitacao({
                    key: "bankNumber",
                    value: e.target.value,
                  })
                }
                inputProps={{ bg: "#fff" }}
                value={digitacaoFields["bankNumber"]}
                errorMessage={errors["bankNumber"]}
              />
            </Grid>

            <Grid templateColumns="220px auto" alignItems="center" gap="20px">
              <InputField
                isDisabled={allDisabled}
                titleProps={{ textAlign: "end", mb: "0px" }}
                title="Agência"
                onChange={(e) =>
                  onChangeDigitacao({
                    key: "bankAgency",
                    value: e.target.value,
                  })
                }
                inputProps={{ bg: "#fff" }}
                value={digitacaoFields["bankAgency"]}
                errorMessage={errors["bankAgency"]}
              />
            </Grid>
            <Grid
              templateColumns="220px auto 67px 15%"
              alignItems="center"
              gap="20px"
            >
              <InputField
                isDisabled={allDisabled}
                titleProps={{ textAlign: "end", mb: "0px" }}
                title="Conta"
                onChange={(e) =>
                  onChangeDigitacao({
                    key: "bankAccountNumber",
                    value: e.target.value,
                  })
                }
                inputProps={{ bg: "#fff" }}
                value={digitacaoFields["bankAccountNumber"]}
                errorMessage={errors["bankAccountNumber"]}
              />
              <InputField
                isDisabled={allDisabled}
                titleProps={{ textAlign: "end", mb: "0px" }}
                title="Díg. Verif."
                onChange={(e) =>
                  onChangeDigitacao({
                    key: "bankDigit",
                    value: e.target.value,
                  })
                }
                inputProps={{ bg: "#fff" }}
                value={digitacaoFields["bankDigit"]}
                errorMessage={errors["bankDigit"]}
              />
            </Grid>
            <Grid templateColumns="220px auto" alignItems="center" gap="20px">
              <DropdownField
                isDisabled={allDisabled}
                titleProps={{ textAlign: "end", mb: "0px" }}
                dropdownProps={{ w: "100%", bg: "#fff" }}
                title="Tipo Conta"
                onChange={(value) =>
                  onChangeDigitacao({ key: "bankType", value: value })
                }
                value={digitacaoFields["bankType"]}
                errorMessage={errors["bankType"]}
                options={dropdownTipoConta}
              />
            </Grid>
          </>
        )}
      </Grid>
    </CustomModal>
  );
}

export const openModalDigitarProposta = (
  props?: ModalDataDigitarPropostaProps & { bancoDigitacao: string }
) => makeEvent("openModalDigitarProposta", props);

// Complete Form
// ...{
//   atendimentoId: 4755244,
//   bancoDigitacao: "FACTA",
//   cpf: "07919272963",
//   name: "Deivide",
//   birthDay: "01/05/1996",
//   gender: "M",
//   civilStatus: "SOLTEIRO",
//   nationality: "BRASILEIRO",
//   documentNumber: "12313132",
//   documentEmissionState: "SC",
//   documentIssuer: "SSP",
//   documentDate: "01/01/2008",
//   mothersName: "Fulana de Tal",
//   fathersName: "Cricano de Tal",
//   birthCity: "FLORIANOPOLIS",
//   incomeValue: 10000,
//   birthState: "SC",
//   equityValue: 150000,
//   illiterate: "NÃO",
//   phoneNumber1: "912312312",
//   phoneDDD1: "47",
//   addressStreet1: "Av. Teste Rua ",
//   addressNumber1: "102301",
//   addressComplement1: "smadksa",
//   addressNeigh1: "intermares",
//   addressCity1: "CABEDELO",
//   addressStateCode1: "PB",
//   addressZipCode1: "5812031",
//   bankNumber: "413",
//   bankAgency: "1230",
//   bankAccountNumber: "103213",
//   bankType: "CONTA_CORRENTE_INDIVIDUAL",
// },
