import {
  AttendanceCardArrayProps,
  AttendanceCardProps,
  AttendanceKanbanColumnArrayProps,
  TabulacaoType,
} from "./types";
import { User } from "contexts/types/application-context-types";
import { Convenio } from "components/atendimentos-components/atendimento-form/types";
import { dropdownConvenioAtendimento } from "components/atendimentos-components/atendimento-form/fields-data";

export const initialKanbanColumns: AttendanceKanbanColumnArrayProps[] = [
  {
    colName: "Pendente",
    title: "VENDAS",
    color: "#F9BE35",
    attendancesList: [],
    colId: "PENDENTE",
    isVisible: (user: User) => true,
  },
  {
    colName: "Em atendimento",
    title: "VENDAS",
    color: "#40A6E5",
    attendancesList: [],
    colId: "EM_ATENDIMENTO",
    isVisible: (user: User) => true,
  },
  {
    colName: "Aguardando digitação",
    title: "OPERACIONAL",
    color: "#8077F1",
    attendancesList: [],
    colId: "AGUARDANDO_DIGITACAO",
    isVisible: (user: User) => true,
  },
  {
    colName: "Digitado",
    title: "OPERACIONAL",
    color: "#141B3B",
    attendancesList: [],
    colId: "DIGITADO",
    isVisible: (user: User) => true,
  },
  {
    colName: "Aguardando formalização",
    title: "VENDAS",
    color: "#F17EAD",
    attendancesList: [],
    colId: "AGUARDANDO_FORMALIZACAO",
    isVisible: (user: User) => true,
  },
  {
    colName: "Aguardando pagamento",
    title: "VENDAS",
    color: "#F17000",
    attendancesList: [],
    colId: "AGUARDANDO_PAGAMENTO",
    isVisible: (user: User) => true,
  },
];

export const parseConvenio = (convenio: Convenio) =>
  dropdownConvenioAtendimento.find((c) => c.value === convenio)?.name;

export const attendancesListMock: AttendanceCardArrayProps[] = [
  {
    id: 1,
    etapa: "PENDENTE",
    convenio: "INSS",
    userName: "Carlos Eduardo",
    dataCriacaoMilliseconds: 1703793264691,
    unreadMessage: true,
    nome: "Vera Lúcia Rezende",
    cpf: "12312312312",
    origem: "LANDING_PAGE",
  },
  {
    id: 2,
    etapa: "AGUARDANDO_PAGAMENTO",
    convenio: "SIAPE",
    userName: "Carlos Eduardo",
    dataCriacaoMilliseconds: 1703793264691,
    nome: "Fulano de Tal",
    cpf: "12312312312",
    origem: "SMS",
  },
  {
    id: 3,
    etapa: "AGUARDANDO_DIGITACAO",
    convenio: "PREF",
    userName: "Carlos Eduardo",
    dataCriacaoMilliseconds: 1703793264691,
    nome: "Cicrano de Tal",
    cpf: "12312312312",
    origem: "WHATSAPP",
  },
];

export const origemOptions = [
  { name: "Wabox", value: "WABOX" },
  { name: "Telefonia", value: "TELEFONIA" },
  { name: "Landing Page", value: "LANDING_PAGE" },
  { name: "Manual", value: "MANUAL" },
  { name: "WhatsApp", value: "WHATSAPP" },
  { name: "URA WhatsApp", value: "URA_WHATSAPP" },
  { name: "SMS", value: "SMS" },
  { name: "PIPELINE", value: "PIPELINE" },
];

export const etapaDropdownArray = initialKanbanColumns.map(
  ({ colId, colName }) => {
    return { name: colName, value: colId };
  }
);

// Falta a tabulacao

export const getNameInitials = (name: string | null | undefined) => {
  if (name) {
    const result = name
      .split(" ")
      .map((subName) => (subName.length > 2 ? subName.charAt(0) : ""))
      .join("")
      .slice(0, 2);
    return result;
  } else return undefined;
};

export const getColumnColor = (
  column: AttendanceKanbanColumnArrayProps["colId"]
) => {
  return initialKanbanColumns.find((crr) => crr.colId === column)?.color;
};

export const columnsColors = new Map<
  string,
  { color: string; bg: string; _hover?: any }
>([
  ["PENDENTE", { color: "#141B3B", bg: "#F9BE35", _hover: { opacity: "0.8" } }],
  [
    "EM_ATENDIMENTO",
    { color: "#fff", bg: "#40A6E5", _hover: { opacity: "0.8" } },
  ],
  [
    "AGUARDANDO_DIGITACAO",
    { color: "#fff", bg: "#8077F1", _hover: { opacity: "0.8" } },
  ],
  ["DIGITADO", { color: "#fff", bg: "#141B3B", _hover: { opacity: "0.8" } }],
  [
    "AGUARDANDO_FORMALIZACAO",
    { color: "#fff", bg: "#F17EAD", _hover: { opacity: "0.8" } },
  ],
  [
    "AGUARDANDO_PAGAMENTO",
    { color: "#fff", bg: "#F17000", _hover: { opacity: "0.8" } },
  ],
]);

export const tabColors = new Map<string, string>([
  ["PENDENTE", "#FEFFCB"],
  ["CLIENTE_QUALIFICADO", "#CBFFCD"],
  ["NAO_E_CLIENTE", "#D8E5FF"],
  ["EM_ATENDIMENTO", "#DFF4F1"],
  ["EM_DIGITACAO", "#ECFFC5"],
  ["AGUARDANDO_DOCUMENTOS", "#FFF1ED"],
  ["DIGITADO", "#D5FFFF"],
  ["AGUARDANDO_FORMALIZACAO", "#F3EBA4"],
]);

export const convenioColors = new Map<string, string>([
  ["ANTECIPACAO_FGTS", "#FFEDBE"],
  ["EXERCITO", "#C3FFBE"],
  ["GOV", "#DFF4F1"],
  ["INSS", "#BEDCFF"],
  ["PREF", "#FFBEFC"],
  ["SIAPE", "#CFBEFF"],
]);

export const statusColors = new Map<TabulacaoType, string>([
  ["AGUARDANDO_AVERBACAO", "#EAE8D5"],
  ["AGUARDANDO_DOCUMENTOS", "#FFF1ED"],
  ["AGUARDANDO_FORMALIZACAO", "#F3EBA4"],
  ["DIGITACAO_PENDENTE", "#F5DABB"],
  ["DIGITADO", "#D5FFFF"],
  ["EM_ATENDIMENTO", "#DFF4F1"],
  ["EM_DIGITACAO", "#D5FFFF"],
  ["EM_NEGOCIACAO", "#E5D8FF"],
  ["NADA_A_OFERECER", "#FFD8ED"],
  ["NAO_E_O_CLIENTE", "#D8E5FF"],
  ["PAGO", "#F2F2F2"],
  ["PENDENTE", "#FEFFCB"],
  ["PERSISTIR_MUITO_TOP", "#FEFFCB"],
  ["REPROVADO", "#FFC5C5"],
  ["SEM_INTERESSE", "#E5E5E5"],
  ["SEM_INTERESSE_QUENTE", "#F3EBA4"],
]);

export const parseOrigem = new Map<AttendanceCardProps["origem"], string>([
  ["LANDING_PAGE", "LP"],
  ["MANUAL", "Manual"],
  ["SMS", "SMS"],
  ["TELEFONIA", "Telefonia"],
  ["URA_WHATSAPP", "URA WPP"],
  ["WABOX", "Wabox"],
  ["WHATSAPP", "WhatsApp"],
  ["PIPELINE", "Pipeline"],
]);
