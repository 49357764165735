import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import { Box, BoxProps, Spinner, useColorMode } from "@chakra-ui/react";
import { CSSProperties, ReactNode, useEffect, useState } from "react";

type SimpleColumn = {
  name: string | ReactNode;
  icon?: ReactNode;
  key?: string;
  render?: (row: any, index: number) => ReactNode;
  cellStyle?: CSSProperties;
  sortClick?: undefined;
  headRender?: () => string | ReactNode;
};

type SortableColumn = {
  name: string | ReactNode;
  icon?: ReactNode;
  key: string;
  render?: (row: any, index: number) => ReactNode;
  cellStyle?: CSSProperties;
  sortClick: boolean;
  headRender?: () => string | ReactNode;
};

export type PDFColumn = SortableColumn | SimpleColumn;

interface CustomTablePDFProps {
  columns: PDFColumn[];
  rows: any[];
  pageSize?: number | null;
  onClickRow?: (row: any) => void;
  isLoading?: boolean;
  emptyMessage?: string;
  sortOrder?: "ASC" | "DESC";
  containerStyle?: CSSProperties;
  rowKey?: string;
  size?: "full" | "md" | "sm";
  onChangePage?: (index: number) => void;
  pageControl?: number;
  containerProps?: BoxProps;
}

export function CustomTablePDF({
  columns = [],
  rows = [],
  pageSize = 100,
  onClickRow,
  isLoading,
  emptyMessage = "Sem dados",
  containerStyle,
  size,
  onChangePage,
  pageControl,
  containerProps,
}: CustomTablePDFProps) {
  const [currentIndexPage, setIndexPage] = useState(0);
  const { colorMode } = useColorMode();

  const numberOfPages = pageSize ? Math.ceil(rows.length / pageSize) : 1;

  const paginedData =
    pageSize && rows.length > pageSize
      ? rows.slice(
          currentIndexPage * pageSize,
          (currentIndexPage + 1) * pageSize
        )
      : rows;

  let pageArray: number[] = [];
  for (let k = 0; k < numberOfPages; k++) {
    pageArray.push(k);
  }

  if (currentIndexPage - 2 >= 0 && numberOfPages > 5) {
    const cut: number[] = pageArray.slice(
      currentIndexPage - 2,
      currentIndexPage + 3
    );
    if (cut.length < 5)
      pageArray = pageArray.slice(numberOfPages - 5, numberOfPages);
    else pageArray = cut;
  } else pageArray = pageArray.slice(0, 5);

  useEffect(() => {
    if (pageControl !== undefined) {
      setIndexPage(pageControl);
    }
  }, [pageControl]);

  const cellPadding =
    ({ sm: "0.3rem 0.5rem" } as any)[size ?? ""] ?? "0.8rem 1.5rem";

  return (
    <Box
      className={`table-container ${colorMode}`}
      style={{ ...containerStyle }}
      {...containerProps}
    >
      <table>
        <thead>
          <tr className={`table-row ${colorMode}1`}>
            {columns.map(({ name, headRender }, index) => {
              const isStart = index === 0;
              const isLast = index + 1 === columns.length;
              return (
                <th
                  key={`column-${name}-${index}`}
                  className="table-header-cell"
                  style={{
                    borderRadius: isStart
                      ? "10px 0px 0 0"
                      : isLast
                      ? "0px 10px 0 0"
                      : undefined,
                    padding: cellPadding,
                  }}
                >
                  <div className="header-cell-align">
                    {headRender?.()}
                    {name}{" "}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {isLoading
            ? null
            : paginedData?.map((row, rowIndex) => (
                <tr
                  key={`row-${rowIndex}`}
                  onClick={onClickRow ? () => onClickRow(row) : undefined}
                  className={`table-row ${colorMode} ${
                    onClickRow ? "cursor-pointer" : ""
                  }`}
                >
                  {columns.map(({ key, render, cellStyle }, index) => {
                    return (
                      <td
                        key={`cell-${rowIndex}-${index}`}
                        className="table-data-cell"
                        style={{ padding: cellPadding, ...cellStyle }}
                      >
                        {render?.(row, rowIndex) ?? row[key ?? ""]}
                      </td>
                    );
                  })}
                </tr>
              ))}
        </tbody>
      </table>
      {isLoading ? (
        <div
          className="loading-container"
          style={{ minHeight: size === "md" ? "200px" : "478px" }}
        >
          <Spinner width="40px" height="40px" />
        </div>
      ) : null}
      {isLoading === false && !rows?.length ? (
        <p className="empty-message">{emptyMessage}</p>
      ) : null}
    </Box>
  );
}
